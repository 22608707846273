import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchItemComponent } from './switch-item.component';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';

@NgModule({
    imports: [CommonModule, TooltipsModule],
    exports: [SwitchItemComponent],
    declarations: [SwitchItemComponent],
})
export class SwitchItemModule {}
