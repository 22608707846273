<cs-top-right-elements *ngIf="(moduleConfig$ | async).enableTopElements"
></cs-top-right-elements>
<ng-container *ngIf="(moduleConfig$ | async).enableCalendar">
    <cityair-map-control [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
         [isShowCityWidget]="store.select(selectShowAqiWidget) | async"
         *ngIf="(store.select(selectMeasurementsForPins) | async)?.length"
    >
        <div map-control-period>
            <calendar
                [columns]="2"
                [monthsVisible]="2"
                [dropdownMode]="true"
                (updateDateRange)="store.dispatch(intervalUpdate($event))"
            ></calendar>
        </div>
        <div map-control-mmts>
            <shared-ui-mmt-selector
                [title]="TEXTS.MAP_CONTROL.on"
                [mmts]="store.select(selectMeasurementsForPins) | async"
                [activeMmt]="store.select(selectGlobalMeasurement) | async"
                (selectMmt)="store.dispatch(setGlobalMeasurement({ mmt: $event }))"
            ></shared-ui-mmt-selector>
        </div>
    </cityair-map-control>
</ng-container>
<div class="timeline-temp-wrapper">
    <div class="timeline__wrapper timeline__wrapper-displayFlex">
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectIsSidebarOpen) | async"
        >
            <cs-switch-button
                class="compare-button"
                data-cy="compare-button"
                [text]="TEXTS.STATION_BLOCK.compare"
                [active]="store.select(selectIsComparisonModeActive) | async"
                (activeChange)="toggleComparisonMode($event)"
            ></cs-switch-button>
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
            <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectSchemeAndMeasure) | async as val"
                [unit]="TEXTS.MEASURES_SCHEME[val.scheme][val.mmt]"
                [measure]="TEXTS.NAMES[val.mmt]"
                [colors]="(store.select(selectZone) | async)?.color"
                [values]="(store.select(selectZone) | async)?.zone"
                [digitsAfterDot]="getDigits(store.select(selectMeasureScheme) | async)(val.mmt)"
            ></color-legend>

            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [sidebarIsOpened]="store.select(selectIsSidebarOpen) | async"
                [currentTime]="store.select(selectCurrentTimeByIndex) | async"
                [dates]="store.select(selectTimelineDateTimes) | async"
                [features]="store.select(selectSourcesAsFeatures) | async"
                [initSelectMeasurement]="store.select(selectGlobalMeasurement) | async"
                [labelMode]="chartSingleSelect"
                [showNow]="true"
                [comparisonEnabled]="true"
                (setCompareMode)="onCompare()"
                [isComparisonModeActive]="store.select(selectIsComparisonModeActive) | async"
                [pdk]="store.select(selectPdkForChart) | async"
                [aqiTooltipTemplate]="aqiTooltipTemplate"
                [chartMinMax]="chartMinMax"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [groupTooltip]="groupTooltip"
                [qualityDataMode]="store.select(selectQualityDataMode) | async"
                [dataQualityTimeline]="store.select(selectQualityDataTimeline)| async"
                [timezone]="store.select(selectUserTimezone) | async"
                (showQualityDataInfo)="store.dispatch(toggleShowQualityDataInfo({ payload: $event }))"
                (goToCity)="goToCity($event)"
                (removeFromComparison)="store.dispatch(removeFromComparison({ id: $event }))"
                (changeTimeIndex)="store.dispatch(updateTimeIndex({ payload: $event }))"
            >
                <div
                    class="legacy-buttons"
                >
                    <switch-item
                        [tabs]="tabsInterval"
                        [currentTab]="selectedTabInterval"
                        [showTooltipForDisabled]="true"
                        (action)="setDataInterval($event)"
                    ></switch-item>
                </div>
            </timeline-panel>

            <ng-template #aqiTooltipTemplate>
                <cs-aqi-details-tooltip
                    *ngIf="isRU"
                    (openDetailsLink)="openAqiFaqLink(AqiType.instant)"
                ></cs-aqi-details-tooltip>
            </ng-template>
        </div>
    </div>
</div>
