import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgLocalization } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { isRU, LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import {
    postsListLabels,
    selectedPostsText,
} from '@cityair/modules/notifications/notifications.utils';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';

import {
    deduplicate,
    deepEqual,
    EmailsListData,
    mapEmails,
    stringToList,
    updateFormControls,
} from '../settings.utils';

import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { GroupFeaturesService } from '@cityair/modules/core/services/group-features/group-features.service';
import {
    selectFormError,
    selectNotifiablePostIds,
} from '@cityair/modules/notifications/store/selectors';
import {
    createNotificationSettings,
    formNotificationSettingsError,
    editNotificationSettings,
    updateNotifiablePosts,
} from '@cityair/modules/notifications/store/actions';

import { TooltipsService } from '@libs/shared-ui/components/tooltips/tooltips.service';
import {
    DataObjType,
    MoIntervalTypeEnum,
    NotificationSettings,
    Post,
    User,
    NotificationType,
} from '@libs/common/models/basicModels';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { getAllMmt } from '@libs/common/utils/utils';

@Component({
    selector: 'settings-measurements',
    templateUrl: 'settings-measurements.component.html',
    styleUrls: ['settings-measurements.component.less'],
})
export class SettingsMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSettings;
    @Input() currentUser: User;
    @Input() groupId: string;
    @Input() isLoadingForm: boolean;
    @Output() cancelEmit = new EventEmitter<{ settings_type: NotificationType }>();

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSettings;
    mosList: Post[];
    allMos: string[];
    allAvailableSubstance: string[];
    allSubstances: string[];
    moCheckboxes: CheckboxItem[] = [];

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;

    showPdkTooltip = isRU;

    TEXTS = TEXTS;
    listStations: CheckboxItem[] = [];
    isShowDropdownForStations = false;
    settingsForm: UntypedFormGroup;
    public errorResponse: string;
    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showMosSelection = false;
    showConfirmationPopup = false;

    maxInlineMos = MAX_INLINE_MOS;

    allowTSP = false;
    minPDK = 0.5;
    maxPDK = 10;

    subscriptions: Subscription[] = [];

    constructor(
        private element: ElementRef,
        private ngLocalization: NgLocalization,
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        readonly tooltipsService: TooltipsService,
        groupFeaturesService: GroupFeaturesService,
        private store: Store
    ) {
        this.allowTSP = groupFeaturesService.getConfig(GroupExtConfigName.enableTSP) as boolean;

        const monitoringObjectsSub = store
            .select(selectAllPosts)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.mosList = mos;
                this.allSubstances = getAllMmt(mos);
                this.allMos = mos.map((v) => v.id);
                this.listStations = this.prepareListMo();
            });

        this.subscriptions.push(monitoringObjectsSub);
        const serverErrorSub = store.select(selectFormError).subscribe((value) => {
            if (value) {
                const data = value.error?.meta?.error?.validation_errors;
                if (data?.length) {
                    data.forEach((error) => {
                        const key = error?.field;
                        if (this.settingsForm?.controls[key]) {
                            this.settingsForm.controls[key].setErrors({
                                incorrect: true,
                                message: error?.error,
                            });
                        } else if (key === 'detail') {
                            this.errorResponse = error?.error;
                            this.clearError();
                        }
                    });
                }
            }
        });

        this.subscriptions.push(serverErrorSub);

        const maxPDK = Math.round(
            groupFeaturesService.getConfig(GroupExtConfigName.commonMaxPDK) as number
        );

        if (maxPDK >= 10 && maxPDK <= 30) {
            this.maxPDK = maxPDK;
        }
    }

    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);

    ngOnInit() {
        this.workingSubscription = this.cloneSettings(this.subscription);

        this.emailsListData = mapEmails(
            this.workingSubscription.notify_settings.notification_emails
        );
        this.emailsListReportsData = mapEmails(
            this.workingSubscription.notify_settings.daily_report_emails
        );

        this.listStations = this.prepareListMo();

        this.setNotifiableMos();

        this.settingsForm = this.fb.group({
            excessLevel: [
                this.workingSubscription.pdk_check_settings.pdk_mr_multiplier,
                [Validators.required],
            ],
            name: [
                this.workingSubscription.name,
                [Validators.required, Validators.minLength(1), Validators.maxLength(100)],
            ],
            emailsList: [
                this.workingSubscription.notify_settings.notification_emails,
                [Validators.minLength(1)],
            ],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            emailsListReports: [
                this.workingSubscription.notify_settings.daily_report_emails,
                [Validators.minLength(1)],
            ],
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            isPeriodic: [this.workingSubscription.pdk_check_settings.should_notify_every_interval],
        });

        const title$ = this.name.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const emailsList$ = this.emailsList.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const titleSub = title$.subscribe((value) => {
            this.workingSubscription.name = value;
        });

        const excessLevelSub = this.excessLevel.valueChanges
            .pipe(debounceTime(600), distinctUntilChanged())
            .subscribe((value) => {
                let fixedValue = Math.max(Math.min(value, this.maxPDK), this.minPDK);

                if (isNaN(fixedValue)) {
                    fixedValue = this.minPDK;
                }
                this.excessLevel.patchValue(fixedValue);
                this.workingSubscription = {
                    ...this.workingSubscription,
                    pdk_check_settings: {
                        ...this.workingSubscription.pdk_check_settings,
                        pdk_mr_multiplier: fixedValue,
                    },
                };
                this.changeCheck();
            });

        const emailsListSub = emailsList$.subscribe((value) => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as string[]);
            this.workingSubscription = {
                ...this.workingSubscription,
                notify_settings: {
                    ...this.workingSubscription.notify_settings,
                    notification_emails: deduplicate(emails),
                },
            };
        });

        const emailsListReportsSub = emailsListReports$.subscribe((value) => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as string[]);
            this.workingSubscription = {
                ...this.workingSubscription,
                notify_settings: {
                    ...this.workingSubscription.notify_settings,
                    daily_report_emails: deduplicate(emails),
                },
            };
        });

        const isPeriodicSub = this.isPeriodic.valueChanges.subscribe((value) => {
            this.workingSubscription = {
                ...this.workingSubscription,
                pdk_check_settings: {
                    ...this.workingSubscription.pdk_check_settings,
                    should_notify_every_interval: value,
                },
            };
        });

        const checkChangesSub = merge(
            title$,
            emailsList$,
            emailsListReports$,
            this.emailsListProxy.valueChanges,
            this.emailsListReportsProxy.valueChanges,
            this.isPeriodic.valueChanges,
            this.excessLevel.valueChanges
        ).subscribe(() => {
            this.changeCheck();
        });

        // react on map clicks
        const notifiableMosSub = this.store.select(selectNotifiablePostIds).subscribe((postIds) => {
            this.listStations = this.mosList.map((s) => ({
                id: s.id,
                label: s.name,
                selected: postIds.indexOf(s.id) >= 0,
            }));
            this.changeCheck();
        });

        this.subscriptions.push(
            titleSub,
            excessLevelSub,
            emailsListSub,
            emailsListReportsSub,
            isPeriodicSub,
            notifiableMosSub
        );

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as UntypedFormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as UntypedFormGroup;
    }

    ngOnDestroy() {
        this.popupProvider.clear();

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    public getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    public removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
        this.setNotifiableMos();
        this.changeCheck();
    }

    public selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        if (num === 0) {
            return this.textsNotification.noneSelected;
        } else if (num === this.listStations.length) {
            return this.textsNotification.allMonitoringPostsSelected;
        }

        return [selected[category], num, post[category]].join(' ');
    }

    public getValueForm(field) {
        return this.settingsForm?.get(field)?.value || '';
    }

    public getError(field: string) {
        if (
            this.settingsForm &&
            this.settingsForm.controls[field] &&
            this.settingsForm.controls[field].invalid &&
            (this.settingsForm.controls[field].dirty || this.settingsForm.controls[field].touched)
        ) {
            if (this.settingsForm.controls[field].errors.required) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.required;
            }

            if (this.settingsForm.controls[field].errors.maxlength) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.maxLength(
                    this.settingsForm.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.settingsForm.controls[field].errors.incorrect) {
                return this.settingsForm.controls[field].errors.message;
            }

            return null;
        }

        return null;
    }

    private clearError() {
        setTimeout(() => {
            this.errorResponse = null;
            this.store.dispatch(formNotificationSettingsError({ payload: null }));
        }, 3000);
    }

    public prepareListMo(): CheckboxItem[] {
        const result = this.mosList.map((s) => ({
            id: s.id,
            label: s.name,
            selected: this.workingSubscription?.pdk_check_settings?.is_for_all_posts
                ? true
                : this.workingSubscription?.pdk_check_settings?.selected_posts.indexOf(s.id) >= 0,
        }));

        return result;
    }

    public changePosts($event) {
        this.listStations = $event;
        this.setNotifiableMos();
        this.changeCheck();
    }

    private normalizeMos(subscription: NotificationSettings) {
        const moItems = this.getMoIds();
        const isForAll = moItems.length === this.allMos.length;
        return Object.assign(this.cloneSettings(subscription), {
            pdk_check_settings: {
                ...subscription.pdk_check_settings,
                selected_posts: isForAll ? [] : moItems,
                is_for_all_posts: isForAll,
            },
        });
    }

    setNotifiableMos() {
        this.store.dispatch(
            updateNotifiablePosts({
                postIds: this.getMoIds(),
            })
        );
    }

    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as UntypedFormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(
            this.emailsListReportsProxy as UntypedFormGroup,
            this.emailsListReportsData
        );
    }

    get name() {
        return this.settingsForm.get('name');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get isPeriodic() {
        return this.settingsForm.get('isPeriodic');
    }

    get excessLevel() {
        return this.settingsForm.get('excessLevel');
    }

    getValueName(value: string) {
        return TEXTS.NAMES[value] ?? value;
    }

    toggleValueItem(value: string) {
        this.workingSubscription = {
            ...this.workingSubscription,
            pdk_check_settings: {
                ...this.workingSubscription.pdk_check_settings,
                is_for_any_value_type: false,
            },
        };
        const valueItems = [...this.workingSubscription.pdk_check_settings.selected_value_types];

        const idx = valueItems.indexOf(value);
        if (idx !== -1) {
            valueItems.splice(idx, 1);
        } else {
            valueItems.push(value);
        }
        this.workingSubscription = {
            ...this.workingSubscription,
            pdk_check_settings: {
                ...this.workingSubscription.pdk_check_settings,
                selected_value_types: valueItems,
            },
        };
        if (!valueItems.length) {
            this.selectAllValues();
        } else {
            this.changeCheck();
        }
    }

    selectAllValues() {
        this.workingSubscription = {
            ...this.workingSubscription,
            pdk_check_settings: {
                ...this.workingSubscription.pdk_check_settings,
                is_for_any_value_type: true,
                selected_value_types: [],
            },
        };
        this.changeCheck();
    }

    changeCheck() {
        this.formDataHasChanged = !deepEqual(
            this.subscription,
            this.normalizeMos(this.workingSubscription)
        );
    }

    getMoIds() {
        return this.listStations.filter((s) => s.selected).map((s) => s.id.toString());
    }

    isValueItemSelected(value: string) {
        return this.workingSubscription.pdk_check_settings.selected_value_types.includes(value);
    }

    updateSliderValue(value: number) {
        this.excessLevel.patchValue(value);
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    cancel = () => {
        this.cancelEmit.emit({ settings_type: NotificationType.pdkExcess });
    };

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        const subscription = this.normalizeMos(this.workingSubscription);
        if (subscription.id) {
            this.store.dispatch(editNotificationSettings({ payload: subscription }));
        } else {
            this.store.dispatch(createNotificationSettings({ payload: subscription }));
        }
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onSavePopup = () => {
        this.save();
    };

    onCancelPopup = () => {
        this.cancel();
    };

    private cloneSettings(src: NotificationSettings) {
        if (src === null) {
            return {
                id: null,
                name: null,
                type: NotificationType.pdkExcess,
                is_active: true,
                group_id: this.groupId,
                obj: DataObjType.notification,
                notify_settings: {
                    daily_report_emails: [],
                    notification_emails: this.currentUser?.email ? [this.currentUser.email] : [],
                },
                pdk_check_settings: {
                    interval_type: MoIntervalTypeEnum.interval20M,
                    is_for_all_posts: true,
                    is_for_any_value_type: true,
                    pdk_mr_multiplier: 1.5,
                    selected_posts: [],
                    selected_value_types: [],
                    should_notify_every_interval: false,
                },
            } as NotificationSettings;
        } else {
            return { ...src };
        }
    }
}
