import { Component, Input } from '@angular/core';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { Measurement, Post } from '@cityair/modules/indoor/services/api';
import { orderOfMeasures } from '@cityair/modules/indoor/config';
import { AqiType } from '@libs/common/enums/aqi.type';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'point-item',
    templateUrl: './point-item.component.html',
    styleUrls: ['./point-item.component.less'],
})
export class PointItemComponent {
    @Input() point: Post;
    @Input() measurement: Measurement;
    @Input() isActive: boolean;
    @Input() mmtScheme: MeasureScheme;

    measureZones = measureZones;
    orderOfMeasures = orderOfMeasures;
    AqiType = AqiType;
    TEXTS = TEXTS;

    isFalseNumber = isFalseNumber;
}
