import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    NetworkActions,
    selectDateRange,
    selectPriorityDataByMmt,
    selectActiveMmt,
    selectPostDataByMmt,
    selectAnalysisData,
    selectRegionByPostId,
} from '@cityair/modules/network/store/network.feature';
import { DateRangeNetwork } from '@cityair/modules/network/service/api-models';
import { EFFECT_NETWORK, LIST_PRIORITY_LIMIT } from '@cityair/modules/network/constants';
import { NgLocalization } from '@angular/common';
import {
    selectAllPosts,
    selectPostNameById,
} from '@cityair/modules/core/store/posts/posts.feature';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

@Component({
    selector: 'cityair-network-main',
    templateUrl: './network-main.component.html',
    styleUrls: ['./network-main.component.less'],
})
export class NetworkMainComponent implements OnInit, OnDestroy {
    public translateText = TEXTS.NETWORK;
    public title = TEXTS.ADMIN_PANEL.network;
    public ngDestroyed$ = new Subject<void>();
    public isOperator = false;
    text = TEXTS.ANALYSIS;
    textMonitoring = TEXTS.ANALYSIS_EVENTS.monitoring;
    public textNames = TEXTS.NAMES;
    public currentLang = LANGUAGE;
    showInfoPopup = false;
    public dateRange: DateRangeNetwork = null;

    selectPriorityDataByMmt = selectPriorityDataByMmt;
    selectPostDataByMmt = selectPostDataByMmt;
    selectPostNameById = selectPostNameById;
    selectAnalysisData = selectAnalysisData;
    selectRegionByPostId = selectRegionByPostId;

    public sortDirectionPriority = 1;
    public sortFieldPriority: string;
    public sortDirection = 1;
    public sortField: string;
    public activeMmt: string;
    public textSchema = TEXTS.MEASURES_SCHEME[MeasureScheme.default];
    LIST_PRIORITY_LIMIT = LIST_PRIORITY_LIMIT;
    currentLimit = LIST_PRIORITY_LIMIT;
    currentLimitPost = LIST_PRIORITY_LIMIT;
    EFFECT_NETWORK = EFFECT_NETWORK;
    constructor(
        public store: Store,
        private _cdRef: ChangeDetectorRef,
        private ngLocalization: NgLocalization,
        private numberFormatPipe: ValueFormatNumberPipe
    ) {
        this.store
            .select(selectAllPosts)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null && v.length > 0)
            )
            .subscribe(async (group) => {
                if (group) {
                    this.store.dispatch(NetworkActions.initModule());
                }
            });
        this.store
            .select(selectDateRange)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (dates) => {
                this.dateRange = dates;
            });
        this.store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (mmt) => {
                this.currentLimit = LIST_PRIORITY_LIMIT;
                this.currentLimitPost = LIST_PRIORITY_LIMIT;
                this.activeMmt = mmt;
            });
    }

    ngOnInit(): void {
        this.setSortingCbPriority('value');
        this.setSortingCb('value');
    }

    ngOnDestroy(): void {
        this.store.dispatch(NetworkActions.setIsAvailable({ payload: false }));
        this.ngDestroyed$.next();
    }

    toggleLimit(count) {
        this.currentLimit = count;
    }

    public setSortingCbPriority(sortCb: string): void {
        if (this.sortFieldPriority === sortCb) {
            this.sortDirectionPriority *= -1;
        } else {
            this.sortFieldPriority = sortCb;
        }
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    public areaText(num: number = 0) {
        const { areas } = TEXTS.NETWORK;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, areas[category]].join(' ');
    }

    public postText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }

    formatSquare(val: number) {
        return this.numberFormatPipe.transform(val, 1);
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.showInfoPopup) {
            this.showInfoPopup = false;
        }
    }
}
