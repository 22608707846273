import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingComponent } from './onboarding.component';
import { SlidesOnboardingModalComponent } from './components/slides-onboarding-modal/slides-onboarding-modal.component';
import { SlidesUpdateChartModalComponent } from './components/slides-udpate-chart-modal/slides-udpate-chart-modal.component';
import { AddedReportsModalComponent } from './components/added-reports-modal/added-reports-modal.component';
import { SimpleOnboardingModalComponent } from './components/simple-onboarding-modal/simple-onboarding-modal.component';
import { NewMapModalComponent } from './components/new-map-onboarding-modal/new-map-onboarding-modal.component';
import { ButtonSimpleModule } from '../button-simple/button-simple.module';
import { SlideIndicatorModule } from '../slide-indicator/slide-indicator.module';
import { ImageCompareModule } from '../image-compare/image-compare.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { AnalysisModalComponent } from '@cityair/components/onboarding/components/analysis-modal/analysis-modal.component';
import { MapStylesModalComponent } from '@cityair/components/onboarding/components/map-styles-modal/map-styles-modal.component';

@NgModule({
    imports: [
        CommonModule,
        CheckboxModule,
        ButtonSimpleModule,
        SlideIndicatorModule,
        CrossButtonModule,
        ImageCompareModule,
    ],
    exports: [OnboardingComponent],
    declarations: [
        OnboardingComponent,
        SlidesOnboardingModalComponent,
        SimpleOnboardingModalComponent,
        NewMapModalComponent,
        SlidesUpdateChartModalComponent,
        AddedReportsModalComponent,
        AnalysisModalComponent,
        MapStylesModalComponent,
    ],
})
export class OnboardingModule {}
