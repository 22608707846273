<div class="analysis-event-wrapper">
    <div class="header">
        <div class="title-row">
            <div class="title">{{ translateText.mainTitle }}</div>
            <div class="tabs-wrapper" *ngIf="false">
                <ca-page-tabs
                    class="page__page-tabs"
                    [tabs]="tabs"
                    [selectedTab]="getActiveTab()"
                    (showTab)="openPage($event)"
                    [isFullHeight]="true"
                ></ca-page-tabs>
            </div>
            <div class="top-elements">
                <div class="notify-wrapper" *ngIf="isDemoMode">
                    <div class="notify"></div>
                    <button><span class="download-icon"></span>{{translateText.downloadEventsButton}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="empty-row"></div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
