import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityStoreModule } from '@cityair/modules/reports/entity-store.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SearchInputBasicModule } from '@libs/shared-ui/components/search-input-basic/search-input-basic.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { BaseChartDirective } from 'ng2-charts';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { AreaChartModule } from '@libs/shared-ui/components/charts/area-chart/area-chart.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';

import { AnalysisMainComponent } from '@cityair/modules/analysis/components/analysis-main/analysis-main.component';
import { CorrelationComponent } from '@cityair/modules/analysis/components/analysis-main/correlation/correlation.component';
import { AnalysisMapComponent } from '@cityair/modules/analysis/components/analysis-map/analysis-map.component';
import { AnalysisMapElementsComponent } from '@cityair/modules/analysis/components/analysis-map/analysis-map-elements/analysis-map-elements.component';
import { analysisReducers } from '@cityair/modules/analysis/store/reducers';
import { analysisRoutes } from '@cityair/modules/analysis/analysis-routing';
import { MmtMapSelectorComponentModule } from '@cityair/components/mmt-map-selector/mmt-map-selector.module';
import { AnalysisEffects } from '@cityair/modules/analysis/store/effects';

import { CorrelationDetailsComponent } from '@cityair/modules/analysis/components/analysis-main/posts/correlation-details/correlation-details.component';
import { LineChartComponent } from '@cityair/modules/analysis/components/analysis-map/line-chart/line-chart.component';
import { RadarChartModule } from '@libs/shared-ui/components/charts/radar-chart/radar-chart.module';
import { InfoCorrelationPopupModule } from '@libs/shared-ui/components/info-correlation-popup/info-correlation-popup.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { SummaryComponent } from '@cityair/modules/analysis/components/analysis-main/summary/summary.component';
import { PostsComponent } from '@cityair/modules/analysis/components/analysis-main/posts/posts.component';
import { InputRadioSquareModule } from '@libs/shared-ui/components/input-radio-square/input-radio-square.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';

@NgModule({
    declarations: [
        AnalysisMainComponent,
        CorrelationComponent,
        AnalysisMapComponent,
        AnalysisMapElementsComponent,
        CorrelationDetailsComponent,
        LineChartComponent,
        SummaryComponent,
        PostsComponent,
    ],
    exports: [AnalysisMapComponent],
    imports: [
        RouterModule,
        RouterModule.forChild(analysisRoutes),
        CommonModule,
        StoreModule.forFeature('analysis', analysisReducers),
        EffectsModule.forFeature([AnalysisEffects]),
        EntityStoreModule,
        PageTabsModule,
        InputDropdownModule,
        InputDateRangeModule,
        InputModule,
        LoadersModule,
        CrossButtonModule,
        DirectivesModule,
        SelectboxRadioModule,
        PopupAskModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        MmtMapSelectorComponentModule,
        PipesModule,
        AreaChartModule,
        BaseChartDirective,
        RadarChartModule,
        TooltipsModule,
        InfoCorrelationPopupModule,
        CityscreenComponentsModule,
        SearchInputBasicModule,
        SortListItemModule,
        InputRadioSquareModule,
        SearchResultPlaceholderModule,
        SwitchItemModule,
        MapControlModule,
        InputForDropdownModule,
        MmtSelectorModule,
        ColorLegendModule,
        InfoIconModule,
        MapStyleSelectorComponent,
    ],
})
export class AnalysisModule {}
