import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { CardModule } from '@cityair/components/card/card.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { SwitchButtonModule } from '@cityair/components/switch-button/switch-button.module';

import { PlumesCalculationResultsCardModule } from './components/plumes-calculation-results-card/plumes-calculation-results-card.module';
import { PlumesRunSummaryModule } from './components/plumes-run-summary/plumes-run-summary.module';
import { PlumesPage } from './components/plumes-page/plumes-page.component';
import { PlumesSourcesList } from './components/plumes-sources-list/plumes-sources-list.component';
import { PlumesControlPoints } from './components/plumes-control-points/plumes-control-points.component';
import { PlumesCalculationResults } from './components/plumes-calculation-results/plumes-calculation-results.component';
import { PlumesControlPointsCard } from './components/plumes-control-points-card/plumes-control-points-card.component';
import { EditControlPointComponent } from './components/edit-control-point/edit-control-point.component';
import { PlumesCalculation } from './components/plumes-calculation/plumes-calculation.component';
import { PlumesSource } from './components/plumes-source/plumes-source.component';
import { CalculationResultSelectorComponent } from './components/calculation-result-selector/calculation-result-selector.component';
import { PlumesCalculationResultsEditComponent } from './components/plumes-calculation-results-edit/plumes-calculation-results-edit.component';
import { PlumesMapElementsComponent } from './components/plumes-map-elements/plumes-map-elements.component';
import { PlumesMapComponent } from './components/plumes-map/plumes-map.component';
import { PlumesMapMobileComponent } from './components/plumes-map-mobile/plumes-map-mobile.component';
import { PlumesDropdownListComponent } from './components/plumes-dropdown-list/plumes-dropdown-list.component';
import { entityPlumesConfig } from './entity-metadata';
import { EntityStorePlumesModule } from './entity-store.module';
import { plumesReducers } from './store/reducers';
import { PlumesEffects } from './store/effects';
import { PLUMES_FEATURE_KEY } from './constants';
import { DownloadBtnModule } from '@cityair/libs/shared/components/download-btn/download-btn.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { PlumesRunTimePipe } from '@cityair/modules/plumes/plumes-run.pipe';
import { HeightsSelectorModule } from '@libs/shared-ui/components/heights-selector/heights-selector.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        SharedComponentsModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        InputForCoordinateModule,
        CalendarMonthsModule,
        LoadersModule,
        CityscreenComponentsModule,
        OverlayModule,
        TimelinePanelModule,
        DownloadBtnModule,
        SwitchItemModule,
        AqiDetailsTooltipModule,
        StoreModule.forFeature(PLUMES_FEATURE_KEY, plumesReducers),
        EffectsModule.forFeature([PlumesEffects]),
        EntityDataModule.forRoot(entityPlumesConfig),
        EntityStorePlumesModule,
        PlumesCalculationResultsCardModule,
        PlumesRunSummaryModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        HeightsSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
    ],
    exports: [PlumesPage, PlumesMapComponent, PlumesMapMobileComponent],
    declarations: [
        PlumesPage,
        PlumesSourcesList,
        PlumesCalculationResults,
        PlumesControlPoints,
        PlumesControlPointsCard,
        EditControlPointComponent,
        PlumesCalculation,
        PlumesSource,
        CalculationResultSelectorComponent,
        PlumesCalculationResultsEditComponent,
        PlumesMapElementsComponent,
        PlumesMapComponent,
        PlumesMapMobileComponent,
        PlumesDropdownListComponent,
        PlumesRunTimePipe,
    ],
})
export class PlumesModule {}
