import { ModulePageConfig } from '@cityair/namespace';

export const forecastModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: false,
    enableCalendar: true,
    enablePlumeButton: true,
    enableAqi: false,

    enableMap: true,
    enableOldTimeline: false,
    enableTopElements: true,
    fullScreen: true,
};
