import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { debounceTime, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TEXTS } from '@libs/common/texts/texts';
import { getAssetPath } from '@cityair/config';

@Component({
    selector: 'instant-aqi-faq',
    templateUrl: 'instant-aqi-faq.component.html',
    styleUrls: ['instant-aqi-faq.component.less'],
})
export class InstantAqiFaqComponent implements OnInit, OnDestroy {
    @Output() closeModal = new EventEmitter<void>();

    @ViewChild('article', { static: true }) article: ElementRef<HTMLElement>;

    TEXTS = TEXTS;

    aqiDangerLevels = [
        TEXTS.AQI_DETAILED_INFO.dangerLevelLow,
        TEXTS.AQI_DETAILED_INFO.dangerLevelMedium,
        TEXTS.AQI_DETAILED_INFO.dangerLevelHigh,
    ];

    menuItems = [
        'Что такое индекс Instant AQI',
        'Что такое индекс качества воздуха?',
        'Что такое EPA AQI?',
        'Почему мы отказались от индекса EPA AQI',
        'Почему мы выбрали Instant AQI',
        'Почему данные с постов мониторинга CityAir выводятся в AQI, а не российском индексе ИЗА?',
    ];

    selectedSection = 0;

    private sections: Element[] = [];
    private sectionsHeaders: Element[] = [];

    private boundaries: {
        top: number;
        bottom: number;
    } = null;

    private onDestroy$ = new Subject<void>();

    ngOnInit() {
        const sections = document.querySelectorAll('.instant-aqi-faq__section');
        this.sections = Array.from(sections);

        const sectionsHeaders = document.querySelectorAll('.instant-aqi-faq__section-header');
        this.sectionsHeaders = Array.from(sectionsHeaders);

        this.updateBounds();

        fromEvent(this.article.nativeElement, 'scroll')
            .pipe(debounceTime(100), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.updateScroll();
            });

        fromEvent(window, 'resize')
            .pipe(debounceTime(100), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.updateBounds();
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    updateBounds() {
        const rect = this.article.nativeElement.getBoundingClientRect();

        if (rect) {
            const { top, bottom } = rect;
            this.boundaries = { top, bottom };
        }
    }

    updateScroll() {
        const lastIndex = this.sections.length - 1;
        const lastSection = this.sections[lastIndex];

        // the last section entirely inside of the view
        if (lastSection.getBoundingClientRect().bottom <= this.boundaries.bottom) {
            this.selectedSection = lastIndex;
            return;
        }

        let index = this.sections.findIndex((s) => {
            const { top, bottom } = s.getBoundingClientRect();
            // section's title is inside of the view
            return top <= this.boundaries.top && bottom >= this.boundaries.bottom;
        });

        if (index === -1) {
            index = this.sectionsHeaders.findIndex((sh) => {
                const { bottom } = sh.getBoundingClientRect();
                // section fills all of the view
                return bottom >= this.boundaries.top && bottom <= this.boundaries.bottom;
            });
        }

        if (index !== -1) {
            this.selectedSection = index;
        }
    }

    close() {
        this.closeModal.emit();
    }

    getAsset = getAssetPath;

    showSection(index: number) {
        const header = this.sectionsHeaders[index];

        if (header) {
            this.selectedSection = index;

            const { nativeElement } = this.article;

            // stop momentum scrolling
            nativeElement.style.overflow = 'hidden';

            nativeElement.scrollTop = 0;

            setTimeout(() => {
                nativeElement.style.overflow = '';
            }, 10);

            header.scrollIntoView({
                block: 'start',
            });
        }
    }
}
