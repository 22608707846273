import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
@Component({
    selector: 'input-dropdown',
    templateUrl: 'input-dropdown.component.html',
    styleUrls: ['input-dropdown.component.less'],
    animations: ANIMATION_OPACITY,
})
export class InputDropdownComponent {
    @Input() inputText: string;
    @Input() label: string;
    @Input() isDisable = false;
    @Input() textError = '';
    @Input() textDescription = '';
    @Input() showDropdown: boolean;
    @Input() showLabelInfoIcon = false;

    @Output() clickInfo = new EventEmitter();
    @Output() showDropdownChange = new EventEmitter<boolean>();

    toggleDropdown() {
        this.showDropdownChange.emit(!this.showDropdown);
    }

    clickIcon() {
        this.clickInfo.emit();
    }
}
