import { Injectable } from '@angular/core';
import { REFRESH_INTERVAL } from '../constant';
import { Store } from '@ngrx/store';
import { updateListByStatus } from '../store/actions';

@Injectable({
    providedIn: 'root',
})
export class UpdaterService {
    private _interval: NodeJS.Timeout;
    private _dateTimeObj: Date = new Date();
    private _lastExecute: number = this._dateTimeObj.getTime();
    private _currentTime: number = null;
    constructor(private store: Store) {
        this.init();
    }

    public init(): void {
        if (this._interval) {
            return;
        }

        this._dateTimeObj = new Date();
        this._currentTime = this._dateTimeObj.getTime();

        if (this._currentTime - this._lastExecute >= REFRESH_INTERVAL) {
            this._refresher();
        }

        this._interval = setInterval(() => {
            this._refresher();
        }, REFRESH_INTERVAL);
    }

    public stop(): void {
        clearInterval(this._interval);

        this._interval = null;
    }

    private _refresher() {
        this._dateTimeObj = new Date();
        this._lastExecute = this._dateTimeObj.getTime();
        this.store.dispatch(updateListByStatus());
    }
}
