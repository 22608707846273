import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
    TemplateRef,
    ViewChildren,
    QueryList,
    Directive,
} from '@angular/core';

import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';

import { NotificationSubscribeType } from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { first, tap } from 'rxjs';
import { selectTimeRange, selectUserId } from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NotificationSettings, FeedItemsExportRequest } from '@libs/common/models/basicModels';
import {
    deleteNotificationSettings,
    downloadHistory,
    editNotificationSettingsActive,
    openSettingForm,
} from '@cityair/modules/notifications/store/actions';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import * as moment from 'moment-timezone';

@Component({
    selector: 'subscription-card',
    templateUrl: 'subscription-card.component.html',
    styleUrls: ['subscription-card.component.less'],
})
export class SubscriptionCard implements OnInit, OnDestroy {
    @Input() subscription: NotificationSettings;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    private popupOpenerElement: HTMLElement;

    isDeletePopupOpen = false;
    isDownloadPopupOpen = false;
    TEXTS = TEXTS;
    textsNotification = TEXTS.NOTIFICATIONS;
    textsDownloadPopup = TEXTS.DOWNLOAD_POPUP;

    subscriptionStatus: string;
    popupActive = false;
    popupPositionTop = 0;
    subscriptionTypes = NotificationSubscribeType;

    timeBegin: number;
    timeEnd: number;

    isShownInEventFeed = false;
    userId: string;

    constructor(
        public basicApi: BasicApi,
        public popupProvider: OffPanelPopupService,
        private store: Store
    ) {
        this.store
            .select(selectUserId)
            .pipe(
                first(),
                filter((v) => !!v)
            )
            .subscribe((id) => (this.userId = id));
    }

    ngOnInit() {
        this.updateTime()
            .pipe(first())
            .subscribe(() => {
                // this.isShownInEventFeed = this.subscription.forUserIds.includes(this.userId);
            });
    }

    includeInEventFeed(e: Event, include: boolean) {
        this.closePopup(e);
    }

    editSubscription() {
        this.store.dispatch(
            openSettingForm({
                settings_type: this.subscription.type,
                currentSetting: this.subscription,
            })
        );
    }

    activateSubscription(e: Event) {
        this.closePopup(e);
        const settings = { ...this.subscription, is_active: true };
        this.store.dispatch(editNotificationSettingsActive({ payload: settings }));
    }

    deactivateSubscription(e: Event) {
        this.closePopup(e);
        const settings = { ...this.subscription, is_active: false };
        this.store.dispatch(editNotificationSettingsActive({ payload: settings }));
    }

    deleteSubscriptionDialog(e: Event) {
        this.closePopup(e);
        this.isDeletePopupOpen = true;
    }

    deleteSubscription() {
        this.store.dispatch(deleteNotificationSettings({ payload: this.subscription }));
    }

    downloadFeedHistoryDialog(e: Event) {
        this.closePopup(e);
        this.isDownloadPopupOpen = true;
    }

    deleteSubscriptionAccept = () => {
        this.isDeletePopupOpen = false;
        this.deleteSubscription();
    };

    deleteSubscriptionCancel = () => {
        this.isDeletePopupOpen = false;
    };

    downloadFeedHistoryAccept = () => {
        this.isDownloadPopupOpen = false;
        this.downloadFeedHistory();
    };

    downloadFeedHistoryCancel = () => {
        this.updateTime()
            .pipe(first())
            .subscribe(() => {
                this.isDownloadPopupOpen = false;
            });
    };

    openPopup({ target, positionTop }) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.popupOutlet, () => (this.popupActive = false));
        this.popupOpenerElement = target;
        this.popupPositionTop = positionTop;
        this.popupActive = true;
    }

    closePopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupActive = false;
        }
    }

    private updateTime = () =>
        this.store.pipe(selectTimeRange).pipe(
            tap((time) => {
                this.timeBegin = time.begin;
                this.timeEnd = time.end;
            })
        );

    downloadFeedHistory() {
        const offset = moment().utcOffset();
        const params: FeedItemsExportRequest = {
            NotificationId: this.subscription.id,
            TimeBegin: moment(this.timeBegin).startOf('day').toISOString(),
            TimeEnd: moment(this.timeEnd).endOf('day').toISOString(),
            TimeZone: offset ? offset / 60 : 0,
            Lang: LANGUAGE,
        };
        this.store.dispatch(downloadHistory({ payload: params }));
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }
}

@Directive()
export class ClosePopupsHelper {
    @ViewChildren(SubscriptionCard)
    popupHolders: QueryList<SubscriptionCard>;

    onScroll(e: Event) {
        this.popupHolders.find((c) => c.popupActive)?.closePopup(e);
    }
}
