<ng-template #markerTooltip>
    <ca-info-tooltip [text]="pinTooltipText"></ca-info-tooltip>
</ng-template>

<mgl-map
    class="mapbox-map"
    movingMethod="jumpTo"
    [style.visibility]="showMap ? 'visible' : 'hidden'"
    [style]="style"
    [trackResize]="true"
    [zoom]="[zoom || mapSettings.zoom]"
    [minZoom]="mapSettings.minzoom"
    [maxZoom]="mapSettings.maxzoom"
    [center]="center || mapSettings.center"
    [maxBounds]="mapSettings.bounds"
    [transformRequest]="authorizeTileRequest"
    [pitchWithRotate]="false"
    [dragRotate]="false"
    [renderWorldCopies]="true"
    [attributionControl]="false"
    (mapCreate)="mapCreate($event)"
    (mapLoad)="mapboxLoad($event)"
    (zoomEvt)="onZoom($event.target.getZoom())"
    (moveStart)="moveStart()"
    (moveEnd)="moveEnd()"
    (mapDragEnd)="mapDragEndHandler($event)"
    (mapClick)="clickedMap($event)"
>
    <mgl-control mglNavigation [showZoom]="false" [showCompass]="false"></mgl-control>

    <mgl-control mglAttribution [compact]="true" [customAttribution]="'© CityAir'"></mgl-control>

    <!-- Forecast (public) -->
    <ng-container
        *ngIf="!isStyleLoading && (store.select(selectIsShowOnMapPublicForecast) | async) && domainTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesPlayer?.config as cfg">
            <mgl-image-source
                [id]="cfg.domain.slug"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                [id]="cfg.domain.slug"
                [source]="cfg.domain.slug"
                [minzoom]="cfg.minzoom"
                [maxzoom]="cfg.maxzoom"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Lens Forecast (public) -->
    <ng-container
        *ngIf="!isStyleLoading && (store.select(selectIsShowOnMapLensForecast) | async) && lensTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="lensTilesPlayer?.config as cfg">
            <mgl-image-source
                [id]="cfg.domain.slug"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                [id]="cfg.domain.slug"
                [source]="cfg.domain.slug"
                [minzoom]="cfg.minzoom"
                [maxzoom]="cfg.maxzoom"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Custom layers -->
    <ng-container *ngFor="let layer of enabledExtraLayers">
        <ng-container *ngIf="!isStyleLoading">
            <mgl-raster-source
                [id]="layer.id"
                [tiles]="layer.source.tiles"
                [tileSize]="layer.source.tileSize"
                [minzoom]="mapSettings.minzoom"
                [maxzoom]="mapSettings.maxzoom"
            ></mgl-raster-source>
            <mgl-layer [id]="layer.id" [type]="layer.source.type" [source]="layer.id"></mgl-layer>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let layer of tilePlayers.default?.layersWindow?.window; index as i">
        <ng-container *ngIf="isGreaterThanMinZoom(layer.source.minzoom) && !isStyleLoading">
            <mgl-raster-source
                [id]="layer.id"
                [tiles]="layer.source.tiles"
                [tileSize]="layer.source.tileSize"
                [bounds]="layer.source.bounds"
                [minzoom]="layer.source.minzoom"
                [maxzoom]="layer.source.maxzoom"
            ></mgl-raster-source>
            <mgl-layer
                [id]="layer.id"
                [type]="layer.source.type"
                [source]="layer.id"
                [paint]="{
                    'raster-opacity': layer.opacity,
                    'raster-fade-duration': timeStep
                }"
            ></mgl-layer>

            <domain-border
                *ngIf="i === 0 && (tilePlayers.default.layers | slice : -1)[0] as lastLayer"
                [bbox]="lastLayer?.source.bounds"
            ></domain-border>
        </ng-container>
    </ng-container>

    <!-- Run Sources -->
    <ng-container *ngIf="sourcePins?.getPins | async as sources">
        <mgl-marker
            *ngFor="let source of sources"
            [className]="
                [
                    'mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker--global'
                ].join(' ')
            "
            [lngLat]="[source?.centroid?.coordinates[0], source?.centroid?.coordinates[1]]"
            [anchor]="(impactAvailable | async) ? 'bottom': 'center'"
        >
            <ng-container *ngIf="(impactAvailable | async) else defaultSourcePin">
                <cityair-impact-source-pin
                    [name]="source.name"
                    [id]="source.id"
                    [isCollapse]="sourcePins.isCollapsed(source.id) | async"
                    (mouseenter)="pinTooltipText = isTouchDevice ? '' : source.name"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                ></cityair-impact-source-pin>
            </ng-container>
            <ng-template #defaultSourcePin>
                <plume-source-marker [name]="source.name"></plume-source-marker>
            </ng-template>
        </mgl-marker>
    </ng-container>

    <!-- Region Pins -->
    <!--<ng-container *ngIf="regionPins?.getPins | async as regions">
        <mgl-marker
            *ngFor="let region of regions"
            [className]="
                [
                    'mapboxgl-marker',
                    'region-mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker&#45;&#45;global'
                ].join(' ')
            "
            [lngLat]="[region?.centroid[1], region?.centroid[0]]"
            [anchor]="'bottom'"
        >
            <ng-container *ngIf="(impactAvailable | async)">
                <impact-region-pin
                    [name]="region.name"
                    [id]="region.id"
                    [value]="store.select(getRegionImpactValue(region.id)) | async"
                    [zoom]="currentZoom"
                ></impact-region-pin>
            </ng-container>
        </mgl-marker>
    </ng-container>-->

    <!-- Forecast (private) -->
    <ng-container
        *ngIf="!isStyleLoading && (showForecastLayer$ | async) && domainTilesForecastPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesForecastPlayer.config as cfg">
            <mgl-image-source
                id="forecast-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="forecast-layer"
                source="forecast-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Plumes -->
    <ng-container
        *ngIf="!isStyleLoading && (showPlumesLayer$ | async) && domainTilesPlumesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="domainTilesPlumesPlayer.config as cfg">
            <mgl-image-source
                id="plumes-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="plumes-layer"
                source="plumes-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- Impact -->
    <ng-container
        *ngIf="!isStyleLoading && (showImpactLayer$ | async) && impactTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="impactTilesPlayer.config as cfg">
            <mgl-image-source
                id="impact-tiles-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="impact-layer-data"
                source="impact-tiles-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!-- NEW  --------------------------------------------------------------------------------------------------------------- -->
    <ng-container
        *ngIf="!isStyleLoading && (showPlumesLayer$ | async) && plumesTilesPlayer?.imageUrl$ | async as url"
    >
        <ng-container *ngIf="plumesTilesPlayer.config as cfg">
            <mgl-image-source
                id="plumes-tiles-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="plumes-layer-data"
                source="plumes-tiles-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
    <!--Network ----------------------------->
    <ng-container *ngIf="!isStyleLoading && (store.select(selectIsShowNetworkGridLayer) | async)">
        <domain-border
            *ngIf="store.select(selectDomain) | async as bbox"
            [bbox]="bbox"
            [onlyBottom]="true"
        ></domain-border>
        <ng-container *ngIf="store.select(selectPolygons) | async as dataNetwork">
            <mgl-geojson-source
                id="graticule-json"
                [data]="dataNetwork?.polygon"
            ></mgl-geojson-source>
            <mgl-layer
                id="graticule-json-line"
                type="fill"
                source="graticule-json"
                [paint]="{
                    'fill-color': ['get', 'color'],
                    'fill-opacity': ['get', 'opacity'],
                    'fill-outline-color': ['get', 'border']
                }"
            ></mgl-layer>
            <ng-container *ngFor="let scale of dataNetwork?.scales">
                <mgl-image-source
                    [id]="scale.id"
                    [url]="scale.url"
                    [coordinates]="scale.coordinates"
                ></mgl-image-source>
                <mgl-layer [id]="'scale-layer' +scale.id " [type]="'raster'" [source]="scale.id"></mgl-layer>
            </ng-container>
        </ng-container>
    </ng-container>
    <!-- Group layers -->
    <ng-container *ngIf="!isStyleLoading && groupFeaturesLayer">
        <mgl-geojson-source
            id="group-features-json"
            [data]="groupFeaturesLayer"
        ></mgl-geojson-source>
        <mgl-layer
            id="group-features-layer-line"
            type="line"
            source="group-features-json"
            [paint]="{
                'line-color': ['get', 'color'],
                'line-opacity': ['get', 'opacity'],
                'line-width': 2
            }"
        ></mgl-layer>
    </ng-container>

    <!-- Marker area -->
    <ng-container *ngIf="pinsAreaData && showMarkersArea">
        <!-- Maximum buffer size fixes overlapping artifacts at the maximum zoom level (mostly). -->
        <mgl-geojson-source
            id="markers-area-data"
            [data]="pinsAreaData | async"
            [buffer]="512"
        ></mgl-geojson-source>
        <mgl-layer
            id="markers-area"
            type="fill"
            source="markers-area-data"
            [paint]="{
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity'],
                'fill-outline-color': 'transparent'
            }"
        >
        </mgl-layer>
    </ng-container>

    <!-- Regular markers -->
    <ng-container *ngIf="(impactAvailable | async) else defaultPin">
        <ng-container  *ngIf="postPins?.getExpand | async as expanded">
            <ng-container *ngIf="postPins?.getSelectedPinIds | async as selected">
                <mgl-marker
                    *ngFor="let pin of postPins?.getPins | async"
                    [className]="
                        [
                            'mapboxgl-marker',
                            'map__mapbox-marker&#45;&#45;global',
                            expanded.includes(pin.id) ? 'map__mapbox-marker&#45;&#45;global-expand'
                                : '',
                            selected.includes(pin.id)
                                ? 'map__mapbox-marker&#45;&#45;global-selected_without_top'
                                : ''
                        ].join(' ')
                    "
                    [lngLat]="[pin.geometry?.coordinates[0], pin.geometry?.coordinates[1]]"
                    [anchor]="'bottom'"
                >
                     <cityair-impact-post-pin
                        *ngIf="{ a: postPins.getValue(pin) | async } as val"
                        [value]="val.a"
                        [numberAfterDot]="(postPins.selectDigitsAfterDot | async) ?? 1"
                        [color]="postPins.getColor(pin) | async"
                        [selected]="selected.includes(pin.id)"
                        [station]="pin"
                        [isExpand]="expanded.includes(pin.id)"
                        [isLoading]="store.select(selectIsLoadingContributionData) | async"
                        [sources]="postPins.getSources() | async"
                        [isCollapse]="postPins.isCollapsed(pin.id) | async"
                        [error]="store.select(selectErrorContributionData) | async"
                        [dataAdditional]="store.select(selectActiveStation) | async"
                        [currentIndex]="postPins.getCurrentIndex() | async"
                        [mmtData]="postPins.getMeasureScheme() | async"
                        (setSelect)="postPins.clickCb(pin)"
                        (setExpand)="postPins.setExpand($event)"
                        (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
                        caTooltip
                        caTooltipPlacement="left"
                        [caTooltipFollowPointer]="true"
                        [caTooltipTemplate]="markerTooltip"
                    ></cityair-impact-post-pin>
                </mgl-marker>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #defaultPin>
        <ng-container *ngIf="postPins?.getSelectedPinIds | async as selected">
            <mgl-marker
                *ngFor="let pin of postPins?.getPins | async"
                [className]="
                    [
                        'mapboxgl-marker',
                        'mapboxgl-marker-anchor-center',
                        'map__mapbox-marker--global',
                        'map__mapbox-marker--global-pin',
                        selected.includes(pin.id) ? 'map__mapbox-marker--global-selected' : ''
                    ].join(' ')
                "
                [lngLat]="[pin.geometry?.coordinates[0], pin.geometry?.coordinates[1]]"
            >
                <post-pin
                    *ngIf="{ a: postPins.getValue(pin) | async } as val"
                    [value]="val.a"
                    [numberAfterDot]="(postPins.selectDigitsAfterDot | async) ?? 1"
                    [color]="postPins.getColor(pin) | async"
                    [selected]="selected.includes(pin.id)"
                    [state]="getMarkerState(pin.id) | async"
                    (click)="postPins.clickCb(pin)"
                    (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                ></post-pin>
            </mgl-marker>
        </ng-container>
    </ng-template>
    <!-- City markers -->
    <ng-container *ngIf="cityPins?.getSelectedPinIds | async as selected">
        <mgl-marker
            *ngFor="let pin of cityPins?.getPins | async"
            [className]="
                [
                    'mapboxgl-marker',
                    'mapboxgl-marker-anchor-center',
                    'map__mapbox-marker--global',
                    'map__mapbox-marker--global-city',
                    selected.includes(pin.id) ? 'map__mapbox-marker--global-selected' : ''
                ].join(' ')
            "
            [lngLat]="[pin?.geometry?.coordinates[0], pin?.geometry?.coordinates[1]]"
        >
            <city-pin
                *ngIf="{ a: cityPins.getValue(pin) | async } as val"
                [city]="pin.name"
                [value]="val.a"
                [numberAfterDot]="(cityPins.selectDigitsAfterDot | async) ?? 1"
                [color]="cityPins.getColor(pin) | async"
                [selected]="selected.includes(pin.id)"
                [state]="getMarkerState(pin.id) | async"
                (click)="cityPins.clickCb(pin)"
            ></city-pin>
        </mgl-marker>
    </ng-container>

    <!-- Markers selected for notifications  -->
    <mgl-marker
        *ngFor="let pin of notificationSelectedPins?.getPins | async"
        [className]="
            [
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                'map__mapbox-marker--global-pin',
                'map__mapbox-marker--global-selected'
            ].join(' ')
        "
        [lngLat]="[pin?.geometry?.coordinates[0], pin?.geometry?.coordinates[1]]"
    >
        <post-pin
            *ngIf="{ a: notificationSelectedPins.getValue(pin) | async } as val"
            [value]="val.a"
            [numberAfterDot]="(notificationSelectedPins.selectDigitsAfterDot | async) ?? 1"
            [color]="postPins.getColor(pin) | async"
            [selected]="true"
            [state]="markerState.selectPost"
            (click)="notificationSelectedPins.clickCb(pin)"
            (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="markerTooltip"
        ></post-pin>
    </mgl-marker>

    <!-- Control points -->
    <ng-container *ngIf="(impactAvailable | async) === false else controlPointContribution">
        <ng-container *ngIf="controlPointPins?.getSelectedPinIds | async as selected">
            <mgl-marker
                *ngFor="let point of controlPointPins?.getPins | async"
                [className]="
                    [
                        'mapboxgl-marker',
                        'map__mapbox-marker--global',
                        selected.includes(point.id)
                            ? 'map__mapbox-marker--global-selected_without_top'
                            : ''
                    ].join(' ')
                "
                [lngLat]="[point.lon, point.lat]"
                [draggable]="controlPointPins.isDraggable(point) | async"
                (markerDragEnd)="controlPointPins.dragEnd($event, point)"
                [anchor]="'bottom'"
            >
                <control-point-pin
                    *ngIf="{ a: controlPointPins.getValue(point) | async } as val"
                    [value]="val.a"
                    [color]="controlPointPins.getColor(point) | async"
                    [numberAfterDot]="(controlPointPins.selectDigitsAfterDot | async) ?? 1"
                    [name]="point?.name"
                    [selected]="selected.includes(point.id)"
                    [draggable]="controlPointPins.isDraggable(point) | async"
                    [showArrowWD]="controlPointPins.isWD |async"
                    (click)="controlPointPins.clickCb(point)"
                    (mouseenter)="pinTooltipText = isTouchDevice ? '' : point.name"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipHidden]="controlPointPins.isDraggable(point) | async"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                ></control-point-pin>
            </mgl-marker>
        </ng-container>
    </ng-container>
    <ng-template #controlPointContribution>
    <!-- Control points Plumes with contributions pop up-->
        <ng-container *ngIf="controlPointPins?.getSelectedPinIds | async as selected">
            <ng-container  *ngIf="controlPointPins?.getExpand | async as expanded">
                <mgl-marker
                    *ngFor="let point of controlPointPins?.getPins | async"
                    [className]="
                        [
                            'mapboxgl-marker',
                            'map__mapbox-marker&#45;&#45;global',
                            selected.includes(point.id)
                                ? 'map__mapbox-marker&#45;&#45;global-selected_without_top'
                                : '',
                            expanded.includes(point.id) ? 'map__mapbox-marker&#45;&#45;global-expand'
                                : ''
                        ].join(' ')
                    "
                    [lngLat]="[point.lon, point.lat]"
                    [draggable]="controlPointPins.isDraggable(point) | async"
                    (markerDragEnd)="controlPointPins.dragEnd($event)"
                    [anchor]="'bottom'"
                >
                    <cityair-control-point-plume-pin
                        [point]="point"
                        [sources]="controlPointPins.getSources() | async"
                        [numberAfterDot]="(controlPointPins.selectDigitsAfterDot | async) ?? 1"
                        [currentIndex]="controlPointPins.getCurrentIndex() | async"
                        [mmtData]="controlPointPins.getMeasureScheme() | async"
                        [decimalFormat]="false"
                        [isExpand]="expanded.includes(point.id)"
                        [selected]="selected.includes(point.id)"
                        [isCollapse]="controlPointPins.isCollapse | async"
                        [draggable]="controlPointPins.isDraggable(point) | async"
                        (mouseenter)="pinTooltipText = isTouchDevice ? '' : point.name"
                        (setSelect)="controlPointPins.clickCb($event)"
                        (setExpand)="controlPointPins.setExpand($event)"
                        caTooltip
                        caTooltipPlacement="left"
                        [caTooltipHidden]="(controlPointPins.isDraggable(point) | async) || expanded.includes(point.id) "
                        [caTooltipFollowPointer]="true"
                        [caTooltipTemplate]="markerTooltip"
                    ></cityair-control-point-plume-pin>
                </mgl-marker>
            </ng-container>
        </ng-container>
    </ng-template>
    <!-- Markers for analysis  -->
    <ng-container *ngIf="correlationPins?.getSelectedPinIds | async as selected">
        <ng-container  *ngIf="correlationPins?.getHover | async as hovered">
            <mgl-marker
                *ngFor="let pin of correlationPins?.getPins | async"
                 [className]="
                    [
                        'mapboxgl-marker',
                        'mapboxgl-marker-anchor-center',
                        'map__mapbox-marker--global',
                        'map__mapbox-marker--global-pin',
                        selected.includes(pin.id) ? 'map__mapbox-marker--global-selected' : '',
                        hovered.includes(pin.id) ? 'map__mapbox-marker--global-hovered' : '',
                    ].join(' ')
                "
                [lngLat]="[pin?.geometry?.coordinates[0], pin?.geometry?.coordinates[1]]"
            >
                <ng-container *ngIf="store.select(selectIsActiveNetwork) | async else analysisTemp">
                    <cityair-network-pin
                        [color]="correlationPins?.getColor(pin) | async"
                        [selected]="selected.includes(pin.id)"
                        (mouseenter)="pinTooltipText = isTouchDevice ? '' : pin.name"
                        caTooltip
                        caTooltipPlacement="left"
                        [caTooltipFollowPointer]="true"
                        [caTooltipTemplate]="markerTooltip"
                    ></cityair-network-pin>
                </ng-container>
                <ng-template #analysisTemp>
                    <cityair-correlation-pin
                        [value]="correlationPins.getValue(pin)| async"
                        [color]="selected.includes(pin.id)? '#653CD8' : '#404655'"
                        [selected]="selected.includes(pin.id)"
                        [notActive]="selected.length === 2 && !selected.includes(pin.id)"
                        [valueMode]="correlationPins?.isValueMode | async"
                        (click)="correlationPins.clickCb(pin)"
                        caTooltip
                        caTooltipPlacement="left"
                        [caTooltipFollowPointer]="true"
                        [caTooltipTemplate]="correlationPinTooltip"
                    ></cityair-correlation-pin>
                    <ng-template #correlationPinTooltip>
                        <ca-info-tooltip *ngIf="!isTouchDevice" [text]="(correlationPins.getTooltip(pin) | async)"></ca-info-tooltip>
                    </ng-template>
                </ng-template>
            </mgl-marker>
        </ng-container>
    </ng-container>
     <!-- Line beetween correlation pins for analysis module  -->
    <ng-container *ngIf="sourceLine?.getHoverLine | async as activeLine">
        <ng-container *ngIf="sourceLine?.getLine | async as data">
            <ng-container *ngFor="let line of data; let i = index">
                 <mgl-geojson-source
                    id="correlation-{{i}}"
                    [data]="line"
                 ></mgl-geojson-source>
                 <mgl-layer
                     id="correlation-layer-{{i}}"
                     type="line"
                     source="correlation-{{i}}"
                     [paint]="{
                         'line-color': ['get', 'color'],
                         'line-width':
                                activeLine.length
                                 && line.properties.first === activeLine[0].properties.first
                                 && line.properties.second === activeLine[0].properties.second
                                 ? 7 : ['get', 'width']
                     }"
                     (layerMouseOver)="map.getCanvas().style.cursor = 'pointer';sourceLine?.mouseEnter($event, line)"
                     (layerMouseOut)="map.getCanvas().style.cursor = '';sourceLine?.mouseLeave()"
                     (layerMouseMove)="sourceLine?.mouseMove($event, line)"
                 ></mgl-layer>
            </ng-container>
       </ng-container>
    </ng-container>
    <ng-container *ngIf="polygon?.getPolygon | async as dataPolygon">
        <mgl-geojson-source
            id="polygon"
            [data]="dataPolygon"
        ></mgl-geojson-source>
        <mgl-layer
            id="polygon-layer"
            type="fill"
            source="polygon"
            [paint]="{
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity'],
                'fill-outline-color': ['get', 'border']
            }"
        ></mgl-layer>
    </ng-container>
    <ng-container *ngIf="infoPins?.getPins | async as infoPinsNetwork">
        <mgl-marker *ngFor="let pin of infoPinsNetwork"
            [lngLat]="[pin.lat, pin.lon]"
            [ngClass]="{
                'mapboxgl-marker mapboxgl-marker-anchor-center map__mapbox-marker--global map__mapbox-marker--global-pin': pin.type === 'add'
            }"
        >
            <cityair-info-pin [name]="pin.name" [color]="pin.color" [type]="pin.type"></cityair-info-pin>
        </mgl-marker>
    </ng-container>
    <ng-container *ngIf="!isStyleLoading && impactAvailable | async">
        <ng-container *ngIf="showImpactLayer$ | async">
            <mgl-geojson-source
                id="demo-impact-border"
                [data]="DEMO_IMPACT_POLYGON"
            ></mgl-geojson-source>
            <mgl-layer
                id="demo-impact-layer"
                type="line"
                source="demo-impact-border"
                [paint]="{
                    'line-color': ['get', 'color'],
                    'line-width': 2,
                    'line-dasharray': ['get', 'dasharray']
                }"
            ></mgl-layer>
        </ng-container>
        <mgl-geojson-source
            id="region-border"
            [data]="store.select(selectImpactRegionsForMap) | async"
        ></mgl-geojson-source>
        <mgl-layer
            id="region-layer"
            type="line"
            source="region-border"
            [paint]="{
                'line-color': ['get', 'color'],
                'line-width': 2,
                'line-dasharray': ['get', 'dasharray']
            }"
        ></mgl-layer>
    </ng-container>
</mgl-map>

