import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventDateRange } from '@libs/common/models/basicModels';

@Component({
    selector: 'shared-ui-basic-date-range',
    templateUrl: './basic-date-range.component.html',
    styleUrls: ['./basic-date-range.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicDateRangeComponent {
    @Input() dateRange: EventDateRange;
}
