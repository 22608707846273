import { Observable } from 'rxjs';
import { LngLat } from 'mapbox-gl';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { AQI, HUM, PRES, TEMP } from '@libs/common/consts/substance.consts';
import { Locality, BasicDataResponse, RegionApi } from '@libs/common/models/basicModels';
import { Geometry } from '@libs/common/models/feature';
import { ColorZone } from '@libs/common/types/color-zone';
import { Source } from '@cityair/modules/plumes/services/run/models';
export enum SeasonsName {
    Year = 'Year',
    Winter = 'Winter',
    Spring = 'Spring',
    Summer = 'Summer',
    Autumn = 'Autumn',
}

export const CHART_TYPE_TIMELINE = 'CHART_TYPE_TIMELINE';
export const CHART_TYPE_TIMELINE_M = 'CHART_TYPE_TIMELINE_M';
export const CHART_TYPE_WIDGET = 'CHART_TYPE_WIDGET';
export const CHART_NAME_TIMELINE = 'CHART_NAME_TIMELINE';
export const CHART_NAME_MONITOR = 'CHART_NAME_MONITOR';

export class WindowGlobalVars extends Window {
    JS_CP_LOGIN: string;
    JS_CP_TOKEN: string;
    JS_CP_SITE_LANG: string;
    JS_CP_APP_TITLE: string;
    CURRENT_MAP_PROVIDER: string;
    LOGIN_STYLE: 'sakhalin' | 'demo-cabinet' | 'spb112' | 'nornickel' | 'mnrecology' | 'sparrow';

    state: any;
    plumeState: any;
    clusterer: any;
    commonLoader: HTMLDivElement;
    visualViewport: any;
}

export class Bounds {
    north: number;
    south: number;
    east: number;
    west: number;
}

export class CityCard {
    city: Locality;
}

export interface DistributionAqi {
    aqi: number;
    percent: number;
}

export interface DistributionDayHour {
    hourOfDay: number;
    aqi: number;
}

export interface DistributionWeekDay {
    dayOfWeek: number;
    aqi: number;
}

export interface Season {
    firstPacketDate: Date;
    distributionAqi: DistributionAqi[];
    distributionDayHour: DistributionDayHour[];
    distributionWeekDay: DistributionWeekDay[];
}

export interface DistributionSummary {
    [SeasonsName.Year]: Season;
    [SeasonsName.Winter]: Season;
    [SeasonsName.Spring]: Season;
    [SeasonsName.Summer]: Season;
    [SeasonsName.Autumn]: Season;
}

export class City_model implements DataForCreateSeries {
    id: string;
    locationId: number;
    name: string;
    countryName: string;
    lat: number;
    lng: number;
    tzOffset: number;
    msOffset?: number;
    bounds?: Bounds;

    zIndex: number;
    isSmallCity: boolean;

    aqiHistory?: AqiHistory_model[];

    // TODO delete after new chart
    originChartData?: OriginChartData_model;

    distributionSummary?: DistributionSummary;

    lastData?: {
        [AQI]?: [number, string];
        [PRES]?: [number, string];
        [HUM]?: [number, string];
        [TEMP]?: [number, string];
        time?: string;
        prevAqi?: number;
    };

    constructor(d: {
        id: string;
        locationId: number;
        name: string;
        countryName: string;
        bounds: {
            east: number;
            north: number;
            south: number;
            west: number;
        };
        tzOffset: number;
        msOffset: number;
        lat: number;
        lng: number;

        zIndex: number;
        isSmallCity: boolean;
    }) {
        this.lastData = {};
        Object.assign(this, d);
    }
}

export class MoBaseInfo {
    id: number;
    name: string;
    tzOffset: number;
}

export enum MarkerType {
    city = 'city',
    mo = 'mo',
    myMo = 'myMo',
    OpenAQ = 'OpenAQ',
    geoPoint = 'geoPoint',
}

export interface PinModel extends BasicDataResponse {
    id: string;
    name: string;
    geometry: Geometry;
    location_id?: number;
}

export class ControlPointModel {
    id?: string;
    name: string;
    lat: number;
    lon: number;
    color?: string;
    type?: string;
}

export class Marker_model extends MoBaseInfo {
    type: MarkerType;
    geometry: {
        type: string;
        coordinates: number[];
    };
    name: string;

    location_id?: number;
    cityId?: string;
    icon?: string;
    city?: City_model;

    constructor(options: Partial<Marker_model>) {
        super();
        Object.assign(this, options);
    }
}

export class AqiHistory_model {
    aqi: number;
    time: number; // timestamp для поиска ближней точки по х
}

export class Station_min_model extends MoBaseInfo {
    isOurStation: boolean;
    originChartData: OriginChartData_model;
}

export class DataForCreateSeries {
    id: number | string;
    name: string;
    pubName?: string;
    originChartData?: OriginChartData_model;
    lastPacketId?: number;
}

export class StationData {
    lastPacketID: number;
    lastPacketTime?: number;
    packets: MeasuresForTime[];
    measuresInfo: MeasuresInfo_model;
}

export class MeasuresInfo_model {
    [id: number | string]: MeasuresInfoFormat;
}

export class MeasuresInfoFormat {
    type: string;
    name: string;
    isHidden?: boolean;
    order?: number;
    unit: string;
}

export class MeasuresForTime {
    time: number;
    values: {
        [measure: string]: number;
    };
}

export class SeriesElement_model {
    [key: string]: any;
}

export class Series_model {
    series: SeriesElement_model[];
    pdk?: { MR: any; CC: any }[];
    yTitle?: string;
    xAxisOption?: {
        [name: string]: any;
    };
}

export class StationForMapPage_model extends Station_min_model {
    pubName: string;
    override isOurStation: boolean;
    override tzOffset: number;
    measuresVal: {
        [chartName: string]: number;
    };
    lastPacketID: number;
    lat: number;
    lng: number;

    type: MarkerType;
    cityId?: string;

    loading?: boolean;

    constructor(marker: Marker_model, originChartData?: OriginChartData_model, type?: MarkerType) {
        super();

        this.id = marker.id;
        this.name = marker.name;
        this.isOurStation = marker.type === 'myMo';
        this.tzOffset = marker.tzOffset;
        this.lat = marker.geometry.coordinates[1];
        this.lng = marker.geometry.coordinates[0];

        this.originChartData = originChartData || {};
        this.measuresVal = {};
        this.lastPacketID = null;
        this.type = marker.type;
        this.cityId = marker.cityId;
    }
}

export class OriginChartData_model {
    [measureName: string]: OriginChartDataMeasure_model; // {AQI, PM... данные для графика}
}

export class OriginChartDataMeasure_model {
    data: [number, number][];
    type: string;
    name: string;
    unit: string;
    min: number;
    max: number;

    constructor() {
        this.data = [];
        this.min = null;
        this.max = null;
    }
}

export class UsersInMo {
    name: string;
    logins: string[];
}

export class UserItems {
    email: string;
    login: string;
    userId: number;
    roleId?: number;
    linksToMo?: MonitoringObject[];

    constructor() {
        this.userId = 0;
        this.email = '';
        this.login = '';
        this.linksToMo = [];
    }
}

export class AdminDevice {
    id: number;
    serialNumber: string;
    model: string;
    sourceId: number;
    sourceName: string;
    name: string;
    startWork: string;
    serviceDate: string;
    intervalSec: number;
    // isNotSaveData: boolean;
    battery: boolean;
    v220: boolean;
    offline: boolean;
    soft: string;
    hardware: string;
    linksToMo: MonitoringObject[];
    namesMo: string[];
    mapBegin?: number;

    lastTime: string;
    geoLatitude: number;
    geoLongitude: number;
    childDevices: AdminDevice[];
}

export class AdminDeviceStandalone extends AdminDevice {
    originChartData: OriginChartData_model;
    measuresVal: {
        [chartName: string]: number;
    };
    lastPacketId: number;
}

export class MonitoringObject {
    id: number;
    name: string;
    description: string;
    isOffline: boolean;
    gmtOffset: number;
    tzOffset: number;
    geoLatitude: number;
    geoLongitude: number;
    pubName: string;
    lastPacketId: number;
    regionCoef: number;
    locationId: number;
    lastConnectedDevice: AdminDevice;

    users: UsersInMo[];
    devicesId: number[];
    devicesObj: AdminDevice[];
    devicesSerialNum: string[];

    originChartData: OriginChartData_model;
    measuresVal: {
        [chartName: string]: number;
    };
}

export class CreateOM extends MonitoringObject {
    locationId: number;

    constructor() {
        super();

        this.id = null;
        this.name = '';
        this.description = '';
        this.isOffline = null;
        this.gmtOffset = -new Date().getTimezoneOffset() / 60;
        this.tzOffset = null; /* в минкутах */
        this.geoLatitude = null;
        this.geoLongitude = null;
        this.pubName = null;
        this.lastPacketId = null;
        this.regionCoef = null;
        this.locationId = null;

        this.users = null;
        this.devicesId = null;
        this.devicesObj = null;
        this.devicesSerialNum = null;

        this.originChartData = null;
        this.measuresVal = null;
    }
}

export class ModulePageConfig {
    enableCompare = true;
    enableCalendar = true;
    enablePlumeButton = true;
    enableAqi = true;

    enableMap = true;
    enableOldTimeline = true;
    enableTopElements = true;
    fullScreen = false;

    lang?: string;
    mapClassName?: string;
}

export type Contours = {
    [aqi: number]: { lat: number; lng: number }[][];
};

export class ModelData {
    locationId: number;
    measure: any;
    width: number;
    height: number;
    bounds: Bounds;
    data: number[];
    contours: Contours;
    time?: number;
}

export class Token {
    tokenId: number;
    title: string;
    isApiKey: boolean;
    createDate: string;
    lastDate: string;
    execCount: number;
    isActive: boolean;
}

export class StartOutsideParams {
    location?: string;
    stations?: number[];
    measures?: MeasuresInfoFormat[];
    time_diapason?: [number, number];
    current_time?: number;
    isOpenCityCard?: boolean;

    action?: 'invite' | '';
    email?: string;
    hash?: string;
}

export enum DeviceStatus {
    NO_SIGNAL,
    OFFLINE,
    LOW_BATTERY_CHARGE,
    POWERED_BY_BATTERY,
    ONLINE,
}

export enum MoExportType {
    mo = 1,
    tza4 = 2,
    moIndoor = 3,
}

export class DownloadPopupData {
    type: MoExportType;
    title: string;
    ids: string[];
    mos?: { id: string; name: string }[];
    devices?: { id: number; serialNumber: string }[];
    currentTabInterval?: number;
}

export enum ModulesIds {
    forecast = 1,
    plumes = 2,
    indoor = 3,
}

export type InitiatorToCloseTimeline = 'plume' | void;

export class CompareActions {
    add: boolean;
    clearBefore: boolean;
    delete: boolean;
}

export class ArrTagDevice {
    id?: string;
    type: 'device' | 'user' | 'mo';
    text: string;
    description?: string;
    isOnline: boolean;
}

export type ModelDownloadPopup = {
    timeBegin: number;
    timeEnd: number;
    downloadType: MoExportType;
    downloadTitle: string;
};

export type IntervalType = 1 | 2 | 3 | 4;
export enum IntervalEnum {
    min5 = <IntervalType>1,
    min20 = <IntervalType>2,
    hour = <IntervalType>3,
    day = <IntervalType>4,
}
export type IntervalV2Type = '5m' | '20m' | '1h' | '1d' | 'source';

export const MINUTE5_MS = 5 * 60 * 1000;
export const MINUTE20_MS = 20 * 60 * 1000;
export const HOUR_MS = 60 * 60 * 1000;
export const DAY_MS = 24 * HOUR_MS;
export const DATA_INTERVAL_TYPES = {
    1: 5, // minutes
    2: 20,
    3: 60,
    4: 1440, // 1day
};

export class GetCSTimelineInfoRequest {
    timeBegin: number;
    timeEnd: number;
    groupId: number;
    measureScheme?: MeasureScheme;
}

export const ACCESS_TOKEN_KEY = 'token';

export class MoItemsDataToExcelRequestProps {
    timeBegin: number;
    timeEnd: number;
    moIds: string[];
    interval: number;
    type: number;
    unitsType: number;
    insertPdk: boolean;
}

export class StationDataToExcelRequestProps {
    type: number;
    timeBegin: number;
    timeEnd: number;
    ids: number[];
}

export const CITYAIR_STND_DEVICE = 'CityAir Device';

export class PinValue {
    id: number;
    time: string | number;
    details?: {
        [mmt in string]: {
            [key in string]: number;
        };
    };
    values: {
        [key in string]: number;
    };
}

// -----------------TIMELINE API

export class TimelineResponse {
    posts: TimelineItem[];
    locations: TimelineItem[];
}

export class TimelineItem {
    id: number;
    timeseries: TimelineTimeseries[];
}

export class PostDate {
    id: number;
    timeseries: {
        time: number;
        details?: {
            [mmt in string]: {
                [key in string]: number;
            };
        };
        values: {
            [key in string]: number;
        };
    }[];
}

export class TimelineTimeseries {
    date: string;
    details?: {
        [mmt in string]: {
            [key in string]: number;
        };
    };
    values: {
        [key in string]: number;
    };
}

export class MapPins {
    getPins: Observable<PinModel[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue: (pin: PinModel) => Observable<number>;
    getColor: (pin: PinModel) => Observable<string>;
    clickCb: (pin: PinModel) => void;
    getHover?: Observable<string[]>;
    getTooltip?: (pin: PinModel) => Observable<string>;
    isValueMode?: Observable<boolean>;
    setExpand?: (id: string) => void;
    getExpand?: Observable<string[]>;
    isCollapsed?: (id: string) => Observable<boolean>;
    getCurrentIndex?: () => Observable<number>;
    getMeasureScheme?: () => Observable<{ scheme: MeasureScheme; mmt: string; zone: ColorZone }>;
    getSources?: () => Observable<{ sources: Source[]; order: number[] }>;
    getRegions?: () => Observable<{ regions: RegionApi[] }>;
}
export class SourceLine {
    getLine: Observable<GeoJSON.Feature<GeoJSON.Geometry>[]>;
    clickCb?: (line) => void;
    mouseEnter?: ($event, line) => void;
    mouseLeave?: () => void;
    mouseMove?: ($event, line) => void;
    getHoverLine?: Observable<GeoJSON.Feature[]>;
}
export class MapControlPins {
    getPins: Observable<any[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue?: (pin: ControlPointModel) => Observable<number>;
    getColor?: (pin: ControlPointModel) => Observable<string>;
    isWD?: Observable<boolean>;
    clickCb: (pin: ControlPointModel) => void;
    dragEnd?: ($event, pin?: ControlPointModel) => void;
    isDraggable?: (pin: any) => Observable<boolean>;
    setExpand?: (id: string) => void;
    getExpand?: Observable<string[]>;
    isCollapse?: Observable<boolean>;
    getCurrentIndex?: () => Observable<number>;
    getMeasureScheme?: () => Observable<{ scheme: MeasureScheme; mmt: string; zone: ColorZone }>;
    getSources?: () => Observable<{ sources: any[]; order: number[] }>;
    getRegions?: () => Observable<{ regions: any[] }>;
}
export class InfoPins {
    getPins: Observable<ControlPointModel[]>;
}
export class SourcePins {
    getPins: Observable<any[]>;
    isCollapsed?: (id: string) => Observable<boolean>;
}
export class RegionPins {
    getPins: Observable<RegionApi[]>;
}
export class MapPolygonInfo {
    getPolygon: Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry>>;
}
export class MapInfoPin {
    getPins: Observable<ControlPointModel[]>;
}
export class MapCenterAndZoom {
    center: LngLat;
    zoom: number;
}

export const GROUP_ID = 'groupId';
export const MAP_STYLE_TYPE_STORAGE_KYE = 'mapStyleType';
export type InfoMessageIconType = 'success' | 'info' | 'progress' | 'warning' | 'error';
export interface InfoMessage {
    id: number;
    messageKey?: string;
    message?: string;
    description?: string;
    positionX?: 'left' | 'center' | 'right';
    positionY?: 'top' | 'center' | 'bottom';
    iconClass?: InfoMessageIconType;
    showCloseIcon?: boolean;
    duration?: number;
    size?: 'md' | 'lg';
    fullScreen?: boolean;
}
export interface IMapClick {
    isAllow: boolean;
    coordinates?: {
        lat: number;
        lon: number;
    };
}
export type intervalApiType = 'Interval5M' | 'Interval20M' | 'Interval1H' | 'Interval1D' | 'Source';
export const defaultModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: true,
    enableCalendar: true,
    enablePlumeButton: false,
    enableAqi: true,
};
