import { Component, EventEmitter, Input, Output } from '@angular/core';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';
import { Device, DISPLAY_DEVICE_TYPE } from '@libs/common/models/basicModels';

@Component({
    selector: 'monitoring-object-plate',
    templateUrl: 'monitoring-object-plate.component.html',
    styleUrls: ['monitoring-object-plate.component.less'],
})
export class MonitoringObjectPlateComponent {
    @Input() name = '';
    @Input() idMo = null;
    @Input() description?: string;
    @Input() city?: string;
    @Input() value: number = null;
    @Input() valueColor: string;
    @Input() device: Device;
    @Input() deviceSourceName: string;
    @Input() isActive = false;
    @Input() markerState: markerState;
    @Input() isComplexMode = false;
    @Input() isShowKebabMenu = true;
    @Input() isNotConnectedDevices = true;
    @Input() isNoDataSources = true;
    @Input() digitsAfterDot = 0;

    @Output() cbClickForStaticTagsDevice = new EventEmitter<{ type: string; id: any }>();
    @Output() cbClickForCard = new EventEmitter<void>();
    @Output() openActionsPopup = new EventEmitter<{ target: HTMLElement; positionTop: number }>();

    TEXTS = TEXTS;
    DISPLAY_DEVICE_TYPE = DISPLAY_DEVICE_TYPE;
    isFalseNumber = isFalseNumber;

    clickForStaticTagsPost(idMO: number) {
        this.cbClickForStaticTagsDevice.emit({ type: 'MO', id: idMO });
    }

    clickForCard() {
        this.cbClickForCard.emit();
    }

    clickForStaticTagsDevice(device: Device) {
        const typeDevice = device.source_type;

        if (typeDevice === DISPLAY_DEVICE_TYPE) {
            this.cbClickForStaticTagsDevice.emit({ type: 'device', id: device.serial_number });
        }
    }

    openActions({ target, positionTop }) {
        this.openActionsPopup.emit({
            target,
            positionTop,
        });
    }
}
