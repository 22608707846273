import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import {
    selectApiKeyByToken,
    selectUserApiKeys,
} from '@cityair/modules/settings/store/settings/selectors';
import { Store } from '@ngrx/store';
import { BasicUserTokens } from '@libs/common/models/basicModels';
import {
    clearApiKeyToken,
    deleteUserToken,
    getUserPermission,
    getUserTokenValue,
    updateApiKey,
} from '@cityair/modules/settings/store/settings/actions';
import { firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { selectIAm } from '@cityair/modules/core/store/selectors';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { addAlert } from '@cityair/modules/core/store/actions';

type PopupType = 'create' | 'rename' | 'close' | '';

@Component({
    selector: 'cityair-tokens',
    templateUrl: './keys.component.html',
    styleUrls: ['./keys.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeysComponent implements OnInit, OnDestroy {
    @ViewChild('tokenMenu', { static: true }) tokenMenu: TemplateRef<HTMLDivElement>;
    @ViewChild('popupMenu', { static: true }) popupMenu: TemplateRef<HTMLDivElement>;

    isShowMenu = false;
    menuPositionTop = 0;
    menuPositionLeft = 0;
    textSettings = TEXTS.CONFIG;
    textClear = TEXTS.LIST_OM.clearSearch;
    keyControl: UntypedFormGroup;
    searchQuery = '';
    public sortDirection = 1;
    public sortField = 'CreateDate';
    popup: PopupType;
    tokenPlaceholder = '********-****-****-****-************';
    selectUserApiKeys = selectUserApiKeys;
    userId: number;
    showTokenValue: {
        [id: number]: string;
    } = {};
    selectedToken: BasicUserTokens;
    hoveredToken: BasicUserTokens;
    language = LANGUAGE;
    quotes = this.language == 'en' ? ['"', '"'] : ['«', '»'];
    private popupOpenerElement: HTMLElement;

    constructor(
        public popupProvider: OffPanelPopupService,
        public store: Store,
        private fb: UntypedFormBuilder
    ) {
        this.store
            .select(selectIAm)
            .pipe(
                filter((g) => !!g),
                take(1)
            )
            .subscribe((user) => {
                this.userId = user?.id ? Number(user.id) : null;
                this.store.dispatch(getUserPermission());
            });
    }

    public getValueForm(field) {
        return this.keyControl?.get(field)?.value || '';
    }

    ngOnInit() {
        this.keyControl = this.fb.group({
            name: new UntypedFormControl(
                {
                    value: null,
                    disabled: false,
                },
                [Validators.required]
            ),
        });
    }

    public getError(field: string): string {
        if (
            this.keyControl &&
            this.keyControl.controls[field] &&
            this.keyControl.controls[field].invalid &&
            (this.keyControl.controls[field].dirty || this.keyControl.controls[field].touched)
        ) {
            if (this.keyControl.controls[field].errors.required) {
                return TEXTS.LIST_USERS.required;
            }
        }

        return null;
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    configPopup = {
        create: {
            title: this.textSettings.createKeyTitle,
            apply: TEXTS.CONFIG.applyCreate,
        },
        close: {
            title: TEXTS.CONFIG.titlePopupClose,
            apply: TEXTS.CONFIG.applyClose,
        },
        rename: {
            title: TEXTS.CONFIG.titlePopupRename,
            apply: TEXTS.CONFIG.applyRename,
        },
    };

    public textChangeIn($event) {
        this.searchQuery = $event;
    }

    copyToken = async (token: BasicUserTokens) => {
        this.store.dispatch(getUserTokenValue({ payload: token }));
        const text = await firstValueFrom(
            this.store.select(selectApiKeyByToken(token.TokenId)).pipe(filter((v) => v !== null))
        );

        const showTokenPlanB = () => (this.showTokenValue[token.TokenId] = text);

        // safari
        if (!navigator.permissions || !navigator.permissions.query) {
            return showTokenPlanB();
        }

        navigator.permissions
            // @ts-ignore
            .query({ name: 'clipboard-write' })
            .then((result) => {
                if (result.state == 'granted' || result.state == 'prompt') {
                    navigator.clipboard.writeText(text).then(
                        () => {
                            this.store.dispatch(
                                addAlert({
                                    id: new Date().valueOf(),
                                    message: TEXTS.CONFIG.copySuccess,
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'success',
                                    duration: 2000,
                                    showCloseIcon: false,
                                    size: 'lg',
                                })
                            );
                        },
                        () => {
                            showTokenPlanB();
                        }
                    );
                } else showTokenPlanB();
            })
            .catch(() => showTokenPlanB());
        setTimeout(() => this.store.dispatch(clearApiKeyToken({ payload: token.TokenId })), 1000);
    };

    showPopup(e: Event, name: PopupType) {
        this.popup = name;

        if (e) {
            this.closeMenu(e);
        }

        this.popupProvider.setTemplate(this.popupMenu);

        if (name === 'rename') {
            this.keyControl.controls.name.setValue(this.selectedToken?.Title);
        } else {
            this.keyControl.controls.name.setValue('');
        }
    }

    getDescription = () => this.textSettings.titlePopupRemove(this.selectedToken.Title);

    applyPopup = () => {
        const type = this.popup;
        this.popup = '';

        if (type === 'rename') {
            const name = this.keyControl?.get('name')?.value;
            const token = { ...this.selectedToken, Title: name };
            this.store.dispatch(updateApiKey({ payload: token }));
        }

        if (type === 'close') {
            this.store.dispatch(deleteUserToken({ payload: this.selectedToken }));
        }

        if (type === 'create') {
            const token = {
                IsApiKey: true,
                Title: this.keyControl?.get('name')?.value,
                UserId: this.userId,
                TokenId: 0,
            } as BasicUserTokens;
            this.store.dispatch(updateApiKey({ payload: token }));
        }
    };

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }
    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    onScroll() {
        this.isShowMenu = false;
    }

    openActions({ target, positionTop, positionLeft }, token: BasicUserTokens) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.tokenMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop - 45;
        this.menuPositionLeft = positionLeft - 240;
        this.isShowMenu = true;
        this.selectedToken = token;
    }
}
