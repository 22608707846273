import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { map, Observable } from 'rxjs';

import { isRU } from '@libs/common/texts/texts';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { environment } from 'environments/environment';
import { ForecastConfig } from '../models';

const API_URL = environment.vanga_api_url + '/forecastnew/v1';

@Injectable({
    providedIn: 'root',
})
export class ForecastsDataService extends DefaultDataService<ForecastConfig> {
    private lang = isRU ? 'ru' : 'en';
    private urlPath = this.urlGenerator.collectionResource('Forecasts', '');

    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super('Forecasts', http, urlGenerator, { root: API_URL });
    }

    getAll(): Observable<ForecastConfig[]> {
        throw new Error('not implemented');
    }

    add(data: ForecastConfig): Observable<ForecastConfig> {
        throw new Error('not implemented');
    }

    update(data: Update<ForecastConfig>): Observable<ForecastConfig> {
        throw new Error('not implemented');
    }

    delete(key: number | string): Observable<number | string> {
        throw new Error('not implemented');
    }

    getWithQuery(params: string | QueryParams): Observable<ForecastConfig[]> {
        const httpOptions = this.getHttpOptions(params);

        return this.http
            .get(this.urlPath, httpOptions)
            .pipe(map((response: any) => response as ForecastConfig[]));
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
