import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { InstantAqiFaqComponent } from './instant-aqi-faq.component';

@NgModule({
    imports: [CommonModule, CrossButtonModule],
    exports: [InstantAqiFaqComponent],
    declarations: [InstantAqiFaqComponent],
})
export class InstantAqiFaqModule {}
