import {
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy,
    ElementRef,
    EventEmitter,
    Output,
} from '@angular/core';

import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';

import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import {
    postsListLabels,
    selectedPostsText,
} from '@cityair/modules/notifications/notifications.utils';
import {
    deduplicate,
    mapEmails,
    stringToList,
    updateFormControls,
    EmailsListData,
    deepEqual,
} from '@cityair/modules/notifications/components/notifications/settings/settings.utils';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';
import { NgLocalization } from '@angular/common';
import { Store } from '@ngrx/store';

import {
    createNotificationSettings,
    editNotificationSettings,
    formNotificationSettingsError,
    updateNotifiablePosts,
} from '@cityair/modules/notifications/store/actions';
import {
    DataObjType,
    NotificationSettings,
    Post,
    User,
    NotificationType,
} from '@libs/common/models/basicModels';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import {
    selectFormError,
    selectNotifiablePostIds,
} from '@cityair/modules/notifications/store/selectors';

const VALUE_PATTERN = /^[-\+]?[1-9][0-9]*$/;
export type BasicCheckboxItem = {
    id: string;
    label?: string;
    selected?: boolean;
    value?: string;
    icon?: string;
};
@Component({
    selector: 'settings-service',
    templateUrl: './settings-service.component.html',
    styleUrls: ['settings-service.component.less'],
})
export class SettingsService implements OnInit, OnDestroy {
    @Input() subscription: NotificationSettings;
    @Input() currentUser: User;
    @Input() groupId: string;
    @Input() isLoadingForm: boolean;
    @Output() cancelEmit = new EventEmitter<{ settings_type: NotificationType }>();

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSettings;
    mosList: Post[];
    allMos: string[];
    moCheckboxes: BasicCheckboxItem[] = [];

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;
    TEXTS = TEXTS;

    listStations: CheckboxItem[] = [];
    isShowDropdownForStations = false;
    public errorResponse: string;
    settingsForm: UntypedFormGroup;

    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showConfirmationPopup = false;
    maxInlineMos = MAX_INLINE_MOS;
    showMosSelection = false;
    subscriptions: Subscription[] = [];
    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);
    getMoNameHandler = this.getMoName.bind(this);
    constructor(
        private element: ElementRef,
        private ngLocalization: NgLocalization,
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        private store: Store
    ) {
        const monitoringObjectsSub = store
            .select(selectAllPosts)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.mosList = mos;
                this.allMos = mos.map((v) => v.id);
                this.listStations = this.prepareListMo();
            });

        this.subscriptions.push(monitoringObjectsSub);
        const serverErrorSub = store.select(selectFormError).subscribe((value) => {
            if (value) {
                const data = value.error?.meta?.error?.validation_errors;
                if (data?.length) {
                    data.forEach((error) => {
                        const key = error?.field;
                        if (this.settingsForm?.controls[key]) {
                            this.settingsForm.controls[key].setErrors({
                                incorrect: true,
                                message: error?.error,
                            });
                        } else if (key === 'detail') {
                            this.errorResponse = error?.error;
                            this.clearError();
                        }
                    });
                }
            }
        });

        this.subscriptions.push(serverErrorSub);
    }

    ngOnInit() {
        this.workingSubscription = this.cloneSettings(this.subscription);

        this.emailsListData = mapEmails(
            this.workingSubscription.notify_settings?.notification_emails
        );
        this.emailsListReportsData = mapEmails(
            this.workingSubscription.notify_settings?.daily_report_emails
        );

        this.listStations = this.prepareListMo();

        this.setNotifiableMos();

        this.settingsForm = this.fb.group({
            name: [
                this.workingSubscription.name,
                [Validators.required, Validators.minLength(1), Validators.maxLength(100)],
            ],
            serviceSettings: this.fb.group({
                nodata: [
                    this.workingSubscription.device_check_settings?.no_data_duration_threshold,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
                nopower: [
                    this.workingSubscription.device_check_settings?.no_ps220_duration_threshold,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
                verification: [
                    this.workingSubscription.device_check_settings?.verification_threshold,
                    [Validators.required, Validators.pattern(VALUE_PATTERN), Validators.min(1)],
                ],
            }),
            emailsList: [
                this.workingSubscription.notify_settings.notification_emails,
                [Validators.minLength(1)],
            ],
            emailsListReports: [
                this.workingSubscription.notify_settings.daily_report_emails,
                [Validators.minLength(1)],
            ],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce(
                    (acc, { name, value }) => ({
                        ...acc,
                        [name]: [value, [Validators.email]],
                    }),
                    {}
                )
            ),
        });

        const title$ = this.name.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const serviceSettings$ = this.serviceSettings.valueChanges.pipe(debounceTime(200));
        const emailsList$ = this.emailsList.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged()
        );

        const titleSub = title$.subscribe((value) => {
            this.workingSubscription.name = value;
        });

        const serviceSettingsSub = serviceSettings$.subscribe((value) => {
            this.workingSubscription = {
                ...this.workingSubscription,
                device_check_settings: {
                    ...this.workingSubscription.device_check_settings,
                    no_data_duration_threshold: value.nodata,
                    no_ps220_duration_threshold: value.nopower,
                    verification_threshold: value.verification,
                },
            };
        });

        const emailListSub = emailsList$.subscribe((value) => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as string[]);
            this.workingSubscription = {
                ...this.workingSubscription,
                notify_settings: {
                    ...this.workingSubscription.notify_settings,
                    notification_emails: deduplicate(emails),
                },
            };
        });

        const emailListReportsSub = emailsListReports$.subscribe((value) => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as string[]);
            this.workingSubscription = {
                ...this.workingSubscription,
                notify_settings: {
                    ...this.workingSubscription.notify_settings,
                    daily_report_emails: deduplicate(emails),
                },
            };
        });

        this.subscriptions.push(titleSub, emailListSub, emailListReportsSub, serviceSettingsSub);
        merge(title$, serviceSettings$, emailsList$, emailsListReports$).subscribe(() => {
            this.changeCheck();
        });
        // react on map clicks
        const notifiableMosSub = this.store.select(selectNotifiablePostIds).subscribe((postIds) => {
            this.listStations = this.mosList.map((s) => ({
                id: s.id,
                label: s.name,
                selected: postIds.indexOf(s.id) >= 0,
            }));
            this.changeCheck();
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    ngOnDestroy() {
        this.popupProvider.clear();
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    public removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
        this.setNotifiableMos();
        this.changeCheck();
    }

    public selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        if (num === 0) {
            return this.textsNotification.noneSelected;
        } else if (num === this.listStations.length) {
            return this.textsNotification.allMonitoringPostsSelected;
        }

        return [selected[category], num, post[category]].join(' ');
    }
    public getValueForm(field) {
        return this.settingsForm?.get(field)?.value || '';
    }

    public getError(field: string) {
        if (
            this.settingsForm &&
            this.settingsForm.controls[field] &&
            this.settingsForm.controls[field].invalid &&
            (this.settingsForm.controls[field].dirty || this.settingsForm.controls[field].touched)
        ) {
            if (this.settingsForm.controls[field].errors.required) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.required;
            }

            if (this.settingsForm.controls[field].errors.maxlength) {
                return this.TEXTS.POSTS_AND_DEVICES.formError.maxLength(
                    this.settingsForm.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.settingsForm.controls[field].errors.incorrect) {
                return this.settingsForm.controls[field].errors.message;
            }

            return null;
        }

        return null;
    }

    private clearError() {
        setTimeout(() => {
            this.errorResponse = null;
            this.store.dispatch(formNotificationSettingsError({ payload: null }));
        }, 3000);
    }

    public prepareListMo(): CheckboxItem[] {
        const result = this.mosList.map((s) => ({
            id: s.id,
            label: s.name,
            selected: this.workingSubscription?.device_check_settings?.is_for_all_posts
                ? true
                : this.workingSubscription?.device_check_settings?.selected_posts.indexOf(s.id) >=
                  0,
        }));

        return result;
    }

    public changePosts($event) {
        this.listStations = $event;
        this.setNotifiableMos();
        this.changeCheck();
    }

    private normalizeMos(subscription: NotificationSettings) {
        const moItems = this.getMoIds();
        const isForAll = moItems.length === this.allMos.length;

        return Object.assign(this.cloneSettings(subscription), {
            device_check_settings: {
                ...subscription.device_check_settings,
                selected_posts: isForAll ? [] : moItems,
                is_for_all_posts: isForAll,
            },
        });
    }

    setNotifiableMos() {
        this.store.dispatch(
            updateNotifiablePosts({
                postIds: this.getMoIds(),
            })
        );
    }

    toggleMosSelection() {
        this.showMosSelection = !this.showMosSelection;
    }

    toggleMosSelectionWithinContainer(e: Event) {
        if (this.element.nativeElement.contains(e.target as HTMLElement)) {
            this.toggleMosSelection();
        }
    }
    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as UntypedFormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(
            this.emailsListReportsProxy as UntypedFormGroup,
            this.emailsListReportsData
        );
    }

    getMoName(id: string) {
        if (!this.mosList) {
            return;
        }
        return this.mosList.find((v) => v.id === id)?.name;
    }

    changeCheck() {
        this.formDataHasChanged = !deepEqual(
            this.subscription,
            this.normalizeMos(this.workingSubscription)
        );
    }

    getMoIds() {
        return this.listStations.filter((s) => s.selected).map((s) => s.id.toString());
    }

    get name() {
        return this.settingsForm.get('name');
    }
    get serviceSettings() {
        return this.settingsForm.get('serviceSettings');
    }

    get nodata() {
        return this.settingsForm.get('serviceSettings').get('nodata');
    }

    get nopower() {
        return this.settingsForm.get('serviceSettings').get('nopower');
    }

    get verification() {
        return this.settingsForm.get('serviceSettings').get('verification');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as UntypedFormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as UntypedFormGroup;
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    cancel = () => {
        this.cancelEmit.emit({ settings_type: NotificationType.deviceIncident });
    };

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        } else {
            this.onClosePopup();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        const subscription = this.normalizeMos(this.workingSubscription);
        if (subscription.id) {
            this.store.dispatch(editNotificationSettings({ payload: subscription }));
        } else {
            this.store.dispatch(createNotificationSettings({ payload: subscription }));
        }
    }

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onSavePopup = () => {
        this.save();
    };

    onCancelPopup = () => {
        this.cancel();
    };

    private cloneSettings(src: NotificationSettings) {
        if (src === null) {
            return {
                id: null,
                name: null,
                type: NotificationType.deviceIncident,
                is_active: true,
                group_id: this.groupId,
                obj: DataObjType.notification,
                notify_settings: {
                    daily_report_emails: [],
                    notification_emails: this.currentUser?.email ? [this.currentUser.email] : [],
                },
                device_check_settings: {
                    is_for_all_posts: true,
                    selected_posts: [],
                    no_data_duration_threshold: 1,
                    no_ps220_duration_threshold: 1,
                    verification_threshold: 1,
                },
            } as NotificationSettings;
        } else {
            return { ...src };
        }
    }
}
