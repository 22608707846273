<div class="stnd_popup__splash" style="width: 100vw;">
    <div class="stnd_popup edit-user" *ngIf="usersControl">
        <div class="stnd_popup__close" (click)="cancel()"> <div class="icon"></div></div>
        <div class="stnd_popup__title">{{ isEditMode ? TEXTS.LIST_USERS.editUsers : TEXTS.LIST_USERS.addUsers }}</div>
        <div class="edit_user__row">
            <div class="edit_user__input-wrapper email">
                <ca-input
                    type="email"
                    controlName="email"
                    [form]="usersControl"
                    [valueForm]="getValueForm('email')"
                    [label]="'Email'"
                    [textError]="getError('email')"
                ></ca-input>
            </div>
            <div class="edit_user__input-wrapper role">
                <input-dropdown [ngClass]="{'no-selected': !selectedRole }"
                    [inputText]="selectedRole?.label ? selectedRole?.label : TEXTS.LIST_USERS.noSelect"
                    [label]="TEXTS.LIST_USERS.accessSelect"
                    [showLabelInfoIcon]="true"
                    (clickInfo)="infoIconClick()"
                    [(showDropdown)]="showUsersRole"
                    (clickOutside)="showUsersRole = false"
                >
                    <selectbox-radio
                        [listItems]="roleList"
                        [isLeftCheckPosition]="false"
                        (listItemsChange)="
                            getSelectedRole($event); showUsersRole = false
                        "
                    ></selectbox-radio>
                </input-dropdown>
            </div>
        </div>
        <div class="edit_user__wrap-decs">
            <ng-container *ngIf="selectedRole && selectedRole?.id === '3'">
                 <div class="edit_user__description" [innerHtml]="TEXTS.LIST_USERS.observer"></div>
            </ng-container>
        </div>
        <div
            class="edit_user__input-wrapper"
            *ngIf="newUserProps?.group_role.id === '3'"
        >
            <ng-container *ngIf="this.mos?.length else noPostTemp">
                <input-dropdown
                    class="post-report-tza__input-dropdown"
                    [inputText]="selectedCheckboxText(getTags().length)"
                    [label]="TEXTS.POSTS_AND_DEVICES.postsMonitoring"
                    [(showDropdown)]="isShowDropdownForStations"
                    (clickOutside)="isShowDropdownForStations = false"
                >
                    <cs-selectbox
                        [listItems]="listStations"
                        (listItemsChange)="updateListStation($event)"
                        [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                        [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
                    ></cs-selectbox>
                </input-dropdown>
                <tags-list
                    class="tags-list"
                    [tags]="getTags()"
                    [textNodesFns]="postsListLabels()"
                    [truncateTo]="4"
                    [editable]="true"
                    (removeTag)="removeFromList($event)"
                ></tags-list>
            </ng-container>
            <ng-template #noPostTemp>
                <div class="error-msg">
                    {{TEXTS.LIST_USERS.noPostsMsg}}
                </div>
            </ng-template>
        </div>
        <div class="error-response-wrapper" *ngIf="errorResponse">
            <div class="title-status">{{ TEXTS.NOTIFICATIONS.error }}</div>
            {{ errorResponse }}
        </div>
        <div class="stnd_popup__btn_block" style="padding-top: 30px;">
            <ca-button type="cancel" (clickAction)="cancel()" style="margin-right: 10px;">
                {{ TEXTS.COMMON.cancel }}
            </ca-button>
            <ca-button type="primary" (clickAction)="apply()" [disabled]="isDisabled()">
                <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                <ng-template #showContent>
                    {{
                        isEditMode
                            ? TEXTS.COMMON.save
                            : TEXTS.LIST_USERS.add
                    }}
                </ng-template>
            </ca-button>
        </div>
    </div>
</div>
<shared-ui-access-info
    *ngIf="showAccessLevelPopup"
    [roles]="roles"
    [moduleAccessInfo]="moduleAccessInfo"
    (cancel)="showAccessLevelPopup=false"
></shared-ui-access-info>
