import * as moment from 'moment-timezone';
import {
    AnalysisEvent,
    AnalysisEventStatus,
    AnalysisEventType,
    GroupEvent,
    SortingData,
} from '@libs/common/models/basicModels';

export const FORECAST_ADD_HOURS = 6;
export const TEST_NOW = '2023-05-09T17:00:00Z';
export const TEST_NOW_MS = new Date(TEST_NOW).valueOf();
export const FORECAST_TIME = moment(TEST_NOW).add(FORECAST_ADD_HOURS, 'hours').toISOString();
export const ADDITIONAL_HOURS_START_TIMELINE = 2;
export const POST_NUMBERS_FOR_GLOBAL_EVENTS = 3;
export const INIT_SORTING: SortingData = {
    name: 'start_ms',
    dir: 1,
};
export const COLORS = [
    '#C83FBE',
    '#653CD8',
    '#3DD496',
    '#8BC3F3',
    '#ff8243',
    '#A2C617',
    '#D780FF',
    '#1f75fe',
    '#30BFA0',
    '#9370d8',
    '#f5d033',
];
const opacity = 0.1;
export const COLORS_HOVER = [
    `rgba(200, 63, 190, ${opacity})`,
    `rgba(101, 60, 216, ${opacity})`,
    `rgba(61, 212, 150, ${opacity})`,
    `rgba(139, 195, 243, ${opacity})`,
    `rgba(255, 130, 67, ${opacity})`,
    `rgba(162, 198, 23, ${opacity})`,
    `rgba(215, 128, 255,${opacity})`,
    `rgba(31, 117, 254, ${opacity})`,
    `rgba(48, 191, 160, ${opacity})`,
    `rgba(147, 112, 216, ${opacity})`,
];
export const COLORS_CORRELATION_WHO = ['#EB3223', '#000000', '#377C22'];
export enum STATUS_COLOR {
    NEW = '#9375E4',
    WAIT = '#FFA33B',
    CANCEL = '#FF7344',
    ACCEPT = '#5FC6B1',
    END = '#6097F3',
}
// constants for Demo Mode
export const TEST_EVENT: GroupEvent = {
    post_id: 'post_6',
    max_value: 270,
    start: '2023-05-09T15:00:00Z',
    end: null,
};
export const TEST_EVENT_2: GroupEvent = {
    post_id: 'post_62',
    max_value: 233,
    start: '2023-05-09T15:20:00Z',
    end: null,
};
export const TEST_EVENT_3: GroupEvent = {
    post_id: 'post_5',
    max_value: 184,
    start: '2023-05-09T16:20:00Z',
    end: null,
};
export const TEST_EVENT_4: GroupEvent = {
    post_id: 'post_475',
    max_value: 267,
    start: '2023-05-09T15:20:00Z',
    end: null,
};
export const TEST_EVENT_5: GroupEvent = {
    post_id: 'post_30',
    max_value: 292,
    start: '2023-05-09T16:20:00Z',
    end: null,
};
export const TEST_EVENT_6: GroupEvent = {
    post_id: 'post_495',
    max_value: 319,
    start: '2023-05-09T16:20:00Z',
    end: null,
};
export const TEST_EVENT_7: GroupEvent = {
    post_id: 'post_543',
    max_value: 290,
    start: '2023-05-09T16:20:00Z',
    end: null,
};
export const TEST_EVENT_8: GroupEvent = {
    post_id: 'post_9',
    max_value: 256,
    start: '2023-05-09T16:40:00Z',
    end: null,
};
export const TEST_EVENT_9: GroupEvent = {
    post_id: 'post_9',
    max_value: 350,
    start: '2023-05-09T16:40:00Z',
    end: '2023-05-09T19:00:00Z',
};
export const TEST_EVENT_10: GroupEvent = {
    post_id: 'post_543',
    max_value: 290,
    start: '2023-05-08T17:40:00Z',
    end: '2023-05-09T19:20:00Z',
};
export const TEST_EVENT_11: GroupEvent = {
    post_id: 'post_494',
    max_value: 290,
    start: '2023-05-08T19:20:00Z',
    end: '2023-05-08T23:20:00Z',
};
export const TEST_EVENT_12: GroupEvent = {
    post_id: 'post_5',
    max_value: 750,
    start: '2023-05-08T19:40:00Z',
    end: '2023-05-08T20:20:00Z',
};
export const TEST_EVENT_13: GroupEvent = {
    post_id: 'post_30',
    max_value: 570,
    start: '2023-05-08T19:00:00Z',
    end: '2023-05-08T20:20:00Z',
};
export const TEST_EVENT_14: GroupEvent = {
    post_id: 'post_61',
    max_value: 670,
    start: '2023-05-08T17:20:00Z',
    end: '2023-05-08T20:40:00Z',
};
export const TEST_ANALYSIS_EVENT: AnalysisEvent = {
    events: [
        TEST_EVENT,
        TEST_EVENT_2,
        TEST_EVENT_4,
        TEST_EVENT_3,
        TEST_EVENT_5,
        TEST_EVENT_6,
        TEST_EVENT_7,
        TEST_EVENT_8,
    ],
    id: '21',
    status: AnalysisEventStatus.new,
    type: AnalysisEventType.global,
    verify: 85,
    start: '2023-05-09T15:00:00Z',
    end: null,
    forecast_end: TEST_NOW,
    param: 'PM25',
    pdk_value: 160.0,
};
export const TEST_ANALYSIS_EVENTS_1: AnalysisEvent = {
    events: [TEST_EVENT_9, TEST_EVENT_10, TEST_EVENT_11],
    id: '20',
    status: AnalysisEventStatus.wait,
    type: AnalysisEventType.global,
    verify: 70,
    start: '2023-05-08T16:40:00Z',
    end: '2023-05-08T19:20:00Z',
    pdk_value: 300,
    param: 'PM10',
};
export const TEST_ANALYSIS_EVENTS_3: AnalysisEvent = {
    events: [
        {
            post_id: 'post_6',
            start: '2023-05-09T00:00:00Z',
            end: '2023-05-09T01:20:00Z',
            max_value: 364.95,
        },
        {
            post_id: 'post_30',
            start: '2023-05-09T01:20:00Z',
            end: '2023-05-09T02:40:00Z',
            max_value: 385.65,
        },
        {
            post_id: 'post_543',
            start: '2023-05-09T01:20:00Z',
            end: '2023-05-09T02:40:00Z',
            max_value: 424.3,
        },
        {
            post_id: 'post_9',
            start: '2023-05-09T01:40:00Z',
            end: '2023-05-09T02:20:00Z',
            max_value: 331.0,
        },
        {
            post_id: 'post_494',
            start: '2023-05-09T01:40:00Z',
            end: '2023-05-09T02:40:00Z',
            max_value: 355.85,
        },
    ],
    id: '19',
    status: AnalysisEventStatus.cancel,
    type: AnalysisEventType.local,
    verify: 50,
    start: '2023-05-09T00:00:00Z',
    end: '2023-05-09T02:40:00Z',
    pdk_value: 300,
    param: 'PM10',
};
export const TEST_ANALYSIS_EVENTS_4: AnalysisEvent = {
    events: [TEST_EVENT_5, TEST_EVENT_6, TEST_EVENT_7],
    id: '18',
    status: AnalysisEventStatus.accept,
    type: AnalysisEventType.global,
    verify: 90,
    start: '2023-05-07T11:40:00Z',
    end: '2023-05-07T19:20:00Z',
    pdk_value: 160,
    param: 'PM25',
};
export const TEST_ANALYSIS_EVENTS_5: AnalysisEvent = {
    events: [TEST_EVENT_12, TEST_EVENT_13, TEST_EVENT_14],
    id: '17',
    status: AnalysisEventStatus.accept,
    type: AnalysisEventType.global,
    verify: 87,
    start: '2023-05-08T19:40:00Z',
    end: '2023-05-08T20:40:00Z',
    param: 'SO2',
    pdk_value: 500,
};
export const REGISTER_EVENTS = [
    TEST_ANALYSIS_EVENT,
    TEST_ANALYSIS_EVENTS_1,
    TEST_ANALYSIS_EVENTS_3,
    TEST_ANALYSIS_EVENTS_4,
    TEST_ANALYSIS_EVENTS_5,
];
export const TEST_BAR_CHART_DATA_AVERAGE = [
    {
        post_5: 7.1428571429,
        post_6: 10.0,
        post_9: 50.0,
        post_30: 25.0,
        post_61: 16.6666666667,
        post_62: 10.0,
        post_475: 16.6666666667,
        post_494: 50.0,
        post_495: 33.3333333333,
        post_528: 0.0,
        post_543: 45.4545454545,
    },
    {
        post_5: 35.7142857143,
        post_6: 0.0,
        post_9: 16.6666666667,
        post_30: 25.0,
        post_61: 33.3333333333,
        post_62: 30.0,
        post_475: 0.0,
        post_494: 30.0,
        post_495: 50.0,
        post_528: 100.0,
        post_543: 36.3636363636,
    },
    {
        post_5: 21.4285714286,
        post_6: 40.0,
        post_9: 16.6666666667,
        post_30: 0.0,
        post_61: 50.0,
        post_62: 10.0,
        post_475: 33.3333333333,
        post_494: 10.0,
        post_495: 0.0,
        post_528: 0.0,
        post_543: 0.0,
    },
    {
        post_5: 21.4285714286,
        post_6: 20.0,
        post_9: 16.6666666667,
        post_30: 37.5,
        post_61: 0.0,
        post_62: 30.0,
        post_475: 33.3333333333,
        post_494: 10.0,
        post_495: 8.3333333333,
        post_528: 0.0,
        post_543: 18.1818181818,
    },
    {
        post_5: 0.0,
        post_6: 10.0,
        post_9: 0.0,
        post_30: 12.5,
        post_61: 0.0,
        post_62: 10.0,
        post_475: 16.6666666667,
        post_494: 0.0,
        post_495: 0.0,
        post_528: 0.0,
        post_543: 0.0,
    },
    {
        post_5: 14.2857142857,
        post_6: 20.0,
        post_9: 0.0,
        post_30: 0.0,
        post_61: 0.0,
        post_62: 10.0,
        post_475: 0.0,
        post_494: 0.0,
        post_495: 8.3333333333,
        post_528: 0.0,
        post_543: 0.0,
    },
];
export const TEST_BAR_CHART_DATA_DAYS_NUMBER = [
    {
        post_5: {
            '0': 26,
            '1': 17,
            '2': 14,
            '3': 10,
            '4': 9,
            '5': 9,
            '6': 6,
            '7': 14,
            '8': 20,
            '9': 19,
            '10': 13,
            '11': 3,
            '12': 4,
            '13': 5,
            '14': 4,
            '15': 3,
            '16': 1,
            '17': 0,
            '18': 1,
            '19': 8,
            '20': 12,
            '21': 28,
            '22': 36,
            '23': 33,
        },
        post_6: {
            '0': 7,
            '1': 3,
            '2': 5,
            '3': 6,
            '4': 12,
            '5': 7,
            '6': 8,
            '7': 9,
            '8': 11,
            '9': 11,
            '10': 11,
            '11': 12,
            '12': 11,
            '13': 9,
            '14': 14,
            '15': 13,
            '16': 6,
            '17': 3,
            '18': 3,
            '19': 2,
            '20': 2,
            '21': 3,
            '22': 7,
            '23': 5,
        },
        post_9: {
            '0': 3,
            '1': 5,
            '2': 3,
            '3': 3,
            '4': 1,
            '5': 1,
            '6': 2,
            '7': 1,
            '8': 3,
            '9': 3,
            '10': 0,
            '11': 0,
            '12': 1,
            '13': 1,
            '14': 0,
            '15': 0,
            '16': 1,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 1,
        },
        post_30: {
            '0': 4,
            '1': 6,
            '2': 4,
            '3': 3,
            '4': 4,
            '5': 4,
            '6': 5,
            '7': 3,
            '8': 9,
            '9': 11,
            '10': 4,
            '11': 2,
            '12': 0,
            '13': 2,
            '14': 0,
            '15': 0,
            '16': 1,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 1,
            '21': 3,
            '22': 1,
            '23': 3,
        },
        post_61: {
            '0': 2,
            '1': 6,
            '2': 2,
            '3': 2,
            '4': 0,
            '5': 1,
            '6': 3,
            '7': 0,
            '8': 3,
            '9': 3,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 2,
            '14': 0,
            '15': 0,
            '16': 1,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 2,
        },
        post_62: {
            '0': 10,
            '1': 9,
            '2': 10,
            '3': 11,
            '4': 9,
            '5': 5,
            '6': 5,
            '7': 9,
            '8': 6,
            '9': 4,
            '10': 6,
            '11': 10,
            '12': 10,
            '13': 9,
            '14': 8,
            '15': 6,
            '16': 5,
            '17': 3,
            '18': 2,
            '19': 3,
            '20': 1,
            '21': 3,
            '22': 2,
            '23': 7,
        },
        post_475: {
            '0': 6,
            '1': 7,
            '2': 6,
            '3': 6,
            '4': 6,
            '5': 3,
            '6': 5,
            '7': 6,
            '8': 10,
            '9': 8,
            '10': 11,
            '11': 8,
            '12': 8,
            '13': 6,
            '14': 3,
            '15': 3,
            '16': 3,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 2,
            '23': 6,
        },
        post_494: {
            '0': 5,
            '1': 3,
            '2': 0,
            '3': 1,
            '4': 0,
            '5': 0,
            '6': 3,
            '7': 0,
            '8': 3,
            '9': 3,
            '10': 1,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 3,
            '17': 1,
            '18': 1,
            '19': 1,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
        },
        post_495: {
            '0': 6,
            '1': 9,
            '2': 7,
            '3': 3,
            '4': 4,
            '5': 7,
            '6': 4,
            '7': 3,
            '8': 2,
            '9': 4,
            '10': 0,
            '11': 0,
            '12': 1,
            '13': 1,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 2,
            '18': 2,
            '19': 2,
            '20': 4,
            '21': 3,
            '22': 4,
            '23': 7,
        },
        post_528: {
            '0': 0,
            '1': 2,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 2,
            '7': 0,
            '8': 1,
            '9': 1,
            '10': 0,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 0,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 0,
            '22': 0,
            '23': 0,
        },
        post_543: {
            '0': 5,
            '1': 5,
            '2': 1,
            '3': 0,
            '4': 1,
            '5': 0,
            '6': 3,
            '7': 1,
            '8': 3,
            '9': 3,
            '10': 2,
            '11': 0,
            '12': 0,
            '13': 0,
            '14': 0,
            '15': 0,
            '16': 3,
            '17': 0,
            '18': 0,
            '19': 0,
            '20': 0,
            '21': 1,
            '22': 2,
            '23': 2,
        },
    },
];
export const CUMSUM_DATA = {
    post_5: {
        '1676394000000': 50.4,
        '1676480400000': 97.2,
        '1676566800000': 163.4,
        '1676653200000': 212.6,
        '1676739600000': 218.5,
        '1676826000000': 267.3,
        '1676912400000': 299.5,
        '1676998800000': 315.1,
        '1677085200000': 328.5,
        '1677171600000': 330.1,
        '1677258000000': 332.4,
        '1677344400000': 334.7,
        '1677430800000': 334.3,
        '1677517200000': 332.3,
        '1677603600000': 334.6,
        '1677690000000': 335.8,
        '1677776400000': 332.7,
        '1677862800000': 329.6,
        '1677949200000': 331.0,
        '1678035600000': 334.2,
        '1678122000000': 338.2,
        '1678208400000': 337.5,
        '1678294800000': 340.8,
        '1678381200000': 345.2,
        '1678467600000': 346.1,
        '1678554000000': 343.5,
        '1678640400000': 344.0,
        '1678726800000': 362.2,
        '1678813200000': 382.0,
        '1678899600000': 394.6,
        '1678986000000': 407.6,
        '1679072400000': 415.1,
        '1679158800000': 413.1,
        '1679245200000': 414.3,
        '1679331600000': 422.4,
        '1679418000000': 447.3,
        '1679504400000': 453.7,
        '1679590800000': 457.3,
        '1679677200000': 462.0,
        '1679763600000': 463.2,
        '1679850000000': 468.6,
        '1679936400000': 474.3,
        '1680022800000': 485.0,
        '1680109200000': 481.9,
        '1680195600000': 477.6,
        '1680282000000': 486.6,
        '1680368400000': 494.7,
        '1680454800000': 497.1,
        '1680541200000': 497.1,
        '1680627600000': 499.2,
        '1680714000000': 510.3,
        '1680800400000': 511.3,
        '1680886800000': 506.9,
        '1680973200000': 506.3,
        '1681059600000': 511.6,
        '1681146000000': 508.4,
        '1681232400000': 505.7,
        '1681318800000': 508.6,
        '1681405200000': 508.4,
        '1681491600000': 503.7,
        '1681578000000': 504.7,
        '1681664400000': 503.2,
        '1681750800000': 501.0,
        '1681837200000': 504.8,
        '1681923600000': 512.0,
        '1682010000000': 525.3,
        '1682096400000': 544.1,
        '1682182800000': 554.3,
        '1682269200000': 556.1,
        '1682355600000': 552.5,
        '1682442000000': 563.8,
        '1682528400000': 581.1,
        '1682614800000': 584.3,
        '1682701200000': 581.6,
        '1682787600000': 579.9,
        '1682874000000': 578.6,
        '1682960400000': 579.4,
        '1683046800000': 586.5,
        '1683133200000': 592.5,
        '1683219600000': 611.6,
        '1683306000000': 643.5,
        '1683392400000': 701.7,
        '1683478800000': 756.0,
        '1683565200000': 800.1,
        '1683651600000': 804.9,
        '1683738000000': 813.5,
        '1683824400000': 821.3,
        '1683910800000': 818.7,
        '1683997200000': 815.9,
        '1684083600000': 816.6,
    },
    post_6: {
        '1676394000000': 72.3,
        '1676480400000': 91.7,
        '1676566800000': 127.9,
        '1676653200000': 169.8,
        '1676739600000': 177.3,
        '1676826000000': 188.0,
        '1676912400000': 193.8,
        '1676998800000': 202.3,
        '1677085200000': 209.2,
        '1677171600000': 211.6,
        '1677258000000': 215.7,
        '1677344400000': 218.8,
        '1677430800000': 219.8,
        '1677517200000': 216.2,
        '1677603600000': 215.5,
        '1677690000000': 217.0,
        '1677776400000': 214.7,
        '1677862800000': 212.7,
        '1677949200000': 210.3,
        '1678035600000': 215.3,
        '1678122000000': 216.0,
        '1678208400000': 214.9,
        '1678294800000': 216.4,
        '1678381200000': 218.7,
        '1678467600000': 217.7,
        '1678554000000': 216.2,
        '1678640400000': 214.7,
        '1678726800000': 216.8,
        '1678813200000': 218.2,
        '1678899600000': 222.4,
        '1678986000000': 220.9,
        '1679072400000': 223.7,
        '1679158800000': 222.0,
        '1679245200000': 220.8,
        '1679331600000': 218.7,
        '1679418000000': 219.8,
        '1679504400000': 218.8,
        '1679590800000': 219.6,
        '1679677200000': 222.7,
        '1679763600000': 225.1,
        '1679850000000': 228.6,
        '1679936400000': 232.0,
        '1680022800000': 237.9,
        '1680109200000': 233.5,
        '1680195600000': 229.5,
        '1680282000000': 227.2,
        '1680368400000': 224.9,
        '1680454800000': 223.2,
        '1680541200000': 222.1,
        '1680627600000': 220.0,
        '1680714000000': 222.3,
        '1680800400000': 220.5,
        '1680886800000': 216.2,
        '1680973200000': 213.4,
        '1681059600000': 212.3,
        '1681146000000': 208.8,
        '1681232400000': 204.9,
        '1681318800000': 203.6,
        '1681405200000': 200.1,
        '1681491600000': 195.8,
        '1681578000000': 193.2,
        '1681664400000': 192.2,
        '1681750800000': 189.8,
        '1681837200000': 190.9,
        '1681923600000': 197.0,
        '1682010000000': 211.8,
        '1682096400000': 219.3,
        '1682182800000': 221.4,
        '1682269200000': 223.5,
        '1682355600000': 220.5,
        '1682442000000': 228.0,
        '1682528400000': 236.4,
        '1682614800000': 236.6,
        '1682701200000': 233.7,
        '1682787600000': 233.1,
        '1682874000000': 232.4,
        '1682960400000': 231.6,
        '1683046800000': 231.3,
        '1683133200000': 233.5,
        '1683219600000': 260.2,
        '1683306000000': 294.9,
        '1683392400000': 352.8,
        '1683478800000': 401.8,
        '1683565200000': 459.4,
        '1683651600000': 463.6,
        '1683738000000': 468.5,
        '1683824400000': 476.5,
        '1683910800000': 473.3,
        '1683997200000': 471.3,
        '1684083600000': 471.8,
    },
    post_9: {
        '1676394000000': 26.4,
        '1676480400000': 50.6,
        '1676566800000': 71.2,
        '1676653200000': 86.8,
        '1676739600000': 89.2,
        '1676826000000': 96.9,
        '1676912400000': 100.0,
        '1676998800000': 104.8,
        '1677085200000': 107.7,
        '1677171600000': 107.9,
        '1677258000000': 106.8,
        '1677344400000': 106.8,
        '1677430800000': 104.4,
        '1677517200000': 100.2,
        '1677603600000': 97.3,
        '1677690000000': 97.1,
        '1677776400000': 92.7,
        '1677862800000': 88.3,
        '1677949200000': 84.7,
        '1678035600000': 84.4,
        '1678122000000': 82.6,
        '1678208400000': 79.6,
        '1678294800000': 80.6,
        '1678381200000': 81.1,
        '1678467600000': 79.1,
        '1678554000000': 74.9,
        '1678640400000': 70.2,
        '1678726800000': 68.4,
        '1678813200000': 67.5,
        '1678899600000': 66.5,
        '1678986000000': 69.5,
        '1679072400000': 69.5,
        '1679158800000': 65.7,
        '1679245200000': 61.6,
        '1679331600000': 57.1,
        '1679418000000': 55.4,
        '1679504400000': 54.0,
        '1679590800000': 51.2,
        '1679677200000': 48.6,
        '1679763600000': 47.3,
        '1679850000000': 45.5,
        '1679936400000': 45.7,
        '1680022800000': 47.4,
        '1680109200000': 44.7,
        '1680195600000': 41.4,
        '1680282000000': 38.7,
        '1680368400000': 35.7,
        '1680454800000': 32.3,
        '1680541200000': 28.4,
        '1680627600000': 25.0,
        '1680714000000': 24.7,
        '1680800400000': 23.0,
        '1680886800000': 18.4,
        '1680973200000': 13.5,
        '1681059600000': 10.2,
        '1681146000000': 5.6,
        '1681232400000': 0.6,
        '1681318800000': -3.1,
        '1681405200000': -6.0,
        '1681491600000': -9.8,
        '1681578000000': -12.3,
        '1681664400000': -15.0,
        '1681750800000': -19.1,
        '1681837200000': -20.9,
        '1681923600000': -20.4,
        '1682010000000': -20.6,
        '1682096400000': -19.1,
        '1682182800000': -18.1,
        '1682269200000': -18.3,
        '1682355600000': -22.3,
        '1682442000000': -17.9,
        '1682528400000': -13.7,
        '1682614800000': -15.9,
        '1682701200000': -18.5,
        '1682787600000': -18.7,
        '1682874000000': -19.7,
        '1682960400000': -20.1,
        '1683046800000': -16.9,
        '1683133200000': -15.4,
        '1683219600000': 3.8,
        '1683306000000': 25.2,
        '1683392400000': 66.0,
        '1683478800000': 96.7,
        '1683565200000': 129.4,
        '1683651600000': 137.3,
        '1683738000000': 142.2,
        '1683824400000': 152.1,
        '1683910800000': 149.9,
        '1683997200000': 146.9,
        '1684083600000': 147.1,
    },
    post_30: {
        '1676394000000': 37.8,
        '1676480400000': 65.7,
        '1676566800000': 95.2,
        '1676653200000': 112.5,
        '1676739600000': 116.3,
        '1676826000000': 126.6,
        '1676912400000': 130.1,
        '1676998800000': 135.1,
        '1677085200000': 138.9,
        '1677171600000': 140.2,
        '1677258000000': 141.2,
        '1677344400000': 142.6,
        '1677430800000': 140.8,
        '1677517200000': 136.8,
        '1677603600000': 134.4,
        '1677690000000': 135.1,
        '1677776400000': 131.8,
        '1677862800000': 127.8,
        '1677949200000': 124.5,
        '1678035600000': 125.1,
        '1678122000000': 124.0,
        '1678208400000': 121.2,
        '1678294800000': 120.7,
        '1678381200000': 121.4,
        '1678467600000': 119.9,
        '1678554000000': 116.4,
        '1678640400000': 112.5,
        '1678726800000': 116.3,
        '1678813200000': 120.7,
        '1678899600000': 121.7,
        '1678986000000': 131.8,
        '1679072400000': 135.0,
        '1679158800000': 132.3,
        '1679245200000': 129.9,
        '1679331600000': 126.6,
        '1679418000000': 125.9,
        '1679504400000': 124.9,
        '1679590800000': 123.3,
        '1679677200000': 121.5,
        '1679763600000': 120.9,
        '1679850000000': 120.7,
        '1679936400000': 122.2,
        '1680022800000': 125.2,
        '1680109200000': 122.6,
        '1680195600000': 119.9,
        '1680282000000': 117.9,
        '1680368400000': 116.2,
        '1680454800000': 113.5,
        '1680541200000': 110.6,
        '1680627600000': 108.7,
        '1680714000000': 109.2,
        '1680800400000': 107.3,
        '1680886800000': 103.0,
        '1680973200000': 99.1,
        '1681059600000': 97.2,
        '1681146000000': 92.5,
        '1681232400000': 87.7,
        '1681318800000': 84.7,
        '1681405200000': 80.9,
        '1681491600000': 78.2,
        '1681578000000': 75.9,
        '1681664400000': 73.1,
        '1681750800000': 69.5,
        '1681837200000': 69.7,
        '1681923600000': 71.2,
        '1682010000000': 74.2,
        '1682096400000': 76.3,
        '1682182800000': 78.0,
        '1682269200000': 79.2,
        '1682355600000': 75.8,
        '1682442000000': 81.7,
        '1682528400000': 87.9,
        '1682614800000': 87.2,
        '1682701200000': 85.1,
        '1682787600000': 85.0,
        '1682874000000': 84.9,
        '1682960400000': 84.2,
        '1683046800000': 87.6,
        '1683133200000': 89.8,
        '1683219600000': 109.5,
        '1683306000000': 133.7,
        '1683392400000': 179.2,
        '1683478800000': 221.7,
        '1683565200000': 261.0,
        '1683651600000': 269.8,
        '1683738000000': 275.1,
        '1683824400000': 286.8,
        '1683910800000': 284.9,
        '1683997200000': 282.2,
        '1684083600000': 284.6,
    },
    post_61: {
        '1676394000000': 21.5,
        '1676480400000': 40.8,
        '1676566800000': 59.6,
        '1676653200000': 73.2,
        '1676739600000': 75.7,
        '1676826000000': 82.0,
        '1676912400000': 84.4,
        '1676998800000': 87.9,
        '1677085200000': 90.8,
        '1677171600000': 91.5,
        '1677258000000': 91.5,
        '1677344400000': 92.2,
        '1677430800000': 90.3,
        '1677517200000': 86.8,
        '1677603600000': 84.7,
        '1677690000000': 84.8,
        '1677776400000': 81.3,
        '1677862800000': 77.3,
        '1677949200000': 74.1,
        '1678035600000': 73.9,
        '1678122000000': 72.2,
        '1678208400000': 69.5,
        '1678294800000': 68.4,
        '1678381200000': 68.5,
        '1678467600000': 66.9,
        '1678554000000': 63.3,
        '1678640400000': 58.8,
        '1678726800000': 57.0,
        '1678813200000': 56.1,
        '1678899600000': 55.7,
        '1678986000000': 58.6,
        '1679072400000': 58.7,
        '1679158800000': 55.6,
        '1679245200000': 51.9,
        '1679331600000': 48.0,
        '1679418000000': 47.1,
        '1679504400000': 45.9,
        '1679590800000': 44.2,
        '1679677200000': 42.3,
        '1679763600000': 41.5,
        '1679850000000': 40.6,
        '1679936400000': 41.6,
        '1680022800000': 43.7,
        '1680109200000': 40.9,
        '1680195600000': 37.6,
        '1680282000000': 35.4,
        '1680368400000': 32.3,
        '1680454800000': 29.4,
        '1680541200000': 26.2,
        '1680627600000': 23.1,
        '1680714000000': 23.5,
        '1680800400000': 21.9,
        '1680886800000': 17.7,
        '1680973200000': 13.5,
        '1681059600000': 10.9,
        '1681146000000': 7.1,
        '1681232400000': 2.9,
        '1681318800000': 0.0,
        '1681405200000': -2.9,
        '1681491600000': -6.6,
        '1681578000000': -7.9,
        '1681664400000': -10.1,
        '1681750800000': -13.3,
        '1681837200000': -14.1,
        '1681923600000': -12.5,
        '1682010000000': -11.6,
        '1682096400000': -9.2,
        '1682182800000': -7.9,
        '1682269200000': -6.6,
        '1682355600000': -9.8,
        '1682442000000': -4.8,
        '1682528400000': 0.5,
        '1682614800000': -0.6,
        '1682701200000': -2.8,
        '1682787600000': -3.0,
        '1682874000000': -3.6,
        '1682960400000': -3.9,
        '1683046800000': -0.9,
        '1683133200000': 0.9,
        '1683219600000': 20.5,
        '1683306000000': 42.5,
        '1683392400000': 82.3,
        '1683478800000': 113.6,
        '1683565200000': 147.2,
        '1683651600000': 155.1,
        '1683738000000': 160.4,
        '1683824400000': 170.2,
        '1683910800000': 168.1,
        '1683997200000': 165.6,
        '1684083600000': 165.7,
    },
    post_62: {
        '1676394000000': 68.5,
        '1676480400000': 110.9,
        '1676566800000': 151.5,
        '1676653200000': 200.6,
        '1676739600000': 207.7,
        '1676826000000': 220.7,
        '1676912400000': 226.6,
        '1676998800000': 235.3,
        '1677085200000': 241.2,
        '1677171600000': 243.9,
        '1677258000000': 247.0,
        '1677344400000': 250.3,
        '1677430800000': 251.6,
        '1677517200000': 248.3,
        '1677603600000': 247.2,
        '1677690000000': 249.5,
        '1677776400000': 248.8,
        '1677862800000': 248.6,
        '1677949200000': 246.6,
        '1678035600000': 251.1,
        '1678122000000': 253.9,
        '1678208400000': 253.4,
        '1678294800000': 255.3,
        '1678381200000': 257.7,
        '1678467600000': 257.2,
        '1678554000000': 255.0,
        '1678640400000': 255.0,
        '1678726800000': 259.6,
        '1678813200000': 267.1,
        '1678899600000': 272.0,
        '1678986000000': 271.5,
        '1679072400000': 275.9,
        '1679158800000': 274.6,
        '1679245200000': 274.3,
        '1679331600000': 273.6,
        '1679418000000': 276.5,
        '1679504400000': 276.4,
        '1679590800000': 278.6,
        '1679677200000': 280.6,
        '1679763600000': 283.2,
        '1679850000000': 286.5,
        '1679936400000': 290.6,
        '1680022800000': 295.5,
        '1680109200000': 291.2,
        '1680195600000': 286.9,
        '1680282000000': 284.8,
        '1680368400000': 284.2,
        '1680454800000': 283.6,
        '1680541200000': 283.4,
        '1680627600000': 282.2,
        '1680714000000': 286.4,
        '1680800400000': 284.8,
        '1680886800000': 281.5,
        '1680973200000': 280.0,
        '1681059600000': 280.0,
        '1681146000000': 276.5,
        '1681232400000': 273.4,
        '1681318800000': 272.4,
        '1681405200000': 269.3,
        '1681491600000': 265.1,
        '1681578000000': 264.4,
        '1681664400000': 263.3,
        '1681750800000': 260.8,
        '1681837200000': 261.8,
        '1681923600000': 268.7,
        '1682010000000': 282.6,
        '1682096400000': 291.2,
        '1682182800000': 294.4,
        '1682269200000': 296.2,
        '1682355600000': 293.6,
        '1682442000000': 301.0,
        '1682528400000': 310.3,
        '1682614800000': 310.4,
        '1682701200000': 307.4,
        '1682787600000': 306.2,
        '1682874000000': 305.5,
        '1682960400000': 304.5,
        '1683046800000': 307.3,
        '1683133200000': 309.9,
        '1683219600000': 333.1,
        '1683306000000': 362.7,
        '1683392400000': 417.2,
        '1683478800000': 462.3,
        '1683565200000': 510.6,
        '1683651600000': 513.5,
        '1683738000000': 517.7,
        '1683824400000': 524.0,
        '1683910800000': 520.6,
        '1683997200000': 518.6,
        '1684083600000': 518.3,
    },
    post_475: {
        '1676394000000': 53.7,
        '1676480400000': 73.4,
        '1676566800000': 113.5,
        '1676653200000': 141.9,
        '1676739600000': 147.8,
        '1676826000000': 160.1,
        '1676912400000': 167.2,
        '1676998800000': 174.7,
        '1677085200000': 182.9,
        '1677171600000': 184.8,
        '1677258000000': 188.1,
        '1677344400000': 190.3,
        '1677430800000': 190.6,
        '1677517200000': 186.8,
        '1677603600000': 185.6,
        '1677690000000': 186.0,
        '1677776400000': 183.8,
        '1677862800000': 181.6,
        '1677949200000': 181.2,
        '1678035600000': 182.7,
        '1678122000000': 183.9,
        '1678208400000': 183.7,
        '1678294800000': 185.0,
        '1678381200000': 186.5,
        '1678467600000': 186.4,
        '1678554000000': 184.1,
        '1678640400000': 183.5,
        '1678726800000': 188.1,
        '1678813200000': 196.7,
        '1678899600000': 199.3,
        '1678986000000': 199.4,
        '1679072400000': 200.2,
        '1679158800000': 198.3,
        '1679245200000': 195.6,
        '1679331600000': 193.7,
        '1679418000000': 196.8,
        '1679504400000': 195.7,
        '1679590800000': 195.8,
        '1679677200000': 197.4,
        '1679763600000': 199.0,
        '1679850000000': 201.3,
        '1679936400000': 204.7,
        '1680022800000': 209.0,
        '1680109200000': 204.1,
        '1680195600000': 199.6,
        '1680282000000': 196.8,
        '1680368400000': 196.5,
        '1680454800000': 194.2,
        '1680541200000': 193.2,
        '1680627600000': 189.9,
        '1680714000000': 194.3,
        '1680800400000': 191.8,
        '1680886800000': 188.7,
        '1680973200000': 186.1,
        '1681059600000': 184.7,
        '1681146000000': 181.9,
        '1681232400000': 179.1,
        '1681318800000': 178.1,
        '1681405200000': 175.0,
        '1681491600000': 171.7,
        '1681578000000': 170.6,
        '1681664400000': 168.4,
        '1681750800000': 165.8,
        '1681837200000': 166.6,
        '1681923600000': 172.4,
        '1682010000000': 185.3,
        '1682096400000': 199.3,
        '1682182800000': 201.8,
        '1682269200000': 203.3,
        '1682355600000': 201.3,
        '1682442000000': 207.1,
        '1682528400000': 216.7,
        '1682614800000': 217.2,
        '1682701200000': 214.4,
        '1682787600000': 212.7,
        '1682874000000': 210.9,
        '1682960400000': 209.2,
        '1683046800000': 209.0,
        '1683133200000': 211.1,
        '1683219600000': 238.4,
        '1683306000000': 272.4,
        '1683392400000': 316.9,
        '1683478800000': 364.4,
        '1683565200000': 405.4,
        '1683651600000': 409.9,
        '1683738000000': 415.6,
        '1683824400000': 421.1,
        '1683910800000': 417.9,
        '1683997200000': 416.1,
        '1684083600000': 416.1,
    },
    post_494: {
        '1676394000000': 11.0,
        '1676480400000': 23.4,
        '1676566800000': 43.1,
        '1676653200000': 45.5,
        '1676739600000': 49.3,
        '1676826000000': 59.7,
        '1676912400000': 63.1,
        '1676998800000': 68.6,
        '1677085200000': 73.9,
        '1677171600000': 76.0,
        '1677258000000': 75.1,
        '1677344400000': 77.6,
        '1677430800000': 75.4,
        '1677517200000': 71.9,
        '1677603600000': 70.0,
        '1677690000000': 71.6,
        '1677776400000': 67.0,
        '1677862800000': 63.1,
        '1677949200000': 59.0,
        '1678035600000': 59.3,
        '1678122000000': 56.7,
        '1678208400000': 54.0,
        '1678294800000': 51.5,
        '1678381200000': 52.3,
        '1678467600000': 51.6,
        '1678554000000': 47.6,
        '1678640400000': 42.5,
        '1678726800000': 40.7,
        '1678813200000': 38.0,
        '1678899600000': 36.7,
        '1678986000000': 34.8,
        '1679072400000': 34.6,
        '1679158800000': 30.8,
        '1679245200000': 26.8,
        '1679331600000': 22.7,
        '1679418000000': 22.0,
        '1679504400000': 21.1,
        '1679590800000': 18.7,
        '1679677200000': 17.8,
        '1679763600000': 19.6,
        '1679850000000': 17.9,
        '1679936400000': 20.8,
        '1680022800000': 24.4,
        '1680109200000': 20.1,
        '1680195600000': 15.5,
        '1680282000000': 11.3,
        '1680368400000': 7.7,
        '1680454800000': 4.2,
        '1680541200000': 0.1,
        '1680627600000': -5.1,
        '1680714000000': -4.3,
        '1680800400000': -6.5,
        '1680886800000': -11.3,
        '1680973200000': -16.1,
        '1681059600000': -18.7,
        '1681146000000': -23.5,
        '1681232400000': -28.6,
        '1681318800000': -31.8,
        '1681405200000': -36.4,
        '1681491600000': -41.2,
        '1681578000000': -44.7,
        '1681664400000': -47.8,
        '1681750800000': -51.7,
        '1681837200000': -53.5,
        '1681923600000': -52.4,
        '1682010000000': -51.3,
        '1682096400000': -49.2,
        '1682182800000': -46.0,
        '1682269200000': -44.0,
        '1682355600000': -48.1,
        '1682442000000': -44.1,
        '1682528400000': -37.4,
        '1682614800000': -39.1,
        '1682701200000': -41.4,
        '1682787600000': -42.0,
        '1682874000000': -39.7,
        '1682960400000': -41.2,
        '1683046800000': -38.9,
        '1683133200000': -38.4,
        '1683219600000': -15.8,
        '1683306000000': 8.0,
        '1683392400000': 47.0,
        '1683478800000': 79.4,
        '1683565200000': 117.7,
        '1683651600000': 123.1,
        '1683738000000': 127.6,
        '1683824400000': 136.1,
        '1683910800000': 132.7,
        '1683997200000': 129.9,
        '1684083600000': 133.4,
    },
    post_495: {
        '1676394000000': 35.8,
        '1676480400000': 71.3,
        '1676566800000': 109.5,
        '1676653200000': 136.2,
        '1676739600000': 138.8,
        '1676826000000': 148.6,
        '1676912400000': 153.6,
        '1676998800000': 159.9,
        '1677085200000': 163.1,
        '1677171600000': 162.0,
        '1677258000000': 163.5,
        '1677344400000': 163.4,
        '1677430800000': 160.8,
        '1677517200000': 155.7,
        '1677603600000': 152.1,
        '1677690000000': 150.6,
        '1677776400000': 146.8,
        '1677862800000': 142.2,
        '1677949200000': 138.4,
        '1678035600000': 138.6,
        '1678122000000': 137.4,
        '1678208400000': 134.6,
        '1678294800000': 135.3,
        '1678381200000': 134.9,
        '1678467600000': 134.2,
        '1678554000000': 132.1,
        '1678640400000': 128.4,
        '1678726800000': 127.9,
        '1678813200000': 133.1,
        '1678899600000': 136.2,
        '1678986000000': 143.5,
        '1679072400000': 143.6,
        '1679158800000': 141.4,
        '1679245200000': 139.9,
        '1679331600000': 135.8,
        '1679418000000': 135.4,
        '1679504400000': 134.8,
        '1679590800000': 132.0,
        '1679677200000': 129.5,
        '1679763600000': 127.7,
        '1679850000000': 126.9,
        '1679936400000': 126.6,
        '1680022800000': 128.8,
        '1680109200000': 127.0,
        '1680195600000': 126.8,
        '1680282000000': 129.2,
        '1680368400000': 125.8,
        '1680454800000': 121.9,
        '1680541200000': 119.0,
        '1680627600000': 115.8,
        '1680714000000': 117.3,
        '1680800400000': 115.5,
        '1680886800000': 111.3,
        '1680973200000': 107.0,
        '1681059600000': 103.7,
        '1681146000000': 99.2,
        '1681232400000': 94.0,
        '1681318800000': 90.9,
        '1681405200000': 87.3,
        '1681491600000': 83.0,
        '1681578000000': 83.2,
        '1681664400000': 80.1,
        '1681750800000': 79.4,
        '1681837200000': 80.1,
        '1681923600000': 82.5,
        '1682010000000': 85.4,
        '1682096400000': 87.6,
        '1682182800000': 89.7,
        '1682269200000': 91.1,
        '1682355600000': 87.9,
        '1682442000000': 93.0,
        '1682528400000': 101.2,
        '1682614800000': 100.3,
        '1682701200000': 98.6,
        '1682787600000': 101.0,
        '1682874000000': 100.9,
        '1682960400000': 101.7,
        '1683046800000': 103.2,
        '1683133200000': 103.6,
        '1683219600000': 121.5,
        '1683306000000': 147.2,
        '1683392400000': 199.0,
        '1683478800000': 239.9,
        '1683565200000': 269.4,
        '1683651600000': 279.5,
        '1683738000000': 284.2,
        '1683824400000': 295.8,
        '1683910800000': 293.6,
        '1683997200000': 291.3,
        '1684083600000': 293.4,
    },
    post_528: {
        '1676394000000': 19.6,
        '1676480400000': 40.6,
        '1676566800000': 56.5,
        '1676653200000': 69.8,
        '1676739600000': 71.5,
        '1676826000000': 77.7,
        '1676912400000': 79.3,
        '1676998800000': 82.6,
        '1677085200000': 84.9,
        '1677171600000': 84.6,
        '1677258000000': 83.3,
        '1677344400000': 82.8,
        '1677430800000': 79.7,
        '1677517200000': 75.2,
        '1677603600000': 72.0,
        '1677690000000': 71.1,
        '1677776400000': 66.3,
        '1677862800000': 61.6,
        '1677949200000': 57.6,
        '1678035600000': 57.0,
        '1678122000000': 54.1,
        '1678208400000': 50.5,
        '1678294800000': 48.1,
        '1678381200000': 47.5,
        '1678467600000': 45.0,
        '1678554000000': 40.8,
        '1678640400000': 36.0,
        '1678726800000': 33.4,
        '1678813200000': 31.3,
        '1678899600000': 29.9,
        '1678986000000': 30.2,
        '1679072400000': 30.6,
        '1679158800000': 26.6,
        '1679245200000': 22.2,
        '1679331600000': 17.9,
        '1679418000000': 15.4,
        '1679504400000': 13.1,
        '1679590800000': 10.4,
        '1679677200000': 7.9,
        '1679763600000': 6.7,
        '1679850000000': 4.8,
        '1679936400000': 4.8,
        '1680022800000': 6.1,
        '1680109200000': 1.8,
        '1680195600000': -2.6,
        '1680282000000': -6.0,
        '1680368400000': -9.4,
        '1680454800000': -12.7,
        '1680541200000': -16.7,
        '1680627600000': -21.6,
        '1680714000000': -22.3,
        '1680800400000': -25.2,
        '1680886800000': -30.1,
        '1680973200000': -35.1,
        '1681059600000': -38.7,
        '1681146000000': -43.6,
        '1681232400000': -48.8,
        '1681318800000': -52.7,
        '1681405200000': -57.1,
        '1681491600000': -61.6,
        '1681578000000': -64.5,
        '1681664400000': -68.1,
        '1681750800000': -72.1,
        '1681837200000': -74.1,
        '1681923600000': -73.6,
        '1682010000000': -74.4,
        '1682096400000': -73.9,
        '1682182800000': -73.4,
        '1682269200000': -73.6,
        '1682355600000': -77.7,
        '1682442000000': -74.3,
        '1682528400000': -70.7,
        '1682614800000': -72.7,
        '1682701200000': -75.6,
        '1682787600000': -76.5,
        '1682874000000': -77.9,
        '1682960400000': -79.7,
        '1683046800000': -77.6,
        '1683133200000': -77.1,
        '1683219600000': -59.8,
        '1683306000000': -42.1,
        '1683392400000': -7.8,
        '1683478800000': 21.1,
        '1683565200000': 47.7,
        '1683651600000': 51.7,
        '1683738000000': 54.3,
        '1683824400000': 62.0,
        '1683910800000': 59.0,
        '1683997200000': 55.4,
        '1684083600000': 54.8,
    },
    post_543: {
        '1676394000000': 16.2,
        '1676480400000': 30.9,
        '1676566800000': 50.5,
        '1676653200000': 53.5,
        '1676739600000': 58.1,
        '1676826000000': 65.5,
        '1676912400000': 68.2,
        '1676998800000': 72.2,
        '1677085200000': 75.7,
        '1677171600000': 76.5,
        '1677258000000': 77.2,
        '1677344400000': 79.3,
        '1677430800000': 77.6,
        '1677517200000': 73.7,
        '1677603600000': 71.5,
        '1677690000000': 72.8,
        '1677776400000': 68.5,
        '1677862800000': 64.6,
        '1677949200000': 60.5,
        '1678035600000': 61.0,
        '1678122000000': 59.5,
        '1678208400000': 56.7,
        '1678294800000': 54.9,
        '1678381200000': 54.5,
        '1678467600000': 52.8,
        '1678554000000': 49.1,
        '1678640400000': 44.5,
        '1678726800000': 42.1,
        '1678813200000': 39.0,
        '1678899600000': 38.3,
        '1678986000000': 37.8,
        '1679072400000': 38.1,
        '1679158800000': 35.0,
        '1679245200000': 31.4,
        '1679331600000': 27.5,
        '1679418000000': 25.5,
        '1679504400000': 24.0,
        '1679590800000': 22.0,
        '1679677200000': 20.9,
        '1679763600000': 21.4,
        '1679850000000': 21.0,
        '1679936400000': 23.4,
        '1680022800000': 27.1,
        '1680109200000': 23.1,
        '1680195600000': 19.0,
        '1680282000000': 18.0,
        '1680368400000': 14.7,
        '1680454800000': 11.9,
        '1680541200000': 9.0,
        '1680627600000': 4.1,
        '1680714000000': 4.3,
        '1680800400000': 1.6,
        '1680886800000': -3.2,
        '1680973200000': -7.4,
        '1681059600000': -9.9,
        '1681146000000': -14.7,
        '1681232400000': -19.6,
        '1681318800000': -22.9,
        '1681405200000': -27.3,
        '1681491600000': -32.3,
        '1681578000000': -34.9,
        '1681664400000': -38.1,
        '1681750800000': -41.5,
        '1681837200000': -42.3,
        '1681923600000': -40.6,
        '1682010000000': -38.5,
        '1682096400000': -37.2,
        '1682182800000': -35.7,
        '1682269200000': -33.0,
        '1682355600000': -36.5,
        '1682442000000': -30.5,
        '1682528400000': -24.0,
        '1682614800000': -24.4,
        '1682701200000': -26.1,
        '1682787600000': -25.3,
        '1682874000000': -25.5,
        '1682960400000': -26.8,
        '1683046800000': -23.9,
        '1683133200000': -22.1,
        '1683219600000': -1.1,
        '1683306000000': 24.6,
        '1683392400000': 68.0,
        '1683478800000': 105.1,
        '1683565200000': 148.7,
        '1683651600000': 157.1,
        '1683738000000': 161.2,
        '1683824400000': 173.8,
        '1683910800000': 171.0,
        '1683997200000': 167.4,
        '1684083600000': 167.8,
    },
};
export const WHO_DATA = [
    {
        '1676394000000': 10,
        '1676480400000': 20,
        '1676566800000': 30,
        '1676653200000': 40,
        '1676739600000': 50,
        '1676826000000': 60,
        '1676912400000': 70,
        '1676998800000': 80,
        '1677085200000': 90,
        '1677171600000': 100,
        '1677258000000': 110,
        '1677344400000': 120,
        '1677430800000': 130,
        '1677517200000': 140,
        '1677603600000': 150,
        '1677690000000': 160,
        '1677776400000': 170,
        '1677862800000': 180,
        '1677949200000': 190,
        '1678035600000': 200,
        '1678122000000': 210,
        '1678208400000': 220,
        '1678294800000': 230,
        '1678381200000': 240,
        '1678467600000': 250,
        '1678554000000': 260,
        '1678640400000': 270,
        '1678726800000': 280,
        '1678813200000': 290,
        '1678899600000': 300,
        '1678986000000': 310,
        '1679072400000': 320,
        '1679158800000': 330,
        '1679245200000': 340,
        '1679331600000': 350,
        '1679418000000': 360,
        '1679504400000': 370,
        '1679590800000': 380,
        '1679677200000': 390,
        '1679763600000': 400,
        '1679850000000': 410,
        '1679936400000': 420,
        '1680022800000': 430,
        '1680109200000': 440,
        '1680195600000': 450,
        '1680282000000': 460,
        '1680368400000': 470,
        '1680454800000': 480,
        '1680541200000': 490,
        '1680627600000': 500,
        '1680714000000': 510,
        '1680800400000': 520,
        '1680886800000': 530,
        '1680973200000': 540,
        '1681059600000': 550,
        '1681146000000': 560,
        '1681232400000': 570,
        '1681318800000': 580,
        '1681405200000': 590,
        '1681491600000': 600,
        '1681578000000': 610,
        '1681664400000': 620,
        '1681750800000': 630,
        '1681837200000': 640,
        '1681923600000': 650,
        '1682010000000': 660,
        '1682096400000': 670,
        '1682182800000': 680,
        '1682269200000': 690,
        '1682355600000': 700,
        '1682442000000': 710,
        '1682528400000': 720,
        '1682614800000': 730,
        '1682701200000': 740,
        '1682787600000': 750,
        '1682874000000': 760,
        '1682960400000': 770,
        '1683046800000': 780,
        '1683133200000': 790,
        '1683219600000': 800,
        '1683306000000': 810,
        '1683392400000': 820,
        '1683478800000': 830,
        '1683565200000': 840,
        '1683651600000': 850,
        '1683738000000': 860,
        '1683824400000': 870,
        '1683910800000': 880,
        '1683997200000': 890,
        '1684083600000': 900,
    },
    {
        '1676394000000': 0,
        '1676480400000': 0,
        '1676566800000': 0,
        '1676653200000': 0,
        '1676739600000': 0,
        '1676826000000': 0,
        '1676912400000': 0,
        '1676998800000': 0,
        '1677085200000': 0,
        '1677171600000': 0,
        '1677258000000': 0,
        '1677344400000': 0,
        '1677430800000': 0,
        '1677517200000': 0,
        '1677603600000': 0,
        '1677690000000': 0,
        '1677776400000': 0,
        '1677862800000': 0,
        '1677949200000': 0,
        '1678035600000': 0,
        '1678122000000': 0,
        '1678208400000': 0,
        '1678294800000': 0,
        '1678381200000': 0,
        '1678467600000': 0,
        '1678554000000': 0,
        '1678640400000': 0,
        '1678726800000': 0,
        '1678813200000': 0,
        '1678899600000': 0,
        '1678986000000': 0,
        '1679072400000': 0,
        '1679158800000': 0,
        '1679245200000': 0,
        '1679331600000': 0,
        '1679418000000': 0,
        '1679504400000': 0,
        '1679590800000': 0,
        '1679677200000': 0,
        '1679763600000': 0,
        '1679850000000': 0,
        '1679936400000': 0,
        '1680022800000': 0,
        '1680109200000': 0,
        '1680195600000': 0,
        '1680282000000': 0,
        '1680368400000': 0,
        '1680454800000': 0,
        '1680541200000': 0,
        '1680627600000': 0,
        '1680714000000': 0,
        '1680800400000': 0,
        '1680886800000': 0,
        '1680973200000': 0,
        '1681059600000': 0,
        '1681146000000': 0,
        '1681232400000': 0,
        '1681318800000': 0,
        '1681405200000': 0,
        '1681491600000': 0,
        '1681578000000': 0,
        '1681664400000': 0,
        '1681750800000': 0,
        '1681837200000': 0,
        '1681923600000': 0,
        '1682010000000': 0,
        '1682096400000': 0,
        '1682182800000': 0,
        '1682269200000': 0,
        '1682355600000': 0,
        '1682442000000': 0,
        '1682528400000': 0,
        '1682614800000': 0,
        '1682701200000': 0,
        '1682787600000': 0,
        '1682874000000': 0,
        '1682960400000': 0,
        '1683046800000': 0,
        '1683133200000': 0,
        '1683219600000': 0,
        '1683306000000': 0,
        '1683392400000': 0,
        '1683478800000': 0,
        '1683565200000': 0,
        '1683651600000': 0,
        '1683738000000': 0,
        '1683824400000': 0,
        '1683910800000': 0,
        '1683997200000': 0,
        '1684083600000': 0,
    },
    {
        '1676394000000': -5,
        '1676480400000': -10,
        '1676566800000': -15,
        '1676653200000': -20,
        '1676739600000': -25,
        '1676826000000': -30,
        '1676912400000': -35,
        '1676998800000': -40,
        '1677085200000': -45,
        '1677171600000': -50,
        '1677258000000': -55,
        '1677344400000': -60,
        '1677430800000': -65,
        '1677517200000': -70,
        '1677603600000': -75,
        '1677690000000': -80,
        '1677776400000': -85,
        '1677862800000': -90,
        '1677949200000': -95,
        '1678035600000': -100,
        '1678122000000': -105,
        '1678208400000': -110,
        '1678294800000': -115,
        '1678381200000': -120,
        '1678467600000': -125,
        '1678554000000': -130,
        '1678640400000': -135,
        '1678726800000': -140,
        '1678813200000': -145,
        '1678899600000': -150,
        '1678986000000': -155,
        '1679072400000': -160,
        '1679158800000': -165,
        '1679245200000': -170,
        '1679331600000': -175,
        '1679418000000': -180,
        '1679504400000': -185,
        '1679590800000': -190,
        '1679677200000': -195,
        '1679763600000': -200,
        '1679850000000': -205,
        '1679936400000': -210,
        '1680022800000': -215,
        '1680109200000': -220,
        '1680195600000': -225,
        '1680282000000': -230,
        '1680368400000': -235,
        '1680454800000': -240,
        '1680541200000': -245,
        '1680627600000': -250,
        '1680714000000': -255,
        '1680800400000': -260,
        '1680886800000': -265,
        '1680973200000': -270,
        '1681059600000': -275,
        '1681146000000': -280,
        '1681232400000': -285,
        '1681318800000': -290,
        '1681405200000': -295,
        '1681491600000': -300,
        '1681578000000': -305,
        '1681664400000': -310,
        '1681750800000': -315,
        '1681837200000': -320,
        '1681923600000': -325,
        '1682010000000': -330,
        '1682096400000': -335,
        '1682182800000': -340,
        '1682269200000': -345,
        '1682355600000': -350,
        '1682442000000': -355,
        '1682528400000': -360,
        '1682614800000': -365,
        '1682701200000': -370,
        '1682787600000': -375,
        '1682874000000': -380,
        '1682960400000': -385,
        '1683046800000': -390,
        '1683133200000': -395,
        '1683219600000': -400,
        '1683306000000': -405,
        '1683392400000': -410,
        '1683478800000': -415,
        '1683565200000': -420,
        '1683651600000': -425,
        '1683738000000': -430,
        '1683824400000': -435,
        '1683910800000': -440,
        '1683997200000': -445,
        '1684083600000': -450,
    },
];
export const FORECAST_TIMELINE_DATA = {
    post_5: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 176,
        '2023-05-09T18:00:00Z': 185,
        '2023-05-09T19:00:00Z': 186,
        '2023-05-09T20:00:00Z': 182,
        '2023-05-09T21:00:00Z': 173,
        '2023-05-09T22:00:00Z': 164,
        '2023-05-09T23:00:00Z': 158,
    },
    post_6: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 265,
        '2023-05-09T18:00:00Z': 194,
        '2023-05-09T19:00:00Z': 185,
        '2023-05-09T20:00:00Z': 173,
        '2023-05-09T21:00:00Z': 163,
        '2023-05-09T22:00:00Z': 155.9797740709,
        '2023-05-09T23:00:00Z': 154.3523013372,
    },
    post_9: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 172.0,
        '2023-05-09T18:00:00Z': 156.1220354093,
        '2023-05-09T19:00:00Z': 148.9314487669,
        '2023-05-09T20:00:00Z': 143.5169434919,
        '2023-05-09T21:00:00Z': 139.3838193116,
        '2023-05-09T22:00:00Z': 134.9152002142,
        '2023-05-09T23:00:00Z': 131.7780470833,
    },
    post_30: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 199.0,
        '2023-05-09T18:00:00Z': 155.236643409,
        '2023-05-09T19:00:00Z': 150.0745664046,
        '2023-05-09T20:00:00Z': 145.7520305445,
        '2023-05-09T21:00:00Z': 141.7268630776,
        '2023-05-09T22:00:00Z': 137.2706094737,
        '2023-05-09T23:00:00Z': 133.932161381,
    },
    post_62: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 231.0,
        '2023-05-09T18:00:00Z': 200.5729270602,
        '2023-05-09T19:00:00Z': 190.2172672416,
        '2023-05-09T20:00:00Z': 178.7996763041,
        '2023-05-09T21:00:00Z': 168.2587756866,
        '2023-05-09T22:00:00Z': 160.2726295651,
        '2023-05-09T23:00:00Z': 157.7547896016,
    },
    post_475: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 257.0,
        '2023-05-09T18:00:00Z': 217.9798114171,
        '2023-05-09T19:00:00Z': 180.875846334,
        '2023-05-09T20:00:00Z': 157.4024809143,
        '2023-05-09T21:00:00Z': 147.6811509255,
        '2023-05-09T22:00:00Z': 137.6248907539,
        '2023-05-09T23:00:00Z': 139.5120139939,
    },
    post_495: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 236.0,
        '2023-05-09T18:00:00Z': 222.9019877071,
        '2023-05-09T19:00:00Z': 193.5446975473,
        '2023-05-09T20:00:00Z': 173.7106091024,
        '2023-05-09T21:00:00Z': 164.4913616961,
        '2023-05-09T22:00:00Z': 152.737726021,
        '2023-05-09T23:00:00Z': 151.8992825292,
    },
    post_543: {
        '2023-05-09T13:00:00Z': null,
        '2023-05-09T14:00:00Z': null,
        '2023-05-09T15:00:00Z': null,
        '2023-05-09T16:00:00Z': null,
        '2023-05-09T17:00:00Z': 215.0,
        '2023-05-09T18:00:00Z': 155.6246794937,
        '2023-05-09T19:00:00Z': 151.9729167212,
        '2023-05-09T20:00:00Z': 148.5791977462,
        '2023-05-09T21:00:00Z': 144.7720177127,
        '2023-05-09T22:00:00Z': 140.2671263686,
        '2023-05-09T23:00:00Z': 136.6377680368,
    },
};
export const WIND_DIR_BY_POST = {
    post_6: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 4,
            E: 13,
            SE: 7,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_9: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_30: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_61: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_62: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 2,
            E: 13,
            SE: 9,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_475: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 5,
            E: 12,
            SE: 7,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_494: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_495: {
        velocity: 3,
        direction: 'E',
        daily_stats: {
            N: 0,
            NE: 2,
            E: 14,
            SE: 8,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_528: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 7,
            E: 5,
            SE: 10,
            S: 1,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
    post_543: {
        velocity: 3,
        direction: 'SE',
        daily_stats: {
            N: 0,
            NE: 8,
            E: 5,
            SE: 10,
            S: 0,
            SW: 0,
            W: 0,
            NW: 0,
        },
    },
};
