import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxItem } from '@libs/common/types/checkbox-item';

@Component({
    selector: 'ca-impact-map-object-selector',
    templateUrl: './impact-map-object-selector.component.html',
    styleUrls: ['./impact-map-object-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactMapObjectSelectorComponent {
    @Input() list: CheckboxItem[];
    @Output() changesList = new EventEmitter<CheckboxItem[]>();
    showDropDown = false;

    change($event) {
        this.changesList.emit($event);
    }
}
