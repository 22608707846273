<div (clickOutside)="close()" class="input_date_range">
    <div (click)="toggleShow()">
        <input-for-dropdown
            [label]="labelText"
            [inputValue]="getIntervalText()"
            [(isOpenDropdown)]="showCalendar"
        ></input-for-dropdown>
    </div>
    <div *ngIf="descriptionText" class="input_date_range__description">{{ descriptionText }}</div>
    <calendar-months
        [show]="showCalendar"
        [timeBegin]="timeBegin"
        [timeEnd]="timeEnd"
        [applyCb]="updateTime"
        [updateDays]="updateDays"
        [monthsVisible]="displayMonth"
        [columns]="displayMonth"
        [dynamicWidth]="true"
        [numberOfMonths]="numberOfMonths"
    ></calendar-months>
</div>
