import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { Post } from '@libs/common/models/basicModels';

@Component({
    selector: 'device-card',
    templateUrl: './device-card.component.html',
    styleUrls: ['./device-card.component.less'],
})
export class DeviceCardComponent {
    @Input() serialNumber = '';
    @Input() isOnline = false;
    @Input() isBattery = false;
    @Input() isNetworks = false;
    @Input() post: Post;

    @Output() cbClickForStaticTags = new EventEmitter<{ type: string; id: any }>();
    @Output() openActionsPopup = new EventEmitter<{ target: HTMLElement; positionTop: number }>();

    textOnline = TEXTS.NOTIFICATIONS.alertTag.online;
    textOffline = TEXTS.NOTIFICATIONS.alertTag.offline;
    textNotMo = TEXTS.LIST_DEVICES.deviceNoPost;
    textList = TEXTS.LIST_OM.objectsView;

    clickForStaticTags(type: string, id: any) {
        this.cbClickForStaticTags.emit({ type: type, id: id });
    }

    openActions({ target, positionTop }) {
        this.openActionsPopup.emit({
            target,
            positionTop,
        });
    }

    getTextOfPower() {
        return this.isNetworks
            ? TEXTS.NOTIFICATIONS.alertTag.onNetworks
            : this.isBattery
            ? TEXTS.NOTIFICATIONS.alertTag.onBattery
            : TEXTS.NOTIFICATIONS.alertTag.thereIsNoData;
    }

    getTypeOfPower() {
        return this.isNetworks ? 'green' : this.isBattery ? 'yellow' : 'gray';
    }
}
