import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';
import { ControlPointModel } from '@cityair/namespace';
@Component({
    selector: 'cityair-info-pin',
    templateUrl: './info-pin.component.html',
    styleUrls: ['./info-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPinComponent {
    isFalseNumber = isFalseNumber;
    @Input() color: string;
    @Input() name: string;
    @Input() type = 'default';
    isShowName = false;
}
