import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'input-for-dropdown',
    templateUrl: 'input-for-dropdown.component.html',
    styleUrls: ['input-for-dropdown.component.less'],
})
export class InputForDropdownComponent {
    @Input() label: string;
    @Input() inputValue: string;
    @Input() isOpenDropdown: boolean;
    @Input() isError = false;
    @Input() isDisable = false;
    @Input() showLabelInfoIcon = false;

    @Output() isOpenDropdownChange = new EventEmitter<boolean>();
    @Output() clickInfoIcon = new EventEmitter();

    texts = TEXTS;

    toggleShow() {
        this.isOpenDropdownChange.emit(!this.isOpenDropdown);
    }

    clickInfo($event) {
        $event.stopPropagation();
        this.clickInfoIcon.emit();
    }
}
