import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultPlaceholderComponent } from './search-result-placeholder.component';

@NgModule({
    declarations: [SearchResultPlaceholderComponent],
    exports: [SearchResultPlaceholderComponent],
    imports: [CommonModule],
})
export class SearchResultPlaceholderModule {}
