import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceCardComponent } from './device-card.component';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { AlertTagsModule } from '@libs/shared-ui/components/alert-tags/alert-tags.module';

@NgModule({
    imports: [CommonModule, KebabMenuModule, StaticTagsModule, AlertTagsModule],
    exports: [DeviceCardComponent],
    declarations: [DeviceCardComponent],
})
export class DeviceCardModule {}
