import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CheckboxItem } from '@libs/common/types/checkbox-item';

@Component({
    selector: 'cityair-filter-data-list',
    templateUrl: './filter-data-list.component.html',
    styleUrls: ['./filter-data-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDataListComponent implements OnChanges {
    @Input() list: CheckboxItem[];
    @Input() label: string;
    @Output() setValue = new EventEmitter<CheckboxItem>();
    selectedValue: CheckboxItem = null;
    isShowDropdown = false;
    public selectValue(item) {
        this.setValue.emit(item);
    }
    ngOnChanges(changes: SimpleChanges) {
        this.selectedValue = this.list?.filter((v) => v.selected)?.[0];
    }
}
