import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { SortList } from './sort-list/sort-list.component';
import { HeaderPageComponent } from './header-page/header-page.component';
import { ControlSelectComponent } from './control-select/control-select.component';
import { StringChoiceComponent } from './string-choice/string-choice.component';

import {
    StationLoadingSplash,
    StndAskPopup,
    StndAskPopup2,
    StndAskPopupThreeQuestions,
    StndErrorPopup,
    StndPopup,
} from './blank';

import { Loader, LoaderMap } from './imgs';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { ArrowModule } from '@libs/shared-ui/components/icons/components/arrow/arrow.module';

import { ListHeaderModule } from './list-header/list-header.module';
import { ScrollTopModule } from './scroll-top/scroll-top.module';
import { SubstancesListModule } from './substances-list/substances-list.module';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        TooltipsModule,
        LoadersModule,
        ArrowModule,
        CrossButtonModule,
        SearchInputModule,
        ItemRadioModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        AqiDetailsTooltipModule,
        ValueCircleModule,
        ListHeaderModule,
        ScrollTopModule,
        SubstancesListModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    exports: [
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        SortList,
        StationLoadingSplash,
        HeaderPageComponent,
        ControlSelectComponent,
        StringChoiceComponent,
        SubstancesListModule,
        DropdownButtonComponent,
        ListHeaderModule,
        ScrollTopModule,
        ButtonModule,
        ButtonsSaveCancelModule,
    ],
    declarations: [
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        SortList,
        StationLoadingSplash,
        HeaderPageComponent,
        ControlSelectComponent,
        StringChoiceComponent,
        DropdownButtonComponent,
    ],
})
export class LittleComponentsModule {}
