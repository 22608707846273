import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { formatNumber } from '@angular/common';
import { LANGUAGE } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'post-pin-rectangle',
    templateUrl: './post-pin-rectangle.component.html',
    styleUrls: ['./post-pin-rectangle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostPinRectangleComponent implements OnChanges {
    markerState = markerState;
    isFalseNumber = isFalseNumber;

    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() state: markerState;
    @Input() decimalFormat = false; // обрезать нули после запятой

    displayValue: string | number;
    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            if (this.decimalFormat) {
                this.displayValue = hasNoData
                    ? '–'
                    : formatNumber(value, LANGUAGE, '1.' + numberAfterDot + '-' + numberAfterDot);
            } else {
                this.displayValue = hasNoData ? 0 : Number(value.toFixed(numberAfterDot));
            }

            this.hasNoData = hasNoData;
        }
    }
}
