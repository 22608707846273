import { GroupUser } from '@libs/common/models/basicModels';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import * as usersActions from '@cityair/modules/settings/store/users/actions';

export enum UserPages {
    USERS_LIST = 'users-list',
    EDIT_USER = 'edit-user',
}
export interface UsersState {
    users: EntityState<GroupUser>;
    isLoadingUsers: boolean;
    isLoadingForm: boolean;
    closeUserForm: boolean;
    currentUserForm: GroupUser;
    formError: HttpErrorResponse;
    foundUserResult: any;
    currentPage: UserPages;
}
export const usersAdapter: EntityAdapter<GroupUser> = createEntityAdapter<GroupUser>();

const initialUsersState: UsersState = {
    users: usersAdapter.getInitialState(),
    isLoadingUsers: true,
    isLoadingForm: false,
    closeUserForm: false,
    currentUserForm: null,
    formError: null,
    foundUserResult: null,
    currentPage: UserPages.USERS_LIST,
};
export const usersReducers = createReducer(
    initialUsersState,
    on(usersActions.setUsersList, (state: UsersState, { payload }) => {
        const response = payload.data as GroupUser[];
        const users = usersAdapter.setMany(response, state.users);
        return {
            ...state,
            users,
            isLoadingUsers: false,
        };
    }),
    on(usersActions.isLoadingUsers, (state: UsersState, { payload }) => ({
        ...state,
        isLoadingUsers: payload,
    })),
    on(usersActions.setFoundUserResult, (state: UsersState, { payload }) => ({
        ...state,
        foundUserResult: payload,
    })),
    on(usersActions.closeUserForm, (state: UsersState) => ({
        ...state,
        currentUserForm: null,
        currentPage: UserPages.USERS_LIST,
        isLoadingForm: false,
        closeUserForm: true,
        formError: null,
    })),
    on(usersActions.deleteUserSuccess, (state: UsersState, { userId }) => {
        const users = usersAdapter.removeOne(userId, state.users);
        return { ...state, users: users };
    }),
    on(usersActions.setLoadingForm, (state: UsersState, { payload }) => ({
        ...state,
        isLoadingForm: payload,
    })),
    on(usersActions.createUserSuccess, (state: UsersState, { payload }) => {
        const user = payload as GroupUser;
        const users = usersAdapter.addOne(user, state.users);
        return { ...state, users: users };
    }),
    on(usersActions.updateUserSuccess, (state: UsersState, { payload }) => {
        const user = payload as GroupUser;
        const users = usersAdapter.upsertOne(user, state.users);
        return { ...state, users: users };
    }),
    on(usersActions.setFormError, (state: UsersState, { payload }) => ({
        ...state,
        formError: payload,
    })),
    on(usersActions.toggleUserForm, (state: UsersState, { payload }) => ({
        ...state,
        closeUserForm: payload,
    }))
);
