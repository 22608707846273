import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionHistory } from '@cityair/modules/posts-and-devices/posts-and-devices.collection';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { TEXTS } from '@libs/common/texts/texts';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { CITYAIR_STND_DEVICE } from '@cityair/namespace';
import { selectDevice } from '@cityair/modules/core/store/actions';
import { Store } from '@ngrx/store';
import { Device, Post } from '@libs/common/models/basicModels';
import { selectDeviceSourceNameByType } from '@cityair/modules/core/store/selectors';
import { selectPostEntitiesById } from '@cityair/modules/core/store/posts/posts.feature';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'device-details',
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.less'],
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {
    @Input() device: Device;

    public ngDestroyed$ = new Subject<void>();
    CITYAIR_STND_DEVICE = CITYAIR_STND_DEVICE;
    post: Post;
    isShowActionHistory = false;
    actionHistory: ActionHistory[] = null;
    TEXTS = TEXTS;
    pages = NETWORK_PAGES;
    path = '';
    selectDeviceSourceNameByType = selectDeviceSourceNameByType;
    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public activatedRoute: ActivatedRoute,
        public store: Store<any>,
        public popupProvider: OffPanelPopupService
    ) {}

    ngOnInit() {
        this.path =
            this.pages.networks +
            '/' +
            this.activatedRoute.snapshot.url[0] +
            '/' +
            this.activatedRoute.snapshot.url[1];
        const serialNumber = this.device.serial_number;
        this.store.dispatch(selectDevice({ serialNumber }));
        this.store
            .select(selectPostEntitiesById(this.device?.ancestor?.id))
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v)
            )
            .subscribe((post) => (this.post = post));
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    getTextOfPower() {
        return this.device?.ext_info?.ps220
            ? this.TEXTS.NOTIFICATIONS.alertTag.onNetworks
            : this.device?.ext_info?.bat_ok
            ? this.TEXTS.NOTIFICATIONS.alertTag.onBattery
            : this.TEXTS.NOTIFICATIONS.alertTag.thereIsNoData;
    }

    getTypeOfPower() {
        return this.device?.ext_info?.ps220
            ? 'green'
            : this.device?.ext_info?.bat_ok
            ? 'yellow'
            : 'gray';
    }
}
