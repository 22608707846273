import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';

import { WindowGlobalVars } from '@cityair/namespace';
import { environment } from 'environments/environment';
import { LANGUAGE } from '@libs/common/texts/texts';

declare const window: WindowGlobalVars;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const { url } = req;

        if (url.indexOf('/harvester/v2/') !== -1) {
            const updatedRequest = req.clone({
                headers: req.headers
                    .set('Authorization', `Bearer ${window.JS_CP_TOKEN}`)
                    .set('Accept-Language', LANGUAGE),
            });

            return next.handle(updatedRequest);
        } else if (url.indexOf(`${environment.vanga_api_url}/auth/`) !== -1) {
            const updatedRequest = req.clone({
                headers: req.headers
                    .set('Authorization', `Token ${window.JS_CP_TOKEN}`)
                    .set('Accept-Language', LANGUAGE),
            });

            return next.handle(updatedRequest);
        } else {
            return next.handle(req);
        }
    }
}
