import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'shared-ui-group-list-item',
    templateUrl: './group-list-item.component.html',
    styleUrls: ['./group-list-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupListItemComponent {
    @Input() name: string;
    @Input() selected: boolean;
    @Input() postsCount: number;
    @Input() iconUrl: string;

    public textProfilePost = TEXTS.PROFILE.post;
}
