import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { environment } from 'environments/environment';
import { RunPlume } from './models';
import { RUN_ENTITY_STORE_KEY } from '../../constants';

const PLUMES_API_URL = `${environment.vanga_api_url}/plumes/v1/`;

@Injectable({
    providedIn: 'root',
})
export class RunDataService extends DefaultDataService<RunPlume> {
    private urlPath = this.urlGenerator.collectionResource(RUN_ENTITY_STORE_KEY, '');

    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super(RUN_ENTITY_STORE_KEY, http, urlGenerator, { root: PLUMES_API_URL });
    }

    getWithQuery(params: string | QueryParams): Observable<RunPlume[]> {
        const httpOptions = this.getHttpOptions(params);
        const urlPath = this.urlPath;

        return this.http
            .get(urlPath, httpOptions)
            .pipe(map((response: any) => response as RunPlume[]));
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
