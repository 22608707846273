<div
    #pin
    [ngClass]="{
        'region-pin': true,
        'region-pin_state-selected': selected
    }"
>
    <div
        class="region-pin__body" 
        [ngClass]="{
            'region-pin__hidden': !showFullPin(zoom),
            'region-pin__hidden_hover': !showFullPin(zoom) && isPinHover,
            'region-pin__visible': showFullPin(zoom),
        }"
    >
        <div class="region-pin__value">
            <div
                #pinValue
                class="region-pin__value_value"
                [ngStyle]="{
                    'background-color': getColorFromZone(zone, value)
                }"
            >{{ displayValue }}</div>
            <div class="region-pin__line"></div>
            <div
                class="region-pin__end"
                [style]="{ backgroundColor: getColorFromZone(zone, value) }"
                (mouseenter)="isPinHover = true"
                (mouseleave)="isPinHover = false"
            ></div>
        </div>
        <div class="region-pin__name">
            {{ displayName }}
        </div>
    </div>
</div>