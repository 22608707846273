import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { delay, fromEvent, merge, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'shared-ui-heights-selector',
    templateUrl: 'heights-selector.component.html',
    styleUrls: ['heights-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightsSelectorComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() heights: number[];
    @Input() value: number;
    @Input() isLoading = false;

    @Output() changeHeight = new EventEmitter<number>();
    @ViewChild('slider', { static: true }) slider: ElementRef<HTMLDivElement>;
    @ViewChild('stub', { static: true }) stub: ElementRef<HTMLDivElement>;
    @ViewChild('handle', { static: true }) handle: ElementRef<HTMLDivElement>;
    @ViewChild('tooltip', { static: true }) tooltip: ElementRef<HTMLDivElement>;
    @ViewChild('flag', { static: true }) flag: ElementRef<HTMLDivElement>;

    private currentValue: number;
    public currentHeight: number;
    private sliderHeight: number;
    public hintText = '';
    public tooltipHeight = '';
    private minY: number;
    private maxY: number;
    public step: number;
    textShortMetr = TEXTS.PLUMES.unitMetr;
    heightsArray = [];
    heightBlock = 1;
    private drag = false;
    private onDestroy$ = new Subject<void>();
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.initParams();
        if (this.value) {
            this.hintText = `${this.value}`;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value?.currentValue && !changes.value.firstChange) {
            this.currentHeight = changes.value.currentValue;
        }
        if (changes.heights?.currentValue) {
            this.heightsArray = [...this.heights].reverse();
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    startHandle(event: MouseEvent) {
        event.preventDefault();
        this.prepareParams();
        this.drag = true;
        const onRelease = fromEvent(window, 'mouseup').pipe(map(() => (this.drag = false)));

        fromEvent(this.slider.nativeElement, 'mouseup')
            .pipe(delay(300), distinctUntilChanged())
            .subscribe(() => this.emitValue());
        fromEvent(this.slider.nativeElement, 'mousemove')
            .pipe(
                takeUntil(merge(onRelease, this.onDestroy$)),
                map((e: MouseEvent) => this.calcValue(e.clientY)),
                distinctUntilChanged()
            )
            .subscribe((value) => this.changeValue(value));
    }

    startTouchHandle(event: TouchEvent) {
        this.prepareParams();
        this.drag = true;
        const onRelease = fromEvent(window, 'touchend').pipe(
            map(() => {
                this.drag = false;
            })
        );

        fromEvent(this.slider.nativeElement, 'touchmove')
            .pipe(
                takeUntil(merge(onRelease, this.onDestroy$)),
                map((e: TouchEvent) => this.calcValue(e.touches.item(0).clientY)),
                distinctUntilChanged()
            )
            .subscribe((value) => this.changeValue(value));
    }

    mouseLeaveHandle($event: MouseEvent) {
        if (this.drag) {
            this.emitValue();
        }
    }

    showTooltip(event: MouseEvent) {
        this.tooltip.nativeElement.style.visibility = 'visible';
        this.tooltip.nativeElement.style.top = event.y - 10 + 'px';
        this.tooltipHeight = this.getHeightByEvent(event.y);
        if (Number(this.tooltipHeight) !== this.currentHeight) {
            this.tooltip.nativeElement.style.visibility = 'visible';
        } else {
            this.tooltip.nativeElement.style.visibility = 'hidden';
        }
    }

    hideTooltip() {
        this.tooltip.nativeElement.style.visibility = 'hidden';
    }

    setValue({ clientY }) {
        this.changeValue(this.calcValue(clientY));
        this.currentHeight = this.getCurrentHeight(clientY);
        this.emitValue();
    }

    emitValue() {
        if (this.currentHeight !== this.value) {
            this.changeHeight.emit(this.currentHeight);
        }
        setTimeout(() => {
            this.correctPositionAfterEmit();
        }, 300);
    }

    setFirstValue() {
        this.changeValue(this.calcValue(this.maxY - this.step / 2));
        this.currentHeight = this.heights[0];
        this.emitValue();
    }

    clickBg($event) {
        if (Math.abs($event.clientY - this.minY) <= this.step) {
            this.setValue({ clientY: this.minY });
        } else if (Math.abs($event.clientY - this.maxY) <= this.step) {
            this.setValue({ clientY: this.maxY - 10 });
        }
    }

    private getValueByHeight(val) {
        const index = this.heightsArray.findIndex((item) => item === val);
        return (index + 1) * this.step + this.minY - this.step / 2 - 2;
    }

    private getCurrentHeight(y?: number) {
        const value = y ?? this.currentValue;
        if (!value) return this.value;
        const index = Math.floor((value - this.minY) / this.step);
        return !isNaN(index) && this.heights[index] !== undefined
            ? this.heightsArray[index]
            : index < 0
            ? this.heights[this.heights.length - 1]
            : this.heights[0];
    }

    private getHeightByEvent(y: number) {
        const index = Math.floor((y - this.minY) / this.step);
        return !isNaN(index) && this.heights[index] !== undefined
            ? this.heightsArray[index]
            : index < 0
            ? this.heights[this.heights.length - 1]
            : this.heights[0];
    }

    private changeValue(value: number) {
        this.currentValue = value;
        this.currentHeight = this.getCurrentHeight(value);
        this.hintText = `${this.currentHeight}`;
        this.moveHandle();
        this._changeDetectorRef.markForCheck();
    }

    private prepareParams() {
        this.sliderHeight = this.slider.nativeElement.clientHeight;
        this.minY = this.slider.nativeElement.getBoundingClientRect().y;
        this.maxY = this.minY + this.sliderHeight;
        this.step = +((this.maxY - this.minY) / this.heights.length).toFixed(2);
        this.heightBlock = this.step;
    }

    private initParams() {
        this.prepareParams();
        const value = this.getValueByHeight(this.value);
        this.currentValue = value;
        this.currentHeight = this.value;
        this.moveHandle();
        this._changeDetectorRef.markForCheck();
    }

    private getTopPosition() {
        return this.currentValue - this.minY - 10;
    }

    private moveHandle(val?) {
        if (!val) {
            val = this.getTopPosition();
        }
        this.stub.nativeElement.style.top = val + 15 + 'px';
        this.stub.nativeElement.style.height = this.sliderHeight - val - 15 + 'px';
        this.handle.nativeElement.style.top = `${val}px`;
        this.flag.nativeElement.style.top = val - 5 + 'px';
    }

    private correctPositionAfterEmit() {
        const val = this.getValueByHeight(this.currentHeight) - this.minY - 10;
        this.moveHandle(val);
    }

    private calcValue(y: number) {
        if (y < this.minY || y > this.maxY - 5) {
            return this.currentValue;
        }
        return y;
    }
}
