import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectTimeRange } from '@cityair/modules/core/store/selectors';
import { CalendarOutput } from '@libs/common/utils/date-formats';

@Component({
    selector: 'calendar',
    template: `
        <div class="calendar" (clickOutside)="close()">
            <ng-container *ngIf="getTimeRange$ | async as dateRange">
                <input-for-dropdown
                    *ngIf="dropdownMode; else defaultTemp"
                    [inputValue]="dateRange | formatDate"
                    [(isOpenDropdown)]="showCalendar"
                ></input-for-dropdown>
                <ng-template #defaultTemp>
                    <div class="calendar__date" (click)="toggleShow()">
                        {{ dateRange | formatDate }}
                    </div>
                </ng-template>
                <calendar-months
                    [show]="showCalendar"
                    [timeBegin]="dateRange?.begin"
                    [timeEnd]="dateRange?.end"
                    [applyCb]="updateTime"
                    [monthsVisible]="monthsVisible"
                    [columns]="columns > 4 ? 4 : columns"
                    [numberOfMonths]="12"
                ></calendar-months>
            </ng-container>
        </div>
    `,
    styleUrls: ['calendar.less'],
})
export class Calendar {
    @Input() monthsVisible: number;
    @Input() columns: number;
    @Input() dropdownMode = false;
    showCalendar = false;

    @Output() updateDateRange = new EventEmitter<CalendarOutput>();

    getTimeRange$ = this.store.pipe(selectTimeRange);

    constructor(public store: Store) {}

    updateTime = (begin: number, end: number) => {
        this.updateDateRange.emit({ begin, end });
        this.showCalendar = false;
    };

    toggleShow = () => {
        this.showCalendar = !this.showCalendar;
    };

    close() {
        this.showCalendar = false;
    }
}
