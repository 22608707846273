import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { IReport, REPORT_STATUS, IKeyValue } from '@cityair/modules/reports/models';
import { STATUS_COLOR } from '@cityair/modules/reports/constant';

const REPORT_STATUS_ORDER = [
    REPORT_STATUS.READY,
    REPORT_STATUS.IN_PROGRESS,
    REPORT_STATUS.QUEUED,
    REPORT_STATUS.ERROR,
];
function sortStatus(a: IKeyValue, b: IKeyValue) {
    const index = REPORT_STATUS_ORDER.indexOf(a.key);
    if (index === -1) {
        return 1;
    }
    return REPORT_STATUS_ORDER.indexOf(a.key) - REPORT_STATUS_ORDER.indexOf(b.key);
}
@Component({
    selector: 'reports-status-counter',
    templateUrl: './status-counter.component.html',
    styleUrls: ['./status-counter.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCounterComponent implements OnChanges {
    @Input() data: IReport[];
    public counterObj: IKeyValue[] = [];
    public translateText = TEXTS.REPORTS;
    public reportStatus = REPORT_STATUS;
    public colors = STATUS_COLOR;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.counterObj = [];
        if (changes.data && changes.data.currentValue.length) {
            this.getCounterStatus(changes.data.currentValue);
        }
    }
    private getCounterStatus(data): void {
        const result = data.reduce(function (acc, el) {
            acc[el.status] = (acc[el.status] || 0) + 1;
            return acc;
        }, {});

        if (!result[REPORT_STATUS.IN_PROGRESS]) {
            result[REPORT_STATUS.IN_PROGRESS] = 0;
        }
        if (!result[REPORT_STATUS.ERROR]) {
            result[REPORT_STATUS.ERROR] = 0;
        }

        for (const key of Object.keys(result)) {
            this.counterObj.push({ key: key as REPORT_STATUS, value: result[key] });
        }

        this.counterObj.sort(sortStatus);
    }
}
