import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'status-dot-svg',
    templateUrl: './dot-svg.component.html',
    styleUrls: ['./dot-svg.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotSvgComponent implements OnInit {
    @Input() width = 8;
    @Input() color = '#FFA33B';

    constructor() {}

    ngOnInit(): void {}
}
