import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleAccessInfo } from '@libs/common/models/access.model';
import { BasicRolesResponse } from '@libs/common/models/basicModels';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { SidebarModule } from '@libs/shared-ui/components/sidebar/sidebar.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';

@Component({
    selector: 'shared-ui-access-info',
    standalone: true,
    imports: [
        CommonModule,
        CrossButtonModule,
        PipesModule,
        SidebarModule,
        TooltipsModule,
        InfoIconModule,
    ],
    templateUrl: './access-info.component.html',
    styleUrl: './access-info.component.less',
})
export class AccessInfoComponent {
    @Input() moduleAccessInfo: ModuleAccessInfo[];
    @Input() roles: BasicRolesResponse[];

    @Output() cancel = new EventEmitter();

    currentLang = LANGUAGE;
    tooltipInfoText: string;
    texts = TEXTS.LIST_USERS;

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.closePopup();
    }

    closePopup() {
        this.cancel.emit();
    }
}
