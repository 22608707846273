import {
    CH2O,
    CO,
    CO2,
    H2S,
    NH3,
    NO,
    NO2,
    O3,
    PM10,
    PM25,
    SO2,
    SUM,
    TSP,
} from '@libs/common/consts/substance.consts';

export const MMT_WITH_PDK = [PM25, PM10, CO, CO2, NO2, NO, SO2, O3, H2S, NH3, CH2O, SUM, TSP];
