import { RegionCoefsDto, BasicUserTokens } from '@libs/common/models/basicModels';
import { createReducer, on } from '@ngrx/store';
import * as settingsActions from '@cityair/modules/settings/store/settings/actions';

export interface SettingsState {
    newCoefficient: number;
    regionCoefficient: RegionCoefsDto;
    userTokens: BasicUserTokens[];
    activeTokenId: number;
    apiKeysToken: { [key: number]: string };
    isSavingMapSettings: boolean;
    isSavingStartModule: boolean;
    updateSettingsResponse: any;
}

export const initialSettingsState: SettingsState = {
    newCoefficient: null,
    regionCoefficient: null,
    userTokens: null,
    activeTokenId: null,
    apiKeysToken: {},
    isSavingMapSettings: false,
    isSavingStartModule: false,
    updateSettingsResponse: null,
};
const _settingsReducer = createReducer(
    initialSettingsState,
    on(settingsActions.setUserPermission, (state: SettingsState, { payload }) => {
        const tokens = payload?.Result.Tokens ?? [];
        return {
            ...state,
            userTokens: [...tokens].sort((a, b) =>
                a.LastDate < b.LastDate ? 1 : a.LastDate > b.LastDate ? -1 : 0
            ),
            activeTokenId: payload?.Result?.ActiveTokenId ?? null,
        };
    }),
    on(settingsActions.setApiKeyToken, (state: SettingsState, { payload, id }) => {
        const apiKeysToken = { [id]: payload };
        return {
            ...state,
            apiKeysToken,
        };
    }),
    on(settingsActions.clearApiKeyToken, (state: SettingsState, { payload }) => {
        const apiKeysToken = { ...state.apiKeysToken };
        delete apiKeysToken[payload];
        return {
            ...state,
            apiKeysToken,
        };
    }),
    on(settingsActions.setRegionCoef, (state: SettingsState, { payload }) => {
        const regionCoefficient: RegionCoefsDto = {
            id: payload?.Result?.RegionCoefsId.toString(),
            name: payload?.Result?.Name,
            fix_pcf: payload?.Result?.Pcf,
            fix_scf: payload?.Result?.Scf,
            description: payload?.Result?.Description,
        };
        return {
            ...state,
            regionCoefficient,
        };
    }),
    on(settingsActions.setNewCoef, (state: SettingsState, { payload }) => ({
        ...state,
        newCoefficient: payload,
    })),
    on(settingsActions.isSavingMapSetting, (state: SettingsState, { payload }) => ({
        ...state,
        isSavingMapSettings: payload,
    })),
    on(settingsActions.updateGroupSettingsResponse, (state: SettingsState, { payload }) => ({
        ...state,
        updateSettingsResponse: payload,
    })),
    on(settingsActions.isSavingStartModule, (state: SettingsState, { payload }) => ({
        ...state,
        isSavingStartModule: payload,
    }))
);
export function settingsReducer(state, action) {
    return _settingsReducer(state, action);
}
