import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MARKER_HEIGHT, MARKER_HOVER_COLORS } from '../constants';
import { ALERT_COLORS } from '@libs/common/consts/alert-colors';

@Component({
    selector: 'marker-icon',
    templateUrl: 'marker-icon.component.html',
    styleUrls: ['./marker-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerIconComponent implements OnInit {
    @Input() selected: boolean;
    @Input() canSelected: boolean;
    @Input() canNotify: boolean;
    @Input() aqi: number;
    @Input() opacity: number;
    @Input() type?: string;
    markerHeight = MARKER_HEIGHT;
    isTypeOpenAQ = false;
    isMarkerOver = false;
    iconShift: {
        x: number;
        y: number;
    };

    constructor() {}

    ngOnInit() {
        this.isTypeOpenAQ = this.type === 'OpenAQ';
    }

    getWidth() {
        let width = 45;

        if (this.selected) {
            width = 59;
        }

        return width;
    }

    getHeight() {
        let height = this.markerHeight;

        if (this.selected) {
            height = 73;
        }

        if (this.isTypeOpenAQ) {
            height *= 0.88;
        }

        return height;
    }

    getTextValue() {
        let value = this.aqi ? `${this.aqi}` : '-';

        if (this.canSelected && !this.selected) {
            value = '+';
        }

        return value;
    }
    getXPosition(isSelected: boolean): number {
        if (this.canSelected && !this.selected) {
            return 13.2;
        }
        const index = this.aqi ? this.aqi : 0;
        switch (index) {
            case 0:
                return isSelected ? 17.5 : 14.425;
                break;
            case 1:
                return isSelected ? 17 : 11.4125;
                break;
            case 4:
            case 5:
                return isSelected ? 15.8125 : 11;
                break;
            case 6:
                return isSelected ? 16.2125 : 11.5125;
                break;
            case 7:
                return isSelected ? 17.8125 : 11.4;
                break;
            case 10:
                return isSelected ? 13 : 10.8125;
                break;
            default:
                return isSelected ? 16.4125 : 11;
                break;
        }
    }
    getTextTransform() {
        const coords = this.isTypeOpenAQ ? ['22 35', '15 35'] : ['15.8591 23.048', '11.5 23.048'];

        const c = this.canSelected && !this.selected;
        const position = c || this.aqi !== 10 ? coords[0] : coords[1];
        return `matrix(1 0 0 1 ${position})`;
    }

    getMainColor() {
        const aqi = this.aqi || 0;

        return this.isMarkerOver ? MARKER_HOVER_COLORS[aqi] : ALERT_COLORS[aqi];
    }

    getTextColor() {
        const aqi = this.aqi || 0;

        return this.selected ? '#fff' : ALERT_COLORS[aqi];
    }

    getTextSize() {
        return this.isTypeOpenAQ ? 21 : 14;
    }

    getCircleColor() {
        return this.selected ? this.getMainColor() : '#fff';
    }
}
