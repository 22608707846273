<cityair-map-control
    [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
     *ngIf="(store.select(selectSpeciesList) | async)?.length"
    [title]="textTitleMapControl"
    [isShowTogglePin]="true"
>
    <div map-control-period>
        <forecast-calendar
            class="forecast-map-calendar"
            *ngIf="dateRange"
            [dateRange]="dateRange"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dropdownMode]="true"
            (updateDateRange)="updateDateRange($event)"
        ></forecast-calendar>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            [title]="textOnMap"
            [showLayerButton]="true"
            [activeLayer]="isActiveForecast"
            [mmts]="store.select(selectSpeciesList) | async"
            [activeMmt]="store.select(currentForecastMmt) | async"
            [meteoMmts]="meteoMmts"
            [disabledLayerButton]="store.select(selectIsHiddenLayerOnMap) | async"
            (toggleLayer)="toggleForecast()"
            (selectMmt)="updatePropsMmt({
                measure: $event,
                contours: false,
                overlay: true
            })"
        ></shared-ui-mmt-selector>
    </div>
    <div switch-selector *ngIf="(store.select(getStations) | async)?.length">
        <ca-layer-button
            class="layer_button lens"
            [type]="LayerButtonIconType.SWITCH_POST"
            [size]="'big'"
            [active]="isShowPins"
            (clickOnIcon)="togglePostPin()"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="switchButtonTooltip"
        >
        </ca-layer-button>
        <ng-template #switchButtonTooltip>
            <ca-tooltip [text]="isShowPins? textsSwitchButton.on : textsSwitchButton.off"></ca-tooltip>
        </ng-template>
    </div>
</cityair-map-control>
