<ca-impact-map-element></ca-impact-map-element>
<div class="timeline-temp-wrapper">
    <div class="timeline__wrapper timeline__wrapper-displayFlex">
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectIsSidebarOpen) | async"
        >
            <ng-container *ngIf="store.select(selectImpactHeights) | async as heights">
                <shared-ui-heights-selector
                    class="heights-selector"
                    *ngIf="heights.length > 1"
                    [heights]="heights"
                    [value]="store.select(selectActiveHeight) | async"
                    [isLoading]="isLoading"
                    (changeHeight)="changeHeight($event)"
                ></shared-ui-heights-selector>
            </ng-container>
            <cs-switch-button *ngIf="store.select(isWindLayerAvailable) | async"
                    class="switch-button"
                    [text]="textWindLayer"
                    [active]="store.select(selectShowWindOnMap) | async"
                    [disable]="store.select(selectDisableWindButton) | async"
                    (activeChange)="toggleWindLayer()"
                    caTooltip
                    caTooltipPlacement="top-left"
                    [caTooltipAllowHover]="true"
                    [caTooltipTemplate]="tooltipWind"
                >
            </cs-switch-button>
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
             <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectImpactSchemaZones) | async as val"
                [unit]="textScheme[val.scheme][val.mmt]"
                [measure]="textNames[val.mmt]"
                [colors]="val?.zone?.color"
                [values]="val?.zone?.zone"
                [digitsAfterDot]="getDigitsAfterDot(val.scheme, val.mmt)"
            ></color-legend>
            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [sidebarIsOpened]="store.select(selectIsSidebarOpen) | async"
                [currentTime]="store.select(selectImpactTime) | async"
                [dates]="store.select(selectDates) | async"
                [features]="store.select(selectChartData) | async"
                [initSelectMeasurement]="store.select(selectActiveMmt) | async"
                [labelMode]="true"
                [showNow]="true"
                [pdk]="store.select(selectPdkForChart) | async"
                [chartMinMax]="chartMinMax"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [timezone]="store.select(selectUserTimezone) | async"
                (goToCity)="goToCity($event)"
                (removeFromComparison)="store.dispatch(removeFromComparison({ id: $event }))"
                (changeTimeIndex)="changeTimeIndex($event)"
                (setPlayingState)="getPlayingState($event)"
            >
            </timeline-panel>

        </div>
    </div>
    <ng-template #tooltipWind>
            <ng-container
                *ngIf="isPlayerTimeline else windTooltipTemplate"
            >
               <ca-tooltip class="tooltip-wind">
                   <div  class="text-tooltip" [innerHtml]="windTooltipTexts.byPlayer"></div>
               </ca-tooltip>

            </ng-container>
            <ng-template #windTooltipTemplate>
                <ng-container
                    *ngIf="store.select(selectImpactHeights) | async as heights"
                >
                    <ng-container *ngIf="heights.length > 1">
                        <ca-tooltip class="tooltip-wind">
                           <div class="text-tooltip" [innerHtml]="windTooltipTexts.byHeight"></div>
                       </ca-tooltip>
                    </ng-container>
                </ng-container>
            </ng-template>
    </ng-template>
</div>
