import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common/models/feature';

@Pipe({
    name: 'contributionData',
})
export class ContributionDataPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[], index: number): number[] {
        const result = [];
        if (data.length === 1 && mmts.length === 1 && data[0]?.properties?.contributions) {
            const contribData = data[0]?.properties?.contributions[mmts[0]];
            if (contribData) {
                const sourceKeys = Object.keys(contribData);
                sourceKeys.forEach((key, k) => {
                    result.push(Math.round(contribData[key][index]));
                });
            }
        }

        return result;
    }
}
