import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import {
    closeUserForm,
    createUser,
    createUserSuccess,
    deleteUser,
    deleteUserSuccess,
    foundUser,
    isLoadingUsers,
    loadUsersList,
    setFormError,
    setFoundUserResult,
    setLoadingForm,
    setUsersList,
    updateUser,
    updateUserSuccess,
} from '@cityair/modules/settings/store/users/actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getCurrentGroup } from '@cityair/modules/core/store/group/group.feature';
import { IBasicResponse } from '@libs/common/models/basicModels';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { addAlert } from '@cityair/modules/core/store/actions';
import { TEXTS } from '@libs/common/texts/texts';
import { selectMyRole } from '@cityair/modules/core/store/selectors';
import { Router } from '@angular/router';
import { SETTINGS_PAGES } from '@cityair/modules/settings/constants';

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private basicApi: BasicApi,
        private router: Router
    ) {}
    loadUsersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUsersList),
            withLatestFrom(this.store.select(getCurrentGroup), this.store.select(selectMyRole)),
            filter(([_, group, myRole]) => group !== null),
            tap(([_, group, myRole]) => {
                if (myRole.edit_user) {
                    this.store.dispatch(isLoadingUsers({ payload: true }));
                } else {
                    this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.system}`]);
                }
            }),
            filter(([_, group, myRole]) => myRole.edit_user),
            switchMap(([props, group]) =>
                this.basicApi.getUsers(group.id).pipe(
                    map((response: IBasicResponse) => setUsersList({ payload: response })),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(
                            errorAction,
                            setUsersList({ payload: { data: [], meta: null } }),
                            isLoadingUsers({ payload: false })
                        );
                    })
                )
            )
        )
    );
    foundUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(foundUser),
                switchMap((action) =>
                    this.basicApi.foundUsers(action.payload).pipe(
                        map((response) => setFoundUserResult({ payload: response?.Result })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    createUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createUser),
                tap(() => this.store.dispatch(setLoadingForm({ payload: true }))),
                switchMap((action) =>
                    this.basicApi.createUser(action.payload).pipe(
                        switchMap((response) => [
                            setLoadingForm({ payload: false }),
                            addAlert({
                                id: new Date().valueOf(),
                                message: action.isNew
                                    ? TEXTS.LIST_USERS.createAccount(action.payload.email)
                                    : TEXTS.LIST_USERS.whenAdd,
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 5000,
                                showCloseIcon: false,
                                size: 'lg',
                            }),
                            createUserSuccess({ payload: response?.data }),
                            closeUserForm(),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            if (errorResponse?.status === 400) {
                                return of(
                                    setLoadingForm({ payload: false }),
                                    setFormError({ payload: errorResponse })
                                );
                            } else {
                                return of(
                                    errorAction,
                                    setLoadingForm({ payload: false }),
                                    setFormError({ payload: errorResponse })
                                );
                            }
                        })
                    )
                )
            ) as Observable<Action>
    );

    updateUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUser),
                tap(() => this.store.dispatch(setLoadingForm({ payload: true }))),
                switchMap((action) =>
                    this.basicApi.updateUser(action.payload).pipe(
                        switchMap((response) => [
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'update_user_success',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 5000,
                                showCloseIcon: false,
                                size: 'lg',
                            }),
                            updateUserSuccess({ payload: response?.data }),
                            closeUserForm(),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, setLoadingForm({ payload: false }));
                        })
                    )
                )
            ) as Observable<Action>
    );

    deleteUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteUser),
                switchMap((action) =>
                    this.basicApi.deleteUser(action.userId).pipe(
                        switchMap((response) => [
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'delete_user_success',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 5000,
                                showCloseIcon: true,
                                size: 'lg',
                            }),
                            deleteUserSuccess({ userId: action.userId }),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );
}
