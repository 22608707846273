import * as moment from 'moment-timezone';
import { MMT_WITH_PDK_SORTED } from '@libs/common/consts/mmt-with-pdk-sorted.const';

import { IntervalEnum, IntervalV2Type, WindowGlobalVars } from '@cityair/namespace';
import { environment } from 'environments/environment';

import { AVAILABLE_AQIS } from '@libs/common/consts/avaliable-aqi.const';
import { detectMobile } from '@libs/common/utils/detect-mobile';

declare let window: WindowGlobalVars;

export const MIN_PASSWORD_LENGTH = 7;

export const CITYAIR_DEVICE_ID = 3;

export const NO_ACCESS_ID = 0;
export const ADMIN_ID = 1;
export const OPERATOR_ID = 2;
export const OBSERVER_ID = 3;
export const SERVICE_ID = 4;

export const RESIZE_TIMEOUT = 200;

export const MAIN_CHART_HEIGHT = 150;
export const MAIN_CHART_HEIGHT_M = 95;

export const STND_CITY_ZOOM = 8;
export const CITY_ZOOM_SHOW_HEXAGON = 10;
export const STND_CITY_MAX_ZOOM = 16;
export const STND_GLOBAL_MIN_ZOOM = 1.5;
export const GLOBAL_ZOOM_LEVEL = 8;

export const DEFAULT_QUALITY_DATA_PERCENT = 50;
export const MO_AREA_RADIUS_IN_METERS = 500;
export const MARKER_AREA_OPACITY = 0.3;
export const AVAILABLE_DATA_IN_MONTH = 12;

export function getColorIndex(zone: number[], value: number) {
    if (value >= zone[zone.length - 1]) {
        return zone.length;
    }

    return zone.findIndex((v) => value < v);
}

export const MMT_FOR_PINS = [...AVAILABLE_AQIS, ...MMT_WITH_PDK_SORTED];

export const getShiftMapCityCard = (): [number, number] => [
    -250,
    document.documentElement.clientHeight / 2 - 350,
];
export const getShiftMapByLine = (): [number, number] => {
    const y =
        document.documentElement.clientHeight < 700
            ? 400 - document.documentElement.clientHeight / 2
            : document.documentElement.clientHeight / 2 - 350;
    return [-250, y];
};
export const getShiftMapByCorrelationPins = (): [number, number] => [-250, 0];
export const getShiftMapMobile = (): [number, number] => [
    0,
    421 /* таймлайн+шторка */ - document.documentElement.clientHeight / 2,
];

export type ModelProps = {
    measure: string;
    contours: boolean;
    overlay: boolean;
};

export function getApiV2Interval(timeBegin: number, timeEnd: number): IntervalV2Type {
    const days = Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days'));

    if (days <= 3) return '5m';

    if (days <= 15) return '20m';

    if (days <= 45) return '1h';

    return '1d';
}

export const STND_INTERVAL = (
    detectMobile() ? IntervalEnum.hour : IntervalEnum.min20
) as IntervalEnum;

export const AVAILABLE_INTERVALS = [
    {
        interval: IntervalEnum.min5,
        days: 3.3,
    },
    {
        interval: IntervalEnum.min20,
        days: 12,
    },
    {
        interval: IntervalEnum.hour,
        days: 36,
    },
    {
        interval: IntervalEnum.day,
        days: Infinity,
    },
];

export function getAssetPath(asset: string) {
    return asset ? `${environment.deploy_url}assets/${asset}` : null;
}

export function getClientAssetPath(asset: string) {
    return asset ? `${environment.deploy_url}client_assets/${asset}` : null;
}
