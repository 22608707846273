import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SidebarToggleButtonModule } from '@libs/shared-ui/components/sidebar-toggle-button/sidebar-toggle-button.module';

import { MenuComponent } from './components/menu/menu.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';

@NgModule({
    imports: [CommonModule, DirectivesModule, TooltipsModule, SidebarToggleButtonModule],
    providers: [],
    exports: [MenuComponent, MenuButtonComponent],
    declarations: [MenuComponent, MenuButtonComponent],
})
export class SidebarModule {}
