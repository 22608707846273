import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { Store } from '@ngrx/store';
import { MAX_PCF, MIN_PCF, SETTINGS_PAGES } from '../../../constants';
import { updateRegionCoef } from '@cityair/modules/settings/store/settings/actions';
import {
    selectNewCoef,
    selectRegionCoefficient,
} from '@cityair/modules/settings/store/settings/selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'cityair-coefficient-group',
    templateUrl: './coefficient-group.component.html',
    styleUrls: ['./coefficient-group.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoefficientGroupComponent implements OnInit, OnDestroy {
    textsSettings = TEXTS.CONFIG;
    public textAdminPanel = TEXTS.ADMIN_PANEL;
    textsCommon = TEXTS.COMMON;
    public ngDestroyed$ = new Subject<void>();
    selectRegionCoefficient = selectRegionCoefficient;
    @ViewChild('coeffPopup', { static: true }) coeffPopup: TemplateRef<HTMLDivElement>;

    constructor(
        private popupProvider: OffPanelPopupService,
        public store: Store,
        private ref: ChangeDetectorRef,
        private router: Router
    ) {
        this.store
            .select(selectNewCoef)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((newCoef) => {
                this.newCoef = newCoef;
                this.ref.markForCheck();
            });
    }

    TEXTS = TEXTS;

    min = MIN_PCF;
    max = MAX_PCF;
    showPopup = false;
    newCoef: number = null;

    isVrongCoeff = (): boolean => {
        if (this.newCoef === null) {
            return false;
        }

        if (typeof this.newCoef !== 'number') {
            return true;
        }

        if (this.newCoef < MIN_PCF || this.newCoef > MAX_PCF) {
            return true;
        }

        return false;
    };

    ngOnInit() {
        this.popupProvider.setTemplate(this.coeffPopup);
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.ngDestroyed$.next();
    }

    setCoefficient(data: number) {
        this.store.dispatch(updateRegionCoef({ payload: data }));
    }

    back = () => {
        this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.groupSettings}/`]);
    };

    cancel() {
        this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.groupSettings}/`]);
    }
}
