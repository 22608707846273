<div class="users-wrapper">
    <div class="row">
        <div class="button-wrapper">
            <button-add  [text]="texts.addUsers" (action)="addUser()"></button-add>
        </div>
        <div class="vert-line"></div>
        <div class="search-wrapper">
            <search-input-basic
                #searchInput
                [textPlaceholderInner]="searchPlaceHolder"
                [textInner]="searchQuery"
                [debounceTimeInner]="300"
                (textChangeInner)="textChangeIn($event)"
            ></search-input-basic>
        </div>

    </div>
    <div class="spinner-wrapper" *ngIf="isLoading; else usersTemp">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
    <ng-template #usersTemp>
        <div class="content" *ngIf="(store.select(selectUsersListWithMe) | async) as users">
            <ng-container *ngIf="users | multipleSearchfilter : ['email', 'login' ] : searchQuery as result">
                <ng-container *ngIf="!result.length else listTemplate">
                    <ng-container *ngIf="searchQuery; else noUsers">
                        <div class="no-data-wrapper">
                            <search-result-placeholder [clearText]="textClear" (clear)="searchQuery = ''"></search-result-placeholder>
                        </div>
                    </ng-container>
                    <ng-template #noUsers>
                        <div class="no-data-wrapper">
                            <non-data-screen [text]="texts.noUsers"></non-data-screen>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template #listTemplate>
                    <div class="list" #contentWrapper>
                        <div class="table-header">
                            <div class="login">
                                <shared-sort-list-item
                                    (click)="setSortingCb('login')"
                                    [sortingDirection]="sortField === 'login' ? sortDirection : 0"
                                    [text]="texts.userEmail"
                                ></shared-sort-list-item>
                            </div>
                            <div class="role header">
                                {{texts.accessLevel}}
                                <ca-info-icon
                                    style="margin-left: 6px;cursor:pointer"
                                    [withGradient]="true"
                                    (click)="showAccessLevelPopup = !showAccessLevelPopup"
                                ></ca-info-icon>
                            </div>
                            <div class="last-login header">
                                {{texts.lastLoginTableHeader}}
                            </div>
                            <div class="actions"></div>
                        </div>
                        <div class="table-content scroll">
                            <div class="item-wrapper"
                                 *ngFor="let user of result | orderBy : sortField : sortDirection"
                            >
                                <div class="login">
                                    <div class="icon" [ngStyle]="{ backgroundColor: getColor(user) }">
                                        <div class="text">{{user.login.substring(0, 1)}}</div>
                                    </div>
                                    <div class="info">
                                        <div class="info-login">
                                            {{user.email}}
                                            <span *ngIf="user.login === currentUser.name">({{texts.you}})</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="role">
                                    <ng-container *ngIf="store.select(selectRoleNameById(user.group_role?.id, currentLang)) | async as roleName">
                                         <div class="wrapper">
                                             <div class="name">
                                                 <div [className]="store.select(selectRoleNameById(user.group_role?.id, 'en')) | async">
                                                     <span>
                                                        {{roleName}}
                                                     </span>
                                                 </div>
                                             </div>
                                         </div>
                                    </ng-container>
                                </div>
                                <div class="last-login">{{user.last_active_at | timeAgo : currentTime}}</div>
                                <div class="actions">
                                    <kebab-menu *ngIf="groupId + '_' + currentUser.id !== user.id"
                                        (clickActions)="openActions($event, user)">
                                    </kebab-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </ng-template>
</div>
<router-outlet></router-outlet>
<ng-template #popupMenu>
    <stnd-ask-popup2
        *ngIf="popup"
        [texts]="{
            title: configPopup[popup].title,
            accept: configPopup[popup].apply,
            body:  popup === 'remove' ? textConfirmDelete.body : ''
        }"
        [disableAccept]="false"
        [isDangerAccept]="popup === 'remove'"
        (cancel)="popup = ''"
        (accept)="applyPopup()"
    >
    </stnd-ask-popup2>
</ng-template>
<ng-template #userMenu>
    <card-actions-list
        *ngIf="isShowMenu"
        [position]="menuPositionTop"
        [positionLeft]="menuPositionLeft"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="textEdit"
            (click)="editUser()"
        ></card-actions-item>
        <ng-container *ngIf="false">
            <card-actions-separator></card-actions-separator>
            <card-actions-item
                data-cy="action-reset-password"
                [text]="texts.actionSendEmailPassword"
                (click)="resetPassword()"
            ></card-actions-item>
        </ng-container>
        <ng-container>
            <card-actions-separator></card-actions-separator>
            <card-actions-item
                data-cy="action-close"
                type="danger"
                [text]="texts.deleteUserAction"
                (click)="showPopup($event, 'remove')"
            ></card-actions-item>
        </ng-container>
    </card-actions-list>
</ng-template>
<shared-ui-access-info
    *ngIf="showAccessLevelPopup"
    [roles]="store.select(selectRoles)| async"
    [moduleAccessInfo]="moduleAccessInfo"
    (cancel)="showAccessLevelPopup=false"
></shared-ui-access-info>
