import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isRU } from '@libs/common/texts/texts';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { environment } from 'environments/environment';
import { ControlPointForecast } from '../models';

const API_URL = environment.vanga_api_url + '/forecastnew/v1';

@Injectable({
    providedIn: 'root',
})
export class ControlPointDataService extends DefaultDataService<ControlPointForecast> {
    private lang = isRU ? 'ru' : 'en';
    private urlPath = this.urlGenerator.collectionResource('ControlPoint', '');

    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super('ControlPoint', http, urlGenerator, { root: API_URL });
    }

    getAll(): Observable<ControlPointForecast[]> {
        throw new Error('not implemented');
    }

    add(data: ControlPointForecast): Observable<ControlPointForecast> {
        const httpOptions = this.getHttpOptions();

        return this.http
            .post(this.urlPath, data, httpOptions)
            .pipe(map((response: any) => response as ControlPointForecast));
    }

    update(data: Update<ControlPointForecast>): Observable<ControlPointForecast> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + data.id + '/';

        return this.http
            .put(urlPath, data.changes, httpOptions)
            .pipe(map((response: any) => response as ControlPointForecast));
    }

    delete(key: number | string): Observable<number | string> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + key + '/';

        return this.http
            .delete(urlPath, httpOptions)
            .pipe(map((response: any) => response as number | string));
    }

    getWithQuery(params: string | QueryParams): Observable<ControlPointForecast[]> {
        const httpOptions = this.getHttpOptions(params);
        const urlPath = this.urlPath + 'timeseries/';

        return this.http
            .get(urlPath, httpOptions)
            .pipe(map((response: any) => response as ControlPointForecast[]));
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
