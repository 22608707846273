import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { InstantAqiLegendComponent } from './instant-aqi-legend.component';
import { AqiIndicatorModule } from '../aqi-indicator/aqi-indicator.module';

@NgModule({
    imports: [CommonModule, BrowserAnimationsModule, DirectivesModule, AqiIndicatorModule],
    exports: [InstantAqiLegendComponent],
    declarations: [InstantAqiLegendComponent],
})
export class InstantAqiLegendModule {}
