import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import { NotificationSubscribeType } from '@cityair/modules/login/services/harvester-api/adminApiModels';
import { NOTIFIACTIONS_CONFIG } from '../../../../notifications.settings';
import { ClosePopupsHelper } from '../subscription-card/subscription-card.component';
import { Store } from '@ngrx/store';
import { NotificationSettings, NotificationType } from '@libs/common/models/basicModels';
import { openSettingForm } from '@cityair/modules/notifications/store/actions';

const DEFAULT_CONFIG = Object.assign({}, NOTIFIACTIONS_CONFIG, {
    type: NotificationSubscribeType.ServiceDevice,
});

const SUGGESTED_CONFIG = Object.assign({}, DEFAULT_CONFIG, {
    title: TEXTS.NOTIFICATIONS.serviceNotifications,
    serviceNoData: 3,
    serviceNoPower: 3,
    serviceVerification: 14,
});

@Component({
    selector: 'subscriptions-service',
    templateUrl: 'subscriptions-service.component.html',
    styles: `::ng-deep .notifications_service .list-header {
        justify-content: flex-end !important;
        padding: 14px 30px 12px 30px !important;
        height: unset !important;
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsService extends ClosePopupsHelper implements OnInit, OnChanges {
    @Input() scrolled: Observable<Event>;
    @Input() settings: NotificationSettings[] = [];
    @Input() userGroupData: any;

    suggestedSubscription: NotificationSettings;

    textsNotification = TEXTS.NOTIFICATIONS;
    emailsList: string[] = [];
    active: NotificationSettings[] = [];
    deactivated: NotificationSettings[] = [];
    popupPositionTop = 0;
    popupActive = false;

    constructor(private store: Store) {
        super();
    }

    ngOnInit() {
        this.scrolled.subscribe((e) => this.onScroll(e));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.settings && changes.settings.currentValue) {
            this.active = this.settings.filter((v) => v.is_active);
            this.deactivated = this.settings.filter((v) => !v.is_active);
        }
    }

    addSubscription() {
        this.store.dispatch(
            openSettingForm({
                settings_type: NotificationType.deviceIncident,
                currentSetting: null,
            })
        );
    }

    editSubscription(subscription: NotificationSettings) {
        this.store.dispatch(
            openSettingForm({
                settings_type: NotificationType.deviceIncident,
                currentSetting: subscription,
            })
        );
    }
}
