import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment-timezone';
import { IntervalEnum } from '@cityair/namespace';

export const TIMELINE_STEP = 600;

export const ANIMATION_MAX_HEIGHT = [
    trigger('maxHeightAnimation', [
        state(
            'true',
            style({
                maxHeight: '{{numberMaxHeight}}px',
                overflow: 'hidden',
            }),
            { params: { numberMaxHeight: 1 } }
        ),
        state(
            'false',
            style({
                maxHeight: '0px',
                overflow: 'hidden',
                opacity: 0,
            })
        ),
        transition('true => false', animate('0.3s linear')),
        transition('false => true', animate('0.3s linear')),
    ]),
];

export const LOAD_HISTORY_DEFAULT = IntervalEnum.hour;
export const LOAD_STATION_SMALL_INTERVAL = IntervalEnum.min20;

export const getStndTimeBegin = () =>
    moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

export const getStndTimeEnd = () => {
    const m = moment();
    m.startOf('minutes');
    m.subtract(m.get('minutes') % 20, 'minutes'); // floor of 20 minutes
    return m.valueOf();
};
export const WIDTH_LEFT_MENU = 58;
export const MAPBOX_STYLES = {
    landuse: {
        'fill-color': '#dfe5dc',
    },
    landcover: {
        'fill-color': '#dfe5dc',
    },
    water: {
        'fill-color': '#A8CDFF',
    },
    land: {
        'background-color': '#f0f0f0',
    },
};
export const MAPBOX_LABELS_COLOR = '#999999';
export const MAPBOX_BOUNDARY_LAYERS = [
    'admin-0-boundary-disputed',
    'admin-0-boundary',
    'admin-0-boundary-bg',
    'admin-1-boundary',
    'admin-1-boundary-bg',
];
