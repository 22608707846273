import * as Color from 'color';
import { MEASUREMENTS_ORDER } from '../consts/measurements-order.const';
import {
    Device,
    BasicDeviceSourceResponse,
    Post,
    DataCorrelation,
} from '@libs/common/models/basicModels';
import { MMT_WITH_PDK } from '@libs/common/consts/mmt-with-pdk';
import { CorrelationAnalysisData } from '@libs/common/models/basicModels';
import { LngLat, MercatorCoordinate } from 'mapbox-gl';
import { LineString, Polygon } from 'geojson';
export function lightenColor(color: string) {
    return Color(color).alpha(0.8).rgb().string();
}

export const isFalseNumber = (a: number) => isNaN(Number(a)) || a === null || a === undefined;

export function sortMeasurements(a: string, b: string) {
    const indexA = MEASUREMENTS_ORDER.indexOf(a);
    const indexB = MEASUREMENTS_ORDER.indexOf(b);

    if (indexA === -1 && indexB === -1) {
        return 0;
    }

    if (indexA === -1) {
        return 1;
    }

    if (indexB === -1) {
        return -1;
    }

    return indexA - indexB;
}
export function sortPostByDistance(currentPost: Post, allPosts: Post[]) {
    return allPosts.sort((a, b) => {
        const _a = getDistanceInMeters(currentPost.geometry.coordinates, a.geometry.coordinates);
        const _b = getDistanceInMeters(currentPost.geometry.coordinates, b.geometry.coordinates);
        return _a - _b;
    });
}
export function copyObj(original) {
    if (!original) return original; // возвратит примитив, undefined, null
    // объект должен быть без функций, сложных объектов, вроде Date и циклических ссылок
    return JSON.parse(JSON.stringify(original));
}

export function getAllMeasurements(posts: Post[], currentGroup): string[] {
    const result = [];
    const excludeIndexes = currentGroup?.ext_config?.excludeIndexes
        ? currentGroup.ext_config.excludeIndexes.split(',')
        : [];
    posts?.forEach((post) => {
        post?.available_indexes.forEach((index) => {
            if (result.indexOf(index) === -1 && excludeIndexes.indexOf(index) === -1) {
                result.push(index);
            }
        });
        post?.available_measurements.forEach((index) => {
            if (result.indexOf(index) === -1) {
                result.push(index);
            }
        });
    });
    result.sort(sortMeasurements);
    return result;
}
export function getAllMeasurementsAnalysis(posts: Post[]): string[] {
    const result = [];
    posts?.forEach((post) => {
        post?.available_measurements.forEach((index) => {
            if (result.indexOf(index) === -1) {
                result.push(index);
            }
        });
    });
    result.sort(sortMeasurements);
    return result;
}
export function getCorrelationData(
    posts: Post[],
    data: DataCorrelation
): CorrelationAnalysisData[] {
    const result = [];
    const uniquePosts = [];
    const id = posts[0]?.id ?? null;
    posts.forEach((currentPost) => {
        const id = currentPost.id;
        const postData = data[id];
        uniquePosts.push(id);
        if (id && postData) {
            posts.forEach((post) => {
                if (post.id !== id && uniquePosts.indexOf(post.id) === -1) {
                    const valueNum = postData[post.id] ? Number(postData[post.id]) : null;
                    const value = valueNum ? Math.round(valueNum * 100) : null;
                    if (value) {
                        result.push({
                            name: `${currentPost.name}-${post.name}`,
                            first_post: id,
                            first_name: currentPost.name,
                            second_post: post.id,
                            second_name: post.name,
                            dist: getDistanceInMeters(
                                currentPost.geometry.coordinates,
                                post.geometry.coordinates
                            ),
                            value,
                        });
                    }
                }
            });
        }
        return result;
    });
    result.sort((a, b) => {
        if (a.value < b.value) return 1;
        if (a.value > b.value) return -1;
        return 0;
    });
    return result;
}

function toRadians(deg) {
    return deg * (Math.PI / 180);
}
function toDegrees(rad: number): string {
    return ((rad * 180) / Math.PI).toFixed(4);
}
export function getDistanceInMeters(coor1, coor2) {
    if (coor1?.length === 2 && coor2?.length === 2) {
        const start = new LngLat(coor1[0], coor1[1]);
        const end = new LngLat(coor2[0], coor2[1]);
        return start.distanceTo(end);
    }
    return null;
}
/**
 * @param  pair coordinates [number, number] latitude and longitude
 *
 * @return array with the center latitude longitude.
 */
export function getLatLngCenter(coor1, coor2) {
    const lngDiff = toRadians(coor2[0] - coor1[0]);
    const latA = toRadians(coor1[1]);
    const latB = toRadians(coor2[1]);
    const lngA = toRadians(coor1[0]);

    const bx = Math.cos(latB) * Math.cos(lngDiff);
    const by = Math.cos(latB) * Math.sin(lngDiff);

    const latMidway = toDegrees(
        Math.atan2(
            Math.sin(latA) + Math.sin(latB),
            Math.sqrt((Math.cos(latA) + bx) * (Math.cos(latA) + bx) + by * by)
        )
    );
    const lngMidway = toDegrees(lngA + Math.atan2(by, Math.cos(latA) + bx));
    return [latMidway, lngMidway];
}
/**
 * @param  number - distance in meters
 *
 * @return number - zoom level
 */
function zoomLevel(distance: number, zoomLevels: number[]): number {
    const values = [3000, 5000, 10000, 25000, 30000];
    let index;
    if (distance >= values[values.length - 1]) {
        index = values.length;
    } else {
        index = values.findIndex((v) => distance < v);
    }
    return zoomLevels[index] ?? 9;
}
export function getZoomLevelByDistance(distance: number): number {
    const zoomLevels = [12.4, 11, 10, 8.9, 8.5];
    return zoomLevel(distance, zoomLevels);
}
export function getZoomLevelByGroup(distance: number): number {
    const zoomLevels = [12.4, 11, 10, 9.5, 9.3];
    return zoomLevel(distance, zoomLevels);
}
export function getAllMmt(posts: Post[]): string[] {
    const result = [];
    posts?.forEach((post) => {
        post?.available_measurements.forEach((index) => {
            if (result.indexOf(index) === -1) {
                result.push(index);
            }
        });
    });

    result.sort(sortMeasurements);
    return result.filter((v) => MMT_WITH_PDK.includes(v));
}
export function getTzOffsetFromBasic(offset: number) {
    return offset ? offset / 60 : null;
}
export function getDeviceSourceName(device: Device, sources: BasicDeviceSourceResponse[]) {
    if (!device || !sources.length) return '';
    const currentSource = sources.find((source) => source.source_type === device.source_type);

    return currentSource?.name || '';
}

export function calculateHexagon(_coordinates: number[], radius = 5000): Polygon {
    const coordinates = [];
    const degToRad = Math.PI / 180;
    const lat = _coordinates[1];
    const lng = _coordinates[0];
    const currentCoordinate = new LngLat(lng, lat);
    const currentMercatorCoordinate = MercatorCoordinate.fromLngLat(currentCoordinate);
    const c = radius * currentMercatorCoordinate.meterInMercatorCoordinateUnits();
    for (let angle = 0; angle < 360; angle += 60) {
        const rad = angle * degToRad;
        const x = currentMercatorCoordinate.x + Math.sin(rad) * c;
        const y = currentMercatorCoordinate.y + Math.cos(rad) * c;
        const m = new MercatorCoordinate(x, y);
        coordinates.push(m.toLngLat().toArray());
    }
    coordinates.push(coordinates[0]);

    return {
        type: 'Polygon',
        coordinates: [coordinates],
    };
}
/*
    remove zero after dot
    @param value - string - number format(Decimal pipe), numberAfterDot - number, current lang
    @example 1,9000 -> 1,9
 */
export function customNumberFormat(
    value: string,
    numberAfterDot: number,
    lang: string = 'ru'
): string {
    const isRu = lang === 'ru';
    switch (numberAfterDot) {
        case 1:
            const last = value.substring(value.length - 2);
            if ((last === ',0' && isRu) || (last === '.0' && !isRu)) {
                value = value.substring(0, value.length - 2);
            }
            break;
        case 2:
            const last2 = value.substring(value.length - 3);
            if ((last2 === ',00' && isRu) || (last2 === '.00' && !isRu)) {
                value = value.substring(0, value.length - 3);
            }
            break;
        case 3:
            const last3 = value.substring(value.length - 4);

            if ((last3 === ',000' && isRu) || (last3 === '.000' && !isRu)) {
                value = value.substring(0, value.length - 4);
            }
            break;
    }

    return isRu ? value.replace(/(\,\d*?)0+$/, '$1') : value.replace(/(\.\d*?)0+$/, '$1');
}
export function getNumberAfterDotByNumber(val: string) {
    const arr = val.split('.');
    return arr[1]?.length ? arr[1].length : 0;
}
export function declOfNum(num: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]];
    // declOfNum(count, ['найдена', 'найдено', 'найдены']);
}
