import * as moment from 'moment-timezone';
import { Feature } from '@libs/common/models/feature';
import { sortMeasurements } from '@libs/common/utils/utils';

export function getIntervalInHours(start: string, end: string): number {
    const startTime = moment(start);
    const endTime = moment(end);
    return moment.duration(endTime.diff(startTime)).asHours();
}

export function getUniqueMmts(data: Feature[]) {
    const result: string[] = [];

    data.forEach((item) => {
        const keys = Object.keys(item.properties.timeseries);
        keys.forEach((key) => {
            if (key !== 'date' && result.indexOf(key) === -1) {
                result.push(key);
            }
        });
    });

    result.sort(sortMeasurements);

    return result;
}
