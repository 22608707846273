<div class="popup-ask__splash">
    <div class="popup-ask">
        <cross-button
            *ngIf="showCloseIcon"
            class="popup-ask__close"
            (click)="close()"
            [size]="'large'"
        ></cross-button>
        <div class="popup-ask__title">{{ title }}</div>
        <div class="popup-ask__title">«{{ report.name }}»</div>
        <div class="popup-ask__ng-content">
            <div class="form-wrapper" *ngIf="ReportForm">
                <form [formGroup]="ReportForm" novalidate>
                    <div class="name-wrapper" *ngIf="mode === 'name'">
                        <ca-input
                            type="text"
                            controlName="name"
                            [autofocus]="true"
                            [form]="ReportForm"
                            [valueForm]="formName.value"
                            [label]="labelTexts.name"
                            [textError]="getError('name')"
                        ></ca-input>
                    </div>
                    <div class="comment-wrapper" *ngIf="mode === 'comment'">
                        <ca-textarea
                            type="text"
                            controlName="comment"
                            [form]="ReportForm"
                            [valueForm]="formComment.value"
                            [label]="labelTexts.commentPlaceholder"
                            [textError]="getError('comment')"
                            [heightValue]="100"
                        ></ca-textarea>
                    </div>
                </form>
            </div>
        </div>
        <div class="popup-ask__btn_block">
            <btn-cancel class="popup-ask__btn popup-ask__btn--cancel" (cbClick)="close()">
                {{ textCancel }}
            </btn-cancel>
            <btn-accept
                class="popup-ask__btn popup-ask__btn--accept"
                (cbClick)="accept()"
                [disabled]="
                    (ReportForm && ReportForm.invalid) ||
                    (ReportForm.untouched && !ReportForm.dirty)
                "
                [isLoading]="isLoading"
            >
                {{ textAccept }}
            </btn-accept>
        </div>
    </div>
</div>
