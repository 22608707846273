import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TEXTS } from '@libs/common/texts/texts';
import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { selectIsSidebarOpen } from '@cityair/modules/core/store/selectors';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveMmt,
    selectSpeciesList,
    selectRunsImpact,
    selectDisplayObjOnMap,
} from '@cityair/modules/impact/store/impact.feature';
import { RunImpact } from '@cityair/modules/impact/service/api-model-impact';
import { CheckboxItem } from '@libs/common/types/checkbox-item';

@Component({
    selector: 'ca-impact-map-element',
    templateUrl: 'impact-map-element.component.html',
    styleUrls: ['impact-map-element.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactMapElementComponent {
    textNames = TEXTS.NAMES;
    textOnMap = TEXTS.MAP_CONTROL.on;
    textsSwitchButton = TEXTS.PLUMES.switchPostButton;
    textTitleMapControl = TEXTS.PLUMES.forecastOn + ':';
    LayerButtonIconType = LayerButtonIconType;

    selectIsSidebarOpen = selectIsSidebarOpen;
    selectActiveRun = selectActiveRun;
    selectActiveMmt = selectActiveMmt;
    selectSpeciesList = selectSpeciesList;
    selectRunsImpact = selectRunsImpact;
    selectDisplayObjOnMap = selectDisplayObjOnMap;
    constructor(public store: Store) {}

    changeMmt(mmt) {
        this.store.dispatch(ImpactActions.setActiveMmt({ payload: mmt }));
    }

    changeRun(run: RunImpact) {
        this.store.dispatch(ImpactActions.setActiveRun({ payload: run }));
    }

    mapObjectChanges(list: CheckboxItem[]) {
        const listItems = [...list];
        this.store.dispatch(ImpactActions.setMapObjectTypes({ payload: listItems }));
    }
}
