<div class="analytics__wrapper">
    <div class="ca-header-wrapper">
        <div class="header">
            <header-of-panel
                [title]="textsAdminPanel.cityCard"
            ></header-of-panel>
        </div>
    </div>
    <div class="analytics-content scroll">
        <shared-ui-analytic
            [cities]="store.select(selectAllCities) | async"
            [selectedCity]="store.select(selectCityCard) | async"
            [isLoading]="store.select(selectIsLoadingSummary) | async"
            [distributionSummary]="store.select(selectCurrentSummaryData) | async"
            [isDemo]="store.select(selectIsDemoAnalytic) | async"
            (setCityCard)="selectCity($event)"
        ></shared-ui-analytic>
    </div>
</div>
