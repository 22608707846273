import { NgModule } from '@angular/core';

import { AqiIndicatorSimpleComponent } from './aqi-indicator-simple.component';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';

@NgModule({
    imports: [ValueCircleModule],
    exports: [AqiIndicatorSimpleComponent],
    declarations: [AqiIndicatorSimpleComponent],
})
export class AqiIndicatorSimpleModule {}
