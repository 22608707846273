import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { GroupTooltipsMmt } from '@libs/common/types/group-tooltips-mmt';

@Component({
    selector: 'chart-checkbox',
    templateUrl: './chart-checkbox.component.html',
    styleUrls: ['./chart-checkbox.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCheckboxComponent {
    @Input() mmt: string;
    @Input() unit: string;
    @Input() aqiTooltip: TemplateRef<{
        text?: string | string[];
    }>;
    @Input() isSelected: boolean;
    @Input() mmtInfoIcon?: {
        name: string;
        cb: () => void;
    };
    @Input() mmtWithTooltip: string[];
    @Input() groupTooltip: GroupTooltipsMmt;

    @Output() selectMmt = new EventEmitter<void>();
    @Output() toggleMmt = new EventEmitter<void>();

    textAdd = TEXTS.STATION_BLOCK.add;
    textSelect = TEXTS.STATION_BLOCK.select;
    textNames = TEXTS.NAMES;
    textLongNames = TEXTS.MMT_LONG_NAMES;
}
