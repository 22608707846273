<cityair-map-control
        [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
         [title]="TEXTS.NETWORK.mapControl.title"
>
    <div map-control-period *ngIf="store.select(selectDateRange) | async as dateRange">
        <div class="network-map-control-period">
            {{ dateRange | dateRangeText : true }}
        </div>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="analysis-mmt"
            [showLayerButton]="true"
            [activeLayer]="store.select(selectShowLayerOnMap) | async"
            [title]="textsForMapControl.on"
            [mmts]="store.select(selectAllMmts) | async"
            [activeMmt]="store.select(selectActiveMmt) | async"
            (selectMmt)="setActiveMmt($event)"
            (toggleLayer)="toggleGridLayer()"
        ></shared-ui-mmt-selector>
    </div>
</cityair-map-control>
<div class="line-temp-wrapper">
    <div class="line-charts">
        <div
            class="top"
            [class.line-panel]="store.select(selectIsSidebarOpen) | async"
        >
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                class="network-map map-selector"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
            <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectNetworkSchemaZones) | async as val"
                [unit]="TEXTS.MEASURES_SCHEME[val.scheme][val.mmt]"
                [measure]="TEXTS.NAMES[val.mmt]"
                [colors]="val.zone?.color"
                [values]="val.zone?.zone"
                [digitsAfterDot]="getDigits(store.select(selectMeasureScheme) | async)(val.mmt)"
            ></color-legend>
        </div>
    </div>
</div>
