import { createAction, props } from '@ngrx/store';
import {
    NotificationQueryParams,
    BaseNotificationEvent,
    NotificationSettings,
    NotificationType,
    FeedItemsExportRequest,
} from '@libs/common/models/basicModels';
import { NotificationPages } from '@cityair/modules/notifications/store/reducers';
import { HttpErrorResponse } from '@angular/common/http';

export enum NotificationsActions {
    UpdateNotifiablePosts = '[Notifications] Update Notifiable Posts',
    RemoveNotifiablePosts = '[Notifications] Remove Notifiable Post',
    AddNotifiablePosts = '[Notifications] Add Notifiable Post',
    getNotificationsSettings = '[Notifications] Get Notifications Settings',
    getNotificationsEventsPdk = '[Notifications] Get Notifications Events PDK',
    getNotificationsEventsDevice = '[Notifications] Get Notifications Events Device',
    setNotificationsSettings = '[Notifications] Set Notifications Settings',
    setNotificationsEventsPdk = '[Notifications] Set Notifications Events PDK',
    setNotificationsEventsDevice = '[Notifications] Set Notifications Events Device',
    changeCurrentNotificationType = '[Notifications] Change Notification Type',
    markNotificationAsVisited = '[Notifications] Mark Notification As Visited',
    updateNotification = '[Notifications] Update Notification',
    clearData = '[Notifications] Clear data',
    isLoadingEventsList = '[Notifications] is loading events list',
    setFilter = '[Notifications] set filter',
    loadMoreEvents = '[Notifications] load more events',
    setNotificationEvents = '[Notifications] set Notifications events',
    changeCurrentSettingsType = '[Notifications] change settings type',
    changeCurrentFormType = '[Notifications] change form type',
    changeCurrentPage = '[Notifications] change Current Page',
    openSettingForm = '[Notifications] open settings form',
    closeSettingForm = '[Notifications] close settings form',
    goToEvent = '[Notifications] go to Event',
    isLoadingForm = '[Notifications] is loading form',
    createNotificationSettings = '[Notifications] create notification settings',
    createNotificationSettingsSuccess = '[Notifications] create notification settings success',
    formNotificationSettingsError = '[Notifications] create notification settings error',
    editNotificationSettings = '[Notifications] edit notification settings',
    editNotificationSettingsSuccess = '[Notifications] edit notification settings success',
    deleteNotificationSettings = '[Notifications] delete notification settings',
    deleteNotificationSettingsSuccess = '[Notifications] delete notification settings success',
    deleteNotificationSettingsError = '[Notifications] delete notification settings error',
    editNotificationSettingsActive = '[Notifications] edit notification settings  active',
    editNotificationSettingsActiveSuccess = '[Notifications] edit notification settings  active success',
    downloadHistory = '[Notifications] download event history',
}

export const updateNotifiablePosts = createAction(
    NotificationsActions.UpdateNotifiablePosts,
    props<{ postIds: string[] }>()
);

export const removeNotifiablePost = createAction(
    NotificationsActions.RemoveNotifiablePosts,
    props<{ postId: string }>()
);

export const addNotifiablePost = createAction(
    NotificationsActions.AddNotifiablePosts,
    props<{ postId: string }>()
);
export const getNotificationsSettings = createAction(NotificationsActions.getNotificationsSettings);
export const getNotificationsEventsPdk = createAction(
    NotificationsActions.getNotificationsEventsPdk
);
export const getNotificationsEventsDevice = createAction(
    NotificationsActions.getNotificationsEventsDevice
);

export const setNotificationsSettings = createAction(
    NotificationsActions.setNotificationsSettings,
    props<{ payload: NotificationSettings[] }>()
);
export const setNotificationsEventsPdk = createAction(
    NotificationsActions.setNotificationsEventsPdk,
    props<{ payload: BaseNotificationEvent[] }>()
);
export const setNotificationsEventsDevice = createAction(
    NotificationsActions.setNotificationsEventsDevice,
    props<{ payload: BaseNotificationEvent[] }>()
);
export const clearNotificationsData = createAction(NotificationsActions.clearData);
export const changeCurrentNotificationType = createAction(
    NotificationsActions.changeCurrentNotificationType,
    props<{ payload: NotificationType }>()
);
export const changeCurrentSettingsType = createAction(
    NotificationsActions.changeCurrentSettingsType,
    props<{ payload: NotificationType }>()
);
export const changeCurrentFormType = createAction(
    NotificationsActions.changeCurrentFormType,
    props<{ payload: NotificationType }>()
);
export const isLoadingEventsList = createAction(
    NotificationsActions.isLoadingEventsList,
    props<{ payload: boolean }>()
);
export const markNotificationAsVisited = createAction(
    NotificationsActions.markNotificationAsVisited,
    props<{ payload: BaseNotificationEvent }>()
);
export const updateNotification = createAction(
    NotificationsActions.updateNotification,
    props<{ payload: any }>()
);
export const setFilter = createAction(NotificationsActions.setFilter, props<{ payload: any }>());
export const loadMoreEvents = createAction(NotificationsActions.loadMoreEvents);
export const setNotificationEvents = createAction(
    NotificationsActions.setNotificationEvents,
    props<{
        payload: { response: BaseNotificationEvent[]; params: NotificationQueryParams };
    }>()
);
export const changeCurrentPage = createAction(
    NotificationsActions.changeCurrentPage,
    props<{ payload: NotificationPages }>()
);
export const openSettingForm = createAction(
    NotificationsActions.openSettingForm,
    props<{ settings_type: NotificationType; currentSetting: NotificationSettings }>()
);
export const closeSettingForm = createAction(
    NotificationsActions.closeSettingForm,
    props<{ settings_type: NotificationType }>()
);
export const goToEvent = createAction(
    NotificationsActions.goToEvent,
    props<{ payload: BaseNotificationEvent }>()
);
export const isLoadingForm = createAction(
    NotificationsActions.isLoadingForm,
    props<{ payload: boolean }>()
);
export const createNotificationSettings = createAction(
    NotificationsActions.createNotificationSettings,
    props<{ payload: NotificationSettings }>()
);
export const createNotificationSettingsSuccess = createAction(
    NotificationsActions.createNotificationSettingsSuccess,
    props<{ payload: NotificationSettings }>()
);
export const formNotificationSettingsError = createAction(
    NotificationsActions.formNotificationSettingsError,
    props<{ payload: HttpErrorResponse }>()
);
export const editNotificationSettings = createAction(
    NotificationsActions.editNotificationSettings,
    props<{ payload: NotificationSettings }>()
);
export const editNotificationSettingsSuccess = createAction(
    NotificationsActions.editNotificationSettingsSuccess,
    props<{ payload: NotificationSettings }>()
);
export const deleteNotificationSettings = createAction(
    NotificationsActions.deleteNotificationSettings,
    props<{ payload: NotificationSettings }>()
);
export const deleteNotificationSettingsSuccess = createAction(
    NotificationsActions.deleteNotificationSettingsSuccess,
    props<{ payload: NotificationSettings }>()
);
export const deleteNotificationSettingsError = createAction(
    NotificationsActions.deleteNotificationSettingsError,
    props<{ payload: NotificationSettings }>()
);
export const editNotificationSettingsActive = createAction(
    NotificationsActions.editNotificationSettingsActive,
    props<{ payload: NotificationSettings }>()
);
export const editNotificationSettingsActiveSuccess = createAction(
    NotificationsActions.editNotificationSettingsActiveSuccess,
    props<{ payload: NotificationSettings }>()
);
export const downloadHistory = createAction(
    NotificationsActions.downloadHistory,
    props<{ payload: FeedItemsExportRequest }>()
);
