<div class="control-point-pin"
    [ngClass]="{
        'control-point-pin_collapse': isCollapse,
        'control-point-pin_state-selected': selected

    }"
>
    <div class="control-point-pin__body"
         *ngIf="!draggable; else draggableTemplate"
         [ngClass]="{ 'with-contribution': !!data, 'expand': isExpand }"
    >
        <ng-container *ngIf="!isExpand else contentTemp">
            <div class="control-point-pin__circle"
                 [style]="{ backgroundColor: color }"
                 (click)="select()"
            ></div>
            <div class="control-point-pin__value"
                 [style]="{ color: color }"
                 (click)="select()"
            >
                {{ displayValue }}
            </div>
            <div class="additionalData" *ngIf="data" (click)="toggleContent($event)">
                <span class="collapse-icon expand"></span>
            </div>
        </ng-container>
        <ng-template #contentTemp>
            <div class="wrapper-content">
                <div class="title-row">
                    <div class="name">{{point?.name}}</div>
                    <div class="collapse-button" (click)="toggleContent($event)">
                        {{texts.expandControlPoint}}
                        <span class="collapse-icon collapse"></span>
                    </div>
                </div>
                <div class="value-row">
                    <div class="value-block">
                        <div class="circle" [style]="{ background: color }"></div>
                        <div class="mmt" *ngIf="mmtData" [innerHtml]="textNames[mmtData.mmt] + ': '"></div>
                        <div class="units-block">
                            <div class="value">{{displayValue}}</div>
                            <div class="units" *ngIf="mmtData as val" [innerHtml]="textScheme[val.scheme][val.mmt]"></div>
                        </div>
                    </div>
                    <div class="time-block">{{currentTime |shortDateFormat}}</div>
                </div>
                <div class="contributions">
                    <div class="chart-title">{{texts.contributionTitle}}:</div>
                    <div class="contributions__wrapper" *ngIf="(displayValue)&&datasets else noData">
                        <div class="contributions-chart">
                            <canvas
                                baseChart
                                type="doughnut"
                                [datasets]="datasets"
                                [labels]="labels"
                                [options]="options"
                            ></canvas>
                        </div>

                        <div class="contributions__items_wrapper">
                            <div *ngFor="let val of data; index as i">
                                <div *ngIf="val" class="contributions__item">
                                    <div class="name-wrapper">
                                        <span class="circle" [style]="{ background: backgroundColor[i] }"></span>
                                        <span class="point" [innerHtml]="labels[i]"></span>
                                    </div>
                                    <span class="contributions__percent">{{ val?.value | number: '1.0-0': currentLang }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noData>
                        <div class="no-data-wrapper">
                            <div class="no-data">{{texts.noContributes}}</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-template #draggableTemplate>
        <div class="control-point-pin__body draggable">
            <div class="control-point-pin__name">{{ point?.name }}</div>
        </div>
    </ng-template>
    <div class="control-point-pin__line"></div>
    <div
        class="control-point-pin__end"
        *ngIf="!draggable; else draggableEndTemplate"
        [style]="{ backgroundColor: color }"
    ></div>
    <ng-template #draggableEndTemplate>
        <div class="control-point-pin__end draggable"></div>
    </ng-template>
</div>



