import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { TEXTS } from '@libs/common/texts/texts';
import { TabModel } from '@libs/common/types/tab-model';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import {
    authError,
    disabledMapNavigation,
    setActivePlumesControlPoint,
    setConfigForStationChart,
    toggleModule,
    updateAllData,
    updateDateRangeRun,
    updateRunsByInterval,
} from '@cityair/modules/plumes/store/actions';
import { firstValueFrom, interval, Observable, Subject } from 'rxjs';
import {
    currentPlumesMmt,
    getRuns,
    getRunsWithFilter,
    selectLoadingAllData,
    selectPlumesSpeciesList,
    selectRunsConfigDic,
    selectRunsLoadError,
    showLayerOnMap,
} from '@cityair/modules/plumes/store/selectors';
import { selectUserRoleId } from '@cityair/modules/core/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { RunService } from '@cityair/modules/plumes/services/run/run.service';
import { OBSERVER_ID, OPERATOR_ID } from '@cityair/config';
import { RunPlume, RunPlumeAnnotation } from '../../services/run/models';
import { annotateWithDates } from '@libs/common/utils/date-formats';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { INTERVAL_UPDATE_RUNS } from '@cityair/modules/plumes/constants';
import { RunConfig } from '@cityair/modules/plumes/services/run-config/models';
import { allowModule, getCurrentGroup } from '@cityair/modules/core/store/group/group.feature';
import { NameModules } from '@libs/common/enums/name-modules';
import { Dictionary } from '@ngrx/entity';

export enum PlumesPages {
    SOURCES = 'sources',
    CONTROL_POINTS = 'control-points',
    CALCULATION_RESULTS = 'calculation-result',
}

enum PlumesEditPages {
    ADD_CHECKPIONT = 'add-checkpoint',
    EDIT_CALCULATION = 'edit-calculation',
}

@Component({
    selector: 'plumes-page',
    templateUrl: 'plumes-page.component.html',
    styleUrls: ['./plumes-page.component.less'],
})
export class PlumesPage implements OnInit, OnDestroy {
    textsAdminPlumes = TEXTS.ADMIN_PANEL.plumes;
    tabs: TabModel[] = [
        {
            title: TEXTS.PLUMES.runResults,
            type: PlumesPages.CALCULATION_RESULTS,
        },
    ];

    editMode = false;
    selectedTab = this.tabs[0];
    titleEditPage: string;

    plumesEditPages = PlumesEditPages;
    plumesPages = PlumesPages;
    currentPage = PlumesPages.CALCULATION_RESULTS;
    currentEditPage = PlumesEditPages.ADD_CHECKPIONT;
    controlPointForEdit: ControlPoint;
    showPlumesLayer$: Observable<boolean>;
    public ngDestroyed$ = new Subject<void>();
    public currentMeasure: string;
    public loadError = false;
    public isOperator = false;
    public runs: RunPlumeAnnotation[] = [];
    public runsWithFilter: RunPlumeAnnotation[] = [];
    public configs: Dictionary<RunConfig>;
    public speciesList: string[];
    public isLoadingData: boolean;
    public currentEditRunConfig: RunConfig;

    constructor(
        readonly mapboxActions: MapboxActions,
        private vangaAuthService: VangaAuthService,
        private runService: RunService,
        private store: Store
    ) {
        let isValidToken = true;
        const token = this.vangaAuthService.getAccessToken();
        if (!token || token === 'null') {
            isValidToken = false;
        }

        this.store
            .select(getCurrentGroup)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(async (group) => {
                if (group) {
                    const extConfig = group?.ext_config;
                    this.store.dispatch(
                        updateDateRangeRun({
                            payload: {
                                start: moment().startOf('day').subtract(2, 'days').toISOString(),
                                end: moment().add(1, 'day').endOf('day').toISOString(),
                            },
                        })
                    );
                    if (extConfig?.disablePlumesRunNavigation) {
                        this.store.dispatch(
                            disabledMapNavigation({
                                payload: extConfig.disablePlumesRunNavigation,
                            })
                        );
                    }
                    if (extConfig?.plumesStationShowFullChartData) {
                        this.store.dispatch(
                            setConfigForStationChart({
                                payload: extConfig.plumesStationShowFullChartData,
                            })
                        );
                    }
                    const allowPlumes = await firstValueFrom(
                        this.store.select(allowModule(NameModules.plumes))
                    );
                    if (group.id && allowPlumes) {
                        if (isValidToken) {
                            this.store.dispatch(updateAllData());
                        } else {
                            this.store.dispatch(authError({ payload: true }));
                        }
                    }
                }
            });

        this.store
            .select(getRunsWithFilter)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                const sortRuns = data.sort((_a, _b) => {
                    const a = new Date(_a.evaluation_time || 0).getTime();
                    const b = new Date(_b.evaluation_time || 0).getTime();

                    return a < b ? 1 : a > b ? -1 : 0;
                });
                this.runsWithFilter = annotateWithDates<RunPlume>((item) =>
                    moment(item.evaluation_time || 0).toDate()
                )(sortRuns);
            });
        this.store
            .select(getRuns)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                const sortRuns = data.sort((_a, _b) => {
                    const a = new Date(_a.evaluation_time || 0).getTime();
                    const b = new Date(_b.evaluation_time || 0).getTime();

                    return a < b ? 1 : a > b ? -1 : 0;
                });
                this.runs = annotateWithDates<RunPlume>((item) =>
                    moment(item.evaluation_time || 0).toDate()
                )(sortRuns);
            });

        store
            .select(selectUserRoleId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((roleId) => !!roleId)
            )
            .subscribe((roleId) => {
                if (roleId !== OBSERVER_ID.toString()) {
                    this.isOperator = roleId === OPERATOR_ID.toString();
                    if (this.tabs.length === 1) {
                        this.addTabs();
                    }
                }
            });
        store
            .select(currentPlumesMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
        this.store
            .select(selectRunsLoadError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.loadError = data));
        this.store
            .select(selectLoadingAllData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.isLoadingData = data));
        this.store
            .select(selectPlumesSpeciesList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.speciesList = data));
        this.store
            .select(selectRunsConfigDic)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.configs = data;
            });
    }

    ngOnInit() {
        this.store.dispatch(toggleModule({ payload: true }));
        this.showPlumesLayer$ = this.store.select(showLayerOnMap);
        interval(INTERVAL_UPDATE_RUNS)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe(() => {
                this.store.dispatch(updateRunsByInterval());
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
        this.store.dispatch(toggleModule({ payload: false }));
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = this.tabs.find((t) => t === tab);
        this.currentPage = tab.type;
    }

    openAddCheckpoint() {
        this.controlPointForEdit = null;
        this.editMode = true;
        this.currentEditPage = this.plumesEditPages.ADD_CHECKPIONT;
        this.titleEditPage = TEXTS.PLUMES.createControlPointTitle;
    }

    closeEditMode() {
        this.editMode = false;
    }

    openEditCheckpoint() {
        this.editMode = true;
        this.currentEditPage = this.plumesEditPages.ADD_CHECKPIONT;
        this.titleEditPage = TEXTS.PLUMES.editControlPointTitle;
    }

    openCalculationResultsEdit(runConfig) {
        this.editMode = true;
        this.currentEditRunConfig = runConfig;
        this.currentEditPage = this.plumesEditPages.EDIT_CALCULATION;
    }

    openChartControlPoint = (point: ControlPoint, mmt: string) => {
        this.store.dispatch(setActivePlumesControlPoint({ payload: point }));
    };

    changesCurrentPage(page) {
        const tab = this.tabs.find((v) => v.type === page);
        if (tab) {
            this.showSelectedTab(tab);
        }
    }

    private addTabs(): void {
        this.tabs.push({
            title: TEXTS.PLUMES.runConfiguration,
            type: PlumesPages.SOURCES,
        });
        this.tabs.push({
            title: TEXTS.PLUMES.controlPoints,
            type: PlumesPages.CONTROL_POINTS,
        });
    }
}
