<div class="analysis-wrapper scroll" *ngIf="store.select(selectCurrentAnalysisEvent) | async as currentEvent" (scroll)="onScroll()" #scrollable>
    <cross-button class="close-button" size="large" (click)="close()"></cross-button>
    <div class="event-wrapper">
        <div class="table-data">
            <div class="title-row">{{texts.analysisEvent}} № {{currentEvent.id}} </div>
            <div class="table">
                <div class="mmt">{{texts.tableHeader.mmt}}</div>
                <div class="value">{{texts.tableHeader.value}}</div>
                <div class="name">{{texts.tableHeader.name}}</div>
                <div class="period">{{texts.tableHeader.period}}</div>
                <div class="duration">{{texts.tableHeader.duration}}</div>
            </div>
            <div class="content" style="padding-bottom: 26px;">
                <div class="mmt" [innerHtml]="textNames[currentEvent.param]"></div>
                <div class="value max">{{textMPC}}:&nbsp;<span class="number">
                     {{currentEvent.pdk_value}}</span>&nbsp;
                     <span [innerHtml]="ugM"
                     ></span>
                </div>
                <div class="name">
                    <div class="post-count">
                        {{postCountText(currentEvent.uniqueEvents?.length)}} {{textMonitoring}}
                    </div>
                    <div class="number-events">{{excessCountText(currentEvent.events.length)}}</div>
                    <ng-container *ngIf="isCollapsedEvents">
                        <div class="toggle-list" #expandButton style="position: absolute; top: 50px;">
                            <div class="toggle-events_wrapper" (click)="showAllListEvents()">
                                <div class="toggle-events">{{texts.expand}}<span class="arrow expand"></span></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="period" [innerHtml]="getPeriod(currentEvent, true)"></div>
                <div class="duration">{{currentEvent.duration}}</div>
            </div>

            <ng-container *ngIf="!isCollapsedEvents">
                <div class="content" style="padding-bottom: 10px;" *ngFor="let item of currentEvent.events;">
                    <div class="mmt"></div>
                    <div class="value"><span class="number">
                         {{item.max_value | number: '1.0-0'}}</span>&nbsp;
                         <span class="mmt" [innerHtml]="ugM"
                         ></span>
                    </div>
                    <div class="name">
                        <div class="ellipsis" *ngIf="(store.select(selectPostNameById(item?.post_id))| async) as name"
                        >
                            {{name}}
                        </div>
                    </div>
                    <div class="period" [innerHtml]="getPeriod(item)"></div>
                    <div class="duration"></div>
                </div>
                <div class="toggle-list" #collapseButton>
                    <div class="toggle-events_wrapper"
                         [ngStyle]="{
                            left: isFixedCollapseButton ? initPositionCollapseButton?.left + 'px' : 'unset',
                            paddingLeft: !isFixedCollapseButton ? initPositionCollapseButton?.left - 30 + 'px' : 'unset'
                         }"
                         [ngClass]="{ 'collapse-button':isFixedCollapseButton }" (click)="hiddenListEvents()">
                        <div class="toggle-events">{{texts.collapse}}<span class="arrow collapse"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="table-result">
            <div class="title-row">{{texts.analysisResults}}</div>
            <div class="table">
                <div class="veracity" *ngIf="isDemoMode">{{texts.tableHeader.veracity}}</div>
                <div class="result" *ngIf="isDemoMode">{{texts.resultExpert}}</div>
            </div>
            <div class="content">
                <div class="veracity veracity-result">
                     <span class="green" *ngIf="isDemoMode">{{currentEvent.verify}}%</span>
                     <div class="row-event">
                         <div class="row-header">
                             {{texts.eventTypeTitle}}
                         </div>
                         <div class="row-title">{{texts.eventTypeName[currentEvent.type]}}</div>
                         <div class="event-type" *ngIf="currentEvent.type === 'global'">{{texts.eventTypeDesc[currentEvent.type]}}</div>
                         <div class="row-header" *ngIf="currentEvent.forecast_end">{{texts.forecastEndTitle}}</div>
                         <div class="row-title" *ngIf="currentEvent.forecast_end">{{currentEvent.forecast_end_time | shortDateFormat}}</div>
                     </div>
                </div>
                <div class="result" *ngIf="isDemoMode">
                    <div class="row">
                        <div class="icon-name purple">И</div>
                        <div class="comment-block">
                            <div class="header-comment">
                                <div class="name-comment">Иванов Иван Алексеевич</div> <div class="date"> {{'2023-05-09T19:10:00Z'| shortDateFormat}}</div>
                            </div>
                            <div class="user-role">{{texts.userRole.ecolog}}</div>
                            <div class="text">Подтверждаю, что событие определено верно, пожары из Томской области</div>
                        </div>
                    </div>
                    <div class="row action">{{texts.doneResultTitle}}</div>
                    <div class="row">
                        <div class="icon-name green">П</div>
                        <div class="comment-block">
                            <div class="header-comment">
                                <div class="name-comment">Петров Василий Степанович</div> <div class="date">{{'2023-05-09T20:10:00Z'| shortDateFormat}}</div>
                            </div>
                            <div class="user-role">{{texts.userRole.mainEcolog}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="details-wrapper">
        <div class="main-statistics">
            <div class="card-data stat">
                <div class="title-row">{{texts.cardTitle.mainStatistics}}</div>
                <div class="tabs">
                    <switch-item
                        [currentTab]="selectedTab"
                        [tabs]="filterTabs"
                        (action)="displayDays($event)"
                    ></switch-item>
                </div>
                <ng-container *ngIf="store.select(selectIsLoadingEventDataById) | async else statisticTemp">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #statisticTemp>
                    <ng-container *ngIf="store.select(selectGeneralStats)|async as statistic">
                        <div class="days-number">{{statistic[selectedTab.type]}}</div>
                        <div class="year-number">/365 {{textDays}}</div>
                        <div class="description" [innerHtml]="texts.statisticsText(textNames[currentEvent.param])"></div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div class="post-mmt">
            <div class="card-data mmt" style="display: flex;flex-flow: column;justify-content: space-between;">
                <div>
                    <div class="title-row">{{texts.cardTitle.postMmts}}</div>
                    <div class="content-mmt">
                        <div class="table header">
                            <div class="name">{{texts.tableHeader.name}}</div>
                            <div class="mmt">{{texts.tableHeader.mmtLine}}</div>
                            <div class="forecast" *ngIf="isDemoMode">{{texts.tableHeader.forecastLine}}</div>
                        </div>
                        <div class="table" *ngFor="let item of currentEvent?.uniqueEvents; let i = index">
                            <div class="name">
                                <span *ngIf="(store.select(selectPostNameById(item?.post_id))| async) as name"
                                >
                                    {{name}}
                                </span>
                            </div>
                            <div class="mmt"><div class="border" [ngStyle]="{'background-color': COLORS[i]}"></div></div>
                            <div class="forecast" *ngIf="isDemoMode">
                                <svg width="100" height="4">
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="100"
                                        y2="0"
                                        [attr.stroke]="COLORS[i]"
                                        stroke-width="4"
                                        [attr.stroke-dasharray]="[2,2]"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="store.select(selectIsLoadingAnalysisTimeline) |async else chartTimeLine">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #chartTimeLine>
                    <div class="chart-row">
                        <cityair-line-chart class="posts-mmt-charts"
                            [data]="store.select(selectPostsLineChartData) | async"
                            [forecastData]="store.select(selectForecastsLineChartData) | async"
                            [isDemoMode]="isDemoMode"
                            (sizeUpdate)="updateChartSize($event)"
                        ></cityair-line-chart>
                        <div class="line-chart-panel"
                            [style.width.px]="(charSize$ | async)?.width"
                        >
                            <div class="line-chart-wrapper">
                                <div class="line-chart-data">
                                    <ng-container
                                        *ngIf="
                                            (store.select(selectAnalysisDates) | async)
                                                | calculateCurrentTickFreq : currentWidthTimeline as tickFreq
                                        "
                                    >
                                        <ng-container *ngIf="(store.select(selectAnalysisDates) | async) as dates">
                                            <div class="line-chart-block-wrapper" *ngFor="let item of dates; index as i; last as l">
                                                <ng-container *ngIf="i | needRenderTick : tickFreq.freq">
                                                    <div
                                                        *ngIf="i | needRenderTick : tickFreq.formatFreq; else timeLabel"
                                                        class="label-block"
                                                    ><span class="time-line-track-time">{{ item | shortDateTimeline }}</span>
                                                    </div>
                                                    <ng-template #timeLabel>
                                                        <div class="label-block">
                                                            <span class="time-line-track-time">{{ item | caTime }}</span>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container *ngIf="item === TEST_NOW && isDemoMode">
                                                    <div class="line-now-wrapper chart-open">
                                                        <div class="line-label-now">{{ nowText }}</div>
                                                        <div class="line-now-border"></div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="l && (currentEvent.end || currentEvent.forecast_end)">
                                                    <div class="line-now-wrapper chart-open">
                                                        <div class="line-label-now finish">{{texts.finishChartLabel}}</div>
                                                        <div class="line-now-border finish"></div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                   </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                 </ng-template>
            </div>
        </div>
        <div class="correlation">
            <div class="card-data mmt">
                <div class="title-row sm-pb">
                    {{texts.cardTitle.correlationData}}
                    <span class="info-icon-block" (click)="showInfoPopup()"></span>
                </div>
                <div class="legend">
                    <div class="post-name" *ngIf="currentEvent?.uniqueEvents as ev">
                        <div class="name" *ngIf="ev[0]?.post_id as id">
                            <span *ngIf="(store.select(selectPostNameById(id))| async) as name"
                            >
                                {{name}}
                            </span>
                        </div>
                    </div>
                    <div class="label-data">
                        <div class="border" [ngStyle]="{'background-color': '#5938D2' }"></div>
                        <div class="label">— {{texts.dataCorrChartLabel}}</div>
                    </div>
                    <div class="label-data">
                        <div class="border" [ngStyle]="{'background-color': '#5FC6B1' }"></div>
                        <div class="label">— {{texts.dataCorrChartLabelLastDay}}</div>
                    </div>
                </div>
                <ng-container *ngIf="store.select(selectIsLoadingAnalysisCorrelation) |async else chartCorrelation">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #chartCorrelation>
                    <div class="chart-wrapper" style="display: block; width: 100%; height: 316px">
                        <cityair-area-chart [data]="store.select(selectCorrelationChartData)| async"></cityair-area-chart>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="search-source" *ngIf="isDemoMode">
            <div class="card-data mmt">
                <div class="title-row">{{texts.cardTitle.searchSource}}</div>
                <div class="content">
                    <div class="search-button-wrapper" [ngClass]="{'hidden': displaySearchResult }">
                        <button class="search-button" (click)="showResultSearch()" [ngClass]="{'hidden': uploadProgress !== 0 }">
                            {{texts.searchButtonText}}
                        </button>
                        <div class="upload-progress" [ngClass]="{'hidden': uploadProgress === 0 }" >
                            <div class="progress upload-progress" >
                               <div class="progress-bar"
                                    role="progressbar"
                                  [style.width.%]="uploadProgress"
                               ></div>
                            </div>
                         </div>
                    </div>
                    <div class="search-result-wrapper" [ngClass]="{'hidden': !displaySearchResult }">
                        <div class="image-search"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="correlation-map" *ngIf="isDemoMode">
            <div class="card-data cor-map">
                <div class="title-row">
                    <div class="title">
                        {{texts.cardTitle.correlationMap}}
                    </div>
                </div>
                <div class="desp" *ngIf="currentEvent?.uniqueEvents as ev">
                    <div class="name" *ngIf="ev[0]?.post_id as id">
                        <span *ngIf="(store.select(selectPostNameById(id))| async) as name"
                        >
                            {{name}}
                        </span>
                    </div>
                </div>
                <div class="map-image-wrapper ">
                    <div class="image"></div>
                </div>
            </div>
        </div>
        <div class="wind-data" *ngIf="isDemoMode && (store.select(selectWindDataByEvent) | async) as windData">
            <div class="card-data wind">
                <div class="title-row"><div class="title">
                        {{texts.cardTitle.windData}}
                    </div>
                    <div class="demo-wind">
                        {{windData?.velocity | valueFormatNumber : 1 }} {{textMeasuresWind}}
                        {{textWind[windData?.direction] ?? windData?.direction }}
                        <span class="arrow"
                            *ngIf="windDegByDir[windData?.direction] as deg"
                            [ngStyle]="{'transform': 'rotate(' + deg + 'deg)'}"
                        >
                        </span>
                    </div>
                </div>
                <div class="desp" *ngIf="currentEvent?.uniqueEvents as ev">
                    {{texts.windDescriptionLabel}}<br/>
                    <b>
                        <div class="name" *ngIf="ev[0]?.post_id as id">
                            <span *ngIf="(store.select(selectPostNameById(id))| async) as name"
                            >
                                {{name}}
                            </span>
                        </div>
                    </b> (%)
                </div>
                <div class="wind-image-wrapper">
                    <cityair-radar-chart [data]="windData?.daily_stats"></cityair-radar-chart>
                </div>
            </div>
        </div>
        <div class="average-durations" [ngClass]="{ 'is-demo': isDemoMode }">
            <div class="card-data">
                <div class="title-row small">{{texts.cardTitle.averageDurations}}</div>
                <ng-container *ngIf="store.select(selectIsLoadingEventDataById) | async else averageChartTemp">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #averageChartTemp>
                    <ng-container *ngIf="store.select(selectAverageChartData) | async as data">
                        <div class="content bar-chart">
                            <div class="bar-chart-wrapper">
                                <cityair-bar-chart
                                    [mmt]="currentEvent.param"
                                    [data]="data"
                                    [labelsData]="texts.barChartLabels"
                                    [formatValue]="'%'"
                                    [activeIndex]="averageActiveIndex"
                                ></cityair-bar-chart>
                            </div>
                            <div class="legend">
                                <div class="wrap-name" *ngFor="let item of currentEvent.uniqueEvents; index as i"
                                    (mouseover)="averageActiveIndex = i"
                                    (mouseleave)="averageActiveIndex = null"
                                >
                                    <div class="color-block" [style.background-color]="averageActiveIndex === null ||averageActiveIndex === i ? COLORS[i] : COLORS_HOVER[i]"></div>
                                    <div class="post-name" *ngIf="(store.select(selectPostNameById(item?.post_id))| async) as name"
                                    >
                                        {{name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="result-block-info" *ngIf="isDemoMode">{{texts.statisticAverageResult}}</div>
                        <div class="result-block-info" *ngIf="!isDemoMode">{{texts.statisticAverageResultByIndex[getMaxIndex(data, 6)]}}</div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div class="numbers-by-day">
            <div class="card-data wind">
                <div class="title-row small">{{texts.cardTitle.numbersByDay}}</div>
                <ng-container *ngIf="store.select(selectIsLoadingEventDataById) | async else numberByDayChartTemp">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #numberByDayChartTemp>
                    <ng-container *ngIf="store.select(selectDaysNumberData) | async as data">
                        <div class="content bar-chart">
                            <div class="bar-chart-wrapper">
                                <cityair-bar-chart [mmt]="currentEvent.param"
                                    [data]="data"
                                    [labelsData]="labelsDataDayNumbers"
                                    [formatValue]="''"
                                    [activeIndex]="numberByDaysActiveIndex"
                                ></cityair-bar-chart>
                            </div>
                            <div class="legend">
                                <div class="wrap-name" *ngFor="let item of currentEvent.uniqueEvents; index as i"
                                     (mouseover)="numberByDaysActiveIndex = i"
                                     (mouseleave)="numberByDaysActiveIndex = null"
                                >
                                    <div class="color-block" [style.background-color]="numberByDaysActiveIndex === null || numberByDaysActiveIndex === i ? COLORS[i] : COLORS_HOVER[i]"></div>
                                    <div class="post-name" *ngIf="(store.select(selectPostNameById(item?.post_id))| async) as name"
                                    >
                                        {{name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="result-block-info" *ngIf="isDemoMode">{{texts.statisticDaysNumberResult}}</div>
                        <div class="result-block-info" *ngIf="!isDemoMode">{{texts.statisticDaysNumberResultByIndex(getMaxIndex(data, 24))}}</div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div class="concentration" *ngIf="isDemoMode">
            <div class="card-data">
                <div class="title-row small">{{texts.cardTitle.concentration(textNames[currentEvent.param])}}</div>
                <div class="content bar-chart">
                    <div class="bar-chart-wrapper">
                        <cityair-cum-line-chart
                                [data]="store.select(selectCumChartData) |async"
                                [whoData]="whoData"
                        ></cityair-cum-line-chart>
                    </div>
                    <div class="legend">
                        <div class="wrap-name" *ngFor="let item of currentEvent.uniqueEvents; index as i">
                            <div class="color-line" [style.background-color]="COLORS[i]"></div>
                            <div class="post-name small" *ngIf="(store.select(selectPostNameById(item?.post_id))| async) as name"
                            >
                                {{name}}
                            </div>
                        </div>
                        <div class="wrap-name" *ngFor="let item of WHOLables; index as i">
                            <div class="">
                                <svg width="50" height="3">
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="50"
                                        y2="0"
                                        [attr.stroke]="COLORS_CORRELATION_WHO[i]"
                                        stroke-width="3"
                                        [attr.stroke-dasharray]="[6,2]"
                                    />
                                </svg>
                            </div>
                            <div class="post-name small">{{item}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="correlation-table" [ngClass]="{'no-margin': !isDemoMode}">
            <div class="card-data">
                <div class="title-row small" style="padding-bottom: 20px">{{texts.cardTitle.correlationTable}}</div>
                <div class="tabs" style="padding-bottom: 20px">
                    <switch-item
                        [currentTab]="selectedTabCorrelation"
                        [tabs]="filterCorrelationTabs"
                        (action)="selectCorrelationPeriod($event)"
                    ></switch-item>
                </div>
                <ng-container *ngIf="store.select(selectIsLoadingCorrelationFullData) |async else correlationTableTemp">
                    <div class="spinner-wrapper">
                        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
                    </div>
                </ng-container>
                <ng-template #correlationTableTemp>
                    <div class="content correlation" *ngIf="(store.select(selectAllPosts) | async) as allPosts">
                        <div class="table-data">
                            <div class="row-correlation" *ngFor="let item of allPosts; index as i">
                                <div class="post-name ellipsis"
                                >
                                    {{item.name}}
                                </div>
                                <div class="table-value" *ngFor="let v of allPosts; index as k"
                                    [style.background-color]="getCorrelationColorByPeriod(item.id, v.id, currentEvent.param)"
                                ><span *ngIf="getCorrelationValueByPeriod(item.id, v.id, currentEvent.param) as value else emptyTd">{{value | number : '1.2-2': currentLang}}</span>
                                    <ng-template #emptyTd ><span style="color: #000000;">&nbsp;&nbsp; - </span></ng-template>
                                </div>
                            </div>
                            <div class="row-correlation">
                                <div class="post-name"
                                >
                                </div>
                                <div class="table-value" *ngFor="let v of allPosts; index as k">
                                    <div class="transform-name"
                                    >
                                        <div class="ellipsis"></div>{{v.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="legend-correlation">
                            <div class="color-block" *ngFor="let color of COLORS_CORRELATION" [style.background-color]="color"></div>
                        </div>
                        <div class="value-correlation">
                            <div class="value-block" *ngFor="let value of correlationValue">{{value | number  : '1.1-1'  : currentLang }}</div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="wind-data" *ngIf="!isDemoMode && (store.select(selectWindDataByEvent) | async) as windData">
            <div class="card-data wind">
                <div class="title-row"><div class="title">
                        {{texts.cardTitle.windData}}
                    </div>
                    <div class="demo-wind">
                        {{windData?.velocity | valueFormatNumber : 1}} {{textMeasuresWind}}
                        {{textWind[windData?.direction] ?? windData?.direction }}
                        <span class="arrow"
                            *ngIf="windDegByDir[windData?.direction] as deg"
                            [ngStyle]="{'transform': 'rotate(' + deg + 'deg)'}"
                        >
                        </span>
                    </div>
                </div>
                <div class="desp" *ngIf="currentEvent?.uniqueEvents as ev">
                    {{texts.windDescriptionLabel}}<br/>
                    <b>
                        <div class="name" *ngIf="ev[0]?.post_id as id">
                            <span *ngIf="(store.select(selectPostNameById(id))| async) as name"
                            >
                                {{name}}
                            </span>
                        </div>
                    </b> (%)
                </div>
                <div class="wind-image-wrapper">
                    <cityair-radar-chart [data]="windData?.daily_stats"></cityair-radar-chart>
                </div>
            </div>
        </div>
        <div class="row actions-wrapper" *ngIf="isDemoMode">
            <div class="actions">
            <button class="add">{{texts.actions.add}}</button>
            <button class="cancel">{{texts.actions.cancel}}</button>
        </div>
            <div class="comments">
            <div class="card-data">
                <div class="title-row small">{{texts.cardTitle.comments}} <span class="comments-number">• 1</span></div>
                <div class="content-comments">
                    <div class="row flex">
                        <div class="icon-name purple">И</div>
                        <div class="comments-wrapper">
                            <div class="comment-user">
                                <div class="row">
                                    <div class="name">
                                        Иванов Иван Алексеевич
                                    </div>
                                    <div class="date">• {{'2023-05-09T19:10:00Z'| shortDateFormat}}</div>
                                </div>
                                <div class="user-role">{{texts.userRole.ecolog}}</div>
                            </div>
                            <div class="comment-block">
                                <div class="text">Подтверждаю, что событие определено верно, пожары из Томской области</div>
                                <div class="as-result">{{texts.actions.asResult}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="icon-name green">M</div>
                        <div class="add-comments-wrapper">
                            <div class="form-wrapper">
                                <ca-textarea
                                    type="text"
                                    controlName="comment"
                                    [form]="CommentForm"
                                    [valueForm]="''"
                                    [label]="texts.actions.addComment"
                                    [heightValue]="96"
                                ></ca-textarea>
                            </div>
                            <div class="add-comments">
                                <button class="add" [disabled]="true">{{texts.actions.addComment}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div class="popup-wrapper" *ngIf="isShowInfoPopup">
    <shared-ui-info-correlation-popup (closePopup)="isShowInfoPopup = false"></shared-ui-info-correlation-popup>
</div>
