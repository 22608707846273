import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

const VANGA_ACCESS_TOKEN_KEY = 'vanga_auth_access_token';
const VANGA_AUTH_URL = `${environment.vanga_api_url}/auth/v1`;

@Injectable({
    providedIn: 'root',
})
export class VangaAuthService {
    private vangaAccessToken = '';

    constructor(private http: HttpClient) {
        this.setAccessToken(this.getAccessToken());
    }

    getVangaToken() {
        return this.http.post<{ access: string }>(`${VANGA_AUTH_URL}/`, {});
    }

    getAccessToken() {
        return (
            this.vangaAccessToken ||
            (this.vangaAccessToken = localStorage.getItem(VANGA_ACCESS_TOKEN_KEY))
        );
    }

    setAccessToken(accessToken: string) {
        this.vangaAccessToken = accessToken;
        localStorage.setItem(VANGA_ACCESS_TOKEN_KEY, accessToken);
    }

    removeAccessToken() {
        this.vangaAccessToken = '';
        localStorage.removeItem(VANGA_ACCESS_TOKEN_KEY);
    }
}
