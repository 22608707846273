import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { filter, takeUntil } from 'rxjs/operators';
import { deleteUser, loadUsersList } from '@cityair/modules/settings/store/users/actions';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectAvailableModule,
    selectIAm,
    selectRoleNameById,
    selectRoles,
} from '@cityair/modules/core/store/selectors';
import {
    selectAllUsers,
    selectCurrentUserPage,
    selectIsLoadingUsers,
    selectUsersList,
    selectUsersListWithMe,
} from '@cityair/modules/settings/store/users/selectors';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { GroupUser, User } from '@libs/common/models/basicModels';
import { Router } from '@angular/router';
import { SETTINGS_PAGES, USER_ICON_COLORS } from '@cityair/modules/settings/constants';
import { getModulesInfo, ModuleAccessInfo } from '@libs/common/models/access.model';

type PopupType = 'remove' | '';

@Component({
    selector: 'cityair-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnDestroy {
    @ViewChild('userMenu', { static: true }) userMenu: TemplateRef<HTMLDivElement>;
    @ViewChild('popupMenu', { static: true }) popupMenu: TemplateRef<HTMLDivElement>;

    isShowMenu = false;
    menuPositionTop = 0;
    menuPositionLeft = 0;

    selectAllUsers = selectAllUsers;
    selectUsersList = selectUsersList;
    selectUsersListWithMe = selectUsersListWithMe;
    selectAllPosts = selectAllPosts;
    selectIsLoadingUsers = selectIsLoadingUsers;
    selectRoles = selectRoles;
    selectCurrentUserPage = selectCurrentUserPage;
    selectRoleNameById = selectRoleNameById;

    currentLang = LANGUAGE;
    public isShowDeletePopUp = false;
    public isLoading = true;
    public ngDestroyed$ = new Subject<void>();
    texts = TEXTS.LIST_USERS;
    textClear = TEXTS.LIST_OM.clearSearch;
    textEdit = TEXTS.NOTIFICATIONS.edit;
    textConfirmDelete = TEXTS.POPUP_DELETE_USER;
    textEmailLabel = TEXTS.LOGIN_WINDOWS.emailAddress;
    popup: PopupType;
    public newTitle: string;
    private popupOpenerElement: HTMLElement;
    searchQuery = '';
    searchPlaceHolder = TEXTS.LIST_OM.filterForName;
    public sortDirection = 0;
    public sortField = 'id';
    sortingUser;
    sortingDirection = 1;
    selectedUser: GroupUser;
    public currentUser: User;
    public groupId: string;
    public showAccessLevelPopup = false;
    public moduleAccessInfo: ModuleAccessInfo[];
    configPopup = {
        remove: {
            title: TEXTS.LIST_USERS.deleteUser,
            apply: TEXTS.COMMON.delete,
        },
    };
    private _interval: NodeJS.Timeout;
    public currentTime = new Date();

    constructor(
        public store: Store,
        public popupProvider: OffPanelPopupService,
        private router: Router,
        private ref: ChangeDetectorRef
    ) {
        this._interval = setInterval(() => {
            this.currentTime = new Date();
        }, 60000);

        this.store
            .select(selectGroupId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((group) => group !== null)
            )
            .subscribe((group) => {
                this.groupId = group;
                this.store.dispatch(loadUsersList());
                this.ref.markForCheck();
            });
        this.store
            .select(selectIsLoadingUsers)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoading = data;
                this.ref.markForCheck();
            });
        this.store
            .select(selectIAm)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((user) => {
                this.currentUser = user;
                this.ref.markForCheck();
            });
        this.store
            .select(selectAvailableModule)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length)
            )
            .subscribe((modules) => {
                this.moduleAccessInfo = getModulesInfo(modules);
                this.ref.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        clearInterval(this._interval);
        this.popupProvider.setTemplate(null);
    }

    getTitleRemove() {
        return this.texts?.deleteUserTitle(this.selectedUser.login);
    }

    getDecsRemove() {
        return this.texts?.deleteUserDecs(this.selectedUser.login);
    }

    sortUsersLogin = (stateUsers: GroupUser) => stateUsers.login;
    sortUsersEmail = (stateUsers: GroupUser) => stateUsers.email;
    sortUsersId = (stateUsers: GroupUser) => stateUsers.group_role?.id;
    public setSortingCb(sortCb: string): void {
        if (this.sortDirection === 0) {
            this.sortDirection = 1;
        }
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    sortingOfUser = (sortItem) => {
        if (this.sortingUser === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingUser = sortItem;
        }
    };

    notShowTooltip = false;

    public textChangeIn($event) {
        this.searchQuery = $event;
    }

    public getColor(user: GroupUser) {
        const arr = user.id.split('_');
        const id = arr[1];
        const index = Number(id) % USER_ICON_COLORS.length;
        return USER_ICON_COLORS[index];
    }

    showPopup(e: Event, name: PopupType) {
        this.popup = name;

        if (e) {
            this.closeMenu(e);
        }

        this.popupProvider.setTemplate(this.popupMenu);

        this.newTitle = '';
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    openActions({ target, positionTop, positionLeft }, user: GroupUser) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.userMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop - 45;
        this.menuPositionLeft = positionLeft - 240;
        this.isShowMenu = true;
        this.selectedUser = user;
    }

    applyPopup = () => {
        const type = this.popup;
        this.popup = '';

        if (type === 'remove') {
            this.store.dispatch(deleteUser({ userId: this.selectedUser.id }));
        }
    };

    editUser() {
        this.isShowMenu = false;
        this.router.navigate([
            `/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.users}/${SETTINGS_PAGES.edit}/${this.selectedUser.id}`,
        ]);
    }

    addUser() {
        this.router.navigate([
            `/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.users}/${SETTINGS_PAGES.add}`,
        ]);
    }

    resetPassword() {
        this.isShowMenu = false;
    }
}
