<div class="register-events-wrapper" [ngClass]="{'scroll': isDemoMode, 'real-data': !isDemoMode}">
    <div class="row-flex border" *ngIf="isDemoMode">
        <div class="statistics column-flex">
            <div class="header">{{translateText.registerEvents.titleHeader.numbersofIncident(currentYear)}}</div>
            <div class="header-value">{{numbersOfIncidents}}
                <span class="small">
                    /{{countDaysInYear }} {{ countDaysInYear | i18nPlural : textsNotification.timeIntervalDays }}
                </span>
            </div>
        </div>
        <div class="statistics column-flex padding-border">
            <div class=" header">{{translateText.registerEvents.titleHeader.oftenMmt(currentYear)}}</div>
            <div class="header-value">{{textNames[oftenMmt]}}</div>
        </div>
        <div class="statistics column-flex padding-border">
            <div class="header">{{translateText.registerEvents.titleHeader.avarageDuration(currentYear)}}</div>
            <div class="header-value">4<span class="small">ч</span> 20<span class="small">мин</span></div>
        </div>
    </div>
    <div class="filter-row"  *ngIf="(store.select(selectGroupsListEvent) | async) as listGroupsEvents" [ngClass]="{'demo-mode': isDemoMode}">
        <div class="events-search-wrapper">
            <search-input-basic
                #searchInput
                [textPlaceholderInner]="translateText.registerEvents.searchText"
                [textInner]="searchQuery"
                [debounceTimeInner]="300"
                (textChangeInner)="textChangeIn($event)"
            ></search-input-basic>
            <div class="vert-line"></div>
            <cityair-filter-data-list
                [list]="statusList"
                [label]="translateText.registerEvents.tableHeader.status"
                (setValue)="updateFilterStatus($event)"
            ></cityair-filter-data-list>
            <cityair-filter-data-list
                [list]="mmtList"
                [label]="translateText.registerEvents.tableHeader.mmt"
                (setValue)="updateFilterMmt($event)"
            ></cityair-filter-data-list>
            <ng-container *ngIf="!isDemoMode">
                <div class="date-range-wrapper" *ngIf="store.select(selectDateRangeForList) | async as dateRange">
                    <cityair-date-range-selector [dateRange]="dateRange" (dateChange)="store.dispatch(setDateRange({payload: $event}))"></cityair-date-range-selector>
                </div>
            </ng-container>
            <div
                class="clear-filter-wrap"
                [ngClass]="{ disabled: store.select(selectIsClearFilterIsDisabled)| async }"
                (click)="clearFilter()"
            >
                {{ translateText.registerEvents.clearFilter }}
            </div>
        </div>
        <div class="counter-wrapper">
            <div class="counter-wrapper" *ngIf="listGroupsEvents.length">
                <div class="item">
                    <div class="status">
                        <span class="status-text">{{translateText.registerEvents.total}} : </span>
                        <span class="number">{{ listGroupsEvents?.length }}</span>
                    </div>
                </div>
                <ng-container *ngIf="isDemoMode else typeByEnd">
                    <div class="item" *ngFor="let item of getCounterStatus(listGroupsEvents)">
                        <div class="status">
                            <span class="dot"
                                ><svg [attr.width]="10" [attr.height]="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle [attr.cx]="5" [attr.cy]="5" [attr.r]="5" [attr.fill]="colors[item.key]" />
                                </svg>
                            </span>
                            <span class="status-text">{{ translateText.registerEvents.statusTextCounter[item.key] }}: </span>
                            <span class="number">{{ item.value }}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #typeByEnd>
                    <div class="item" *ngFor="let item of getCounterStatusByEnd(listGroupsEvents)">
                        <div class="status">
                            <span class="dot"
                                ><svg [attr.width]="10" [attr.height]="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle [attr.cx]="5" [attr.cy]="5" [attr.r]="5" [attr.fill]="colors[item.key]" />
                                </svg>
                            </span>
                            <span class="status-text">{{ translateText.registerEvents.typeByEndTextCounter[item.key] }}: </span>
                            <span class="number">{{ item.value }}</span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(store.select(selectAnalysisEventLoaded) |async) === false else mainContent">
        <div class="spinner-wrapper">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </div>
    </ng-container>
    <ng-template #mainContent>
        <ng-container *ngIf="(store.select(selectGroupsListEventWithFilter) | async) as listGroupsEvents">
            <div class="column-flex" *ngIf="listGroupsEvents.length else emptyList">
                <div class="table table-padding">
                    <div class="ids">№</div>
                    <div class="period">
                        <div class="wrapper-sorting">
                            <shared-sort-list-item
                                class="point_list__sort_name start"
                                (click)="setSortingCb('start_ms')"
                                [sortingDirection]="sortField === 'start_ms' ? sortDirection : 0"
                                [text]="translateText.registerEvents.tableHeader.periodStart"
                            ></shared-sort-list-item>
                            <shared-sort-list-item
                                class="point_list__sort_name"
                                (click)="setSortingCb('end_ms')"
                                [sortingDirection]="sortField === 'end_ms' ? sortDirection : 0"
                                [text]="translateText.registerEvents.tableHeader.periodEnd"
                            ></shared-sort-list-item>
                        </div>
                    </div>
                    <div class="mmt">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('param')"
                            [sortingDirection]="sortField === 'param' ? sortDirection : 0"
                            [text]="translateText.registerEvents.tableHeader.mmt"
                        ></shared-sort-list-item>
                    </div>
                    <div class="value">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('pdk_value')"
                            [sortingDirection]="sortField === 'pdk_value' ? sortDirection : 0"
                            [text]="translateText.registerEvents.pdkMr"
                        ></shared-sort-list-item>
                    </div>
                    <div class="name">{{translateText.registerEvents.tableHeader.name}}</div>
                    <div class="duration">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('duration_ms')"
                            [sortingDirection]="sortField === 'duration_ms' ? sortDirection : 0"
                            [text]="translateText.registerEvents.tableHeader.duration"
                        ></shared-sort-list-item>
                    </div>
                    <div class="veracity" *ngIf="isDemoMode">{{translateText.registerEvents.tableHeader.veracity}}</div>
                    <div class="status">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('status_rank')"
                            [sortingDirection]="sortField === 'status_rank' ? sortDirection : 0"
                            [text]="translateText.registerEvents.tableHeader.status"
                        ></shared-sort-list-item>
                    </div>
                    <div class="actions"></div>
                </div>
                <div class="main-content scroll" [ngClass]="{'real-data': !isDemoMode}">
                    <div class="content border table-padding mb-10" *ngFor="let currentEvent of listGroupsEvents | orderBy : sortField : sortDirection ">
                        <div class="ids">{{currentEvent.id}}</div>
                        <div class="period">{{ currentEvent.start | shortDateFormatWithOrNotYear : (currentEvent.end === null ? currentEvent.forecast_end_time :  currentEvent.end)}} -
                            <span *ngIf="currentEvent.end === null else finishTime"
                                class="not-ended"
                                caTooltip
                                caTooltipPlacement="top"
                                [caTooltipTemplate]="notEndedTooltip"
                            >
                                <span *ngIf="currentEvent.forecast_end else emptyForecast">
                                    {{currentEvent.forecast_end_time | shortDateFormat}}
                                </span>
                                <ng-template #emptyForecast>&nbsp; ---&nbsp;</ng-template>
                            </span>
                            <ng-template #notEndedTooltip>
                                <ca-tooltip [text]="translateText.registerEvents.tableHeader.forecast"></ca-tooltip>
                            </ng-template>
                            <ng-template #finishTime>
                                {{ currentEvent.end | shortDateFormat }}
                            </ng-template>
                        </div>
                        <div class="mmt" [innerHtml]="textNames[currentEvent.param]"></div>
                        <div class="value">
                            <div class="max"><span class="number">
                                 {{currentEvent.pdk_value}}</span>&nbsp;
                                 <span [innerHtml]="ugM"
                                 ></span>
                            </div>
                        </div>
                        <div class="name"
                            caTooltip
                            caTooltipPlacement="top"
                            [caTooltipTemplate]="postList"
                        >
                            <span *ngIf="(store.select(selectPostNameById(currentEvent.uniqueEvents[0].post_id)) | async) as name">{{name}}</span>&nbsp;
                            <span *ngIf="currentEvent.uniqueEvents?.length > 1" class="additional">
                                +&nbsp;{{translateText.more}} {{postCountText(currentEvent.uniqueEvents?.length - 1)}} {{translateText.monitoring}}
                            </span>
                        </div>
                        <ng-template #postList>
                            <ng-container *ngIf="currentEvent.uniqueEvents?.length > 1">
                                <ca-tooltip [text]="store.select(selectPostListByEvent(currentEvent)) | async"></ca-tooltip>
                            </ng-container>
                        </ng-template>
                        <div class="duration">{{currentEvent.duration}}</div>
                        <div class="veracity" *ngIf="isDemoMode">
                            <span [ngClass]="{
                                'green': currentEvent.verify >= verifyGoodValue,
                                'red':  currentEvent.verify < verifyGoodValue
                                 }"
                            >
                                {{currentEvent.verify}}%
                            </span>
                        </div>
                        <!--<div class="forecast" *ngIf="isDemoMode" [ngClass]="{'center': currentEvent.forecast_end_time === null }">
                            <span *ngIf="currentEvent.forecast_end_time">{{currentEvent.forecast_end_time | shortDateFormat }}</span>
                            <span *ngIf="!currentEvent.forecast_end_time">-&#45;&#45;</span>
                        </div>-->
                        <div class="status" *ngIf="isDemoMode">
                            <div [className]="'status-text ' + currentEvent.status">
                                <span class="dot"
                                    ><svg [attr.width]="8" [attr.height]="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle [attr.cx]="4" [attr.cy]="4" [attr.r]="4" [attr.fill]="colors[currentEvent.status]" />
                                    </svg>
                                </span>
                                {{ translateText.registerEvents.statusText[currentEvent.status] }} <span *ngIf="currentEvent.status === analysisStatus.wait"> 17 ч</span>
                            </div>
                        </div>
                        <div class="status" *ngIf="!isDemoMode">
                            <div [className]="'status-text ' + currentEvent.eventTypeByEnd">
                                <span class="dot"
                                    ><svg [attr.width]="8" [attr.height]="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle [attr.cx]="4" [attr.cy]="4" [attr.r]="4" [attr.fill]="colors[currentEvent.eventTypeByEnd]" />
                                    </svg>
                                </span>
                                {{ translateText.registerEvents.typeByEndText[currentEvent.eventTypeByEnd] }}
                            </div>
                        </div>
                        <div class="actions" [ngClass]="{'disable': isDemoMode && currentEvent.id !== '21'}" (click)="analysisEvents(currentEvent)">{{translateText.registerEvents.actions.analysis}}</div>
                    </div>
                </div>
            </div>
            <ng-template #emptyList>
                <ng-container *ngIf="store.select(selectIsClearFilterIsDisabled)| async else noData">
                    <div class="register-events-list no-data-wrapper">
                        <non-data-screen
                            [text]="translateText.registerEvents.noEventsByPeriod"
                        ></non-data-screen>
                    </div>
                </ng-container>
                <ng-template #noData>
                    <div class="register-events-list no-data-wrapper period">
                         <search-result-placeholder [clearText]="translateText.registerEvents.clearText" (clear)="clearFilter()"
                         ></search-result-placeholder>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>
    </ng-template>
</div>
