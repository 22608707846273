import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { detectTouchDevice } from '@cityair/utils/utils';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { filter, Subject } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { DEFAULT_BOUNDS, DRAW_STYLES, SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import {
    DEFAULT_MAP_SETTINGS,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { GroupMapSettings } from '@libs/common/types/group-map-settings';
import { getCurrentGroup } from '@cityair/modules/core/store/group/group.feature';
import { takeUntil } from 'rxjs/operators';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { LngLat, LngLatBounds, LngLatBoundsLike, Map } from 'mapbox-gl';
import { createBBoxFromRectangle } from '@cityair/utils/utils';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { Feature, FeatureCollection, Position } from 'geojson';
import { Post } from '@libs/common/models/basicModels';
import { setMapSettings } from '@cityair/modules/settings/store/settings/actions';
import {
    selectGroupResponseAfterUpdate,
    selectIsSavingMapSettings,
} from '@cityair/modules/settings/store/settings/selectors';

const BOUNDS_ID = 'new-bounds';

@Component({
    selector: 'cityair-settings-map-settings',
    templateUrl: './map-settings.component.html',
    styleUrls: ['./map-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSettingsComponent implements OnDestroy {
    textsSettings = TEXTS.CONFIG;
    textsCommon = TEXTS.COMMON;
    public textAdminPanel = TEXTS.ADMIN_PANEL;
    public ngDestroyed$ = new Subject<void>();
    public mapSettings: GroupMapSettings;
    public isLoading = true;
    public currentZoom: number;
    public currentCenter: any;
    public showMap = false;
    public isEditPositionMode = false;
    public existBorder = false;
    public isEditBorder = false;
    public showInfoEditBorder = false;
    public showInfoEditPosition = false;
    isTouchDevice: boolean;
    private currentBoundsSettings: LngLatBounds;
    public isChangesZoomCenter = false;
    private map: Map;
    public drawFeatures: Feature[] = [];
    public isSavingMapSettings = false;
    public allPosts: Post[];
    public isMapPreview = false;
    public errorCenter = false;
    public errorPosts = false;
    public form: UntypedFormGroup;
    private groupMapSettings: GroupMapSettings = null;
    private draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            trash: true,
        },
        modes: { ...MapboxDraw.modes, draw_rectangle: DrawRectangle },
        styles: DRAW_STYLES,
    });

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        public store: Store,
        private ref: ChangeDetectorRef,
        private groupFeaturesService: GroupFeaturesService,
        private zone: NgZone
    ) {
        this.isTouchDevice = detectTouchDevice();
        store
            .select(getCurrentGroup)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((g) => !!g)
            )
            .subscribe((group) => {
                this.isLoading = false;
                this.mapSettings = {
                    ...DEFAULT_MAP_SETTINGS,
                    ...(group.ext_config?.mapSettings || {}),
                };
                this.currentZoom = this.mapSettings.zoom;
                this.currentCenter = this.mapSettings.center;
                this.showMap = true;
                this.createForm(this.mapSettings);
                this.existBorder = !!group.ext_config?.mapSettings?.bounds;
                this.groupMapSettings = group.ext_config?.mapSettings ?? {};
                this.updateMapPosition();
                this.ref.markForCheck();
            });
        store
            .select(selectIsSavingMapSettings)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => {
                this.isSavingMapSettings = isLoading;
                this.ref.markForCheck();
            });
        store
            .select(selectGroupResponseAfterUpdate)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((response) => {
                if (response) {
                    if (this.isEditBorder) {
                        this.isEditBorder = false;
                    }
                    if (this.isEditPositionMode) {
                        this.isEditPositionMode = false;
                        this.showInfoEditPosition = false;
                    }
                    this.ref.markForCheck();
                }
            });

        store
            .select(selectAllPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((posts) => {
                this.allPosts = posts;
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    mapCreate(map: Map) {
        this.map = map;
    }

    back = () => {
        this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.groupSettings}/`]);
    };

    mapboxLoad($event) {
        this.currentCenter = this.map.getCenter();
        this.currentZoom = Number(this.map.getZoom().toFixed(4));
        const self = this;
        if (this.existBorder) {
            this.map.setMaxBounds(this.groupMapSettings.bounds);
        }

        this.map.on('draw.create', function (event) {
            self.drawFeatures = event?.features;
            self.ref.markForCheck();
        });

        this.map.on('draw.update', function (event) {
            const correctPolygon = self.correctPolygonAfterUpdate(
                event?.features,
                self.drawFeatures
            );
            self.drawFeatures = correctPolygon.features;

            self.draw.delete(event?.features[0]?.id);
            self.draw.add(correctPolygon);
            self.draw.changeMode('simple_select', { featureIds: [BOUNDS_ID] });
            self.ref.markForCheck();
        });

        this.map.on('draw.delete', function (event) {
            self.drawFeatures = [];
            self.ref.detectChanges();
            self.removeDrawControl();
            self.addDrawControl();
        });
    }

    setBorderMode() {
        this.isEditBorder = true;
        this.showInfoEditBorder = true;
        if (this.existBorder) {
            this.zone.runOutsideAngular(() => {
                this.map.setMaxBounds(DEFAULT_BOUNDS);
                const bounds = this.mapSettings.bounds;
                this.map.fitBounds(bounds, { padding: 200 });
                const polygon = this.getFeatureFromBounds(bounds, BOUNDS_ID);
                this.map.addControl(this.draw);
                this.drawFeatures = polygon.features;
                this.draw.add(polygon);
                this.draw.changeMode('simple_select', { featureIds: [BOUNDS_ID] });
            });
        } else {
            this.zone.runOutsideAngular(() => {
                this.map.addControl(this.draw);
                this.draw.changeMode('draw_rectangle');
            });
        }
    }

    setEditPosition() {
        this.isEditPositionMode = true;
        this.showInfoEditPosition = true;
        this.zone.runOutsideAngular(() => {
            this.map.flyTo({
                center: this.currentCenter,
                zoom: this.currentZoom,
                essential: true,
            });
            if (this.groupMapSettings?.bounds) {
                this.map.setMaxBounds(this.groupMapSettings.bounds);
            }
        });
    }

    public getValueForm(field) {
        return this.form?.get(field)?.value || '';
    }

    applyBorder() {
        this.isEditBorder = false;
        this.showInfoEditBorder = false;
        if (this.drawFeatures?.length) {
            this.existBorder = true;
            const newBounds = this.getBoundsFromFeatures(
                // @ts-ignore
                this.drawFeatures[0]?.geometry.coordinates[0]
            );
            this.currentBoundsSettings = this.getBoundsSetting(newBounds);
            this.removeDrawControl();
            this.errorPosts = this.newBoundsIsValid();
            this.errorCenter = this.centerIsValid();
            if (newBounds) {
                const mapSettings = { ...this.groupMapSettings, bounds: newBounds };
                this.store.dispatch(setMapSettings({ payload: { mapSettings } }));
            }
            this.ref.markForCheck();
        }
    }

    cancelBorderMode() {
        this.isEditBorder = false;
        this.showInfoEditBorder = false;
        this.drawFeatures = [];
        this.errorCenter = false;
        this.errorPosts = false;
        this.zone.runOutsideAngular(() => {
            this.removeDrawControl();
            if (this.groupMapSettings?.bounds) {
                this.map.setMaxBounds(this.groupMapSettings.bounds);
            }
            const center = this.map.flyTo({
                center: this.currentCenter,
                zoom: this.currentZoom,
                essential: true,
            });
        });
    }

    savePosition() {
        let mapSettings = {};
        if (this.isChangesZoomCenter) {
            mapSettings = {
                ...this.groupMapSettings,
                center: {
                    lat: Number(this.currentCenter.lat.toFixed(4)),
                    lng: Number(this.currentCenter.lng.toFixed(4)),
                },
                zoom: Number(this.currentZoom.toFixed(4)),
            };
        }
        this.store.dispatch(setMapSettings({ payload: { mapSettings } }));
    }

    private addDrawControl(): void {
        this.zone.runOutsideAngular(() => {
            this.map.addControl(this.draw);
            this.draw.changeMode('draw_rectangle');
        });
    }

    public updateZoomCenterForm($event) {
        if (this.isEditPositionMode && !this.isEditBorder) {
            if (!this.isChangesZoomCenter) {
                this.isChangesZoomCenter = true;
            }
            this.currentCenter = $event.target.getCenter();
            this.currentZoom = $event.target.getZoom();
            this.form?.controls?.zoom.setValue($event.target.getZoom().toFixed(4));
            this.form?.controls?.lat.setValue(Number($event.target.getCenter()?.lat.toFixed(5)));
            this.form?.controls?.lng.setValue(Number($event.target.getCenter()?.lng.toFixed(5)));
        }
    }

    public cancelPositionMode() {
        this.isEditPositionMode = false;
        this.isChangesZoomCenter = false;
        this.showInfoEditPosition = false;
        this.currentZoom = this.mapSettings.zoom;
        this.currentCenter = this.mapSettings.center;
        this.form?.controls?.zoom.setValue(this.currentZoom);
        this.form?.controls?.lat.setValue(this.currentCenter.lat);
        this.form?.controls?.lng.setValue(this.currentCenter.lng);
        this.zone.runOutsideAngular(() => {
            this.map.flyTo({
                center: this.currentCenter,
                zoom: this.currentZoom,
                essential: true,
            });
        });
        this.ref.markForCheck();
    }

    private removeDrawControl(): void {
        this.zone.runOutsideAngular(() => {
            this.map.removeControl(this.draw);
        });
    }

    private getBoundsSetting(data: LngLatBoundsLike): LngLatBounds {
        const boundaries = [
            [data[0].lng, data[0].lat],
            [data[0].lng, data[1].lat],
            [data[1].lng, data[1].lat],
            [data[1].lng, data[0].lat],
        ];
        const bbox = createBBoxFromRectangle(boundaries);
        return new LngLatBounds([bbox[2], bbox[3]], [bbox[0], bbox[1]]);
    }

    private getFeatureFromBounds(data: LngLatBoundsLike, id: string): FeatureCollection {
        const boundaries = [
            [data[0].lng, data[0].lat],
            [data[0].lng, data[1].lat],
            [data[1].lng, data[1].lat],
            [data[1].lng, data[0].lat],
        ];

        const polygon = {
            id,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [[...boundaries, boundaries[0]]],
            },
        } as Feature;

        return {
            type: 'FeatureCollection',
            features: [polygon],
        };
    }

    private getBoundsFromFeatures(coord: number[][]): LngLatBoundsLike {
        const lats = coord.map((v) => v[1]);
        const lngs = coord.map((v) => v[0]);
        const minLng = Math.min(...lngs);
        const maxLng = Math.max(...lngs);
        const minLat = Math.min(...lats);
        const maxLat = Math.max(...lats);
        const northEast: LngLat = new LngLat(minLng, minLat);
        const southWest: LngLat = new LngLat(maxLng, maxLat);
        return [northEast, southWest];
    }

    private correctPolygonAfterUpdate(
        newPolygon: Feature[],
        oldPolygon: Feature[]
    ): FeatureCollection {
        const coordinates = [];
        let diffPoint: number[] = null;
        let changePointIndex: number = null;
        if (
            'coordinates' in newPolygon[0].geometry &&
            newPolygon[0].geometry.coordinates?.length &&
            'coordinates' in oldPolygon[0].geometry &&
            oldPolygon[0].geometry.coordinates?.length
        ) {
            const coorNew = newPolygon[0].geometry.coordinates[0] as Position[];
            coorNew?.forEach((v, key) => {
                if (
                    'coordinates' in oldPolygon[0].geometry &&
                    JSON.stringify(v) !== JSON.stringify(oldPolygon[0].geometry.coordinates[0][key])
                ) {
                    diffPoint = v;
                    changePointIndex = key;
                }
            });
            if (changePointIndex !== null && diffPoint !== null) {
                const replaceLat = oldPolygon[0].geometry.coordinates[0][changePointIndex][0];
                const replaceLng = oldPolygon[0].geometry.coordinates[0][changePointIndex][1];
                coorNew?.forEach((v, key) => {
                    if (v[0] === replaceLat) {
                        coordinates.push([diffPoint[0], v[1]]);
                    } else if (v[1] === replaceLng) {
                        coordinates.push([v[0], diffPoint[1]]);
                    } else {
                        coordinates.push(v);
                    }
                });
            }
        }

        const polygon = {
            id: BOUNDS_ID,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
            },
            properties: {},
        } as Feature;

        return {
            type: 'FeatureCollection',
            features: [polygon],
        };
    }

    private newBoundsIsValid() {
        return this.allPosts.some(
            (post) => !this.currentBoundsSettings.contains(post.geometry.coordinates)
        );
    }

    private centerIsValid() {
        return !this.currentBoundsSettings.contains(this.currentCenter);
    }

    private createForm(mapSettings) {
        this.form = this.fb.group({
            zoom: [mapSettings.zoom],
            lat: [mapSettings.center?.lat],
            lng: [mapSettings.center?.lng],
        });
        this.form.disable();
    }

    private updateMapPosition() {
        if (this.map) {
            this.zone.runOutsideAngular(() => {
                this.map.flyTo({
                    center: this.currentCenter,
                    zoom: this.currentZoom,
                    essential: true,
                });
                if (this.groupMapSettings?.bounds) {
                    this.map.setMaxBounds(this.groupMapSettings.bounds);
                }
            });
        }
    }
}
