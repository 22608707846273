import { Map, LngLat, LngLatLike, PointLike, Sources, Layer } from 'mapbox-gl';

import { NgZone } from '@angular/core';
import { Locality } from '@libs/common/models/basicModels';
const DISTANCE_TO_CITY_IN_METERS = 100_000;
export function findClosestCity(cities: Locality[], center: LngLat): Locality {
    if (!cities || !cities.length || !center) {
        return null;
    }

    let closest: { distance: number; city: Locality };

    cities.forEach((city) => {
        const distance = new LngLat(
            city.geometry.coordinates[0],
            city.geometry.coordinates[1]
        ).distanceTo(center);

        if (!closest || distance <= closest.distance) {
            closest = { distance, city };
        }
    });

    if (closest && closest.distance < DISTANCE_TO_CITY_IN_METERS) {
        return closest.city;
    }

    return null;
}

// TODO: extract as a service
export class MapObject {
    cache: {
        layers: Layer[];
        sources: Sources;
    };

    constructor(private readonly map: Map, private zone: NgZone) {
        const { layers, sources } = map.getStyle();
        this.cache = { layers, sources };
    }

    getCenter() {
        return this.map.getCenter().wrap();
    }

    centerTo(center: LngLatLike, zoom: number) {
        this.zone.runOutsideAngular(() => {
            this.map.jumpTo({
                center,
                zoom,
            });
        });
    }

    zoomTo(zoom: number) {
        this.zone.runOutsideAngular(() => {
            this.map.zoomTo(zoom);
        });
    }

    moveToLine(center: LngLatLike, zoom?: number, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 500,
                easing: (t: number) => t,
            };

            if (offset) {
                options.offset = offset;
            }

            this.map.easeTo(options);
        });
    }

    moveTo(center: LngLatLike, zoom?: number, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 1000,
                easing: (t: number) => t,
            };

            if (offset) {
                options.offset = offset;
            }

            this.map.easeTo(options);
        });
    }

    panTo(coords: LngLatLike, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            this.map.panTo(coords, offset ? { offset } : undefined);
        });
    }

    panBy(coords: PointLike) {
        this.zone.runOutsideAngular(() => {
            this.map.panBy(coords);
        });
    }
}
