import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LngLat, LngLatLike } from 'mapbox-gl';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import {
    InfoPins,
    MapCenterAndZoom,
    MapControlPins,
    MapPins,
    MapPolygonInfo,
    RegionPins,
    SourceLine,
    SourcePins,
} from '@cityair/namespace';
import {
    canClickOnMarker,
    selectAllCities,
    selectCityCurrentValue,
    selectIsCityMode,
    selectMarkersWithoutLocation,
    selectPostCurrentValue,
    selectSchemeAndMeasure,
    selectZone,
} from '@cityair/modules/core/store/selectors';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { GroupFeaturesService } from '@cityair/modules/core/services/group-features/group-features.service';
import { clickOnCityMarker, mapMarkerClick } from '@cityair/modules/core/store/actions';
import { getColorFromZone } from '@cityair/utils/utils';
import { comparedItemsIds } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import MapboxActions from '../components/mapbox/mapboxActions';
import { Locality, Post } from '@libs/common/models/basicModels';
import {
    selectIsMovingMap,
    setMapCenter,
    setMapZoom,
} from '@cityair/modules/core/store/map/map.feature';

class MapProperties {
    zoom?: number = null;
    center?: LngLatLike = null;

    postPins?: MapPins = null;
    cityPins?: MapPins = null;
    notificationsSelectedPosts?: MapPins = null;
    controlPointPins?: MapControlPins = null;
    correlationPins?: MapPins = null;
    sourceLine?: SourceLine = null;
    polygon?: MapPolygonInfo = null;
    infoPins?: InfoPins = null;
    sourcePins? = null;
    regionPins? = null;
    groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    onMapDragEnd?: (props: MapCenterAndZoom) => void = null;
    onMapZoomChanged?: (zoom: number) => void = null;
}

@Injectable({
    providedIn: 'root',
})
export class MapAdapterService {
    private _empty$ = new BehaviorSubject([] as Locality[]).asObservable();
    private _markers$: Observable<Post[]>;
    private _cityMarkers$: Observable<Locality[]>;

    public zoom: number = null;
    public center: LngLatLike = null;

    public notificationsSelectedPosts: MapPins = null;
    public correlationPins: MapPins = null;
    public postPins: MapPins = null;
    public cityPins: MapPins = null;
    public controlPointPins: MapControlPins = null;

    public sourcePins: SourcePins = null;
    public regionPins: RegionPins = null;
    public groupFeaturesLayer: GeoJSON.FeatureCollection<GeoJSON.Geometry> = null;
    public sourceLine: SourceLine = null;
    public polygon: MapPolygonInfo = null;
    public infoPins: InfoPins = null;
    public pinsAreaData: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>> = null;

    public onMapDragEnd: (props: MapCenterAndZoom) => void = null;
    public onMapZoomChanged: (zoom: any) => void = null;
    public isMovingMap: boolean;
    constructor(
        private store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private mapActions: MapboxActions
    ) {
        this._markers$ = this.store
            .select(selectIsCityMode)
            .pipe(
                switchMap((isCityMode) =>
                    this.store.select(isCityMode ? selectAllPosts : selectMarkersWithoutLocation)
                )
            );

        this._cityMarkers$ = this.store
            .select(selectIsCityMode)
            .pipe(
                switchMap((isCityMode) =>
                    isCityMode ? this._empty$ : this.store.select(selectAllCities)
                )
            );
        this.store.select(selectIsMovingMap).subscribe((data) => (this.isMovingMap = data));
    }

    set(data: MapProperties) {
        Object.assign(this, new MapProperties(), data);
    }

    public getDefaultCityPins(): MapPins {
        const cityPins: MapPins = {
            getPins: null,
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectCityCurrentValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectCityCurrentValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) => {
                const canClickMarker = await firstValueFrom(
                    this.store.select(canClickOnMarker(pin.id))
                );

                if (canClickMarker) {
                    this.store.dispatch(clickOnCityMarker({ cityMarker: pin as Locality }));
                }
            },
        };

        Object.defineProperty(cityPins, 'getPins', {
            get: () => this._cityMarkers$,
        });

        return cityPins;
    }

    public getDefaultPostPins(): MapPins {
        const postPins: MapPins = {
            getPins: null,
            selectDigitsAfterDot: this.store
                .select(selectSchemeAndMeasure)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.store.select(comparedItemsIds),
            getValue: (pin) => this.store.select(selectPostCurrentValue(pin.id)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectZone),
                    this.store.select(selectPostCurrentValue(pin.id)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: async (pin) =>
                (await firstValueFrom(this.store.select(canClickOnMarker(pin.id)))) &&
                this.store.dispatch(mapMarkerClick({ markerId: pin.id })),
        };

        Object.defineProperty(postPins, 'getPins', {
            get: () => this._markers$,
        });

        return postPins;
    }

    public async getDefaultGroupFeaturesLayer() {
        await lastValueFrom(
            this.groupFeaturesService.readyBehavior$.pipe(
                filter((v) => v),
                take(1)
            )
        );
        return this.groupFeaturesService.getConfig(GroupExtConfigName.featuresLayer);
    }

    private defaultMapDragEnd = async (props: MapCenterAndZoom) => {
        if (props?.center && props.center instanceof LngLat) {
            this.store.dispatch(setMapZoom({ payload: props.zoom }));
            this.store.dispatch(setMapCenter({ payload: props.center }));
        }
    };

    public defaultMapZoomChanged = (value: any) => this.zoomChanges(value);

    public zoomChanges = (value: any) => {
        if (this.mapActions.preventZooming || this.isMovingMap) {
            return;
        }
        const zoom = value instanceof Object ? value?.zoom : value;
        const center =
            value instanceof Object && value?.zoom ? value?.zoom : this.mapActions.getCenter();
        this.mapActions.currentZoom = zoom;
        this.store.dispatch(setMapZoom({ payload: zoom }));
        this.store.dispatch(setMapCenter({ payload: center ?? this.mapActions.getCenter() }));
    };

    public async setDefaultMap() {
        this.set({
            cityPins: this.getDefaultCityPins(),
            postPins: this.getDefaultPostPins(),
            groupFeaturesLayer: await this.getDefaultGroupFeaturesLayer(),
            onMapDragEnd: this.defaultMapDragEnd,
            onMapZoomChanged: this.defaultMapZoomChanged,
        });
    }
}
