<div class="system-settings">
    <div class="system-settings__left">
        <div class="system-settings__top scroll">
            <div class="system-settings__items">
                <div class="system-settings__item">
                    <div class="system-settings__item-title">{{ TEXTS.CONFIG.units }}</div>
                    <div class="system-settings__block">
                            <switch-item
                                [currentTab]="findSelected()"
                                [tabs]="tabs"
                                (action)="onSelectScheme($event)"
                            ></switch-item>
                    </div>
                </div>
                <div class="system-settings__item">
                    <div class="system-settings__item-title">{{ TEXTS.CONFIG.timezoneTitle }}</div>
                    <div>
                        <shared-ui-ca-toggle-button
                            [active]="isAutoTimeZone"
                            (activeChange)="toggleAutoTimezone()"
                        ></shared-ui-ca-toggle-button>
                        <span class="toggle-label" (click)="toggleAutoTimezone()"
                              [ngClass]="{ 'not-active' : !isAutoTimeZone }"
                        >
                            {{ TEXTS.CONFIG.autoTimezoneLabel }}
                        </span>
                        <div class="wrapper" [ngClass]="{ disabled: isAutoTimeZone }" (clickOutside)="isShowDropdown = false">
                            <input-dropdown
                                class="tza__input-dropdown"
                                [inputText]="selectedTimezone?.label"
                                [label]="TEXTS.CONFIG.timezoneTitle"
                                [isDisable]="isAutoTimeZone"
                                [(showDropdown)]="isShowDropdown"
                                (click)="toggleTzDropDown()"
                            >
                            </input-dropdown>
                            <div *ngIf="!isAutoTimeZone">
                                <div class="selectbox-radio" [ngClass]="{ hidden: !isShowDropdown }">
                                    <search-input
                                        [textPlaceholder]="TEXTS.CONFIG.searchPlaceholderTzText"
                                        [(text)]="searchText"
                                        [debounceTime]="400"
                                    ></search-input>
                                    <div class="selectbox-radio__list scroll" #timezoneList>
                                        <div
                                            class="selectbox-radio__list-item"
                                            *ngFor="let item of list | multipleSearchfilter : ['label'] : searchText"
                                        >
                                            <label class="selectbox-radio__list-item__label"
                                                   [ngClass]="{'selected': item.selected }"
                                                   (click)="selectTZ(item)"
                                            >
                                                <item-radio
                                                    class="selectbox-radio__list-item__checkmark"
                                                    [check]="item.selected"
                                                ></item-radio>
                                                <div
                                                    class="selectbox-radio__list-item__text"
                                                    [innerHTML]="item.label"
                                                ></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="system-settings__item system-settings__item_type-select" style="margin-bottom: 40px;">
                    <div class="system-settings__item-title">{{ TEXTS.CONFIG.langTitle }}</div>
                    <div class="system-settings__block">
                        <input-dropdown
                            [ngClass]="{
                                'dropdown-lang-icon': true,
                                'dropdown-lang-icon__flag-ru': lang === 'ru',
                                'dropdown-lang-icon__flag-en': lang === 'en'}"
                            data-cy="settings-langauge"
                            [inputText]="inputTextLanguage"
                            [label]="labelLanguage"
                            [(showDropdown)]="showDropdownLanguage"
                            (clickOutside)="showDropdownLanguage = false"
                        >
                            <selectbox-radio-lang
                                [(listItems)]="LanguageItems"
                                (listItemsChange)="updateLanguage($event)"
                            ></selectbox-radio-lang>
                        </input-dropdown>
                    </div>
                </div>
            </div>
            <div class="settings-footer">
                <div class="settings-footer__btn-accept" data-cy="settings-footer-btn-accept">
                    <btn-accept
                        [disabled]="_lang === lang && (store.select(selectMeasureScheme) | async) === scheme && isChangeTimezone()"
                        [isLoading]="false"
                        (cbClick)="saveSystemSettings()"
                        >{{TEXTS.COMMON.save}}</btn-accept
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="system-settings__plug">
        <cityair-settings-plug [title]="textsSettings.title" [image]="'settings.svg'"></cityair-settings-plug>
    </div>
</div>
