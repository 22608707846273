import { Injectable } from '@angular/core';
import { Actions as NgRxActions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap, forkJoin, iif, interval, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {
    GROUP_ID,
    InfoMessage,
    IntervalEnum,
    MAP_STYLE_TYPE_STORAGE_KYE,
} from '@cityair/namespace';
import { MINUTE5_MS } from '@cityair/namespace';
import {
    changeQualityDataMode,
    cityModeOff,
    clickFromApToMo,
    clickOnCityMarker,
    destroyMainMap,
    doNothing,
    groupInfoLoad,
    initCityScreenPage,
    initMainMap,
    intervalUpdate,
    isLoadingTimeline,
    loadCity,
    loadQualityData,
    logOut,
    mapMarkerClick,
    refreshVangaToken,
    removeFromComparison,
    saveSettings,
    selectCity,
    setChartData,
    setCityMode,
    setComparisonMode,
    setDefaultGroup,
    setGlobalMeasurement,
    setGroupInfo,
    setMapStyleType,
    setQualityData,
    setQualityDataMarkers,
    setTimelineDateTimes,
    setTypeInterval,
    setUserInfo,
    setUserSettings,
    setWorldMode,
    addAlert,
    timelineLoaded,
    updateChartData,
    updateGroupInfo,
    updateTimeRangeData,
    updateUserSettings,
    vangaTokenUpdated,
    vangaTokenUpdateError,
} from './actions';
import {
    AppState,
    getIntervalUpdateData,
    getDataParams,
    isCompareMode,
    selectAllCities,
    selectIsCityMode,
    selectMyRole,
    selectNearestCity,
    selectParamsForTimeline,
    selectParamsForTimelineOne,
    selectQualityDataMode,
    selectQualityDataPostId,
    selectUserScheme,
} from './selectors';
import { selectAllPosts, updatePostList } from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectGroupList,
    setGroupList,
    setCurrentGroup,
    getCurrentGroup,
    selectGroupId,
    selectExtConfig,
} from '@cityair/modules/core/store/group/group.feature';

import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { copyObj } from '@libs/common/utils/utils';
import { StoreSettingsPart } from '@cityair/modules/core/store/reducers';
import { ZONES } from '@libs/common/consts/zone.const';
import { measureZones } from '@libs/common/helpers/measure-zones';
import {
    onIsEnabledChart,
    setMainChartChartLoading,
} from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import {
    clearCompareList,
    compareListRemove,
    compareListAddUnique,
} from './compared-list/compared-list.actions';
import {
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import { hideHtmlLoader } from '@cityair/utils/utils';
import { StorageService } from '@cityair/modules/login/services/auth/storage.service';
import {
    clearCurrentCity,
    selectCurrentCity,
    setCurrentCity,
} from './current-city/current-city.feature';
import {
    isLoadingSummaryData,
    loadSummaryData,
    selectAllSummaryDic,
    setCityCard,
    setCitySummary,
} from './citycard/citycard.feature';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { RoutingService } from '../routing.service';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { actionsBeforeCompare } from './helpers/actions-before-compare';
import {
    ADMIN_ID,
    getShiftMapCityCard,
    getShiftMapMobile,
    GLOBAL_ZOOM_LEVEL,
} from '@cityair/config';
import { DataQualityApi } from '@cityair/modules/core/services/api/data-quality-api';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { IQualityDataParams } from '@libs/common/models/dataQuality';
import { AQI_IN_ANALYTICS_GROUP_IDS } from '@cityair/modules/analytics/constants';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import {
    IBasicResponse,
    IExtraTimelineResponse,
    DataObjType,
    Group,
    User,
} from '@libs/common/models/basicModels';
import { GroupFeaturesService } from '@cityair/modules/core/services/group-features/group-features.service';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { setAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { loadUsersList } from '@cityair/modules/settings/store/users/actions';
import { currentEvent } from '@cityair/modules/notifications/store/selectors';
import {
    selectMapCenter,
    selectMapZoom,
    setMapCenter,
    setMapZoom,
} from '@cityair/modules/core/store/map/map.feature';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { LANGUAGE } from '@libs/common/texts/texts';
import { setSavedLang } from '@libs/common/utils/local-storage';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { updateGroupSettingsResponse } from '@cityair/modules/settings/store/settings/actions';
import { DEFAULT_MAP_STYLES } from '@libs/common/consts/map.const';

@Injectable()
export class CommonEffects {
    constructor(
        private actions$: NgRxActions,
        private dataQualityApi: DataQualityApi,
        private basicApi: BasicApi,
        private storageService: StorageService,
        private authService: AuthService,
        private groupFeaturesService: GroupFeaturesService,
        private store: Store<AppState>,
        private vangaAuthService: VangaAuthService,
        private routingService: RoutingService,
        private mapActions: MapboxActions
    ) {}

    _startInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initMainMap),
            switchMap(() =>
                interval(MINUTE5_MS).pipe(takeUntil(this.actions$.pipe(ofType(destroyMainMap))))
            ),
            switchMap(() => this.store.select(getIntervalUpdateData).pipe(take(1))),
            switchMap(({ isAllowUpdate, newTimeRange }) => {
                if (isAllowUpdate)
                    return [
                        intervalUpdate({
                            begin: newTimeRange.begin,
                            end: newTimeRange.end,
                        }),
                    ];

                return [doNothing()];
            })
        )
    );

    loadUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initCityScreenPage),
            switchMap((props) => this.basicApi.getUser()),
            switchMap((response: IBasicResponse) => {
                const actions = [];
                const user = response?.data as User;
                if (user?.settings) {
                    // check current lang settings
                    if (user.settings.language && LANGUAGE !== user.settings.language) {
                        setSavedLang(user.settings.language);
                        location.reload();
                    }
                    const scheme = user.settings?.use_pdk_shares
                        ? MeasureScheme.mpc
                        : user.settings.measure_scheme;
                    actions.push(setUserSettings({ payload: scheme }));
                    // set global users timezone    user?.settings?.timezone && user?.settings?.timezone === 'default'
                    if (
                        user?.settings.timezone === null ||
                        user?.settings?.timezone === 'default'
                    ) {
                        moment.tz.setDefault();
                    } else {
                        moment.tz.setDefault(user.settings.timezone);
                    }
                }

                actions.push(setUserInfo({ payload: response }));
                return actions;
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                hideHtmlLoader();
                const errorAction = getActionBasicError(errorResponse, true);
                return of(errorAction);
            })
        )
    );

    loadGroupsInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setUserInfo),
            switchMap((props) => this.basicApi.getGroups()),
            map((response: IBasicResponse) => {
                const list = response?.data as Group[];
                if (list && list.length) {
                    return setGroupList({ payload: response });
                } else {
                    hideHtmlLoader();
                    return addAlert({
                        id: new Date().valueOf(),
                        messageKey: 'Access_Denied',
                        fullScreen: true,
                    });
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                hideHtmlLoader();
                const errorAction = getActionBasicError(errorResponse, true);
                return of(errorAction);
            })
        )
    );

    setGroupList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setGroupList),
            map((action) => setDefaultGroup())
        )
    );

    setDefaultGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setDefaultGroup),
            withLatestFrom(this.store.select(selectGroupList), this.store.select(getCurrentGroup)),
            switchMap(([actionData, groupList, currentGroup]) => {
                const actions = [];

                if (!currentGroup?.id && groupList?.length) {
                    const savedGroupId = this.storageService.get(GROUP_ID);
                    const group = groupList?.find((g) => g.id === savedGroupId) ?? groupList[0];
                    const extConfig = group?.ext_config ?? {};
                    this.groupFeaturesService.setFeatures(extConfig);
                    // style for Map
                    const mapStylesTypes = extConfig?.mapStyleSelector ?? DEFAULT_MAP_STYLES;
                    if (mapStylesTypes?.length) {
                        const savedMapStylesType =
                            (this.storageService.get(MAP_STYLE_TYPE_STORAGE_KYE) as MapStyleType) ??
                            extConfig.mapSettings?.type;
                        if (savedMapStylesType && mapStylesTypes.includes(savedMapStylesType)) {
                            actions.push(setMapStyleType({ payload: savedMapStylesType }));
                        }
                    } else if (
                        extConfig.mapSettings?.type &&
                        extConfig.mapSettings?.type in MapStyleType
                    ) {
                        actions.push(setMapStyleType({ payload: extConfig.mapSettings.type }));
                    }

                    actions.push(setCurrentGroup({ group }));
                }

                return [...actions, updateGroupInfo()];
            })
        )
    );

    updateGroupInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGroupInfo),
            withLatestFrom(this.store.select(getCurrentGroup)),
            switchMap(([actionData, currentGroup]) => [
                groupInfoLoad({ groupId: currentGroup?.id }),
            ])
        )
    );

    loadInfoGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupInfoLoad),
            withLatestFrom(this.store.select(getCurrentGroup), this.store.select(selectMyRole)),
            filter(([props, currentGroup]) => !!currentGroup),
            switchMap(([props, currentGroup, myRole]) => {
                const query = [
                    this.basicApi.getAllPost(props.groupId),
                    this.basicApi.getAllLocality(props.groupId),
                    this.basicApi.getAllDevices(props.groupId),
                ];
                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const actions = [];
                        hideHtmlLoader();
                        actions.push(setAllPosts({ payload: res[0]?.data }));
                        actions.push(setGroupInfo({ payload: res, currentGroup, myRole }));
                        return actions;
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        hideHtmlLoader();
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                );
            })
        )
    );

    loadUserList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupInfoLoad),
            withLatestFrom(this.store.select(selectMyRole)),
            filter(([_, myRole]) => myRole !== null && myRole?.id === ADMIN_ID.toString()),
            map(() => loadUsersList())
        )
    );

    prepareSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setGroupInfo),
            withLatestFrom(this.store.select(selectUserScheme)),
            switchMap(([data, scheme]) => {
                const actions = [];
                const obj: StoreSettingsPart = {};
                const extConfig = data.currentGroup?.ext_config ?? {};
                if (extConfig) {
                    const pinsDataFormat = extConfig[GroupExtConfigName.pinsDataFormat];
                    if (pinsDataFormat) {
                        const zones = copyObj(ZONES);
                        zones[pinsDataFormat.measureScheme][pinsDataFormat.type] = {
                            color: pinsDataFormat.colors,
                            zone: pinsDataFormat.zones,
                        };

                        obj.measuresZones = zones;
                    }
                }
                // TODO for demo indore(atmos)
                if (
                    data.currentGroup.id &&
                    AQI_IN_ANALYTICS_GROUP_IDS.indexOf(data.currentGroup.id) >= 0
                ) {
                    actions.push(setTypeInterval({ payload: IntervalEnum.hour }));
                }
                measureZones.setScheme(scheme);

                if (!data.myRole) {
                    actions.push(
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Access_Denied',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'error',
                            duration: 10000,
                            showCloseIcon: true,
                            size: 'lg',
                        })
                    );
                }

                return [...actions, saveSettings(obj)];
            })
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshVangaToken),
            switchMap((action) =>
                this.vangaAuthService.getVangaToken().pipe(
                    map((result) => {
                        if (result?.access) {
                            this.vangaAuthService.setAccessToken(result.access);
                            return vangaTokenUpdated();
                        }
                    }),
                    catchError((error) => of(vangaTokenUpdateError()))
                )
            )
        )
    );

    isEnabledChartOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            switchMap((_) => [clearCompareList()])
        )
    );

    onRemoveFromComparison = createEffect(() =>
        this.actions$.pipe(
            ofType(removeFromComparison),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectQualityDataMode)
            ),
            switchMap(([{ id }, comparedItems, dataQualityMode]) => {
                const actions = [];

                const newSelected = comparedItems.filter((item) => item.id != id);
                if (!newSelected.length) {
                    actions.push(setComparisonMode({ payload: false }));
                    actions.push(onIsEnabledChart({ payload: false }));
                } else if (
                    dataQualityMode &&
                    newSelected.length === 1 &&
                    newSelected[0].obj === DataObjType.post
                ) {
                    actions.push(loadQualityData({ id: newSelected[0].id }));
                }

                return [...actions, compareListRemove({ id: id })];
            })
        )
    );

    getTimelineData = createEffect(() =>
        this.actions$.pipe(
            ofType(setGroupInfo, setGlobalMeasurement, updateTimeRangeData, setTypeInterval),
            withLatestFrom(this.store.select(selectParamsForTimelineOne)),
            filter(([_, data]) => data !== null),
            tap(() => this.store.dispatch(isLoadingTimeline({ payload: true }))),
            switchMap(([action, { groupId, params }]) =>
                this.basicApi.getDataTimeline(groupId, params).pipe(
                    switchMap((response) => [
                        isLoadingTimeline({ payload: false }),
                        timelineLoaded({ payload: response }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, isLoadingTimeline({ payload: false }));
                        }
                    })
                )
            )
        )
    );

    updateDates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(timelineLoaded),
            map((action) => {
                const extraResponse = action.payload.meta.extra as IExtraTimelineResponse;
                const dates = extraResponse?.dates ?? [];
                return setTimelineDateTimes({ dateTimes: dates });
            })
        )
    );
    cityModeOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cityModeOff),
            switchMap((action) => [
                clearCurrentCity(),
                setComparisonMode({ payload: false }),
                setWorldMode(),
            ])
        )
    );

    clearChartData = createEffect(() =>
        this.actions$.pipe(
            ofType(setComparisonMode),
            filter((action) => !action.payload),
            map((action) => setChartData({ payload: null, feature: null }))
        )
    );

    selectCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCity),
            withLatestFrom(this.store.select(selectAllCities)),
            switchMap(([{ cityId, centringMap }, cities]) => {
                const actions = [];
                const city = cities.find((c) => c.id === cityId);
                actions.push(setCityMode());

                if (city) {
                    actions.push(setCurrentCity({ city }));
                    if (centringMap) {
                        this.mapActions.centringMap(city, true);
                    }
                }

                return actions;
            })
        )
    );

    loadCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCity),
            switchMap(({ cityId, centringMap }) => [selectCity({ cityId, centringMap })])
        )
    );

    clickFromApToMo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickFromApToMo),
            withLatestFrom(this.store.select(selectIsCityMode), this.store.select(selectAllPosts)),
            switchMap(([{ moObjId }, isCityMode, markers]) => {
                const actions = [];

                const marker = markers.find((el) => el.id === moObjId);

                if (marker) {
                    if (!isCityMode && marker.ancestor?.id) {
                        actions.push(loadCity({ cityId: marker.ancestor.id, centringMap: true }));
                    }

                    actions.push(mapMarkerClick({ markerId: marker.id }));

                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        getShiftMapCityCard()
                    );
                }

                return actions;
            })
        )
    );

    mapMarkerClick$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapMarkerClick),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode),
                this.store.select(selectQualityDataMode),
                this.store.select(selectAllPosts)
            ),
            switchMap(
                ([
                    { markerId },
                    comparedItems,
                    isLimitd,
                    isComparedMode,
                    qualityDataMode,
                    posts,
                ]) => {
                    const actions = [];

                    const marker = posts.find((el) => el.id === markerId);
                    const compareActions = actionsBeforeCompare(
                        marker,
                        comparedItems,
                        isLimitd,
                        isComparedMode
                    );
                    if (compareActions.delete) {
                        actions.push(removeFromComparison({ id: markerId }));
                    }

                    if (compareActions.clearBefore) {
                        actions.push(clearCompareList());
                    }

                    if (!compareActions.add) {
                        return actions;
                    }

                    if (marker.obj === DataObjType.outdoorPost && qualityDataMode) {
                        if (!isComparedMode || comparedItems.length === 0) {
                            actions.push(loadQualityData({ id: marker.id }));
                        } else {
                            actions.push(setQualityData({ payload: [] }));
                        }
                    }
                    actions.push(compareListAddUnique({ objects: [marker] }));
                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        detectMobile() ? getShiftMapMobile() : getShiftMapCityCard()
                    );
                    return actions;
                }
            )
        )
    );

    clickOnCityMarker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickOnCityMarker),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode)
            ),
            switchMap(([{ cityMarker }, comparedItems, isLimitd, isComparedMode]) => {
                const actions = [];
                const compareActions = actionsBeforeCompare(
                    cityMarker,
                    comparedItems,
                    isLimitd,
                    isComparedMode
                );

                if (compareActions.delete) {
                    actions.push(removeFromComparison({ id: cityMarker.id }));
                    return actions;
                }

                if (compareActions.clearBefore) {
                    actions.push(clearCompareList());
                }

                if (compareActions.add) {
                    actions.push(compareListAddUnique({ objects: [cityMarker] }));
                }

                if (compareActions.add && detectMobile()) {
                    this.mapActions.centringOnMarker(
                        cityMarker.geometry.coordinates[1],
                        cityMarker.geometry.coordinates[0],
                        true,
                        getShiftMapMobile()
                    );
                }

                return actions;
            })
        )
    );

    getQualityDataMarkers = createEffect(() =>
        this.actions$.pipe(
            ofType(changeQualityDataMode),
            switchMap((response) =>
                this.dataQualityApi.getQualityDataMarkers().pipe(
                    switchMap((response) => [setQualityDataMarkers({ payload: response })]),
                    catchError((error: HttpErrorResponse) => of(doNothing()))
                )
            )
        )
    );

    loadChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(compareListAddUnique),
            withLatestFrom(
                this.store.select(selectParamsForTimeline),
                this.store.select(selectExtConfig)
            ),
            filter(([action, data]) => data !== null && !!action.objects[0]?.id),
            tap(() => this.store.dispatch(setMainChartChartLoading({ payload: true }))),
            concatMap(([action, { groupId, params }, extConfig]) => {
                const isCity = action.objects[0]?.obj === DataObjType.city;
                const id = action.objects[0].id;
                const paramsWithGroup = Object.assign({}, params, { group_id: groupId });
                return iif(
                    () => isCity,
                    this.basicApi.getCityDataTimeline(id, paramsWithGroup).pipe(
                        tap(() =>
                            this.store.dispatch(setMainChartChartLoading({ payload: false }))
                        ),
                        map((response) =>
                            setChartData({
                                payload: response,
                                feature: action.objects[0],
                                extConfig,
                            })
                        ),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    ),
                    this.basicApi.getPostDataTimeline(id, paramsWithGroup).pipe(
                        tap(() =>
                            this.store.dispatch(setMainChartChartLoading({ payload: false }))
                        ),
                        map((response) =>
                            setChartData({
                                payload: response,
                                feature: action.objects[0],
                                extConfig,
                            })
                        ),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return iif(
                                () => errorResponse.status !== HttpStatusCode.NotFound,
                                of(errorAction),
                                of(
                                    removeFromComparison({ id: action.objects[0]?.id }),
                                    addAlert({
                                        id: new Date().valueOf(),
                                        messageKey: 'notFoundPost',
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    }),
                                    updatePostList()
                                )
                            );
                        })
                    )
                );
            })
        )
    );

    getCitySummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCityCard),
            withLatestFrom(this.store.select(selectAllSummaryDic)),
            filter(([_, data]) => !!_.city?.id && !data[_.city.id]),
            map(([action, summary]) => loadSummaryData({ id: action.city.id }))
        )
    );

    loadSummaryData$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSummaryData),
                withLatestFrom(this.store.select(selectGroupId)),
                filter(([action, groupId]) => !!action.id && !!groupId),
                tap(() => this.store.dispatch(isLoadingSummaryData({ payload: true }))),
                switchMap(([action, groupId]) =>
                    this.basicApi.getSummary(groupId, action.id).pipe(
                        map((response) => setCitySummary({ payload: response?.data })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, isLoadingSummaryData({ payload: false }));
                        })
                    )
                )
            ) as Observable<Action>
    );

    updateChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate, setTypeInterval),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectParamsForTimeline),
                this.store.select(selectExtConfig),
                this.store.select(currentEvent)
            ),
            filter(
                ([action, compredItems, params, extConfig]) =>
                    !!compredItems.length && params !== null
            ),
            tap(() => this.store.dispatch(setMainChartChartLoading({ payload: true }))),
            switchMap(([actionData, comparedItems, { groupId, params }, extConfig, event]) => {
                const paramsWithGroup = Object.assign({}, params, { group_id: groupId });
                let isExistInCompare = false;
                let postId;

                if (event !== null) {
                    postId = event?.post_id ?? event?.service_excess_info?.post_id;
                    isExistInCompare =
                        comparedItems.find((v) => v.id === postId) === undefined ? false : true;
                }
                const query = comparedItems.map((item) =>
                    item?.obj === DataObjType.city
                        ? this.basicApi.getCityDataTimeline(item.id, paramsWithGroup)
                        : this.basicApi.getPostDataTimeline(item.id, paramsWithGroup)
                );

                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const actions = [];
                        actions.push(setMainChartChartLoading({ payload: false }));
                        actions.push(
                            updateChartData({ payload: res, features: comparedItems, extConfig })
                        );
                        if (!isExistInCompare && postId) {
                            actions.push(
                                clickFromApToMo({
                                    moObjId: postId,
                                })
                            );
                        }

                        return actions;
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(
                            setMainChartChartLoading({ payload: false }),
                            updateChartData({ payload: null, features: null }),
                            clearCompareList(),
                            updatePostList(),
                            errorAction
                        );
                    })
                );
            })
        )
    );

    updateQualityData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate, setTypeInterval),
            withLatestFrom(this.store.select(selectQualityDataPostId)),
            switchMap(([_, id]) =>
                iif(
                    () => id !== null,
                    [loadQualityData({ id: id })],
                    [setQualityData({ payload: [] })]
                )
            )
        )
    );

    onIsEnabledChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setChartData),
            filter((action) => action.feature !== null),
            map((actionData) => onIsEnabledChart({ payload: true }))
        )
    );

    loadQualityDataByPost = createEffect(() =>
        this.actions$.pipe(
            ofType(loadQualityData),
            withLatestFrom(this.store.select(getDataParams)),
            filter(([action, params]) => action?.id !== null && params !== null),
            switchMap(([action, params]) =>
                this.dataQualityApi
                    .getQualityDataTimeline(
                        Object.assign(
                            { post_id: action.id.toString() },
                            params
                        ) as IQualityDataParams
                    )
                    .pipe(
                        withLatestFrom(this.store.select(selectComparedItems)),
                        map(([response, data]) =>
                            setQualityData({ payload: data?.length === 1 ? response : [] })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                setQualityData({ payload: [] }),
                                addAlert({
                                    id: new Date().valueOf(),
                                    messageKey: 'errorQualityDataTimeline',
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'error',
                                    duration: 10000,
                                    showCloseIcon: true,
                                    size: 'lg',
                                })
                            )
                        )
                    )
            )
        )
    );

    logOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logOut),
                tap(() => this.authService.logOut(false, true)),
                tap(() => this.vangaAuthService.removeAccessToken())
            ),
        {
            dispatch: false,
        }
    );

    clearChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearCompareList),
            map(() => updateChartData({ payload: null, features: null }))
        )
    );

    mapZoomChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setMapZoom, setMapCenter, setGroupInfo),
            withLatestFrom(
                this.store.select(selectIsCityMode),
                this.store.select(selectNearestCity),
                this.store.select(selectMapZoom),
                this.store.select(selectMapCenter),
                this.store.select(selectCurrentCity)
            ),
            switchMap(([action, isCityMode, city, zoom, center, currentCity]) => {
                const actions = [];
                if (zoom < GLOBAL_ZOOM_LEVEL - 1 && isCityMode) {
                    actions.push(cityModeOff());
                }

                if (
                    (!isCityMode && zoom >= GLOBAL_ZOOM_LEVEL - 1 && city) ||
                    ((isCityMode || zoom >= GLOBAL_ZOOM_LEVEL - 1) &&
                        city &&
                        currentCity &&
                        city.id !== currentCity.id)
                ) {
                    actions.push(loadCity({ cityId: city.id, centringMap: false }));
                }

                return actions;
            })
        )
    );

    saveUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserSettings),
            switchMap((action) =>
                this.basicApi.updateUserSettings(action.payload).pipe(
                    switchMap((response) => {
                        location.reload();
                        return [];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        }
                    })
                )
            )
        )
    );

    updateGroupSettings = createEffect(() =>
        this.actions$.pipe(
            ofType(updateGroupSettingsResponse),
            withLatestFrom(this.store.select(getCurrentGroup)),
            switchMap(([action, currentGroup]) => {
                if (action?.payload) {
                    const group = { ...currentGroup, ext_config: action.payload };
                    return [setCurrentGroup({ group })];
                }
                return [];
            })
        )
    );

    setCurrentMapStyleType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setMapStyleType),
            map((action) => {
                this.storageService.set(MAP_STYLE_TYPE_STORAGE_KYE, action.payload);
                return doNothing();
            })
        )
    );
}
// need move from effects to interceptor
export function getActionBasicError(errorResponse: HttpErrorResponse, isCritical?) {
    const status = errorResponse?.status;
    if (status === 0) {
        return doNothing();
    } else if (!status || status >= HttpStatusCode.InternalServerError) {
        const params = getShowInfoParams('serverError', isCritical);
        return addAlert(params);
    } else if (status === HttpStatusCode.Unauthorized) {
        return logOut();
    } else if (status === HttpStatusCode.NotFound) {
        const params = getShowInfoParams('Not_Found', isCritical);
        return addAlert(params);
    } else if (status === HttpStatusCode.Conflict) {
        const params = getShowInfoParams('Create_Error', isCritical);
        return addAlert(params);
    } else {
        const error =
            errorResponse?.error?.InternalErrorCode ??
            errorResponse?.error.meta?.error?.internal_error_code;
        const params = getShowInfoParams(error, isCritical);
        return addAlert(params);
    }
}
export function getShowInfoParams(key, isCritical?): InfoMessage {
    if (isCritical) {
        return {
            messageKey: key,
            fullScreen: true,
        } as InfoMessage;
    }
    return {
        id: new Date().valueOf(),
        messageKey: key,
        positionX: 'left',
        positionY: 'bottom',
        iconClass: 'error',
        duration: 10000,
        showCloseIcon: true,
        size: 'lg',
    } as InfoMessage;
}
