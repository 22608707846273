import { MeasureScheme } from '@libs/common/enums/measure-scheme';

export const NUMBER_AFTER_DOT: {
    [key in MeasureScheme]: number;
} = {
    [MeasureScheme.default]: 1,
    [MeasureScheme.usa_default]: 1,
    [MeasureScheme.c_mmhg_mg]: 3,
    [MeasureScheme.mpc]: 3,
};
