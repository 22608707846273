<cs-top-right-elements-m
    *ngIf="(moduleConfig$ | async).enableTopElements"
></cs-top-right-elements-m>

<cityscreen-mobile-header></cityscreen-mobile-header>
<div class="timeline-temp-wrapper">
    <div
        class="timeline__wrapper timeline__wrapper-displayFlex"
        [class.timeline__wrapper--mobile]="true"
    >
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectIsSidebarOpen) | async"
            [class.timeline-panel--mobile]="true"
        >
            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [currentTime]="store.select(selectCurrentTimeByIndex) | async"
                [dates]="store.select(selectTimelineDateTimes) | async"
                [features]="store.select(selectSourcesAsFeatures) | async"
                [initSelectMeasurement]="store.select(selectGlobalMeasurement) | async"
                [labelMode]="true"
                [showNow]="true"
                [isComparisonModeActive]="store.select(selectIsComparisonModeActive) | async"
                [pdk]="store.select(selectPdkForChart) | async"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [qualityDataMode]="store.select(selectQualityDataMode) | async"
                [dataQualityTimeline]="store.select(selectQualityDataTimeline)| async"
                [timezone]="store.select(selectUserTimezone) | async"
                (goToCity)="goToCity($event)"
                (changeTimeIndex)="store.dispatch(updateTimeIndex({ payload: $event }))"
            >
            </timeline-panel>
        </div>
    </div>
</div>
