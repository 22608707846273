<div class="network-wrapper">
     <div class="ca-header-wrapper with-tabs">
        <div class="header">
            <header-page
                [textObject]="{
                    titlePage: title
                }"
            ></header-page>
        </div>
    </div>
    <div class="content scroll">
        <div class="body">
            <div class="density-block"  *ngIf="store.select(selectAnalysisData) | async as networkData">
                <div class="wrapper">
                    <div class="header">
                        <div>{{text.summary.header}}</div>
                        <ca-info-icon
                            class="icon-info"
                            [withGradient]="true"
                            (click)="showInfoPopup = !showInfoPopup"
                        ></ca-info-icon>
                    </div>
                    <div class="percent-block">
                        <div class="status">
                            <div class="title">{{ networkData | networkDensity : text.summary.density }}</div>
                            <div class="desc" *ngIf="networkData.stations_count && networkData.network_area_total">
                               {{postText(networkData.stations_count)}} {{textMonitoring}}
                                <span
                                    [innerHtml]="text.summary.squareText(formatSquare(networkData.network_area_total))"
                                ></span>
                            </div>
                        </div>
                        <div class="value-block">
                            <div class="value" *ngIf="networkData.stations_coverage > 1 else persent">
                                {{100 | number}}
                            </div>
                            <ng-template #persent>
                                  <div class="value"> {{networkData.stations_coverage * 100 | number}}</div>
                            </ng-template>
                            <div class="percent">%</div>
                        </div>
                    </div>
                    <div class="header">
                        <div>{{title}} по <span [innerHtml]="textNames[activeMmt]"></span></div>
                        <!--<ca-info-icon class="icon-info" [withGradient]="true" (click)="showInfoPopup = !showInfoPopup"></ca-info-icon>-->
                    </div>
                    <div class="percent-block">
                        <div class="status">
                            <div class="title">
                                <ng-container *ngIf="activeMmt === 'NO2' else defAultTemp">
                                    Низкая
                                </ng-container>
                                <ng-template #defAultTemp>
                                    Средняя
                                </ng-template>
                            </div>
                            <div class="desc" >
                                {{EFFECT_NETWORK[activeMmt]?.posts}} из {{EFFECT_NETWORK[activeMmt]?.count}} постов в рекомендованных областях
                            </div>
                        </div>
                        <div class="value-block">
                            <div class="value">
                                {{EFFECT_NETWORK[activeMmt]?.value}}
                            </div>
                            <div class="percent">%</div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="store.select(selectPriorityDataByMmt) | async as priorityData">
                <ng-container *ngIf="priorityData?.length as countPriority">
                    <div class="block-header">
                        {{ translateText.priorityHeader}}
                    </div>
                    <div class="period">
                        <div class="calendar-icon"></div>
                        <div class="text" *ngIf="dateRange">{{translateText.periodTitle}}   {{ dateRange | dateRangeText : false}}</div>
                    </div>
                    <div class="table">
                        <div class="table-header">
                            <div class="name">{{translateText.name}}</div>
                            <shared-sort-list-item
                                class="sort_value"
                                (click)="setSortingCbPriority('value')"
                                [sortingDirection]="sortFieldPriority === 'value' ? sortDirectionPriority : 0"
                                [text]="textNames[activeMmt]"
                            ></shared-sort-list-item>
                        </div>
                        <div class="priority-content" *ngIf="priorityData | orderBy : sortFieldPriority : sortDirectionPriority as resultPriority">
                            <ng-container *ngFor="let item of resultPriority; let i = index;">
                                <div class="item" *ngIf="i < currentLimit">
                                     <div class="name-block">
                                        <div class="name">{{translateText.polygonName}} {{item.name}}</div>
                                        <div class="region">
                                            <div class="status">
                                                <ng-container *ngIf="item.postIds?.length else neighbourTemp">
                                                    <div class="has-post">
                                                        {{translateText.hasPost}} {{postText(item.postIds?.length)}} {{translateText.monitoring}}                                                    </div>
                                                </ng-container>
                                                <ng-template #neighbourTemp>
                                                    <div class="has-around" *ngIf="item.neighbourPostIds.length">
                                                        {{translateText.hasPost}} {{postText(item.neighbourPostIds.length)}} {{translateText.monitoring}} {{translateText.neighborPost}}
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="value-block">
                                        <div class="color-block" [ngStyle]="{'backgroundColor': item.color}"></div>
                                        <div class="value">{{item.value}}</div>
                                        <div class="unit" [innerHtml]="textSchema[activeMmt]"></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(currentLimit > LIST_PRIORITY_LIMIT) else showMoreTemp">
                                 <div class="show-more" (click)="currentLimit = LIST_PRIORITY_LIMIT">
                                    {{translateText.collapse}}
                                     <span class="arrow collapse">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M1 1L5 5L9 1" stroke="#6097F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #showMoreTemp>
                                <div class="show-more"  *ngIf="countPriority > currentLimit" (click)="currentLimit = countPriority">
                                    {{translateText.showMore}} {{areaText(countPriority - currentLimit)}}
                                    <span class="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M1 1L5 5L9 1" stroke="#6097F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="store.select(selectPostDataByMmt) | async as postData">
                <ng-container *ngIf="postData?.length as countPostData">
                    <div class="block-header">
                        {{translateText.postHeader}}
                    </div>
                    <div class="period">
                        <div class="calendar-icon"></div>
                        <div class="text">{{translateText.periodTitle}} {{ dateRange | dateRangeText : false}}</div>
                    </div>
                    <div class="table">
                        <div class="table-header">
                            <div class="name">{{translateText.name}}</div>
                            <shared-sort-list-item
                                class="sort_value"
                                (click)="setSortingCb('value')"
                                [sortingDirection]="sortField === 'value' ? sortDirection : 0"
                                [text]="textNames[activeMmt]"
                            ></shared-sort-list-item>
                        </div>
                        <div class="priority-content" *ngIf="postData | orderBy : sortField : sortDirection as result">
                            <ng-container *ngFor="let item of result; let i = index;">
                                <div class="item" *ngIf="i < currentLimitPost">
                                     <div class="name-block">
                                        <div class="name">
                                            <ng-container
                                                *ngIf="(store.select(selectPostNameById(item.id))| async) as name"
                                            >
                                                {{name}}
                                            </ng-container>
                                        </div>
                                        <div class="region">
                                            <ng-container
                                                *ngIf="(store.select(selectRegionByPostId(item.id))| async) as nameRegion"
                                            >
                                                {{nameRegion?.name ?? ''}}
                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="value-block">
                                        <div class="color-block" [ngStyle]="{'backgroundColor': item.color}"></div>
                                        <div class="value">{{item.value}}</div>
                                        <div class="unit" [innerHtml]="textSchema[activeMmt]"></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="currentLimitPost > LIST_PRIORITY_LIMIT else showMoreTemp">
                                 <div class="show-more" (click)="currentLimitPost = LIST_PRIORITY_LIMIT">
                                    {{translateText.collapse}}
                                     <span class="arrow collapse">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M1 1L5 5L9 1" stroke="#6097F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #showMoreTemp>
                                <div class="show-more" (click)="currentLimitPost = countPostData">
                                    {{translateText.showMore}} {{postText(countPostData - currentLimitPost)}}
                                    <span class="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M1 1L5 5L9 1" stroke="#6097F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div class="popup-wrapper" *ngIf="showInfoPopup">
    <div class="density-popup">
        <cross-button
            class="close"
            size="large"
            (click)="showInfoPopup=false"
        ></cross-button>
        <div class="title" [innerHTML]="text.summary.header"></div>
        <div class="desc">{{text.summary.popup.desc}}</div>
        <div class="table"  style="padding-bottom: 40px">
            <div class="row">
                <div class="item grey"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.density; let last=last;"
                >
                    {{item}}
                </div>
            </div>
            <div class="row">
                <div class="item"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.increaseValue; let last=last;"
                >
                    {{item}}
                </div>
            </div>
        </div>
        <div class="title">{{text.summary.popup.subTitle}}</div>
        <div class="desc">{{text.summary.popup.subDesc}}</div>
        <div class="table">
            <div class="row">
                <div class="item grey"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.averageStatus; let last=last;"
                >
                    {{item}}
                </div>
            </div>
            <div class="row">
                <div class="item"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.averageDist;  let last=last;"
                >
                    {{item}}
                </div>
            </div>
        </div>
    </div>
</div>
