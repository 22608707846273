import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { IconsModule } from '@libs/shared-ui/components/icons/icons.module';
import { AnalyticChartTitleComponent } from './analytic-chart-title.component';

@NgModule({
    imports: [CommonModule, IconsModule, TooltipsModule],
    declarations: [AnalyticChartTitleComponent],
    exports: [AnalyticChartTitleComponent],
})
export class AnalyticChartTitleModule {}
