<cityair-map-control *ngIf="store.select(selectActiveRun)| async as run"
    [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
    [title]="textTitleMapControl"
>
    <div map-control-period>
        <ca-impact-run-selector
            [runs]="store.select(selectRunsImpact) | async"
            [activeRun]="run"
            (setRun)="changeRun($event)"
        ></ca-impact-run-selector>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="impact-mmt"
            [showLayerButton]="false"
            [title]="textOnMap"
            [mmts]="store.select(selectSpeciesList) | async"
            [activeMmt]="store.select(selectActiveMmt) | async"
            (selectMmt)="changeMmt($event)"
        ></shared-ui-mmt-selector>
    </div>
    <div switch-selector-map-object>
        <ca-impact-map-object-selector class="map-object-selector"
            [list]="store.select(selectDisplayObjOnMap) | async"
           (changesList)="mapObjectChanges($event)"
        >
        </ca-impact-map-object-selector>
    </div>
</cityair-map-control>
