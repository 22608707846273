<input
     type="file"
     class="file-input"
     (change)="onFileSelected($event)"
     #fileUpload
>
<div class="file-upload-wrapper" [ngClass]="{'finish-status': status === 'done' }">
   <ng-container *ngIf="fileName else chooseFileTemplate">
      <div class="upload-result-wrapper">
         <div class="upload-result">
            <div class="icon-file"></div>
            <div class="file-info-wrapper">
               <div class="filename">{{fileName}}<span class="extension">.{{fileExt}}</span></div>
               <div class="info">
                  <div class="size">
                     {{ fileSize | filesize : LANG }}
                  </div>
               </div>
            </div>
            <cross-button *ngIf="fileName" class="close-button" size="medium" (click)="cancelUpload()"></cross-button>
         </div>
         <!--<div class="upload-progress">
            <div class="progress upload-progress" >
               <div class="progress-bar"
                    role="progressbar"
                  [style.width.%]="uploadProgress"
               ></div>
            </div>
         </div>-->
      </div>
   </ng-container>
   <ng-template #chooseFileTemplate>
      <div class="choose-file" (click)="fileUpload.click()" appDragDropFileUpload (fileDropped)="dragDropLoad($event)">
         <div class="icon-download"></div>
         <div class="text-download">
            {{fileUploadText.uploadText1}}
            <span class="link">
               {{fileUploadText.uploadText2}}
            </span>
            {{fileUploadText.uploadText3}}
         </div>
      </div>
   </ng-template>
</div>
<div class="error" *ngIf="error">
   {{error}}
</div>
<div class="hint" *ngIf="params?.values.length as format">
   <span *ngIf="format === 1">{{fileUploadText.availableFormat}}</span>
   <span *ngIf="format > 1">{{fileUploadText.availableFormats}}</span>
   {{params?.values.join(', ')}}.
</div>
