import { SP, T, R2, TEMP, HUM, PRES, WDA_V, WVA_V, WD, WV } from './substance.consts';
import { ColorZone } from '@libs/common/types/color-zone';
export const DEMO_FORECAST_METEO_GROUPS: string[] = ['4'];
export const METEO_MMTS_FORECAST = [T, R2, SP, WD, WV];
export const METEO_FORECAST_TO_BASIC = {
    [T]: TEMP,
    [R2]: HUM,
    [SP]: PRES,
    [WD]: WDA_V,
    [WV]: WVA_V,
};
export const COLOR_ZONE_BY_METEO_FORECAST: { [key: string]: ColorZone } = {
    [T]: {
        color: [
            '#3d22cd',
            '#455BF7',
            '#63a2dc',
            '#78ace0',
            '#98C2F7',
            '#ffe11a',
            '#f2b705',
            '#e33e50',
        ],
        zone: [-30, -20, -10, 0, 10, 20, 30],
    },
    [R2]: {
        color: [
            '#E24230',
            '#FD4E3A',
            '#FF8441',
            '#FFAD33',
            '#6ECF50',
            '#6ECF50',
            '#77D6E0',
            '#7ABCFA',
            '#7A91FA',
            '#6264FA',
            '#6264FA',
        ],
        zone: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    },
    [SP]: {
        color: [
            '#870D56',
            '#D21512',
            '#F02F1D',
            '#FDA347',
            '#96D644',
            '#96D644',
            '#FDA347',
            '#F02F1D',
            '#D21512',
            '#870D56',
        ],
        zone: [740, 745, 750, 755, 760, 765, 770, 775, 780],
    },
    [WV]: {
        color: [
            '#95A0B3',
            '#5B5DEA',
            '#4B9EEB',
            '#3BCC9C',
            '#5AB440',
            '#A2C618',
            '#FFAD33',
            '#F02F1D',
            '#D21512',
            '#870D56',
        ],
        zone: [4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
    },
};
