import { Injectable } from '@angular/core';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import { Action, Store } from '@ngrx/store';
import { AppState, selectCurrentMo } from '@cityair/modules/core/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { iif, Observable, of, tap } from 'rxjs';
import {
    addPost,
    addPostError,
    addPostSuccess,
    clearAllPosts,
    closePostForm,
    deletePost,
    deletePostSuccess,
    downloadDataDevice,
    downloadDataPosts,
    downloadDataResult,
    loadingPosts,
    loadingPostsForm,
    setAllPosts,
    updatePost,
    updatePostError,
    updatePostList,
    updatePostSuccess,
} from '@cityair/modules/core/store/posts/posts.feature';
import { removeFromComparison, addAlert } from '@cityair/modules/core/store/actions';
import { HttpErrorResponse } from '@angular/common/http';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { saveDataToDisk, savePostDataToDisk } from '@cityair/utils/utils';
import { selectComparedItems } from '@cityair/modules/core/store/compared-list/compared-list.selectors';

@Injectable()
export class PostsEffects {
    constructor(
        private actions$: Actions,
        private basicApi: BasicApi,
        private store: Store<AppState>
    ) {}

    addPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addPost),
            tap(() => this.store.dispatch(loadingPostsForm({ payload: true }))),
            switchMap((action) =>
                this.basicApi.addPost(action.payload.params).pipe(
                    switchMap((response) => [
                        addPostSuccess({ payload: response?.data }),
                        loadingPostsForm({ payload: false }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Create_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 5000,
                            showCloseIcon: false,
                            size: 'lg',
                        }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return iif(
                            () => errorResponse?.status === 400,
                            of(
                                loadingPostsForm({ payload: false }),
                                addPostError({ payload: errorResponse })
                            ),
                            of(loadingPostsForm({ payload: false }), errorAction)
                        );
                    })
                )
            )
        )
    );

    updatePosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePost),
            tap(() => this.store.dispatch(loadingPostsForm({ payload: true }))),
            switchMap((action) =>
                this.basicApi.updatePost(action.payload.id, action.payload.params).pipe(
                    switchMap((response) => [
                        updatePostSuccess({ payload: response?.data }),
                        loadingPostsForm({ payload: false }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Update_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 5000,
                            showCloseIcon: false,
                            size: 'lg',
                        }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return iif(
                            () => errorResponse?.status === 400,
                            of(
                                loadingPostsForm({ payload: false }),
                                updatePostError({ payload: errorResponse })
                            ),
                            of(loadingPostsForm({ payload: false }), errorAction)
                        );
                    })
                )
            )
        )
    );

    deletePosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deletePost),
            withLatestFrom(this.store.select(selectCurrentMo)),
            filter(([_, currentPost]) => !!currentPost),
            switchMap(([action, currentPost]) =>
                this.basicApi.deletePost(currentPost.id).pipe(
                    switchMap((response) => [
                        deletePostSuccess({ payload: currentPost }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Delete_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 5000,
                            showCloseIcon: false,
                            size: 'lg',
                        }),
                    ]),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === 403) {
                            return of(
                                addAlert({
                                    id: new Date().valueOf(),
                                    messageKey: 'Delete_Post_Error',
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'error',
                                    duration: 5000,
                                    showCloseIcon: false,
                                    size: 'lg',
                                })
                            );
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        }
                    })
                )
            )
        )
    );

    deletePostSucces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deletePostSuccess),
            withLatestFrom(this.store.select(selectComparedItems)),
            switchMap(([action, compareItems]) => {
                const currentPost = compareItems.find((v) => v.id === action.payload.id);
                if (currentPost) {
                    return [removeFromComparison({ id: currentPost.id })];
                }
                return [];
            })
        )
    );

    updatePostList$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updatePostList),
                withLatestFrom(this.store.select(selectGroupId)),
                filter(([_, groupId]) => !!groupId),
                tap(() => this.store.dispatch(loadingPosts({ payload: true }))),
                tap(() => this.store.dispatch(clearAllPosts())),
                switchMap(([action, groupId]) =>
                    this.basicApi.getAllPost(groupId).pipe(
                        map((response) => setAllPosts({ payload: response?.data })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    closeFormPost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addPostSuccess, updatePostSuccess),
            map(() => closePostForm({ payload: true }))
        )
    );

    downloadDataPosts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadDataPosts),
                tap(() => this.store.dispatch(downloadDataResult({ payload: null }))),
                switchMap((action) =>
                    iif(
                        () => true, // check is TZ-A4 revert new api
                        this.basicApi.saveMoDataToExcel(action.params).pipe(
                            map((response) => {
                                saveDataToDisk(response?.Result, action.filename);
                                return downloadDataResult({ payload: true });
                            }),
                            catchError((errorResponse: HttpErrorResponse) => {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction, downloadDataResult({ payload: false }));
                            })
                        ),
                        this.basicApi.saveMoData(action.params).pipe(
                            map((response) => {
                                const data = new window.Blob([response]);
                                savePostDataToDisk(data, action.filename);
                                return downloadDataResult({ payload: true });
                            }),
                            catchError((errorResponse: HttpErrorResponse) => {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction, downloadDataResult({ payload: false }));
                            })
                        )
                    )
                )
            ) as Observable<Action>
    );

    downloadDataDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadDataDevice),
                tap(() => this.store.dispatch(downloadDataResult({ payload: null }))),
                switchMap((action) =>
                    this.basicApi.saveDeviceDataToExcel(action.params).pipe(
                        map((data) => {
                            saveDataToDisk(data?.Result, action.filename);
                            return downloadDataResult({ payload: true });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, downloadDataResult({ payload: false }));
                        })
                    )
                )
            ) as Observable<Action>
    );
}
