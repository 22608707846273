import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { formatDayMonthYear } from '@libs/common/utils/date-formats';

@Component({
    selector: 'indoor-calendar',
    templateUrl: './indoor-calendar.component.html',
    styleUrls: ['./indoor-calendar.component.less'],
})
export class IndoorCalendarComponent implements OnChanges {
    @Input() timeBegin: Date;
    @Input() timeEnd: Date;
    @Output() timeRangeChange = new EventEmitter<{ begin: number; end: number }>();

    show = false;
    tzOffset = moment().utcOffset();

    begin: number;
    end: number;

    ngOnChanges() {
        this.begin = this.timeBegin.getTime();
        this.end = this.timeEnd.getTime() - 1; // чтоб не отображалось начало следующего дня
    }

    updateTimeRange = (begin: number, end: number) => {
        this.timeRangeChange.emit({ begin: begin, end: end });
        this.show = false;
    };

    getIntervalText() {
        const begin = moment(this.begin);
        const end = moment(this.end);

        const textStart = formatDayMonthYear(begin);
        const textEnd = formatDayMonthYear(end);

        return `${textStart} - ${textEnd}`;
    }
}
