import { Component, Input } from '@angular/core';
import { HeaderPage } from '@libs/common/models/header-page';

@Component({
    selector: 'header-page',
    templateUrl: 'header-page.component.html',
    styleUrls: ['header-page.component.less'],
})
export class HeaderPageComponent {
    @Input() textObject: HeaderPage;
    @Input() cbBack?: () => void;
    @Input() cbOpenPage?: () => void;
}
