import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StoreModule } from '@ngrx/store';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { PopupsModule } from '@cityair/components/popups/popups.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { CardModule } from '@cityair/components/card/card.module';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';

import { GradientSliderModule } from '@libs/shared-ui/components/gradient-slider/gradient-slider.module';
import { Notifications } from './components/notifications/notifications.component';
import { Subscriptions } from './components/notifications/subscriptions/subscriptions.component';
import { SubscriptionsMeasurements } from './components/notifications/subscriptions/subscriptions-measurements/subscriptions-measurements.component';
import { SubscriptionsService } from './components/notifications/subscriptions/subscriptions-service/subscriptions-service.component';
import { SubscriptionCard } from './components/notifications/subscriptions/subscription-card/subscription-card.component';
import { SubscriptionCardMeasurements } from './components/notifications/subscriptions/subscription-card-measurements/subscription-card-measurements.component';
import { SubscriptionCardService } from './components/notifications/subscriptions/subscription-card-service/subscription-card-service.component';
import { Settings } from './components/notifications/settings/settings.component';
import { SettingsMeasurements } from './components/notifications/settings/settings-measurements/settings-measurements.component';
import { SettingsService } from './components/notifications/settings/settings-service/settings-service.component';
import { notificationsReducer } from './store/reducers';
import { MultipleSearchFnsPipe } from '@libs/shared-ui/components/pipes/pipes';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { EffectsModule } from '@ngrx/effects';
import { BaseChartDirective } from 'ng2-charts';
import { TextareaModule } from '@cityair/components/textarea/textarea.module';
import { NotificationsEffects } from '@cityair/modules/notifications/store/effects';
import { EventsComponent } from '@cityair/modules/notifications/components/notifications/events/events.component';
import { EventItemComponent } from '@cityair/modules/notifications/components/notifications/events/event-item/event-item.component';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';

@NgModule({
    imports: [
        StoreModule.forFeature('notifications', notificationsReducer),
        EffectsModule.forFeature([NotificationsEffects]),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        ScrollingModule,
        LittleComponentsModule,
        PopupsModule,
        TooltipsModule,
        CardModule,
        CalendarModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        PageTabsModule,
        CardActionsModule,
        SelectboxModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
        GradientSliderModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        InputDropdownModule,
        InputModule,
        CrossButtonModule,
        BaseChartDirective,
        TextareaModule,
        SearchResultPlaceholderModule,
    ],
    exports: [Notifications, Subscriptions, GradientSliderModule],
    providers: [MultipleSearchFnsPipe],
    declarations: [
        Notifications,
        Subscriptions,
        SubscriptionsMeasurements,
        SubscriptionsService,
        SubscriptionCard,
        SubscriptionCardMeasurements,
        SubscriptionCardService,
        Settings,
        SettingsMeasurements,
        SettingsService,
        EventsComponent,
        EventItemComponent,
    ],
})
export class NotificationsModule {}
