import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { Observable, of } from 'rxjs';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import {
    closeSettingForm,
    createNotificationSettings,
    createNotificationSettingsSuccess,
    deleteNotificationSettings,
    deleteNotificationSettingsError,
    deleteNotificationSettingsSuccess,
    downloadHistory,
    editNotificationSettings,
    editNotificationSettingsActive,
    editNotificationSettingsActiveSuccess,
    editNotificationSettingsSuccess,
    formNotificationSettingsError,
    getNotificationsEventsDevice,
    getNotificationsEventsPdk,
    getNotificationsSettings,
    goToEvent,
    isLoadingEventsList,
    isLoadingForm,
    loadMoreEvents,
    markNotificationAsVisited,
    setNotificationEvents,
    setNotificationsEventsDevice,
    setNotificationsEventsPdk,
    setNotificationsSettings,
    updateNotifiablePosts,
    updateNotification,
} from '@cityair/modules/notifications/store/actions';
import {
    currentEvent,
    currentEventDataBeginTime,
    currentEventDataRange,
    selectLoadMoreParams,
    selectParamsLoadDeviceEvent,
    selectParamsLoadPdkEvent,
} from '@cityair/modules/notifications/store/selectors';
import {
    clickFromApToMo,
    doNothing,
    intervalUpdate,
    setTimelineDateTimes,
    addAlert,
    updateTimeIndex,
} from '@cityair/modules/core/store/actions';
import { selectTypeInterval } from '@cityair/modules/core/store/selectors';
import { selectComparedItems } from '@cityair/modules/core/store/compared-list/compared-list.selectors';
import * as moment from 'moment-timezone';
import { IntervalEnum } from '@cityair/namespace';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { saveDataToDisk } from '@cityair/utils/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class NotificationsEffects {
    constructor(private actions$: Actions, private store: Store, private basicApi: BasicApi) {}

    getNotificationsSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getNotificationsSettings),
                withLatestFrom(this.store.select(selectGroupId)),
                switchMap(([action, groupId]) =>
                    this.basicApi.getAllNotificationsSettings(groupId).pipe(
                        map((response) => setNotificationsSettings({ payload: response?.data })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    getNotificationsEventsPdk$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getNotificationsEventsPdk),
                withLatestFrom(this.store.select(selectParamsLoadPdkEvent)),
                filter(([ac, params]) => params !== null),
                switchMap(([action, params]) =>
                    this.basicApi.getAllNotificationsEvents(params).pipe(
                        map((response) => setNotificationsEventsPdk({ payload: response?.data })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    getNotificationsEventsDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getNotificationsEventsDevice),
                withLatestFrom(this.store.select(selectParamsLoadDeviceEvent)),
                filter(([ac, params]) => params !== null),
                switchMap(([action, params]) =>
                    this.basicApi.getAllNotificationsEvents(params).pipe(
                        map((response) =>
                            setNotificationsEventsDevice({ payload: response?.data })
                        ),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    markNotificationAsVisited$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(markNotificationAsVisited),
                switchMap((action) =>
                    this.basicApi.markAsViewNotification(action.payload.id).pipe(
                        map((response) => updateNotification({ payload: action.payload })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    loadMoreEvents$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadMoreEvents),
                withLatestFrom(this.store.select(selectLoadMoreParams)),
                filter(([ac, params]) => params !== null),
                tap(() => this.store.dispatch(isLoadingEventsList({ payload: true }))),
                switchMap(([action, params]) =>
                    this.basicApi.getAllNotificationsEvents(params).pipe(
                        map((response) =>
                            setNotificationEvents({
                                payload: { response: response?.data, params: params },
                            })
                        ),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );

    closeSettingForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(closeSettingForm),
            map((action) => updateNotifiablePosts({ postIds: [] }))
        )
    );

    goToEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(goToEvent),
            withLatestFrom(
                this.store.select(currentEventDataRange),
                this.store.select(selectComparedItems)
            ),
            filter(([action, currentEvent]) => !!action.payload && !!currentEvent),
            switchMap(([action, dateRange, compareItems]) => {
                const actions = [];
                actions.push(intervalUpdate(dateRange));
                const postId =
                    action.payload?.post_id ?? action.payload.service_excess_info?.post_id;
                const isExistInCompare = compareItems.find((v) => v.id === postId);
                if (isExistInCompare === undefined && compareItems?.length === 0) {
                    actions.push(
                        clickFromApToMo({
                            moObjId: postId,
                        })
                    );
                }

                return actions;
            })
        )
    );

    updateIndexForEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setTimelineDateTimes),
            withLatestFrom(
                this.store.select(currentEventDataBeginTime),
                this.store.select(selectTypeInterval)
            ),
            filter(([action, currentEvent]) => currentEvent !== null),
            switchMap(([action, eventTime, typeInterval]) => {
                const dates = action.dateTimes;
                const index = getIndexByType(dates, eventTime, typeInterval);
                const actions = [];
                if (index >= 0) {
                    actions.push(updateTimeIndex({ payload: index }));
                }
                actions.push(goToEvent({ payload: null }));
                return actions;
            })
        )
    );

    clearCurrentEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            withLatestFrom(this.store.select(currentEvent)),
            filter(([action, currentEvent]) => !action.payload && currentEvent !== null),
            map(([action, eventTime]) => goToEvent({ payload: null }))
        )
    );

    createNotificationSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createNotificationSettings),
                tap(() => this.store.dispatch(isLoadingForm({ payload: true }))),
                switchMap((action) =>
                    this.basicApi.addNotificationsSettings(action.payload).pipe(
                        switchMap((response) => [
                            createNotificationSettingsSuccess({
                                payload: response?.data,
                            }),
                            closeSettingForm({ settings_type: action.payload.type }),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                formNotificationSettingsError({ payload: errorResponse }),
                                isLoadingForm({ payload: false })
                            );
                        })
                    )
                )
            ) as Observable<Action>
    );
    createNotificationSettingsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createNotificationSettingsSuccess),
            switchMap((action) => [
                addAlert({
                    id: new Date().valueOf(),
                    messageKey: 'Create_Success',
                    positionX: 'left',
                    positionY: 'bottom',
                    iconClass: 'success',
                    duration: 5000,
                    showCloseIcon: false,
                    size: 'lg',
                }),
            ])
        )
    );

    editNotificationSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editNotificationSettings),
                tap(() => this.store.dispatch(isLoadingForm({ payload: true }))),
                switchMap((action) =>
                    this.basicApi.updateNotificationsSettings(action.payload).pipe(
                        switchMap((response) => [
                            editNotificationSettingsSuccess({
                                payload: response?.data,
                            }),
                            closeSettingForm({ settings_type: action.payload.type }),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                formNotificationSettingsError({ payload: errorResponse }),
                                isLoadingForm({ payload: false })
                            );
                        })
                    )
                )
            ) as Observable<Action>
    );
    editNotificationSettingsActive$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editNotificationSettingsActive),
                switchMap((action) =>
                    this.basicApi.updateNotificationsSettings(action.payload).pipe(
                        switchMap((response) => [
                            editNotificationSettingsActiveSuccess({
                                payload: response?.data,
                            }),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );
    deleteNotificationSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteNotificationSettings),
                switchMap((action) =>
                    this.basicApi.deleteNotificationsSettings(action.payload).pipe(
                        switchMap((response) => [
                            deleteNotificationSettingsSuccess({ payload: action.payload }),
                        ]),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(
                                errorAction,
                                deleteNotificationSettingsError({ payload: action.payload }),
                                isLoadingForm({ payload: false })
                            );
                        })
                    )
                )
            ) as Observable<Action>
    );

    downloadHistory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadHistory),
                switchMap((action) =>
                    this.basicApi.exportNotificationsBySettings(action.payload).pipe(
                        map((data) => {
                            saveDataToDisk(data?.Result, 'feed-data.xlsx');
                            return doNothing();
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    )
                )
            ) as Observable<Action>
    );
}

function getIndexByType(dates, time, typeInterval: IntervalEnum): number {
    let interval = 5 * 60 * 1000;

    switch (typeInterval) {
        case IntervalEnum.min20:
            interval = 4 * interval;
            break;
        case IntervalEnum.hour:
            interval = 12 * interval;
            break;
        case IntervalEnum.day:
            interval = 24 * 12 * interval;
            break;
    }

    const roundTime = moment(Math.floor(moment(time).valueOf() / interval) * interval)
        .toISOString()
        .replace('.000Z', 'Z');
    return dates.indexOf(roundTime);
}
