<div class="wrapper-filter">
    <div class="gradient-bg" (click)="isShowDropdown = true" (clickOutside)="isShowDropdown = false">
        <div class="label">{{label}}:</div>&nbsp;
        <div class="selected" [innerHTML]="selectedValue?.label"></div>
        <ca-arrow [size]="'small'" class="arrow"></ca-arrow>
    </div>
    <div class="selectbox-radio" *ngIf="isShowDropdown">
        <div class="selectbox-radio__list scroll">
            <div
                class="selectbox-radio__list-item"
                *ngFor="let item of list"
            >
                <label class="selectbox-radio__list-item__label" (click)="selectValue(item)">
                    <div
                        class="selectbox-radio__list-item__text"
                        [ngClass]="{'active': item.selected}"
                        [innerHTML]="item.label"
                    ></div>
                    <item-radio
                        class="selectbox-radio__list-item__checkmark"
                        [check]="item.selected"
                    ></item-radio>
                </label>
            </div>
        </div>
    </div>
</div>
