import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { IReportKind } from '../models';

@Injectable({ providedIn: 'root' })
export class ReportKindService extends EntityCollectionServiceBase<IReportKind> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ReportKind', serviceElementsFactory);
    }
}
