import { createAction, props } from '@ngrx/store';
import { GetCSTimelineInfoRequest, InfoMessage, IMapClick, IntervalEnum } from '@cityair/namespace';

import { StoreSettingsPart } from '@cityair/modules/core/store/reducers';
import { DataMarker, DataQuality, DataQualityInfo } from '@libs/common/models/dataQuality';
import {
    Locality,
    BasicDataResponse,
    Group,
    BasicRolesResponse,
    IBasicResponse,
    UserSettings,
} from '@libs/common/models/basicModels';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';

export enum CommonActions {
    Noop = '[CommonAction] Do nothing',
    GroupInfoLoad = '[CommonActions] GroupInfoLoad',
    GroupInfoLoaded = '[CommonActions] GroupInfoLoaded',
    getInfoGroup = '[CommonActions] getInfoGroup',
    setUserSettings = '[CommonActions] setUserSettings',
    updateUserSettings = '[CommonActions] updateUserSettings',
    SaveSettings = '[CommonActions] SaveSettings',
    TimelineInfoLoad = '[CommonActions] TimelineInfoLoad',
    SelectDevice = '[CommonActions] SelectDevice',
    SelectMo = '[CommonActions] SelectMo',
    ToggleSidebar = '[CommonActions] toggleSidebar',
    OpenSidebar = '[CommonActions] openSidebar',
    CloseSidebar = '[CommonActions] CloseSidebar',
    MapLoaded = '[CommonActions] MapLoaded',
    SetGlobalMeasurement = '[CommonActions] SetGlobalMeasurement',
    OpenInstantAqiFaq = '[CommonActions] OpenInstantAqiFaq',
    CloseInstantAqiFaq = '[CommonActions] CloseInstantAqiFaq',
    PinsValuesLoaded = '[CommonActions] PinsValuesLoaded',
    CurrentTimeUpdate = '[CommonActions] CurrentTimeUpdate',
    IntervalUpdate = '[CommonActions] IntervalUpdate',
    UpdateCurrentTimeWithNearestData = '[CommonActions] UpdateCurrentTimeWithNearestData',
    SetTimelineDateTimes = '[CommonActions] SetTimelineDateTimes',
    SetCityMode = '[CommonActions] SetCityMode (Legacy)',
    SetWorldMode = '[CommonActions] SetWorldMode (Legacy)',
    CityModeOff = '[CommonActions] CityModeOff (Legacy)',
    SetTzOffset = '[CommonActions] SetTzOffset',
    ResetTzOffset = '[CommonActions] ResetTzOffset',
    ShowSourcesInTimeline = '[CommonActions] ShowSourcesInTimeline',
    UpdatePlumesControlPointChart = '[CommonActions] UpdatePlumesControlPointChart',
    SetErrorMessage = '[CommonAction] SetErrorMessage',
    ClearErrorMessage = '[CommonAction] ClearErrorMessage',
    RefreshVangaToken = '[CommonAction] RefreshVangaToken',
    VangaTokenUpdated = '[CommonAction] VangaTokenUpdated',
    SetComparisonMode = '[CommonAction] SetComparisonMode',
    UpdateTimeRangeData = '[CommonAction] UpdateTimeRangeData',
    SetTypeInterval = '[CommonAction] SetTypeInterval',
    RemoveFromComparison = '[CommonAction] RemoveFromComparison',
    UpdatePostData = '[CommonAction] UpdatePostData',
    VangaTokenUpdateError = '[CommonAction] VangaTokenUpdateError',
    addAlert = '[CommonAction] addAlert',
    removeAlert = '[CommonAction] removeAlert',
    InitMainMap = '[CommonAction] InitMainMap',
    DestroyMainMap = '[CommonAction] DestroyMainMap',
    SetMapClickState = '[CommonAction] SetMapClickState',
    SetDefaultGroup = '[CommonActions] SetDefaultGroup',
    UpdateGroupInfo = '[CommonActions] UpdateGroupInfo',
    LoadStationData = '[CommonActions] LoadStationData',
    UpdateStationData = '[CommonActions] UpdateStationData',
    updateCompareStations = '[CommonActions] UpdateCompareStations',
    loadMonitoringData = '[CommonActions] LoadMonitoringData',
    updateCitiesChart = '[CommonActions] updateCitiesChart',
    openCityCard = '[CommonActions] openCityCard',
    openCity = '[CommonActions] openCity',
    selectCity = '[CommonActions] selectCity',
    loadCity = '[CommonActions] loadCity',
    clickFromApToMo = '[CommonActions] clickFromApToMo',
    mapMarkerClick = '[CommonActions] mapMarkerClick',
    clickOnCityMarker = '[CommonActions] clickOnCityMarker',
    setAvailableModule = '[CommonActions] setAvailableModule',
    changeQualityDataMode = '[CommonActions] toggleQualityDataMode',
    toggleShowQualityDataInfo = '[CommonActions] toggleShowQualityDataInfo',
    setQualityDataMarkers = '[CommonActions] setQualityDataMarkers',
    loadQualityData = '[CommonActions] loadQualityData',
    setQualityData = '[CommonActions] setQualityData',
    setQualityDataTimeline = '[CommonActions] setQualityDataTimeline',
    initCityScreenPage = '[CommonActions] init cityScree page',
    setGroupInfo = '[CommonActions] set group info',
    setGroupList = '[CommonActions] set group list',
    timelineLoaded = '[CommonActions] timeline loaded',
    isLoadingTimeline = '[CommonActions] set is loading timeline',
    updateTimeIndex = '[CommonActions] update time index',
    setChartData = '[CommonActions] set chart Data',
    updateChartData = '[CommonActions] update chart data',
    setUserInfo = '[CommonActions] set user info',
    logOut = '[CommonActions] log out',
    togglePublicForecast = '[CommonActions] toggle public forecast',
    setMapStyleType = '[CommonActions] set map style type',
}

export const doNothing = createAction(CommonActions.Noop);

export const initCityScreenPage = createAction(CommonActions.initCityScreenPage);
export const setErrorMessage = createAction(
    CommonActions.SetErrorMessage,
    props<{ msg: string }>()
);

export const clearErrorMessage = createAction(CommonActions.ClearErrorMessage);

export const initMainMap = createAction(CommonActions.InitMainMap);

export const destroyMainMap = createAction(CommonActions.DestroyMainMap);

export const setDefaultGroup = createAction(CommonActions.SetDefaultGroup);
export const updateGroupInfo = createAction(CommonActions.UpdateGroupInfo);
export const groupInfoLoad = createAction(
    CommonActions.GroupInfoLoad,
    props<{ groupId: string }>()
);
export const getInfoGroup = createAction(CommonActions.getInfoGroup, props<{ groupId: number }>());
export const setUserSettings = createAction(
    CommonActions.setUserSettings,
    props<{ payload: MeasureScheme }>()
);
export const updateUserSettings = createAction(
    CommonActions.updateUserSettings,
    props<{ payload: UserSettings }>()
);
export const saveSettings = createAction(CommonActions.SaveSettings, props<StoreSettingsPart>());

export const timelineInfoLoad = createAction(
    CommonActions.TimelineInfoLoad,
    props<GetCSTimelineInfoRequest>()
);

export const selectDevice = createAction(
    CommonActions.SelectDevice,
    props<{ serialNumber: string }>()
);

export const selectMo = createAction(CommonActions.SelectMo, props<{ id: string }>());

export const openSidebar = createAction(CommonActions.OpenSidebar);
export const closeSidebar = createAction(CommonActions.CloseSidebar);
export const toggleSidebar = createAction(CommonActions.ToggleSidebar);

export const mapLoaded = createAction(CommonActions.MapLoaded);

export const setGlobalMeasurement = createAction(
    CommonActions.SetGlobalMeasurement,
    props<{ mmt: string }>()
);

export const openInstantAqiFaq = createAction(CommonActions.OpenInstantAqiFaq);
export const closeInstantAqiFaq = createAction(CommonActions.CloseInstantAqiFaq);

export const setTimelineDateTimes = createAction(
    CommonActions.SetTimelineDateTimes,
    props<{ dateTimes: string[] }>()
);

export const setCityMode = createAction(CommonActions.SetCityMode);

export const setWorldMode = createAction(CommonActions.SetWorldMode);
export const cityModeOff = createAction(CommonActions.CityModeOff);

export const setTzMinutesOffset = createAction(CommonActions.SetTzOffset, props<{ tz: number }>());
export const resetTzMinutesOffset = createAction(CommonActions.ResetTzOffset);

export const showSourcesInTimeline = createAction(
    CommonActions.ShowSourcesInTimeline,
    props<{ ids: string[] }>()
);

export const setComparisonMode = createAction(
    CommonActions.SetComparisonMode,
    props<{ payload: boolean }>()
);

// ----------------------- TIME ---------------------------

export const currentTimeUpdate = createAction(
    CommonActions.CurrentTimeUpdate,
    props<{ time: number }>()
);

export const intervalUpdate = createAction(
    CommonActions.IntervalUpdate,
    props<{ begin: number; end: number }>()
);

export const updateCurrentTimeWithNearestData = createAction(
    CommonActions.UpdateCurrentTimeWithNearestData
);

export const updateTimeRangeData = createAction(CommonActions.UpdateTimeRangeData);

// Plumes

export const updatePlumesControlPointChart = createAction(
    CommonActions.UpdatePlumesControlPointChart
);

export const refreshVangaToken = createAction(CommonActions.RefreshVangaToken);

export const vangaTokenUpdated = createAction(CommonActions.VangaTokenUpdated);

export const setTypeInterval = createAction(
    CommonActions.SetTypeInterval,
    props<{ payload: IntervalEnum }>()
);
export const vangaTokenUpdateError = createAction(CommonActions.VangaTokenUpdateError);

export const removeFromComparison = createAction(
    CommonActions.RemoveFromComparison,
    props<{ id: string }>()
);
export const addAlert = createAction(CommonActions.addAlert, props<InfoMessage>());
export const removeAlert = createAction(
    CommonActions.removeAlert,
    props<{ payload: InfoMessage }>()
);
export const setMapClickState = createAction(CommonActions.SetMapClickState, props<IMapClick>());

export const loadStationData = createAction(CommonActions.LoadStationData, props<{ id: string }>());

export const loadMonitoringData = createAction(
    CommonActions.loadMonitoringData,
    props<{ markerId: string }>()
);

export const updateCitiesChart = createAction(CommonActions.updateCitiesChart);

export const selectCity = createAction(
    CommonActions.selectCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const loadCity = createAction(
    CommonActions.loadCity,
    props<{ cityId: string; centringMap: boolean }>()
);

export const clickFromApToMo = createAction(
    CommonActions.clickFromApToMo,
    props<{ moObjId: string }>()
);

export const mapMarkerClick = createAction(
    CommonActions.mapMarkerClick,
    props<{ markerId: string }>()
);

export const clickOnCityMarker = createAction(
    CommonActions.clickOnCityMarker,
    props<{ cityMarker: Locality }>()
);
export const changeQualityDataMode = createAction(
    CommonActions.changeQualityDataMode,
    props<{ payload: number }>()
);
export const toggleShowQualityDataInfo = createAction(
    CommonActions.toggleShowQualityDataInfo,
    props<{ payload: DataQualityInfo }>()
);
export const setQualityDataMarkers = createAction(
    CommonActions.setQualityDataMarkers,
    props<{ payload: DataMarker[] }>()
);
export const loadQualityData = createAction(CommonActions.loadQualityData, props<{ id: string }>());
export const setQualityData = createAction(
    CommonActions.setQualityData,
    props<{ payload: DataQuality[] }>()
);
export const setGroupInfo = createAction(
    CommonActions.setGroupInfo,
    props<{ payload: any[]; currentGroup: Group; myRole: BasicRolesResponse }>()
);
export const timelineLoaded = createAction(
    CommonActions.timelineLoaded,
    props<{ payload: IBasicResponse }>()
);
export const isLoadingTimeline = createAction(
    CommonActions.isLoadingTimeline,
    props<{ payload: boolean }>()
);
export const updateTimeIndex = createAction(
    CommonActions.updateTimeIndex,
    props<{ payload: number }>()
);
export const setChartData = createAction(
    CommonActions.setChartData,
    props<{ payload: IBasicResponse; feature: BasicDataResponse; extConfig?: any }>()
);
export const updateChartData = createAction(
    CommonActions.updateChartData,
    props<{ payload: IBasicResponse[]; features: BasicDataResponse[]; extConfig?: any }>()
);
export const setUserInfo = createAction(
    CommonActions.setUserInfo,
    props<{ payload: IBasicResponse }>()
);
export const logOut = createAction(CommonActions.logOut);
export const togglePublicForecast = createAction(
    CommonActions.togglePublicForecast,
    props<{ payload: boolean }>()
);
export const setMapStyleType = createAction(
    CommonActions.setMapStyleType,
    props<{ payload: MapStyleType }>()
);
