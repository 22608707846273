import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cityscreen-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.less'],
})
export class CompareComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
