<div class="start-module-settings">
    <div class="start-module-settings__left">
        <div class="header">
            <header-page
                [textObject]="{
                    titlePage: textsSettings.settingGroupTitles.startModule,
                }"
            ></header-page>
        </div>
        <div class="start-module-settings__top">
            <div class="start-module-settings__content scroll">
                <div class="info-wrapper">
                    <div class="info">
                        <ca-info-icon class="info-icon" [withGradient]="true"></ca-info-icon>
                        <div class="info-desc">
                            {{textsSettings.startModuleSetting.info}}
                        </div>
                    </div>
                </div>
                <div class="select-modules" (clickOutside)="isShowDropdown = false">
                    <div
                        [ngClass]="{
                            input_for_dropdown__block: true,
                            'input_for_dropdown__block-active': isShowDropdown,
                        }"
                        (click)="isShowDropdown = !isShowDropdown"
                    >
                        <div class="input_for_dropdown__text">
                            <ca-menu-button *ngIf="selectedModuleIcon"
                                [showTooltip]="false"
                                class="icon-module"
                                name="{{selectedModuleIcon}}"
                            ></ca-menu-button>
                            <span *ngIf="selectedModuleName; else noSelect" [innerHTML]="selectedModuleName"></span>
                            <ng-template #noSelect>
                                {{textsNoSelect }}
                            </ng-template>
                        </div>
                        <div class="input_for_dropdown__title">
                            <span style="position: relative" [innerHTML]="textsSettings.startModuleSetting.label"></span>
                        </div>
                        <ca-arrow
                            class="input_for_dropdown__wrapper_arrow"
                            size="small"
                            [direction]="isShowDropdown ? 'up' : 'down'"
                        ></ca-arrow>
                    </div>
                    <selectbox-radio *ngIf="isShowDropdown"
                        [listItems]="modulesList"
                        [isLeftCheckPosition]="false"
                        (listItemsChange)="
                            selectModule($event); isShowDropdown = false
                        "
                    ></selectbox-radio>
                </div>
            </div>

            <div class="start-module-settings__btn">
                <btn-cancel (click)="cancel()">
                    {{ textsCommon.cancel }}
                </btn-cancel>
                <btn-accept
                    [disabled]="initModule === activeModule"
                    [isLoading]="isSaving"
                    (cbClick)="save()"
                >
                    {{ textsCommon.save }}
                </btn-accept>
            </div>
        </div>
    </div>
    <div class="start-module-settings__plug">
         <cityair-settings-plug [title]="textsSettings.title" [image]="'settings.svg'"></cityair-settings-plug>
    </div>
</div>
