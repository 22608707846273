import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';
import { LANGUAGE } from '@libs/common/texts/texts';
import { customNumberFormat, isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'control-point-pin',
    templateUrl: './control-point-pin.component.html',
    styleUrls: ['./control-point-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPointPinComponent implements OnChanges {
    isFalseNumber = isFalseNumber;

    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() draggable = false;
    @Input() name: string;
    @Input() showArrowWD = false;

    displayValue: number | string;
    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            const formatValue = formatNumber(
                value,
                LANGUAGE,
                '1.' + numberAfterDot + '-' + numberAfterDot
            );
            this.displayValue = hasNoData
                ? '–'
                : customNumberFormat(formatValue, numberAfterDot, LANGUAGE);
        }
    }
}
