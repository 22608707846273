import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as commonActions from './actions';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { IMapClick, InfoMessage, IntervalEnum, IntervalType } from '@cityair/namespace';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';
import * as moment from 'moment-timezone';
import { ADMIN_ID, OPERATOR_ID, STND_INTERVAL } from '@cityair/config';
import { calcDays, findMinInterval } from '@cityair/utils/utils';
import { DEFAULT_AQI_TYPE } from '@libs/common/consts/default-aqi-type.const';
import { ZONES } from '@libs/common/consts/zone.const';
import { MeasuresZones } from '@libs/common/types/measures-zones';
import { comparedListReducers } from './compared-list/compared-list.reducers';
import { findNearestTime } from '@cityair/utils/find-nearest-time';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { DataMarker, DataQualityTimeline } from '@libs/common/models/dataQuality';
import {
    BasicDataResponse,
    BasicDeviceSourceResponse,
    BasicRolesResponse,
    DataObjType,
    Device,
    Group,
    IBasicResponse,
    IExtraGroupInfoResponse,
    IExtraTimelineResponse,
    IExtraUserInfo,
    ITimeseriesDataItem,
    Locality,
    Post,
    User,
} from '@libs/common/models/basicModels';
import { Feature } from '@libs/common/models/feature';
import { getAllMeasurements } from '@libs/common/utils/utils';
import { prepareExtConfig } from '@libs/common/models/feature-config';
import { DEFAULT_MEASURE_SCHEME } from '@libs/common/consts/default-measure-scheme.const';
import { AqiType } from '@libs/common/enums/aqi.type';
import { AQI_IN_ANALYTICS_GROUP_IDS } from '@cityair/modules/analytics/constants';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { DEFAULT_MAP_STYLES } from '@libs/common/consts/map.const';

export function sortByNameLocality(a: Locality, b: Locality): number {
    return a.name.localeCompare(b.name);
}
export const cityAdapter: EntityAdapter<Locality> = createEntityAdapter<Locality>({
    sortComparer: sortByNameLocality,
});
export function sortBySerialNumberDevice(a: Device, b: Device): number {
    return a.serial_number.localeCompare(b.serial_number);
}
export const deviceAdapter: EntityAdapter<Device> = createEntityAdapter<Device>({
    sortComparer: sortBySerialNumberDevice,
});
export const timelineAdapter: EntityAdapter<ITimeseriesDataItem> =
    createEntityAdapter<ITimeseriesDataItem>();

export interface MapState {
    loaded: boolean;
}

export type StoreSettings = {
    measuresZones: { [key in MeasureScheme]: MeasuresZones };
    currentMeasureScheme: MeasureScheme;
};

export type StoreSettingsPart = {
    measuresZones?: { [key in MeasureScheme]: MeasuresZones };
};

export interface ICoreState {
    map: MapState;
    time: TimeObject;
    isTimelineLoading: boolean;
    isSidebarOpen: boolean;
    selectedMoId: string;
    selectedDeviceSerialNumber: string;
    allMeasurements: string[];
    currentMeasurement: string;
    isInstantAqiFaqOpen: boolean;
    timelineDateTimes: string[];
    isCityMode: boolean;
    errorMessage: string;
    isVangaTokenLoading: boolean;
    isCompareMode: boolean;
    comparedItems: BasicDataResponse[];
    currentTypeInterval: IntervalEnum;
    measuresZones: { [key in MeasureScheme]: MeasuresZones };
    currentMeasureScheme: MeasureScheme;
    infoMessage: InfoMessage[];
    mapClickState: IMapClick;
    availableModule: MAIN_PAGES[];
    qualityDataMode: number;
    isShowQualityDataInfo: any;
    qualityDataMarkers: DataMarker[];
    qualityDataTimeline: DataQualityTimeline[];
    roles: BasicRolesResponse[];
    iAm: User;
    timeline: EntityState<ITimeseriesDataItem>;
    currentTimeIndex: number;
    deviceSources: BasicDeviceSourceResponse[];
    chartData: Feature[];
    cities: EntityState<Locality>;
    devices: EntityState<Device>;
    isShowPublicForecast: boolean;
    currentMapType: MapStyleType;
    mapStyleTypes: MapStyleType[];
}

export interface TimeObject {
    current: number;
    begin: number;
    end: number;
    tzMinutesOffset: number;
}

export type DataSeriesType = {
    name: string;
    data: {
        x: string;
        y: number;
    }[];
};

const stndBeginTime = getStndTimeBegin();
const stndEndTime = getStndTimeEnd();

export const initialState: ICoreState = {
    isTimelineLoading: true,
    map: {
        loaded: false,
    },
    time: {
        current: findNearestTime(null, stndBeginTime, stndEndTime),
        begin: stndBeginTime,
        end: stndEndTime,
        tzMinutesOffset: getDefaultTzMinutesOffset(moment.tz.guess(true)),
    },
    isSidebarOpen: false,
    selectedMoId: null,
    selectedDeviceSerialNumber: null,
    allMeasurements: [],
    currentMeasurement: DEFAULT_AQI_TYPE,
    isInstantAqiFaqOpen: false,
    timelineDateTimes: [],
    isCityMode: false,
    errorMessage: null,
    isVangaTokenLoading: false,
    isCompareMode: false,
    comparedItems: [],
    currentTypeInterval: STND_INTERVAL,
    measuresZones: ZONES,
    currentMeasureScheme: DEFAULT_MEASURE_SCHEME,
    infoMessage: [],
    mapClickState: null,
    availableModule: [],
    qualityDataMode: null,
    isShowQualityDataInfo: null,
    qualityDataMarkers: [],
    qualityDataTimeline: [],
    roles: [],
    iAm: null,
    timeline: timelineAdapter.getInitialState(),
    currentTimeIndex: null,
    deviceSources: null,
    chartData: [],
    cities: cityAdapter.getInitialState(),
    devices: deviceAdapter.getInitialState(),
    isShowPublicForecast: false,
    currentMapType: MapStyleType.cityair,
    mapStyleTypes: DEFAULT_MAP_STYLES,
};

const _commonReducer = createReducer(
    initialState,
    on(commonActions.setUserSettings, (state: ICoreState, data: { payload: MeasureScheme }) => ({
        ...state,
        currentMeasureScheme: data.payload,
    })),
    on(commonActions.saveSettings, (state: ICoreState, settings: StoreSettingsPart) => ({
        ...state,
        measuresZones: settings.measuresZones || state.measuresZones,
    })),

    on(commonActions.setErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: data.msg,
    })),

    on(commonActions.clearErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: null,
    })),

    on(commonActions.timelineInfoLoad, (state: ICoreState) => ({
        ...state,
        isTimelineLoading: true,
    })),
    on(commonActions.selectMo, (state: ICoreState, data: { id: string }) => ({
        ...state,
        selectedMoId: data.id,
    })),

    on(commonActions.selectDevice, (state: ICoreState, data: { serialNumber: string }) => ({
        ...state,
        selectedDeviceSerialNumber: data.serialNumber,
    })),
    on(commonActions.mapLoaded, (state: ICoreState) => ({
        ...state,
        map: {
            ...state.map,
            loaded: true,
        },
    })),

    on(commonActions.openSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: true,
    })),

    on(commonActions.closeSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: false,
    })),

    on(commonActions.toggleSidebar, (state: ICoreState) => ({
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
    })),

    on(commonActions.setGlobalMeasurement, (state: ICoreState, { mmt }) => ({
        ...state,
        currentMeasurement: mmt,
    })),

    on(commonActions.openInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: true,
    })),

    on(commonActions.closeInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: false,
    })),

    on(commonActions.currentTimeUpdate, (state: ICoreState, props) => {
        if (!props.time) return state;

        return {
            ...state,
            time: {
                ...state.time,
                current: props.time,
            },
        };
    }),

    on(commonActions.intervalUpdate, (state: ICoreState, props) => {
        const minInterval = findMinInterval(calcDays(props.begin, props.end))
            ?.interval as IntervalType;

        let currentTypeInterval =
            state.currentTypeInterval < minInterval ? minInterval : state.currentTypeInterval;
        if (detectMobile() && state.currentTypeInterval > minInterval) {
            currentTypeInterval = IntervalEnum.hour;
        }

        return {
            ...state,
            time: {
                ...state.time,
                ...props,
            },
            currentTypeInterval,
            isNeedUpdateIndex: true,
        };
    }),

    on(commonActions.setTzMinutesOffset, (state: ICoreState, props) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: props.tz,
        },
    })),

    on(commonActions.resetTzMinutesOffset, (state: ICoreState) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: getDefaultTzMinutesOffset(moment.tz.guess(true)),
        },
    })),

    //
    on(commonActions.setTimelineDateTimes, (state: ICoreState, data: { dateTimes: string[] }) => {
        let currentIndex;
        if (state.isShowPublicForecast) {
            currentIndex = 0;
        } else {
            const oldCurrentData = [...state.timelineDateTimes][state.currentTimeIndex]
                ? state.timelineDateTimes[state.currentTimeIndex]
                : '';

            const isLastIndex = state.currentTimeIndex === state.timelineDateTimes.length - 1;
            currentIndex = data.dateTimes.indexOf(oldCurrentData);
            if (currentIndex === -1 || isLastIndex) {
                currentIndex = data.dateTimes.length - 1;
            }
        }
        return {
            ...state,
            timelineDateTimes: data.dateTimes,
            currentTimeIndex: currentIndex,
        };
    }),

    on(commonActions.setCityMode, (state: ICoreState) => ({
        ...state,
        isCityMode: true,
    })),

    on(commonActions.setWorldMode, (state: ICoreState) => ({
        ...state,
        isCityMode: false,
    })),

    on(commonActions.refreshVangaToken, (state: ICoreState) => ({
        ...state,
        isVangaTokenLoading: true,
    })),

    on(commonActions.vangaTokenUpdated, (state: ICoreState) => ({
        ...state,
        isVangaTokenLoading: false,
    })),

    on(commonActions.setComparisonMode, (state: ICoreState, props) => ({
        ...state,
        isCompareMode: props.payload,
    })),
    on(commonActions.setTypeInterval, (state: ICoreState, props) => ({
        ...state,
        currentTypeInterval: props.payload,
    })),
    on(commonActions.addAlert, (state: ICoreState, data) => ({
        ...state,
        infoMessage: state.infoMessage.concat(data),
    })),
    on(commonActions.removeAlert, (state: ICoreState, { payload }) => {
        const current = [...state.infoMessage].filter((item) => item.id !== payload?.id);
        return {
            ...state,
            infoMessage: current,
        };
    }),
    on(commonActions.setMapClickState, (state: ICoreState, data) => ({
        ...state,
        mapClickState: data,
    })),

    on(commonActions.changeQualityDataMode, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMode: payload,
    })),
    on(commonActions.toggleShowQualityDataInfo, (state: ICoreState, { payload }) => ({
        ...state,
        isShowQualityDataInfo: payload,
    })),

    on(commonActions.setQualityDataMarkers, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMarkers: payload,
    })),
    on(commonActions.setQualityData, (state: ICoreState, { payload }) => {
        const result: DataQualityTimeline[] = [];
        const qualityDataMarkers = state?.qualityDataMarkers ?? [];
        payload.forEach((v) => {
            if (v === null) {
                result.push(null);
            } else {
                const percentGroup = state?.qualityDataMode;
                if (v.isShow(percentGroup) && v.markerCodes.length) {
                    const markers = v.markerCodes.map((v) =>
                        qualityDataMarkers.find((marker) => marker.code === v)
                    );
                    result.push(new DataQualityTimeline(markers));
                } else {
                    result.push(null);
                }
            }
        });

        return {
            ...state,
            qualityDataTimeline: result,
        };
    }),
    on(commonActions.isLoadingTimeline, (state: ICoreState, { payload }) => ({
        ...state,
        isTimelineLoading: payload,
    })),
    on(commonActions.setGroupInfo, (state: ICoreState, { payload, currentGroup, myRole }) => {
        const postsResponse = payload[0]?.data as Post[];
        const citiesResponse = payload[1]?.data as Locality[];
        const cities = cityAdapter.setMany(citiesResponse, state.cities);
        const devicesResponse = payload[2]?.data as Device[];
        const devices = deviceAdapter.setMany(devicesResponse, state.devices);
        const allMeasurements = getAllMeasurements(postsResponse, currentGroup);
        const extraData = payload[2]?.meta.extra as IExtraGroupInfoResponse;
        const availableModule = getAvailableModules(
            currentGroup,
            myRole,
            postsResponse,
            citiesResponse,
            devicesResponse
        );
        const defaultMmt = getDefaultMmt(state.currentMeasurement, currentGroup, allMeasurements);
        const deviceSources = extraData.device_sources;
        return {
            ...state,
            allMeasurements: allMeasurements,
            availableModule: availableModule,
            currentMeasurement: defaultMmt,
            cities,
            devices,
            deviceSources: deviceSources ?? null,
        };
    }),
    on(commonActions.setUserInfo, (state: ICoreState, { payload }) => {
        const rolesResponse = payload?.meta?.extra as IExtraUserInfo;
        const roles = rolesResponse?.group_roles
            ? [...rolesResponse.group_roles].sort((a, b) => Number(a.id) - Number(b.id))
            : [];
        return {
            ...state,
            roles,
            iAm: payload?.data as User,
        };
    }),
    on(commonActions.updateTimeIndex, (state: ICoreState, { payload }) => ({
        ...state,
        currentTimeIndex: payload,
    })),
    on(commonActions.timelineLoaded, (state: ICoreState, { payload }) => {
        const response = (payload?.data as ITimeseriesDataItem[]) ?? [];
        const timeline = timelineAdapter.setMany(response, state.timeline);
        return {
            ...state,
            timeline,
        };
    }),

    on(commonActions.setChartData, (state: ICoreState, { payload, feature, extConfig }) => {
        if (!payload || !feature) {
            return {
                ...state,
                chartData: [],
            };
        }
        const currentData = transformToFeature(payload, feature, extConfig);
        const isCompare = state.isCompareMode;
        const result = [...state.chartData];
        if (isCompare) {
            result.push(currentData);
        }
        return {
            ...state,
            chartData: isCompare ? result : [currentData],
        };
    }),
    on(commonActions.updateChartData, (state: ICoreState, { payload, features, extConfig }) => {
        if (payload === null || features === null || !payload.length || !features.length) {
            return {
                ...state,
                chartData: [],
            };
        }
        const result = payload.map((item, index) =>
            transformToFeature(item, features[index], extConfig)
        );
        return {
            ...state,
            chartData: result,
        };
    }),
    on(commonActions.togglePublicForecast, (state: ICoreState, { payload }) => ({
        ...state,
        isShowPublicForecast: payload,
    })),
    on(commonActions.setMapStyleType, (state: ICoreState, { payload }) => ({
        ...state,
        currentMapType: payload,
    })),
    ...comparedListReducers
);

function getDefaultTzMinutesOffset(timezone): number {
    return moment().tz(timezone).utcOffset();
}

export function commonReducers(state, action) {
    return _commonReducer(state, action);
}
function transformToFeature(
    response: IBasicResponse,
    feature: BasicDataResponse,
    extConfig
): Feature {
    const extraResponse = response.meta.extra as IExtraTimelineResponse;
    const timelineData = response.data as ITimeseriesDataItem[];
    const excludeIndexes = extConfig?.excludeIndexes ? extConfig?.excludeIndexes.split(',') : [];
    if (feature && timelineData?.[0] && timelineData[0].id === feature.id) {
        const indexes = { ...timelineData[0].data?.indexes };
        if (excludeIndexes.length) {
            excludeIndexes.forEach((index) => {
                if (timelineData[0].data?.indexes.hasOwnProperty(index)) {
                    delete indexes[index];
                }
            });
        }

        const hasTimeseries =
            !!Object.keys(timelineData[0].data?.measurements).length ||
            !!Object.keys(indexes).length;
        const timeseriesData = {
            date: extraResponse.dates,
        };

        const timeseriesDetails = {};
        if (hasTimeseries) {
            Object.entries(timelineData[0].data?.measurements).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
            });
            Object.entries(indexes).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
                timeseriesDetails[key] = value.details;
            });
        }
        return {
            type: 'Feature',
            geometry: feature.geometry,
            properties: {
                uuid: feature.id,
                city_id: `${feature.ancestor?.id}`,
                name: feature.name,
                name_ru: feature.name,
                timeseries: timeseriesData,
                details: timeseriesDetails,
                obj: feature.obj === DataObjType.city ? 'city' : 'station',
                has_any_timeseries: hasTimeseries,
                ancestors: [
                    {
                        name: feature.ancestor?.name,
                        obj: feature.ancestor?.obj,
                        uuid: `${feature.ancestor?.id}`,
                    },
                ],
            },
        } as Feature;
    }
}

function getAvailableModules(currentGroup, myRole, posts, cities, devices) {
    const pages = Object.values(MAIN_PAGES);
    const result = [];
    const allowPlumes = currentGroup.modules.find((module) => module.id === 2) ? true : false;
    const allowIndoor = currentGroup.modules.find((module) => module.id === 3) ? true : false;
    const allowNetwork = currentGroup.modules.find((module) => module.id === 5) ? true : false;
    const allowEvents = currentGroup.modules.find((module) => module.id === 4) ? true : false;
    const allowImpact = currentGroup.modules.find((module) => module.id === 6) ? true : false;
    const extConfig = prepareExtConfig(currentGroup.ext_config);
    const excludeIndexes = extConfig?.excludeIndexes?.split(',') ?? [];

    pages.forEach((page) => {
        if (
            page === MAIN_PAGES.analytics &&
            currentGroup.id !== '213' &&
            cities.length > 0 &&
            (excludeIndexes.indexOf(AqiType.instant) === -1 ||
                AQI_IN_ANALYTICS_GROUP_IDS.indexOf(currentGroup.id) >= 0)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.plumes && allowPlumes && currentGroup.id !== '213') {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.notifications &&
            !allowIndoor &&
            currentGroup.id !== '213' &&
            myRole?.edit_station &&
            !!(devices.length || posts.length)
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.networks && (devices?.length || posts?.length) > 0) {
            result.push(page);
        }

        if (page === MAIN_PAGES.settings) {
            result.push(page);
        }

        if (
            (page === MAIN_PAGES.indoor ||
                page === MAIN_PAGES.global ||
                page === MAIN_PAGES.indoorWidget) &&
            allowIndoor
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.reports &&
            (myRole?.id === ADMIN_ID.toString() || myRole?.id === OPERATOR_ID.toString())
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.forecast && extConfig[GroupExtConfigName.showForecastModule]) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.analysis &&
            allowNetwork &&
            currentGroup.id !== '213' &&
            (myRole?.id === ADMIN_ID.toString() || myRole?.id === OPERATOR_ID.toString())
        ) {
            result.push(page);
        }
        if (
            page === MAIN_PAGES.analysisEvents &&
            allowEvents &&
            currentGroup.id !== '213' &&
            (myRole?.id === ADMIN_ID.toString() || myRole?.id === OPERATOR_ID.toString())
        ) {
            result.push(page);
        }
        if (page === MAIN_PAGES.network && allowImpact) {
            result.push(page);
        }
        if (page === MAIN_PAGES.impact && allowImpact) {
            result.push(page);
        }
        if (page === MAIN_PAGES.events && allowImpact) {
            result.push(page);
        }
    });

    return result;
}

function getDefaultMmt(current: string, currentGroup: Group, allMeasurements): string {
    const extConfig = prepareExtConfig(currentGroup.ext_config);
    const aqi = extConfig[GroupExtConfigName.defaultAqi];
    if (aqi && allMeasurements.includes(aqi)) {
        return aqi;
    }

    const pinsDataFormatType = extConfig[GroupExtConfigName.pinsDataFormat]
        ? extConfig[GroupExtConfigName.pinsDataFormat].type
        : null;

    if (pinsDataFormatType && allMeasurements.includes(pinsDataFormatType)) {
        return pinsDataFormatType;
    }

    if (allMeasurements.length && allMeasurements.indexOf(current) === -1) {
        return allMeasurements[0];
    }
    return current;
}
