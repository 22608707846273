import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersAdapter, UsersState } from '@cityair/modules/settings/store/users/reducers';
import { selectCurrentUserGroupId, selectIAm } from '@cityair/modules/core/store/selectors';
import { ADMIN_ID } from '@cityair/config';

const usersSelector = createFeatureSelector<UsersState>('users');
export const selectAllUsers = createSelector(usersSelector, (state) =>
    usersAdapter.getSelectors().selectAll(state.users)
);
export const selectUsersList = createSelector(selectAllUsers, selectIAm, (users, iAm) =>
    users.filter(
        (user) => user.id.toString() !== iAm.id && user.group_role?.id !== ADMIN_ID.toString()
    )
);
export const selectUsersListWithMe = createSelector(
    selectAllUsers,
    selectCurrentUserGroupId,
    (users, currentUserId) => {
        const currentUser = users.find((user) => user.id.toString() === currentUserId);
        if (currentUser) {
            const allUser = users.filter(
                (user) => user.id.toString() !== currentUserId && !user.is_admin
            );
            return [currentUser].concat(allUser);
        } else {
            return users;
        }
    }
);
export const selectIsLoadingUsers = createSelector(
    usersSelector,
    (state: UsersState) => state?.isLoadingUsers
);
export const selectFoundUserResult = createSelector(
    usersSelector,
    (state: UsersState) => state?.foundUserResult
);
export const selectCurrentUserPage = createSelector(
    usersSelector,
    (state: UsersState) => state?.currentPage
);
export const selectUserForEdit = createSelector(
    usersSelector,
    (state: UsersState) => state?.currentUserForm
);
export const selectIsLoadingForm = createSelector(
    usersSelector,
    (state: UsersState) => state?.isLoadingForm
);
export const selectFormError = createSelector(
    usersSelector,
    (state: UsersState) => state?.formError
);
export const selectIsCloseUserForm = createSelector(
    usersSelector,
    (state: UsersState) => state?.closeUserForm
);
export const selectUsersForPost = (id) =>
    createSelector(selectAllUsers, (users) => {
        if (users.length) {
            return users
                .filter((user) => user?.group_role?.details?.posts.indexOf(id) >= 0)
                ?.map((user) => user?.login);
        }
        return [];
    });
