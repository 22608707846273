import { 
    ChangeDetectionStrategy, 
    ChangeDetectorRef, 
    Component, 
    Input, 
    OnDestroy, 
    OnInit 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { DateRangeImpact, SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import { 
    ImpactActions, 
    impactFeature, 
    selectActiveMmt, 
    selectActiveSources, 
    selectSourceListDemoImpact 
} from '@cityair/modules/impact/store/impact.feature';
import { SOURCES_TYPE_BY_ID } from '@libs/common/consts/demo-impact-groups';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { TEXTS } from '@libs/common/texts/texts';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { TabModel } from '@libs/common/types/tab-model';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { filter, Subject, takeUntil } from 'rxjs';

enum PAGES {
    regions = 'regions',
    points = 'points'
}

const impactToRegion = [
    {name: "Дзержинский", color: "#FF7344", value: 15, concentration: {pm25: 3.5}},
    {name: "Железнодорожный", color: "#F1C21F", value: 2, concentration: {pm25: 1.4}},
    {name: "Заельцовский", color: "#9966CB", value: 1, concentration: {pm25: 0.2}},
    {name: "Кировский", color: "#448EEC", value: 1, concentration: {pm25: 0.1}},
    {name: "Остальные", color: "#90B044", value: 0, concentration: {pm25: 0}},
];

const impactToControlPoints = [
    {name: "Аэропорт", color: "#FF7344", value: 5, concentration: {pm25: 2.0}},
    {name: "Берёзовка", color: "#F1C21F", value: 2, concentration: {pm25: 1.2}},
    {name: "Горский", color: "#9966CB", value: 2, concentration: {pm25: 0.4}},
    {name: "Раздольное", color: "#448EEC", value: 1, concentration: {pm25: 0.1}},
    {name: "Остальные", color: "#90B044", value: 0, concentration: {pm25: 0}},
]

const commonСontribution = [
    {name: "ТЭЦ-5", color: "#FF7344", value: 12},
    {name: "Приборостроительный завод", color: "#F1C21F", value: 38},
    {name: "Цементный завод", color: "#9966CB", value: 24},
    {name: "Склад ТБО", color: "#448EEC", value: 16},
    {name: "ТЭЦ-4", color: "#90B044", value: 8},
    {name: "Остальные", color: "#FDA7CE", value: 2},
]

@Component({
    selector: 'impact-source',
    templateUrl: 'impact-source.component.html',
    styleUrls: ['impact-source.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourceComponent implements OnInit, OnDestroy {
    source: SourceImpact = null;
    activeSourceId: number;
    sourceType: string;
    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;
    textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    SOURCES_TYPE_BY_ID = SOURCES_TYPE_BY_ID;
    public ngDestroyed$ = new Subject<void>();
    private isInit = true;
    public speciesList = ['PM25', 'NO2', 'СO'];
    dateRange: DateRangeImpact;
    calendarIsOpened = false;
    numberOfFutureDays = 1;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    dateTimeBegin: number;
    dateTimeEnd: number;
    public mmtList: CheckboxItem[] = [];
    public selectedSourceMmt: CheckboxItem;
    isShowDropdownForMmtList: boolean = false;

    PAGES = PAGES;
    tabsDuration: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        }
    ];
    currentTabDuration: TabModel = this.tabsDuration[1];

    tabsConcentration: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        }
    ];
    currentTabConcentration: TabModel = this.tabsConcentration[1];

    tabsContribution: TabModel[] = [
        {
            id: PAGES.points,
            title: 'Контрольные точки',
        },
        {
            id: PAGES.regions,
            title: 'Районы города',
        }
    ];
    currentTabContribution: TabModel = this.tabsContribution[1];

    impactToRegion = impactToRegion;
    impactToControlPoints = impactToControlPoints;
    commonСontribution = commonСontribution;
    dataChart: number[] = [];
    labelChart: string[] = [];
    colorsChart: string[] = [];

    constructor(
        public store: Store,
        public router: Router,
        private route: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.sources }));
        this.route.params.subscribe((params) => {
            if (this.isInit) {
                this.activeSourceId = params?.id;
            }
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(impactFeature.selectDateRangeSource)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dateRange = dates;
                this.dateTimeBegin = moment(this.dateRange.start).valueOf();
                this.dateTimeEnd = moment(this.dateRange.end).valueOf();
            });

        this.commonСontribution.map((v) => {
            this.dataChart = [...this.dataChart, v.value];
            this.labelChart = [...this.labelChart, v.name];
            this.colorsChart = [...this.colorsChart, v.color];
        })
    }

    async ngOnInit() {
        this.store.select(selectSourceListDemoImpact)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((sources) => { 
                if (sources) {            
                    const currentSource = sources?.find((v) => v.id === Number(this.activeSourceId));
                    this.isInit = false;
                    if(currentSource) {
                        this.sourceType = SOURCES_TYPE_BY_ID[currentSource.id];
                        this.source = currentSource;
                        //this.store.dispatch(ImpactActions.setCurrentSource({payload: currentSource}));
                    }
                    this._changeDetectorRef.markForCheck();
                }
            })
        this.store.select(impactFeature.selectSpeciesList)
            .pipe(takeUntil(this.ngDestroyed$))  
            .subscribe((list) => {
                list.map((item, index) => 
                    this.mmtList.push({
                        id: item, 
                        label: this.textNames[item], 
                        selected: index === 0 ? true : false,
                    }
                ));
                this.selectedSourceMmt = this.mmtList[0];
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    backToSources(){
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}/`]);
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactActions.updateDateRangeSource({payload: {start, end}}));
    };

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    changeTabDuration($event) {
        this.currentTabDuration = $event;
    }
    changeTabConcentration($event) {
        this.currentTabConcentration = $event;
    }
    changeTabContribution($event) {
        this.currentTabContribution = $event;
    }

    getRegionChartWidth(hours: number) {
        return `${Math.round(hours / 24 * 100)}%`;
    }

    selectSourceMmt(list: CheckboxItem[]){
        const selectedMmt = this.mmtList.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        this.selectedSourceMmt = selectedMmt;
    }
}
