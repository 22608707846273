import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonsSaveCancelComponent } from './buttons-save-cancel.component';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';

@NgModule({
    imports: [CommonModule, LoadersModule, ButtonModule],
    exports: [ButtonsSaveCancelComponent],
    declarations: [ButtonsSaveCancelComponent],
})
export class ButtonsSaveCancelModule {}
