import * as moment from 'moment-timezone';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { getColorIndex } from '@cityair/config';
import { NO_DATA_COLOR } from '@libs/common/consts/no-data-color.const';
import { PM25, PM10, CO2, TEMP, PRES, HUM } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { TEXTS } from '@libs/common/texts/texts';
import { isFalseNumber } from '@libs/common/utils/utils';

export const orderOfMeasures = [PM25, PM10, CO2, TEMP, HUM];

export enum analyticType {
    post = 'post',
    floor = 'floor',
    config = 'config',
}

export function chartDateFormater() {
    let lastMonth;

    return (date: moment.Moment) => {
        const month = date.get('month');

        const _return = lastMonth === month ? date.format('DD') : date.format('DD MMMM').split(' ');

        lastMonth = month;

        return _return;
    };
}

export function chartHoursFormatter() {
    let showAm = true;
    let showPm = true;

    return (value: number) => {
        const str = moment(`${value}:00`, ['h:mm']).format('hh A');
        const strSplit = str.split(' ');

        if (str.includes('AM') && showAm) {
            showAm = false;
            return strSplit;
        }

        if (str.includes('PM') && showPm) {
            showPm = false;
            return strSplit;
        }

        return strSplit[0];
    };
}

export enum MMT_INDOOR {
    indoorAqi = 'indoorAqi',
    pm2 = 'pm2',
    pm10 = 'pm10',
    co2 = 'co2',
    temperature = 'temperature',
    humidity = 'humidity',
    pressure = 'pressure',
}

export const indoorNamesToGlobal = {
    [MMT_INDOOR.indoorAqi]: AqiType.indoor,
    [MMT_INDOOR.pm2]: PM25,
    [MMT_INDOOR.pm10]: PM10,
    [MMT_INDOOR.co2]: CO2,
    [MMT_INDOOR.temperature]: TEMP,
    [MMT_INDOOR.humidity]: HUM,
    [MMT_INDOOR.pressure]: PRES,
};

export const MMT_TEXT = {
    [MMT_INDOOR.indoorAqi]: TEXTS.NAMES[AqiType.indoor],
    [MMT_INDOOR.pm2]: TEXTS.NAMES.PM25,
    [MMT_INDOOR.pm10]: TEXTS.NAMES.PM10,
    [MMT_INDOOR.co2]: TEXTS.NAMES.CO2,
    [MMT_INDOOR.temperature]: TEXTS.NAMES.TEMPERATURE,
    [MMT_INDOOR.humidity]: TEXTS.NAMES.HUMIDITY,
    [MMT_INDOOR.pressure]: TEXTS.NAMES.PRESSURE,
};

export const mmtGetUnit = (scheme: MeasureScheme) => {
    const textMeasure = TEXTS.MEASURES_SCHEME[scheme];

    return {
        [MMT_INDOOR.indoorAqi]: textMeasure[AqiType.indoor],
        [MMT_INDOOR.pm2]: textMeasure[PM25],
        [MMT_INDOOR.pm10]: textMeasure[PM10],
        [MMT_INDOOR.co2]: textMeasure[CO2],
        [MMT_INDOOR.temperature]: textMeasure[TEMP],
        [MMT_INDOOR.humidity]: textMeasure[HUM],
        [MMT_INDOOR.pressure]: textMeasure[PRES],
    };
};

export type IndoorZone = {
    val: number[];
    colors: string[];
    min?: string;
    isLimit?: boolean;
};

const calcZoneMg = (zone: { [key: string]: IndoorZone }): { [key: string]: IndoorZone } => {
    const newZone: { [key: string]: IndoorZone } = {};

    Object.keys(zone).forEach((key) => {
        newZone[key] = {
            val: zone[key].val.map((value) => value / 1000),
            colors: zone[key].colors,
        };
    });

    return newZone;
};

const _pmZones: { [key: string]: IndoorZone } = {
    [MMT_INDOOR.pm2]: {
        val: [11, 23, 35, 66, 97, 128, 160, 285, 410],
        colors: [
            '#8CB917',
            '#A2C617',
            '#BEC617',
            '#FFCC33',
            '#FFA33B',
            '#FF7344',
            '#FF494B',
            '#D63B50',
            '#AD2D55',
            '#821E5A',
        ],
    },
    [MMT_INDOOR.pm10]: {
        val: [20, 40, 60, 120, 180, 240, 300, 540, 780],
        colors: [
            '#8CB917',
            '#A2C617',
            '#BEC617',
            '#FFCC33',
            '#FFA33B',
            '#FF7344',
            '#FF494B',
            '#D63B50',
            '#AD2D55',
            '#821E5A',
        ],
    },
};

const _indoorZones: { [key: string]: IndoorZone } = {
    [MMT_INDOOR.indoorAqi]: {
        val: [2, 3, 4, 5, 6, 7, 8, 9, 10],
        colors: [
            '#8CB917',
            '#A2C617',
            '#BEC617',
            '#FFCC33',
            '#FFA33B',
            '#FF7344',
            '#FF494B',
            '#D63B50',
            '#AD2D55',
            '#821E5A',
        ],
        min: '1',
        isLimit: true,
    },
    [MMT_INDOOR.co2]: {
        val: [550, 700, 900, 1100, 1300, 1500, 2000, 2500, 3000],
        colors: [
            '#8CB917',
            '#A2C617',
            '#BEC617',
            '#FFCC33',
            '#FFA33B',
            '#FF7344',
            '#FF494B',
            '#D63B50',
            '#AD2D55',
            '#821E5A',
        ],
    },
    [MMT_INDOOR.temperature]: {
        val: [65, 77, 95],
        colors: ['#62C6DC', '#ADD21A', '#FFCC32', '#FF7344'],
        min: '45',
        isLimit: true,
    },
    [MMT_INDOOR.humidity]: {
        val: [20, 40, 60, 80, 100],
        colors: ['#6097F3', '#6097F3', '#8DD9FF', '#A25AFF', '#A25AFF', '#A25AFF'],
        min: '0',
        isLimit: true,
    },
};

export const indoorZones: {
    [key in string]: {
        [key: string]: IndoorZone;
    };
} = {
    [MeasureScheme.default]: {
        ..._indoorZones,
        ..._pmZones,
        [MMT_INDOOR.temperature]: {
            val: [18, 25, 35],
            colors: ['#62C6DC', '#ADD21A', '#FFCC32', '#FF7344'],
            min: '7',
            isLimit: true,
        },
    },
    [MeasureScheme.usa_default]: {
        ..._indoorZones,
        ..._pmZones,
    },
    [MeasureScheme.c_mmhg_mg]: {
        ..._indoorZones,
        ...calcZoneMg(_pmZones),
        [MMT_INDOOR.temperature]: {
            val: [18, 25, 35],
            colors: ['#62C6DC', '#ADD21A', '#FFCC32', '#FF7344'],
            min: '7',
            isLimit: true,
        },
    },
};

export function getIndoorColor(colorZone: IndoorZone, value: number) {
    if (isFalseNumber(value) || !colorZone) return NO_DATA_COLOR;

    const index = getColorIndex(colorZone.val, value);

    return colorZone.colors?.[index] ?? NO_DATA_COLOR;
}

export const getTimeBegin = () => moment().subtract(1, 'days').toISOString();
export const getTimeEnd = () => moment().toISOString();
