import { Injectable } from '@angular/core';
import { EntityAction, EntityActionFactory, EntityOp, QueryParams } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    CommonActions,
    doNothing,
    mapLoaded,
    refreshVangaToken,
    addAlert,
    vangaTokenUpdateError,
} from '@cityair/modules/core/store/actions';
import { AppState } from '@cityair/modules/core/store/selectors';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { allowModule, selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { Station } from '../services/station/models';
import {
    CONTROL_POINT_ENTITY_STORE_KEY,
    NEW_CONTROL_POINT_OBJ_TYPE,
    RUN_CONFIG_ENTITY_STORE_KEY,
    RUN_ENTITY_STORE_KEY,
    STATION_ENTITY_STORE_KEY,
} from '../constants';
import {
    authError,
    PlumesActions,
    setActivePlumesControlPoint,
    setActiveRun,
    setActiveStation,
    setChartData,
    setChartLoading,
    setControlPointData,
    setControlPointError,
    setControlPointLoading,
    isDisabledWindButton,
    setRunConfigUpdateError,
    setRunLoading,
    setRunsLoadError,
    toggleLayerOnMap as togglePlumesLayerOnMap,
    toggleModule,
    toggleWindLayer,
    updateAllData,
    updateRunsByInterval,
    datesUpdate,
    clickControlPointsOnMap,
    setSourcesSnapShot,
    setFilterForRuns,
} from './actions';
import { PlumesState } from './reducers';
import {
    allowUpdateRuns,
    getControlPoints,
    getParamsControlPoints,
    getParamsRun,
    getParamsStation,
    getParamsStationById,
    getRunsWithFilter,
    isActivePlumes,
    isWindLayerAvailable,
    isWindShowPlumesOnMap,
    selectControlPointReports,
    selectActiveControlPoint,
    selectActivePointAfterUpdate,
    selectActiveRun,
    selectActiveStation,
    selectDatesPlumes,
    selectEditControlPoint,
    selectIsShowFullStationData,
    selectPlumesCurrentHeight,
    selectPlumesHeights,
    getStations,
} from './selectors';
import { RunPlume } from '../services/run/models';
import { ApiPlumes } from '../services/api/api-plumes';
import { of } from 'rxjs';
import { ControlPoint, ControlPointsResponse } from '../services/control-point/models';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { RunConfig } from '../services/run-config/models';

import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { ITimelineState } from '@libs/shared-ui/components/timeline-panel/store/core.reducer';
import { NameModules } from '@libs/common/enums/name-modules';
import { Feature, Properties } from '@libs/common/models/feature';

function transformStation(stations: Station[], timeseries: string[]): Feature[] {
    const result: Feature[] = [];
    stations.forEach((station) => {
        if (station) {
            const hasTimeseries =
                !!Object.keys(station.data?.measurements).length ||
                !!Object.keys(station.data?.indexes).length;
            const timeseriesData = {
                date: timeseries,
            };

            if (hasTimeseries) {
                Object.entries(station.data?.measurements).forEach(([key, value]) => {
                    timeseriesData[key] = value.values;
                });
                Object.entries(station.data?.indexes).forEach(([key, value]) => {
                    timeseriesData[key] = value.values;
                });
            }
            result.push({
                type: 'Feature',
                geometry: station.geometry,
                properties: {
                    uuid: station.id,
                    city_id: `${station.ancestor?.id}`,
                    name: station.name,
                    name_ru: station.name,
                    timeseries: timeseriesData,
                    obj: 'station',
                    has_any_timeseries: hasTimeseries,
                    ancestors: [
                        {
                            name: station.ancestor?.name,
                            obj: station.ancestor?.obj,
                            uuid: `${station.ancestor?.id}`,
                        },
                    ],
                },
            });
        }
    });

    return result;
}
function transformControlPoint(point: ControlPoint): Feature[] {
    if (!point) {
        return [];
    }
    const properties: Properties = {
        uuid: point.id,
        name: point.name,
        name_ru: point.name,
        timeseries: point.timeline,
        obj: point.obj,
        has_any_timeseries: !!point?.timeline,
    };
    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [point?.lon, point?.lat],
            },
            properties,
        },
    ];
}
const STATION_MANY_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<Station>(
    STATION_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_SUCCESS
).type;
const STATION_MANY_LOADED_ACTION_ERROR = new EntityActionFactory().create<ControlPoint>(
    STATION_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_ERROR
).type;
const CONTROL_POINTS_MANY_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_SUCCESS
).type;
const CONTROL_POINTS_MANY_ERROR = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_ERROR
).type;
export function onLoadStationAction(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>(
        STATION_ENTITY_STORE_KEY,
        EntityOp.QUERY_MANY,
        params
    );
}

export function onLoadControlPoints(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>(
        CONTROL_POINT_ENTITY_STORE_KEY,
        EntityOp.QUERY_MANY,
        params
    );
}

export function onLoadRunConfig(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>(
        RUN_CONFIG_ENTITY_STORE_KEY,
        EntityOp.QUERY_MANY,
        params
    );
}
const CONTROL_POINT_DELETE_ONE_SUCCESS = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_DELETE_ONE_SUCCESS
).type;
const CONTROL_POINT_SAVE_ONE_SUCCESS = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_ADD_ONE_SUCCESS
).type;
const CONTROL_POINT_SAVE_UPDATE_ONE_SUCCESS = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_UPDATE_ONE_SUCCESS
).type;
const CONTROL_POINT_ADD_ONE_ERROR = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_ADD_ONE_ERROR
).type;
const CONTROL_POINT_UPDATE_ONE_ERROR = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_UPDATE_ONE_ERROR
).type;
const CONTROL_POINT_DELETE_ONE_ERROR = new EntityActionFactory().create<ControlPoint>(
    CONTROL_POINT_ENTITY_STORE_KEY,
    EntityOp.SAVE_DELETE_ONE_ERROR
).type;
const RUN_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<RunPlume>(
    RUN_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_SUCCESS
).type;

const RUN_LOADED_ACTION_ERROR = new EntityActionFactory().create<RunPlume>(
    RUN_ENTITY_STORE_KEY,
    EntityOp.QUERY_MANY_ERROR
).type;
const RUN_CONFIG_UPDATE_ONE_ERROR = new EntityActionFactory().create<RunConfig>(
    RUN_CONFIG_ENTITY_STORE_KEY,
    EntityOp.SAVE_UPDATE_ONE_ERROR
).type;

export function onLoadRunsAction(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>(
        RUN_ENTITY_STORE_KEY,
        EntityOp.QUERY_MANY,
        params
    );
}
const errorDuration = 10000;

@Injectable()
export class PlumesEffects {
    constructor(
        private actions$: Actions,
        private storeCore$: Store<AppState>,
        private plumesCore$: Store<PlumesState>,
        private timelineCore$: Store<ITimelineState>,
        private apiPlumes: ApiPlumes,
        private mapboxActions: MapboxActions
    ) {}

    updateRunDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.updateDateRangeRun),
            withLatestFrom(this.plumesCore$.select(getParamsRun)),
            map(([action, runParams]) => this.loadRunData(runParams))
        )
    );

    checkPlumesIsAvailable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.GroupInfoLoaded),
            withLatestFrom(this.storeCore$.select(allowModule(NameModules.plumes))),
            filter(([_, allowPlumes]) => allowPlumes),
            map(() => toggleModule({ payload: false }))
        )
    );

    updateActiveStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STATION_MANY_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.plumesCore$.select(selectActiveStation)),
            filter(([_, activeStation]) => activeStation !== null),
            map(([{ payload }, activeStation]: [EntityAction<Station[]>, Station]) => {
                const { id } = activeStation;
                const { data } = payload;
                const updatedStation = data.find((p) => p.id === id);
                return setActiveStation({ payload: updatedStation || null });
            })
        )
    );

    updatePlumesRuns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.updatePlumesRuns),
            switchMap(async (action: any) => onLoadRunsAction(action.payload))
        )
    );

    loadPlumesRunsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RUN_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.plumesCore$.select(selectActiveRun)),
            switchMap(([action, run]) => {
                // @ts-ignore
                const result = action.payload.data;
                let index = 0;
                if (result && result.length) {
                    if (run) {
                        const indexOld = result.findIndex((item) => item.id === run.id);
                        if (indexOld !== -1) {
                            index = indexOld;
                        }
                    }
                    return [
                        setActiveRun({ payload: result[index] }),
                        setSourcesSnapShot({ payload: result }),
                    ];
                } else {
                    return [doNothing()];
                }
            })
        )
    );

    clearActiveControlPointOrStationCloseChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            withLatestFrom(
                this.plumesCore$.select(selectActiveControlPoint),
                this.plumesCore$.select(selectActiveStation),
                this.plumesCore$.select(isActivePlumes)
            ),
            filter(
                ([_, isActiveControl, isActiveStation, data]) =>
                    data && (!!isActiveControl || !!isActiveStation)
            ),
            switchMap(([_, isActiveControl, isActiveStation, data]) => {
                if (isActiveControl) {
                    return [
                        setActivePlumesControlPoint({ payload: null }),
                        setChartData({ payload: [] }),
                    ];
                } else {
                    return [setActiveStation({ payload: null }), setChartData({ payload: [] })];
                }
            })
        )
    );

    updateControlPointsByRun = createEffect(() =>
        this.actions$.pipe(
            ofType(
                PlumesActions.setActiveRun,
                PlumesActions.changeCurrentMmt,
                PlumesActions.setHeight,
                CONTROL_POINTS_MANY_LOADED_ACTION_SUCCESS
            ),
            withLatestFrom(
                this.plumesCore$.select(getParamsControlPoints),
                this.plumesCore$.select(getControlPoints)
            ),
            tap(([action, params, points]) => {
                if (Object.keys(points).length === 0) {
                    this.plumesCore$.dispatch(
                        setControlPointData({ payload: null, controlPoints: points })
                    );
                    this.plumesCore$.dispatch(setControlPointLoading({ payload: false }));
                }
            }),
            filter(
                ([action, params, points]) => params !== null && Object.keys(points).length !== 0
            ),
            tap(() => this.plumesCore$.dispatch(setControlPointLoading({ payload: true }))),
            switchMap(([action, params, controlPoints]) =>
                this.apiPlumes.getControlPoints(params).pipe(
                    tap((response) =>
                        this.plumesCore$.dispatch(datesUpdate({ payload: response?.meta.dates }))
                    ),
                    map((response) =>
                        setControlPointData({
                            payload: response as ControlPointsResponse,
                            controlPoints,
                        })
                    ),
                    catchError((error: HttpErrorResponse) => {
                        const actions = [];
                        if (this.checkErrorStatus(error?.status)) {
                            const errorAction = this.actionError(error?.status);
                            actions.push(errorAction);
                        }

                        actions.push(setRunLoading({ payload: false }));
                        actions.push(setControlPointLoading({ payload: false }));
                        return actions;
                    })
                )
            )
        )
    );

    closeChartForActiveControlPointsNotCurrentRun$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setControlPointData),
            withLatestFrom(this.plumesCore$.select(selectActiveControlPoint)),
            filter(([action, activePoint]) => activePoint !== null),
            switchMap(([action, activePoint]) => {
                const controlPointsForCurrentRunIds =
                    action?.payload?.control_points_measurements?.map((v) => v.control_point);
                if (controlPointsForCurrentRunIds.includes(Number(activePoint.id))) {
                    return [];
                }
                return [onIsEnabledChart({ payload: false })];
            })
        )
    );

    updateControlPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(
                CONTROL_POINT_DELETE_ONE_SUCCESS,
                CONTROL_POINT_SAVE_ONE_SUCCESS,
                CONTROL_POINT_SAVE_UPDATE_ONE_SUCCESS
            ),
            withLatestFrom(this.plumesCore$.select(getParamsRun)),
            filter(([action, params]) => params !== null),
            switchMap(([action, runParams]) => [
                this.loadControlPointsData({ group_id: runParams.group_id }),
                setControlPointLoading({ payload: true }),
            ])
        )
    );

    closeChartActiveControlPointWasDelete = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_DELETE_ONE_SUCCESS),
            withLatestFrom(this.plumesCore$.select(selectActiveControlPoint)),
            filter(([action, active]) => active !== null),
            switchMap(([action, params]) => [
                setActivePlumesControlPoint({ payload: null }),
                setChartData({ payload: [] }),
                onIsEnabledChart({ payload: false }),
            ])
        )
    );

    setActiveControlPointsOnMap = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.clickControlPointsOnMap),
            withLatestFrom(this.plumesCore$.select(selectEditControlPoint)),
            switchMap(([action, editControlPoint]) => {
                // @ts-ignore
                const pin = action?.payload as ControlPoint;
                if (
                    pin.obj !== NEW_CONTROL_POINT_OBJ_TYPE &&
                    (editControlPoint === null || pin.id !== editControlPoint.id)
                ) {
                    this.mapboxActions.centringOnMarker(pin.lat, pin.lon, false);
                    return [setActivePlumesControlPoint({ payload: pin })];
                } else {
                    return [];
                }
            })
        )
    );

    updateActiveControlPointChart = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.setControlPointData),
            withLatestFrom(this.plumesCore$.select(selectActivePointAfterUpdate)),
            filter((action, activeControlPoint) => !!activeControlPoint),
            switchMap(([action, controlPoint]) => [
                setActivePlumesControlPoint({ payload: controlPoint }),
            ])
        )
    );

    errorSaveControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                CONTROL_POINT_ADD_ONE_ERROR,
                CONTROL_POINT_UPDATE_ONE_ERROR,
                CONTROL_POINT_DELETE_ONE_ERROR
            ),
            switchMap((action: EntityAction) => {
                const actions = [];
                let data = null;
                const status = action.payload?.data?.error?.error?.status;
                if (this.checkErrorStatus(status)) {
                    data = action.payload?.data?.error?.error;
                }

                const actionError = this.actionError(status);
                actions.push(actionError);
                actions.push(setControlPointError({ payload: data }));
                return actions;
            })
        )
    );

    disableWindByHeight = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.setHeight),
            withLatestFrom(
                this.plumesCore$.select(selectPlumesCurrentHeight),
                this.plumesCore$.select(selectPlumesHeights),
                this.plumesCore$.select(isWindShowPlumesOnMap),
                this.plumesCore$.select(isWindLayerAvailable)
            ),
            switchMap(([action, height, heights, isWindShowOnMap, isWindAvailable]) => {
                const actions = [];
                if (isWindAvailable && heights.length) {
                    if (height !== heights[0]) {
                        actions.push(isDisabledWindButton({ payload: true }));
                        if (isWindShowOnMap) {
                            actions.push(toggleWindLayer({ payload: false }));
                        }
                    } else {
                        actions.push(isDisabledWindButton({ payload: false }));
                    }
                }
                return actions;
            })
        )
    );

    errorUpdateRunConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RUN_CONFIG_UPDATE_ONE_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                let data = null;
                const error = action.payload?.data?.error?.error;
                if (this.checkErrorStatus(error?.status)) {
                    data = action.payload.data.error;
                }
                const actionError = this.actionError(error?.status);
                actions.push(actionError);
                actions.push(setRunConfigUpdateError({ payload: data }));
                return actions;
            })
        )
    );

    setActiveRunLoadStation = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.setActiveRun, PlumesActions.changeCurrentMmt),
            withLatestFrom(this.plumesCore$.select(getParamsStation)),
            filter(([_, params]) => !!params),
            switchMap(([action, params]) => [this.loadStationData(params)])
        )
    );

    loadPlumesRunsError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RUN_LOADED_ACTION_ERROR),
            switchMap((data: EntityAction) => {
                const error = data.payload?.data?.error?.error;
                const errorMsg = this.actionError(error.status);
                if (error?.status === HttpStatusCode.Unauthorized) {
                    return [authError({ payload: true })];
                } else {
                    return [setRunsLoadError({ payload: true }), errorMsg];
                }
            })
        )
    );

    loadControlPointsListError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINTS_MANY_ERROR),
            switchMap((data: EntityAction) => {
                const error = data.payload?.data?.error?.error;
                if (error?.status === HttpStatusCode.Unauthorized) {
                    return [authError({ payload: true })];
                } else {
                    const errorAction = this.actionError(error?.status);
                    if (this.checkErrorStatus(error?.status)) {
                        return of(errorAction);
                    }
                    return of(doNothing());
                }
            })
        )
    );

    loadStationError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STATION_MANY_LOADED_ACTION_ERROR),
            switchMap((data: EntityAction) => {
                const error = data.payload?.data?.error?.error;
                if (error?.status === HttpStatusCode.Unauthorized) {
                    return [
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'tokenExpired',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'error',
                            duration: errorDuration,
                            showCloseIcon: true,
                            size: 'lg',
                        }),
                    ];
                }
            })
        )
    );

    setActiveStationSingleChart = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveStation),
            withLatestFrom(
                this.plumesCore$.select(selectIsShowFullStationData),
                this.plumesCore$.select(selectDatesPlumes)
            ),
            filter(([action, showFullData]) => !showFullData),
            switchMap(([action, isShow, dates]) => [
                setChartData({
                    payload: transformStation([action.payload], dates),
                }),
            ])
        )
    );

    setActiveStationLoadFullData = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveStation),
            withLatestFrom(
                this.plumesCore$.select(getParamsStationById),
                this.plumesCore$.select(selectIsShowFullStationData)
            ),
            filter(([action, data, showFullData]) => data !== null && showFullData),
            tap(() => this.plumesCore$.dispatch(setChartLoading({ payload: true }))),
            switchMap(([action, params]) =>
                this.apiPlumes.getStationData(params).pipe(
                    switchMap((response) => {
                        const result = transformStation(response.data, response.meta?.extra?.dates);
                        return [
                            setChartData({ payload: result }),
                            setChartLoading({ payload: false }),
                        ];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = this.actionError(error?.status);
                        if (this.checkErrorStatus(error?.status)) {
                            return of(errorAction);
                        }
                        return of(doNothing());
                    })
                )
            )
        )
    );

    setActiveControlPointById = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.setActiveControlPointById),
            withLatestFrom(this.plumesCore$.select(selectControlPointReports)),
            switchMap(([action, points]) => {
                const actions = [];
                // @ts-ignore
                const id = action?.payload;
                if (id) {
                    const point = points.find((v) => v.id === id);
                    if (point) {
                        actions.push(clickControlPointsOnMap({ payload: point }));
                    }
                }
                return actions;
            })
        )
    );

    setActiveStationById = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.setActiveStationById),
            withLatestFrom(this.plumesCore$.select(getStations)),
            switchMap(([action, stations]) => {
                const actions = [];
                // @ts-ignore
                const id = action?.payload;
                if (id) {
                    const station = stations.find((v) => v.id === id);
                    if (station) {
                        actions.push(setActiveStation({ payload: station }));
                    }
                }
                return actions;
            })
        )
    );

    createFeatureFromActivePlumesControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActivePlumesControlPoint),
            filter((action) => action?.payload !== null),
            switchMap((action) => [
                setChartData({
                    payload: transformControlPoint(action?.payload as ControlPoint),
                }),
            ])
        )
    );

    showPlumesLayerOnMapLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapLoaded),
            switchMap(() => this.plumesCore$.select(isActivePlumes)),
            map((isActive) => togglePlumesLayerOnMap({ payload: isActive }))
        )
    );

    setChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setChartData),
            map((props) => onIsEnabledChart({ payload: props.payload.length > 0 }))
        )
    );

    vangaServerError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(vangaTokenUpdateError),
            withLatestFrom(this.plumesCore$.select(isActivePlumes)),
            filter(([_, isActive]) => isActive),
            switchMap(() => [setRunsLoadError({ payload: true }), this.actionError(0)])
        )
    );

    authError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.authError),
            take(1),
            switchMap((action: EntityAction) => {
                const actions = [];
                if (action.payload) {
                    actions.push(refreshVangaToken());
                }
                return actions;
            })
        )
    );

    reloadDataOnTokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.VangaTokenUpdated),
            withLatestFrom(this.plumesCore$.select(isActivePlumes)),
            filter(([_, isActive]) => isActive),
            switchMap(([action, isActive]) => (isActive ? [updateAllData()] : []))
        )
    );

    updateAllData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlumesActions.updateAllData),
            withLatestFrom(this.storeCore$.select(selectGroupId)),
            filter(([action, params]) => !!params),
            switchMap(([action, runParams]) => {
                const actions = [];
                const paramsConfig: QueryParams = {
                    group_id: runParams.toString(),
                    expand: 'domain',
                };
                actions.push(this.loadRunConfigData(paramsConfig));
                actions.push(
                    this.loadControlPointsData({
                        group_id: runParams.toString(),
                    })
                );
                return actions;
            })
        )
    );

    loadControlPointsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINTS_MANY_LOADED_ACTION_SUCCESS, CONTROL_POINTS_MANY_ERROR),
            withLatestFrom(this.plumesCore$.select(getParamsRun)),
            filter(([action, params]) => params !== null),
            take(2),
            map(([action, runParams]) => this.loadRunData(runParams))
        )
    );

    updateRunsByIntervalEffect = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRunsByInterval),
            withLatestFrom(
                this.plumesCore$.select(allowUpdateRuns),
                this.plumesCore$.select(getParamsRun)
            ),
            filter(([action, allow, params]) => allow && params !== null),
            map(([action, allow, runParams]) => this.loadRunData(runParams))
        )
    );

    setActiveRunByFilter = createEffect(() =>
        this.actions$.pipe(
            ofType(setFilterForRuns),
            withLatestFrom(
                this.plumesCore$.select(getRunsWithFilter),
                this.plumesCore$.select(selectActiveRun)
            ),
            switchMap(([action, runs, activeRun]) => {
                const actions = [];
                if (
                    action?.payload &&
                    activeRun &&
                    runs?.length &&
                    action.payload !== activeRun.config
                ) {
                    actions.push(setActiveRun({ payload: runs[0] }));
                }
                return actions;
            })
        )
    );

    private loadRunData(params) {
        if (params) {
            return onLoadRunsAction(params);
        }

        return { type: 'empty' };
    }

    private loadStationData(params: QueryParams) {
        if (params) {
            return onLoadStationAction(params);
        } else {
            return doNothing();
        }
    }

    private loadControlPointsData(params: QueryParams) {
        if (params) {
            return onLoadControlPoints(params);
        } else {
            return doNothing();
        }
    }

    private loadRunConfigData(params: QueryParams) {
        if (params) {
            return onLoadRunConfig(params);
        } else {
            return doNothing();
        }
    }

    private actionError(statusError) {
        if (statusError === undefined) {
            return doNothing();
        }
        if (statusError === HttpStatusCode.Unauthorized) {
            return refreshVangaToken();
        } else if (statusError === 404) {
            return addAlert({
                id: new Date().valueOf(),
                messageKey: 'notFoundObject',
                positionX: 'left',
                positionY: 'bottom',
                iconClass: 'error',
                duration: errorDuration,
                showCloseIcon: true,
                size: 'lg',
            });
        } else if (statusError === 0 || statusError >= 500) {
            return addAlert({
                id: new Date().valueOf(),
                messageKey: 'serverError',
                positionX: 'left',
                positionY: 'bottom',
                iconClass: 'error',
                duration: errorDuration,
                showCloseIcon: true,
                size: 'lg',
            });
        }
        return doNothing();
    }

    private checkErrorStatus(status: number): boolean {
        return (
            status === 0 ||
            status >= HttpStatusCode.InternalServerError ||
            status === HttpStatusCode.BadRequest ||
            status === HttpStatusCode.Unauthorized
        );
    }
}
