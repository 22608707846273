import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { TEXTS } from '@libs/common/texts/texts';

export type AccessLevelType = 'fullAccess' | 'partialAccess' | 'closeAccess';
export interface AccessLevel {
    level: AccessLevelType;
    details?: string;
}
export interface ModuleAccessInfo {
    icon: string;
    name: string;
    adminAccess?: AccessLevel;
    operatorAccess?: AccessLevel;
    observerAccess?: AccessLevel;
    module: MAIN_PAGES;
}
export function getModulesInfo(
    modules: MAIN_PAGES[],
    withUser: boolean = true
): ModuleAccessInfo[] {
    const result = [];
    if (modules.includes(MAIN_PAGES.networks)) {
        result.push({
            icon: 'postsAndDevices',
            name: TEXTS.ADMIN_PANEL.postsAndDevices,
            module: MAIN_PAGES.networks,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: {
                level: 'partialAccess',
                details: TEXTS.LIST_USERS.observer,
            },
        });
    }

    if (modules.includes(MAIN_PAGES.analysis)) {
        result.push({
            icon: 'analysis',
            name: TEXTS.ADMIN_PANEL.analysis,
            module: MAIN_PAGES.analysis,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: {
                level: 'closeAccess',
            },
        });
    }
    if (modules.includes(MAIN_PAGES.analysisEvents)) {
        result.push({
            icon: 'analysisEvents',
            name: TEXTS.ADMIN_PANEL.analysisEvents,
            module: MAIN_PAGES.analysisEvents,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'closeAccess' },
        });
    }
    if (modules.includes(MAIN_PAGES.analytics)) {
        result.push({
            icon: 'cityCard',
            name: TEXTS.ADMIN_PANEL.cityCard,
            module: MAIN_PAGES.analytics,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'fullAccess' },
        });
    }
    if (modules.includes(MAIN_PAGES.forecast)) {
        result.push({
            icon: 'forecast',
            name: TEXTS.ADMIN_PANEL.forecast,
            module: MAIN_PAGES.forecast,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'fullAccess' },
        });
    }
    if (modules.includes(MAIN_PAGES.plumes)) {
        result.push({
            icon: 'plumes',
            name: TEXTS.ADMIN_PANEL.plumes,
            module: MAIN_PAGES.plumes,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'partialAccess' },
        });
    }
    if (modules.includes(MAIN_PAGES.indoor)) {
        result.push({
            icon: 'indoor',
            name: TEXTS.ADMIN_PANEL.indoor,
            module: MAIN_PAGES.indoor,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'partialAccess' },
        });
    }

    if (modules.includes(MAIN_PAGES.notifications)) {
        result.push({
            icon: 'notifications',
            name: TEXTS.ADMIN_PANEL.notifications,
            module: MAIN_PAGES.notifications,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'closeAccess' },
        });
    }
    if (modules.includes(MAIN_PAGES.reports)) {
        result.push({
            icon: 'reports',
            name: TEXTS.ADMIN_PANEL.reports,
            module: MAIN_PAGES.reports,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'fullAccess' },
            observerAccess: { level: 'closeAccess' },
        });
    }
    if (withUser) {
        result.push({
            icon: 'listUsers',
            name: TEXTS.LIST_USERS.accessPopUp.moduleNames.users,
            module: null,
            adminAccess: { level: 'fullAccess' },
            operatorAccess: { level: 'closeAccess' },
            observerAccess: { level: 'closeAccess' },
        });
    }

    return result;
}
