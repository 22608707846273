import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { copyObj } from '@libs/common/utils/utils';

@Component({
    selector: 'cs-selectbox',
    templateUrl: 'selectbox.component.html',
    styleUrls: ['selectbox.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectboxComponent implements OnInit, OnChanges {
    @Input() listItems: CheckboxItem[];
    @Input() searchPlaceholderText?: string;
    @Input() selectAllText?: string;
    @Input() showSearch = true;

    @Output() listItemsChange = new EventEmitter<CheckboxItem[]>();

    searchText = '';
    items: CheckboxItem[];

    ngOnInit() {
        this.updateItems();
    }

    ngOnChanges({ listItems }: SimpleChanges) {
        if (listItems) {
            const { firstChange, currentValue, previousValue } = listItems;

            if (!firstChange && currentValue !== previousValue && this.listItems !== this.items) {
                this.updateItems();
            }
        }
    }

    private updateItems() {
        this.items = copyObj(this.listItems);
    }

    allSelect = () => {
        const value = !this.isAllSelected();

        this.items.forEach((item) => (item.selected = value));

        this.listItemsChange.emit(this.items);
    };

    isAllSelected = () => !this.items.find((item) => item.selected === false);

    select = (item: CheckboxItem) => {
        item.selected = !item.selected;

        this.listItemsChange.emit(this.items);
    };
}
