import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import { catchError, filter, map, switchMap, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { selectIAm } from '@cityair/modules/core/store/selectors';
import {
    deleteAllActiveToken,
    deleteUserToken,
    getUserPermission,
    updateApiKey,
    setUserPermission,
    getUserTokenValue,
    setApiKeyToken,
    setRegionCoef,
    updateRegionCoef,
    setNewCoef,
    setMapSettings,
    isSavingMapSetting,
    updateGroupSettingsResponse,
    setStartModuleSettings,
    isSavingStartModule,
} from '@cityair/modules/settings/store/settings/actions';
import { selectAllTokenNotCurrentSession } from '@cityair/modules/settings/store/settings/selectors';
import { addAlert } from '@cityair/modules/core/store/actions';
import {
    selectCurrentRegionCoefs,
    selectExtConfig,
    selectGroupId,
} from '@cityair/modules/core/store/group/group.feature';

@Injectable()
export class SettingsEffects {
    constructor(private actions$: Actions, private store: Store, private basicApi: BasicApi) {}

    getUserPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserPermission),
            withLatestFrom(this.store.select(selectIAm)),
            filter(([action, params]) => params !== null),
            switchMap(([action, params]) =>
                this.basicApi.getUserPermission(params).pipe(
                    map((response) => setUserPermission({ payload: response })),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    deleteUserToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteUserToken),
            concatMap((action) =>
                this.basicApi.deleteUserToken(action.payload).pipe(
                    switchMap(() => {
                        const actions = [];
                        if (action?.payload?.IsApiKey) {
                            actions.push(
                                addAlert({
                                    id: new Date().valueOf(),
                                    messageKey: 'Delete_Success',
                                    positionX: 'left',
                                    positionY: 'bottom',
                                    iconClass: 'success',
                                    duration: 3000,
                                    showCloseIcon: false,
                                    size: 'lg',
                                })
                            );
                        }
                        actions.push(getUserPermission());
                        return actions;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    deleteAllActiveToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAllActiveToken),
            withLatestFrom(this.store.select(selectAllTokenNotCurrentSession)),
            filter(([_, tokens]) => tokens.length > 0),
            switchMap(([action, tokens]) => {
                const actions = [];
                tokens.forEach((token) => actions.push(deleteUserToken({ payload: token })));
                return actions;
            })
        )
    );

    updateApiKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateApiKey),
            switchMap((action) =>
                this.basicApi.updateUserToken(action.payload).pipe(
                    switchMap((response) => [
                        getUserPermission(),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey:
                                action?.payload?.TokenId === 0
                                    ? 'Create_Success'
                                    : 'Update_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 3000,
                            showCloseIcon: false,
                            size: 'lg',
                        }),
                    ]),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    getUserTokenValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserTokenValue),
            switchMap((action) =>
                this.basicApi.getUserApiKeyToken(action.payload).pipe(
                    map((response) =>
                        setApiKeyToken({ payload: response.Result, id: action?.payload?.TokenId })
                    ),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    updateRegionCoef$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRegionCoef),
            withLatestFrom(this.store.select(selectCurrentRegionCoefs)),
            tap(([action, current]) =>
                this.store.dispatch(setNewCoef({ payload: action.payload }))
            ),
            switchMap(([action, current]) => {
                const data = { ...current, fix_pcf: action.payload };
                return this.basicApi.setRegionCoefsItem(data).pipe(
                    switchMap((response) => [
                        setRegionCoef({ payload: response }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Update_Success',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 5000,
                            showCloseIcon: false,
                            size: 'lg',
                        }),
                        setNewCoef({ payload: null }),
                    ]),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction);
                    })
                );
            })
        )
    );

    setMapSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setMapSettings),
            withLatestFrom(this.store.select(selectExtConfig), this.store.select(selectGroupId)),
            tap(([action, current, groupId]) =>
                this.store.dispatch(isSavingMapSetting({ payload: true }))
            ),
            switchMap(([action, current, groupId]) => {
                const params = { ...current, mapSettings: action?.payload?.mapSettings };
                const data = {};
                Object.keys(params).forEach(
                    (key) => (data[key] = JSON.stringify(params[key], null, 4))
                );
                return this.basicApi.updateGroupExtConfig(groupId, { config_json: data }).pipe(
                    switchMap((response) => {
                        return [
                            updateGroupSettingsResponse({ payload: params }),
                            isSavingMapSetting({ payload: false }),
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'Update_Success',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 5000,
                                showCloseIcon: false,
                                size: 'lg',
                            }),
                        ];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction, updateGroupSettingsResponse({ payload: null }));
                    })
                );
            })
        )
    );

    setStartModule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setStartModuleSettings),
            withLatestFrom(this.store.select(selectExtConfig), this.store.select(selectGroupId)),
            tap(([action, current, groupId]) =>
                this.store.dispatch(isSavingMapSetting({ payload: true }))
            ),
            switchMap(([action, current, groupId]) => {
                const params = { ...current, startModule: action?.payload };
                if (action?.payload === null) {
                    delete params.startModule;
                }
                const data = {};
                Object.keys(params).forEach(
                    (key) => (data[key] = JSON.stringify(params[key], null, 4))
                );

                return this.basicApi.updateGroupExtConfig(groupId, { config_json: data }).pipe(
                    switchMap((response) => {
                        return [
                            updateGroupSettingsResponse({ payload: params }),
                            isSavingStartModule({ payload: false }),
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'Update_Success',
                                positionX: 'left',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 5000,
                                showCloseIcon: false,
                                size: 'lg',
                            }),
                        ];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(error);
                        return of(errorAction, updateGroupSettingsResponse({ payload: null }));
                    })
                );
            })
        )
    );
}
