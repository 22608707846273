import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TEXTS, isRU } from '@libs/common/texts/texts';
import {
    DataMarker,
    DataQualityInfo,
    DataQualityMarkerType,
    DEVICE_TERMS_VALUES,
} from '@libs/common/models/dataQuality';
import { HUM, TEMP } from '@libs/common/consts/substance.consts';
import { debounceTime, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getAssetPath } from '@cityair/config';
import {
    LINK_RSI,
    METROLOGICAL_DATA,
    METROLOGICAL_DATA_EN,
} from '@cityair/components/data-quality/constants';

interface MmtData {
    mmt: string;
    minValue: number;
    maxValue: number;
}
@Component({
    selector: 'cityair-data-quality',
    templateUrl: './data-quality.component.html',
    styleUrls: ['./data-quality.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataQualityComponent implements OnInit, OnDestroy, OnChanges {
    @Input() data: DataQualityInfo;
    @Input() qualityDataPercent;
    @Input() dataMarkers: DataMarker[];
    @Output() closeModal = new EventEmitter<void>();

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }
    public lang = isRU ? 'ru' : 'en';
    public textQualityData = TEXTS.QUALITY_DATA;
    public textQualityDataInfo = TEXTS.QUALITY_DATA_INFO;
    public deviceInfo = '';
    public termOfUseDevice = {};
    public mmtNamesText = TEXTS.NAMES;
    public mmtData: MmtData[] = [];
    public TEMP = TEMP;
    public HUM = HUM;
    public ACCURACY = [
        ['±20', null],
        [null, '±20'],
    ];
    public metrologicalData = isRU ? METROLOGICAL_DATA : METROLOGICAL_DATA_EN;
    public dataMarkerPackagesNumber;
    public rsiLinks = LINK_RSI;
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}
    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataMarkers?.currentValue || changes.data?.currentValue) {
            this.updateDeviceInfo();
        }
        if (changes.dataQualityPercent?.currentValue) {
            this.dataMarkerPackagesNumber = this.prepareConditions(this.qualityDataPercent);
        }
    }

    private updateDeviceInfo() {
        if (this.dataMarkers.length) {
            this.termOfUseDevice = this.prepareTermsOfUse();
            this.mmtData = this.prepareMarkersData();
        }
    }

    @ViewChild('article', { static: true }) article: ElementRef<HTMLElement>;

    TEXTS = TEXTS;

    aqiDangerLevels = [
        TEXTS.AQI_DETAILED_INFO.dangerLevelLow,
        TEXTS.AQI_DETAILED_INFO.dangerLevelMedium,
        TEXTS.AQI_DETAILED_INFO.dangerLevelHigh,
    ];

    menuItems = [
        this.textQualityDataInfo.menuItems.dataQuality,
        this.textQualityDataInfo.menuItems.termsForUse,
        this.textQualityDataInfo.menuItems.mainProperty,
        this.textQualityDataInfo.menuItems.markersData,
        this.textQualityDataInfo.menuItems.intervalMarkers,
        this.textQualityDataInfo.menuItems.conditionsMarkers,
        this.textQualityDataInfo.menuItems.markersReports,
    ];

    selectedSection = 0;

    private sections: Element[] = [];
    private sectionsHeaders: Element[] = [];

    private boundaries: {
        top: number;
        bottom: number;
    } = null;

    private onDestroy$ = new Subject<void>();

    ngOnInit() {
        const sections = document.querySelectorAll('.data-quality__section');
        this.sections = Array.from(sections);

        const sectionsHeaders = document.querySelectorAll('.data-quality__section-header');
        this.sectionsHeaders = Array.from(sectionsHeaders);

        this.updateBounds();

        fromEvent(this.article.nativeElement, 'scroll')
            .pipe(debounceTime(100), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.updateScroll();
                this._changeDetectorRef.markForCheck();
            });

        fromEvent(window, 'resize')
            .pipe(debounceTime(100), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.updateBounds();
                this._changeDetectorRef.markForCheck();
            });
        this.dataMarkerPackagesNumber = this.prepareConditions(this.qualityDataPercent);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    updateBounds() {
        const rect = this.article.nativeElement.getBoundingClientRect();

        if (rect) {
            const { top, bottom } = rect;
            this.boundaries = { top, bottom };
        }
    }

    updateScroll() {
        const lastIndex = this.sections.length - 1;
        const lastSection = this.sections[lastIndex];

        // the last section entirely inside of the view
        if (lastSection.getBoundingClientRect().bottom <= this.boundaries.bottom) {
            this.selectedSection = lastIndex;
            return;
        }

        let index = this.sections.findIndex((s) => {
            const { top, bottom } = s.getBoundingClientRect();
            // section's title is inside of the view
            return top <= this.boundaries.top && bottom >= this.boundaries.bottom;
        });

        if (index === -1) {
            index = this.sectionsHeaders.findIndex((sh) => {
                const { bottom } = sh.getBoundingClientRect();
                // section fills all of the view
                return bottom >= this.boundaries.top && bottom <= this.boundaries.bottom;
            });
        }

        if (index !== -1) {
            this.selectedSection = index;
        }
    }

    close() {
        this.closeModal.emit();
    }

    getAsset = getAssetPath;

    showSection(index: number) {
        const header = this.sectionsHeaders[index];

        if (header) {
            this.selectedSection = index;

            const { nativeElement } = this.article;

            // stop momentum scrolling
            nativeElement.style.overflow = 'hidden';

            nativeElement.scrollTop = 0;

            setTimeout(() => {
                nativeElement.style.overflow = '';
            }, 10);

            header.scrollIntoView({
                block: 'start',
            });
        }
    }

    private prepareMarkersData() {
        let mmtData = {};
        this.dataMarkers?.forEach((marker) => {
            if (DEVICE_TERMS_VALUES.indexOf(marker.valueType) === -1) {
                if (mmtData.hasOwnProperty(marker.valueType)) {
                    if (marker.type === DataQualityMarkerType.MinValue) {
                        mmtData[marker.valueType] = Object.assign(mmtData[marker.valueType], {
                            minValue: marker.value,
                        });
                    } else if (marker.type === DataQualityMarkerType.MaxValue) {
                        mmtData[marker.valueType] = Object.assign(mmtData[marker.valueType], {
                            maxValue: marker.value,
                        });
                    }
                } else {
                    if (marker.type === DataQualityMarkerType.MinValue) {
                        mmtData = Object.assign(
                            { ...mmtData },
                            { [marker.valueType]: { minValue: marker.value } }
                        );
                    } else if (marker.type === DataQualityMarkerType.MaxValue) {
                        mmtData[marker.valueType] = Object.assign(mmtData[marker.valueType], {
                            maxValue: marker.value,
                        });
                    }
                }
            }
        });

        const result = [];
        for (const index in mmtData) {
            if (mmtData.hasOwnProperty(index)) {
                result.push({
                    mmt: index,
                    minValue: mmtData[index]?.minValue ?? null,
                    maxValue: mmtData[index]?.maxValue ?? null,
                });
            }
        }

        return result;
    }

    private prepareTermsOfUse() {
        let result = {};
        DEVICE_TERMS_VALUES.forEach((val) => {
            if (val === TEMP) {
                const minValue = this.dataMarkers.find(
                    (marker) =>
                        marker.valueType === TEMP && marker.type === DataQualityMarkerType.MinValue
                );
                const maxValue = this.dataMarkers.find(
                    (marker) =>
                        marker.valueType === TEMP && marker.type === DataQualityMarkerType.MaxValue
                );
                if (minValue && maxValue) {
                    result = {
                        ...result,
                        [TEMP]: { minValue: minValue.value, maxValue: maxValue.value },
                    };
                }
            }
            if (val === HUM) {
                const maxValue = this.dataMarkers.find(
                    (marker) =>
                        marker.valueType === HUM && marker.type === DataQualityMarkerType.MaxValue
                );
                if (maxValue) {
                    result = {
                        ...result,
                        [HUM]: { maxValue: maxValue.value },
                    };
                }
            }
        });
        return result;
    }

    private prepareConditions(dataPercent) {
        if (!dataPercent) {
            return;
        }
        const c = dataPercent / 100;
        return {
            min5: Math.ceil(5 * c),
            min20: Math.ceil(4 * c),
            hour: Math.round(3 * c),
            day: Math.round(24 * c),
        };
    }
}
