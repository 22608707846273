import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { Group, IBasicResponse } from '@libs/common/models/basicModels';
import { NameModules } from '@libs/common/enums/name-modules';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { DEFAULT_MAP_STYLES } from '@libs/common/consts/map.const';

export enum GroupActions {
    setCurrentGroup = '[GroupActions] set Current Group',
    setGroupList = '[GroupActions] set Group List',
}

export const setCurrentGroup = createAction(
    GroupActions.setCurrentGroup,
    props<{ group: Group | null }>()
);

export const setGroupList = createAction(
    GroupActions.setGroupList,
    props<{ payload: IBasicResponse }>()
);
type GroupState = {
    groups: Group[];
    currentGroup: Group;
};
const initialState: GroupState = {
    groups: [],
    currentGroup: null,
};

export const groupFeature = createFeature({
    name: 'group',
    reducer: createReducer(
        initialState,

        on(setGroupList, (state, { payload }) => {
            const dataResponse = payload?.data as Group[];
            const groups = [...dataResponse]?.sort((a, b) =>
                a.name > b.name ? 1 : a.name < b.name ? -1 : 0
            );
            return {
                ...state,
                groups: groups ?? [],
            };
        }),

        on(setCurrentGroup, (state, { group }) => ({
            ...state,
            currentGroup: group,
        }))
    ),
});

const featureSelector = createFeatureSelector<GroupState>('group');

export const getCurrentGroup = createSelector(featureSelector, (state) => state.currentGroup);
export const selectGroupList = createSelector(featureSelector, (state) => state.groups);
export const selectGroupId = createSelector(getCurrentGroup, (group) => group?.id);
export const selectExtConfig = createSelector(getCurrentGroup, (group) =>
    group ? group?.ext_config : null
);
export const allowModule = (module: NameModules) =>
    createSelector(getCurrentGroup, (group) => {
        const currentModule = group?.modules?.find((m) => m.name === module);
        return currentModule ? true : false;
    });
export const selectCurrentRegionCoefs = createSelector(getCurrentGroup, (group) =>
    group ? group?.region_coefs : null
);
export const selectGroupCreateDate = createSelector(getCurrentGroup, (group) =>
    group ? group?.create_date : null
);
export const selectMapStyleTypes = createSelector(selectExtConfig, (extConfig) => {
    if (extConfig?.mapStyleSelector) {
        return extConfig?.mapStyleSelector as MapStyleType[];
    }
    return DEFAULT_MAP_STYLES;
});
