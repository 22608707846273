import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'mmt-item',
    templateUrl: 'mmt-item.component.html',
    styleUrls: ['mmt-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactMmtItemComponent {
    @Input() id: string;
    @Input() title: string;
    @Input() value: number;
    @Input() unit: string;

    public TEXTS = TEXTS;
}
