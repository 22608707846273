import { Component, Input } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';
import { detectTouchDevice } from '@libs/common/utils/detect-touch-device';

@Component({
    selector: 'ca-menu-button',
    templateUrl: 'menu-button.component.html',
    styleUrls: ['menu-button.component.less'],
})
export class MenuButtonComponent {
    @Input() name: string;
    @Input() isAvailable = true;
    @Input() showTooltip = true;

    TEXTS = TEXTS;

    isTouchDevice = detectTouchDevice();

    onClick($event) {
        if (!this.isAvailable) {
            $event.stopPropagation();
        }
    }
}
