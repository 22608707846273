import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { SearchInputBasicModule } from '@libs/shared-ui/components/search-input-basic/search-input-basic.module';
import { IconsModule } from '@libs/shared-ui/components/icons/icons.module';
import { CorrelationPinModule } from '@cityair/components/correlation-pin/correlation-pin.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';

import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { settingsRoutes } from '@cityair/modules/settings/settings-routing';
import { KeysComponent } from '@cityair/modules/settings/components/keys/keys.component';
import { SettingsMainComponent } from '@cityair/modules/settings/components/settings-main/settings-main.component';
import { SystemSettingsComponent } from '@cityair/modules/settings/components/system-settings/system-settings.component';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { CardModule } from '@cityair/components/card/card.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioLangModule } from '@cityair/components/selectbox-radio-lang/selectbox-radio-lang.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { SettingsPlugComponent } from '@cityair/modules/settings/components/settings-plug/settings-plug.component';
import { UsersComponent } from '@cityair/modules/settings/components/users/users.component';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';
import { BtnExtraModule } from '@libs/shared-ui/components/btn-extra/btn-extra.module';

import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { SettingsEditUser } from '@cityair/modules/settings/components/users/edit-user/edit-user.component';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { AccessInfoComponent } from '@libs/shared-ui/components/access-info/access-info.component';
import { SwitchButtonModule } from '@cityair/components/switch-button/switch-button.module';
import { CaToggleButtonComponent } from '@libs/shared-ui/components/ca-toggle-button/ca-toggle-button.component';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';
import { StoreModule } from '@ngrx/store';
import { usersReducers } from '@cityair/modules/settings/store/users/reducers';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects } from '@cityair/modules/settings/store/users/effects';
import { settingsReducer } from '@cityair/modules/settings/store/settings/reducers';
import { SettingsEffects } from '@cityair/modules/settings/store/settings/effects';
import { SettingsGroupComponent } from '@cityair/modules/settings/components/settings-group/settings-group.component';
import { MapSettingsComponent } from '@cityair/modules/settings/components/settings-group/map-settings/map-settings.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { StartModuleSettingsComponent } from '@cityair/modules/settings/components/settings-group/start-module-settings/start-module-settings.component';
import { SidebarModule } from '@libs/shared-ui/components/sidebar/sidebar.module';
import { CoefficientGroupComponent } from '@cityair/modules/settings/components/settings-group/coefficient-group/coefficient-group.component';
const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJjazk4MWhja3IwNHZiM25waDJkeWhheHQ0In0.2cWaHj0iCYNPagxl9GBTuA';

@NgModule({
    declarations: [
        SettingsMainComponent,
        SystemSettingsComponent,
        KeysComponent,
        SettingsPlugComponent,
        UsersComponent,
        SettingsEditUser,
        SettingsGroupComponent,
        MapSettingsComponent,
        StartModuleSettingsComponent,
        CoefficientGroupComponent,
    ],
    exports: [],
    imports: [
        RouterModule,
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN,
        }),
        RouterModule.forChild(settingsRoutes),
        StoreModule.forFeature('users', usersReducers),
        StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([UsersEffects, SettingsEffects]),
        CommonModule,
        PageTabsModule,
        LoadersModule,
        CrossButtonModule,
        DirectivesModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        SearchInputBasicModule,
        IconsModule,
        SortListItemModule,
        SearchResultPlaceholderModule,
        TooltipsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        TooltipsModule,
        CardModule,
        SharedComponentsModule,
        ButtonAddModule,
        CardActionsModule,
        SwitchItemModule,
        InputDropdownModule,
        InputForCoordinateModule,
        CorrelationPinModule,
        SelectboxRadioLangModule,
        BtnAcceptModule,
        BtnCancelModule,
        CheckboxModule,
        InfoIconModule,
        FormsModule,
        ReactiveFormsModule,
        KebabMenuModule,
        InputModule,
        SelectboxRadioModule,
        TagsListModule,
        SelectboxModule,
        AccessInfoComponent,
        SwitchButtonModule,
        CaToggleButtonComponent,
        SearchInputModule,
        ItemRadioModule,
        BtnExtraModule,
        SidebarModule,
    ],
})
export class SettingsModule {}
