import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TabModel } from '@libs/common/types/tab-model';

@Component({
    selector: 'switch-item',
    template: `
        <div class="ca_switch_item">
            <ng-container *ngFor="let tab of tabs">
                <div
                    [ngClass]="{
                        'ca_switch_item__tab noselect': true,
                        'ca_switch_item__tab-active': tab === currentTab,
                        disabled: tab.isDisabled
                    }"
                    (click)="!tab.isDisabled && selectTab(tab)"
                    [innerHTML]="tab.title"
                    [attr.data-cy]="tab.type"
                    caTooltip
                    caTooltipPlacement="top-left"
                    [caTooltipAllowHover]="true"
                    [caTooltipTemplate]="Tooltip"
                ></div>
                <ng-template #Tooltip>
                    <ca-tooltip
                        *ngIf="showTooltipForDisabled ? tab.isDisabled && tab.tooltip : tab.tooltip"
                        [text]="tab.tooltip"
                    ></ca-tooltip>
                </ng-template>
            </ng-container>
        </div>
    `,
    styleUrls: ['switch-item.component.less'],
})
export class SwitchItemComponent {
    @Input() tabs: TabModel[];
    @Input() currentTab: TabModel;
    @Input() showTooltipForDisabled = false;
    @Output() action = new EventEmitter<TabModel>();

    selectTab(tab: TabModel) {
        this.action.emit(tab);
    }
}
