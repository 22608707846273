import { Component, Input, OnChanges } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { isRU } from '@libs/common/texts/texts';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

const WIND_ORDER_DIRECTION = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
@Component({
    selector: 'cityair-radar-chart',
    templateUrl: './radar-chart.component.html',
    styleUrls: ['./radar-chart.component.less'],
})
export class RadarChartComponent implements OnChanges {
    @Input() data: { [key: string]: number };
    labels = isRU ? ['C', 'СВ', 'В', 'ЮВ', 'Ю', 'ЮЗ', 'З', 'СЗ'] : WIND_ORDER_DIRECTION;
    constructor(private numberFormatPipe: ValueFormatNumberPipe) {}
    public radarChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                ticks: {
                    callback: function (value) {
                        return value.toString() + '%';
                    },
                },
                min: 0,
                max: 100,
                pointLabels: {
                    color: '#404655',
                    font: {
                        size: 10,
                        family: 'Inter',
                        weight: 'bold',
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                borderWidth: 1,
                displayColors: true,
                xAlign: 'center',
                yAlign: 'top',
                callbacks: {
                    label: (tooltipItem) => {
                        const value = Number(tooltipItem.formattedValue);
                        if (isNaN(value)) {
                            return `${tooltipItem.label}: ${tooltipItem.formattedValue.slice(
                                0,
                                4
                            )}%`;
                        } else {
                            const formatValue = this.numberFormatPipe.transform(value, 2);
                            return `${tooltipItem.label}: ${formatValue}%`;
                        }
                    },
                },
            },
        },
    };
    public radarChartData: ChartData<'radar'>;
    public radarChartType: ChartType = 'radar';

    ngOnChanges() {
        const data = [];
        WIND_ORDER_DIRECTION.forEach((k) => {
            const val = this.data.hasOwnProperty(k)
                ? Number((this.data[k] * 100) / 24)?.toFixed(2)
                : 0;
            data.push(val);
        });

        this.radarChartData = {
            datasets: [
                {
                    label: this.labels[0],
                    data,
                    fill: true,
                    backgroundColor: ['rgba(0, 128, 0, 0)'],
                    borderColor: '#008000',
                    pointBackgroundColor: 'rgba(0, 128, 0, 1)',
                },
            ],
            labels: this.labels,
        };
    }
}
