<div class="chart-content" *ngIf="datasets.length else emptyData">
    <canvas
        baseChart
        type="bar"
        [datasets]="datasets"
        [labels]="labels"
        [options]="options"
    ></canvas>
</div>
<ng-template #emptyData>
    <div class="nodata">{{noDataText}}</div>
</ng-template>

