import { Component, HostListener, OnDestroy } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgLocalization } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

import { isRU, LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { differentObject } from '@cityair/utils/utils';
import { copyObj } from '@libs/common/utils/utils';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import {
    DataObjType,
    GroupUserParams,
    Post,
    GroupUser,
    BasicRolesResponse,
} from '@libs/common/models/basicModels';
import { Store } from '@ngrx/store';
import {
    createUser,
    foundUser,
    setFormError,
    toggleUserForm,
    updateUser,
} from '@cityair/modules/settings/store/users/actions';
import {
    selectAllUsers,
    selectFormError,
    selectFoundUserResult,
    selectIsCloseUserForm,
    selectIsLoadingForm,
} from '@cityair/modules/settings/store/users/selectors';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { filter, Subject, takeUntil } from 'rxjs';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { selectAvailableModule, selectRoles } from '@cityair/modules/core/store/selectors';
import { getModulesInfo, ModuleAccessInfo } from '@libs/common/models/access.model';

@Component({
    selector: 'edit-user',
    templateUrl: 'edit-user.component.html',
    styleUrls: ['edit-user.component.less'],
})
export class SettingsEditUser implements OnDestroy {
    users: GroupUser[];
    mos: Post[];

    TEXTS = TEXTS;
    usersControl: UntypedFormGroup;

    notShowTooltip = true;
    showUsersRole = false;
    isLoading = false;

    isEditMode = false;
    isUserFound = null;
    newUserProps: GroupUser;
    currentEmailText: string;
    currentUser: GroupUser;
    groupId: string;
    showAdditional = false;
    errorResponse: string;
    listStations: CheckboxItem[] = [];
    roles: BasicRolesResponse[];
    roleList: CheckboxItem[] = [];
    selectedRole: CheckboxItem;
    isShowDropdownForStations = false;
    showAccessLevelPopup = false;
    public moduleAccessInfo: ModuleAccessInfo[];
    private isInit = true;
    private initUser: string;
    private currentUserId: string;

    public ngDestroyed$ = new Subject<void>();
    constructor(
        private ngLocalization: NgLocalization,
        public store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder
    ) {
        this.route.params.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
            if (this.isInit) {
                this.initUser = params.hasOwnProperty('id') ? params?.id : null;
                if (!this.initUser) {
                    this.initForm();
                }
            }
        });
        this.store
            .select(selectRoles)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((roles) => {
                this.roles = roles;
                this.roleList = this.prepareRoleList();
            });
        this.store
            .select(selectAllPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((result) => {
                this.mos = result;
                this.listStations = this.prepareListMo([]);
            });
        this.store
            .select(selectAllUsers)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length)
            )
            .subscribe((result) => {
                this.users = result;
                if (this.isInit && this.initUser !== null) {
                    this.isInit = false;
                    const currentUser = result.find((v) => v.id === this.initUser);
                    if (currentUser) {
                        this.currentUserId = this.initUser;
                        this.newUserProps = copyObj(currentUser);
                        this.isEditMode = true;
                        this.showAdditional = true;
                        this.roleList = this.prepareRoleList(this.newUserProps?.group_role?.id);
                        this.selectedRole = this.roleList.find((v) => v.selected);
                        if (this.newUserProps?.group_role?.details?.posts?.length) {
                            this.listStations = this.prepareListMo(
                                this.newUserProps.group_role.details.posts
                            );
                        }
                        this.currentUser = currentUser;
                        this.initForm();
                    } else {
                        this.router.navigate([
                            `/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.users}`,
                        ]);
                    }
                }
            });

        this.store
            .select(selectFoundUserResult)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter(() => !this.isEditMode)
            )
            .subscribe((result) => {
                if (result && this.usersControl) {
                    this.newUserProps = {
                        id: null,
                        login: result?.Login,
                        name: null,
                        email: result?.Email,
                        obj: DataObjType.user,
                        ...result,
                        group_role: { id: '2', details: null },
                    };
                    this.selectedRole = this.roleList.find((v) => v.selected);
                    this.isUserFound = true;
                } else {
                    this.setEmptyUser();
                    this.isUserFound = false;
                }
            });
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((result) => {
                this.groupId = result;
            });
        this.store
            .select(selectIsLoadingForm)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((result) => {
                this.isLoading = result;
            });
        this.store
            .select(selectFormError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((response) => {
                if (response) {
                    const data = response.error?.meta?.error?.validation_errors;
                    if (data?.length) {
                        data.forEach((error) => {
                            const key = error?.field;
                            if (key && this.usersControl[key]) {
                                this.usersControl[key].setErrors({
                                    incorrect: true,
                                    message: error?.error,
                                });
                            } else {
                                this.errorResponse = this.TEXTS.INFO_MESSAGE.Create_Error;
                            }

                            setTimeout(() => {
                                this.store.dispatch(setFormError({ payload: null }));
                                this.errorResponse = null;
                            }, 5000);
                        });
                    }
                }
            });

        this.store
            .select(selectIsCloseUserForm)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v)
            )
            .subscribe((result) => {
                this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.users}`]);
            });
        this.store
            .select(selectAvailableModule)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length)
            )
            .subscribe((modules) => {
                this.moduleAccessInfo = getModulesInfo(modules);
            });
    }

    public getError(field: string): string {
        if (
            this.usersControl &&
            this.usersControl.controls[field] &&
            this.usersControl.controls[field].invalid &&
            (this.usersControl.controls[field].dirty || this.usersControl.controls[field].touched)
        ) {
            if (this.usersControl.controls[field].errors.required) {
                return this.TEXTS.LIST_USERS.required;
            }
            if (
                field === 'email' &&
                this.usersControl.controls[field].invalid &&
                this.usersControl.controls[field].enabled
            ) {
                if (this.usersControl.controls[field].errors.email) {
                    return TEXTS.LIST_USERS.incorrectEmail;
                } else if (this.usersControl.controls[field].errors.emailInGroupValidator) {
                    return TEXTS.LIST_USERS.emailInGroup;
                } else if (this.usersControl.controls[field].errors.minlength) {
                    return TEXTS.LIST_USERS.minLength(
                        this.usersControl.controls[field].errors.minlength?.requiredLength
                    );
                }
            } else if (this.usersControl.controls[field].errors.incorrect) {
                return this.usersControl.controls[field].errors.message;
            } else if (field === 'login') {
                return TEXTS.LIST_USERS.invalidLogin;
            }
        }

        return null;
    }

    public selectedCheckboxText(num: number = 0) {
        const { selected, post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        if (num === 0) {
            return TEXTS.LIST_USERS.noSelect;
        } else if (num === this.listStations.length) {
            return TEXTS.NOTIFICATIONS.selectAll;
        }

        return [selected[category], num, post[category]].join(' ');
    }

    public prepareListMo(list: string[]): CheckboxItem[] {
        return this.mos.map((s) => ({
            id: s.id,
            label: s.name,
            selected: list.includes(s.id),
        }));
    }

    public removeFromList(index: number) {
        const label = this.getTags()[index];
        this.listStations.find((s) => {
            if (s.label === label) {
                s.selected = false;
                return true;
            }
        });

        this.listStations = [...this.listStations];
        this.updateListStation(this.listStations);
    }

    public updateListStation($event) {
        this.listStations = $event;
        const mos = [];
        this.listStations?.forEach((v) => {
            if (v.selected) {
                mos.push(v.id);
            }
        });
        if (this.newUserProps.group_role.details === null) {
            this.newUserProps.group_role.details = { posts: [] };
        }
        this.newUserProps.group_role.details.posts = mos;
    }

    public getTags = () => this.listStations.filter((s) => s.selected).map((s) => s.label);

    public postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    public getValueForm(field) {
        return this.usersControl?.get(field)?.value || '';
    }

    initForm() {
        this.usersControl = this.fb.group({
            email: new UntypedFormControl(
                {
                    value: this.currentUser?.email,
                    disabled: !!this.currentUser,
                },
                [this.emailInGroupValidator, Validators.minLength(5), Validators.email]
            ),
        });

        this.usersControl.controls.email.valueChanges
            .pipe(debounceTime(300))
            .subscribe(async (value) => {
                if (value && this.usersControl.controls.email.valid) {
                    this.currentEmailText = value;
                    if (!this.showAdditional) {
                        this.showAdditional = true;
                    }
                    this.store.dispatch(foundUser({ payload: value }));
                    if (!this.isUserFound) {
                        this.setEmptyUser();
                    }
                }
            });
    }

    getSelectedRole(role: CheckboxItem[]) {
        this.selectedRole = this.roleList.find((v) => v.selected);
        this.newUserProps.group_role.id = this.selectedRole.id.toString();
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.cancel();
    }

    ngOnDestroy() {
        this.store.dispatch(toggleUserForm({ payload: false }));
        this.ngDestroyed$.next();
    }

    emailInGroupValidator = (formControl: UntypedFormControl) =>
        this.users?.find((u) => u.email === formControl?.value)
            ? { emailInGroupValidator: { message: 'user in group' } }
            : null;

    usernameValidator = (formControl: UntypedFormControl) =>
        formControl?.value?.match(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~\.-]*$/)
            ? null
            : { usernameValidator: { message: 'error usernameValidator' } };

    updateUsersMO = (mos: GroupUser[]) => {
        if (this.newUserProps.group_role.details === null) {
            this.newUserProps.group_role.details = { posts: [] };
        }
        this.newUserProps.group_role.details.posts = mos;
    };

    toggleUsersRole(e: MouseEvent) {
        e.stopPropagation();
        this.showUsersRole = !this.showUsersRole;
    }

    hideUserRoles() {
        this.showUsersRole = false;
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    checkChangeRole = () => this.newUserProps?.group_role.id !== this.currentUser?.group_role.id;

    checkChangeMos = () =>
        differentObject(
            this.newUserProps?.group_role.details?.posts,
            this.currentUser?.group_role.details?.posts,
            true
        );

    apply = async () => {
        if (this.isEditMode) {
            const params: GroupUserParams = {
                user: this.newUserProps.id,
                role: this.newUserProps.group_role?.id,
                posts: this.newUserProps.group_role.details?.posts,
            };
            this.store.dispatch(updateUser({ payload: params }));
        } else {
            const params: GroupUserParams = {
                group: this.groupId,
                email: this.newUserProps.email,
                login: !this.isUserFound ? this.newUserProps.email : this.newUserProps.login,
                role: this.newUserProps.group_role?.id,
                posts: this.newUserProps.group_role.details?.posts ?? null,
            };

            this.store.dispatch(createUser({ payload: params, isNew: !this.isUserFound }));
        }
    };

    isDisabled = () => {
        if (this.isLoading) {
            return true;
        }

        if (this.isEditMode) {
            return !(this.checkChangeRole() || this.checkChangeMos());
        } else if (this.usersControl) {
            const { email } = this.usersControl.controls;
            return !(email.valid && email.value && this.selectedRole);
        }
        return true;
    };

    cancel() {
        this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.users}`]);
    }

    infoIconClick() {
        this.showAccessLevelPopup = true;
    }

    private setEmptyUser() {
        this.newUserProps = {
            id: null,
            name: null,
            login: null,
            email: null,
            obj: DataObjType.user,
            group_role: { id: '2', details: null },
        };
        this.newUserProps.email = this.currentEmailText;
        this.newUserProps.login = this.currentEmailText;
    }

    private prepareRoleList(currentRoleId?: string): CheckboxItem[] {
        return this.roles?.map((s) => ({
            id: s.id,
            label: `<div class="role-text ${s.name}">${isRU ? s.name_ru : s.name}</div>`,
            selected: s.id === currentRoleId,
        }));
    }
}
