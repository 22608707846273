import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectboxRadioLangComponent } from './selectbox-radio-lang.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';

@NgModule({
    exports: [SelectboxRadioLangComponent],
    declarations: [SelectboxRadioLangComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ItemRadioModule],
})
export class SelectboxRadioLangModule {}
