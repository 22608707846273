import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { IZA } from '@libs/common/consts/iza.conts';
import { customNumberFormat, getNumberAfterDotByNumber } from '@libs/common/utils/utils';
import { formatNumber } from '@angular/common';

export function labelToKey(label: string) {
    return label.split('$$')[1];
}

export function keyToLabel(seriesName: string, key: string) {
    return `${seriesName}$$${key}`;
}

export function createElement(type: string, className?: string) {
    const el = document.createElement(type);
    if (className) el.classList.add(className);

    return el;
}

export function correctLabelTooltip(text: string) {
    const values = text.split(':');
    const parser = values[0].split('$$');
    const label = parser[1] ?? parser[0];
    return {
        label: label,
        cityName: parser[0] ?? '',
        value: values[1] ?? '',
    };
}

export function getOrCreateTooltip(chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector('.tooltip-html');
    if (!tooltipEl) {
        tooltipEl = createElement('div');
        tooltipEl.classList.add('tooltip-html');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
        tooltipEl.style.borderRadius = '7px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.fontSize = '12px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.zIndex = '40';

        const table = createElement('table');
        table.style.margin = '0px';
        table.style.width = '100%';
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
}

export function createTooltipTr(color, body, valueInit) {
    const td1 = createElement('td', 'chart_tooltip__td1');

    const span = createElement('span', 'chart_tooltip__color');
    span.style.background = color;
    td1.appendChild(span);

    const span2 = createElement('span');

    const textCorrect = correctLabelTooltip(body[0]);
    span2.innerHTML =
        TEXTS.NAMES[textCorrect.label] +
        ':' +
        (textCorrect.cityName ? ` (${textCorrect.cityName})` : '');
    td1.appendChild(span2);

    const td2 = createElement('td', 'chart_tooltip__td2');
    const value = textCorrect.value.trim();
    if (valueInit !== null && !isNaN(Number(value))) {
        const numberAfterDot = getNumberAfterDotByNumber(value);
        const formatValue = formatNumber(
            Number(value),
            LANGUAGE,
            '1.' + numberAfterDot + '-' + numberAfterDot
        );
        td2.innerHTML = formatValue;
    } else {
        td2.innerHTML = valueInit !== null ? textCorrect.value : '-';
    }

    const tr = createElement('tr', 'chart_tooltip__tr');
    tr.appendChild(td1);
    tr.appendChild(td2);

    return tr;
}

export function createTooltipIzaTr(data, tableBody) {
    const { val, details } = data;

    Object.keys(details).forEach((key) => {
        const iza = details[key];

        const tr = createElement('tr', 'chart_tooltip__iza_tr');

        const td1 = createElement('td', 'chart_tooltip__td1');
        td1.innerHTML = `${TEXTS.NAMES[IZA]} ${TEXTS.NAMES[key] ?? key}:`;

        const td2 = createElement('td', 'chart_tooltip__td2');
        const formatValue = formatNumber(iza, LANGUAGE, '1.1-1');
        td2.innerHTML = customNumberFormat(formatValue, 1, LANGUAGE);

        const td3 = createElement('td', 'chart_tooltip__td3');
        const percent = Math.round((iza / val) * 100);
        td3.innerHTML = (percent <= 0 ? '<1' : percent) + '%';

        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.appendChild(td3);

        tableBody.appendChild(tr);
    });
}
export function createContributionTr(data) {
    const { value, color, label } = data;
    const td1 = createElement('td', 'chart_tooltip__td1');
    const span = createElement('span', 'chart_tooltip__color-contrib');
    span.style.background = color;
    td1.appendChild(span);
    const span2 = createElement('span');
    span2.innerHTML = `${label}`;
    td1.appendChild(span2);

    const td2 = createElement('td', 'chart_tooltip__td2');
    td2.innerHTML = `${Math.round(value)}%`;
    const tr = createElement('tr', 'chart_tooltip__tr');
    tr.appendChild(td1);
    tr.appendChild(td2);
    return tr;
}
