import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { TEXTS } from '@libs/common/texts/texts';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { GroupFeaturesService } from '../../../core/services/group-features/group-features.service';
import { ControlPointService } from '../../services/control-point/control-point.service';
import { Subject } from 'rxjs';
import { RunPlume, RunPlumeAnnotation } from '../../services/run/models';
import {
    getControlPointValues,
    selectActiveControlPoint,
    selectActiveRun,
    selectControlPointReports,
    selectControlPointsLoading,
    selectCurrentIndexPlumes,
} from '../../store/selectors';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { Dictionary } from '@ngrx/entity';
import { RunConfig } from '@cityair/modules/plumes/services/run-config/models';

@Component({
    selector: 'plumes-control-points',
    templateUrl: 'plumes-control-points.component.html',
    styleUrls: ['plumes-control-points.component.less'],
})
export class PlumesControlPoints implements OnInit, OnDestroy {
    @Output() cbOpenAddCheckpoint = new EventEmitter<void>();
    @Output() cbOpenEditCheckpoint = new EventEmitter<void>();
    @Output() controlPointForEditChange = new EventEmitter<ControlPoint>();
    @Input() controlPointForEdit: ControlPoint;
    @Input() openChartControlPoint: (point: ControlPoint, measures: string) => void;
    @Input() centringOnMarker: (
        lat: number,
        lng: number,
        zooming: boolean,
        shiftMap?: [number, number]
    ) => void;
    @Input() currentMeasure: string;
    @Input() loadError: boolean;
    @Input() runs: RunPlumeAnnotation[];
    @Input() configs: Dictionary<RunConfig>;
    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;
    @ViewChild('popupDeleteOutlet', { static: true })
    popupDeleteOutlet: TemplateRef<HTMLDivElement>;

    selectMeasureScheme = selectMeasureScheme;

    textsPlumes = TEXTS.PLUMES;
    editStation = TEXTS.EDIT_STATION;
    textsScheme = TEXTS.MEASURES_SCHEME;
    public mmtNames = TEXTS.NAMES;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    GroupExtConfigName = GroupExtConfigName;
    measureScheme = MeasureScheme;
    getDigitsAfterDot = getDigitsAfterDot;

    sortingControlPoint;
    sortingDirection = 1;
    controlPoints: ControlPoint[] = [];
    run: RunPlume;
    public activeControlPoint: ControlPoint;
    public loading;
    popupActive = false;
    popupPositionTop = 0;
    getControlPointValues = getControlPointValues;
    timeIndex: number;
    selectedPoint: ControlPoint = null;

    popupOpenerElement: HTMLElement;

    isDeletePopupOpen: boolean;
    popupDeleteTexts = Object.assign({}, TEXTS.PLUMES.popupDelete);
    public ngDestroyed$ = new Subject<void>();
    constructor(
        readonly popupProvider: OffPanelPopupService,
        readonly controlPointService: ControlPointService,
        public groupFeaturesService: GroupFeaturesService,
        public store: Store
    ) {
        store
            .select(selectControlPointReports)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data) this.controlPoints = data;
            });
        store
            .select(selectActiveRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.run = data));
        store
            .select(selectActiveControlPoint)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.activeControlPoint = data));
        store
            .select(selectCurrentIndexPlumes)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.timeIndex = data));
        store
            .select(selectControlPointsLoading)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.loading = data));
    }

    ngOnInit() {
        this.sortingDirection = 1;
        this.sortingOfControlPoint(this.sortControlPointName);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    sortControlPointName = (cp: ControlPoint) => cp.name;
    sortControlPointValue = (cp: ControlPoint) => {
        if (cp.timeline.hasOwnProperty(this.currentMeasure) && cp.timeline[this.currentMeasure]) {
            return cp.timeline[this.currentMeasure][this.timeIndex];
        }

        return null;
    };

    sortingOfControlPoint = (sortItem) => {
        if (this.sortingControlPoint === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingControlPoint = sortItem;
        }
    };

    openAddCheckpoint = () => {
        this.cbOpenAddCheckpoint.emit();
    };

    deleteControlPoint = (id: string) => {
        this.controlPointService.delete(id);
    };

    editControlPoint = () => {
        this.controlPointForEditChange.emit(this.selectedPoint);
        this.cbOpenEditCheckpoint.emit();
    };

    openControlPointChart(point: ControlPoint) {
        this.centringOnMarker(point.lat, point.lon, false);
        this.openChartControlPoint(point, this.currentMeasure);
    }

    openActionsPopup($event, point: ControlPoint) {
        this.popupOpenerElement = $event.target;
        this.popupProvider.setTemplate(this.actionsOutlet);
        this.popupActive = true;
        this.selectedPoint = point;
        this.popupPositionTop = $event.positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedPoint = null;
        }
    }

    deleteControlPointAccept = () => {
        this.closeDeleteControlPointDialog();
        this.deleteControlPoint(this.selectedPoint.id);
    };

    closeDeleteControlPointDialog = () => {
        this.isDeletePopupOpen = false;
        this.popupProvider.setTemplate(null);
    };

    deleteControlPointDialog(e: Event, point: ControlPoint) {
        this.closeActionsPopup(e);
        const { body } = this.textsPlumes.popupDelete;
        this.popupDeleteTexts.body = body.replace('%s', point.name);
        this.isDeletePopupOpen = true;
        this.popupProvider.setTemplate(this.popupDeleteOutlet);
        this.selectedPoint = point;
    }

    onScroll(e: Event) {
        this.closeActionsPopup(e);
    }
}
