import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityStoreModule } from '@cityair/modules/reports/entity-store.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { TextareaModule } from '@cityair/components/textarea/textarea.module';
import { SearchInputBasicModule } from '@libs/shared-ui/components/search-input-basic/search-input-basic.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';
import { BaseChartDirective } from 'ng2-charts';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';

import { MmtMapSelectorComponentModule } from '@cityair/components/mmt-map-selector/mmt-map-selector.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { analysisEventsRoutes } from '@cityair/modules/analysis-events/analysis-routing';
import { AnalysisEventsMainComponent } from '@cityair/modules/analysis-events/components/analysis-events-main/analysis-events-main.component';
import { AnalysisEventsRegisterComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-register.component';
import { AnalysisEventsEffects } from '@cityair/modules/analysis-events/store/effects';
import { analysisEventsReducer } from '@cityair/modules/analysis-events/store/reducers';
import { AnalysisEventsDetailsComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/analysis-events-details.component';
import { LineChartComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/line-chart/line-chart.component';
import { BarChartComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/bar-chart/bar-chart.component';
import { CumLineChartComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/cum-line-chart/cum-line-chart.component';
import { IconsModule } from '@libs/shared-ui/components/icons/icons.module';
import { AreaChartComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/area-chart/area-chart.component';
import { RadarChartModule } from '@libs/shared-ui/components/charts/radar-chart/radar-chart.module';
import { InfoCorrelationPopupModule } from '@libs/shared-ui/components/info-correlation-popup/info-correlation-popup.module';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';
import { FilterDataListComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/filter-data-list/filter-data-list.component';
import { BasicDateRangeModule } from '@libs/shared-ui/components/basic-date-range/basic-date-range.module';
import { DateRangeSelectorComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/date-range-selector/date-range-selector.component';

@NgModule({
    declarations: [
        AnalysisEventsMainComponent,
        AnalysisEventsRegisterComponent,
        AnalysisEventsDetailsComponent,
        LineChartComponent,
        BarChartComponent,
        CumLineChartComponent,
        AreaChartComponent,
        FilterDataListComponent,
        DateRangeSelectorComponent,
    ],
    exports: [],
    imports: [
        RouterModule,
        RouterModule.forChild(analysisEventsRoutes),
        CommonModule,
        StoreModule.forFeature('analysisEvents', analysisEventsReducer),
        EffectsModule.forFeature([AnalysisEventsEffects]),
        EntityStoreModule,
        PageTabsModule,
        InputDropdownModule,
        InputDateRangeModule,
        InputModule,
        LoadersModule,
        CrossButtonModule,
        DirectivesModule,
        SelectboxRadioModule,
        PopupAskModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        MmtMapSelectorComponentModule,
        PipesModule,
        BaseChartDirective,
        SwitchItemModule,
        TextareaModule,
        SearchInputBasicModule,
        IconsModule,
        RadarChartModule,
        SortListItemModule,
        SearchResultPlaceholderModule,
        InfoCorrelationPopupModule,
        ItemRadioModule,
        TooltipsModule,
        BasicDateRangeModule,
        CalendarMonthsModule,
    ],
})
export class AnalysisEventsModule {}
