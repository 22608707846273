import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsMainComponent } from '@cityair/modules/settings/components/settings-main/settings-main.component';
import { SETTINGS_INIT_PAGE, SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import { SystemSettingsComponent } from '@cityair/modules/settings/components/system-settings/system-settings.component';
import { KeysComponent } from '@cityair/modules/settings/components/keys/keys.component';
import { UsersComponent } from '@cityair/modules/settings/components/users/users.component';
import { SettingsEditUser } from '@cityair/modules/settings/components/users/edit-user/edit-user.component';
import { SettingsGroupComponent } from '@cityair/modules/settings/components/settings-group/settings-group.component';
import { MapSettingsComponent } from '@cityair/modules/settings/components/settings-group/map-settings/map-settings.component';
import { StartModuleSettingsComponent } from '@cityair/modules/settings/components/settings-group/start-module-settings/start-module-settings.component';
import { CoefficientGroupComponent } from '@cityair/modules/settings/components/settings-group/coefficient-group/coefficient-group.component';

export const settingsRoutes: Routes = [
    {
        path: '',
        component: SettingsMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: SETTINGS_INIT_PAGE,
            },
            {
                path: SETTINGS_PAGES.system,
                component: SystemSettingsComponent,
            },
            {
                path: SETTINGS_PAGES.keys,
                component: KeysComponent,
            },
            {
                path: SETTINGS_PAGES.users,
                component: UsersComponent,
                children: [
                    {
                        path: SETTINGS_PAGES.add,
                        component: SettingsEditUser,
                    },
                    {
                        path: `${SETTINGS_PAGES.edit}/:id`,
                        component: SettingsEditUser,
                    },
                ],
            },
            {
                path: SETTINGS_PAGES.groupSettings,
                component: SettingsGroupComponent,
                children: [
                    {
                        path: SETTINGS_PAGES.groupSettingsMap,
                        component: MapSettingsComponent,
                    },
                    {
                        path: SETTINGS_PAGES.groupSettingsCoef,
                        component: CoefficientGroupComponent,
                    },
                    {
                        path: SETTINGS_PAGES.groupSettingsStartModule,
                        component: StartModuleSettingsComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(settingsRoutes)],
    exports: [RouterModule],
})
export class SettingsRoutingModule {}
