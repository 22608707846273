<div class="counter-wrapper">
    <div class="item">
        <div class="status">
            <span class="status-text">{{ translateText.totalReports }}: </span>
            <span class="number">{{ data?.length }}</span>
        </div>
    </div>
    <div class="item" *ngFor="let item of counterObj">
        <div class="status" *ngIf="item.key !== reportStatus.READY">
            <span class="dot"
                ><status-dot-svg [color]="colors[item.key]" [width]="10"></status-dot-svg
            ></span>
            <span class="status-text">{{ translateText[item.key] }}: </span>
            <span class="number">{{ item.value }}</span>
        </div>
    </div>
</div>
