import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComfortConditionSettings } from '@libs/common/models/feature-config';
import { Post } from '@cityair/modules/indoor/services/api';

@Component({
    selector: 'indoor-settings',
    templateUrl: './indoor-settings.component.html',
    styleUrls: ['./indoor-settings.component.less'],
})
export class IndoorSettingsComponent implements OnInit {
    @Input() data: ComfortConditionSettings;
    @Input() points: Post[];
    @Output() closeConfig = new EventEmitter<void>();

    days: {
        name: string;
        active: boolean;
    }[];

    hoursSlider = {
        handleMinPosition: 30.43,
        minPositionName: ``,
        handleMaxPosition: 73.91,
        maxPositionName: ``,
    };

    temperatureSlider = {
        handleMinPosition: 41.38,
        handleMaxPosition: 65.52,
        currentMinValue: 18,
        currentMaxValue: 25,
        minValue: 5,
        maxValue: 35,
        measure: '°C',
        colors: ['#62C6DC', '#ADD21A', '#FFCC32', '#FF7344'],
    };

    humiditySlider = {
        handleMinPosition: 14,
        handleMaxPosition: 75,
        currentMinValue: 40,
        currentMaxValue: 50,
        minValue: 0,
        maxValue: 100,
        measure: '%',
        colors: ['#6097F3', '#8DD9FF', '#A25AFF'],
    };

    CO2Slider = {
        handleMinPosition: 40,
        currentMinValue: 600,
        minValue: 440,
        maxValue: 2000,
        measure: 'ppm',
        colors: [
            '#8CB917',
            '#A2C618',
            '#BEC617',
            '#FFCC32',
            '#FFA33B',
            '#FF7344',
            '#FF494B',
            '#D63B50',
            '#AC2D55',
            '#821D5A',
        ],
    };

    selectedRooms;

    ngOnInit() {
        this.selectedRooms = this.points.map((p) => p.name);

        const _d = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        this.days = this.data.WorkDays.map((active, i) => ({
            name: _d[i],
            active,
        }));

        this.hoursSlider.minPositionName = `${this.data.WorkHoursBegin} AM`;
        this.hoursSlider.maxPositionName = `${this.data.WorkHoursEnd - 12} PM`;

        this.temperatureSlider.currentMinValue = this.data.TemperatureMin;
        this.temperatureSlider.currentMaxValue = this.data.TemperatureMax;

        this.humiditySlider.currentMinValue = this.data.HumidityMin;
        this.humiditySlider.currentMaxValue = this.data.HumidityMax;

        this.CO2Slider.currentMinValue = this.data.Co2Max;
    }

    removeFromList(index: number) {
        this.selectedRooms.splice(index, 1);
    }
}
