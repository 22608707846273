import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ModelProps } from '@cityair/config';
import { TEXTS } from '@libs/common/texts/texts';
import { selectIsSidebarOpen } from '@cityair/modules/core/store/selectors';
import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import {
    setForecastMeasurement,
    toggleDisplayStationOnMap,
    toggleLayerOnMap,
    updateDateRange,
} from '../../store/actions';
import {
    currentForecastMmt,
    getStations,
    initCalendarData,
    selectDateRange,
    selectIsHiddenLayerOnMap,
    selectSpeciesList,
    showLayerOnMap,
    selectIsDisplayStationOnMap,
} from '../../store/selectors';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import {
    DEMO_FORECAST_METEO_GROUPS,
    METEO_MMTS_FORECAST,
} from '@libs/common/consts/demo-forecast-meteo-groups';
import { DateRange } from '../../models';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';

@Component({
    selector: 'forecast-top-elements',
    templateUrl: 'forecast-top-elements.html',
    styleUrls: ['forecast-top-elements.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastTopElements implements OnInit, OnDestroy {
    NAMES = TEXTS.NAMES;
    onDestroy$ = new Subject<void>();
    textTitleMapControl = TEXTS.PLUMES.forecastOn + ':';
    LayerButtonIconType = LayerButtonIconType;
    currentForecastMmt = currentForecastMmt;
    selectIsHiddenLayerOnMap = selectIsHiddenLayerOnMap;
    selectDateRange = selectDateRange;
    selectSpeciesList = selectSpeciesList;
    selectIsSidebarOpen = selectIsSidebarOpen;
    getStations = getStations;
    textsSwitchButton = TEXTS.PLUMES.switchPostButton;
    textOnMap = TEXTS.MAP_CONTROL.on;
    isActiveForecast: boolean;
    dateRange: DateRange;
    minDate: any;
    maxDate: any;
    meteoMmts: string[];
    isShowPins: boolean;

    constructor(
        readonly authService: AuthService,
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store
            .select(showLayerOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isActive) => {
                this.isActiveForecast = isActive;
                this._changeDetectorRef.markForCheck();
            });
        this.store
            .select(initCalendarData)
            .pipe(
                filter((data) => !!data?.dateRange),
                take(1)
            )
            .subscribe((data) => {
                this.dateRange = data.dateRange;
                this.minDate = data.minDate;
                this.maxDate = data.maxDate;
                this._changeDetectorRef.markForCheck();
            });
        this.store
            .select(selectGroupId)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((groupId) => !!groupId)
            )
            .subscribe((groupId) => {
                if (DEMO_FORECAST_METEO_GROUPS.includes(groupId)) {
                    this.meteoMmts = METEO_MMTS_FORECAST;
                }

                this._changeDetectorRef.markForCheck();
            });
        store
            .select(selectIsDisplayStationOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.isShowPins = data;
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    updateDateRange({ begin, end }) {
        this.store.dispatch(updateDateRange({ startDate: begin, finishDate: end }));
    }

    toggleForecast() {
        this.store.dispatch(toggleLayerOnMap({ payload: !this.isActiveForecast }));
    }

    updatePropsMmt(data: ModelProps) {
        this.store.dispatch(setForecastMeasurement({ payload: data.measure }));
    }

    togglePostPin() {
        this.store.dispatch(toggleDisplayStationOnMap({ payload: !this.isShowPins }));
    }
}
