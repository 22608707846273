import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isRU } from '@libs/common/texts/texts';

import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import { environment } from 'environments/environment';
import { ControlPoint } from './models';
import { CONTROL_POINT_ENTITY_STORE_KEY } from '../../constants';

const PLUMES_API_URL = `${environment.vanga_api_url}/plumes/v1`;

@Injectable({
    providedIn: 'root',
})
export class ControlPointDataService extends DefaultDataService<ControlPoint> {
    private lang = isRU ? 'ru' : 'en';
    private urlPath = this.urlGenerator.collectionResource(CONTROL_POINT_ENTITY_STORE_KEY, '');

    constructor(
        http: HttpClient,
        private urlGenerator: HttpUrlGenerator,
        private vangaAuthService: VangaAuthService
    ) {
        super(CONTROL_POINT_ENTITY_STORE_KEY, http, urlGenerator, { root: PLUMES_API_URL });
    }

    getAll(): Observable<ControlPoint[]> {
        throw new Error('not implemented');
    }

    add(data: ControlPoint): Observable<ControlPoint> {
        const httpOptions = this.getHttpOptions();

        return this.http
            .post(this.urlPath, data, httpOptions)
            .pipe(map((response: any) => response as ControlPoint));
    }

    update(data: Update<ControlPoint>): Observable<ControlPoint> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + data.id + '/';

        return this.http
            .patch(urlPath, data.changes, httpOptions)
            .pipe(map((response: any) => response as ControlPoint));
    }

    delete(key: number | string): Observable<number | string> {
        const httpOptions = this.getHttpOptions();
        const urlPath = this.urlPath + key + '/';

        return this.http
            .delete(urlPath, httpOptions)
            .pipe(map((response: any) => response as number | string));
    }

    getWithQuery(params: string | QueryParams): Observable<ControlPoint[]> {
        const httpOptions = this.getHttpOptions(params);
        const urlPath = this.urlPath;

        return this.http
            .get(urlPath, httpOptions)
            .pipe(map((response: any) => response as ControlPoint[]));
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }
}
