import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { formatNumber } from '@angular/common';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { customNumberFormat, isFalseNumber } from '@libs/common/utils/utils';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { ColorZone } from '@libs/common/types/color-zone';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { getColorFromZone } from '@cityair/utils/utils';
import { Source } from '@cityair/modules/plumes/services/run/models';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common/consts/demo-impact-groups';

const options: ChartConfiguration['options'] = {
    responsive: true,
    animation: false,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            borderWidth: 1,
            bodyColor: '#000',
            titleColor: '#000',
            displayColors: true,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            callbacks: {
                label: (tooltipItem) => `${tooltipItem.formattedValue}%`,
                title: (tooltipItem) => {
                    let label = tooltipItem[0].label;
                    if (label.length > 14) {
                        label = label.substring(0, 12);
                        label += '...';
                        return label;
                    } else {
                        return `${tooltipItem[0].label}`;
                    }
                },
            },
        },
    },
};
@Component({
    selector: 'cityair-control-point-plume-pin',
    templateUrl: './control-point-plume-pin.component.html',
    styleUrls: ['./control-point-plume-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPointPlumePinComponent implements OnChanges {
    isFalseNumber = isFalseNumber;
    texts = TEXTS.PLUMES;
    public textScheme = TEXTS.MEASURES_SCHEME;
    public textNames = TEXTS.NAMES;
    @Input() point: ControlPoint;
    @Input() sources: { sources: Source[]; order: number[] };
    @Input() numberAfterDot?: number = 0;
    @Input() selected: boolean;
    @Input() decimalFormat = false; // обрезать нули после запятой
    @Input() draggable = false;
    @Input() isExpand: boolean;
    @Input() currentIndex: number;
    @Input() mmtData: { scheme: MeasureScheme; mmt: string; zone: ColorZone };
    @Input() isCollapse = false;

    @Output() setSelect = new EventEmitter<ControlPoint>();
    @Output() setExpand = new EventEmitter<string>();

    public backgroundColor = [];
    public defaultSourcesColor = CONTRIBUTIONS_COLORS_SOURCES_ORDER;
    public options = options;
    public labels: string[] = [];
    public datasets: ChartDataset<'doughnut', number[]>[] = [];
    public displayValue: number | string;
    public hasNoData: boolean;
    public displayData = [];
    public data;
    public color = '#eeee';
    public currentTime: string;
    public currentLang = LANGUAGE;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.currentIndex || changes?.point) {
            const { numberAfterDot } = this;
            let value = null;
            if (!isFalseNumber(this.currentIndex) && this.mmtData?.mmt && this.point) {
                const arr = this.point.timeline?.[this.mmtData?.mmt];
                value = arr ? arr[this.currentIndex] : null;
                this.currentTime = this.point.timeline?.date[this.currentIndex];
                this.color = getColorFromZone(this.mmtData?.zone, value);
            }
            const hasNoData = isFalseNumber(value);
            const formatValue = formatNumber(
                value,
                LANGUAGE,
                '1.' + numberAfterDot + '-' + numberAfterDot
            );
            this.displayValue = hasNoData
                ? '–'
                : customNumberFormat(formatValue, numberAfterDot, LANGUAGE);
            if (this.point?.contributions) {
                if (!isFalseNumber(this.currentIndex) && this.mmtData?.mmt && this.point) {
                    const arr = this.point.contributions?.[this.mmtData?.mmt];
                    if (arr) {
                        const keys = Object.keys(arr);
                        const result = {};
                        keys.forEach((key) => {
                            result[key] = arr[key][this.currentIndex];
                        });

                        this.data = this.prepareDataContributions(result);
                        this.labels = this.data.map((item) => item?.name);
                        const datasets = this.data.map((item) => Math.round(item?.value));
                        this.backgroundColor = this.data.map((item) => item?.color);
                        this.datasets = datasets?.length
                            ? [
                                  {
                                      data: datasets,
                                      backgroundColor: this.backgroundColor,
                                      hoverBackgroundColor: this.backgroundColor,
                                      hoverBorderColor: 'transparent',
                                  },
                              ]
                            : null;
                    }
                }
            }
        }
    }

    toggleContent($event) {
        if (this.isExpand) {
            this.setExpand.emit(null);
        } else {
            this.setExpand.emit(this.point.id);
        }
    }

    select() {
        this.setSelect.emit(this.point);
    }

    private prepareDataContributions(data) {
        const result = [];
        for (const k in data) {
            if (data.hasOwnProperty(k)) {
                const source = this.sources?.sources?.find((item) => item.id === Number(k));
                if (source) {
                    const colorIndex = this.sources?.order.findIndex((v) => v === source.id);
                    result.push({
                        name: source.name,
                        value: Math.round(data[k]),
                        color:
                            this.defaultSourcesColor[colorIndex] ??
                            this.backgroundColor[colorIndex],
                    });
                }
            }
        }
        const dataResult = result?.sort((a, b) => -(a.value - b.value));
        dataResult.length = dataResult.length > 5 ? 5 : dataResult.length;
        const sum = dataResult.reduce((a, b) => a + (b.value || 0), 0);

        if (sum && sum !== 100) {
            dataResult.push({
                name: this.texts.otherChartLabel,
                value: 100 - sum,
                color: '#849CFF',
            });
        }

        return dataResult.filter((v) => v.value > 0);
    }
}
