import { Injectable } from '@angular/core';
import { EntityAction, EntityActionFactory, EntityOp, QueryParams } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { AppState } from '@cityair/modules/core/store/selectors';
import {
    CommonActions,
    mapLoaded,
    refreshVangaToken,
    addAlert,
    doNothing,
} from '@cityair/modules/core/store/actions';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';

import { INIT_DAYS_INTERVAL } from '../constants';
import { ControlPointForecast, DateRange, ForecastConfig, Station } from '../models';
import {
    currentForecastMmt,
    getParamsControlPoint,
    getParamsStation,
    isActiveForecast,
    selectActivePoint,
    selectActiveStation,
    selectControlPointsForecast,
    selectDateRange,
    selectDatesForecast,
    selectDatesStationForecast,
    selectForecasts,
} from './selectors';
import { ForecastState } from './reducers';
import {
    addControlPointError,
    ForecastActions,
    onUpdateControlPoint,
    setActivePoint,
    setActiveStation as setActiveForecastStation,
    setActiveStation,
    setAuthError,
    setChartData,
    setCurrentConfig,
    setErrorLoadControlList,
    setIsHiddenLayerOnMap,
    setToken,
    datesUpdate,
    toggleModule,
    updateAllData,
    setDatesControlPoints,
    toggleLayerOnMap as toggleForecastLayerOnMap,
} from './actions';
import { Feature, Timeseries } from '@libs/shared-ui/components/timeline-panel/models/core';
import { selectExtConfig, selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { HttpStatusCode } from '@angular/common/http';
import { transformMeteoForecastToBasicMmt } from '@cityair/modules/forecast/utils/transform-meteo-mmt';
import { METEO_MMTS_FORECAST } from '@libs/common/consts/demo-forecast-meteo-groups';

function transformToDataSeriesNew(point: ControlPointForecast, mmt: string): Feature[] {
    const currentMmt = transformMeteoForecastToBasicMmt(mmt);

    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [point.lon, point.lat],
            },
            properties: {
                uuid: point.id,
                name: point.name,
                name_ru: point.name,
                timeseries: {
                    date: point.timeseries?.indexes,
                    [currentMmt]: point.timeseries?.values[mmt],
                } as Timeseries,
                obj: point.obj,
                has_any_timeseries: !!point.timeseries,
            },
        },
    ];
}
function transformForecastStation(
    station: Station,
    mmt: string,
    dates: string[],
    datesStation
): Feature[] {
    if (station) {
        const currentMmt = transformMeteoForecastToBasicMmt(mmt);
        let data = [...station.data.measurements[currentMmt]?.values];
        if (currentMmt !== mmt) {
            data = [];
            dates.forEach((v) => {
                const index = datesStation.findIndex((k) => k === v);
                if (index !== -1) {
                    data.push(station.data.measurements[currentMmt]?.values[index]);
                } else {
                    data.push(null);
                }
            });
        }

        return [
            {
                type: 'Feature',
                geometry: station.geometry,
                properties: {
                    uuid: station.id,
                    city_id: `${station.ancestor?.id}`,
                    name: station.name,
                    name_ru: station.name,
                    timeseries: {
                        date: dates,
                        [currentMmt]: data,
                    } as Timeseries,
                    obj: 'station',
                    has_any_timeseries: !!Object.keys(station.data?.measurements).length,
                },
            },
        ];
    }

    return [];
}

const STATION_MANY_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<Station>(
    'Station',
    EntityOp.QUERY_MANY_SUCCESS
).type;
const STATION_MANY_LOADED_ERROR_ACTION = new EntityActionFactory().create<Station>(
    'Station',
    EntityOp.QUERY_MANY_ERROR
).type;

const CONTROL_POINT_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<Station>(
    'ControlPoint',
    EntityOp.QUERY_MANY_SUCCESS
).type;
const CONTROL_POINT_LOADED_ERROR_ACTION = new EntityActionFactory().create<ControlPointForecast>(
    'ControlPoint',
    EntityOp.QUERY_MANY_ERROR
).type;
const CONTROL_POINT_ADD_ONE_ERROR = new EntityActionFactory().create<ControlPointForecast>(
    'ControlPoint',
    EntityOp.SAVE_ADD_ONE_ERROR
).type;
export const CONTROL_POINT_SAVE_ADD_ONE_SUCCESS =
    new EntityActionFactory().create<ControlPointForecast>(
        'ControlPoint',
        EntityOp.SAVE_ADD_ONE_SUCCESS
    ).type;
const CONTROL_POINT_UPDATE_ONE_ERROR = new EntityActionFactory().create<ControlPointForecast>(
    'ControlPoint',
    EntityOp.SAVE_UPDATE_ONE_ERROR
).type;
const CONTROL_POINT_UPDATE_ONE_SUCCESS = new EntityActionFactory().create<ControlPointForecast>(
    'ControlPoint',
    EntityOp.SAVE_UPDATE_ONE_SUCCESS
).type;
const CONTROL_POINT_DELETE_ONE_SUCCESS = new EntityActionFactory().create<ControlPointForecast>(
    'ControlPoint',
    EntityOp.SAVE_DELETE_ONE_SUCCESS
).type;

const FORECAST_CONFIG_MANY_LOADED_ACTION_SUCCESS = new EntityActionFactory().create<ForecastConfig>(
    'Forecasts',
    EntityOp.QUERY_MANY_SUCCESS
).type;
const FORECAST_CONFIG_MANY_LOADED_ACTION_ERROR = new EntityActionFactory().create<ForecastConfig>(
    'Forecasts',
    EntityOp.QUERY_MANY_ERROR
).type;
export function onLoadStationAction(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>('Station', EntityOp.QUERY_MANY, params);
}
export function onLoadControlPointAction(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>(
        'ControlPoint',
        EntityOp.QUERY_MANY,
        params
    );
}
export function onLoadForecastConfigAction(params: QueryParams) {
    return new EntityActionFactory().create<QueryParams>('Forecasts', EntityOp.QUERY_MANY, params);
}
@Injectable()
export class ForecastEffects {
    constructor(
        private actions$: Actions,
        private storeCore$: Store<AppState>,
        private forecastCore$: Store<ForecastState>
    ) {}

    setConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FORECAST_CONFIG_MANY_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.forecastCore$.select(selectDateRange)),
            switchMap(([action, currentDate]) => {
                const actions = [];
                actions.push(setToken({ payload: true }));
                // @ts-ignore
                const response = action.payload.data as ForecastConfig[];
                if (currentDate === null) {
                    const dateRange = this.getInitDateRange(response) as DateRange;
                    const config = this.getConfigByDateRange(response, dateRange);
                    actions.push(setCurrentConfig({ payload: { config, dateRange } }));
                }

                return actions;
            })
        )
    );

    showForecastLayerOnMapLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapLoaded),
            switchMap(() => this.forecastCore$.select(isActiveForecast)),
            map((isActive) => toggleForecastLayerOnMap({ payload: isActive }))
        )
    );

    updateForecastDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.updateDateRange),
            withLatestFrom(
                this.forecastCore$.select(isActiveForecast),
                this.forecastCore$.select(selectForecasts)
            ),
            filter(([_, isActive]) => !!isActive),
            switchMap(([action, isActive, configs]) => {
                const dateRange = action as DateRange;
                const config = this.getConfigByDateRange(configs, dateRange);
                return [setCurrentConfig({ payload: { config, dateRange } })];
            })
        )
    );

    setCurrentConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setCurrentConfig, ForecastActions.setForecastMeasurement),
            map((action) => updateAllData())
        )
    );

    toggleForecastLayerForMeteoMmt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setForecastMeasurement),
            switchMap((action) => {
                // @ts-ignore
                const mmt = action?.payload;
                if (METEO_MMTS_FORECAST.includes(mmt)) {
                    return [setIsHiddenLayerOnMap({ payload: true })];
                } else {
                    return [setIsHiddenLayerOnMap({ payload: false })];
                }
            })
        )
    );

    noConfigError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setCurrentConfig),
            switchMap((action) => {
                // @ts-ignore
                if (action?.payload?.config === null) {
                    return [
                        setErrorLoadControlList({ payload: { messages: 'No config' } }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'no_forecast_config',
                            positionX: 'left',
                            positionY: 'bottom',
                            iconClass: 'error',
                            duration: 5000,
                            showCloseIcon: true,
                            size: 'lg',
                        }),
                    ];
                }
                return [];
            })
        )
    );

    loadControlPointSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.forecastCore$.select(selectControlPointsForecast)),
            switchMap(([action, controlPoints]) => {
                const actions = [];
                const dates = controlPoints[0]?.timeseries?.indexes ?? [];
                actions.push(setDatesControlPoints({ payload: dates }));
                actions.push(datesUpdate({ payload: dates }));
                return actions;
            })
        )
    );

    closeChartActiveControlPointWasDelete = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_DELETE_ONE_SUCCESS),
            withLatestFrom(this.forecastCore$.select(selectActivePoint)),
            filter(([action, active]) => active !== null),
            switchMap(([action, params]) => [setActivePoint({ payload: null })])
        )
    );

    errorLoadControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_LOADED_ERROR_ACTION, FORECAST_CONFIG_MANY_LOADED_ACTION_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                const status = action.payload?.data?.error?.error?.status;
                if (status && status === HttpStatusCode.Unauthorized) {
                    actions.push(setAuthError({ payload: true }));
                } else {
                    actions.push(
                        setErrorLoadControlList({ payload: action.payload?.data?.error?.error })
                    );
                }
                return actions;
            })
        )
    );

    authErrorLoadControlPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setAuthError),
            take(1),
            switchMap((action: EntityAction) => {
                const actions = [];
                if (action.payload) {
                    actions.push(refreshVangaToken());
                    actions.push(onUpdateControlPoint({ payload: true }));
                }
                return actions;
            })
        )
    );

    errorSaveControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_ADD_ONE_ERROR, CONTROL_POINT_UPDATE_ONE_ERROR),
            switchMap((action: EntityAction) => {
                const actions = [];
                let data = null;
                if (action.payload?.data?.error?.error?.status === HttpStatusCode.BadRequest) {
                    data = action.payload?.data.error;
                }
                if (action.payload?.data?.error?.error?.status === HttpStatusCode.Unauthorized) {
                    actions.push(refreshVangaToken());
                    data = action.payload?.data.error;
                }
                actions.push(addControlPointError({ payload: data }));
                return actions;
            })
        )
    );

    saveControlPointSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_SAVE_ADD_ONE_SUCCESS, CONTROL_POINT_UPDATE_ONE_SUCCESS),
            withLatestFrom(this.forecastCore$.select(getParamsControlPoint)),
            switchMap(([action, params]) => [this.loadControlPointData(params)])
        )
    );

    updateVangaToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.VangaTokenUpdated, ForecastActions.updateForecastConfig),
            withLatestFrom(
                this.storeCore$.select(selectGroupId),
                this.forecastCore$.select(isActiveForecast)
            ),
            filter(([_, groupId, isActive]) => isActive),
            switchMap(([action, groupId, isActive]) => {
                const actions = [];
                if (groupId) {
                    actions.push(this.loadForecastConfig(groupId));
                }
                return actions;
            })
        )
    );

    updateData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.updateAllData),
            withLatestFrom(
                this.forecastCore$.select(getParamsControlPoint),
                this.forecastCore$.select(getParamsStation)
            ),
            switchMap(([action, controlPointParams, stationParams]) => [
                this.loadStationData(stationParams),
                this.loadControlPointData(controlPointParams),
            ])
        )
    );

    setActiveControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setActivePoint),
            withLatestFrom(
                this.forecastCore$.select(selectActiveStation),
                this.forecastCore$.select(selectActivePoint)
            ),
            filter(([_, activeStation, activePoint]) => !!activePoint && activeStation !== null),
            map(() => setActiveStation({ payload: null }))
        )
    );

    setActiveStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.setActiveStation),
            withLatestFrom(
                this.forecastCore$.select(selectActiveStation),
                this.forecastCore$.select(selectActivePoint)
            ),
            filter(([_, activeStation, activePoint]) => !!activeStation && activePoint !== null),
            map(() => setActivePoint({ payload: null }))
        )
    );

    updateActiveStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(STATION_MANY_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.storeCore$.select(selectActiveStation)),
            filter(([_, activeStation]) => activeStation !== null),
            map(([{ payload }, activeStation]: [EntityAction<Station[]>, Station]) => {
                const { id } = activeStation;
                const { data } = payload;
                const updatedControlPoint = data.find((p) => p.id === id);
                return setActiveStation({ payload: updatedControlPoint || null });
            })
        )
    );

    checkForecastIsAvailable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.GroupInfoLoaded),
            withLatestFrom(this.storeCore$.select(selectExtConfig)),
            filter(([_, extConfig]) => !extConfig?.showForecastModule),
            map(() => toggleModule({ payload: false }))
        )
    );

    updateActiveControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CONTROL_POINT_LOADED_ACTION_SUCCESS),
            withLatestFrom(this.storeCore$.select(selectActivePoint)),
            filter(([_, activePoint]) => activePoint !== null),
            map(
                ([{ payload }, activePoint]: [
                    EntityAction<ControlPointForecast[]>,
                    ControlPointForecast
                ]) => {
                    const { id } = activePoint;
                    const { data } = payload;

                    const updatedControlPoint = data.find((p) => p.id === id);

                    return setActivePoint({ payload: updatedControlPoint || null });
                }
            )
        )
    );

    closeChartClearActive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter((props) => !props.payload),
            withLatestFrom(
                this.forecastCore$.select(isActiveForecast),
                this.forecastCore$.select(selectActivePoint),
                this.forecastCore$.select(selectActiveStation)
            ),
            filter(([_, isActive, activePoint, activeStation]) => isActive),
            switchMap(([action, isActive, activePoint, activeStation]) => {
                const actions = [];
                if (activePoint) {
                    actions.push(setActivePoint({ payload: null }));
                    actions.push(setChartData({ payload: [] }));
                } else if (activeStation) {
                    actions.push(setActiveStation({ payload: null }));
                    actions.push(setChartData({ payload: [] }));
                }

                return actions;
            })
        )
    );

    updateForecast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ForecastActions.updateStation),
            withLatestFrom(this.forecastCore$.select(getParamsStation)),
            switchMap(([action, stationParams]) => [this.loadStationData(stationParams)])
        )
    );

    createFeatureFromActivePoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActivePoint),
            withLatestFrom(this.forecastCore$.select(currentForecastMmt)),
            filter(([props, mmt]) => props?.payload?.timeseries?.values[mmt]),
            switchMap(([props, mmt]) => [
                setChartData({ payload: transformToDataSeriesNew(props.payload, mmt) }),
            ])
        )
    );

    createFeatureFromActiveForecastStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveForecastStation),
            withLatestFrom(
                this.forecastCore$.select(currentForecastMmt),
                this.forecastCore$.select(selectDatesForecast),
                this.forecastCore$.select(selectDatesStationForecast)
            ),
            map(([props, mmt, dates, datesStation]) => {
                const data = transformForecastStation(props.payload, mmt, dates, datesStation);
                if (data?.length) {
                    return setChartData({
                        payload: transformForecastStation(props.payload, mmt, dates, datesStation),
                    });
                }
                return doNothing();
            })
        )
    );

    setChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setChartData),
            map((props) => onIsEnabledChart({ payload: props.payload.length > 0 }))
        )
    );

    private loadStationData(params) {
        if (params) {
            return onLoadStationAction(params);
        }

        return { type: 'empty' };
    }

    private loadControlPointData(params) {
        if (params) {
            return onLoadControlPointAction(params);
        }

        return { type: 'empty' };
    }

    private loadForecastConfig(groupId) {
        if (groupId) {
            const params: QueryParams = {
                group_id: groupId.toString(),
            };
            return onLoadForecastConfigAction(params);
        }

        return { type: 'empty' };
    }

    private getInitDateRange(data: ForecastConfig[]) {
        const start = moment().startOf('day').valueOf();
        const end = moment().startOf('day').add(INIT_DAYS_INTERVAL, 'days').valueOf();
        if (!data.length) {
            return {
                startDate: start,
                finishDate: end,
            };
        }
        let max = data[0].end;
        data.forEach(function (v) {
            max = new Date(v.end) > new Date(max) ? v.end : max;
        });

        const startM = moment(start);
        const endM = moment(max);
        const diffDays = endM.diff(startM, 'days');

        if (diffDays > 0 && diffDays < INIT_DAYS_INTERVAL) {
            return {
                startDate: start,
                finishDate: end,
            };
        }

        return {
            startDate: moment(max).subtract(3, 'days').startOf('day').valueOf(),
            finishDate: moment(max).valueOf(),
        };
    }

    private getConfigByDateRange(response: ForecastConfig[], dateRange: DateRange): ForecastConfig {
        if (response.length && dateRange) {
            let currentConfig = response[0];
            let max = response[0].end;
            let min = response[0].start;
            const endTime = moment(dateRange.finishDate);
            for (let i = 0; i < response.length; i++) {
                if (
                    !response[i].meteo &&
                    endTime.isBetween(response[i].start, response[i].end, undefined, '[]')
                ) {
                    currentConfig = response[i];
                }
                max = new Date(response[i].end) >= new Date(max) ? response[i].end : max;
                min = new Date(response[i].start) <= new Date(min) ? response[i].start : min;
            }
            return {
                ...currentConfig,
                domain: { ...currentConfig.domain, start: min, end: max },
            };
        }

        return null;
    }
}
