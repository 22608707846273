import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ANALYSIS_EVENTS_INIT_PAGE, ANALYSIS_EVENTS_PAGES } from './models';
import { AnalysisEventsRegisterComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-register.component';
import { AnalysisEventsMainComponent } from '@cityair/modules/analysis-events/components/analysis-events-main/analysis-events-main.component';
import { AnalysisEventsDetailsComponent } from '@cityair/modules/analysis-events/components/analysis-events-register/analysis-events-details/analysis-events-details.component';

export const analysisEventsRoutes: Routes = [
    {
        path: '',
        component: AnalysisEventsMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: ANALYSIS_EVENTS_INIT_PAGE,
            },
            {
                path: ANALYSIS_EVENTS_PAGES.registerEvents,
                component: AnalysisEventsRegisterComponent,
            },
            {
                path: ANALYSIS_EVENTS_PAGES.registerEvents + '/:id',
                component: AnalysisEventsDetailsComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(analysisEventsRoutes)],
    exports: [RouterModule],
})
export class AnalysisEventsRoutingModule {}
