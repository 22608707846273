import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { indoorModuleConfig } from '@cityair/modules/indoor/module-config';
import { reportsModuleConfig } from '@cityair/modules/reports/module-config';
import { forecastModuleConfig } from '@cityair/modules/forecast/module-config';
import { defaultModuleConfig } from '@cityair/namespace';
import { plumeModuleConfig } from '@cityair/modules/plumes/module-config';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import { GroupFeaturesService } from './services/group-features/group-features.service';
import { closeSidebar, openSidebar } from './store/actions';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { AVAILABLE_MOBILE_PAGES } from '@libs/common/consts/available-mobile-pages';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { indoorWidgetModuleConfig } from '@cityair/modules/indoor-widget/module-config';
import { setModuleConfig } from './store/module-page-config/module-page-config.feature';
import { analysisModuleConfig } from '@cityair/modules/analysis/module-config';
import { analysisEventsModuleConfig } from '@cityair/modules/analysis-events/module-config';
import { settingsModuleConfig } from '@cityair/modules/settings/module-config';
import { globalModuleConfig } from '@cityair/components/global-map/module-config';
import { analyticsModuleConfig } from '@cityair/modules/analytics/module-config';
import { networkModuleConfig } from '@cityair/modules/network/module-config';
import { impactModuleConfig } from '@cityair/modules/impact/module-config';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    firstStart = true;

    private _pageChange$ = new BehaviorSubject<string>('');
    public pageChange$ = this._pageChange$.asObservable();

    constructor(
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
            if (this.firstStart) {
                this.checkDefaultModule();
                this.firstStart = false;
            }

            this.setModuleConfig();

            this.store.dispatch(this.getActivePage() ? openSidebar() : closeSidebar());

            this._pageChange$.next(this.getActivePage());
        });
    }

    private checkDefaultModule() {
        this.groupFeaturesService.readyBehavior$
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                const startModule = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.startModule
                );

                if (startModule && !this.getActivePage()) {
                    if (detectMobile() && !AVAILABLE_MOBILE_PAGES.includes(startModule))
                        this.goToPage(MAIN_PAGES.default);
                    else this.goToPage(startModule);
                }
            });
    }

    private setModuleConfig() {
        const page = this.getActivePage();

        switch (page) {
            case MAIN_PAGES.indoor:
                this.store.dispatch(setModuleConfig({ config: indoorModuleConfig }));
                break;
            case MAIN_PAGES.indoorWidget:
                this.store.dispatch(setModuleConfig({ config: indoorWidgetModuleConfig }));
                break;
            case MAIN_PAGES.reports:
                this.store.dispatch(setModuleConfig({ config: reportsModuleConfig }));
                break;
            case MAIN_PAGES.forecast:
                this.store.dispatch(setModuleConfig({ config: forecastModuleConfig }));
                break;
            case MAIN_PAGES.plumes:
                this.setPlumeModuleConfig();
                break;
            case MAIN_PAGES.analysis:
                this.store.dispatch(setModuleConfig({ config: analysisModuleConfig }));
                break;
            case MAIN_PAGES.analysisEvents:
                this.store.dispatch(setModuleConfig({ config: analysisEventsModuleConfig }));
                break;
            case MAIN_PAGES.settings:
                this.store.dispatch(setModuleConfig({ config: settingsModuleConfig }));
                break;
            case MAIN_PAGES.global:
                this.store.dispatch(setModuleConfig({ config: globalModuleConfig }));
                break;
            case MAIN_PAGES.analytics:
                this.store.dispatch(setModuleConfig({ config: analyticsModuleConfig }));
                break;
            case MAIN_PAGES.impact:
                this.store.dispatch(setModuleConfig({ config: impactModuleConfig }));
                break;
            case MAIN_PAGES.network:
                this.store.dispatch(setModuleConfig({ config: networkModuleConfig }));
            case MAIN_PAGES.events:
                this.store.dispatch(setModuleConfig({ config: networkModuleConfig }));
                break;
            case MAIN_PAGES.networks:
            case MAIN_PAGES.dashboard:
            case MAIN_PAGES.notifications:
            default:
                this.setDefaultModuleConfig();
                break;
        }
    }

    private setDefaultModuleConfig = () => {
        this.store.dispatch(setModuleConfig({ config: defaultModuleConfig }));
    };

    private setPlumeModuleConfig = () => {
        this.store.dispatch(setModuleConfig({ config: plumeModuleConfig }));
    };

    public isActiveRoute(page: MAIN_PAGES) {
        return this.router.url.includes(page);
    }

    public getActivePage() {
        return this.router.url.split('/')[1];
    }

    public goToPage(page: MAIN_PAGES) {
        this.router.navigate([page], {
            relativeTo: this.activatedRoute,
        });
    }
}
