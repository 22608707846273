import { Injectable } from '@angular/core';
import { currentSavedLang } from '@libs/common/utils/local-storage';

declare const window: any;

export type LangItem = {
    code: string;
    name: string;
};

export const Languages: LangItem[] = [
    {
        code: 'ru',
        name: 'Русский',
    },
    {
        code: 'en',
        name: 'English',
    },
];

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private language: string;

    constructor() {
        const lang = currentSavedLang;

        const isRU = !!~(lang || navigator.language).indexOf('ru');

        this.language = lang || (isRU ? 'ru' : 'en');

        // TODO: globals probably should be purged from everywhere
        window.JS_CP_SITE_LANG = this.language.toUpperCase();
    }

    getLanguage() {
        return this.language;
    }
}
