import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'cs-feedback-mobile-button',
    templateUrl: 'feedback-mobile-button.component.html',
    styleUrls: ['feedback-mobile-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackMobileButtonComponent {
    TEXTS = TEXTS;
}
