import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, BehaviorSubject, Subscription, merge, EMPTY } from 'rxjs';
import { map, distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';

import { TEXTS } from '@libs/common/texts/texts';
import { FormErrors } from '@libs/common/models/formErrors';

const FORM_TEXT_SAVE_KEY = 'cs_feedback_form_text';

@Component({
    selector: 'cs-feedback-form',
    templateUrl: 'feedback-form.component.html',
    styleUrls: ['feedback-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFormComponent implements OnInit, OnDestroy {
    @Input() success: Observable<void>;

    @Input() errors: Observable<FormErrors>;

    @Input() isMobile?: boolean;

    @Output() submitForm = new EventEmitter<string>();

    @Output() closeForm = new EventEmitter<void>();

    feedbackForm: UntypedFormGroup;

    subscriptions: Subscription[] = [];

    textFeedback = TEXTS.FEEDBACK;

    isLoading = false;

    private _localErrors$ = new BehaviorSubject<string>('');
    errors$: Observable<string>;

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit() {
        const savedText = sessionStorage.getItem(FORM_TEXT_SAVE_KEY) || '';

        this.feedbackForm = this.fb.group({
            message: [savedText, [Validators.required]],
        });

        const saveSub = this.feedbackForm.controls.message.valueChanges
            .pipe(startWith(savedText), distinctUntilChanged(), debounceTime(200))
            .subscribe((msg) => {
                sessionStorage.setItem(FORM_TEXT_SAVE_KEY, msg);
            });

        this.errors$ = merge(
            this.errors?.pipe(map((err) => err?.fields?.message || err?.error)) || EMPTY,
            this._localErrors$
        );

        const onErrors = this.errors$.subscribe(() => {
            this.isLoading = false;
        });

        const onSuccess = (this.success || EMPTY).subscribe(() => {
            sessionStorage.removeItem(FORM_TEXT_SAVE_KEY);
            this.onClose();
        });

        this.subscriptions.push(saveSub, onErrors, onSuccess);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    onSubmit() {
        if (this.isLoading) {
            return;
        }

        const { message } = this.feedbackForm.value;

        if (this.feedbackForm.valid) {
            this._localErrors$.next('');
            this.isLoading = true;
            this.submitForm.emit(message);
        } else if (!message) {
            this._localErrors$.next(this.textFeedback.requiredField);
        } else {
            this._localErrors$.next('');
        }
    }

    onClose() {
        this.closeForm.emit();
    }
}
