<div class="settings-wrapper">
    <div class="header">
        <div class="title-row">
            <div class="title">{{ textsSettings.title }}</div>
        </div>
        <div class="title-row">
            <div class="tabs-wrapper">
                <ca-page-tabs
                    class="page__page-tabs"
                    [tabs]="tabs"
                    [selectedTab]="getActiveTab()"
                    (showTab)="openPage($event)"
                    [isFullHeight]="true"
                ></ca-page-tabs>
            </div>
        </div>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
