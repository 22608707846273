import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMENT_MAX_LEN, FILE_NAME_MAX_LEN } from '../../../constant';
import { TEXTS } from '@libs/common/texts/texts';
import { Reports } from '../../../models';

@Component({
    selector: 'update-report-popup',
    templateUrl: './update-report.popup.component.html',
    styleUrls: ['./update-report-popup.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateReportPopupComponent implements OnDestroy, OnChanges {
    @Input() report: Reports;
    @Input() textCancel = '';
    @Input() textAccept = '';
    @Input() isLoading = false;
    @Input() showCloseIcon = true;
    @Input() mode = 'name'; // or comment

    @Output() cbAccept = new EventEmitter<Reports>();
    @Output() cbClose = new EventEmitter<void>();

    public ReportForm: UntypedFormGroup;
    public labelTexts = TEXTS.REPORTS.formCreate;
    public showErrorPopup = false;
    public title = TEXTS.REPORTS.titleRenamePopup;
    constructor(private fb: UntypedFormBuilder) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.report?.currentValue && changes.mode?.currentValue) {
            this.createForm(changes.mode.currentValue);
            if (changes.mode?.currentValue === 'comment') {
                this.title = changes.report.currentValue.comment
                    ? TEXTS.REPORTS.titleEditComment
                    : TEXTS.REPORTS.titleAddComment;
            }
        }
    }

    ngOnDestroy(): void {
        this.isLoading = false;
    }

    get formName() {
        return this.ReportForm.get('name');
    }

    get formComment() {
        return this.ReportForm.get('comment');
    }

    public getError(field: string): string {
        if (this.ReportForm) {
            if (
                this.mode === 'name' &&
                this.ReportForm.controls.name.invalid &&
                (this.ReportForm.controls.name.dirty || this.ReportForm.controls.name.touched)
            ) {
                if (this.ReportForm.controls[field].errors.required) {
                    return field === 'name'
                        ? this.labelTexts.nameError
                        : this.labelTexts.postMoError;
                }

                if (this.ReportForm.controls.name.errors.maxlength) {
                    return this.labelTexts.maxLength(
                        field,
                        this.ReportForm.controls.name.errors.maxlength.requiredLength
                    );
                }
            } else if (
                this.mode === 'comment' &&
                this.ReportForm.controls.comment.invalid &&
                (this.ReportForm.controls.comment.dirty || this.ReportForm.controls.comment.touched)
            ) {
                if (this.ReportForm.controls.comment.errors.maxlength) {
                    return this.labelTexts.maxLength(
                        field,
                        this.ReportForm.controls.comment.errors.maxlength.requiredLength
                    );
                }
            }
        }

        return '';
    }

    close() {
        this.cbClose.emit();
    }

    accept() {
        const formData = this.ReportForm.getRawValue();
        const report = Object.assign({}, this.report);
        if (this.mode === 'name') {
            report.name = formData.name;
        } else if (this.mode === 'comment') {
            report.comment = formData.comment;
        }

        this.cbAccept.emit(report);
    }

    @HostListener('window:keydown.enter', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (this.ReportForm.valid && this.ReportForm.touched && this.ReportForm.dirty) {
            this.accept();
        }
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.close();
    }

    private createForm(mode: string): void {
        if (mode === 'name') {
            this.ReportForm = this.fb.group({
                name: [
                    this.report ? this.report.name : '',
                    [Validators.required, Validators.maxLength(FILE_NAME_MAX_LEN)],
                ],
            });
        } else if (mode === 'comment') {
            this.ReportForm = this.fb.group({
                comment: [
                    this.report ? this.report.comment : '',
                    [Validators.maxLength(COMMENT_MAX_LEN)],
                ],
            });
        }
    }
}
