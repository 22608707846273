<div class="settings-group">
    <div class="settings-group__left">
        <div class="settings-group__top scroll">
            <div class="item" *ngFor="let item of settingList" (click)="openPage(item.id)">{{item.value}}</div>
        </div>
    </div>
    <div class="settings-group__plug">
        <cityair-settings-plug [title]="textsSettings.title" [image]="'settings.svg'"></cityair-settings-plug>
    </div>
</div>
<router-outlet></router-outlet>
