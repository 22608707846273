import { ModulePageConfig } from '@cityair/namespace';

export const globalModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: false,
    enableCalendar: false,
    enablePlumeButton: false,
    enableAqi: false,

    enableMap: true,
    enableOldTimeline: true,
    enableTopElements: false,
    fullScreen: true,
};
