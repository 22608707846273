import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadBtnComponent } from './download-btn.component';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';

@NgModule({
    imports: [CommonModule, PopupAskModule],
    exports: [DownloadBtnComponent],
    declarations: [DownloadBtnComponent],
})
export class DownloadBtnModule {}
