import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { AnalyticChartTitleModule } from '@libs/shared-ui/components/analytic/analytic-charts/analytic-chart-title/analytic-chart-title.module';
import { DoughnutChartComponent } from './doughnut-chart.component';

@NgModule({
    imports: [CommonModule, BaseChartDirective, AnalyticChartTitleModule],
    declarations: [DoughnutChartComponent],
    exports: [DoughnutChartComponent],
})
export class DoughnutChartModule {}
