import { createReducer, on } from '@ngrx/store';
import {
    timelinePostsLoaded,
    setCurrentAnalysisEvent,
    setGroupsEvents,
    setRegisterSearchText,
    setFilterStatus,
    setFilterMmt,
    setCorrelationDataEvents,
    setCurrentEventData,
    setCorrelationByEventData,
    setLoadingAnalysisTimeline,
    setLoadingAnalysisCorrelation,
    clearTimelineData,
    isLoadingAnalysisEvent,
    setSorting,
    setDateRange,
    clearGroupEvents,
    setWindData,
} from './actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
    AnalysisEvent,
    AnalysisEventStatus,
    AnalysisEventStatusByEnd,
    CorrelationData,
    CorrelationPostData,
    EventData,
    IAnalysisEvent,
    IExtraTimelineResponse,
    ITimeseriesDataItem,
    SortingData,
    WindDataPost,
} from '@libs/common/models/basicModels';
import { timelineAdapter } from '@cityair/modules/core/store/reducers';
import { getListMmt, prepareEventsAnalysis } from '@cityair/modules/analysis-events/utils';
import { INIT_SORTING } from '@cityair/modules/analysis-events/constants';
import { EventDateRange } from '@libs/common/models/basicModels';

interface FilterData {
    query: string;
    status: AnalysisEventStatus | AnalysisEventStatusByEnd;
    mmt: string;
}
export const eventsAdapter: EntityAdapter<IAnalysisEvent> = createEntityAdapter<IAnalysisEvent>();
export interface AnalysisEventsState {
    dateRange: EventDateRange;
    isLoadingAnalysisEventById: boolean;
    isLoadingAnalysisTimeline: boolean;
    isLoadingAnalysisCorrelation: boolean;
    isLoadingAnalysisEvent: boolean;
    analysisPostsTimeLine: EntityState<ITimeseriesDataItem>;
    analysisPostsDates: string[];
    currentAnalysisEvent: IAnalysisEvent;
    listGroupsEvents: EntityState<IAnalysisEvent>;
    isLoadedEvents: boolean;
    filter: FilterData;
    listMmt: string[];
    correlationData: CorrelationData;
    isDemoMode: boolean;
    currentEventData: EventData;
    correlationByEvent: CorrelationPostData;
    sortingData: SortingData;
    windData: WindDataPost;
}

const initialState: AnalysisEventsState = {
    dateRange: null,
    isLoadingAnalysisEventById: false,
    isLoadingAnalysisTimeline: false,
    isLoadingAnalysisCorrelation: false,
    isLoadingAnalysisEvent: true,
    analysisPostsTimeLine: timelineAdapter.getInitialState(),
    analysisPostsDates: [],
    currentAnalysisEvent: null,
    listGroupsEvents: eventsAdapter.getInitialState(),
    isLoadedEvents: false,
    filter: {
        query: '',
        status: null,
        mmt: null,
    },
    listMmt: null,
    correlationData: null,
    isDemoMode: false,
    currentEventData: null,
    correlationByEvent: null,
    sortingData: INIT_SORTING,
    windData: null,
};

export const analysisEventsReducer = createReducer(
    initialState,
    on(timelinePostsLoaded, (state: AnalysisEventsState, { payload }) => {
        const response = payload?.data as ITimeseriesDataItem[];
        const postIds = state.currentAnalysisEvent?.events?.map((v) => v.post_id);
        const data = response.filter((v) => postIds.indexOf(v.id) >= 0);
        const analysisPostsTimeLine = timelineAdapter.setMany(data, state.analysisPostsTimeLine);
        const extra = payload?.meta?.extra as IExtraTimelineResponse;
        return {
            ...state,
            analysisPostsTimeLine,
            analysisPostsDates: extra.dates,
            isLoadingAnalysisTimeline: false,
        };
    }),
    on(clearTimelineData, (state: AnalysisEventsState) => ({
        ...state,
        analysisPostsTimeLine: timelineAdapter.removeAll(state.analysisPostsTimeLine),
    })),
    on(setDateRange, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        dateRange: payload,
    })),
    on(setGroupsEvents, (state: AnalysisEventsState, { payload, isDemoMode }) => {
        const data = payload as AnalysisEvent[];
        const prepareData = data.map((item) => prepareEventsAnalysis(item, isDemoMode));
        const listGroupsEvents = eventsAdapter.setMany(prepareData, state.listGroupsEvents);
        const listMmt = getListMmt(prepareData);
        return { ...state, listGroupsEvents, listMmt, isLoadedEvents: true, isDemoMode };
    }),
    on(clearGroupEvents, (state: AnalysisEventsState) => {
        const listGroupsEvents = eventsAdapter.removeAll(state.listGroupsEvents);
        return { ...state, listGroupsEvents, isLoadedEvents: false };
    }),
    on(setCurrentAnalysisEvent, (state: AnalysisEventsState, { eventId }) => ({
        ...state,
        currentAnalysisEvent: state.listGroupsEvents.entities[eventId],
    })),
    on(setRegisterSearchText, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        filter: { ...state.filter, query: payload },
    })),
    on(setFilterStatus, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        filter: { ...state.filter, status: payload },
    })),
    on(setFilterMmt, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        filter: { ...state.filter, mmt: payload },
    })),
    on(setCorrelationDataEvents, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        correlationData: payload,
        isLoadingAnalysisEvent: false,
    })),
    on(setCurrentEventData, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        currentEventData: payload,
    })),
    on(setCorrelationByEventData, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        correlationByEvent: payload,
    })),
    on(setLoadingAnalysisTimeline, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        isLoadingAnalysisTimeline: payload,
    })),
    on(setLoadingAnalysisCorrelation, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        isLoadingAnalysisCorrelation: payload,
    })),
    on(isLoadingAnalysisEvent, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        isLoadingAnalysisEventById: payload,
    })),
    on(setSorting, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        sortingData: payload,
    })),
    on(setWindData, (state: AnalysisEventsState, { payload }) => ({
        ...state,
        windData: payload,
    }))
);
