import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

@Component({
    selector: 'color-legend',
    templateUrl: './color-legend.component.html',
    styleUrls: ['./color-legend.component.less'],
})
export class ColorLegendComponent {
    @Input() unit: string;
    @Input() measure: string;
    @Input() values: number[] = [];
    @Input() colors: string[] = [];
    @Input() digitsAfterDot?: number = 1;
    @Input() min: string;
    @Input() isLimit = false;
    @Input() showInfo = false;
    @Input() tooltipInfoText: string;

    @Output() clickInfo = new EventEmitter<void>();

    constructor(private numberFormatPipe: ValueFormatNumberPipe) {}
    minWidth = 8;
    getGradient() {
        const length = this.colors?.length;
        const percents = 100 / length;
        this.minWidth = 400 / length;
        const bloorPercent = percents / 1.5;

        const colorsGradient = this.colors?.map((color, index) => {
            const start = percents * index + bloorPercent;
            const end = percents * (index + 1);
            return `${color} ${start}%, ${color} ${end}%,`;
        });

        return `linear-gradient(90deg, ${colorsGradient?.join(' ').slice(0, -1)})`;
    }

    getGridTemplateColumns() {
        return `1fr repeat(${this.values?.length}, 1fr)`;
    }

    getDisplayedValue = (val: number) =>
        isFalseNumber(val) ? 0 : this.numberFormatPipe.transform(val, this.digitsAfterDot);

    setActionInfo() {
        this.clickInfo.emit();
    }
}
