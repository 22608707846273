import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeightsSelectorComponent } from '@libs/shared-ui/components/heights-selector/heights-selector.component';

@NgModule({
    exports: [HeightsSelectorComponent],
    declarations: [HeightsSelectorComponent],
    imports: [CommonModule],
})
export class HeightsSelectorModule {}
