import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import * as moment from 'moment-timezone';
import {
    AnalysisEventStatus,
    AnalysisEventStatusByEnd,
    EventStatus,
    IAnalysisEvent,
} from '@libs/common/models/basicModels';
import { STATUS_COLOR } from '@cityair/modules/analysis-events/constants';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectPostNameById } from '@cityair/modules/core/store/posts/posts.feature';
import {
    initAnalysisMainEvent,
    clearFilter,
    setDateRange,
    setFilterMmt,
    setFilterStatus,
    setRegisterSearchText,
    setSorting,
} from '@cityair/modules/analysis-events/store/actions';
import {
    selectAnalysisEventLoaded,
    selectDataForFilterMmt,
    selectDateRangeForList,
    selectFilterMmt,
    selectFilterSearchText,
    selectFilterStatus,
    selectGroupsListEvent,
    selectGroupsListEventWithFilter,
    selectIsClearFilterIsDisabled,
    selectIsDemoMode,
    selectPostListByEvent,
    selectSorting,
} from '@cityair/modules/analysis-events/store/selectors';

import { ANALYSIS_EVENTS_PAGES } from '@cityair/modules/analysis-events/models';
import { NgLocalization } from '@angular/common';
import { STATUS_ORDER, STATUS_ORDER_BY_END } from '@cityair/modules/analysis-events/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchInputBasicComponent } from '@libs/shared-ui/components/search-input-basic/search-input-basic.component';

export interface IKeyValue {
    key: AnalysisEventStatus;
    value: number;
}
export interface IKeyValueTypeByEnd {
    key: AnalysisEventStatusByEnd;
    value: number;
}
function sortStatus(a: IKeyValue, b: IKeyValue) {
    const index = STATUS_ORDER.indexOf(a.key);
    if (index === -1) {
        return 1;
    }
    return STATUS_ORDER.indexOf(a.key) - STATUS_ORDER.indexOf(b.key);
}
function sortStatusByEnd(a: IKeyValueTypeByEnd, b: IKeyValueTypeByEnd) {
    const index = STATUS_ORDER_BY_END.indexOf(a.key);
    if (index === -1) {
        return 1;
    }
    return STATUS_ORDER_BY_END.indexOf(a.key) - STATUS_ORDER_BY_END.indexOf(b.key);
}
@Component({
    selector: 'cityair-analysis-events-register',
    templateUrl: './analysis-events-register.component.html',
    styleUrls: ['./analysis-events-register.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisEventsRegisterComponent implements OnDestroy {
    public translateText = TEXTS.ANALYSIS_EVENTS;
    public textNames = TEXTS.NAMES;
    public textsNotification = TEXTS.NOTIFICATIONS;
    public currentEvent: IAnalysisEvent;
    public ngDestroyed$ = new Subject<void>();
    ugM = TEXTS.ugM;
    oftenMmt = 'PM25';
    currentYear = moment().format('YYYY');
    countDaysInYear = moment().dayOfYear();
    numbersOfIncidents = 42;
    selectGroupsListEvent = selectGroupsListEvent;
    selectGroupsListEventWithFilter = selectGroupsListEventWithFilter;
    selectAnalysisEventLoaded = selectAnalysisEventLoaded;
    selectPostListByEvent = selectPostListByEvent;
    selectDateRangeForList = selectDateRangeForList;
    selectIsClearFilterIsDisabled = selectIsClearFilterIsDisabled;
    selectPostNameById = selectPostNameById;
    setDateRange = setDateRange;
    colors = STATUS_COLOR;
    public searchQuery = '';
    public analysisStatus = AnalysisEventStatus;
    statusList = [];
    availableMmtList;
    mmtList = [];
    public filterMmtValue: string;
    public filterStatusValue: string;
    public sortDirection;
    public sortField;
    EventStatus = EventStatus;
    verifyGoodValue = 50;
    public isDemoMode = false;
    currentValueFilterStatus = {
        id: null,
        label: this.translateText.registerEvents.tableHeader.all,
        selected: false,
    };
    @ViewChild('searchInput') searchInputComponent: SearchInputBasicComponent;
    constructor(
        public store: Store,
        private router: Router,
        private ngLocalization: NgLocalization,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store.dispatch(initAnalysisMainEvent());
        this.store
            .select(selectFilterStatus)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((currentStatus) => {
                this.statusList = this.prepareStatusList(currentStatus, this.isDemoMode);
                this.filterStatusValue = currentStatus;
            });
        this.store
            .select(selectDataForFilterMmt)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null)
            )
            .subscribe((data) => {
                this.availableMmtList = data?.list;
                this.filterMmtValue = data.mmt;
                this.mmtList = this.prepareFilterListMmt(data?.mmt, data?.list);
            });
        this.store
            .select(selectFilterMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((currentMmt) => {
                this.mmtList = this.prepareFilterListMmt(currentMmt, this.availableMmtList);
            });
        this.store
            .select(selectIsDemoMode)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isDemo) => {
                this.isDemoMode = isDemo;
                this.statusList = this.prepareStatusList(this.filterStatusValue, this.isDemoMode);
            });
        this.store
            .select(selectSorting)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((sortingData) => {
                this.sortDirection = sortingData.dir;
                this.sortField = sortingData.name;
            });
        this.store
            .select(selectFilterSearchText)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((searchText) => {
                this.searchQuery = searchText;
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    public analysisEvents(event) {
        if (this.isDemoMode && event.id !== '21') {
            return;
        }
        this.router.navigate([
            `/${ANALYSIS_EVENTS_PAGES.analysisEvents}/${ANALYSIS_EVENTS_PAGES.registerEvents}/${event.id}`,
        ]);
    }

    public textChangeIn($event) {
        this.searchQuery = $event;
        const query = this.searchQuery ? this.searchQuery.toLowerCase().trim() : null;
        this.store.dispatch(setRegisterSearchText({ payload: query }));
    }

    getCounterStatus(data) {
        const counterObj: IKeyValue[] = [];
        const result = data.reduce(function (acc, el) {
            acc[el.status] = (acc[el.status] || 0) + 1;
            return acc;
        }, {});

        if (!result[AnalysisEventStatus.new]) {
            result[AnalysisEventStatus.new] = 0;
        }
        if (!result[AnalysisEventStatus.wait]) {
            result[AnalysisEventStatus.wait] = 0;
        }
        if (!result[AnalysisEventStatus.cancel]) {
            result[AnalysisEventStatus.cancel] = 0;
        }
        if (!result[AnalysisEventStatus.accept]) {
            result[AnalysisEventStatus.accept] = 0;
        }

        for (const key of Object.keys(result)) {
            counterObj.push({ key: key as AnalysisEventStatus, value: result[key] });
        }

        counterObj.sort(sortStatus);
        return counterObj;
    }

    getCounterStatusByEnd(data) {
        const counterObj: IKeyValueTypeByEnd[] = [];
        const result = data.reduce(function (acc, el) {
            acc[el.eventTypeByEnd] = (acc[el.eventTypeByEnd] || 0) + 1;
            return acc;
        }, {});

        if (!result[AnalysisEventStatusByEnd.new]) {
            result[AnalysisEventStatusByEnd.new] = 0;
        }
        if (!result[AnalysisEventStatusByEnd.end]) {
            result[AnalysisEventStatusByEnd.end] = 0;
        }

        for (const key of Object.keys(result)) {
            counterObj.push({ key: key as AnalysisEventStatusByEnd, value: result[key] });
        }

        counterObj.sort(sortStatusByEnd);
        return counterObj;
    }

    public postCountText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }

    updateFilterStatus(event) {
        const status = event.id as AnalysisEventStatus | AnalysisEventStatusByEnd;
        this.store.dispatch(setFilterStatus({ payload: status }));
    }

    updateFilterMmt(event) {
        this.store.dispatch(setFilterMmt({ payload: event.id }));
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
        this.store.dispatch(
            setSorting({ payload: { name: this.sortField, dir: this.sortDirection } })
        );
    }

    public clearFilter() {
        if (this.searchQuery) {
            this.searchInputComponent?.form.reset();
        }
        this.store.dispatch(clearFilter());
    }

    private prepareFilterListMmt(currentValue, list) {
        const result = [
            {
                id: null,
                label: this.translateText.registerEvents.tableHeader.all,
                selected: currentValue === null,
            },
        ];
        list?.forEach((v) => {
            result.push({
                id: v,
                label: this.textNames[v],
                selected: v === currentValue,
            });
        });
        return result;
    }

    private prepareStatusList(currentStatus, isDemoMode) {
        const result = [];
        result.push({
            id: null,
            label: this.translateText.registerEvents.tableHeader.all,
            selected: currentStatus === null,
        });
        const orderArray = isDemoMode ? STATUS_ORDER : STATUS_ORDER_BY_END;
        orderArray.forEach((v) => {
            const labelText = isDemoMode
                ? this.translateText.registerEvents.statusTextCounter[v]
                : this.translateText.registerEvents.typeByEndTextCounter[v];
            result.push({
                id: v,
                label: `<span class="status-text ${v}">${labelText}</span>`,
                selected: v === currentStatus,
            });
        });
        return result;
    }
}
