import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TEXTS } from '@libs/common/texts/texts';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import { LoginUpdateService } from '../../services/login-update/login-update.service';
import { LoginPage } from '../../login.settings';

@Component({
    selector: 'ca-reset-code-form',
    templateUrl: 'reset-code-form.component.html',
    styleUrls: ['reset-code-form.component.less'],
})
export class ResetCodeFormComponent implements OnInit {
    TEXTS = TEXTS;

    hasErrors = false;

    isLoading = false;

    resetCodeForm: UntypedFormGroup;

    loginPage = LoginPage;

    constructor(
        readonly harvester: HarvesterApiService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private loginUpdateService: LoginUpdateService
    ) {}

    ngOnInit() {
        this.resetCodeForm = this.fb.group({
            code: ['', [Validators.required, Validators.minLength(6)]],
        });
    }

    async onSubmit() {
        if (this.isLoading || this.resetCodeForm.invalid) {
            return;
        }

        this.hasErrors = false;

        const { code } = this.resetCodeForm.value;

        this.isLoading = true;

        const resetEmail = this.loginUpdateService.getEmail();

        this.harvester.verifyResetCode(resetEmail, code).subscribe(
            () => {
                this.isLoading = false;
                this.loginUpdateService.setVerificationCode(code);
                this.router.navigate([LoginPage.Login, LoginPage.NewPassword]);
            },
            () => {
                this.isLoading = false;
                this.hasErrors = true;
            }
        );
    }
}
