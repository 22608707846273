import { Injectable } from '@angular/core';
import { Actions as NgRxActions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
    clickToPinForLinks,
    initAnalysisModule,
    isLoadingData,
    isLoadingPostTimeline,
    setActiveCorrelationPair,
    setActiveCorrelationPost,
    setActiveLine,
    setAllMeasurements,
    setComparedItems,
    setCorrelationChartDataPair,
    setCorrelationData,
    setCorrelationDataList,
    setCurrentMmt,
    setErrorCorrelationData,
    setIsLoadingChartCorrelationData,
    setNetworkAnalysisData,
    setPeriod,
    setSearchQueryLinks,
    setSearchQueryPosts,
    setTimelineDates,
    setWindData,
    timelinePostsLoaded,
} from '@cityair/modules/analysis/store/actions';

import {
    selectAllPostsAnalysis,
    selectComparedItems,
    selectCorrelationAnalysisData,
    selectCorrelationData,
    selectCurrentMmt,
    selectDataCorrelationByPeriodAndMmt,
    selectNetworkAnalysisData,
    selectParamsForCorrelationDataByPosts,
    selectParamsForTimeline,
    selectParamsForWindQuery,
    selectSearchQueryLinks,
    selectSearchQueryPosts,
} from '@cityair/modules/analysis/store/selectors';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { of } from 'rxjs';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import {
    CorrelationData,
    IExtraTimelineResponse,
    ITimeseriesDataItem,
    NetworkAnalysisData,
} from '@libs/common/models/basicModels';
import { getCorrelationData } from '@libs/common/utils/utils';
import { doNothing } from '@cityair/modules/core/store/actions';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { Router } from '@angular/router';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { sortMeasurements } from '@libs/common/utils/utils';

@Injectable()
export class AnalysisEffects {
    constructor(
        private actions$: NgRxActions,
        private store: Store,
        private basicApi: BasicApi,
        private router: Router
    ) {}

    setDefaultMmt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setAllMeasurements),
            withLatestFrom(this.store.select(selectCurrentMmt)),
            filter(([action, current]) => current === null),
            switchMap(([action, current]) => [setCurrentMmt({ payload: action.payload[0] })])
        )
    );

    updateData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCorrelationData, setCurrentMmt, setPeriod),
            withLatestFrom(this.store.select(selectParamsForTimeline)),
            filter(([action, params]) => params !== null),
            tap((data) => this.store.dispatch(isLoadingPostTimeline({ payload: true }))),
            switchMap(([action, { groupId, params }]) =>
                this.basicApi.getDataTimeline(groupId, params).pipe(
                    switchMap((response) => {
                        const extraResponse = response?.meta.extra as IExtraTimelineResponse;
                        const dates = extraResponse?.dates ?? [];
                        const timeline = response?.data as ITimeseriesDataItem[];
                        return [
                            timelinePostsLoaded({ payload: timeline }),
                            isLoadingPostTimeline({ payload: false }),
                            setTimelineDates({ payload: dates }),
                        ];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, isLoadingPostTimeline({ payload: false }));
                        }
                    })
                )
            )
        )
    );

    updateCorrelationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initAnalysisModule),
            withLatestFrom(
                this.store.select(selectGroupId),
                this.store.select(selectAllPostsAnalysis),
                this.store.select(selectCorrelationData)
            ),
            filter(([action, groupId, posts, data]) => groupId !== null && data === null),
            tap((data) => this.store.dispatch(isLoadingData({ payload: true }))),
            switchMap(([action, groupId, posts]) =>
                this.basicApi.getAnalysisData(groupId).pipe(
                    switchMap((response) => {
                        const data = response?.data as CorrelationData;
                        const mmts = Object.keys(data).sort(sortMeasurements);
                        return [
                            setAllMeasurements({ payload: mmts }),
                            isLoadingData({ payload: false }),
                            setCorrelationData({ payload: { data, posts } }),
                        ];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            if (errorResponse?.status === HttpStatusCode.NotFound) {
                                return of(
                                    setErrorCorrelationData({ payload: true }),
                                    isLoadingData({ payload: false })
                                );
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction, isLoadingData({ payload: false }));
                            }
                        }
                    })
                )
            )
        )
    );

    updateCorrelationDataByPeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setPeriod, setCurrentMmt),
            withLatestFrom(
                this.store.select(selectAllPostsAnalysis),
                this.store.select(selectDataCorrelationByPeriodAndMmt)
            ),
            filter(([action, posts, data]) => !!data),
            switchMap(([action, posts, data]) => {
                const correlationData = getCorrelationData(posts, data?.coefficients);
                return [setCorrelationDataList({ payload: correlationData })];
            })
        )
    );

    loadChartDataForActivePair$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveCorrelationPair, setPeriod, setCurrentMmt),
            withLatestFrom(this.store.select(selectParamsForCorrelationDataByPosts)),
            filter(([action, params]) => params !== null),
            tap(() => this.store.dispatch(setIsLoadingChartCorrelationData({ payload: true }))),
            switchMap(([action, params]) =>
                this.basicApi.getCorrelationTimelines(params).pipe(
                    switchMap((response) => {
                        const data = response?.data as number[];
                        return [setCorrelationChartDataPair({ payload: data })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            if (errorResponse?.status === HttpStatusCode.NotFound) {
                                return of(doNothing());
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(
                                    errorAction,
                                    setIsLoadingChartCorrelationData({ payload: false })
                                );
                            }
                        }
                    })
                )
            )
        )
    );

    clickToPinForLinksEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickToPinForLinks),
            withLatestFrom(this.store.select(selectComparedItems)),
            switchMap(([action, list]) => {
                const pin = action.payload;
                let newList = [...list];
                const existPin = list.find((v) => v.id === pin.id);
                if (existPin) {
                    newList = list.filter((v) => v.id !== pin.id);
                    return [setComparedItems({ payload: newList })];
                } else {
                    newList.push(pin);
                }
                return [setComparedItems({ payload: newList })];
            })
        )
    );

    setActivePairFromComparedItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setComparedItems),
            withLatestFrom(this.store.select(selectCorrelationAnalysisData)),
            switchMap(([action, pairs]) => {
                const list = action.payload;
                if (list.length === 2) {
                    const activePair = pairs.find(
                        (v) =>
                            (v.first_post === list[0].id || v.first_post === list[1].id) &&
                            (v.second_post === list[0].id || v.second_post === list[1].id)
                    );
                    return [setActiveCorrelationPair({ payload: activePair })];
                }

                return [];
            })
        )
    );

    setActivePairBySetLine$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveLine),
            withLatestFrom(this.store.select(selectCorrelationAnalysisData)),
            switchMap(([action, pairs]) => {
                const line = action.payload?.properties;
                if (line) {
                    const activePair = pairs.find(
                        (v) =>
                            (v.first_post === line.first || v.first_post === line.second) &&
                            (v.second_post === line.first || v.second_post === line.second)
                    );
                    if (activePair) {
                        this.router.navigate([
                            `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}/${activePair.first_post}-${activePair.second_post}`,
                        ]);
                    }
                    return [];
                }

                return [];
            })
        )
    );

    getDensityDataEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initAnalysisModule),
            withLatestFrom(
                this.store.select(selectGroupId),
                this.store.select(selectNetworkAnalysisData)
            ),
            filter(([action, groupId, data]) => !!groupId && data === null),
            switchMap(([action, groupId]) =>
                this.basicApi.getNetworkAnalysis(groupId).pipe(
                    switchMap((response) => {
                        const data = response?.data as NetworkAnalysisData;
                        return [setNetworkAnalysisData({ payload: data })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            if (errorResponse?.status === HttpStatusCode.NotFound) {
                                return of(doNothing());
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction);
                            }
                        }
                    })
                )
            )
        )
    );

    clearSearchQueryPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveCorrelationPost),
            withLatestFrom(
                this.store.select(selectSearchQueryPosts),
                this.store.select(selectAllPostsAnalysis)
            ),
            filter(([action, query]) => action.postId !== null && query !== null),
            switchMap(([action, query, posts]) => {
                const currentPost = posts.find((v) => v.id === action.postId);
                if (posts && currentPost) {
                    const name = currentPost.name.toLowerCase();
                    if (name && name.indexOf(query.toLowerCase()) === -1) {
                        return [setSearchQueryPosts({ payload: null })];
                    }
                }

                return [];
            })
        )
    );

    loadWindData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initAnalysisModule),
            withLatestFrom(this.store.select(selectParamsForWindQuery)),
            filter(([action, params]) => params !== null),
            switchMap(([action, params]) =>
                this.basicApi.getAnalysisWindData(params).pipe(
                    switchMap((response) => [setWindData({ payload: response?.data })]),
                    catchError((errorResponse: HttpErrorResponse) => of(doNothing()))
                )
            )
        )
    );

    clearSearchQueryLinks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveCorrelationPair),
            withLatestFrom(this.store.select(selectSearchQueryLinks)),
            filter(([action, query]) => action.payload !== null && query !== null),
            switchMap(([action, query]) => {
                if (action?.payload?.name) {
                    const name = action.payload.name.toLowerCase();
                    if (name && name.indexOf(query.toLowerCase()) === -1) {
                        return [setSearchQueryLinks({ payload: null })];
                    }
                }

                return [];
            })
        )
    );
}
