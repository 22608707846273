import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { TabModel } from '@libs/common/types/tab-model';
import { SETTINGS_INIT_PAGE, SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectMyRole } from '@cityair/modules/core/store/selectors';
import { filter, take } from 'rxjs';

@Component({
    selector: 'cityair-settings-main',
    templateUrl: './settings-main.component.html',
    styleUrls: ['./settings-main.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMainComponent {
    textsSettings = TEXTS.CONFIG;
    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: SETTINGS_PAGES.system,
            title: this.textsSettings.settings,
        },
        {
            isDisabled: false,
            type: 3,
            id: SETTINGS_PAGES.keys,
            title: this.textsSettings.token,
            withSeparator: false,
        },
    ];
    selectedTab: TabModel = this.tabs.find((item) => item.id === SETTINGS_INIT_PAGE);
    readonly mainComponentUrl = `/${SETTINGS_PAGES.settings}/`;

    constructor(private router: Router, public store: Store, private ref: ChangeDetectorRef) {
        this.store
            .select(selectMyRole)
            .pipe(
                filter((v) => !!v),
                take(1)
            )
            .subscribe((data) => {
                if (data?.edit_group) {
                    this.tabs.splice(2, 0, {
                        isDisabled: false,
                        type: 6,
                        id: SETTINGS_PAGES.groupSettings,
                        title: this.textsSettings.settingsGroup,
                    });
                }
                if (data.edit_user) {
                    this.tabs.splice(2, 0, {
                        isDisabled: false,
                        type: 5,
                        id: SETTINGS_PAGES.users,
                        title: this.textsSettings.users,
                    });
                    const indexKeys = this.tabs.findIndex((v) => v.type === 3);
                    if (indexKeys) {
                        this.tabs[indexKeys] = { ...this.tabs[indexKeys], withSeparator: true };
                    }
                }
                this.ref.markForCheck();
            });
    }

    getActiveTab() {
        const tabId = this.router.url.split('/')[2];
        return this.tabs.find((item) => item.id === tabId);
    }

    openPage(event) {
        this.router.navigate([this.mainComponentUrl + event.id]);
    }
}
