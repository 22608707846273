import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import * as moment from 'moment-timezone';

import { isFalseNumber } from '@libs/common/utils/utils';
import { COLORS, COLORS_CORRELATION_WHO } from '@cityair/modules/analysis-events/constants';
type ChartPoint = {
    x: string;
    y: number;
};
@Component({
    selector: 'cityair-cum-line-chart',
    templateUrl: './cum-line-chart.component.html',
    styleUrls: ['./cum-line-chart.component.less'],
})
export class CumLineChartComponent implements OnChanges {
    @Input() data: any;
    @Input() whoData: any;
    dashLinesMap: {
        [key: string]: number;
    } = {};
    public currentMmt;
    public pdkValue;
    public chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1000,
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    display: true,
                },
                time: {
                    tooltipFormat: 'DD MMM, HH:mm',
                    parser: (value: string) => moment(value).valueOf(),
                },
                grid: {
                    display: false,
                },
            },

            y: {
                position: 'right',
                border: {
                    display: false,
                },
                grid: {
                    color: '#e6e6e6',
                    tickLength: 0,
                },
            },
        },
    };
    public chartType: ChartType = 'line';
    chartData: ChartDataset<ChartType, ChartPoint[]>[] = [];

    ngOnChanges(changes: SimpleChanges) {
        this.createChartData();
    }

    private createChartData() {
        const result = [];
        this.data?.forEach((item, i) => {
            const timeline = item?.data;
            if (timeline) {
                const chartData = this.prepareChartData(timeline);
                result.push({
                    label: item.label,
                    data: chartData,
                    type: 'line',
                    borderColor: COLORS[i] ?? '#C83FBE',
                });
            }
        });

        // add WHO line
        this.whoData?.forEach((item, i) => {
            const timeline = item?.data;
            if (timeline) {
                const chartData = this.prepareChartData(timeline);
                result.push({
                    label: item.label,
                    data: chartData,
                    type: 'line',
                    borderDash: [6, 2],
                    borderColor: COLORS_CORRELATION_WHO[i] ?? '#C83FBE',
                });
            }
        });
        this.chartData = result;
    }

    private prepareChartData(data) {
        const timeline = Object.values(data) as number[];
        const dates = Object.keys(data);
        return (
            timeline
                ?.map((y, i) => {
                    const date = dates[i] ? new Date(Number(dates[i])) : null;
                    const isoString = date ? date.toISOString() : null;
                    return {
                        y: !isFalseNumber(y) ? Math.round(y) : null,
                        x: isoString,
                    };
                })
                .filter((point) => !!point.x) || []
        );
    }
}
