import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { TEXTS } from '@libs/common/texts/texts';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { ImpactActions } from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { TabModel } from '@libs/common/types/tab-model';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';

@Component({
    selector: 'cs-impact-control-points',
    templateUrl: './control-points.component.html',
    styleUrls: ['./control-points.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ControlPointsComponent implements OnDestroy {
    texts = TEXTS.IMPACT.controlPoints;
    tabs: TabModel[] = [
        {
            id: IMPACT_PAGES.regions,
            title: this.texts.tabs[IMPACT_PAGES.regions],
        },
        {
            id: IMPACT_PAGES.points,
            title: this.texts.tabs[IMPACT_PAGES.points],
        },
        {
            id: IMPACT_PAGES.posts,
            title: this.texts.tabs[IMPACT_PAGES.posts],
        },
    ];
    currentTab: TabModel;
    IMPACT_PAGES = IMPACT_PAGES;

    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.controlPoints }));
        const routeUrlArr = this.router.url.split('/');
        const currentPage = routeUrlArr[routeUrlArr.length - 1];
        this.currentTab = this.tabs.find((v) => v.id === currentPage);
    }

    changeTab($event) {
        this.currentTab = $event;
        this.router.navigate([`/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${$event.id}`]);
    }

    openAddCheckpoint() {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}/${IMPACT_PAGES.add}`,
        ]);
    }

    ngOnDestroy() {}
}
