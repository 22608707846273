import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cityscreen-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.less'],
})
export class AnalyticsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
