import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticComponent } from '@libs/shared-ui/components/analytic/analytic.component';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { DoughnutChartModule } from '@libs/shared-ui/components/analytic/analytic-charts/doughnut-chart/doughnut-chart.module';
import { BarChartModule } from '@libs/shared-ui/components/analytic/analytic-charts/bar-chart/bar-chart.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        HeaderOfPanelModule,
        InputDropdownModule,
        SelectboxRadioModule,
        SwitchItemModule,
        LoadersModule,
        DoughnutChartModule,
        BarChartModule,
        DirectivesModule,
    ],
    exports: [AnalyticComponent],
    declarations: [AnalyticComponent],
})
export class AnalyticModule {}
