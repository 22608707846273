import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';

import { TEXTS } from '@libs/common/texts/texts';
import { Feature } from '@libs/common/models/feature';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { BaseChartDirective } from 'ng2-charts';
import {
    CHART_TOOLTIP_MAX_STRINGS,
    MEASUREMENTS_CONFIG,
} from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/config';
import { Y_SCALE_CONTRIBUTIONS_ID } from '@libs/shared-ui/components/timeline-panel/chart-timeline/chart-timeline.component';
import * as moment from 'moment-timezone';
import {
    createContributionTr,
    createElement,
    createTooltipTr,
    getOrCreateTooltip,
    labelToKey,
} from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/tooltip-helper';
import { formatDayMonth } from '@libs/common/utils/date-formats';
import { TIME_FORMAT } from '@libs/common/consts/date-format.const';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { GASES } from '@libs/common/consts/mmt-with-pdk-sorted.const';
import { NO_DATA_COLOR } from '@libs/common/consts/no-data-color.const';
import { METEO_VALUES_COLORS } from '@libs/shared-ui/components/timeline-panel/constants';

@Component({
    selector: 'cs-chart-post-correlation',
    templateUrl: './chart-post-correlation.component.html',
    styleUrls: ['./chart-post-correlation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPostCorrelationComponent implements OnChanges {
    @Input() data: Feature[];
    @Input() mmts?: string[];
    @Input() measureScheme: MeasureScheme;
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    @Output() sizeUpdate = new EventEmitter<{ left: number; width: number }>();

    externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const currentTime = tooltip.dataPoints[0]?.raw?.x;

            const data = formatDayMonth(moment(currentTime));
            const format = `${data} ${moment(currentTime).format(`${TIME_FORMAT}`)}`;
            const titleLines = [format];
            const bodyLines = tooltip.body.map((b) => b.lines);
            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');

            titleLines.forEach((title) => {
                const div = createElement('div');

                div.appendChild(document.createTextNode(title));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            const allValues: string[] = [];
            if (this.data[0].properties?.contributions) {
                let lineValue = null;
                const contributionDataTooltip = [];
                bodyLines.forEach((body, i) => {
                    const value = tooltip.dataPoints[i].parsed.y;
                    if (tooltip.dataPoints[i].dataset?.type === 'line') {
                        lineValue = value;
                        const key = labelToKey(tooltip.dataPoints[i].dataset?.label);
                        let color = measureZones.getColor(value, key);

                        if (
                            GASES.includes(key) ||
                            (color === NO_DATA_COLOR && METEO_VALUES_COLORS[key])
                        )
                            color = METEO_VALUES_COLORS[key];
                        const tr = createTooltipTr(color, body, value);
                        tableBody.appendChild(tr);
                    } else if (tooltip.dataPoints[i].dataset?.type === 'bar' && Math.round(value)) {
                        const label = tooltip.dataPoints[i].dataset?.label?.split('$$')[0];
                        contributionDataTooltip.push({
                            value: Math.round(value),
                            color: tooltip.dataPoints[i].dataset?.backgroundColor,
                            label,
                        });
                    }
                });
                if (lineValue && contributionDataTooltip.length) {
                    const tr = createElement('tr', 'chart_tooltip__contrib_tr');
                    const td1 = createElement('td', 'chart_tooltip__td1');
                    td1.innerHTML = `<b>${TEXTS.ADMIN_PANEL.impact}:</b>`;
                    tr.appendChild(td1);
                    tableBody.appendChild(tr);
                    contributionDataTooltip.sort((a, b) => {
                        if (b.value < a.value) {
                            return -1;
                        }
                        if (b.value > a.value) {
                            return 1;
                        }
                    });
                    contributionDataTooltip.forEach((v) => {
                        const trI = createContributionTr(v);
                        tableBody.appendChild(trI);
                    });
                }
            } else {
                bodyLines.length = CHART_TOOLTIP_MAX_STRINGS;
                bodyLines.forEach((body, i) => {
                    const value = tooltip.dataPoints[i].parsed.y;
                    const key = labelToKey(tooltip.dataPoints[i].dataset?.label);
                    allValues.push(key);
                    let color = measureZones.getColor(value, key);

                    if (
                        GASES.includes(key) ||
                        (color === NO_DATA_COLOR && METEO_VALUES_COLORS[key])
                    )
                        color = METEO_VALUES_COLORS[key];

                    const tr = createTooltipTr(color, body, value);
                    tableBody.appendChild(tr);
                });
            }

            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 25 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 25 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };
    units: Record<string, string>;
    hoverLinePosition = 0;
    hoverLineVisible = false;
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}
    chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        resizeDelay: 100,
        maintainAspectRatio: false,
        animation: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                stepped: false,
                borderDash: [],
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];

            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandler,
                filter: ({ dataset, dataIndex }) =>
                    dataIndex > 0 && dataIndex < dataset.data.length - 1,
            },
        },
    };

    chartPlugins: ChartConfiguration['plugins'] = [
        {
            id: 'adjustScales',
            afterUpdate: (chart) => {
                const xScale = chart.scales.x;
                const data = (chart.data.datasets[0]?.data as any[]) ?? [];
                const len = data?.length;
                if (len >= 25) {
                    const val1 = data[1]?.x;
                    const valN = data[len - 2]?.x;
                    const x1 = xScale.getPixelForValue(moment(val1).valueOf());
                    const xN = xScale.getPixelForValue(moment(valN).valueOf());
                    const delta = (xN - x1) / (len - 1);
                    if (delta !== 0 && x1 > 0) {
                        this.sizeUpdate.emit({
                            left: x1 - delta / 2,
                            width: delta * len,
                        });
                    }
                } else {
                    const {
                        chartArea: { left, width },
                    } = chart;
                    if (left !== null && width) {
                        this.sizeUpdate.emit({
                            left: left + 10,
                            width: width,
                        });
                    }
                }
            },
        },
    ];
    private xScale: any = {
        type: 'time',
        stacked: true,
        display: false,
        ticks: {
            display: true,
        },
        time: {
            tooltipFormat: 'DD MMM, HH:mm',
            parser: (value: string) => moment(value).valueOf(),
        },
        grid: {
            display: false,
        },
    };

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && this.data) {
            this.updateChart();
        }

        if (changes.mmts?.currentValue) {
            this.updateChart();
        }
    }

    private getAxisId(mmt: string) {
        return this.units[mmt] ?? mmt;
    }

    private createYAxisConfig() {
        const yScale = this.mmts.reduce((acc, mmt) => {
            const id = this.getAxisId(mmt);

            return !acc[id]
                ? {
                      ...acc,
                      [id]: {
                          id,
                          position: 'right',
                          beginAtZero: false,
                          min: MEASUREMENTS_CONFIG[mmt]?.minChartValue,
                          max: MEASUREMENTS_CONFIG[mmt]?.maxChartValue,
                          display: true,
                          ticks: {
                              display: true,
                              rotation: 0,
                              crossAlign: 'center',
                              mirror: true,
                          },
                          border: {
                              display: false,
                          },
                          grid: {
                              color: '#e6e6e6',
                              tickLength: 0,
                              display: true,
                          },
                      },
                  }
                : acc;
        }, {} as ChartConfiguration['options']['scales']);

        if (this.data[0]?.properties?.contributions) {
            yScale[Y_SCALE_CONTRIBUTIONS_ID] = {
                id: Y_SCALE_CONTRIBUTIONS_ID,
                stacked: true,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                display: false,
                min: 0,
                max: 100,
            };
        }
        return yScale;
    }

    private updateChart() {
        this.updateUnits();
        if (this.units) {
            const yAxes = this.createYAxisConfig();
            this.updateYAxes(yAxes);
        }
    }

    private updateUnits() {
        this.units = this.mmts.reduce(
            (acc, mmt) => ({
                ...acc,
                [mmt]: TEXTS.MEASURES_SCHEME[this.measureScheme][mmt],
            }),
            {}
        );
    }

    private updateYAxes(scales: ChartConfiguration['options']['scales']) {
        const yAxes = Object.keys(this.createYAxisConfig()).reduce(
            (acc, k) =>
                k === 'x'
                    ? acc
                    : {
                          ...acc,
                          [k]: scales[k],
                      },
            {} as ChartConfiguration['options']['scales']
        );

        // push updates to the chart component
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                x: this.xScale,
                ...yAxes,
            },
        };
    }
}
