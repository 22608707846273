import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { REPORT_TEMPLATE_PARAMS_KEY, convertLabelTimezone } from '../../../models';

@Component({
    selector: 'report-form-dropdown',
    templateUrl: './report-form-dropdown.component.html',
    styleUrls: ['./report-form-dropdown.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFormDropdownComponent implements OnInit, OnChanges {
    @Input() values: string[];
    @Input() defaultValue: string;
    @Input() key: string;
    @Input() label: string;
    @Input() search = false;
    @Output() setValue = new EventEmitter<{ value: string; key: string }>();

    public labelTexts = TEXTS.REPORTS;
    public isShowDropdown = false;
    public list: CheckboxItem[] = [];
    public selectedValue: string;
    public isDisabled = true;
    public searchText = '';
    public searchPlaceholderText = 'Search';
    constructor() {}

    ngOnInit(): void {
        this.list = this.getList();
        if (this.labelTexts.formCreate.search[this.key]) {
            this.searchPlaceholderText = this.labelTexts.formCreate.search[this.key];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.values?.currentValue) {
            this.isDisabled = changes.values.currentValue.length === 1;
        }
        if (changes && changes.defaultValue?.currentValue && changes.key?.currentValue) {
            if (changes.key?.currentValue === REPORT_TEMPLATE_PARAMS_KEY.timezone) {
                this.selectedValue = convertLabelTimezone(changes.defaultValue?.currentValue);
            }
        }
    }

    private getList() {
        if (!this.values) {
            return;
        }
        const result = [];
        this.values.forEach((item, index) => {
            result.push({
                id: index,
                label: this.formatLabel(item),
                selected: item === this.defaultValue,
                value: item,
            });
        });

        return result;
    }

    private formatLabel(item: string) {
        if (this.key === REPORT_TEMPLATE_PARAMS_KEY.timezone) {
            return convertLabelTimezone(item);
        }

        return item;
    }

    selectItem(item) {
        this.isShowDropdown = false;
        if (this.key === REPORT_TEMPLATE_PARAMS_KEY.timezone) {
            this.selectedValue = convertLabelTimezone(item.value);
            this.setValue.emit({ value: item.value, key: this.key });
        } else {
            this.selectedValue = item.value;
            this.setValue.emit({ value: this.selectedValue, key: this.key });
        }
    }
}
