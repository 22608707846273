import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmtMapSelectorComponent } from '@cityair/components/mmt-map-selector/mmt-map-selector.component';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';

@NgModule({
    imports: [CommonModule, OverlayModule, TooltipsModule],
    exports: [MmtMapSelectorComponent],
    declarations: [MmtMapSelectorComponent],
})
export class MmtMapSelectorComponentModule {}
