import { Pipe, PipeTransform } from '@angular/core';
import { DataEvent, EventItem } from '@cityair/modules/impact/service/api-model-impact';

import { Feature } from '@libs/common/models/feature';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common/consts/demo-impact-groups';
import moment from 'moment-timezone';

@Pipe({
    name: 'eventContributionByTime',
})
export class EventContributionByTimePipe implements PipeTransform {
    transform(event: EventItem, data: Feature[], mmt): DataEvent {
        const result = [];
        const dates = data?.[0].properties?.timeseries?.date;

        const removeIndexes = [];
        const start = moment(event.start);
        const end = moment(event.end);
        dates?.forEach((v, index) => {
            const isBetween = moment(v).isBetween(start, end);
            if (!isBetween) {
                removeIndexes.push(index);
            }
        });

        if (data?.length) {
            const contributions = data?.[0].properties?.contributions[mmt];
            const timelineContributions = data?.[0].properties?.timelineContributions[mmt].filter(
                (v, index) => !removeIndexes.includes(index)
            );
            const summarySources = {};
            const totalContribution = timelineContributions.reduce((a, b) => a + b, 0);
            Object.keys(contributions)?.forEach((v) => {
                let value = 0;
                const row = contributions[v].filter((v, index) => !removeIndexes.includes(index));
                timelineContributions?.forEach((k, index) => {
                    value += +(k * row[index]).toFixed(2);
                });
                summarySources[v] = value / totalContribution;
            });

            Object.keys(contributions).forEach((v) => {
                if (summarySources[v]) {
                    const source = data?.[0].properties?.contributionsDetails.sources[v];
                    const colorIndex = data?.[0].properties?.contributionsDetails.order.findIndex(
                        (k) => k === Number(v)
                    );
                    const value = Math.round((summarySources[v] * totalContribution) / 100);
                    if (value) {
                        result.push({
                            source_id: v,
                            value,
                            label: source?.name,
                            color: CONTRIBUTIONS_COLORS_SOURCES_ORDER[colorIndex] ?? '#f5f6f7',
                            percent: Math.round(summarySources[v]),
                        });
                    }
                }
            });
            result.sort((a, b) => (a?.value > b?.value ? -1 : 1));
        }
        return result?.length ? result[0] : null;
    }
}
