import {
    Component,
    EventEmitter,
    OnInit,
    OnDestroy,
    Output,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ControlPoint } from '../../services/control-point/models';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ControlPointService } from '../../services/control-point/control-point.service';
import { selectMapClickState } from '@cityair/modules/core/store/selectors';
import { setMapClickState, addAlert } from '@cityair/modules/core/store/actions';
import { Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
    setControlPointError,
    setEditControlPoint,
    setNewControlPoint,
    setCoordinates,
    updateControlPoint,
} from '../../store/actions';
import {
    selectActiveConfig,
    selectControlPointPlumesError,
    selectNewCoordinates,
} from '../../store/selectors';
import { NEW_CONTROL_POINT_OBJ_TYPE, NUMBER_ROUND_COORDINATES } from '../../constants';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { RunConfig } from '@cityair/modules/plumes/services/run-config/models';

@Component({
    selector: 'edit-control-point',
    templateUrl: 'edit-control-point.component.html',
    styleUrls: ['./edit-control-point.component.less'],
})
export class EditControlPointComponent implements OnInit, OnDestroy {
    @Output() cbCloseEditMode = new EventEmitter<void>();
    @Input() controlPointForEdit: ControlPoint;
    @Input() title: string;

    private groupId: string;
    texts = TEXTS.EDIT_STATION;
    textsPlumes = TEXTS.PLUMES;
    textQuestions = TEXTS.POPUP_THREE_QUESTIONS;
    public translateText = TEXTS.FORECAST;
    editControlPoint: UntypedFormGroup;
    showConfirmationPopup = false;
    public currentConfig: RunConfig;
    isLoading = false;
    public infoText: string;
    public lastKeyError: string[] = [];
    public ngDestroyed$ = new Subject<void>();
    public showDomainError = false;
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    constructor(
        private fb: UntypedFormBuilder,
        private popupProvider: OffPanelPopupService,
        private controlPointService: ControlPointService,
        private store: Store
    ) {
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((groupId) => (this.groupId = groupId));
        this.store
            .select(selectActiveConfig)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((currentConfig) => (this.currentConfig = currentConfig));
        this.store
            .select(selectMapClickState)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((mapState) => !!mapState?.coordinates)
            )
            .subscribe((mapState) => {
                if (mapState?.coordinates) {
                    // create control points marker on the Map
                    const lat = parseFloat(
                        mapState.coordinates.lat.toFixed(NUMBER_ROUND_COORDINATES)
                    );
                    const lon = parseFloat(
                        mapState.coordinates.lon.toFixed(NUMBER_ROUND_COORDINATES)
                    );
                    this.store.dispatch(
                        setNewControlPoint({
                            payload: {
                                name: this.textsPlumes.newControlPointName,
                                lat: lat,
                                lon: lon,
                                obj: NEW_CONTROL_POINT_OBJ_TYPE,
                                id: null,
                            },
                        })
                    );

                    this.editControlPoint?.controls.lat.setValue(lat);
                    this.editControlPoint?.controls.lon.setValue(lon);
                    this.store.dispatch(setMapClickState({ isAllow: false }));
                }
            });
        this.store
            .select(selectNewCoordinates)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((coordinates) => !!coordinates)
            )
            .subscribe((coordinates) => {
                if (coordinates) {
                    this.editControlPoint.controls.lat.setValue(coordinates.lat);
                    this.editControlPoint.controls.lon.setValue(coordinates.lon);
                    this.editControlPoint.get('lat').markAsDirty();
                    this.editControlPoint.get('lon').markAsDirty();
                    this.showDomainError = false;
                }
            });
        this.store
            .select(selectControlPointPlumesError)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((value) => {
                if (value && value.error) {
                    const data = value.error;
                    if (data.hasOwnProperty('lat') && data.hasOwnProperty('lon')) {
                        this.showDomainError = true;
                        this.editControlPoint.controls['lat'].setErrors({
                            incorrect: true,
                            message: ' ',
                        });
                        this.editControlPoint.controls['lon'].setErrors({
                            incorrect: true,
                            message: ' ',
                        });
                        this.editControlPoint.get('lat').markAsDirty();
                        this.editControlPoint.get('lon').markAsDirty();
                    } else {
                        for (const key in data) {
                            if (data.hasOwnProperty(key) && this.editControlPoint?.controls[key]) {
                                this.editControlPoint.controls[key].setErrors({
                                    incorrect: true,
                                    message: data[key].join(', '),
                                });
                                this.editControlPoint.get(key).markAsDirty();
                                this.lastKeyError.push(key);
                            } else if (data.hasOwnProperty('detail')) {
                                this.store.dispatch(
                                    addAlert({
                                        id: new Date().valueOf(),
                                        message: `${data.detail}. ${this.translateText.tryAgain}.`,
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    })
                                );
                            }
                        }
                    }
                    this.isLoading = false;
                    setTimeout(() => {
                        this.store.dispatch(setControlPointError({ payload: null }));
                    }, 2500);
                }
                this.isLoading = false;
            });
    }

    ngOnInit() {
        this.infoText = this.controlPointForEdit
            ? this.textsPlumes.infoTextEdit
            : this.textsPlumes.infoTextCreate;
        if (this.controlPointForEdit === null) {
            this.store.dispatch(setMapClickState({ isAllow: true }));
        } else {
            this.store.dispatch(setEditControlPoint({ payload: this.controlPointForEdit }));
        }
        this.editControlPoint = this.fb.group({
            name: [
                this.controlPointForEdit ? this.controlPointForEdit.name : null,
                [Validators.required, Validators.maxLength(60)],
            ],
            lat: [
                this.controlPointForEdit ? this.controlPointForEdit.lat : null,
                [Validators.required, Validators.min(-90), Validators.max(90)],
            ],
            lon: [
                this.controlPointForEdit ? this.controlPointForEdit.lon : null,
                [Validators.required, Validators.min(-180), Validators.max(180)],
            ],
            group_id: [this.groupId],
            id: [this.controlPointForEdit ? this.controlPointForEdit.id : null],
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            this.openPopup();
            return true;
        });
    }

    ngOnDestroy() {
        this.store.dispatch(setEditControlPoint({ payload: null }));
        if (!this.controlPointForEdit) {
            this.store.dispatch(setNewControlPoint({ payload: null }));
        } else {
            // hook for return marker position
            this.store.dispatch(
                updateControlPoint({ payload: this.controlPointForEdit, field: 'hash' })
            );
        }
        this.store.dispatch(setCoordinates({ payload: null }));
        this.popupProvider.clear();
        this.ngDestroyed$.next();
    }

    get name() {
        return this.editControlPoint.get('name');
    }

    get lat() {
        return this.editControlPoint.get('lat');
    }

    get lon() {
        return this.editControlPoint.get('lon');
    }

    save() {
        this.onSubmit();
    }

    public onSubmit() {
        this.isLoading = true;
        this.showDomainError = false;
        const formData = this.editControlPoint.getRawValue();
        if (this.controlPointForEdit) {
            this.controlPointService.update(formData).subscribe((data) => {
                this.isLoading = false;
                setTimeout(() => {
                    this.openCheckpoint();
                }, 500);
            });
        } else {
            this.controlPointService
                .add({
                    name: formData.name,
                    lat: formData.lat,
                    lon: formData.lon,
                    group_id: this.groupId,
                })
                .subscribe((data) => {
                    this.isLoading = false;
                    setTimeout(() => {
                        this.openCheckpoint();
                    }, 500);
                });
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    };

    onSavePopup = () => {
        this.save();
        this.showConfirmationPopup = false;
    };

    openCheckpoint = () => {
        this.cbCloseEditMode.emit();
    };

    public getError(field: string): string {
        this.clearError(field);
        if (
            this.editControlPoint &&
            this.editControlPoint.controls[field].invalid &&
            (this.editControlPoint.controls[field].dirty ||
                this.editControlPoint.controls[field].touched)
        ) {
            if (this.editControlPoint.controls[field].errors.required) {
                return field === 'name'
                    ? this.translateText.nameRequiredError
                    : this.translateText.errorRequired;
            }
            if (this.editControlPoint.controls[field].errors.max) {
                return (
                    this.translateText.maxError +
                    ' ' +
                    this.editControlPoint.controls[field].errors.max.max
                );
            }
            if (this.editControlPoint.controls[field].errors.min) {
                return (
                    this.translateText.minError +
                    ' ' +
                    this.editControlPoint.controls[field].errors.min.min
                );
            }
            if (this.editControlPoint.controls[field].errors.maxlength) {
                return this.translateText.maxLength(
                    this.editControlPoint.controls[field].errors.maxlength.requiredLength
                );
            }

            if (this.editControlPoint.controls[field].errors.incorrect) {
                return this.editControlPoint.controls[field].errors.message;
            }
        }

        return '';
    }

    private clearError(field: string) {
        this.lastKeyError.forEach((key) => {
            if (this.editControlPoint.controls[key] && field === key) {
                this.editControlPoint.get(key).updateValueAndValidity();
            }
        });
        this.lastKeyError = [];
    }
}
