import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndoorWidgetComponent } from './indoor-widget.component';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';

@NgModule({
    exports: [IndoorWidgetComponent],
    declarations: [IndoorWidgetComponent],
    imports: [CommonModule, LoadersModule],
})
export class IndoorWidgetModule {}
