import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopupAskComponent } from './popup-ask.component';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';

@NgModule({
    exports: [PopupAskComponent],
    declarations: [PopupAskComponent],
    imports: [CommonModule, CrossButtonModule, BtnAcceptModule, BtnCancelModule],
})
export class PopupAskModule {}
