import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'outdoor-air-pin',
    templateUrl: './outdoor-air-pin.component.html',
    styleUrls: ['./outdoor-air-pin.component.less'],
})
export class OutdoorAirPinComponent implements OnChanges {
    @Input() title: string;
    @Input() value: number;
    @Input() color: string;
    @Input() selected = false;
    @Input() numberAfterDot?: number = 0;

    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.hasNoData = isFalseNumber(this.value);
        }
    }
}
