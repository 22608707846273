import { NgModule } from '@angular/core';

import { AqiIndicatorComponent } from './aqi-indicator.component';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';

@NgModule({
    imports: [ValueCircleModule],
    exports: [AqiIndicatorComponent],
    declarations: [AqiIndicatorComponent],
})
export class AqiIndicatorModule {}
