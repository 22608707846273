import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CorrelationData, EventStatus, IAnalysisEvent } from '@libs/common/models/basicModels';
import {
    getColorsForCorrelationTableLegend,
    getCorrelationColor,
    LABEL_CORRELATION,
} from '@libs/common/consts/correlation-zone.const';
import { setCurrentAnalysisEvent } from '@cityair/modules/analysis-events/store/actions';
import {
    selectAllPosts,
    selectPostNameById,
} from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectCorrelationChartData,
    selectPostsLineChartData,
    selectAnalysisDates,
    selectAverageChartData,
    selectDaysNumberData,
    selectCumChartData,
    selectForecastsLineChartData,
    selectCurrentAnalysisEvent,
    selectCorrelationDataEvents,
    selectAnalysisEventLoaded,
    selectIsDemoMode,
    selectGeneralStats,
    selectIsLoadingAnalysisCorrelation,
    selectIsLoadingAnalysisTimeline,
    selectIsLoadingEventDataById,
    selectIsLoadingCorrelationFullData,
    selectWindDataByEvent,
} from '@cityair/modules/analysis-events/store/selectors';
import {
    COLORS,
    COLORS_CORRELATION_WHO,
    COLORS_HOVER,
    TEST_NOW,
    WHO_DATA,
    WIND_DIR_BY_POST,
} from '@cityair/modules/analysis-events/constants';
import { TabModel } from '@libs/common/types/tab-model';
import { shortDateFormat } from '@libs/common/utils/date-formats';
import * as moment from 'moment-timezone';
import { ANALYSIS_EVENTS_PAGES } from '@cityair/modules/analysis-events/models';
import { NgLocalization } from '@angular/common';
import { AnalysisIntervalType } from '@cityair/modules/analysis/models';
import { WIND_DIR_DEG } from '@libs/common/consts/wind-directions.const';

@Component({
    selector: 'cityair-analysis-events-details',
    templateUrl: './analysis-events-details.component.html',
    styleUrls: ['./analysis-events-details.component.less'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisEventsDetailsComponent implements OnDestroy {
    @ViewChild('scrollable') scrollable: ElementRef<HTMLDivElement>;
    @ViewChild('collapseButton') collapseButton: ElementRef;
    @ViewChild('expandButton') expandButton: ElementRef;
    texts = TEXTS.ANALYSIS_EVENTS.analysisEvent;
    textMPC = TEXTS.ANALYSIS_EVENTS.registerEvents.pdkMr;
    textMonitoring = TEXTS.ANALYSIS_EVENTS.monitoring;
    textDays = TEXTS.NOTIFICATIONS.timeIntervalDays.zero;
    textMeasuresWind = TEXTS.MEASURES.WVA;
    public nowText = TEXTS.COMMON.now;
    public finishText = TEXTS.COMMON.now;
    ugM = TEXTS.ugM;
    EventStatus = EventStatus;
    public textNames = TEXTS.NAMES;
    currentId: string;
    loading = true;
    public ngDestroyed$ = new Subject<void>();
    displaySearchResult = false;
    public CommentForm: UntypedFormGroup;
    public uploadProgress = 0;
    public currentEvent: IAnalysisEvent;
    public WHOLables = this.texts.WHOChartLabels;
    public whoData;
    COLORS_CORRELATION_WHO = COLORS_CORRELATION_WHO;
    COLORS_CORRELATION = getColorsForCorrelationTableLegend();
    correlationData: CorrelationData;
    correlationValue = LABEL_CORRELATION;
    currentLang = LANGUAGE;
    charSize$ = new BehaviorSubject<{ width: number; left: number }>(null);
    currentWidthTimeline;
    TEST_NOW = TEST_NOW;
    filterTabs: TabModel[] = [
        {
            title: this.texts.statisticTabs['100'],
            type: '100',
        },
        {
            title: this.texts.statisticTabs['90'],
            type: '90',
        },
        {
            title: this.texts.statisticTabs['50'],
            type: '50',
        },
        {
            title: this.texts.statisticTabs['20'],
            type: '20',
        },
    ];
    filterCorrelationTabs: TabModel[] = [
        {
            title: this.texts.correlationTabsName.year,
            type: AnalysisIntervalType.year,
        },
        {
            title: this.texts.correlationTabsName.halfYear,
            type: AnalysisIntervalType.halfYear,
        },
        {
            title: this.texts.correlationTabsName.quarter,
            type: AnalysisIntervalType.quarter,
        },
        {
            title: this.texts.correlationTabsName.month,
            type: AnalysisIntervalType.month,
        },
    ];
    selectedTab: TabModel = this.filterTabs[0];
    selectedTabCorrelation: TabModel = this.filterCorrelationTabs[3];
    windData = WIND_DIR_BY_POST;
    selectPostNameById = selectPostNameById;
    selectAllPosts = selectAllPosts;
    selectCorrelationChartData = selectCorrelationChartData;
    selectPostsLineChartData = selectPostsLineChartData;
    selectAnalysisDates = selectAnalysisDates;
    selectAverageChartData = selectAverageChartData;
    selectDaysNumberData = selectDaysNumberData;
    selectCumChartData = selectCumChartData;
    selectForecastsLineChartData = selectForecastsLineChartData;
    selectCurrentAnalysisEvent = selectCurrentAnalysisEvent;
    selectIsLoadingEventDataById = selectIsLoadingEventDataById;
    selectIsLoadingAnalysisCorrelation = selectIsLoadingAnalysisCorrelation;
    selectIsLoadingAnalysisTimeline = selectIsLoadingAnalysisTimeline;
    selectIsLoadingCorrelationFullData = selectIsLoadingCorrelationFullData;
    selectGeneralStats = selectGeneralStats;
    selectWindDataByEvent = selectWindDataByEvent;
    labelsDataDayNumbers = Array.from(Array(24).keys()).map((v) => v.toString());
    textWind = TEXTS.WIND_DIRECTION;
    windDegByDir = WIND_DIR_DEG;
    COLORS = COLORS;
    COLORS_HOVER = COLORS_HOVER;
    isShowInfoPopup = false;
    averageActiveIndex = null;
    numberByDaysActiveIndex = null;
    isDemoMode = false;
    isCollapsedEvents = true;
    isFixedCollapseButton = true;
    initPositionCollapseButton: { top: number; left: number };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private ngLocalization: NgLocalization
    ) {
        this.route.params.subscribe((params) => {
            this.currentId = params?.id;
            _changeDetectorRef.markForCheck();
            this.store
                .select(selectAnalysisEventLoaded)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((v) => !!v)
                )
                .subscribe((groupId) => {
                    if (this.currentId) {
                        this.store.dispatch(setCurrentAnalysisEvent({ eventId: this.currentId }));
                    }
                });
        });
        this.store
            .select(selectCorrelationDataEvents)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v)
            )
            .subscribe((data) => {
                this.correlationData = data;
            });
        this.whoData = this.getWhoData();
        this.CommentForm = this.fb.group({
            comment: [''],
        });
        this.store
            .select(selectIsDemoMode)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isDemo) => {
                this.isDemoMode = isDemo;
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }

    close() {
        this.router.navigate([
            `/${ANALYSIS_EVENTS_PAGES.analysisEvents}/${ANALYSIS_EVENTS_PAGES.registerEvents}`,
        ]);
    }

    public displayDays(tab: TabModel) {
        this.selectedTab = tab;
    }

    public selectCorrelationPeriod(tab: TabModel) {
        this.selectedTabCorrelation = tab;
    }

    getCorrelationColorByPeriod(post1, post2, mmt) {
        if (!this.correlationData && !this.correlationData.hasOwnProperty(mmt)) {
            return null;
        }
        const value = this.getCorrelationValueByPeriod(post1, post2, mmt);
        return getCorrelationColor(value) ?? '#e6ecf2';
    }

    getCorrelationValueByPeriod(post1, post2, mmt) {
        if (!this.correlationData && !this.correlationData.hasOwnProperty(mmt)) {
            return null;
        }
        const data = this.correlationData[mmt][this.selectedTabCorrelation.type]?.coefficients;
        return data && data[post1][post2] ? data[post1][post2] : null;
    }

    onScroll() {
        if (this.isCollapsedEvents) {
            return;
        }
        this.isFixedCollapseButton =
            this.scrollable?.nativeElement.scrollTop >= this.initPositionCollapseButton?.top
                ? false
                : true;
    }

    showAllListEvents() {
        const leftPositionExpand = this.expandButton?.nativeElement?.getBoundingClientRect()?.left;
        this.isCollapsedEvents = false;
        setTimeout(() => {
            const topPositionButton =
                this.collapseButton?.nativeElement?.getBoundingClientRect()?.top;
            const contentRect = this.scrollable?.nativeElement?.getBoundingClientRect();
            if (topPositionButton <= contentRect?.height) {
                this.isFixedCollapseButton = false;
            }
            this.initPositionCollapseButton = {
                ...this.initPositionCollapseButton,
                top: topPositionButton - contentRect?.height - contentRect?.top,
                left: leftPositionExpand - 58,
            };
            this._changeDetectorRef.markForCheck();
        }, 100);
    }

    hiddenListEvents() {
        this.isCollapsedEvents = true;
        this.scrollable?.nativeElement?.scrollTo(60, 0);
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.isShowInfoPopup) {
            this.isShowInfoPopup = false;
        } else {
            this.close();
        }
    }

    private getWhoData() {
        const result = [];
        this.WHOLables.forEach((v, index) => {
            const data = WHO_DATA[index];
            if (data) {
                result.push({ data, label: v });
            }
        });
        return result;
    }

    public getPeriod(event, isFull?: boolean) {
        const { start, end } = event;
        const startTime = shortDateFormat(start);
        const endTime = shortDateFormat(end);
        if (this.isDemoMode && !isFull) {
            return `${startTime}`;
        }
        return end === null
            ? `${startTime} - <i>${this.texts.notEnded}</i>`
            : `${startTime} - ${endTime}`;
    }

    public getStart(event) {
        const { start } = event;
        return `${shortDateFormat(start)}`;
    }

    public getForecast(event) {
        const forecast = moment().add(10, 'minutes').toISOString();
        return event.status === EventStatus.End ? ` ---` : `${shortDateFormat(forecast)}`;
    }

    public showResultSearch() {
        setTimeout(() => {
            this.displaySearchResult = true;
            this._changeDetectorRef.detectChanges();
        }, 3000);
        for (let i = 0; i < 10; i++) {
            const ms = 300 * (i + 1);
            setTimeout(() => {
                this.uploadProgress = this.uploadProgress + 10;
                this._changeDetectorRef.detectChanges();
            }, ms);
        }
    }

    updateChartSize(value: { width: number; left: number }) {
        this.charSize$.next(value);
        this.currentWidthTimeline = value?.width;
        this._changeDetectorRef.detectChanges();
    }

    public postCountText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }

    public excessCountText(num: number = 0) {
        const { excess } = this.texts;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, excess[category]].join(' ');
    }

    public showInfoPopup() {
        this.isShowInfoPopup = !this.isShowInfoPopup;
    }

    public getMaxIndex(chartData, len) {
        const result: number[] = Array(len).fill(0);
        chartData.forEach((item) => {
            item.data?.forEach((v, index) => {
                result[index] = result[index] + v;
            });
        });

        return result.reduce((max_index, val, i, arr) => (val > arr[max_index] ? i : max_index), 0);
    }
}
