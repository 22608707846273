import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LngLat, LngLatBounds } from 'mapbox-gl';

import { TEXTS } from '@libs/common/texts/texts';
import { createBoundaries, createBBoxFromRectangle } from '@cityair/utils/utils';

@Component({
    selector: 'domain-border',
    templateUrl: 'domain-border.component.html',
    styleUrls: ['domain-border.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainBorderComponent implements OnChanges {
    @Input() bbox: number[];

    @Input() coordinates: GeoJSON.Position[];

    @Input() isOpenLayers = false;
    @Input() onlyBottom? = false;
    private static instanceCounter = 0;

    dataId: number;

    TEXTS = TEXTS;

    feature: GeoJSON.Feature;

    centerCoordinates: LngLat;

    northLat: number;

    southLat: number;

    constructor() {
        this.dataId = DomainBorderComponent.instanceCounter++;
    }

    ngOnChanges(changes: SimpleChanges) {
        let boundaries: number[][] = [];

        if (changes.bbox?.currentValue) {
            boundaries = createBoundaries(this.bbox);
            this.northLat = this.bbox[3];
            this.southLat = this.bbox[1];
            this.centerCoordinates = this.getCenterFromBBox(this.bbox);
        } else if (changes.coordinates?.currentValue) {
            boundaries = this.coordinates;
            const bbox = createBBoxFromRectangle(boundaries);
            this.northLat = bbox[3];
            this.southLat = bbox[1];
            this.centerCoordinates = this.getCenterFromBBox(bbox);
        }

        if (changes.bbox || changes.coordinates) {
            this.feature = {
                type: 'Feature',
                properties: {
                    color: '#95A0B3',
                    width: 1,
                    dasharray: [3, 3],
                },
                geometry: {
                    type: 'MultiLineString',
                    coordinates: [[...boundaries, boundaries[0]]],
                },
            };
        }
    }

    getCenterFromBBox(bbox: number[]) {
        return new LngLatBounds([bbox[0], bbox[1]], [bbox[2], bbox[3]]).getCenter();
    }
}
