import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalysisEventsState, eventsAdapter } from './reducers';
import { selectAllPosts, selectAllPostsDic } from '@cityair/modules/core/store/posts/posts.feature';
import {
    getCorrelationsForPosts,
    prepareEventsAnalysis,
} from '@cityair/modules/analysis-events/utils';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import {
    ADDITIONAL_HOURS_START_TIMELINE,
    CUMSUM_DATA,
    FORECAST_TIMELINE_DATA,
    TEST_BAR_CHART_DATA_AVERAGE,
    TEST_BAR_CHART_DATA_DAYS_NUMBER,
    WIND_DIR_BY_POST,
} from '@cityair/modules/analysis-events/constants';
import { QueryParams } from '@ngrx/data';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { timelineAdapter } from '@cityair/modules/core/store/reducers';
import { IntervalEnum } from '@cityair/namespace';
import { sortPostByDistance } from '@libs/common/utils/utils';
import * as moment from 'moment-timezone';
import { selectIAm } from '@cityair/modules/core/store/selectors';

export const selectAnalysisEventsState =
    createFeatureSelector<AnalysisEventsState>('analysisEvents');

export const selectCurrentAnalysisEvent = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.currentAnalysisEvent
);
export const selectGroupsListEvent = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => eventsAdapter.getSelectors().selectAll(state.listGroupsEvents)
);
export const selectAnalysisDates = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.analysisPostsDates
);
export const selectAnalysisEventLoaded = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isLoadedEvents
);
export const selectFilterSearchText = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.filter?.query
);
export const selectFilterStatus = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.filter?.status
);
export const selectFilterMmt = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.filter?.mmt
);
export const selectSorting = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.sortingData
);
export const selectRegisterListMmt = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.listMmt
);
export const selectCorrelationDataEvents = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.correlationData
);
export const selectIsDemoMode = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isDemoMode
);
export const selectCorrelationByEvent = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.correlationByEvent
);
export const selectIsLoadingAnalysisCorrelation = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isLoadingAnalysisCorrelation
);
export const selectIsLoadingAnalysisTimeline = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isLoadingAnalysisTimeline
);
export const selectIsLoadingCorrelationFullData = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isLoadingAnalysisEvent
);
export const selectWindData = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.windData
);
export const selectWindDataByEvent = createSelector(
    selectWindData,
    selectCurrentAnalysisEvent,
    selectIsDemoMode,
    (windData, event, isDemo) => {
        const id = event.uniqueEvents[0].post_id;
        if (isDemo) {
            return WIND_DIR_BY_POST?.[id] ?? null;
        } else if (windData && event && event?.uniqueEvents.length) {
            return windData?.[id] ?? null;
        }

        return null;
    }
);
export const selectDataForFilterMmt = createSelector(
    selectFilterMmt,
    selectRegisterListMmt,
    (mmt, list) => {
        if (list?.length) {
            return { list, mmt };
        }
        return null;
    }
);
export const selectDateRangeForList = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.dateRange
);
export const selectParamsForEventList = createSelector(
    selectDateRangeForList,
    selectGroupId,
    (dateRange, groupId) => {
        if (dateRange && groupId) {
            const params: QueryParams = {
                group: groupId,
                date__gt: dateRange.start,
                date__lt: dateRange.end,
            };
            return params;
        }
        return null;
    }
);
export const selectParamsForEventListInit = createSelector(
    selectGroupId,
    selectIAm,
    (groupId, iAm) => {
        if (groupId && iAm) {
            return {
                start: moment().startOf('day').subtract(1, 'months').toISOString(),
                end: moment().endOf('day').toISOString(),
            };
        }
        return null;
    }
);
export const selectPostsIdsByName = createSelector(
    selectAllPosts,
    selectFilterSearchText,
    (posts, filterText) => {
        if (filterText === undefined || filterText === null || filterText === '') {
            return [];
        }
        const result = posts?.filter((v) =>
            v?.name?.toLowerCase().includes(filterText?.toLowerCase())
        );
        return result.map((v) => v.id);
    }
);
export const selectGroupsListEventWithFilter = createSelector(
    selectGroupsListEvent,
    selectPostsIdsByName,
    selectFilterSearchText,
    selectFilterStatus,
    selectFilterMmt,
    selectIsDemoMode,
    (data, posts, filterText, status, mmt, isDemoMode) => {
        let result;
        const emptySearch = filterText === undefined || filterText === null || filterText === '';
        if (emptySearch && status === null && mmt === null) {
            result = [...data];
        } else {
            result = [...data];
            if (!emptySearch) {
                if (filterText.toLowerCase() === 'pm2.5') {
                    filterText = 'pm25';
                }
                result = [...result].filter((item) => {
                    const eventsIds = item?.events.map((v) => v.post_id);
                    const intersection = posts.filter((x) => eventsIds.includes(x));
                    return (
                        intersection.length ||
                        item.param?.toLowerCase().includes(filterText?.toLowerCase()) ||
                        item.id?.toString().toLowerCase().includes(filterText?.toLowerCase())
                    );
                });
            }
        }

        if (status) {
            if (isDemoMode) {
                result = [...result].filter((item) => item.status === status);
            } else {
                result = [...result].filter((item) => item.eventTypeByEnd === status);
            }
        }

        if (mmt) {
            result = [...result].filter((item) => item.param === mmt);
        }

        return result;
    }
);
export const selectCorrelationChartData = createSelector(
    selectCurrentAnalysisEvent,
    selectAllPostsDic,
    selectAllPosts,
    selectCorrelationByEvent,
    ({ events, uniqueEvents }, allPostsDic, allPosts, correlationData) => {
        if (events?.length && correlationData && allPosts.length) {
            const posts = uniqueEvents.length > 3 ? [] : allPosts;
            if (uniqueEvents.length > 3) {
                uniqueEvents.forEach((event) => {
                    const id = event?.post_id;
                    const post = allPostsDic[id];
                    if (post) {
                        posts.push(post);
                    }
                });
            }

            sortPostByDistance(allPostsDic[uniqueEvents[0].post_id], posts);
            const labels = posts.map((v) => v.name);

            const data = correlationData['1m'];
            const data24hour = getCorrelationsForPosts(posts, correlationData['24h']);
            const datasets = getCorrelationsForPosts(posts, data);
            return {
                datasets,
                labels,
                data24hour,
            };
        }

        return {
            datasets: [],
            labels: [],
            data24hour: [],
        };
    }
);
export const selectParamsForEventAnalysis = createSelector(
    selectCurrentAnalysisEvent,
    selectGroupId,
    selectIsDemoMode,
    (currentEvent, groupId, isDemoMode) => {
        if (currentEvent?.events?.length && groupId) {
            const event = prepareEventsAnalysis(currentEvent, isDemoMode);
            const params: QueryParams = {
                date__gt: moment(event.start)
                    .subtract(ADDITIONAL_HOURS_START_TIMELINE, 'hours')
                    .toISOString(),
                date__lt: event.end ?? event.forecast_end_time,
                interval: isDemoMode
                    ? IntervalEnum.hour.toString()
                    : event.end === null
                    ? IntervalEnum.hour.toString()
                    : IntervalEnum.min20.toString(),
                measure_scheme: MeasureScheme.default,
                concentration_in_mpc: false.toString(),
                packet_type: currentEvent.param,
            };
            return {
                groupId,
                params,
            };
        }
        return null;
    }
);
export const selectParamsForWindQuery = createSelector(
    selectCurrentAnalysisEvent,
    selectGroupId,
    selectIsDemoMode,
    (currentEvent, groupId, isDemoMode) => {
        if (currentEvent?.events?.length && groupId) {
            const event = prepareEventsAnalysis(currentEvent, isDemoMode);
            const params: QueryParams = {
                date: event.start,
                group: groupId,
            };
            return params;
        }
        return null;
    }
);
export const selectParamsForCorrelationByEvent = createSelector(
    selectCurrentAnalysisEvent,
    selectGroupId,
    (event, groupId) => {
        if (event && groupId) {
            const params: QueryParams = {
                date: event.start,
                packet_type: event.param,
                group_id: groupId,
            };
            return params;
        }
        return null;
    }
);
export const selectAnalysisTimelineData = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) =>
        timelineAdapter.getSelectors().selectAll(state.analysisPostsTimeLine)
);
export const selectAnalysisTimelineDataDic = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) =>
        timelineAdapter.getSelectors().selectEntities(state.analysisPostsTimeLine)
);
export const selectAnalysisForecastTimelineData = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => FORECAST_TIMELINE_DATA
);
export const selectIsLoadingEventDataById = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.isLoadingAnalysisEventById
);
export const selectCurrentEventData = createSelector(
    selectAnalysisEventsState,
    (state: AnalysisEventsState) => state.currentEventData
);
export const selectIsClearFilterIsDisabled = createSelector(
    selectDateRangeForList,
    selectFilterSearchText,
    selectFilterStatus,
    selectFilterMmt,
    (dateRange, searchQuery, status, mmt) =>
        !searchQuery &&
        status === null &&
        mmt === null &&
        (dateRange === null ||
            (dateRange &&
                moment(dateRange.start).isSame(
                    moment().startOf('day').subtract(1, 'months'),
                    'day'
                ) &&
                moment(dateRange.end).isSame(moment().endOf('day'), 'day')))
);
export const selectPostsLineChartData = createSelector(
    selectAnalysisTimelineDataDic,
    selectAnalysisDates,
    selectCurrentAnalysisEvent,
    (timelineData, dates, event) => {
        if (timelineData && dates.length && event) {
            const timeline = [];
            event?.uniqueEvents?.forEach((v) => {
                if (v.post_id && timelineData[v.post_id]) {
                    timeline.push(timelineData[v.post_id]);
                }
            });
            return {
                timeline,
                dates,
                event,
            };
        }
        return null;
    }
);
export const selectForecastsLineChartData = createSelector(
    selectAnalysisForecastTimelineData,
    selectCurrentAnalysisEvent,
    selectAllPostsDic,
    (timeline, event, posts) => {
        if (timeline && event && posts) {
            const result = [];
            event?.uniqueEvents.forEach((v) => {
                const post = posts[v.post_id];
                if (post && timeline.hasOwnProperty(v.post_id)) {
                    result.push({
                        data: timeline[post.id],
                        label: post?.name,
                    });
                }
            });
            return result;
        }
        return null;
    }
);
export const selectAverageChartData = createSelector(
    selectCurrentAnalysisEvent,
    selectAllPostsDic,
    selectCurrentEventData,
    selectIsDemoMode,
    (event, posts, data, isDemo) => {
        if (event && posts) {
            const result = [];
            const currentData = data?.average_durations_stats[event.param];
            event?.uniqueEvents.forEach((v) => {
                let dataChart = [];
                if (isDemo) {
                    dataChart = [...TEST_BAR_CHART_DATA_AVERAGE].map((item) => item[v.post_id]);
                } else if (currentData) {
                    const postData = currentData[v.post_id];
                    if (postData) {
                        if (postData.length === 0) {
                            dataChart = Array(5).fill(0);
                        } else {
                            postData.forEach((item) => {
                                dataChart.push(item.percentage);
                            });
                        }
                    }
                }
                result.push({ data: dataChart, label: posts[v.post_id]?.name });
            });

            return result;
        }

        return null;
    }
);
export const selectDaysNumberData = createSelector(
    selectCurrentAnalysisEvent,
    selectAllPostsDic,
    selectCurrentEventData,
    selectIsDemoMode,
    (event, posts, data, isDemo) => {
        if (event && posts && data) {
            const result = [];
            if (isDemo) {
                event?.uniqueEvents.forEach((v) => {
                    let isNotEmpty = false;
                    const dataObjects = [...TEST_BAR_CHART_DATA_DAYS_NUMBER].map(
                        (item) => item[v.post_id]
                    );
                    const postData = Object.values(dataObjects[0]);
                    isNotEmpty = postData.some((value) => value !== null);
                    if (postData) {
                        result.push({
                            data: !isNotEmpty ? null : postData,
                            label: posts[v.post_id]?.name,
                        });
                    }
                });
            } else {
                const currentData = data?.by_hours_of_day[event.param];
                if (currentData) {
                    event?.uniqueEvents.forEach((v) => {
                        const postData = currentData[v.post_id];
                        const isNotEmpty = postData.some((value) => value !== 0);
                        if (postData && isNotEmpty) {
                            result.push({
                                data: postData,
                                label: posts[v.post_id]?.name,
                            });
                        }
                    });
                }
            }

            return result;
        }

        return null;
    }
);
export const selectGeneralStats = createSelector(
    selectCurrentEventData,
    selectCurrentAnalysisEvent,
    (data, event) => {
        if (data && event) {
            return data.general_stats ? data.general_stats[event.param] : null;
        }

        return null;
    }
);

export const selectCumChartData = createSelector(
    selectCurrentAnalysisEvent,
    selectAllPostsDic,
    (event, posts) => {
        if (event && posts) {
            const result = [];
            event?.uniqueEvents.forEach((v) => {
                const data = CUMSUM_DATA[v.post_id];
                if (data) {
                    result.push({ data, label: posts[v.post_id]?.name });
                }
            });
            return result;
        }

        return null;
    }
);
export const selectPostListByEvent = (event) =>
    createSelector(selectAllPostsDic, (allPost) => {
        if (event) {
            const text = [];
            event.uniqueEvents?.forEach((item) => {
                const name = allPost[item.post_id]?.name;
                if (name) {
                    text.push(name);
                }
            });
            return text.join('<br/>');
        }

        return '';
    });
