import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { isFalseNumber } from '@libs/common/utils/utils';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'plumes-control-points-card',
    templateUrl: 'plumes-control-points-card.component.html',
    styleUrls: ['plumes-control-points-card.component.less'],
})
export class PlumesControlPointsCard {
    @Input() currentPointValues: number;
    @Input() measureUnit: string;
    @Input() point: ControlPoint;
    @Input() isActive: boolean;
    @Input() numberAfterDot: number;

    @Output() openActionsPopup = new EventEmitter<any>();

    popupActive = false;

    isFalseNumber = isFalseNumber;

    constructor(public popupProvider: OffPanelPopupService) {}

    openActions($event) {
        this.openActionsPopup.emit($event);
    }
}
