import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutdoorAirPinComponent } from './outdoor-air-pin.component';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { ValueRoundedModule } from '../value-rounded/value-rounded.module';

@NgModule({
    exports: [OutdoorAirPinComponent],
    declarations: [OutdoorAirPinComponent],
    imports: [CommonModule, PipesModule, ValueRoundedModule],
})
export class OutdoorAirPinModule {}
