import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ReportsMainComponent } from './components/reports-main/reports-main.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { CompareComponent } from './components/compare/compare.component';
import { AddReportComponent } from './components/add-report/add-report.component';
import { REPORT_INIT_PAGE } from './models';

export const reportsRoutes: Routes = [
    {
        path: '',
        component: ReportsMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: REPORT_INIT_PAGE,
            },
            {
                path: 'analytics',
                component: AnalyticsComponent,
            },
            {
                path: 'list',
                component: ReportsListComponent,
            },
            {
                path: 'compare',
                component: CompareComponent,
            },
            {
                path: 'add/:id',
                component: AddReportComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(reportsRoutes)],
    exports: [RouterModule],
})
export class ReportsRoutingModule {}
