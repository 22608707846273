import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { TabModel } from '@libs/common/types/tab-model';
import { Store } from '@ngrx/store';
import {
    changeCurrentNotificationType,
    getNotificationsEventsDevice,
    getNotificationsEventsPdk,
    goToEvent,
    loadMoreEvents,
    markNotificationAsVisited,
    openSettingForm,
    setFilter,
} from '@cityair/modules/notifications/store/actions';
import { allowModule, selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
    selectNotificationSettingById,
    selectCurrentEventType,
    selectIsLoadingEventsList,
    selectAllNotificationsEventsWithFilter,
    selectSettingsNotifyByType,
    selectIsAvailableLoadMore,
} from '@cityair/modules/notifications/store/selectors';
import { BaseNotificationEvent, NotificationType } from '@libs/common/models/basicModels';
import {
    selectAllPostsIds,
    selectPostEntitiesById,
} from '@cityair/modules/core/store/posts/posts.feature';

import { selectMyRole } from '@cityair/modules/core/store/selectors';
import * as moment from 'moment-timezone';
import { AVAILABLE_DATA_IN_MONTH } from '@cityair/config';
import { Subject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DIFF_SCROLL_COUNT } from '@cityair/modules/notifications/constants';
import { Router } from '@angular/router';
import { ANALYSIS_EVENTS_PAGES } from '@cityair/modules/analysis-events/models';
import { NameModules } from '@libs/common/enums/name-modules';

@Component({
    selector: 'cityair-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.less'],
})
export class EventsComponent implements OnDestroy {
    @ViewChild('feed') feed: CdkVirtualScrollViewport;

    textsNotification = TEXTS.NOTIFICATIONS;
    filterText = '';
    public textClear = TEXTS.LIST_OM.clearSearch;
    initialScrollPosition = true;
    selectedCount = 0;
    disableNavigation = false;
    public todayDate = new Date();
    public mosIds: any[] = [];
    public loading = true;
    onDestroy$ = new Subject<void>();
    public isLoadingList: boolean;
    public isAvailableLoadMore: boolean;
    filterTabs: TabModel[] = [
        {
            title: this.textsNotification.measures,
            type: NotificationType.pdkExcess,
        },
        {
            title: this.textsNotification.service,
            type: NotificationType.deviceIncident,
        },
    ];

    selectedTab: TabModel = this.filterTabs[0];
    data: {
        item: BaseNotificationEvent;
        annotation: string;
    }[];
    NotificationType = NotificationType;
    selectPostEntitiesById = selectPostEntitiesById;
    selectNotificationSettingById = selectNotificationSettingById;
    selectMyRole = selectMyRole;
    selectSettingsNotifyByType = selectSettingsNotifyByType;
    allowModule = allowModule;
    eventsName = NameModules.events;

    constructor(public store: Store, private router: Router) {
        this.store
            .select(selectGroupId)
            .pipe(
                filter((v) => !!v),
                take(1)
            )
            .subscribe((data) => {
                this.store.dispatch(getNotificationsEventsPdk());
                this.store.dispatch(getNotificationsEventsDevice());
            });

        this.store
            .select(selectAllNotificationsEventsWithFilter)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.data = data;
                this.selectedCount = data?.length ?? 0;
            });
        this.store
            .select(selectIsLoadingEventsList)
            .pipe(take(2))
            .subscribe((data) => (this.loading = data));
        this.store
            .select(selectCurrentEventType)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.selectedTab = this.filterTabs.find((v) => v.type === data)));
        this.store
            .select(selectAllPostsIds)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((mos) => {
                this.mosIds = mos;
            });
        this.store
            .select(selectIsLoadingEventsList)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((loading) => {
                this.isLoadingList = loading;
            });
        this.store
            .select(selectIsAvailableLoadMore)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isAvailable) => {
                this.isAvailableLoadMore = isAvailable;
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    public filterUpdate(text: string) {
        this.filterText = text;
        this.store.dispatch(setFilter({ payload: text }));
    }

    public showSelectedTab(tab: TabModel) {
        this.store.dispatch(changeCurrentNotificationType({ payload: tab.type }));
    }

    public getPostId(notification) {
        if (notification.type === NotificationType.pdkExcess) {
            return notification.post_id;
        } else {
            return notification?.service_excess_info?.post_id;
        }
    }

    public checkNotifyClickable(item: BaseNotificationEvent) {
        const today = moment();
        const begin = moment(item.begin_time);
        const diffMonth = today.diff(begin, 'months', true);
        const moId =
            item.type === NotificationType.pdkExcess
                ? item?.post_id
                : item?.service_excess_info?.post_id;

        if (moId && this.mosIds.indexOf(moId) >= 0 && diffMonth <= AVAILABLE_DATA_IN_MONTH) {
            return false;
        }

        return true;
    }

    public gotoMonitoringObject(event: BaseNotificationEvent) {
        if (!this.checkNotifyClickable(event)) {
            this.store.dispatch(goToEvent({ payload: event }));
        }
    }

    public openSettingsFor(setting) {
        this.store.dispatch(
            openSettingForm({ settings_type: this.selectedTab.type, currentSetting: setting })
        );
    }

    public openSubscriptions = () => {
        this.store.dispatch(
            openSettingForm({ settings_type: this.selectedTab.type, currentSetting: null })
        );
    };

    public markNotificationAsVisited(item: BaseNotificationEvent) {
        this.store.dispatch(markNotificationAsVisited({ payload: item }));
    }

    public scrollTop() {
        this.feed?.scrollToIndex(0);
    }

    public handlerScroll($event) {
        if (
            this.selectedCount - $event < DIFF_SCROLL_COUNT &&
            this.isAvailableLoadMore &&
            !this.isLoadingList
        ) {
            this.store.dispatch(loadMoreEvents());
        }
    }

    public gotToAnalysisEvents() {
        this.router.navigate([
            `/${ANALYSIS_EVENTS_PAGES.analysisEvents}/${ANALYSIS_EVENTS_PAGES.registerEvents}`,
        ]);
    }
}
