import { parseUrl } from '@cityair/utils/utils';

export function checkInviteLink(): { email: string; inviteCode: string } | null {
    const saved = parseUrl();

    const props = <ReturnType<typeof checkInviteLink>>{};

    if (saved.action === 'invite') {
        props.email = saved.email;
        props.inviteCode = saved.hash;

        return {
            email: saved.email,
            inviteCode: saved.hash,
        };
    }

    return null;
}
