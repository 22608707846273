import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common/models/feature';
import { CONTRIBUTIONS_COLORS_SOURCES_ORDER } from '@libs/common/consts/demo-impact-groups';
import { ContributionLabel } from '@libs/shared-ui/components/timeline-panel/models/core';

@Pipe({
    name: 'contributionLabels',
})
export class ContributionLabelsPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[]): ContributionLabel[] {
        const labels: ContributionLabel[] = [];
        if (data.length === 1 && mmts.length === 1 && data[0]?.properties?.contributions) {
            const contribData = data[0]?.properties?.contributions[mmts[0]];
            if (contribData) {
                const sources = data[0]?.properties?.contributionsDetails?.sources;
                const order = data[0]?.properties?.contributionsDetails?.order;
                const sourceKeys = Object.keys(contribData);
                sourceKeys.forEach((key, k) => {
                    const colorIndex = order?.findIndex((v) => v === sources[key]?.id);
                    const color = CONTRIBUTIONS_COLORS_SOURCES_ORDER[colorIndex] ?? '#84e39e';
                    labels.push({
                        id: key,
                        color,
                        name: sources[key]?.name,
                    });
                });
            }
        }

        return labels;
    }
}
