import { createAction, props } from '@ngrx/store';
import { GroupUserParams, User, IBasicResponse } from '@libs/common/models/basicModels';
import { HttpErrorResponse } from '@angular/common/http';

export enum UsersActions {
    loadUsersList = '[UsersActions] load users',
    setUsersList = '[UsersActions] set users list',
    isLoadingUsers = '[UsersActions] is loading users',
    foundUser = '[UsersActions] found user',
    setFoundUserResult = '[Users Actions] set Found User Result',
    createUser = '[Users Actions] create User',
    createUserSuccess = '[Users Actions] create user success',
    updateUser = '[Users Actions] update User',
    updateUserSuccess = '[Users Actions] update user success',
    changeCurrentUserPage = '[Users Actions] change current user pages',
    openUserForm = '[Users Actions] open user form',
    closeUserForm = '[Users Actions] close user form',
    deleteUser = '[Users Actions] delete user',
    deleteUserSuccess = '[Users Actions] delete user success',
    setLoadingForm = '[Users Actions] is loading from',
    setFormError = '[Users Actions] is loading from',
    toggleUserForm = '[Users Actions] toggle user form',
}
export const loadUsersList = createAction(UsersActions.loadUsersList);

export const isLoadingUsers = createAction(
    UsersActions.isLoadingUsers,
    props<{ payload: boolean }>()
);
export const setUsersList = createAction(
    UsersActions.setUsersList,
    props<{ payload: IBasicResponse }>()
);
export const foundUser = createAction(UsersActions.foundUser, props<{ payload: string }>());
export const setFoundUserResult = createAction(
    UsersActions.setFoundUserResult,
    props<{ payload: any }>()
);
export const createUser = createAction(
    UsersActions.createUser,
    props<{ payload: GroupUserParams; isNew: boolean }>()
);
export const createUserSuccess = createAction(
    UsersActions.createUserSuccess,
    props<{ payload: User }>()
);
export const updateUser = createAction(
    UsersActions.updateUser,
    props<{ payload: GroupUserParams }>()
);
export const updateUserSuccess = createAction(
    UsersActions.updateUserSuccess,
    props<{ payload: User }>()
);

export const closeUserForm = createAction(UsersActions.closeUserForm);
export const deleteUser = createAction(UsersActions.deleteUser, props<{ userId: string }>());
export const deleteUserSuccess = createAction(
    UsersActions.deleteUserSuccess,
    props<{ userId: string }>()
);
export const setLoadingForm = createAction(
    UsersActions.setLoadingForm,
    props<{ payload: boolean }>()
);
export const setFormError = createAction(
    UsersActions.setFormError,
    props<{ payload: HttpErrorResponse }>()
);
export const toggleUserForm = createAction(
    UsersActions.toggleUserForm,
    props<{ payload: boolean }>()
);
