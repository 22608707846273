import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    setActiveCorrelationPost,
    setActiveHoverLine,
    setCurrentTab,
    setPostHover,
    setSearchQueryPosts,
    setSortingPosts,
} from '@cityair/modules/analysis/store/actions';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import {
    selectPostNameById,
    selectPostParentNameIfNeed,
} from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectErrorLoadCorrelationData,
    selectIsLoadingData,
    selectGeoParamsByActivePost,
    selectSortingDataPosts,
    selectSearchQueryPosts,
    selectAllPostsAnalysis,
} from '@cityair/modules/analysis/store/selectors';
import { TEXTS } from '@libs/common/texts/texts';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter, Subject } from 'rxjs';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { getShiftMapByCorrelationPins } from '@cityair/config';
import { takeUntil } from 'rxjs/operators';
import { SearchInputBasicComponent } from '@libs/shared-ui/components/search-input-basic/search-input-basic.component';

@Component({
    selector: 'cityair-posts',
    templateUrl: './posts.component.html',
    styleUrls: ['./posts.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostsComponent implements OnDestroy {
    text = TEXTS.ANALYSIS;
    public textClear = TEXTS.LIST_OM.clearSearch;
    public textNames = TEXTS.NAMES;
    searchQuery = '';
    showInfoPopup = false;
    public sortDirection: number;
    public sortField: string;
    currentId: string;
    selectIsLoadingData = selectIsLoadingData;
    selectErrorLoadCorrelationData = selectErrorLoadCorrelationData;
    selectPostNameById = selectPostNameById;
    selectPostParentNameIfNeed = selectPostParentNameIfNeed;
    selectAllPostsAnalysis = selectAllPostsAnalysis;

    public ngDestroyed$ = new Subject<void>();
    private isInit = true;
    private initPost: string;

    @ViewChild('contentWrapper') contentWrapper: ElementRef<HTMLElement>;
    @ViewChild('searchInput') searchInputComponent: SearchInputBasicComponent;
    private tsForScroll = 1000;
    constructor(
        readonly store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private mapActions: MapboxActions
    ) {
        this.store.dispatch(setCurrentTab({ payload: ANALYSIS_PAGES.posts }));
        this.route.params.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
            if (this.isInit) {
                this.initPost = params?.id;
            } else {
                this.currentId = params?.id;
                if (this.currentId) {
                    this.store.dispatch(setActiveCorrelationPost({ postId: this.currentId }));
                } else {
                    this.store.dispatch(setActiveCorrelationPost({ postId: null }));
                }
            }
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(selectAllPostsAnalysis)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length && this.isInit && this.initPost !== null)
            )
            .subscribe((posts) => {
                const currentPost = posts?.find((v) => v.id === this.initPost);
                this.isInit = false;
                if (currentPost) {
                    this.currentId = this.initPost;
                    this.store.dispatch(setActiveCorrelationPost({ postId: this.currentId }));
                } else {
                    this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}`]);
                }
            });
        this.store
            .select(selectGeoParamsByActivePost)
            .pipe(
                filter((v) => v !== null),
                distinctUntilChanged(this.same),
                takeUntil(this.ngDestroyed$)
            )
            .subscribe((data) => {
                const zoom = this.mapActions.currentZoom > 9.5 ? this.mapActions.currentZoom : 9.5;
                this.mapActions.centringOnLine(
                    Number(data.center[1]),
                    Number(data.center[0]),
                    false,
                    data.zoomLevel,
                    getShiftMapByCorrelationPins()
                );
                setTimeout(() => this.correctScroll(), this.tsForScroll);
            });
        this.store
            .select(selectSortingDataPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.sortDirection = data.dir;
                this.sortField = data.name;
            });
        this.store
            .select(selectSearchQueryPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((query) => {
                this.searchQuery = query;
                if (query === null) {
                    this.searchInputComponent?.form.reset();
                }
            });
    }

    showInfo() {
        this.showInfoPopup = !this.showInfoPopup;
    }

    ngOnDestroy() {
        this.store.dispatch(setActiveHoverLine({ payload: [] }));
        this.ngDestroyed$.next();
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.currentId) {
            this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}`]);
        }
    }

    selectPost(post) {
        if (this.currentId === post.id) {
            return;
        } else {
            this.router.navigate([
                `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}/${post.id}`,
            ]);
        }
    }

    close(event) {
        event.stopPropagation();
        this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}`]);
    }

    onMouseEnterPost(id: string) {
        this.store.dispatch(setPostHover({ payload: [id] }));
    }

    onMouseLeavePost(id: string) {
        this.store.dispatch(setPostHover({ payload: [] }));
    }

    public textChangeIn($event) {
        this.store.dispatch(setSearchQueryPosts({ payload: $event }));
    }

    public setSortingCb(sortCb: string): void {
        let sortDirection = this.sortDirection;
        let sortField = this.sortField;
        if (this.sortField === sortCb) {
            sortDirection *= -1;
        } else {
            sortField = sortCb;
        }
        this.store.dispatch(
            setSortingPosts({
                payload: {
                    name: sortField,
                    dir: sortDirection,
                },
            })
        );
    }

    private correctScroll() {
        this.tsForScroll = 100;
        const currentEl = document.querySelector('.active-post') as HTMLElement;
        if (currentEl) {
            currentEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }

    private same(a, b): boolean {
        return (
            a.center[0] === b.center[0] &&
            a.center[1] === b.center[1] &&
            a.zoomLevel === b.zoomLevel
        );
    }
}
