import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isFalseNumber } from '@libs/common/utils/utils';
import { LANGUAGE } from '@libs/common/texts/texts';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'cityair-correlation-pin',
    templateUrl: './correlation-pin.component.html',
    styleUrls: ['./correlation-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrelationPinComponent implements OnChanges {
    isFalseNumber = isFalseNumber;
    @Input() value: number;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() notActive: boolean;
    @Input() valueMode: boolean;
    public currentLang = LANGUAGE;
    displayValue: string;
    ngOnChanges(changes: SimpleChanges): void {
        this.displayValue = isFalseNumber(this.value)
            ? this.valueMode && !this.selected
                ? ' - '
                : ''
            : `${formatNumber(Number(this.value), this.currentLang, '1.2-2')}`;
    }
}
