import { Component, Input } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'button-download-data',
    template: `<div class="button-download-data">
        <div class="button-download-data__img"></div>
        <div class="button-download-data__text">{{ text }}</div>
    </div>`,
    styleUrls: ['./button-download-data.component.less'],
})
export class ButtonDownloadDataComponent {
    @Input() text = TEXTS.EDIT_STATION.downloadXLSData;
}
