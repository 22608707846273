import { ModulePageConfig } from '@cityair/namespace';

export const indoorWidgetModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: false,
    enableCalendar: false,
    enablePlumeButton: false,
    enableAqi: false,

    enableMap: false,
    enableOldTimeline: false,
    enableTopElements: false,
    fullScreen: true,
};
