import { Component, OnDestroy } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@cityair/modules/login/services/auth/auth.service';
import { Subject } from 'rxjs';
import { TabModel } from '@libs/common/types/tab-model';
import {
    ANALYSIS_EVENTS_INIT_PAGE,
    ANALYSIS_EVENTS_PAGES,
} from '@cityair/modules/analysis-events/models';
import { initAnalysisEvent } from '@cityair/modules/analysis-events/store/actions';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { filter, takeUntil } from 'rxjs/operators';
import { selectIsDemoMode } from '@cityair/modules/analysis-events/store/selectors';

@Component({
    selector: 'cityair-analysis-events-main',
    templateUrl: './analysis-events-main.component.html',
    styleUrls: ['./analysis-events-main.component.less'],
})
export class AnalysisEventsMainComponent implements OnDestroy {
    public translateText = TEXTS.ANALYSIS_EVENTS;
    public ngDestroyed$ = new Subject<void>();
    public showLogout = false;
    public translateAccessType = TEXTS.USER_ACCESS_TYPE;
    public exitText = TEXTS.LOGIN_WINDOWS.logout;
    readonly mainComponentUrl = `/${ANALYSIS_EVENTS_PAGES.analysisEvents}/`;
    private initActive = ANALYSIS_EVENTS_INIT_PAGE;
    public isDemoMode = false;
    constructor(private router: Router, public store: Store, public authService: AuthService) {
        this.store
            .select(selectGroupId)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((groupId) => !!groupId)
            )
            .subscribe((groupId) => {
                this.store.dispatch(initAnalysisEvent());
            });
        this.store
            .select(selectIsDemoMode)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isDemo) => {
                this.isDemoMode = isDemo;
            });
    }
    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }

    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: ANALYSIS_EVENTS_PAGES.registerEvents,
            title: this.translateText.registerEventsTitle,
        },
    ];
    selectedTab: TabModel = this.tabs.find((item) => item.id === this.initActive);

    getActiveTab() {
        const tabId = this.router.url
            ? this.router.url.replace(this.mainComponentUrl, '')
            : this.selectedTab;
        return this.tabs.find((item) => item.id === tabId);
    }

    openPage(event) {
        this.router.navigate([this.mainComponentUrl + event.id]);
    }
}
