import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { AVAILABLE_MOBILE_PAGES } from '@libs/common/consts/available-mobile-pages';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
@Injectable({
    providedIn: 'root',
})
export class MobileGuard implements CanActivate {
    constructor() {}

    canActivate(route: ActivatedRouteSnapshot) {
        const _segments = location.href.split('/');
        const page = _segments[_segments.length - 1] as MAIN_PAGES;
        const pageIsCorrect = Object.values(MAIN_PAGES).includes(page);

        if (
            detectMobile() &&
            pageIsCorrect &&
            page !== MAIN_PAGES.default &&
            !AVAILABLE_MOBILE_PAGES.includes(page)
        ) {
            location.href = location.origin + '/' + MAIN_PAGES.default;
            return false;
        }

        return true;
    }
}
