import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoCorrelationPopupComponent } from './info-correlation-popup.component';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';

@NgModule({
    imports: [CommonModule, CrossButtonModule, TooltipsModule],
    exports: [InfoCorrelationPopupComponent],
    declarations: [InfoCorrelationPopupComponent],
})
export class InfoCorrelationPopupModule {}
