import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as moment from 'moment-timezone';
import { CalendarOutput, formatDayMonthYear } from '@libs/common/utils/date-formats';
import { DateRange } from '../../../models';

@Component({
    selector: 'forecast-calendar',
    templateUrl: 'forecast-calendar.component.html',
    styleUrls: ['forecast-calendar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastCalendarComponent implements OnInit, OnChanges {
    @Input() dateRange: DateRange;
    @Input() minDate: string = null;
    @Input() maxDate: string = null;
    @Input() dropdownMode = false;

    @Output() updateDateRange = new EventEmitter<CalendarOutput>();

    showCalendar = false;
    begin: number;
    end: number;
    monthsVisible: number;
    columns: number;
    numberOfMonth: number;
    numberOfFutureDays = 0;
    public intervalText = '';
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;

    ngOnInit() {
        this.setCalendarConfig();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateRange && changes.dateRange.currentValue) {
            this.begin = this.dateRange.startDate;
            this.end = this.dateRange.finishDate;
            this.intervalText = this.getIntervalText();
        }
    }

    public getIntervalText() {
        const begin = moment(this.begin);
        const end = moment(this.end);

        const textStart = formatDayMonthYear(begin);
        const textEnd = formatDayMonthYear(end);

        return `${textStart} - ${textEnd}`;
    }

    updateTime = (begin: number, end: number) => {
        // valid by config;
        const maxDate = moment(this.maxDate).valueOf();
        this.end = end < maxDate ? end : maxDate;
        const minDate = moment(this.minDate).valueOf();
        this.begin = begin < minDate ? minDate : begin;

        this.intervalText = this.getIntervalText();
        this.updateDateRange.emit({ begin: this.begin, end: this.end });
        this.showCalendar = false;
    };

    toggleShow = () => {
        this.showCalendar = !this.showCalendar;
        if (this.showCalendar) {
            const calendarConf: { calendarMinDate?: string; numberOfFutureDays?: number } = {
                calendarMinDate: moment(this.minDate).subtract(1, 'days').toISOString(),
                numberOfFutureDays: this.numberOfFutureDays,
            };
            this.handleUpdateDays(calendarConf?.numberOfFutureDays, calendarConf?.calendarMinDate);
        }
    };

    updateDays = (fn: () => void) => (this.handleUpdateDays = fn);

    close = () => {
        this.showCalendar = false;
    };

    private setCalendarConfig() {
        if (this.minDate && this.maxDate) {
            const startDate = moment(this.minDate).startOf('months');
            const endDate = moment(this.maxDate).endOf('months');
            const diff = moment(endDate).diff(moment(startDate), 'months', true);
            const monthDifference = Math.ceil(diff);
            this.monthsVisible = monthDifference >= 2 ? 2 : 1;
            this.columns = monthDifference >= 2 ? 2 : 1;
            const increment = monthDifference % this.columns ?? 0;
            this.numberOfMonth = monthDifference >= 12 ? 12 : monthDifference + increment;
            const diffDays = moment(this.maxDate).diff(moment(), 'days', true);
            this.numberOfFutureDays = Math.ceil(diffDays);
        } else {
            this.monthsVisible = 2;
            this.columns = 2;
            this.numberOfMonth = 12;
        }
    }
}
