import { RasterSource } from 'mapbox-gl';
import * as moment from 'moment-timezone';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GroupTilePlayerSettings } from '@libs/common/models/feature-config';
import { TIMELINE_STEP } from '@cityair/libs/shared/utils/config';

type CustomLayer = {
    id: string;
    opacity: number;
    source: RasterSource;
};

const PREFIX = 'tile-player';
const DEFAULT_OPACITY = 0.5;
const PRELOADED_STEPS = 3;
const TRANSITION_SPLIT = 0.8;

const DEFAULT_SOURCE_SETTINGS = {
    tiles: [''],
    tileSize: 512,
};

function createTimeToSteps(end: number, timeStep: number, layers: number) {
    return (timestamp: number) => layers - (end - timestamp) / timeStep;
}

class TimeWindow {
    private source: CustomLayer[];
    window: CustomLayer[];

    constructor(source: CustomLayer[]) {
        this.source = source;
        this.window = [];
    }

    get start() {
        return this.source.findIndex((l) => l === this.window[0]);
    }

    get end() {
        return this.source.findIndex((l) => l === this.window[this.window.length - 1]);
    }

    appendFrame() {
        this.window.push(...this.source.slice(this.end + 1, this.end + 2));
    }

    resetTo(index: number) {
        this.window = this.source.slice(index, index + PRELOADED_STEPS);
    }
}

export class TilePlayer {
    layersTemplate: (frame: number | string) => CustomLayer;

    layers: CustomLayer[] = [];

    layerId: string;

    layersWindow: TimeWindow;

    currentLayer: CustomLayer;

    timeToStep: (timestamp: number) => number = () => 0;

    sourceSettings = DEFAULT_SOURCE_SETTINGS;

    timeSubscription: Subscription;

    private static instanceCounter = 0;

    dateFormat: string;

    constructor(
        time$: Observable<number>,
        datesRange: Date[],
        playerSettings: GroupTilePlayerSettings
    ) {
        this.layerId = playerSettings.layerId || `generatedId-${TilePlayer.instanceCounter}`;

        let availableDatesRange = playerSettings.datesRange.map((d) => new Date(d));

        if (availableDatesRange?.length !== 2) {
            availableDatesRange = datesRange;
        }

        if (!this.validateDatesRange(availableDatesRange) || !this.validateDatesRange(datesRange)) {
            return;
        }

        const datesIntersection = this.getIntersectionInterval(datesRange, availableDatesRange);

        if (!this.validateDatesRange(datesIntersection)) {
            return;
        }

        const [fromTimestamp, toTimestamp] = datesIntersection;
        const frameDuration = playerSettings.timeStep;

        const totalFrames = Math.round((toTimestamp - fromTimestamp) / frameDuration) + 1;

        if (isNaN(totalFrames) || totalFrames < 0) {
            console.log(`TilePlayer: frames count is not valid [${totalFrames}]`);
            return;
        }

        const sourceSettings = Object.assign(this.sourceSettings, playerSettings.layer);

        this.dateFormat = playerSettings.dateFormat;

        this.layersTemplate = this.createLayerSettings(sourceSettings);

        // TODO: may not work for the FIRMS
        const [startOfRange, endOfRange] = [fromTimestamp, toTimestamp];

        this.layers = Array(totalFrames)
            .fill(startOfRange)
            .map((ts: number, i) =>
                this.layersTemplate(this.getLayerIdFromStep(ts, frameDuration, i))
            );

        this.timeToStep = createTimeToSteps(endOfRange, frameDuration, totalFrames);

        this.layersWindow = new TimeWindow(this.layers);

        this.timeSubscription = time$.pipe(filter((ts) => !!ts)).subscribe((timestamp) => {
            const step = Math.trunc(this.timeToStep(timestamp));

            const id = this.getLayerIdFromStep(startOfRange, frameDuration, step);

            const layerIndex = this.getFrameIndex(id);

            const layer = this.layers[layerIndex];

            if (!layer) {
                this.hideLayers();
            } else if (layer !== this.currentLayer) {
                const windowBeginIndex = this.layersWindow.start;
                const windowEndIndex = this.layersWindow.end;

                if (layerIndex >= windowBeginIndex && layerIndex <= windowEndIndex) {
                    this.layersWindow.appendFrame();
                } else {
                    this.layersWindow.resetTo(layerIndex);
                }

                this.transition(layerIndex, playerSettings.opacity);

                this.currentLayer = layer;
            }
        });

        TilePlayer.instanceCounter++;
    }

    private transition(layerIndex: number, opacity: number = DEFAULT_OPACITY) {
        const layer = this.layers[layerIndex];

        if (layerIndex > 0) {
            this.prepareTransitionFor(layerIndex);

            const previousLayer = this.layers[layerIndex - 1];

            this.startTransition(previousLayer, layer, opacity);

            setTimeout(() => {
                this.finishTransition(previousLayer, layer, opacity);
            }, TIMELINE_STEP / 2);
        } else if (layerIndex === 0) {
            layer.opacity = opacity;

            if (this.currentLayer && this.layersWindow.window.length > 1) {
                this.currentLayer.opacity = 0;
            }
        }
    }

    private prepareTransitionFor(layerIndex: number) {
        this.hideLayers(this.layers.filter((_, i) => i < layerIndex - 1 || i > layerIndex));
    }

    private hideLayers(layers?: CustomLayer[]) {
        (layers || this.layers).forEach((layer) => {
            layer.opacity = 0;
        });
    }

    private startTransition(fromLayer: CustomLayer, toLayer: CustomLayer, opacity: number) {
        fromLayer.opacity = (1 - TRANSITION_SPLIT) * opacity;
        toLayer.opacity = TRANSITION_SPLIT * opacity;
    }

    private finishTransition(fromLayer: CustomLayer, toLayer: CustomLayer, opacity: number) {
        if (toLayer.opacity === TRANSITION_SPLIT * opacity) {
            fromLayer.opacity = 0;
            toLayer.opacity = opacity;
        }
    }

    private getLayerIdFromStep(timestamp: number, frameDuration: number, step: number) {
        return moment(Math.round(timestamp + step * frameDuration))
            .utcOffset(0)
            .format(this.dateFormat);
    }

    private getFrameIndex(id: string) {
        return this.layers.findIndex((l) => l.id === `${PREFIX}-${this.layerId}-${id}`);
    }

    private getIntersectionInterval(range1: Date[], range2: Date[]) {
        return [
            Math.max(range1[0].valueOf(), range2[0].valueOf()),
            Math.min(range1[1].valueOf(), range2[1].valueOf()),
        ];
    }

    private validateDatesRange(datesRange: (Date | number)[]) {
        if (datesRange?.length !== 2 || datesRange[1] < datesRange[0]) {
            console.log(`TilePlayer: dates range is not valid [${datesRange}]`);
            return false;
        }

        return true;
    }

    private createLayerSettings(sourceSettings: RasterSource) {
        return (frameIndex: number | string) => ({
            id: `${PREFIX}-${this.layerId}-${frameIndex}`,
            opacity: 0,
            source: {
                type: 'raster',
                tiles: sourceSettings.tiles.map((tpl) => tpl.replace(/\{t\}/g, `${frameIndex}`)),
                tileSize: sourceSettings.tileSize,
                bounds: sourceSettings.bounds,
                minzoom: sourceSettings.minzoom,
                maxzoom: sourceSettings.maxzoom,
            } as RasterSource,
        });
    }

    getChangeDetection(opacities: number[]) {
        if (this.layersWindow) {
            const { window } = this.layersWindow;

            return (
                window.length !== opacities.length ||
                window.some((l, i) => l.opacity !== opacities[i])
            );
        }
    }

    destroy() {
        this.layersWindow = null;
        this.timeSubscription?.unsubscribe();
    }
}
