import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';

import {
    EventStatus,
    BaseNotificationEvent,
    NotificationSettings,
    Post,
    NotificationType,
    ServiceExcessType,
} from '@libs/common/models/basicModels';
import {
    formatDuration,
    shortDateFormat,
    shortDateFormatWithToday,
} from '@libs/common/utils/date-formats';
import { TEXTS } from '@libs/common/texts/texts';
import * as moment from 'moment-timezone';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

const enum DurationMap {
    STARTED = 'begin',
    CONTINUING = 'continue',
}

@Component({
    selector: 'cityair-event-item',
    templateUrl: './event-item.component.html',
    styleUrls: ['./event-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventItemComponent implements OnChanges {
    @Input() notification: BaseNotificationEvent;
    @Input() post: Post;
    @Input() settings: NotificationSettings;
    @Input() todayDate: Date;
    @Input() disableNavigation: boolean;
    @Input() configurable: boolean;

    @Output() gotoMonitoringObject = new EventEmitter<BaseNotificationEvent>();

    @Output() openSubscriptionSettings = new EventEmitter<void>();
    @Output() markNotificationAsVisited = new EventEmitter<void>();

    tooltipIconText: string;
    firstNotification: BaseNotificationEvent;
    NotificationType = NotificationType;
    BasicNotificationEventType = EventStatus;
    durationType: DurationMap;
    textsNotification = TEXTS.NOTIFICATIONS;
    eventTypes = EventStatus;
    serviceExcessTypes = ServiceExcessType;
    firstEventVisible = false;
    durationTooltipText = '';
    headerDateTimeText = '';
    longNameHeaderDuration = '';
    textNames = TEXTS.NAMES;
    textDuration = {
        minutes: this.textsNotification.timeIntervalMinutes,
        hours: this.textsNotification.timeIntervalHours,
        days: this.textsNotification.moreDays,
        months: this.textsNotification.timeIntervalMonths,
        years: this.textsNotification.timeIntervalYears,
    };
    pdkValues = '';
    private _interval: NodeJS.Timeout;
    public currentTime = moment().toISOString();

    constructor(private numberFormatPipe: ValueFormatNumberPipe) {
        this._interval = setInterval(() => {
            this.currentTime = moment().toISOString();
        }, 60000);
    }
    ngOnChanges() {
        const { begin_time, end_time, type } = this.notification;

        this.durationType = this.hasEnded ? DurationMap.CONTINUING : DurationMap.STARTED;

        this.tooltipIconText =
            {
                [NotificationType.pdkExcess]: this.textsNotification.tooltipEventIcon.measurements,
                [NotificationType.deviceIncident]: this.textsNotification.tooltipEventIcon.service,
            }[type] || '';

        this.durationTooltipText = this.getDurationTooltipText();

        this.headerDateTimeText = this.hasEnded
            ? this.durationFormat(begin_time, end_time)
            : shortDateFormatWithToday(this.todayDate, begin_time);

        this.firstNotification = this.hasEnded
            ? Object.assign({}, this.notification, {
                  update_time: null,
                  status: this.eventTypes.Begin,
              })
            : null;
        if (this.notification.type === NotificationType.pdkExcess) {
            this.pdkValues = this.getPdkValues();
        }
    }

    getDurationTooltipText() {
        const { begin_time, end_time, update_time } = this.notification;

        let result = '';

        if (this.hasEnded) {
            result = this.dateRangeText(begin_time, end_time);
        } else {
            const end = moment().toISOString();
            result = this.durationFormat(begin_time, end);
        }

        return result;
    }
    get hasEnded() {
        return this.notification.status === this.eventTypes.End;
    }

    get isPdkType() {
        return this.notification.type === NotificationType.pdkExcess;
    }

    get isServiceType() {
        return this.notification.type === NotificationType.deviceIncident;
    }

    openSettings() {
        this.openSubscriptionSettings.emit();
    }

    toggleFirstEvent(e: Event) {
        e.stopPropagation();

        this.firstEventVisible = !this.firstEventVisible;
    }

    durationFormat(begin: string, end: string) {
        const diffValue = moment(end).diff(begin);
        const result = formatDuration(moment.duration(diffValue));
        const resLen = result.split(' ')?.length;
        this.longNameHeaderDuration = resLen > 3 ? 'long-duration' : '';
        return result;
    }

    markAsVisited() {
        if (!this.notification.is_viewed) {
            this.markNotificationAsVisited.emit();
        }
    }

    private dateRangeText(begin: string, end: string) {
        return [shortDateFormat(begin), '—', shortDateFormat(end)].join(' ');
    }

    private getPdkValues() {
        const { max_value, pdk_value } = this.notification.excess_info;
        const pdkValue = max_value / pdk_value;
        const value = this.numberFormatPipe.transform(pdkValue, 1);
        return [
            this.textNames[this.notification.pdk_value_type] ?? this.notification.pdk_value_type,
            `${this.textsNotification.qmax}:`,
            value,
            this.textsNotification.excessLevelUnits,
        ].join(' ');
    }
}
