import { createSelector, select, Store } from '@ngrx/store';
import { lastValueFrom, pipe } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { cityAdapter, deviceAdapter, ICoreState } from './reducers';
import { AVAILABLE_INTERVALS, MMT_FOR_PINS } from '@cityair/config';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { DAY_MS, HOUR_MS, IntervalEnum } from '@cityair/namespace';
import { isRU } from '@libs/common/texts/texts';
import { PDK_SS, PDK_MR } from '@libs/common/consts/pdk.consts';
import { ColorZone } from '@libs/common/types/color-zone';
import { PdkType } from '@libs/common/types/pdk-type';
import {
    getComparedListObject,
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import { markerState } from '@libs/common/enums/marker-state.enum';
import * as moment from 'moment-timezone';
import {
    allowModule,
    getCurrentGroup,
    selectCurrentRegionCoefs,
    selectGroupId,
    selectMapStyleTypes,
} from '@cityair/modules/core/store/group/group.feature';
import { DataObjType } from '@libs/common/models/basicModels';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { getDeviceSourceName } from '@libs/common/utils/utils';
import { selectAllPosts, selectAllPostsDic } from '@cityair/modules/core/store/posts/posts.feature';
import { AqiType } from '@libs/common/enums/aqi.type';
import { NameModules } from '@libs/common/enums/name-modules';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import {
    DOMAINS_FORECASTS,
    LENS_FORECASTS,
} from '@cityair/modules/map/components/mapbox/domain-forecasts.settings';
import {
    selectIsShowLensForecast,
    selectIsShowPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import {
    selectMapCenter,
    selectMapStyleLoading,
} from '@cityair/modules/core/store/map/map.feature';
import { findClosestCity } from '@cityair/modules/map/components/mapbox/mapAPI';
import { OBSERVER_ID } from '@cityair/config';
import { isToday } from '@libs/common/utils/date-formats';

export interface AppState {
    core: ICoreState;
}

export const coreSelector = (state: AppState) => state.core;

export const selectIAm = createSelector(coreSelector, (state: ICoreState) => state?.iAm);
export const selectUserId = createSelector(coreSelector, (state: ICoreState) => state?.iAm?.id);

export const selectUserRoleId = createSelector(selectIAm, selectGroupId, (iAm, groupId) => {
    if (iAm && groupId) {
        return iAm?.group_roles[groupId] ?? null;
    }
    return null;
});

export const selectRoles = createSelector(coreSelector, (state: ICoreState) => state?.roles);
export const selectGroupWithUser = createSelector(
    getCurrentGroup,
    selectUserId,
    (group, userId) => {
        if (group && group?.id && userId) {
            return {
                groupId: group.id,
                userId: userId,
            };
        }
        return null;
    }
);
export const selectGroupAndIAm = createSelector(selectIAm, selectGroupId, (iAm, groupId) => {
    if (iAm && groupId) {
        return {
            groupId,
            iAm,
        };
    }
    return null;
});
export const selectUserScheme = createSelector(selectIAm, (iAm) => iAm?.settings?.measure_scheme);
export const selectUserTimezone = createSelector(selectIAm, (iAm) => iAm?.settings?.timezone);
export const selectUserTimezoneLabel = createSelector(selectUserTimezone, (userTimezone) => {
    if (userTimezone === 'default') {
        const timezone = moment.tz.guess();
        return `(GMT ${moment.tz(timezone).format('Z')}) ${timezone}`;
    } else {
        return `(GMT ${moment.tz(userTimezone).format('Z')}) ${userTimezone}`;
    }
});

export const selectMyRole = createSelector(selectUserRoleId, selectRoles, (roleId, roles) => {
    if (roleId && roles !== null) {
        return roles.find((role) => role.id === roleId);
    }
    return null;
});
export const selectRoleNameById = (roleId, lang) =>
    createSelector(selectRoles, (roles) => {
        if (roleId && roles !== null) {
            const role = roles.find((role) => role.id === roleId);
            return lang === 'ru' ? role.name_ru : role.name;
        }
        return null;
    });
export const selectUserData = createSelector(selectIAm, selectMyRole, (iAm, role) => {
    if (iAm && role) {
        return {
            userId: iAm.id ?? null,
            login: iAm.name ?? null,
            email: iAm.email ?? null,
            roleId: role.id ?? null,
            roleName: role.name,
            roleNameRu: role.name_ru,
            shortName: iAm.name ? iAm.name.substring(0, 2).toUpperCase() : '',
        };
    }
    return null;
});
export const selectCurrentMo = createSelector(
    coreSelector,
    selectAllPostsDic,
    (state: ICoreState, posts) => posts[state.selectedMoId] ?? null
);

export const selectAllDevices = createSelector(coreSelector, (state: ICoreState) =>
    deviceAdapter.getSelectors().selectAll(state.devices)
);

export const selectDeviceByPostId = (id) =>
    createSelector(selectAllDevices, (devices) => devices.find((d) => d.ancestor?.id === id));

export const selectAllCities = createSelector(coreSelector, (state: ICoreState) =>
    cityAdapter.getSelectors().selectAll(state.cities)
);
export const getCityById = (id) =>
    createSelector(coreSelector, (state: ICoreState) => state.cities.entities[id] ?? null);

export const selectCityParentNameById = (id) =>
    createSelector(getCityById(id), (city) => city?.ancestor?.name ?? null);

export const selectDeviceList = createSelector(
    selectAllDevices,
    selectAllPostsDic,
    (devices, posts) => {
        const result = [];
        devices?.forEach((device) => {
            const post = device?.ancestor?.id ? posts[device?.ancestor?.id] ?? null : null;
            const item = Object.assign(
                { ...device },
                { post: post },
                {
                    arrMoName: [post?.name],
                    arrSerialNumber: [device.serial_number],
                }
            );
            result.push(item);
        });

        return result;
    }
);

export const selectDeviceSourceNameByType = (type: string) =>
    createSelector(
        selectDeviceSources,
        (sources) => sources?.find((source) => source.source_type === type)?.name
    );
export const selectTotalDevices = createSelector(coreSelector, (state: ICoreState) =>
    deviceAdapter.getSelectors().selectTotal(state.devices)
);

export const selectCurrentSerialNumberDevice = createSelector(
    coreSelector,
    (state: ICoreState) => state.selectedDeviceSerialNumber
);

export const selectLoadingTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isTimelineLoading
);

export const selectMainLoading = createSelector(
    selectLoadingTimeline,
    selectMapStyleLoading,
    selectMapStyleTypes,
    (isLoadingTimeline, isLoadingMapStyle, styleTypes) =>
        isLoadingTimeline || (styleTypes?.length > 0 && isLoadingMapStyle)
);
export const selectMarkersWithoutLocation = createSelector(selectAllPosts, (posts) =>
    posts.filter((m) => !m.ancestor)
);

export const selectMapLoaded = createSelector(
    coreSelector,
    (state: ICoreState) => !!state.map?.loaded
);

export const selectIsSidebarOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isSidebarOpen
);

export const selectMeasurementsForPins = createSelector(coreSelector, (state: ICoreState) =>
    state?.allMeasurements.filter((name) => MMT_FOR_PINS.includes(name))
);

export const selectGlobalMeasurement = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentMeasurement
);
export const selectMeasureScheme = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentMeasureScheme
);

export const selectSchemeAndMeasure = createSelector(coreSelector, (state: ICoreState) => ({
    scheme: state?.currentMeasureScheme,
    mmt: state?.currentMeasurement,
}));

export const selectIsInstantAqiFaqOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state.isInstantAqiFaqOpen
);

// time -----------------------

export const selectTzMinutesOffset = createSelector(
    coreSelector,
    (state: ICoreState) => state?.time.tzMinutesOffset
);

export const selectCurrentTzOffset = createSelector(
    coreSelector,
    (state: ICoreState) => state?.time.tzMinutesOffset
);
export const selectDeviceSources = createSelector(
    coreSelector,
    (state: ICoreState) => state?.deviceSources
);
export const selectTimeRangeWithoutDistinct = createSelector(coreSelector, (state: ICoreState) => ({
    begin: state?.time.begin,
    end: state?.time.end,
}));

export const selectTimeRange = pipe(
    select(selectTimeRangeWithoutDistinct),
    distinctUntilChanged(
        (prev, current) => prev.begin === current.begin && prev.end === current.end
    )
);

export const selectTime = createSelector(coreSelector, (state: ICoreState) => state?.time.current);

const _selectCurrentTime = createSelector(coreSelector, (state: ICoreState) => ({
    current: state?.time.current,
}));

export const selectCurrentTime = pipe(
    select(_selectCurrentTime),
    distinctUntilChanged((prev, current) => prev.current === current.current)
);

function formatTimeForNgrxPins(time: string) {
    return time.substring(0, time.length - 5) + 'Z';
}

export const selectZone = createSelector(coreSelector, (state: ICoreState) => {
    const scheme = state.currentMeasureScheme;
    const mmt = state.currentMeasurement;

    return state.measuresZones[scheme][mmt] as ColorZone;
});
export const selectMeasuresZones = createSelector(
    coreSelector,
    (state: ICoreState) => state.measuresZones
);

export const selectTimelineDateTimes = createSelector(
    coreSelector,
    (state: ICoreState) => state?.timelineDateTimes
);
export const selectCurrentIndex = createSelector(
    coreSelector,
    (state: ICoreState) => state.currentTimeIndex
);
export const selectCurrentTimeByIndex = createSelector(
    selectCurrentIndex,
    selectTimelineDateTimes,
    (index, dates) => {
        if (index !== null && dates.length) {
            return new Date(dates[index]).getTime() ?? null;
        }
        return null;
    }
);
export const selectCurrentTimeIndex = pipe(
    select(selectCurrentTimeByIndex),
    distinctUntilChanged((prev, current) => prev === current)
);
export const selectCityTimelineById = (id: string) =>
    createSelector(coreSelector, (state: ICoreState) => state.timeline.entities[id] ?? null);

export const selectPostTimelineById = (id: string) =>
    createSelector(coreSelector, (state: ICoreState) => state.timeline.entities[id] ?? null);

export const selectCityCurrentValue = (id: string) =>
    createSelector(
        selectCityTimelineById(id),
        selectGlobalMeasurement,
        selectCurrentIndex,
        (city, mmt, index) => {
            if (city && mmt && index !== null) {
                if (city.data.measurements.hasOwnProperty(mmt)) {
                    return city.data.measurements[mmt].values[index] ?? null;
                }
                if (city.data.indexes.hasOwnProperty(mmt)) {
                    return city.data.indexes[mmt].values[index] ?? null;
                }

                return null;
            }
            return null;
        }
    );
export const selectPostCurrentValue = (id: string) =>
    createSelector(
        selectPostTimelineById(id),
        selectGlobalMeasurement,
        selectCurrentIndex,
        (post, mmt, index) => {
            if (post && mmt && index !== null) {
                if (post.data.measurements.hasOwnProperty(mmt)) {
                    return post.data.measurements[mmt].values[index] ?? null;
                }
                if (post.data.indexes.hasOwnProperty(mmt)) {
                    return post.data.indexes[mmt].values[index] ?? null;
                }

                return null;
            }
            return null;
        }
    );

// helpers for synchronous requests
export const getPostPromiseValue = async (store: Store, markerId: string) =>
    lastValueFrom(store.select(selectPostCurrentValue(markerId)).pipe(take(1)));

export const getLocationPromiseValue = async (store: Store, markerId: string) =>
    lastValueFrom(store.select(selectCityCurrentValue(markerId)).pipe(take(1)));

export const selectIsCityMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isCityMode
);
export const selectChartData = createSelector(
    coreSelector,
    (state: ICoreState) => state?.chartData
);
export const selectSourcesAsFeatures = createSelector(
    selectChartData,
    selectAllPostsDic,
    (chartData, posts) => {
        if (chartData.length && posts) {
            const result = chartData.map((item) => {
                const id = item?.properties.uuid;
                const currentPost = posts[id] ? { ...posts[id] } : null;
                if (id && currentPost) {
                    const postProperties = {
                        city_id: `${currentPost.ancestor?.id}`,
                        name: currentPost.name,
                        name_ru: currentPost.name,
                        ancestor: currentPost.ancestor,
                    };
                    const geometry = Object.assign({}, item.geometry, currentPost.geometry);
                    const properties = Object.assign({}, item.properties, postProperties);
                    return {
                        ...item,
                        geometry,
                        properties,
                    };
                }
                return item;
            });

            return result;
        }
    }
);

export const selectErrorMessage = createSelector(
    coreSelector,
    (state: ICoreState) => state.errorMessage
);

export const selectVangaTokenStatus = createSelector(
    coreSelector,
    (state: ICoreState) => state.isVangaTokenLoading
);

export const isCompareMode = createSelector(
    coreSelector,
    (state: ICoreState) => state.isCompareMode
);

export const getMarkerState = (id: string) =>
    createSelector(
        coreSelector,
        isCompareMode,
        isComparedListLimited,
        getComparedListObject(id),
        (state: ICoreState, isCompareMode, isComparedListLimited, comparedListObject) =>
            isCompareMode && (!isComparedListLimited || comparedListObject)
                ? markerState.add
                : markerState.default
    );

export const canClickOnMarker = (id: string) =>
    createSelector(
        coreSelector,
        getComparedListObject(id),
        getMarkerState(id),
        (state, comparedListObject, markerCurrentState) =>
            !(comparedListObject && markerCurrentState === markerState.default)
    );

export const selectTypeInterval = createSelector(
    coreSelector,
    (state: ICoreState) => state?.currentTypeInterval
);

export const selectPdkForChart = createSelector(coreSelector, (state: ICoreState) => {
    if (state.currentMeasureScheme === MeasureScheme.mpc || !isRU) return null;

    const isDay = state.currentTypeInterval === IntervalEnum.day;
    const pdks = (isDay ? PDK_SS : PDK_MR)[state.currentMeasureScheme];

    return {
        type: isDay ? PdkType.ss : PdkType.mr,
        pdks,
    };
});

export const selectInfoMessage = createSelector(
    coreSelector,
    (state: ICoreState) => state?.infoMessage
);

export const selectAllowUpdate = createSelector(coreSelector, (state: ICoreState) => {
    const isToday = new Date().getTime() - state.time.end <= HOUR_MS;

    const isNotMaxTime = state.time.end - state.time.begin <= AVAILABLE_INTERVALS[0].days * DAY_MS;

    return isToday && isNotMaxTime;
});

export const getNewBeginEndTime = createSelector(coreSelector, (state: ICoreState) => {
    const delta = new Date().getTime() - state.time.end;

    return {
        begin: state.time.begin + delta,
        end: new Date().getTime(),
    };
});

export const getIntervalUpdateData = createSelector(
    selectAllowUpdate,
    getNewBeginEndTime,
    (isAllowUpdate, newTimeRange) => ({ isAllowUpdate, newTimeRange })
);
export const selectMapClickState = createSelector(
    coreSelector,
    (state: ICoreState) => state?.mapClickState
);
export const selectAvailableModule = createSelector(
    coreSelector,
    (state: ICoreState) => state?.availableModule
);
export const isAvailableModule = (module: MAIN_PAGES) =>
    createSelector(selectAvailableModule, (modules) => modules.includes(module));
export const selectQualityDataMode = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMode
);
export const selectIsShowQualityDataInfo = createSelector(
    coreSelector,
    (state: ICoreState) => state?.isShowQualityDataInfo
);
export const selectQualityDataMarkers = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataMarkers
);
export const selectQualityDataTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state?.qualityDataTimeline
);

export const selectQualityDataPostId = createSelector(
    selectQualityDataMode,
    selectComparedItems,
    (qualityDataMode, comparedItems) => {
        if (
            qualityDataMode &&
            comparedItems.length === 1 &&
            comparedItems[0].obj === DataObjType.outdoorPost
        ) {
            return comparedItems[0].id;
        }

        return null;
    }
);
export const getDataParams = createSelector(
    selectTimeRangeWithoutDistinct,
    selectTypeInterval,
    selectGroupId,
    (time, interval, groupId) => {
        if (time && interval && groupId) {
            const date__lt = isToday(new Date(time.end))
                ? new Date().toISOString()
                : new Date(time.end).toISOString();
            const params = {
                group_id: groupId.toString(),
                date__gt: new Date(time.begin).toISOString(),
                date__lt,
                interval: interval.toString(),
            };

            return params;
        }

        return null;
    }
);
export const selectParamsForTimeline = createSelector(
    getDataParams,
    selectMeasureScheme,
    (paramsData, scheme) => {
        if (paramsData && scheme) {
            const params = {
                date__gt: paramsData.date__gt,
                date__lt: paramsData.date__lt,
                interval: paramsData.interval,
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
            };
            return {
                groupId: Number(paramsData.group_id),
                params,
            };
        }

        return null;
    }
);
export const selectParamsForTimelineOne = createSelector(
    selectParamsForTimeline,
    selectGlobalMeasurement,
    (data, mmt) => {
        if (data && mmt) {
            const params = Object.assign({}, data.params, {
                packet_type: mmt,
            });
            return {
                groupId: data.groupId,
                params,
            };
        }

        return null;
    }
);
export const selectMyDevicesAndPosts = createSelector(
    selectAllDevices,
    selectAllPosts,
    (devices, posts) => ({ devices, posts })
);
export const selectPostList = createSelector(
    selectAllPosts,
    selectDeviceSources,
    selectAllDevices,
    selectAllCities,
    (posts, sources, devices, cities) => {
        const result = [];
        if (posts?.length && sources?.length) {
            posts.forEach((post) => {
                const device = devices.find((d) => d.ancestor?.id === post.id);
                result.push({
                    id: post.id,
                    name: post.name,
                    serialNumber: device?.serial_number ?? null,
                    city: cities && cities?.length > 1 ? post.ancestor?.name : '',
                    device: device ?? null,
                    isNoDataSources: device === null,
                    deviceSourcesName: getDeviceSourceName(device, sources),
                });
            });

            return result;
        }
        return null;
    }
);
export const selectInitMap = createSelector(getCurrentGroup, selectMapLoaded, (group, mapLoaded) =>
    group && mapLoaded ? group : null
);
export const selectShowAqiWidget = createSelector(
    selectGlobalMeasurement,
    selectIsCityMode,
    (mmt, isCityMode) => isCityMode && Object.values(AqiType).includes(mmt as AqiType)
);
export const selectIsShowSettingCoefficient = createSelector(
    selectMyRole,
    selectCurrentRegionCoefs,
    (role, coef) => role?.edit_group && coef !== null
);
export const selectNearestCity = createSelector(
    selectAllCities,
    selectMapCenter,
    (locations, center) => findClosestCity(locations, center)
);
export const selectPublicForecastConfig = createSelector(
    allowModule(NameModules.forecast),
    selectCurrentCity,
    selectIsCityMode,
    (isAllow, city, isCityMode) => {
        if (isAllow && city && isCityMode) {
            return DOMAINS_FORECASTS.hasOwnProperty(city.id) ? DOMAINS_FORECASTS[city.id] : null;
        }
        return null;
    }
);
export const selectLensForecastConfig = createSelector(
    allowModule(NameModules.forecast),
    selectCurrentCity,
    selectIsCityMode,
    (isAllow, city, isCityMode) => {
        if (isAllow && city && isCityMode) {
            return LENS_FORECASTS.hasOwnProperty(city.id) ? LENS_FORECASTS[city.id] : null;
        }
        return null;
    }
);
export const selectIsAllowPublicForecast = createSelector(selectPublicForecastConfig, (config) =>
    config ? true : false
);
export const selectIsAllowLensForecast = createSelector(selectLensForecastConfig, (config) =>
    config ? true : false
);
export const selectIsShowOnMapPublicForecast = createSelector(
    selectIsAllowPublicForecast,
    selectIsShowPublicForecast,
    (isAllow, isShow) => isAllow && isShow
);
export const selectIsShowOnMapLensForecast = createSelector(
    selectIsAllowLensForecast,
    selectIsShowLensForecast,
    (isAllow, isShow) => isAllow && isShow
);
export const selectCanShowDownloadButtons = createSelector(
    selectComparedItems,
    selectUserRoleId,
    (comparedItems, roleId) =>
        roleId !== OBSERVER_ID.toString()
            ? !comparedItems.find((item) => item.obj !== DataObjType.outdoorPost)
            : false
);
export const selectCurrentUserGroupId = createSelector(selectGroupId, selectIAm, (groupId, iAm) =>
    groupId && iAm ? `${groupId}_${iAm.id}` : null
);
export const selectCurrentMapStyleType = createSelector(
    coreSelector,
    (state: ICoreState) => state.currentMapType
);
