import { defaultModuleConfig } from '@cityair/namespace';
import { ModulePageConfig } from '@cityair/namespace';
import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';

export const setModuleConfig = createAction(
    '[ModulePageConfig] SetModuleConfig',
    props<{ config: ModulePageConfig }>()
);

type ModulePageConfigState = {
    config: ModulePageConfig;
};

const initialState: ModulePageConfigState = {
    config: defaultModuleConfig,
};

export const modulePageConfigFeature = createFeature({
    name: 'modulePageConfig',
    reducer: createReducer(
        initialState,

        on(setModuleConfig, (state, { config }) => ({
            config,
        }))
    ),
});

const featureSelector = createFeatureSelector<ModulePageConfigState>('modulePageConfig');

export const getModulePageConfig = createSelector(featureSelector, (state) => state.config);
