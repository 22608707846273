import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@libs/common/models/feature';

@Pipe({
    name: 'issetContributionTimelineData',
})
export class IssetContributionTimelineDataPipe implements PipeTransform {
    transform(data: Feature[], mmts: string[]): boolean {
        if (data.length === 1 && mmts.length === 1 && data[0]?.properties?.timelineContributions) {
            return true;
        }
        return false;
    }
}
