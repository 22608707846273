import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const MARGIN_SIDEBAR = 115;
const SIDEBAR_WIDTH = 506;
const CITY_WIDGET_WIDTH = 110;
const LAYER_PIN_BUTTON = 61;
const SWITCH_MAP_OBJ_WIDTH = 171;

@Component({
    selector: 'cityair-map-control',
    templateUrl: './map-control.component.html',
    styleUrls: ['./map-control.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapControlComponent implements AfterContentInit, AfterViewInit, OnChanges {
    @Input() title? = TEXTS.MAP_CONTROL.title.default;
    @Input() isOpenSidebar = false;
    @Input() isShowCityWidget? = false;
    @Input() isShowTogglePin? = false;
    @Input() isShowMapObj? = false;
    @ViewChild('titleWrapper') titleWrapper: ElementRef<HTMLElement>;
    @ViewChild('periodContentWrapper') periodContentWrapper: ElementRef<HTMLElement>;
    @ViewChild('mmtsWrapper') mmtsWrapper: ElementRef<HTMLElement>;

    public checkWrap = false;
    public leftPadding = SIDEBAR_WIDTH + MARGIN_SIDEBAR;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
        fromEvent(window, 'resize')
            .pipe(debounceTime(100))
            .subscribe((data) => {
                this.correctWidth();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.isOpenSidebar) {
            this.leftPadding = this.isOpenSidebar ? SIDEBAR_WIDTH + MARGIN_SIDEBAR : MARGIN_SIDEBAR;
            this.correctWidth();
        }
        if (changes?.isShowCityWidget) {
            this.correctWidth();
        }
        if (changes?.isShowTogglePin) {
            this.correctWidth();
        }
    }

    ngAfterContentInit() {
        this.correctWidth();
    }

    ngAfterViewInit() {
        this.correctWidth();
    }

    private correctWidth() {
        const periodWr = this.periodContentWrapper?.nativeElement?.getBoundingClientRect();
        const titleWrapper = this.titleWrapper?.nativeElement?.getBoundingClientRect();
        const mmtsWr = this.mmtsWrapper?.nativeElement?.getBoundingClientRect();
        const aqiWidgetWidth = this.isShowCityWidget ? CITY_WIDGET_WIDTH : 0;
        const layerPinBtn = this.isShowTogglePin ? LAYER_PIN_BUTTON : 0;
        const mapObjSelector = this.isShowMapObj ? SWITCH_MAP_OBJ_WIDTH : 0;
        const wrapPadding = 84;
        this.checkWrap =
            window.innerWidth <
            this.leftPadding +
                titleWrapper?.width +
                periodWr?.width +
                mmtsWr?.width +
                wrapPadding +
                aqiWidgetWidth +
                layerPinBtn +
                mapObjSelector
                ? true
                : false;

        this._changeDetectorRef.detectChanges();
    }
}
