import { DistributionSummaryEnum, LocalitySummary } from '@libs/common/models/basicModels';

export const AQI_IN_ANALYTICS_GROUP_IDS = ['192', '193'];
export const ANALYTIC_INDORE_DATA: LocalitySummary = {
    [DistributionSummaryEnum.year]: {
        first_packet_date: '2021-11-01T10:00:00Z',
        distribution_aqi: [
            {
                aqi: 50,
                percent: 19,
            },
            {
                aqi: 100,
                percent: 32,
            },
            {
                aqi: 200,
                percent: 32,
            },
            {
                aqi: 300,
                percent: 16,
            },
            {
                aqi: 400,
                percent: 2,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distribution_day_hour: [
            {
                aqi: 120,
                hour_of_day: 0,
            },
            {
                aqi: 120,
                hour_of_day: 1,
            },
            {
                aqi: 120,
                hour_of_day: 2,
            },
            {
                aqi: 120,
                hour_of_day: 3,
            },
            {
                aqi: 120,
                hour_of_day: 4,
            },
            {
                aqi: 120,
                hour_of_day: 5,
            },
            {
                aqi: 120,
                hour_of_day: 6,
            },
            {
                aqi: 120,
                hour_of_day: 7,
            },
            {
                aqi: 120,
                hour_of_day: 8,
            },
            {
                aqi: 119,
                hour_of_day: 9,
            },
            {
                aqi: 119,
                hour_of_day: 10,
            },
            {
                aqi: 119,
                hour_of_day: 11,
            },
            {
                aqi: 119,
                hour_of_day: 12,
            },
            {
                aqi: 119,
                hour_of_day: 13,
            },
            {
                aqi: 120,
                hour_of_day: 14,
            },
            {
                aqi: 119,
                hour_of_day: 15,
            },
            {
                aqi: 118,
                hour_of_day: 16,
            },
            {
                aqi: 118,
                hour_of_day: 17,
            },
            {
                aqi: 118,
                hour_of_day: 18,
            },
            {
                aqi: 119,
                hour_of_day: 19,
            },
            {
                aqi: 119,
                hour_of_day: 20,
            },
            {
                aqi: 120,
                hour_of_day: 21,
            },
            {
                aqi: 120,
                hour_of_day: 22,
            },
            {
                aqi: 120,
                hour_of_day: 23,
            },
        ],
        distribution_week_day: [
            {
                aqi: 118,
                day_of_week: 0,
            },
            {
                aqi: 129,
                day_of_week: 1,
            },
            {
                aqi: 125,
                day_of_week: 2,
            },
            {
                aqi: 116,
                day_of_week: 3,
            },
            {
                aqi: 117,
                day_of_week: 4,
            },
            {
                aqi: 115,
                day_of_week: 5,
            },
            {
                aqi: 117,
                day_of_week: 6,
            },
        ],
    },
    [DistributionSummaryEnum.winter]: {
        first_packet_date: '2021-11-01T10:00:00Z',
        distribution_aqi: [
            {
                aqi: 100,
                percent: 22,
            },
            {
                aqi: 200,
                percent: 55,
            },
            {
                aqi: 300,
                percent: 22,
            },
            {
                aqi: 400,
                percent: 1,
            },
        ],
        distribution_day_hour: [
            {
                aqi: 155,
                hour_of_day: 0,
            },
            {
                aqi: 155,
                hour_of_day: 1,
            },
            {
                aqi: 155,
                hour_of_day: 2,
            },
            {
                aqi: 155,
                hour_of_day: 3,
            },
            {
                aqi: 155,
                hour_of_day: 4,
            },
            {
                aqi: 155,
                hour_of_day: 5,
            },
            {
                aqi: 155,
                hour_of_day: 6,
            },
            {
                aqi: 155,
                hour_of_day: 7,
            },
            {
                aqi: 155,
                hour_of_day: 8,
            },
            {
                aqi: 155,
                hour_of_day: 9,
            },
            {
                aqi: 154,
                hour_of_day: 10,
            },
            {
                aqi: 154,
                hour_of_day: 11,
            },
            {
                aqi: 154,
                hour_of_day: 12,
            },
            {
                aqi: 154,
                hour_of_day: 13,
            },
            {
                aqi: 154,
                hour_of_day: 14,
            },
            {
                aqi: 153,
                hour_of_day: 15,
            },
            {
                aqi: 153,
                hour_of_day: 16,
            },
            {
                aqi: 153,
                hour_of_day: 17,
            },
            {
                aqi: 153,
                hour_of_day: 18,
            },
            {
                aqi: 153,
                hour_of_day: 19,
            },
            {
                aqi: 154,
                hour_of_day: 20,
            },
            {
                aqi: 154,
                hour_of_day: 21,
            },
            {
                aqi: 155,
                hour_of_day: 22,
            },
            {
                aqi: 155,
                hour_of_day: 23,
            },
        ],
        distribution_week_day: [
            {
                aqi: 153,
                day_of_week: 0,
            },
            {
                aqi: 169,
                day_of_week: 1,
            },
            {
                aqi: 159,
                day_of_week: 2,
            },
            {
                aqi: 149,
                day_of_week: 3,
            },
            {
                aqi: 147,
                day_of_week: 4,
            },
            {
                aqi: 151,
                day_of_week: 5,
            },
            {
                aqi: 155,
                day_of_week: 6,
            },
        ],
    },
    [DistributionSummaryEnum.spring]: {
        first_packet_date: '2021-11-01T10:00:00Z',
        distribution_aqi: [
            {
                aqi: 50,
                percent: 13,
            },
            {
                aqi: 100,
                percent: 58,
            },
            {
                aqi: 200,
                percent: 14,
            },
            {
                aqi: 300,
                percent: 11,
            },
            {
                aqi: 400,
                percent: 4,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distribution_day_hour: [
            {
                aqi: 103,
                hour_of_day: 0,
            },
            {
                aqi: 103,
                hour_of_day: 1,
            },
            {
                aqi: 103,
                hour_of_day: 2,
            },
            {
                aqi: 103,
                hour_of_day: 3,
            },
            {
                aqi: 103,
                hour_of_day: 4,
            },
            {
                aqi: 103,
                hour_of_day: 5,
            },
            {
                aqi: 104,
                hour_of_day: 6,
            },
            {
                aqi: 104,
                hour_of_day: 7,
            },
            {
                aqi: 104,
                hour_of_day: 8,
            },
            {
                aqi: 104,
                hour_of_day: 9,
            },
            {
                aqi: 105,
                hour_of_day: 10,
            },
            {
                aqi: 105,
                hour_of_day: 11,
            },
            {
                aqi: 106,
                hour_of_day: 12,
            },
            {
                aqi: 107,
                hour_of_day: 13,
            },
            {
                aqi: 107,
                hour_of_day: 14,
            },
            {
                aqi: 107,
                hour_of_day: 15,
            },
            {
                aqi: 105,
                hour_of_day: 16,
            },
            {
                aqi: 104,
                hour_of_day: 17,
            },
            {
                aqi: 104,
                hour_of_day: 18,
            },
            {
                aqi: 104,
                hour_of_day: 19,
            },
            {
                aqi: 104,
                hour_of_day: 20,
            },
            {
                aqi: 104,
                hour_of_day: 21,
            },
            {
                aqi: 105,
                hour_of_day: 22,
            },
            {
                aqi: 105,
                hour_of_day: 23,
            },
        ],
        distribution_week_day: [
            {
                aqi: 103,
                day_of_week: 0,
            },
            {
                aqi: 104,
                day_of_week: 1,
            },
            {
                aqi: 104,
                day_of_week: 2,
            },
            {
                aqi: 102,
                day_of_week: 3,
            },
            {
                aqi: 109,
                day_of_week: 4,
            },
            {
                aqi: 107,
                day_of_week: 5,
            },
            {
                aqi: 102,
                day_of_week: 6,
            },
        ],
    },
    [DistributionSummaryEnum.summer]: {
        first_packet_date: '2021-11-01T10:00:00Z',
        distribution_aqi: [
            {
                aqi: 50,
                percent: 77,
            },
            {
                aqi: 100,
                percent: 18,
            },
            {
                aqi: 200,
                percent: 1,
            },
            {
                aqi: 300,
                percent: 5,
            },
        ],
        distribution_day_hour: [
            {
                aqi: 49,
                hour_of_day: 0,
            },
            {
                aqi: 49,
                hour_of_day: 1,
            },
            {
                aqi: 49,
                hour_of_day: 2,
            },
            {
                aqi: 49,
                hour_of_day: 3,
            },
            {
                aqi: 49,
                hour_of_day: 4,
            },
            {
                aqi: 46,
                hour_of_day: 5,
            },
            {
                aqi: 46,
                hour_of_day: 6,
            },
            {
                aqi: 46,
                hour_of_day: 7,
            },
            {
                aqi: 46,
                hour_of_day: 8,
            },
            {
                aqi: 45,
                hour_of_day: 9,
            },
            {
                aqi: 46,
                hour_of_day: 10,
            },
            {
                aqi: 46,
                hour_of_day: 11,
            },
            {
                aqi: 45,
                hour_of_day: 12,
            },
            {
                aqi: 46,
                hour_of_day: 13,
            },
            {
                aqi: 47,
                hour_of_day: 14,
            },
            {
                aqi: 46,
                hour_of_day: 15,
            },
            {
                aqi: 46,
                hour_of_day: 16,
            },
            {
                aqi: 45,
                hour_of_day: 17,
            },
            {
                aqi: 47,
                hour_of_day: 18,
            },
            {
                aqi: 47,
                hour_of_day: 19,
            },
            {
                aqi: 48,
                hour_of_day: 20,
            },
            {
                aqi: 47,
                hour_of_day: 21,
            },
            {
                aqi: 47,
                hour_of_day: 22,
            },
            {
                aqi: 47,
                hour_of_day: 23,
            },
        ],
        distribution_week_day: [
            {
                aqi: 41,
                day_of_week: 0,
            },
            {
                aqi: 42,
                day_of_week: 1,
            },
            {
                aqi: 55,
                day_of_week: 2,
            },
            {
                aqi: 52,
                day_of_week: 3,
            },
            {
                aqi: 52,
                day_of_week: 4,
            },
            {
                aqi: 47,
                day_of_week: 5,
            },
            {
                aqi: 39,
                day_of_week: 6,
            },
        ],
    },
    [DistributionSummaryEnum.autumn]: {
        first_packet_date: '2021-11-01T10:00:00Z',
        distribution_aqi: [
            {
                aqi: 50,
                percent: 8,
            },
            {
                aqi: 100,
                percent: 34,
            },
            {
                aqi: 200,
                percent: 35,
            },
            {
                aqi: 300,
                percent: 20,
            },
            {
                aqi: 400,
                percent: 2,
            },
            {
                aqi: 500,
                percent: 0,
            },
        ],
        distribution_day_hour: [
            {
                aqi: 136,
                hour_of_day: 0,
            },
            {
                aqi: 136,
                hour_of_day: 1,
            },
            {
                aqi: 136,
                hour_of_day: 2,
            },
            {
                aqi: 136,
                hour_of_day: 3,
            },
            {
                aqi: 136,
                hour_of_day: 4,
            },
            {
                aqi: 136,
                hour_of_day: 5,
            },
            {
                aqi: 136,
                hour_of_day: 6,
            },
            {
                aqi: 136,
                hour_of_day: 7,
            },
            {
                aqi: 135,
                hour_of_day: 8,
            },
            {
                aqi: 134,
                hour_of_day: 9,
            },
            {
                aqi: 134,
                hour_of_day: 10,
            },
            {
                aqi: 135,
                hour_of_day: 11,
            },
            {
                aqi: 135,
                hour_of_day: 12,
            },
            {
                aqi: 134,
                hour_of_day: 13,
            },
            {
                aqi: 134,
                hour_of_day: 14,
            },
            {
                aqi: 133,
                hour_of_day: 15,
            },
            {
                aqi: 133,
                hour_of_day: 16,
            },
            {
                aqi: 133,
                hour_of_day: 17,
            },
            {
                aqi: 134,
                hour_of_day: 18,
            },
            {
                aqi: 134,
                hour_of_day: 19,
            },
            {
                aqi: 135,
                hour_of_day: 20,
            },
            {
                aqi: 135,
                hour_of_day: 21,
            },
            {
                aqi: 136,
                hour_of_day: 22,
            },
            {
                aqi: 137,
                hour_of_day: 23,
            },
        ],
        distribution_week_day: [
            {
                aqi: 138,
                day_of_week: 0,
            },
            {
                aqi: 157,
                day_of_week: 1,
            },
            {
                aqi: 146,
                day_of_week: 2,
            },
            {
                aqi: 123,
                day_of_week: 3,
            },
            {
                aqi: 128,
                day_of_week: 4,
            },
            {
                aqi: 120,
                day_of_week: 5,
            },
            {
                aqi: 132,
                day_of_week: 6,
            },
        ],
    },
};
