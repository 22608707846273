import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { RunImpact } from '@cityair/modules/impact/service/api-model-impact';

@Component({
    selector: 'ca-impact-run-selector',
    templateUrl: 'impact-run-selector.component.html',
    styleUrls: ['impact-run-selector.component.less'],
    animations: ANIMATION_OPACITY,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactRunSelectorComponent {
    @Input() runs: RunImpact[];
    @Input() activeRun: RunImpact;

    @Output() setRun = new EventEmitter<RunImpact>();
    showDropDown = false;

    select(run: RunImpact) {
        if (this.activeRun.id !== run.id) {
            this.setRun.emit(run);
        }
        this.showDropDown = false;
    }
}
