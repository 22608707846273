import { environment } from 'environments/environment';

const CITYAIR_API_BASIC_URL = '/api/basic/v1/';
const CITYAIR_API_2 = '/AdminApi2/';
const VANGA_API = '/plumes/v1/';

const BASIC_API_URL = `${environment.api_url}${CITYAIR_API_BASIC_URL}`;
const API_2_URL = `${environment.harvester_v1_api_url}${CITYAIR_API_2}`;
const VANGA_API_URL = `${environment.vanga_api_url}${VANGA_API}`;

const getBasicApiUrl = (method: string) => `${BASIC_API_URL}${method}`;
const getAdminApiUrl = (method: string) => `${API_2_URL}${method}`;
const getVangaApiUrl = (method: string) => `${VANGA_API_URL}${method}`;

export const URLS = {
    me: getBasicApiUrl('user/me/'),
    userSettings: getBasicApiUrl('user/settings/'),
    group: getBasicApiUrl('group/'),
    groupUser: getBasicApiUrl('groupuser/'),
    groupSettings: getBasicApiUrl('group_settings/'),
    post: getBasicApiUrl('post/'),
    locality: getBasicApiUrl('locality/'),
    city: getBasicApiUrl('locality/city/'),
    device: getBasicApiUrl('device/'),
    region: getBasicApiUrl('region/'),
    notifications: getBasicApiUrl('notifications/'),
    notificationSettings: getBasicApiUrl('notification_settings/'),
    events: getBasicApiUrl('events/'),
    networkAnalysis: getBasicApiUrl('network-analysis/'),
    markers: getBasicApiUrl('DataQuality/markers/'),
    correlationTimelines: getBasicApiUrl('network-analysis/timelines/correlation/'),

    exportDataPost: `${environment.harvester_v1_api_url}/MoApi2/ExportMoPackets`,
    exportData: `${environment.harvester_v1_api_url}/MoApi2/ExportMoPacketsToExcel`,
    exportDataDevice: `${environment.harvester_v1_api_url}/DevicesApi2/ExportDataToBase64`,

    userIsExist: getAdminApiUrl('GetObserverUserItem'),
    userPermission: getAdminApiUrl('GetUserPermission'),
    deleteUserToken: getAdminApiUrl('DeleteUserToken'),
    renameApiKey: getAdminApiUrl('UpsertUserToken'),
    tokenValue: getAdminApiUrl('GetUserTokenValue'),
    changeRegionCoefs: getAdminApiUrl('SetRegionCoefsItem'),
    exportNotification: getAdminApiUrl('ExportFeedItemsToExcel'),

    impactRun: getVangaApiUrl('runs/'),
    impactConfig: getVangaApiUrl('run-config/'),
    controlPoints: getVangaApiUrl('control-points/'),
    timelineContribution: (id) => `${VANGA_API_URL}runs/${id}/timeline/`,
};
