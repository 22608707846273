import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgLocalization } from '@angular/common';
import { TEXTS } from '@libs/common/texts/texts';
import { showMoreText } from '@cityair/modules/notifications/notifications.utils';
import { MAX_INLINE_MOS } from '@cityair/modules/notifications/notifications.settings';
import { Store } from '@ngrx/store';
import { postsListLabels } from '@cityair/modules/notifications/notifications.utils';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationSettings, Post } from '@libs/common/models/basicModels';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';

@Component({
    selector: 'subscription-card-service',
    templateUrl: './subscription-card-service.component.html',
    styleUrls: ['subscription-card-service.component.less'],
})
export class SubscriptionCardService implements OnInit, OnDestroy {
    @Input() subscription: NotificationSettings;
    textsNotification = TEXTS.NOTIFICATIONS;
    saving = false;
    currentMos: string[];
    allMos: string[];
    maxInlineMos = MAX_INLINE_MOS;
    monitoringObjects: Post[];
    subscriptions: Subscription[] = [];
    stubTags = [this.postsListLabels().all()];
    constructor(private ngLocalization: NgLocalization, private store: Store) {
        const monitoringObjectsSub = store
            .select(selectAllPosts)
            .pipe(filter((v) => !!v.length))
            .subscribe((mos) => {
                this.monitoringObjects = mos;
                this.allMos = mos.map((v) => v.id);
            });

        this.subscriptions.push(monitoringObjectsSub);
    }

    ngOnInit() {
        this.currentMos = this.initialMosList()
            .map((mo) => this.getMoName(mo))
            .filter((v) => v);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    showMoreText = showMoreText.bind(null, this.ngLocalization);

    initialMosList() {
        return this.subscription.device_check_settings?.is_for_all_posts
            ? this.allMos
            : this.subscription.device_check_settings?.selected_posts ?? [];
    }

    getMoName(id: string) {
        return this.monitoringObjects.find((v) => v.id === id)?.name;
    }

    getEmails() {
        return this.subscription.notify_settings?.notification_emails?.join(', ');
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }
}
