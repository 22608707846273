import { Component } from '@angular/core';
import { isIos } from '@cityair/libs/shared/utils/other-utils';
import { TEXTS } from '@libs/common/texts/texts';
import { detectMobile } from '@libs/common/utils/detect-mobile';

@Component({
    selector: 'cs-market-links',
    templateUrl: './market-links.component.html',
    styleUrls: ['./market-links.component.less'],
})
export class MarketLinksComponent {
    isIos = isIos();

    isMobile = detectMobile();

    TEXTS = TEXTS;
}
