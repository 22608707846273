import { IReportKind } from '@cityair/modules/reports/models';
// use as className in table reports
export enum REPORTS_COLOR {
    green = '#C1E9D3',
    peach = '#FDDCD5',
    blue = '#BFE2F8',
    violet = '#EAE0FF',
}
export enum STATUS_COLOR {
    CREATED = '#5FC6B1',
    QUEUED = '#6097F3',
    IN_PROGRESS = '#FFA33B',
    ERROR = '#FF7344',
    READY = '#5FC6B1',
}
export const FILE_NAME_MAX_LEN = 100;
export const COMMENT_MAX_LEN = 2000;
export const REFRESH_INTERVAL = 20000; // 20 sec
export const REPORT_DOWNLOAD_ENDPOINT = 'download';
export const TIMEOUT_SHOW_ALERT_MSG = 3000;
export const COUNTRIES_FOR_TIMEZONE_SELECT = ['RU'];
export const THRESHOLD_DEFAULT_VALUE = 1.5;
export const THRESHOLD_MAX_VALUE = 3;
export const THRESHOLD_MIN_VALUE = 1;
export const THRESHOLD_STEP_VALUE = 0.1;
export const MARKETING_REPORT_KIND: IReportKind = {
    id: 2023,
    name: 'Моя форма отчёта',
    name_eng: 'My report form',
    slug: 'marketing',
    short_description: '',
    short_description_eng: '',
    description: '',
    description_eng: '',
    default_format: '',
    params: {},
};
