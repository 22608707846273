import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { IReportKind } from '@cityair/modules/reports/models';
import { isRU, TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'cityair-demo-report',
    templateUrl: './demo-report.component.html',
    styleUrls: ['./demo-report.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoReportComponent implements AfterViewInit {
    @Input() currentKind: IReportKind;
    @ViewChild('demoWrapper') demoWrapper: ElementRef;

    public translateText = TEXTS.REPORTS.demo;
    public demoType: 'pdf' | 'xlsx';
    public isRu = isRU;
    public xlsxPaddingLeft = '135%';

    ngAfterViewInit() {
        this.setImagePadding();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setImagePadding();
    }

    private setImagePadding() {
        const width = this.demoWrapper?.nativeElement?.offsetWidth - 510;
        const padding = (1502 * 100) / width + 5;
        this.xlsxPaddingLeft = `${padding}%`;
    }
}
