import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataQualityComponent } from './data-quality.component';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { IconsModule } from '@libs/shared-ui/components/icons/icons.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';

@NgModule({
    exports: [DataQualityComponent],
    declarations: [DataQualityComponent],
    imports: [CommonModule, CrossButtonModule, IconsModule, TooltipsModule, PipesModule],
})
export class DataQualityModule {}
