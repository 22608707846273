import { ModulePageConfig } from '@cityair/namespace';

export const networkModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: false,
    enableCalendar: false,
    enablePlumeButton: true,
    enableOldTimeline: false,
    enableAqi: false,
    mapClassName: 'network',
};
