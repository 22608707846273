import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { isFalseNumber } from '@libs/common/utils/utils';

@Component({
    selector: 'post-pin',
    templateUrl: './post-pin.component.html',
    styleUrls: ['./post-pin.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostPinComponent implements OnChanges {
    markerState = markerState;
    isFalseNumber = isFalseNumber;

    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() state: markerState;

    hasNoData: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);

            this.hasNoData = hasNoData;
        }
    }
}
