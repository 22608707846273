import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { IReportKind } from '../../../models';
import { TEXTS, isRU } from '@libs/common/texts/texts';
import { Observable } from 'rxjs';
import { MARKETING_REPORT_KIND } from '@cityair/modules/reports/constant';

@Component({
    selector: 'report-kind-view',
    templateUrl: './report-kind-view.component.html',
    styleUrls: ['./report-kind-view.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportKindViewComponent {
    @Input() loadingKind: Observable<boolean>;
    @Input() reportsKind: Observable<IReportKind[]>;
    @Input() groupId: string;
    @Input() isOpenList: boolean;

    @Output() addReport = new EventEmitter<IReportKind>();
    @Output() toggleList = new EventEmitter<boolean>();

    public titleText = TEXTS.REPORTS.createReportTitle;
    public buttonText = TEXTS.REPORTS.generateReport;
    public displayCount = 3;
    public isRu = isRU;
    public marketingReportKind: IReportKind = MARKETING_REPORT_KIND;
    constructor() {}

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        this.toggleList.emit(false);
    }

    add(kind: IReportKind) {
        this.addReport.emit(kind);
    }

    toggleShowMore() {
        this.toggleList.emit(!this.isOpenList);
    }
}
