import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'widget',
    templateUrl: 'widget.component.html',
    styleUrls: ['widget.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetComponent {
    @Input() title: string;
    @Input() widgetLink: TemplateRef<any>;

    public TEXTS = TEXTS;
}
