import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import { AnalysisEvent, ITimeseriesDataItem } from '@libs/common/models/basicModels';
import { isFalseNumber } from '@libs/common/utils/utils';
import { COLORS, TEST_NOW_MS } from '@cityair/modules/analysis-events/constants';
import * as moment from 'moment-timezone';
import {
    createElement,
    getOrCreateTooltip,
} from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/tooltip-helper';

type ChartPoint = {
    x: string;
    y: number;
};
interface DataTypeLineChart {
    timeline: ITimeseriesDataItem[];
    dates: string[];
    event: AnalysisEvent;
}

@Component({
    selector: 'cityair-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnChanges {
    @Input() data: DataTypeLineChart;
    @Input() forecastData: any[];
    @Input() isDemoMode = false;
    @Output() sizeUpdate = new EventEmitter<{ left: number; width: number }>();
    public currentMmt = 'PM25';
    hoverLinePosition = 0;
    hoverLineVisible = false;
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}
    private externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);
            bodyLines.length = this.data?.event?.events?.length + 1;
            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');
            titleLines.forEach((title) => {
                const div = createElement('div');

                div.appendChild(document.createTextNode(title));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {
                const borderColor = tooltip?.dataPoints[i]?.dataset?.borderColor;
                const isForecast = tooltip?.dataPoints[i]?.dataset?.borderDash;
                const tr2 = this.createTooltipTrSecond(borderColor, body, isForecast);
                tableBody.appendChild(tr2);
            });
            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 25 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 25 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };
    private createTooltipTrSecond(color, body, isForecast) {
        const td1 = createElement('td', 'charts_tooltip__td1');
        const className = isForecast ? 'charts_tooltip__color-forecast' : 'charts_tooltip__color';
        const span = createElement('span', className);
        span.style.background = color;
        td1.appendChild(span);

        const span2 = createElement('span');
        span2.innerHTML = body[0];
        td1.appendChild(span2);

        const td2 = createElement('td', 'charts_tooltip__td2');
        td2.innerHTML = '';

        const tr = createElement('tr', 'charts_tooltip__tr');
        tr.appendChild(td1);
        tr.appendChild(td2);

        return tr;
    }

    dashLinesMap: {
        [key: string]: number;
    } = {};

    public pdkValue;
    public chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1000,
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    display: false,
                },
                time: {
                    tooltipFormat: 'DD MMM, HH:mm',
                    parser: (value: string) => moment(value).valueOf(),
                },
                grid: {
                    display: false,
                },
            },

            y: {
                position: 'right',
                border: {
                    display: false,
                },
                grid: {
                    color: '#e6e6e6',
                    tickLength: 0,
                },
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];
            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x + 10;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandler,
            },
        },
    };

    public chartType: ChartType = 'line';
    chartData: ChartDataset<ChartType, ChartPoint[]>[] = [];
    plugins: ChartConfiguration['plugins'] = [
        {
            id: 'adjustScales',
            afterUpdate: (chart) => {
                if (chart?.chartArea?.width) {
                    this.sizeUpdate.emit({
                        left: 0,
                        width: chart.chartArea.width,
                    });
                }
            },
        },
    ];

    ngOnChanges(changes: SimpleChanges) {
        this.currentMmt = this.data?.event?.param;
        this.pdkValue = this.data?.event?.pdk_value;
        this.createChartData();
    }

    private createChartData() {
        const result = [];
        if (this.pdkValue && this.data?.dates?.length) {
            const pdkData = Array(this.data?.dates?.length).fill(this.pdkValue);
            const chartData = this.prepareChartData(pdkData, this.data?.dates, this.isDemoMode);
            result.push({
                label: 'ПДКмр ' + this.currentMmt,
                data: chartData,
                type: 'line',
                borderColor: '#EE4F46',
            });
        }
        this.data?.timeline?.forEach((item, i) => {
            const timeline = item?.data?.measurements[this.currentMmt]?.values;
            if (timeline) {
                const chartData = this.prepareChartData(
                    timeline,
                    this.data?.dates,
                    this.isDemoMode
                );
                result.push({
                    label: item.name,
                    data: chartData,
                    type: 'line',
                    borderColor: COLORS[i] ?? '#C83FBE',
                });
            }
        });
        if (this.isDemoMode) {
            this.forecastData?.forEach((item, i) => {
                const timeline = item?.data;
                if (timeline) {
                    const chartData = this.prepareForecastChartData(timeline);
                    result.push({
                        label: item.label,
                        data: chartData,
                        type: 'line',
                        borderColor: COLORS[i] ?? '#C83FBE',
                        borderDash: [2, 2],
                    });
                }
            });
        }

        this.chartData = result;
    }

    private prepareChartData(timeline: number[], dates: string[], isDemoMode: boolean) {
        const data =
            timeline
                ?.map((y, i) => ({
                    y: this.getValue(y, dates[i]),
                    x: dates[i],
                }))
                .filter((point) => !!point.x) || [];
        if (!isDemoMode) {
            const first = moment(dates[0]);
            const last = moment(dates[dates.length - 1]);
            let timeStep = (last.valueOf() - first.valueOf()) / (dates.length - 1);
            const addDelta = (date: moment.Moment, d: number) =>
                date.add(d, 'milliseconds').toISOString();
            timeStep = (last.valueOf() - first.valueOf()) / (2 * dates.length);
            return [
                {
                    x: addDelta(first, -timeStep),
                    y: null,
                },
                ...data,
                {
                    x: addDelta(last, timeStep),
                    y: null,
                },
            ];
        } else {
            return data;
        }
    }

    private getValue(y, date) {
        if (this.isDemoMode) {
            return !isFalseNumber(y) && new Date(date).valueOf() <= TEST_NOW_MS
                ? Math.round(y)
                : null;
        }
        return !isFalseNumber(y) ? Math.round(y) : null;
    }
    private prepareForecastChartData(data) {
        const timeline = Object.values(data) as number[];
        const dates = Object.keys(data);
        return (
            timeline
                ?.map((y, i) => ({
                    y: !isFalseNumber(y) ? Math.round(y) : null,
                    x: dates[i] ?? null,
                }))
                .filter((point) => !!point.x) || []
        );
    }
}
