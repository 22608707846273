<cityair-map-control *ngIf="run"
    [isOpenSidebar]="store.select(selectIsSidebarOpen) | async"
    [title]="textTitleMapControl"
>
    <div map-control-period>
        <div class="plumes-map-control-period">
            {{ run | plumesRunTime }}
        </div>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="plumes-mmt"
            [showLayerButton]="true"
            [activeLayer]="showPlumesLayer$ | async"
            [title]="textOnMap"
            [mmts]="speciesList"
            [activeMmt]="currentMeasure"
            (selectMmt)="!isLoading && changeMmt($event)"
            (toggleLayer)="togglePlumesLayer()"
        ></shared-ui-mmt-selector>
    </div>
    <div switch-selector *ngIf="(store.select(selectAllPosts) | async)?.length">
        <ca-layer-button
            class="layer_button lens"
            [type]="LayerButtonIconType.SWITCH_POST"
            [size]="'big'"
            [active]="isShowPins"
            (clickOnIcon)="togglePostPin()"
            caTooltip
            caTooltipPlacement="left"
            [caTooltipFollowPointer]="true"
            [caTooltipTemplate]="switchButtonTooltip"
        >
        </ca-layer-button>
        <ng-template #switchButtonTooltip>
            <ca-tooltip [text]="isShowPins? textsSwitchButton.on : textsSwitchButton.off"></ca-tooltip>
        </ng-template>
    </div>
</cityair-map-control>