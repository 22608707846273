<div class="demo-wrapper">
    <div class="wrap-marketing" *ngIf="currentKind" #demoWrapper>
        <div class="context">
            <div class="item">
                <div class="title">
                    {{ isRu ? currentKind.name : currentKind.name_eng }}
                </div>
                <div class="desc marketing">
                    {{translateText.desc}}
                </div>
            </div>
            <div class="form-wrapper">
                <div class="label">{{translateText.showText}}</div>
                <div class="button-wrapper-marketing">
                    <div class="button-return-wrapper">
                        <button class="return-button" tabindex="1" (click)="demoType = 'pdf'"> {{translateText.buttonExamplePdf}}</button>
                    </div>
                    <div class="button-return-wrapper xlsx" (click)="demoType = 'xlsx'">
                        <button class="return-button" tabindex="2">{{translateText.buttonExampleXlsx}}</button>
                    </div>
                </div>
            </div>
            <div class="contact-wrapper">
                <div class="text">
                    {{translateText.contact}}
                </div>
                <div class="contact">
                    <a class="phone" href="tel:+74955405516">+ 7 495 540 55 16 </a>
                    <a class="mail" href="mailto:sales@cityair.ru">sales&#64;cityair.ru</a>
                </div>
            </div>
        </div>
        <div class="preview" *ngIf="demoType" [ngClass]="{ 'xlsx-wrapper' : demoType === 'xlsx' }">
            <div class="pdf wrapper scroll" *ngIf="demoType === 'pdf'">
                    <div class="img-responsive"></div>
            </div>
            <div class="xlsx wrapper scroll" *ngIf="demoType === 'xlsx'">
                <div class="image-header">
                    <alert-tags [typeTag]="'green'" [text]="translateText.tagXlsx"></alert-tags>
                    <div class="period">{{translateText.xlsxPeriod}}: <span>09.06.2022 - 12.08.2022</span></div>
                </div>
                <div class="img-responsive" [ngStyle]="{ 'padding-left': xlsxPaddingLeft }"></div>
            </div>
        </div>
    </div>
</div>
