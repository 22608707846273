import { NgModule } from '@angular/core';
import { CommonModule, I18nPluralPipe } from '@angular/common';

import {
    MultipleSearchfilterPipe,
    MultipleSearchFnsPipe,
    SafeHtmlPipe,
    SortingPipe,
    AsyncSortingPipe,
    OrderByPipe,
    TimeDatePipe,
    ShortDatePipe,
    FileSizePipe,
    CalculateCurrentTickFreqPipe,
    NeedRenderTickPipe,
    DistancePipe,
    DurationDateRangeFormatPipe,
    DurationDateMsFormatPipe,
    ShortDateTimelinePipe,
    shortDateFormatPipe,
    dateRangeTextPipe,
    shortDateFormatWithOrNotYearPipe,
    ShortUserNamePipe,
    ValueFormatNumberPipe,
    TimeAgoPipe,
    CaTimePipe,
    shortDateWithTodayPipe,
    DurationFullFormatPipe,
    NoValueFormatPipe,
    NetworkDensityPipe,
} from './pipes';

@NgModule({
    imports: [CommonModule],
    providers: [I18nPluralPipe],
    exports: [
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        AsyncSortingPipe,
        OrderByPipe,
        TimeDatePipe,
        ShortDatePipe,
        FileSizePipe,
        CalculateCurrentTickFreqPipe,
        NeedRenderTickPipe,
        DistancePipe,
        DurationDateRangeFormatPipe,
        DurationDateMsFormatPipe,
        ShortDateTimelinePipe,
        shortDateFormatPipe,
        dateRangeTextPipe,
        shortDateFormatWithOrNotYearPipe,
        ShortUserNamePipe,
        ValueFormatNumberPipe,
        TimeAgoPipe,
        CaTimePipe,
        shortDateWithTodayPipe,
        DurationFullFormatPipe,
        NoValueFormatPipe,
        NetworkDensityPipe,
    ],
    declarations: [
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        AsyncSortingPipe,
        OrderByPipe,
        TimeDatePipe,
        ShortDatePipe,
        FileSizePipe,
        CalculateCurrentTickFreqPipe,
        NeedRenderTickPipe,
        DistancePipe,
        DurationDateRangeFormatPipe,
        DurationDateMsFormatPipe,
        ShortDateTimelinePipe,
        shortDateFormatPipe,
        dateRangeTextPipe,
        shortDateFormatWithOrNotYearPipe,
        ShortUserNamePipe,
        ValueFormatNumberPipe,
        TimeAgoPipe,
        CaTimePipe,
        shortDateWithTodayPipe,
        DurationFullFormatPipe,
        NoValueFormatPipe,
        NetworkDensityPipe,
    ],
})
export class PipesModule {}
