import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';

import { PlumesCalculationResultsCardComponent } from './plumes-calculation-results-card.component';

@NgModule({
    imports: [CommonModule, LoadersModule],
    exports: [PlumesCalculationResultsCardComponent],
    declarations: [PlumesCalculationResultsCardComponent],
})
export class PlumesCalculationResultsCardModule {}
