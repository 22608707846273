import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
} from '@angular/core';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { Store } from '@ngrx/store';
import { LngLat, LngLatBounds, LngLatLike } from 'mapbox-gl';
import { getOutdoorValue, indoorSelectors } from '@cityair/modules/indoor/store/selectors';
import { selectMmt, selectPoint, selectPointOutdoor } from '@cityair/modules/indoor/store/actions';
import { Post } from '@cityair/modules/indoor/services/api';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IndoorConfig, IFloorPin } from '@libs/common/models/feature-config';
import {
    ImgToMap,
    INDOOR_MAP_STYLE,
} from '@cityair/modules/indoor/components/floor-map/map-helpers';
import { TEXTS } from '@libs/common/texts/texts';
import { LayerButtonIconType } from '@cityair/libs/shared/modules/overlay/components/layer-button/layer-button.component';
import {
    analyticType,
    getIndoorColor,
    indoorNamesToGlobal,
    IndoorZone,
    MMT_INDOOR,
} from '@cityair/modules/indoor/config';
import { getColorFromZone } from '@cityair/utils/utils';
import { detectTouchDevice } from '@libs/common/utils/detect-touch-device';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { getAssetPath } from '@cityair/config';
import { IIndoorState } from '@cityair/modules/indoor/store/reducers';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { DEFAULT_AQI_TYPE } from '@libs/common/consts/default-aqi-type.const';
import { PM25, PM10, CO2, HUM, PRES, TEMP } from '@libs/common/consts/substance.consts';
import { ZONES } from '@libs/common/consts/zone.const';
import { AqiType } from '@libs/common/enums/aqi.type';

@Component({
    selector: 'floor-map',
    templateUrl: './floor-map.component.html',
    styleUrls: ['./floor-map.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorMapComponent implements OnInit {
    @Input() configMap: IndoorConfig;

    bounds: LngLatBounds;
    markerState = markerState;
    LayerButtonIconType = LayerButtonIconType;
    indoorSelectors = indoorSelectors;
    selectMmt = selectMmt;
    INDOOR_MAP_STYLE = INDOOR_MAP_STYLE;
    onIsEnabledChart = onIsEnabledChart;
    analyticType = analyticType;

    pinTooltipText = '';
    isTouchDevice = detectTouchDevice();

    MMT_ARRAY = Object.keys(MMT_INDOOR).filter((v) => v !== MMT_INDOOR.pressure) as MMT_INDOOR[];

    names = {
        [MMT_INDOOR.indoorAqi]: TEXTS.NAMES[AqiType.indoor],
        [MMT_INDOOR.pm2]: TEXTS.NAMES[PM25],
        [MMT_INDOOR.pm10]: TEXTS.NAMES[PM10],
        [MMT_INDOOR.co2]: TEXTS.NAMES[CO2],
        [MMT_INDOOR.humidity]: TEXTS.NAMES[HUM],
        [MMT_INDOOR.pressure]: TEXTS.NAMES[PRES],
        [MMT_INDOOR.temperature]: TEXTS.NAMES[TEMP],
    };

    imgToMap: ImgToMap;
    defaultMapPath = 'default-map.png';
    impSourcePath: string;
    floorPin: IFloorPin;
    floorPinCoordinates: LngLatLike;
    pins: {
        getPins: Observable<Post[]>;
        getSelectedPinIds: Observable<number>;
        getValue: (pin) => Observable<any>;
        getColor: (value: number, mmt) => string;
        clickCb: (pin) => void;
    };

    outdoorPins: {
        getPins: Observable<Post[]>;
        getSelectedPinIds: Observable<number>;
        getValue: (pin) => Observable<any>;
        getColor: (value: number, mmt) => string;
        clickCb: (pin) => void;
    };

    constructor(public store: Store<IIndoorState>, private _changeDetectorRef: ChangeDetectorRef) {}

    getAsset = getAssetPath;

    ngOnInit(): void {
        // TODO need add check file exist
        if (this.configMap?.imgSourcePath) {
            this.impSourcePath = `./indoor-map-imgs/${this.configMap?.imgSourcePath}`;
        } else {
            this.impSourcePath = `./indoor-map-imgs/${this.defaultMapPath}`;
        }
        if (this.configMap?.floorPin) {
            this.floorPin = this.configMap?.floorPin;
        }

        this.store
            .select(indoorSelectors.listPoints)
            .pipe(
                filter((list) => !!list?.length),
                take(1)
            )
            .subscribe((list) => {
                this.imgToMap = new ImgToMap(this.configMap.imgSize);
                this.bounds = this.configMap?.maxBounds
                    ? this.getMaxBounds(this.configMap.maxBounds)
                    : this.getImgMaxBounds();
                if (!this.floorPin?.hasOwnProperty('coordinates')) {
                    this.floorPinCoordinates = [
                        this.imgToMap.getRightLon() / 2,
                        this.imgToMap.getTopLat() / 2,
                    ];
                } else if (this.floorPin?.coordinates) {
                    this.floorPinCoordinates = this.imgToMap.getCoordinates(
                        this.floorPin?.coordinates
                    );
                }
                this.imgToMap.setCoordinates(list, this.configMap.pinsCoordinatesInPixels);
                this._changeDetectorRef.markForCheck();
            });

        this.pins = {
            getPins: this.store.select(indoorSelectors.listPoints),
            getSelectedPinIds: this.store.select(indoorSelectors.activePointId),
            getValue: (pin) => this.store.select(indoorSelectors.getMeasurementValue(pin.id)),
            getColor: (value, zone: IndoorZone) => getIndoorColor(zone, value),
            clickCb: (pin) => this.store.dispatch(selectPoint({ id: pin.id })),
        };

        this.outdoorPins = {
            getPins: this.store.select(indoorSelectors.outdoorPosts),
            getSelectedPinIds: this.store.select(indoorSelectors.activeOutdoorId),
            getValue: (pin) => this.store.select(getOutdoorValue(pin.id)),
            getColor: (value, obj: { mmt: string; scheme: MeasureScheme }) => {
                let mmt = indoorNamesToGlobal[obj.mmt] || obj.mmt;
                if (AqiType.indoor === mmt) {
                    mmt = DEFAULT_AQI_TYPE;
                }

                const zone = ZONES[obj.scheme][mmt];

                if (!zone) {
                    return '#8FCBF9';
                }

                return getColorFromZone(zone, value);
            },
            clickCb: (pin) => this.store.dispatch(selectPointOutdoor({ id: pin.id })),
        };
    }

    private getImgMaxBounds(): LngLatBounds {
        const sw = new LngLat(-this.imgToMap.getRightLon() / 2, -this.imgToMap.getTopLat() / 2);
        const ne = new LngLat(this.imgToMap.getRightLon() * 1.5, this.imgToMap.getTopLat() * 1.5);

        return new LngLatBounds(sw, ne);
    }

    private getMaxBounds(maxBounds): LngLatBounds {
        const sw = new LngLat(maxBounds[0], maxBounds[1]);
        const ne = new LngLat(maxBounds[2], maxBounds[3]);
        return new LngLatBounds(sw, ne);
    }

    public getOutDoorCoordinate(post: Post): LngLat {
        if (this.configMap?.outdoorPinsConfig && this.imgToMap) {
            const pin = this.configMap.outdoorPinsConfig.find((item) => item.id === post.id);
            if (pin && pin.coordinates) {
                return new LngLat(pin.coordinates[0] / 1000, pin.coordinates[1] / 1000);
            }
        }
    }
    /* clicked(e) {
        if (e.lngLat) {
            console.log(e.lngLat);
            console.log(`[${(e.lngLat.lat * 1000).toFixed(0)}, ${(e.lngLat.lng * 1000).toFixed(0)}]`);
        }
    } */
}
