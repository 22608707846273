import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import { selectMyRole } from '@cityair/modules/core/store/selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { selectCurrentRegionCoefs } from '@cityair/modules/core/store/group/group.feature';

@Component({
    selector: 'cityair-settings-group',
    templateUrl: './settings-group.component.html',
    styleUrls: ['./settings-group.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsGroupComponent implements OnDestroy {
    textsSettings = TEXTS.CONFIG;
    readonly mainComponentUrl = `/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.groupSettings}/`;
    public ngDestroyed$ = new Subject<void>();
    public settingList = [
        {
            id: SETTINGS_PAGES.groupSettingsMap,
            value: this.textsSettings.settingGroupTitles.map,
        },
        {
            id: SETTINGS_PAGES.groupSettingsStartModule,
            value: this.textsSettings.settingGroupTitles.startModule,
        },
    ];
    constructor(private router: Router, public store: Store, private ref: ChangeDetectorRef) {
        this.store
            .select(selectCurrentRegionCoefs)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((coef) => {
                const isset = this.settingList.find(
                    (v) => v.id === SETTINGS_PAGES.groupSettingsCoef
                );
                if (coef && !isset) {
                    this.settingList.push({
                        id: SETTINGS_PAGES.groupSettingsCoef,
                        value: this.textsSettings.settingGroupTitles.coef,
                    });
                }
                this.ref.markForCheck();
            });
        this.store
            .select(selectMyRole)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((role) => role !== null)
            )
            .subscribe((myRole) => {
                if (!myRole.edit_group) {
                    this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.system}`]);
                }
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    openPage(event) {
        this.router.navigate([this.mainComponentUrl + event]);
    }
}
