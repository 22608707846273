import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';
import { copyObj } from '@libs/common/utils/utils';

@Component({
    selector: 'control-select',
    templateUrl: `control-select.component.html`,
    styleUrls: ['control-select.component.less'],
    providers: [],
})
export class ControlSelectComponent implements OnInit {
    TEXTS = TEXTS;

    @Input() text = '';
    @Input() title = ' ';
    @Input() items: any[];
    @Input() model: any;
    @Input() format?: 'simple' | 'array' | 'items' = 'simple';
    // simple - string | number
    // array - (string | number)[]
    // items - если в модели используем такой же формат как и в items

    @Input() keyValue: string; // имя поля в перечисляемых объектах
    @Input() keyName: string; // имя поля в перечисляемых объектах

    @Input() disabled: boolean;
    @Input() buttonsSelectAll: boolean;
    @Input() optionsWidth?: string;
    @Input() classPopup?: string;
    @Input() classOption?: string;
    @Input() classText?: string;
    @Input() classButton?: string;

    @Output() closeEvent = new EventEmitter<any>();
    @Output() modelChange = new EventEmitter<any>();
    @Output() openEvent = new EventEmitter<void>();

    show = false;

    getText() {
        // если единственный выбран то выводим его значение
        if (!this.text && this.format === 'simple') {
            const item = this.items.find((i) => i[this.keyValue] === this.model);
            return item ? item[this.keyName] + '' : this.text;
        }
        return this.text;
    }

    ngOnInit() {
        if (!this.model && this.format === 'array') {
            this.model = [];
        }

        // чтоб не изменить исходный. Пушим же сюда
        this.model = copyObj(this.model);
    }

    equally(item: any) {
        if (this.format === 'simple') {
            return item[this.keyValue] == this.model;
        }

        if (this.format === 'array') {
            return this.model.includes(item[this.keyValue]);
        }

        if (this.format === 'items') {
            return this.model.find((m) => m[this.keyValue] === item[this.keyValue]);
        }
    }

    selectAll() {
        this.model = [];
        this.items.forEach((item) => this.model.push(item));
        this.modelChange.emit(this.model);
    }

    deselectAll() {
        this.model = [];
        this.modelChange.emit(this.model);
    }

    add(item: any) {
        const val = item[this.keyValue];

        if (this.format === 'simple') {
            this.model = val;
            this.close();
        }

        if (this.format === 'array') {
            const index = this.model.indexOf(val);

            if (index === -1) {
                this.model.push(val);
            } else {
                this.model.splice(index, 1);
            }
        }

        if (this.format === 'items') {
            let index: number;
            const find = this.model.find((m, _index) => {
                index = _index;
                return m[this.keyValue] === item[this.keyValue];
            });

            if (find) this.model.splice(index, 1);
            else this.model.push(item);
        }

        this.modelChange.emit(this.model);
    }

    toggle = () => (this.show ? this.close() : this.open());

    open = () => {
        if (!this.disabled) {
            this.show = true;

            this.openEvent.emit();
        }
    };

    close() {
        this.show = false;

        this.closeEvent.emit(this.model);
    }

    clickOutside = () => (this.show ? this.close() : null);
}
