import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { IControl } from '@cityair/modules/reports/models';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'cityair-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
    @Input() params: IControl;
    @Output() setFile = new EventEmitter<any>();

    @ViewChild('fileUpload') formInput: ElementRef;
    file: File;
    public fileName = '';
    public fileExt = '';
    public fileSize: number;
    public uploadProgress = 0;
    uploadSub: Subscription;
    LANG = LANGUAGE;
    public status: 'none' | 'loading' | 'done';
    public fileUploadText = TEXTS.REPORTS.fileUpload;
    public availableFormatText = TEXTS.REPORTS.fileUpload.availableFormat;
    constructor(private http: HttpClient, private _changeDetectorRef: ChangeDetectorRef) {}
    public errorText = this.fileUploadText.error;
    public error: string;

    dragDropLoad($event): void {
        const file: File = $event;
        this.uploadFile(file);
        this._changeDetectorRef.detectChanges();
    }

    public onFileSelected(event, isFile = false) {
        const file: File = event.target.files[0];
        this.uploadFile(file);
        this._changeDetectorRef.detectChanges();
    }

    private uploadFile(file: File) {
        this.error = null;
        this.file = file;
        if (file && this.params.upload_url) {
            this.fileExt = file?.name.split('.').pop();
            if (this.params?.values.indexOf(this.fileExt) >= 0) {
                this.fileName = file.name.split('.').slice(0, -1).join('.') || file.name;
                this.fileSize = file?.size ?? null;
                this.setFile.emit(file);
            } else {
                this.error = this.errorText.incorrectFormat;
            }
        } else {
            this.error = this.errorText.loadError;
        }
        this._changeDetectorRef.detectChanges();
    }

    cancelUpload() {
        this.formInput.nativeElement.value = '';
        this.file = null;
        this.fileName = '';
        this.fileExt = '';
        this.fileSize = null;
        this.status = 'none';
        this.error = null;
        this.setFile.emit(null);
        // this.reset();
    }

    reset() {
        this.uploadProgress = 0;
        this.uploadSub = null;
    }
}
