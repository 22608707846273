import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitoringObjectPlateComponent } from './monitoring-object-plate.component';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';

@NgModule({
    imports: [CommonModule, KebabMenuModule, StaticTagsModule, ValueCircleModule],
    exports: [MonitoringObjectPlateComponent],
    declarations: [MonitoringObjectPlateComponent],
})
export class MonitoringObjectPlateModule {}
