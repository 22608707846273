<div class="map-settings">
    <div class="map-settings__left">
        <div class="header">
            <header-page
                [cbBack]="back"
                [textObject]="{
                    titlePage: textsSettings.settingGroupTitles.map,
                    btnBack: textAdminPanel.back
                }"
            ></header-page>
        </div>
        <div class="map-settings__top">
            <div class="map-settings__content scroll">
                <div class="position-mode" *ngIf="isEditPositionMode"></div>
                <div class="bounds-wrapper">
                    <div class="title-bounds"> {{ textsSettings.mapSettings.bounds.title }}</div>
                    <div class="desc">
                        <span *ngIf="!existBorder else infoTemp">
                            {{ textsSettings.mapSettings.bounds.description }}
                        </span>
                        <ng-template #infoTemp>
                            {{ textsSettings.mapSettings.bounds.info }}
                        </ng-template>
                    </div>
                    <ng-container *ngIf="!isEditBorder else editBorderTemp">
                        <div class="set-border-btn" (click)="setBorderMode()">
                            <span *ngIf="existBorder">{{ textsSettings.mapSettings.bounds.setBoundsTitle }}</span>
                            <span *ngIf="!existBorder">{{ textsSettings.mapSettings.bounds.newBoundsTitle }}</span>
                        </div>
                    </ng-container>
                    <ng-template #editBorderTemp>
                        <div class="btn-wrapper">
                            <btn-cancel (click)="cancelBorderMode()">
                                {{ textsCommon.cancel }}
                            </btn-cancel>
                            <btn-accept
                                [isLoading]="false"
                                [disabled]="this.drawFeatures?.length === 0"
                                (cbClick)="applyBorder()"
                            >
                                {{ textsSettings.mapSettings.bounds.newBoundsTitle }}
                            </btn-accept>
                        </div>
                    </ng-template>
                </div>
                <div class="position-wrapper">
                    <div class="border-mode" *ngIf="isEditBorder"></div>
                    <div class="title">
                         {{ textsSettings.mapSettings.centerZoom.title }}
                    </div>
                    <div class="desc">
                        {{ textsSettings.mapSettings.centerZoom.info }}
                    </div>
                    <div class="form" *ngIf="form">
                        <div class="wrapper-content">
                            <ca-input
                                class="input-wrapper"
                                type="text"
                                controlName="zoom"
                                [form]="form"
                                [valueForm]="getValueForm('zoom')"
                                [label]="textsSettings.mapSettings.centerZoom.labelZoom"
                                [disabled]="true"
                            ></ca-input>
                        </div>
                        <div class="wrapper-coordinate">
                            <input-for-coordinate
                                type="text"
                                controlName="lat"
                                [form]="form"
                                [valueForm]="getValueForm('lat')"
                                [label]="textsSettings.mapSettings.centerZoom.labelLat"
                                [positioning]="'left'"
                                [disabled]="true"
                            ></input-for-coordinate>
                            <input-for-coordinate
                                type="text"
                                controlName="lng"
                                [form]="form"
                                [valueForm]="getValueForm('lng')"
                                [label]="textsSettings.mapSettings.centerZoom.labelLng"
                                [positioning]="'right'"
                                [disabled]="true"
                            ></input-for-coordinate>
                        </div>
                    </div>
                    <div class="fixed-wrapper">
                        <ng-container *ngIf="!isEditPositionMode else editCenterZoomTemp">
                            <div class="edit-btn" (click)="setEditPosition()">
                                {{ textsSettings.mapSettings.bounds.setBoundsTitle }}
                            </div>
                        </ng-container>
                        <ng-template #editCenterZoomTemp>
                            <div class="btn-wrapper">
                                <btn-cancel (click)="cancelPositionMode()" [disabled]="isSavingMapSettings">
                                    {{ textsCommon.cancel }}
                                </btn-cancel>
                                <btn-accept
                                    [isLoading]="isSavingMapSettings"
                                    [disabled]="!isChangesZoomCenter"
                                    (cbClick)="savePosition()"
                                >
                                    {{ textsCommon.save }}
                                </btn-accept>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="error-block" *ngIf="errorPosts" [ngClass]="{ 'with-center': errorCenter }">
                <cross-button class="close-button"
                    size="small"
                    [color]="'#FFFFFF'"
                    [needHover]="false"
                    (click)="errorPosts=false"
                ></cross-button>
                <div class="warning-icon"></div>
                <div class="text-error">{{textsSettings.mapSettings.errorPosts}}</div>
            </div>
            <div class="error-block" *ngIf="errorCenter">
                 <cross-button
                     class="close-button"
                     size="small"
                     [color]="'#FFFFFF'"
                     [needHover]="false"
                     (click)="errorCenter=false"
                 ></cross-button>
                 <div class="warning-icon"></div>
                 <div class="text-error">{{textsSettings.mapSettings.errorCenter}}</div>
            </div>
        </div>
    </div>
    <div class="map-settings__plug">
        <ng-container *ngIf="showMap">
            <ng-container *ngIf="showInfoEditBorder">
                <div class="info-border-wrapper">
                    <ca-info-icon class="icon-info" [withGradient]="true"></ca-info-icon>
                    <div class="info-text">
                        <div class="text-info-border" *ngIf="drawFeatures?.length else noBorderTemp">
                            {{  textsSettings.mapSettings.bounds.infoTooltip }}
                        </div>
                        <ng-template #noBorderTemp>
                            <div class="text-info-border">
                                {{  textsSettings.mapSettings.bounds.notExist }}
                            </div>
                        </ng-template>
                    </div>
                    <cross-button class="close-button"
                        size="small"
                        [color]="'#FFFFFF'"
                        [needHover]="false"
                        (click)="showInfoEditBorder=false"
                    ></cross-button>
                </div>
            </ng-container>
            <ng-container *ngIf="showInfoEditPosition">
                <div class="info-border-wrapper">
                    <ca-info-icon class="icon-info" [withGradient]="true"></ca-info-icon>
                    <div class="info-text">
                        <div class="text-info-border">
                            {{  textsSettings.mapSettings.centerZoom.description}}
                        </div>
                    </div>
                    <cross-button class="close-button"
                        size="small"
                        [color]="'#FFFFFF'"
                        [needHover]="false"
                        (click)="showInfoEditPosition=false"
                    ></cross-button>
                </div>
            </ng-container>
            <mgl-map
                class="mapbox-map"
                movingMethod="jumpTo"
                [style.visibility]="showMap ? 'visible' : 'hidden'"
                [style]="mapSettings.style"
                [zoom]="[mapSettings.zoom]"
                [minZoom]="mapSettings.minzoom"
                [maxZoom]="mapSettings.maxzoom"
                [center]="mapSettings.center"
                [pitchWithRotate]="false"
                [dragRotate]="false"
                [renderWorldCopies]="true"
                [attributionControl]="false"
                (zoomEvt)="updateZoomCenterForm($event)"
                (mapCreate)="mapCreate($event)"
                (mapLoad)="mapboxLoad($event)"
                (mapDragEnd)="updateZoomCenterForm($event)"
            >
                <mgl-control mglNavigation [position]="'top-right'" [showZoom]="true" [showCompass]="false"></mgl-control>
                <ng-container *ngIf="allPosts">
                    <mgl-marker
                        *ngFor="let pin of allPosts"
                        class="mapboxgl-marker"
                        [lngLat]="[pin.geometry?.coordinates[0], pin.geometry?.coordinates[1]]"
                    >
                        <cityair-correlation-pin
                            [value]="null"
                            [color]="'#404655'"
                            [valueMode]="false"
                            caTooltip
                            caTooltipPlacement="left"
                            [caTooltipFollowPointer]="true"
                            [caTooltipTemplate]="correlationPinTooltip"
                        ></cityair-correlation-pin>
                        <ng-template #correlationPinTooltip>
                            <ca-info-tooltip *ngIf="!isTouchDevice" [text]="pin?.name"></ca-info-tooltip>
                        </ng-template>
                    </mgl-marker>
                </ng-container>
            </mgl-map>
        </ng-container>
    </div>
</div>
