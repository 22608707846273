import { Directive, EventEmitter, Output, HostListener, HostBinding } from '@angular/core';
@Directive({
    selector: '[appDragDropFileUpload]',
})
export class DragDropFileUploadDirective {
    @Output() fileDropped = new EventEmitter<any>();
    @HostBinding('style.background-color') private background = '#ffffff';

    @HostListener('dragover', ['$event'])
    public dragOver(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.background = '#e0ebfe'; // @blueLight
    }

    @HostListener('dragleave', ['$event'])
    public dragLeave(event: any) {
        event.stopPropagation();
        event.preventDefault();
        this.background = '#ffffff';
    }

    @HostListener('drop', ['$event'])
    public drop(event: any) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.dataTransfer?.files[0];
        if (file) {
            this.fileDropped.emit(file);
        }
    }
}
