import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, DefaultDataServiceConfig } from '@ngrx/data';

import { environment } from 'environments/environment';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { SearchInputBasicModule } from '@libs/shared-ui/components/search-input-basic/search-input-basic.module';
import { SearchResultPlaceholderModule } from '@cityair/components/search-result-placeholder/search-result-placeholder.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { InputDateRangeModule } from '@cityair/libs/common/calendar/input-date-range/input-date-range.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { TextareaModule } from '@cityair/components/textarea/textarea.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { TagsListModule } from '@libs/shared-ui/components/tags-list/tags-list.module';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { SearchInputModule } from '@libs/shared-ui/components/search-input/search-input.module';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';
import { EntityStoreModule } from './entity-store.module';
import { reportsReducers } from './store/reducers';
import { ReportsEffects } from './store/effects';
import { entityReportsConfig } from './entity-metadata';
import { ReportsMainComponent } from './components/reports-main/reports-main.component';
import { CompareComponent } from './components/compare/compare.component';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { reportsRoutes } from './reports-routing';
import { AddReportComponent } from './components/add-report/add-report.component';
import { StatusCounterComponent } from './components/reports-list/status-counter/status-counter.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ReportKindViewComponent } from './components/reports-list/report-kind-view/report-kind-view.component';
import { FileUploadComponent } from './components/add-report/file-upload/file-upload.component';
import { ReportDetailComponent } from './components/reports-list/report-detail/report-detail.component';
import { ReportFormDropdownComponent } from './components/add-report/report-form-dropdown/report-form-dropdown.component';
import { ReportDateRangeComponent } from './components/reports-list/report-date-range/report-date-range.component';
import { MeasureSelectListComponent } from './components/measure-select-list/measure-select-list.component';
import { UpdateReportPopupComponent } from './components/reports-list/update-report-popup/update-report-popup.component';
import { DotSvgComponent } from './components/reports-list/dot-svg/dot-svg.component';
import { GradientSliderModule } from '@libs/shared-ui/components/gradient-slider/gradient-slider.module';
import { DemoReportComponent } from '@cityair/modules/reports/components/add-report/demo-report/demo-report.component';
import { AlertTagsModule } from '@libs/shared-ui/components/alert-tags/alert-tags.module';
import { CheckboxModule } from '@libs/shared-ui/components/checkbox/checkbox.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { DragDropFileUploadDirective } from '@cityair/modules/reports/components/add-report/file-upload/dragDrop.directive';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.vanga_api_url,
};

@NgModule({
    declarations: [
        ReportsMainComponent,
        CompareComponent,
        ReportsListComponent,
        AddReportComponent,
        DemoReportComponent,
        StatusCounterComponent,
        AnalyticsComponent,
        ReportKindViewComponent,
        ReportDetailComponent,
        ReportFormDropdownComponent,
        ReportDateRangeComponent,
        MeasureSelectListComponent,
        UpdateReportPopupComponent,
        DotSvgComponent,
        FileUploadComponent,
        DragDropFileUploadDirective,
    ],
    imports: [
        RouterModule,
        RouterModule.forChild(reportsRoutes),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('reports', reportsReducers),
        EffectsModule.forFeature([ReportsEffects]),
        EntityDataModule.forRoot(entityReportsConfig),
        EntityStoreModule,
        PageTabsModule,
        SortListItemModule,
        SearchInputBasicModule,
        CalendarMonthsModule,
        PipesModule,
        SearchResultPlaceholderModule,
        InputDropdownModule,
        InputDateRangeModule,
        InputModule,
        LoadersModule,
        TextareaModule,
        CrossButtonModule,
        BtnAcceptModule,
        BtnCancelModule,
        TagsListModule,
        SelectboxModule,
        DirectivesModule,
        SelectboxRadioModule,
        KebabMenuModule,
        PopupAskModule,
        CardActionsModule,
        NonDataScreenModule,
        InputForDropdownModule,
        SearchInputModule,
        ItemRadioModule,
        GradientSliderModule,
        AlertTagsModule,
        CheckboxModule,
        SwitchItemModule,
    ],
    providers: [
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
    ],
})
export class ReportsModule {}
