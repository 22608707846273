import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter, Subject } from 'rxjs';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { SETTINGS_PAGES } from '@cityair/modules/settings/constants';
import { selectExtConfig } from '@cityair/modules/core/store/group/group.feature';
import { takeUntil } from 'rxjs/operators';
import { selectAvailableModule } from '@cityair/modules/core/store/selectors';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { getModulesInfo, ModuleAccessInfo } from '@libs/common/models/access.model';
import { setStartModuleSettings } from '@cityair/modules/settings/store/settings/actions';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { selectIsSavingStartModule } from '@cityair/modules/settings/store/settings/selectors';

@Component({
    selector: 'start-module-settings',
    templateUrl: './start-module-settings.component.html',
    styleUrls: ['./start-module-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartModuleSettingsComponent implements OnDestroy {
    textsSettings = TEXTS.CONFIG;
    textsCommon = TEXTS.COMMON;
    textsNoSelect = TEXTS.CONFIG.startModuleSetting.noSelect;
    isShowDropdown = false;
    public ngDestroyed$ = new Subject<void>();
    public isLoading = true;
    public moduleAccessInfo: ModuleAccessInfo[];
    public modulesList: CheckboxItem[] = [];
    public MAIN_PAGE = MAIN_PAGES;
    initModule: MAIN_PAGES;
    public isSaving = false;
    activeModule: MAIN_PAGES;
    public selectedModuleName: string;
    public selectedModuleIcon: string = 'empty';
    constructor(private router: Router, public store: Store, private ref: ChangeDetectorRef) {
        store
            .select(selectExtConfig)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((g) => !!g)
            )
            .subscribe((config) => {
                this.initModule = config?.startModule ?? null;
                this.activeModule = config?.startModule ?? null;
                this.update();
                this.ref.markForCheck();
            });
        this.store
            .select(selectAvailableModule)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v.length)
            )
            .subscribe((modules) => {
                this.moduleAccessInfo = this.prepareModulesList(modules);
                this.isLoading = false;
                this.update();
                this.ref.markForCheck();
            });
        store
            .select(selectIsSavingStartModule)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => {
                this.isSaving = isLoading;
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    selectModule(list: CheckboxItem[]) {
        const selectedModule = this.modulesList.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        if (selectedModule) {
            this.selectedModuleName = selectedModule.label;
            this.selectedModuleIcon = selectedModule.icon;
            this.activeModule = selectedModule.id as MAIN_PAGES;
            this.ref.markForCheck();
        }
    }

    cancel() {
        this.router.navigate([`/${SETTINGS_PAGES.settings}/${SETTINGS_PAGES.groupSettings}/`]);
    }

    save() {
        this.store.dispatch(setStartModuleSettings({ payload: this.activeModule }));
    }

    delete() {
        this.activeModule = null;
        this.update();
    }

    private update() {
        if (!this.moduleAccessInfo) {
            this.selectedModuleName = null;
            this.selectedModuleIcon = 'empty';
            return;
        }

        this.modulesList = this.moduleAccessInfo.map((v) => ({
            id: v.module,
            label: v.name,
            selected: this.activeModule === v.module,
            icon: v.icon,
        }));
        if (this.activeModule) {
            const current = this.modulesList.find((v) => v.selected == true);
            this.selectedModuleName = current?.label;
            this.selectedModuleIcon = current?.icon;
        } else {
            this.selectedModuleName = null;
            this.selectedModuleIcon = 'empty';
        }
    }

    private prepareModulesList(modules) {
        const result = [
            {
                icon: 'empty',
                name: this.textsSettings.startModuleSetting.noSelect,
                module: null,
            },
        ];
        const modulesList = getModulesInfo(modules, false);
        return [...result, ...modulesList];
    }
}
