import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { Style } from 'mapbox-gl';

export const MAP_ACTION_DURATION = 1000;
export const DEFAULT_MAP_STYLES = [
    MapStyleType.cityair,
    MapStyleType.satellite,
    MapStyleType.outdoor,
];
export const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-streets-v12';
export const OUTDOOR_STYLE = 'mapbox://styles/mapbox/outdoors-v12';
export const OSM_STYLE = {
    version: 8,
    sources: {
        osm: {
            type: 'raster',
            tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
            tileSize: 256,
            attribution:
                '&copy; <a href="https://www.mapbox.com"> Mapbox</a> | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
    },
    layers: [
        {
            id: 'osm',
            type: 'raster',
            source: 'osm',
        },
    ],
} as Style;
