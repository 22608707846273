import { ChangeDetectionStrategy, Component } from '@angular/core';
import { selectAllCities } from '@cityair/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import {
    selectCityCard,
    selectCurrentSummaryData,
    selectIsDemoAnalytic,
    selectIsLoadingSummary,
    setCityCard,
} from '@cityair/modules/core/store/citycard/citycard.feature';
import { Locality } from '@libs/common/models/basicModels';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'analytics-page',
    styleUrls: ['./analytics-page.component.less'],
    templateUrl: 'analytics-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsPage {
    selectAllCities = selectAllCities;
    selectCityCard = selectCityCard;
    selectIsLoadingSummary = selectIsLoadingSummary;
    selectCurrentSummaryData = selectCurrentSummaryData;
    selectIsDemoAnalytic = selectIsDemoAnalytic;
    textsAdminPanel = TEXTS.ADMIN_PANEL;
    constructor(public store: Store) {}

    selectCity(city: Locality) {
        this.store.dispatch(setCityCard({ city: city }));
    }
}
