import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ChartDataset, ChartType } from 'chart.js';

import { ALERT_COLORS } from '@libs/common/consts/alert-colors';
import { AQI } from '@libs/common/consts/substance.consts';
import { TEXTS } from '@libs/common/texts/texts';
import { options } from './doughnut-chart.config';

import { AqiType } from '@libs/common/enums/aqi.type';
import { declOfNum } from '@libs/common/utils/utils';
import {
    AQI_IN_COLORS,
    LABELS_AQI_IN,
} from '@libs/shared-ui/components/analytic/analytic.component';

@Component({
    selector: 'cs-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoughnutChartComponent implements OnChanges {
    @Input() isDemo: boolean;
    @Input() data: number[];
    @Input() tooltipDescription: string;
    @Input() measure?: string = TEXTS.NAMES[AQI];

    titleChartText = TEXTS.CITY_CARD.charts[0];
    backgroundColor = ALERT_COLORS.slice(1);
    options = options;
    public doughnutChartType: ChartType = 'doughnut';
    public tooltip: string;
    public labels: string[] = [];
    public datasets: ChartDataset<'doughnut', number[]>[] = [];

    ngOnChanges() {
        this.measure = this.isDemo ? TEXTS.NAMES[AqiType.aqiIn] : TEXTS.NAMES[AQI];
        this.tooltip = TEXTS.ANALYTICS_COMPONENT.tooltip(0, this.measure);
        if (this.isDemo) {
            this.backgroundColor = AQI_IN_COLORS;
        }
        this.labels = !this.isDemo
            ? this.data.map((_, i) => {
                  const index = i + 1;
                  return `${index} ${declOfNum(index, TEXTS.AQI_DECL2)}`;
              })
            : LABELS_AQI_IN;
        const backgroundColor = this.backgroundColor;

        this.datasets = [
            {
                data: this.data,
                backgroundColor,
                hoverBackgroundColor: backgroundColor,
                hoverBorderColor: 'transparent',
            },
        ];
    }
}
