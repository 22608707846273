import { Component } from '@angular/core';

import {
    ClientConfigService,
    ClientConfig,
} from '../../modules/core/services/client-config/client-config.service';
import { detectMobile } from '@libs/common/utils/detect-mobile';
import { ActivatedRoute } from '@angular/router';
import { AIRVOICE_DOMAIN } from '@libs/common/consts/airvoice-domain';

@Component({
    selector: 'ca-client-login-page',
    templateUrl: 'client-login-page.component.html',
    styleUrls: ['client-login-page.component.less'],
})
export class ClientLoginPageComponent {
    clientConfig: ClientConfig;
    isMobile: boolean;
    isAirVoiceDomain = false;
    inviteData: {
        email: string;
        code: string;
    };

    constructor(private route: ActivatedRoute, clientConfigService: ClientConfigService) {
        const email = this.route.snapshot.queryParamMap.get('email');
        const inviteCode = this.route.snapshot.queryParamMap.get('inviteCode');

        if (email && inviteCode) {
            this.inviteData = {
                email,
                code: inviteCode,
            };
        } else {
            this.isAirVoiceDomain = location.host === AIRVOICE_DOMAIN;
        }
        this.clientConfig = clientConfigService.getConfig();
        this.isMobile = detectMobile();
    }
}
