import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import {
    PostCreateRequest,
    Post,
    ITimeseriesDataItem,
    MoItemsDataToExcelRequestProps,
    StationDataToExcelRequestProps,
} from '@libs/common/models/basicModels';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { selectAllCities } from '@cityair/modules/core/store/selectors';

export enum PostActions {
    setAllPosts = '[PostActions] set posts',
    clearAllPosts = '[PostActions] clear all Posts',
    loadingPosts = '[PostActions] loading posts',
    updatePostList = '[PostActions] update post list',
    addPost = '[PostActions] add post',
    addPostSuccess = '[PostActions] add post success',
    addPostError = '[PostActions] add post error',
    updatePost = '[PostActions] update post',
    updatePostSuccess = '[PostActions] update post success',
    updatePostError = '[PostActions] update post error',
    deletePost = '[PostActions] delete post',
    deletePostSuccess = '[PostActions] delete post success',
    deletePostError = '[PostActions] delete post error',
    isLoadingPostForm = '[PostActions] is loading post form',
    closePostForm = '[PostActions] close post form',
    downloadDataPosts = '[PostActions] download data posts',
    downloadDataResult = '[PostActions] download data result',
    downloadDataDevice = '[PostActions] download data device',
}
export const setAllPosts = createAction(PostActions.setAllPosts, props<{ payload: Post[] }>());

export const loadingPosts = createAction(PostActions.loadingPosts, props<{ payload: boolean }>());
export const updatePostList = createAction(PostActions.updatePostList);
export const loadingPostsForm = createAction(
    PostActions.isLoadingPostForm,
    props<{ payload: boolean }>()
);
export const addPost = createAction(
    PostActions.addPost,
    props<{ payload: { params: PostCreateRequest } }>()
);

export const updatePost = createAction(
    PostActions.updatePost,
    props<{ payload: { params: PostCreateRequest; id: string } }>()
);

export const deletePost = createAction(PostActions.deletePost);

export const addPostSuccess = createAction(PostActions.addPostSuccess, props<{ payload: Post }>());
export const updatePostSuccess = createAction(
    PostActions.updatePostSuccess,
    props<{ payload: Post }>()
);
export const deletePostSuccess = createAction(
    PostActions.deletePostSuccess,
    props<{ payload: Post }>()
);
export const addPostError = createAction(
    PostActions.addPostError,
    props<{ payload: HttpErrorResponse }>()
);
export const updatePostError = createAction(
    PostActions.updatePostError,
    props<{ payload: HttpErrorResponse }>()
);
export const deletePostError = createAction(PostActions.deletePostError);
export const closePostForm = createAction(PostActions.closePostForm, props<{ payload: boolean }>());
export const clearAllPosts = createAction(PostActions.clearAllPosts);
export const downloadDataPosts = createAction(
    PostActions.downloadDataPosts,
    props<{ params: MoItemsDataToExcelRequestProps; filename: string }>()
);
export const downloadDataDevice = createAction(
    PostActions.downloadDataDevice,
    props<{ params: StationDataToExcelRequestProps; filename: string }>()
);
export const downloadDataResult = createAction(
    PostActions.downloadDataResult,
    props<{ payload: boolean }>()
);

type PostsState = {
    posts: EntityState<Post>;
    isLoadingList: boolean;
    timeline: EntityState<ITimeseriesDataItem>;
    isLoadingForm: boolean;
    closePostForm: boolean;
    formError: HttpErrorResponse;
    downloadIsSuccess: boolean;
};
export function sortByName(a: Post, b: Post): number {
    return a.name.localeCompare(b.name);
}
export const postAdapter: EntityAdapter<Post> = createEntityAdapter<Post>({
    sortComparer: sortByName,
});

export const postTimeseriesAdapter: EntityAdapter<ITimeseriesDataItem> =
    createEntityAdapter<ITimeseriesDataItem>();

const initialState: PostsState = {
    posts: postAdapter.getInitialState(),
    isLoadingList: true,
    timeline: postTimeseriesAdapter.getInitialState(),
    isLoadingForm: false,
    closePostForm: false,
    formError: null,
    downloadIsSuccess: null,
};
export const postsFeature = createFeature({
    name: 'posts',
    reducer: createReducer(
        initialState,
        on(setAllPosts, (state, { payload }) => {
            const postsResponse = payload as Post[];
            const posts = postAdapter.setMany(postsResponse, state.posts);
            return {
                ...state,
                posts,
                isLoadingList: false,
            };
        }),
        on(clearAllPosts, (state) => {
            const posts = postAdapter.removeAll(state.posts);
            return {
                ...state,
                posts,
            };
        }),
        on(closePostForm, (state, { payload }) => ({
            ...state,
            closePostForm: payload,
        })),
        on(addPostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.addOne(postsResponse, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        }),
        on(addPostError, (state, { payload }) => ({
            ...state,
            formError: payload,
        })),
        on(updatePostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.setOne(postsResponse, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        }),
        on(updatePostError, (state, { payload }) => ({
            ...state,
            formError: payload,
        })),
        on(loadingPosts, (state, { payload }) => ({
            ...state,
            isLoadingList: payload,
        })),
        on(deletePostSuccess, (state, { payload }) => {
            if (payload) {
                const postsResponse = payload as Post;
                const posts = postAdapter.removeOne(postsResponse.id, state.posts);
                return {
                    ...state,
                    posts,
                };
            } else {
                return { ...state };
            }
        }),
        on(downloadDataResult, (state, { payload }) => ({
            ...state,
            downloadIsSuccess: payload,
        }))
    ),
});
const featureSelector = createFeatureSelector<PostsState>('posts');
export const selectAllPosts = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectAll(state.posts)
);
export const selectAllPostsIds = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectIds(state.posts)
);
export const selectAllPostsDic = createSelector(featureSelector, (state) =>
    postAdapter.getSelectors().selectEntities(state.posts)
);
export const selectPostEntitiesById = (id) =>
    createSelector(featureSelector, (state) => state.posts.entities[id] ?? null);
export const isLoadingForm = createSelector(featureSelector, (state) => state.isLoadingForm);
export const selectIsClosePostForm = createSelector(
    featureSelector,
    (state) => state.closePostForm
);
export const selectPostFormError = createSelector(featureSelector, (state) => state.formError);
export const selectIsLoadingPosts = createSelector(featureSelector, (state) => state.isLoadingList);
export const selectDownloadResult = createSelector(
    featureSelector,
    (state) => state.downloadIsSuccess
);
export const selectPostNameById = (id) =>
    createSelector(selectPostEntitiesById(id), (post) => post?.name ?? null);
export const selectPostParentNameById = (id) =>
    createSelector(selectPostEntitiesById(id), (post) => post?.ancestor?.name ?? null);
export const selectPostParentNameIfNeed = (id) =>
    createSelector(selectPostParentNameById(id), selectAllCities, (name, cities) =>
        cities?.length > 1 ? name : null
    );
