import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { BarChartComponent } from '@libs/shared-ui/components/analytic/analytic-charts/bar-chart/bar-chart.component';
import { AnalyticChartTitleModule } from '@libs/shared-ui/components/analytic/analytic-charts/analytic-chart-title/analytic-chart-title.module';

@NgModule({
    imports: [CommonModule, BaseChartDirective, AnalyticChartTitleModule],
    declarations: [BarChartComponent],
    exports: [BarChartComponent],
})
export class BarChartModule {}
