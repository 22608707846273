import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'cs-feedback-success',
    templateUrl: 'feedback-success.component.html',
    styleUrls: ['feedback-success.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackSuccessComponent {
    TEXTS = TEXTS;

    @Output() closeView = new EventEmitter<void>();

    close() {
        this.closeView.emit();
    }
}
