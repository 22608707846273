import { Component, OnDestroy, OnInit } from '@angular/core';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { TabModel } from '@libs/common/types/tab-model';
import { TEXTS } from '@libs/common/texts/texts';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { selectMo } from '@cityair/modules/core/store/actions';
import { selectAllDevices } from '@cityair/modules/core/store/selectors';
import { selectPostEntitiesById } from '@cityair/modules/core/store/posts/posts.feature';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { Device, Post } from '@libs/common/models/basicModels';

export class PATH {
    name: string;
    path: string;
}

@Component({
    selector: 'details-header',
    templateUrl: './details-header.component.html',
    styleUrls: ['./details-header.component.less'],
    animations: ANIMATION_OPACITY,
})
export class DetailsHeaderComponent implements OnInit, OnDestroy {
    pages = NETWORK_PAGES;
    name = '';
    selectedMos: Post[] = [];
    selectedDevice: Device[] = [];

    displayMo: Post;
    displayDevice: Device;

    tabs: TabModel[] = [];
    selectedTab: TabModel = null;
    path = '';
    location = '';
    TEXTS = TEXTS;
    navigationLink: PATH[] = [];
    indexSelectElement = 1;
    idTargetCard = '';

    subscribe1;
    subscribe2;

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public store: Store<any>,
        public popupProvider: OffPanelPopupService
    ) {}

    ngOnInit() {
        const snapshot = this.activatedRoute.snapshot;
        const idRouter = snapshot.params.id;
        this.idTargetCard = idRouter;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        if (isCheckExists) {
            this.getDataForDetails(idRouter);
        } else {
            this.postsAndDevicesFacade.openPage(
                this.pages.networks + '/' + this.activatedRoute.snapshot.url[0].path
            );
        }
    }

    getDataForDetails(routerId) {
        if (this.activatedRoute.snapshot.url[0].path == NETWORK_PAGES.postsList) {
            const id = routerId;
            this.store.dispatch(selectMo({ id }));
            this.subscribe1 = this.store.select(selectPostEntitiesById(id)).subscribe((scm) => {
                if (scm) {
                    this.name = scm.name;
                    this.selectedMos.push(scm);
                    this.displayMo = scm;
                    this.toFormTabs();
                    this.createBreadCrumbs();
                } else {
                    this.postsAndDevicesFacade.openPage(
                        this.pages.networks + '/' + this.activatedRoute.snapshot.url[0].path
                    );
                }
            });
        } else {
            this.subscribe2 = this.store
                .select(selectAllDevices)
                .pipe(filter((smd) => !!smd?.length))
                .subscribe((smd) => {
                    smd.forEach((s) => {
                        if (s.serial_number === this.activatedRoute.snapshot.params.id) {
                            this.name = s.serial_number;
                            this.selectedDevice.push(s);
                            this.displayDevice = s;
                            this.toFormTabs();
                            this.createBreadCrumbs();
                        }
                    });
                });
        }
    }

    toFormTabs() {
        this.selectedMos?.forEach((sm) => {
            this.tabs.push({
                title: this.selectedMos.length == 1 ? this.TEXTS.POSTS_AND_DEVICES.post : sm.name,
                id: sm.id.toString(),
                type: 1,
            });
        });
        this.selectedDevice?.forEach((dsn) => {
            if (dsn) {
                this.tabs.push({
                    title: dsn.serial_number,
                    id: dsn.serial_number,
                    type: 2,
                });
            }
        });
        this.selectedTab = this.tabs?.find((t) => t.id == this.activatedRoute.snapshot.params.id);
        this.tabs?.forEach((t, index) => {
            if (t.id == this.activatedRoute.snapshot.params.id) {
                this.indexSelectElement = index;
            }
        });
    }

    getSelectedDevice() {
        return this.selectedDevice.find((sm) => sm.id.toString() == this.selectedTab.id);
    }

    createBreadCrumbs() {
        this.navigationLink = [
            {
                name:
                    TEXTS.POSTS_AND_DEVICES.postAndDevices +
                    ' | ' +
                    (this.selectedTab.type == 1
                        ? TEXTS.POSTS_AND_DEVICES.posts
                        : TEXTS.POSTS_AND_DEVICES.devices),
                path: NETWORK_PAGES.networks + '/' + this.activatedRoute.snapshot.url[0],
            },
            {
                name: this.name,
                path: null,
            },
        ];
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        if (tab.type == 1) {
            this.displayMo = this.selectedMos.find((sm) => sm.id.toString() == tab.id);
        } else {
            this.displayDevice = this.selectedDevice.find((sd) => sd.serial_number == tab.id);
        }
    }

    openPage(target: any, pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    ngOnDestroy() {
        this.subscribe1?.unsubscribe();
        this.subscribe2?.unsubscribe();
    }
}
