<div class="wrapper" [ngClass]="{ disabled: isDisabled }" (clickOutside)="isShowDropdown = false">
    <input-dropdown
        class="post-report-tza__input-dropdown"
        [inputText]="selectedValue ?? defaultValue"
        [label]="labelTexts.keyParams[key]"
        [isDisable]="isDisabled"
        [(showDropdown)]="isShowDropdown"
    >
    </input-dropdown>
    <div *ngIf="!isDisabled">
        <div class="selectbox-radio" [ngClass]="{ hidden: !isShowDropdown }">
            <search-input
                *ngIf="search"
                [textPlaceholder]="searchPlaceholderText"
                [(text)]="searchText"
                [debounceTime]="400"
            ></search-input>
            <div class="selectbox-radio__list scroll">
                <div
                    class="selectbox-radio__list-item"
                    *ngFor="let item of list | multipleSearchfilter : ['label'] : searchText"
                >
                    <label class="selectbox-radio__list-item__label" (click)="selectItem(item)">
                        <item-radio
                            class="selectbox-radio__list-item__checkmark"
                            [check]="item.selected"
                        ></item-radio>
                        <div
                            class="selectbox-radio__list-item__text"
                            [innerHTML]="item.label"
                        ></div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
