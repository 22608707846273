import { intervalApiType } from '@cityair/namespace';
import { IntervalEnum } from '@libs/common/enums/interval.enum';
import { ContributionsSeries, Geometry, Timeseries } from '@libs/common/models/feature';

export interface TimeSeries {
    obj: string;
    values: {
        [mmt: string]: number[];
    };
    details?: {
        [mmt: string]: number[];
    };
}

export interface GeoPoint {
    type: string;
    coordinates: [number, number];
}

export interface LocationData {
    id: number;
    timeseries: TimeSeries;
}
export interface DataMeasurements {
    measurements: TimeSeries;
    indexes: TimeSeries;
}
export interface Station {
    id: string;
    name: string;
    geometry: Geometry;
    tzOffset: number;
    gmt_offset_seconds: number;
    data: DataMeasurements;
    dataContribution?: StationContributionResponse;
    group_id?: number;
    obj: string;
    ancestor?: Station;
}

export interface StationParams {
    group_id: string;
    date__gt: string;
    date__lt: string;
    packet_type: string;
}

export interface MetaExtraStation {
    dates: string[];
    group_id: number;
}
export interface MetaStationResponse {
    extra: MetaExtraStation;
    status: string;
    type: string;
    error: any;
}
export interface StationResponse {
    data: Station[];
    meta: MetaStationResponse;
}
export interface StationContributionResponse {
    meta: {
        dates: string[];
    };
    response: {
        contributions: ContributionsSeries;
        timeseries: Timeseries;
    };
}
export function getIntervalByRun(duration: number): intervalApiType {
    switch (duration) {
        case 5:
            return 'Interval5M';
            break;
        case 20:
            return 'Interval20M';
            break;
        case 60:
            return 'Interval1H';
            break;
        default:
            return 'Interval20M';
            break;
    }
}
export function getIntervalDataQualityByRun(duration: number): IntervalEnum {
    switch (duration) {
        case 5:
            return IntervalEnum.min5;
            break;
        case 20:
            return IntervalEnum.min20;
            break;
        case 60:
            return IntervalEnum.hour;
            break;
        default:
            return IntervalEnum.min20;
            break;
    }
}
