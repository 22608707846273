import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { InstantAqiLegendMobileComponent } from './instant-aqi-legend-mobile.component';
import { AqiIndicatorSimpleModule } from '../aqi-indicator-simple/aqi-indicator-simple.module';

@NgModule({
    imports: [CommonModule, DirectivesModule, AqiIndicatorSimpleModule],
    exports: [InstantAqiLegendMobileComponent],
    declarations: [InstantAqiLegendMobileComponent],
})
export class InstantAqiLegendMobileModule {}
