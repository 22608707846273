import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    ImpactActions,
    selectRunImpactParams,
    selectRunConfigParams,
    selectActiveRun,
    selectStationParams,
    selectParamsControlPointsByRun,
    selectControlPointsForMap,
    selectSourcesImpact,
    selectSourcesOrder,
    selectParamsForLocality,
    selectActivePin,
    selectIsActiveImpact,
    selectImpactStationsDic,
    selectParamsStationContribution,
    selectActiveStation,
    impactFeature,
    selectControlPoints,
    selectActiveHeight,
    selectImpactHeights,
    selectShowWindOnMap,
    selectEvents,
    selectRunsImpact,
    selectActiveMmt,
    selectParamsForEvent,
    selectParamsForEvents,
    selectRegions,
} from '@cityair/modules/impact/store/impact.feature';
import { ImpactApiService } from '@cityair/modules/impact/service/impact-api.service';
import {
    addAlert,
    CommonActions,
    doNothing,
    refreshVangaToken,
} from '@cityair/modules/core/store/actions';
import { forkJoin, of } from 'rxjs';
import { HttpErrorResponse, HttpParamsOptions, HttpStatusCode } from '@angular/common/http';
import { getSourcesByRuns } from '@cityair/modules/impact/utils';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { Dictionary } from '@ngrx/entity';
import { ContributionDetails, Feature, Properties } from '@libs/common/models/feature';
import {
    CityImpactData,
    EventItem,
    NetworkDensityData,
    SourceImpact,
} from '@cityair/modules/impact/service/api-model-impact';
import { onIsEnabledChart } from '@libs/shared-ui/components/timeline-panel/store/core.actions';

import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import { setCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import {
    Station,
    StationContributionResponse,
} from '@cityair/modules/plumes/services/station/models';
import { Source } from '@cityair/modules/plumes/services/run/models';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { INIT_IMPACT_EVENT_DATE, REGION_CONTROL_POINTS_IDS, REGISTER_EVENTS, SOURCES } from '../consts';
import { CorrelationData, TimeSeriesData } from '@libs/common/models/basicModels';
import { hideHtmlLoader } from '@cityair/utils/utils';
import { selectAllPostsDic } from '@cityair/modules/core/store/posts/posts.feature';
import { Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';

function transformControlPoint(
    point: ControlPoint,
    sources: Dictionary<SourceImpact>,
    order: number[]
): Feature[] {
    if (!point) {
        return [];
    }
    const properties: Properties = {
        uuid: point.id,
        name: point.name,
        name_ru: point.name,
        timeseries: point.timeline,
        obj: point.obj,
        has_any_timeseries: !!point?.timeline,
        contributions: point?.contributions,
        contributionsDetails: {
            sources,
            order,
        },
    };

    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [point?.lon, point?.lat],
            },
            properties,
        },
    ];
}
function transformEventsContributions(
    events: EventItem[],
    stations: Dictionary<Station>,
    data: StationContributionResponse[],
    sources: Dictionary<Source>,
    order: number[]
): { [key: string]: Feature[] } {
    const result = {};
    events?.forEach((event, index) => {
        const station = stations[event.post_id];
        const chartData = transformStationContributions(station, data[index], sources, order);
        result[event.post_id] = chartData;
    });
    return result;
}
function transformStationContributions(
    station: Station,
    data: StationContributionResponse,
    sources: Dictionary<Source>,
    order: number[]
): Feature[] {
    const result: Feature[] = [];
    const details: ContributionDetails = {
        sources,
        order,
    };
    if (station) {
        const hasTimeseries =
            !!Object.keys(station.data?.measurements).length ||
            !!Object.keys(station.data?.indexes).length;
        const timeseriesData = {
            date: data?.meta?.dates,
        };

        if (hasTimeseries) {
            Object.entries(station.data?.measurements).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
            });
            Object.entries(station.data?.indexes).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
            });
        }
        const timelineContributions = data?.response.timeseries;
        result.push({
            type: 'Feature',
            geometry: station.geometry,
            properties: {
                uuid: station.id,
                city_id: `${station.ancestor?.id}`,
                name: station.name,
                name_ru: station.name,
                timeseries: timeseriesData,
                contributions: { ...data?.response?.contributions, date: data?.meta?.dates },
                contributionsDetails: details,
                timelineContributions,
                obj: 'station',
                has_any_timeseries: true,
                ancestors: [
                    {
                        name: station.ancestor?.name,
                        obj: station.ancestor?.obj,
                        uuid: `${station.ancestor?.id}`,
                    },
                ],
            },
        });
    }
    return result;
}
@Injectable()
export class ImpactEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private impactService: ImpactApiService,
        private basicApi: BasicApi,
        private router: Router
    ) {}

    loadRegionGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, selectGroupId]) => !!selectGroupId),
            switchMap(([_, groupId]) =>
                this.basicApi.getAllRegion(groupId).pipe(
                    switchMap((res: any) => {
                        const regions = Object.assign(res?.data);
                        const regionControlPointsIds = REGION_CONTROL_POINTS_IDS;
                        regions.map((region) => {
                            const filterRegion = regionControlPointsIds.filter((v) => v.regionId === region.id);
                            filterRegion.length
                                ? region.control_point_ids = filterRegion[0].controlPointsIds
                                : region.control_point_ids = [];
                        });
                        return [ImpactActions.setRegionsApi({ payload: regions })]
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    getRuns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectRunImpactParams)),
            filter(([_, params]) => params !== null),
            tap(() => this.store.dispatch(ImpactActions.setLoading({ payload: true }))),
            switchMap(([action, runParams]) =>
                this.impactService.getRuns(runParams).pipe(
                    switchMap((response) => {
                        const sources = getSourcesByRuns(response);
                        return [
                            ImpactActions.setLoading({ payload: false }),
                            ImpactActions.setRuns({ payload: response }),
                            ImpactActions.setSources({ payload: sources }),
                            ImpactActions.setSourcesListDemo({ payload: SOURCES }),
                            ImpactActions.updateDateRangeEvents({
                                payload: INIT_IMPACT_EVENT_DATE,
                            }),
                        ];
                    }),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(
                                ImpactActions.setRunLoadError({ payload: true }),
                                ImpactActions.setLoading({ payload: false })
                            );
                        }
                    })
                )
            )
        )
    );

    getEvents = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.updateDateRangeEvents),
            withLatestFrom(
                this.store.select(selectRunsImpact),
                this.store.select(selectParamsForEvents)
            ),
            filter(([_, runs, params]) => params !== null),
            tap(() => this.store.dispatch(ImpactActions.setIsLoadingEvents({ payload: true }))),
            switchMap(([action, runs, params]) =>
                this.basicApi.getGroupEvents(params).pipe(
                    switchMap((response) => {
                        // const events = prepareImpactEvents(response?.data, runs);
                        return [
                            ImpactActions.setIsLoadingEvents({ payload: false }),
                            ImpactActions.setEvents({ payload: REGISTER_EVENTS }),
                        ];
                    }),
                    catchError((errorResponse) =>
                        of(ImpactActions.setIsLoadingEvents({ payload: false }))
                    )
                )
            )
        )
    );

    getRunConfigs = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectRunConfigParams)),
            filter(([_, params]) => params !== null),
            switchMap(([action, runParams]) =>
                this.impactService.getConfig(runParams).pipe(
                    switchMap((response) => [ImpactActions.setRunConfigs({ payload: response })]),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(doNothing());
                        }
                    })
                )
            )
        )
    );

    getDataForLocality = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentCity),
            withLatestFrom(this.store.select(selectParamsForLocality)),
            filter(([_, params]) => params !== null),
            switchMap(([action, { cityId, params }]) =>
                this.basicApi.getCityDataTimeline(cityId, params).pipe(
                    switchMap((response) => {
                        const result = response?.data?.[0]?.data as TimeSeriesData;
                        return [ImpactActions.setCityData({ payload: result })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(doNothing());
                    })
                )
            )
        )
    );

    getNetworkDensityDataEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(
                this.store.select(selectGroupId),
                this.store.select(impactFeature.selectNetworkDensity)
            ),
            filter(([action, groupId, data]) => !!groupId && data === null),
            switchMap(([action, groupId]) =>
                this.basicApi.getNetworkAnalysis(groupId).pipe(
                    switchMap((response) => {
                        const data = response?.data as NetworkDensityData;
                        return [ImpactActions.setNetworkDensity({ payload: data })];
                        return [ImpactActions.setNetworkDensity({ payload: data })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(doNothing());
                    })
                )
            )
        )
    );

    getCorrelationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, groupId]) => !!groupId),
            switchMap(([action, groupId]) =>
                this.basicApi.getAnalysisData(groupId).pipe(
                    switchMap((response) => {
                        const data = response?.data as CorrelationData;
                        return [ImpactActions.setCorrelationData({ payload: data })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => of(doNothing()))
                )
            )
        )
    );

    changeActiveRunByEvent = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveEvent),
            withLatestFrom(
                this.store.select(selectEvents),
                this.store.select(selectRunsImpact),
                this.store.select(selectActiveMmt)
            ),
            switchMap(([action, events, runs, activeMmt]) => {
                if (action?.payload === null) {
                    return runs[0] ? [ImpactActions.setActiveRun({ payload: runs[0] })] : [];
                } else {
                    const currentEvent = events.find((v) => v.id === action.payload);
                    const actions = [];
                    const currentRun = currentEvent?.run_id
                        ? runs.find((v) => v.id === currentEvent?.run_id)
                        : null;
                    if (currentRun) {
                        actions.push(ImpactActions.setActiveRun({ payload: currentRun }));
                        if (activeMmt !== currentEvent.param) {
                            actions.push(
                                actions.push(
                                    ImpactActions.setActiveMmt({ payload: currentEvent.param })
                                )
                            );
                        }
                        return actions;
                    }
                    return [];
                }
            })
        )
    );

    getControlPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactActions.initModule,
                ImpactActions.addControlPointSuccess,
                ImpactActions.deleteControlPointSuccess
            ),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, params]) => params !== null),
            switchMap(([action, groupId]) =>
                this.impactService.getControlPoints(groupId).pipe(
                    switchMap((response) => {
                        return [ImpactActions.setControlPoints({ payload: response }),
                    ]}),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(doNothing());
                        }
                    })
                )
            )
        )
    );

    getControlPointsTimeLineByRun = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactActions.setActiveRun,
                ImpactActions.setActiveMmt,
                ImpactActions.setActiveHeight,
                ImpactActions.addControlPointSuccess,
                ImpactActions.deleteControlPointSuccess
            ),
            withLatestFrom(this.store.select(selectParamsControlPointsByRun)),
            filter(([action, params]) => params !== null),
            tap(() =>
                this.store.dispatch(ImpactActions.setControlPointsIsLoading({ payload: true }))
            ),
            switchMap(([action, params]) =>
                this.impactService.getControlPointsTimeline(params).pipe(
                    switchMap((response) => [
                        ImpactActions.setSourcesOrder({ payload: response?.meta?.sources }),
                        ImpactActions.setDates({ payload: response?.meta.dates }),
                        ImpactActions.setControlPointsIsLoading({ payload: false }),
                        ImpactActions.setControlPointsTimeline({
                            payload: response?.control_points_measurements,
                        }),
                    ]),
                    catchError((error: HttpErrorResponse) => {
                        const actions = [];
                        /* if (this.checkErrorStatus(error?.status)) {
                            const errorAction = this.actionError(error?.status);
                            actions.push(errorAction);
                        }

                        actions.push(setRunLoading({ payload: false })); */
                        actions.push(ImpactActions.setControlPointsIsLoading({ payload: false }));
                        return actions;
                    })
                )
            )
        )
    );

    getStationsTimeLineByRun = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactActions.setActiveRun,
                ImpactActions.setActiveMmt,
                ImpactActions.setActiveHeight
            ),
            withLatestFrom(this.store.select(selectStationParams)),
            filter(([action, params]) => params !== null),
            switchMap(([action, { groupId, params }]) =>
                this.basicApi.getDataTimeline(groupId, params).pipe(
                    switchMap((response) => {
                        return [ImpactActions.setStationsData({ payload: response?.data })];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const actions = [];

                        return actions;
                    })
                )
            )
        )
    );

    updateConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.updateRunConfig),
            tap(() => this.store.dispatch(ImpactActions.setLoadingEditRun({ payload: true }))),
            switchMap((action) =>
                this.impactService.updateConfig(action?.payload).pipe(
                    switchMap((response) => {
                        return [
                            ImpactActions.setRunConfig({ payload: response }),
                            ImpactActions.setLoadingEditRun({ payload: false }),
                            ImpactActions.setSuccessEditRun({ payload: true }),
                        ];
                    }),
                    catchError((errorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Unauthorized) {
                            return of(refreshVangaToken());
                        } else {
                            return of(ImpactActions.setErrorEditRun({ payload: errorResponse }));
                        }
                    })
                )
            )
        )
    );

    setActiveRunByRuns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setRuns),
            withLatestFrom(this.store.select(selectActiveRun)),
            switchMap(([action, activeRun]) => {
                const result = action.payload;
                let index = 0;
                if (result && result.length) {
                    if (activeRun) {
                        const indexOld = result.findIndex((item) => item.id === activeRun.id);
                        if (indexOld !== -1) {
                            index = indexOld;
                        }
                    }
                    return [ImpactActions.setActiveRun({ payload: result[index] })];
                }
                return [];
            })
        )
    );

    setChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActivePin),
            withLatestFrom(
                this.store.select(selectControlPointsForMap),
                this.store.select(selectSourcesImpact),
                this.store.select(selectSourcesOrder)
            ),
            switchMap(([action, points, sources, order]) => {
                const actions = [];
                const id = action?.payload?.toString();
                if (id) {
                    const point = points.find((v) => v.id === id);
                    if (point) {
                        const chartData = transformControlPoint(point, sources, order);
                        actions.push(ImpactActions.setChartData({ payload: chartData }));
                    }
                }
                return actions;
            })
        )
    );

    setActiveStationById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveStationId),
            withLatestFrom(this.store.select(selectImpactStationsDic)),
            switchMap(([action, stations]) => [
                ImpactActions.setActiveStation({ payload: stations[action?.payload] }),
            ])
        )
    );

    updateActiveControlPoints$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setControlPointsTimeline),
            withLatestFrom(
                this.store.select(selectActivePin),
                this.store.select(selectControlPoints)
            ),
            filter(([action, active, points]) => active !== null),
            switchMap(([action, active, points]) => {
                const point = points.find((v) => v.id === Number(active));
                if (point) {
                    return [ImpactActions.setActivePin({ payload: point.id })];
                } else {
                    return null;
                }
            })
        )
    );

    updateActiveStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setStationsData),
            withLatestFrom(
                this.store.select(selectActiveStation),
                this.store.select(selectImpactStationsDic)
            ),
            filter(([action, active]) => !!active?.id),
            switchMap(([action, active, stations]) => [
                ImpactActions.setActiveStation({ payload: stations[active.id] }),
            ])
        )
    );

    loadContributionDataForActiveStation = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveStation),
            withLatestFrom(
                this.store.select(selectParamsStationContribution),
                this.store.select(selectSourcesImpact),
                this.store.select(selectSourcesOrder)
            ),
            filter(([action, data]) => data !== null),
            // tap(() => this.plumesCore$.dispatch(setChartLoading({ payload: true }))),
            tap(() =>
                this.store.dispatch(
                    ImpactActions.setLoadingContributionStationData({ payload: true })
                )
            ),
            tap(() =>
                this.store.dispatch(
                    ImpactActions.setContributionDataToActiveStation({ payload: null })
                )
            ),
            /* tap(([action, data]) =>
                this.mapboxActions.centringOnMarker(
                    Number(data.params.lat),
                    Number(data.params.lon),
                    false
                )
            ), */
            switchMap(([action, params, sources, order]) =>
                this.impactService.getStationContributionData(params).pipe(
                    switchMap((response) => {
                        const actions = [];
                        const result = transformStationContributions(
                            action?.payload,
                            response,
                            sources,
                            order
                        );
                        if (params?.active) {
                            actions.push(ImpactActions.setChartData({ payload: result }));
                        }
                        actions.push(
                            ImpactActions.setLoadingContributionStationData({ payload: false })
                        );
                        // actions.push(setChartLoading({ payload: false }));
                        actions.push(
                            ImpactActions.setContributionDataToActiveStation({ payload: response })
                        );
                        return actions;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(doNothing());
                    })
                )
            )
        )
    );

    disableWindByHeight$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveHeight),
            withLatestFrom(
                this.store.select(selectActiveHeight),
                this.store.select(selectImpactHeights),
                this.store.select(selectShowWindOnMap),
                this.store.select(selectActiveRun)
            ),
            switchMap(([action, height, heights, isWindShowOnMap, activeRun]) => {
                const actions = [];
                if (activeRun?.wind_on && heights.length) {
                    if (height !== heights[0]) {
                        actions.push(ImpactActions.disableWindButton({ payload: true }));
                        if (isWindShowOnMap) {
                            actions.push(ImpactActions.toggleWindLayer({ payload: false }));
                        }
                    } else {
                        actions.push(ImpactActions.disableWindButton({ payload: false }));
                    }
                }
                return actions;
            })
        )
    );

    enabledChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setChartData),
            map((props) => onIsEnabledChart({ payload: props.payload.length > 0 }))
        )
    );

    clearChartDataCloseChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            withLatestFrom(
                this.store.select(selectActivePin),
                this.store.select(selectIsActiveImpact)
            ),
            filter(([_, activePin, data]) => data && activePin !== null),
            switchMap(([_, activePin, data]) =>
                 [
                      ImpactActions.setActivePin({ payload: null }),
                      ImpactActions.setChartData({ payload: [] }),
                 ]
            )
        )
    );

    clearChartDataActiveStationCloseChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            withLatestFrom(
                this.store.select(selectActiveStation),
                this.store.select(selectIsActiveImpact)
            ),
            filter(([_, activePin, data]) => data && activePin !== null),
            switchMap(([_, activePin, data]) => [
                ImpactActions.setActiveStation({ payload: null }),
                ImpactActions.setChartData({ payload: [] }),
            ])
        )
    );

    unActiveControlPointByActiveStation = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActivePin),
            filter(({ payload }) => payload !== null),
            withLatestFrom(
                this.store.select(selectActiveStation),
                this.store.select(selectIsActiveImpact)
            ),
            filter(([_, activeStation, data]) => data && activeStation !== null),
            map((props) => ImpactActions.setActiveStation({ payload: null }))
        )
    );

    unActiveStationByActiveControlPoint = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveStation),
            filter(({ payload }) => payload !== null),
            withLatestFrom(
                this.store.select(selectActivePin),
                this.store.select(selectIsActiveImpact)
            ),
            filter(([_, activePin, data]) => data && activePin !== null),
            map((props) => ImpactActions.setActivePin({ payload: null }))
        )
    );

    setActiveRunLoadStation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.setActiveRun, ImpactActions.setActiveMmt),
            withLatestFrom(this.store.select(selectStationParams)),
            filter(([_, params]) => !!params),
            switchMap(([action, params]) => {
                return [];
            })
        )
    );

    reloadDataOnTokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CommonActions.VangaTokenUpdated),
            withLatestFrom(this.store.select(selectIsActiveImpact)),
            filter(([action, isActive]) => isActive),
            take(1),
            map((action) => ImpactActions.initModule())
        )
    );

    addControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.addControlPoint),
            tap(() =>
                this.store.dispatch(ImpactActions.setIsLoadingControlPointForm({ payload: true }))
            ),
            switchMap((action) =>
                this.impactService.addControlPoint(action?.payload).pipe(
                    switchMap((response) => {
                        this.router.navigate([
                            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}`,
                        ]);
                        return [
                            ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                            ImpactActions.addControlPointSuccess({ payload: response }),
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'Create_Success',
                                positionX: 'right',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 3000,
                                showCloseIcon: true,
                                size: 'lg',
                            }),
                        ];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                            ImpactActions.setControlPointFormError({ payload: error })
                        );
                    })
                )
            )
        )
    );

    updateControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.updateControlPoint),
            tap(() =>
                this.store.dispatch(ImpactActions.setIsLoadingControlPointForm({ payload: true }))
            ),
            switchMap((action) =>
                this.impactService.updateControlPoint(action?.payload).pipe(
                    switchMap((response) => {
                        this.router.navigate([
                            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}`,
                        ]);
                        return [
                            ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                            ImpactActions.addControlPointSuccess({ payload: response }),
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'Update_Success',
                                positionX: 'right',
                                positionY: 'bottom',
                                iconClass: 'success',
                                duration: 3000,
                                showCloseIcon: true,
                                size: 'lg',
                            }),
                        ];
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                            ImpactActions.setControlPointFormError({ payload: error })
                        );
                    })
                )
            )
        )
    );

    deleteControlPoint$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.deleteControlPoint),
            tap(() =>
                this.store.dispatch(ImpactActions.setIsLoadingControlPointForm({ payload: true }))
            ),
            switchMap((action) =>
                this.impactService.deleteControlPoint(action?.payload).pipe(
                    switchMap((response) => [
                        ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                        ImpactActions.deleteControlPointSuccess({ payload: response }),
                        addAlert({
                            id: new Date().valueOf(),
                            messageKey: 'Delete_Success',
                            positionX: 'right',
                            positionY: 'bottom',
                            iconClass: 'success',
                            duration: 3000,
                            showCloseIcon: true,
                            size: 'lg',
                        }),
                    ]),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ImpactActions.setIsLoadingControlPointForm({ payload: false }),
                            ImpactActions.setControlPointFormError({ payload: error }),
                            addAlert({
                                id: new Date().valueOf(),
                                messageKey: 'Delete_Error',
                                positionX: 'right',
                                positionY: 'bottom',
                                iconClass: 'error',
                                duration: 3000,
                                showCloseIcon: true,
                                size: 'lg',
                            })
                        );
                    })
                )
            )
        )
    );

    getDataByCurrentEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImpactActions.getEventDetailsData),
            withLatestFrom(
                this.store.select(selectParamsForEvent),
                this.store.select(selectSourcesImpact),
                this.store.select(selectSourcesOrder),
                this.store.select(selectAllPostsDic),
                this.store.select(selectImpactStationsDic)
            ),
            filter(([action, data]) => action?.payload !== null && data !== null),
            switchMap(([action, { id, params }, sources, order, posts, stations]) => {
                const query = [];
                action?.payload?.events?.forEach((event) => {
                    const currentPost = posts[event.post_id];
                    if (currentPost) {
                        const eventParams: HttpParamsOptions = {
                            fromObject: {
                                lon: currentPost?.geometry?.coordinates[0]?.toString(),
                                lat: currentPost?.geometry?.coordinates[1]?.toString(),
                                h: params.h,
                                species_list: params.species_list,
                                measure_scheme: params.measure_scheme,
                            },
                        };
                        const currentParams = { id: id, params: eventParams };
                        query.push(this.impactService.getStationContributionData(currentParams));
                    }
                });
                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const actions = [];
                        const data = transformEventsContributions(
                            action?.payload?.events,
                            stations,
                            res,
                            sources,
                            order
                        );
                        actions.push(ImpactActions.setEventDetailsData({ payload: data }));
                        return actions;
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        hideHtmlLoader();
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                );
            })
        )
    );

    getDataForRegions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ImpactActions.setActiveRun,
                ImpactActions.setActiveMmt,
            ),
            withLatestFrom(
                this.store.select(selectRegions),
                this.store.select(impactFeature.selectParamsForRegion),
                this.store.select(impactFeature.selectActiveMmt),
            ),
            tap(() =>
                this.store.dispatch(ImpactActions.setRegionsApiIsLoading({ payload: true }))
            ),
            switchMap(([action, regions, { id, params }, activeMmt]) => {
                const query = [];
                regions.map((region) => {
                    const regionParams: HttpParamsOptions = {
                        fromObject: {
                            lon: region?.centroid?.[1]?.toString(),
                            lat: region?.centroid?.[0]?.toString(),
                            h: params.h,
                            species_list: params.species_list,
                            measure_scheme: params.measure_scheme,
                        },
                    };
                    const currentParams = { id: id, params: regionParams };
                    query.push(this.impactService.getStationContributionData(currentParams));
                });
                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const regionsCopy = [];
                        regions.map((region, index) => {
                            const regionCopy = {timeseries: []}
                            Object.entries(region).forEach(([key, value]) => {
                                regionCopy[key] = value;
                            });
                            regionCopy.timeseries = res[index]?.response?.timeseries[activeMmt];
                            regionsCopy.push(regionCopy);
                        });
                        return [
                            ImpactActions.setRegionsApi({ payload: regionsCopy }),
                            ImpactActions.setRegionsApiIsLoading({ payload: false }),
                        ];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        hideHtmlLoader();
                        const errorAction = getActionBasicError(errorResponse);
                        return of(
                            errorAction,
                            ImpactActions.setRegionsApiIsLoading({ payload: false }),
                        )
                    })
                );
            })
        )
    )
}
