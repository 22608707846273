import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';
import { getIntervalText } from './get-interval-text.function';
import { CalendarOutput, formatDayMonthYear } from '@libs/common/utils/date-formats';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    transform(time: CalendarOutput): string {
        return getIntervalText(time, (time) => formatDayMonthYear(moment(time)));
    }
}
