import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectIsLoadingPosts } from '@cityair/modules/core/store/posts/posts.feature';
import {
    selectRadarChartDataById,
    selectWindDataByPost,
} from '@cityair/modules/analysis/store/selectors';
import { TEXTS } from '@libs/common/texts/texts';
import { setActiveCorrelationPost } from '@cityair/modules/analysis/store/actions';

@Component({
    selector: 'cityair-correlation-details',
    templateUrl: './correlation-details.component.html',
    styleUrls: ['./correlation-details.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrelationDetailsComponent implements OnDestroy {
    text = TEXTS.ANALYSIS.correlation;
    public textNames = TEXTS.NAMES;
    currentId: string;
    loading = true;
    public ngDestroyed$ = new Subject<void>();
    selectRadarChartDataById = selectRadarChartDataById;
    selectWindDataByPost = selectWindDataByPost;
    chartData = { datasets: [], labels: [] };
    windData;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.route.params.subscribe((params) => {
            this.currentId = params?.id;
            _changeDetectorRef.markForCheck();
        });
        this.store
            .select(selectIsLoadingPosts)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((isLoading) => {
                this.loading = isLoading;
                _changeDetectorRef.markForCheck();
            });
    }

    public back() {
        this.store.dispatch(setActiveCorrelationPost({ postId: null }));
        this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}`]);
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }
}
