import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { BasicDateRangeComponent } from '@libs/shared-ui/components/basic-date-range/basic-date-range.component';

@NgModule({
    imports: [CommonModule, PipesModule],
    exports: [BasicDateRangeComponent],
    declarations: [BasicDateRangeComponent],
})
export class BasicDateRangeModule {}
