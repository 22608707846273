import { Pipe, PipeTransform } from '@angular/core';
import { TimeTrackView } from '@libs/shared-ui/components/timeline-panel/models/core';
import {
    TIMELINE_HAS_DATA_TRACK_COLOR,
    TIMELINE_TRACK_QUALITY_DATA_COLOR,
} from '@libs/shared-ui/components/timeline-panel/constants';

@Pipe({
    name: 'timelineBlockBackgroundColor',
})
export class TimelineBlockBackgroundColorPipe implements PipeTransform {
    transform(i: number, timeline: TimeTrackView): string {
        return this.getBackgroundColor(timeline.hasData, timeline.hasQualityData);
    }

    private getBackgroundColor(hasData: boolean, hasQualityData: boolean): string {
        const transparent = 'transparent';

        const defaultColor = TIMELINE_HAS_DATA_TRACK_COLOR;
        const lightRedOpacity = TIMELINE_TRACK_QUALITY_DATA_COLOR;

        if (hasQualityData) {
            return lightRedOpacity;
        }

        if (hasData) {
            return defaultColor;
        }

        return transparent;
    }
}
