import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CityPinComponent } from './city-pin.component';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';

@NgModule({
    exports: [CityPinComponent],
    declarations: [CityPinComponent],
    imports: [CommonModule, ValueCircleModule],
})
export class CityPinModule {}
