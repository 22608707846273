import { NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';
import { ReportKindDataService } from '@cityair/modules/reports/services/report-kind-data.service';
import { ReportDataService } from './services/report-data.service';

@NgModule({
    providers: [ReportDataService],
})
export class EntityStoreModule {
    constructor(
        entityDataService: EntityDataService,
        reportDataService: ReportDataService,
        reportKindDataService: ReportKindDataService
    ) {
        entityDataService.registerService('Report', reportDataService);
        entityDataService.registerService('ReportKind', reportKindDataService);
    }
}
