import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import type { CheckboxItem } from '@libs/common/types/checkbox-item';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { selectMeasureScheme, selectUserTimezone } from '@cityair/modules/core/store/selectors';
import { environment } from 'environments/environment';
import { TEMP } from '@libs/common/consts/substance.consts';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { currentSavedLang, setSavedLang } from '@libs/common/utils/local-storage';
import { UserSettings } from '@libs/common/models/basicModels';
import { updateUserSettings } from '@cityair/modules/core/store/actions';
import { getTZList } from '@libs/common/utils/date-formats';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
export const DEFAULT_TIMEZONE = 'default';

@Component({
    selector: 'cityair-system-settings',
    templateUrl: './system-settings.component.html',
    styleUrls: ['./system-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemSettingsComponent implements OnDestroy {
    isAWS = environment.isAWS;
    TEMP = TEMP;
    MeasureScheme = MeasureScheme;
    selectMeasureScheme = selectMeasureScheme;
    selectUserTimezone = selectUserTimezone;
    isShowDropdown = false;
    searchText = '';
    TEXTS = TEXTS;
    textsSettings = TEXTS.CONFIG;
    _lang = currentSavedLang ? currentSavedLang : LANGUAGE;
    lang: string = this._lang;
    public ngDestroyed$ = new Subject<void>();
    scheme: MeasureScheme = null;
    currentTimezone;
    tzList: { name: string; offset: number }[];
    isAutoTimeZone = true;
    userTimezone: string;
    public list: CheckboxItem[] = [];
    public selectedTimezone: CheckboxItem;
    @ViewChild('timezoneList') timezoneList: ElementRef<HTMLElement>;

    constructor(public store: Store, private ref: ChangeDetectorRef) {
        store
            .select(selectMeasureScheme)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((scheme) => (this.scheme = scheme));
        store
            .select(selectUserTimezone)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => !!v)
            )
            .subscribe((timezone) => {
                this.userTimezone = timezone;
                this.setTimeZone(timezone);
            });
    }

    toggleTzDropDown() {
        if (this.isShowDropdown) {
            this.correctScroll();
        }
    }

    tabs = [
        {
            title: TEXTS.mgM,
            type: 1,
            scheme: MeasureScheme.c_mmhg_mg,
        },
        {
            title: TEXTS.ugM,
            type: 2,
            scheme: MeasureScheme.default,
        },
        {
            title: TEXTS.shareOfMPC,
            type: 3,
            scheme: MeasureScheme.mpc,
        },
    ];

    tabsAWS = [
        {
            title: TEXTS.mgM,
            type: 1,
            scheme: MeasureScheme.c_mmhg_mg,
        },
        {
            title: TEXTS.ugM,
            type: 2,
            scheme: MeasureScheme.default,
        },
    ];

    findSelected = () => this.tabs.find((t) => t.scheme === this.scheme);

    findSelectedAWS = () => {
        let _scheme = this.scheme;

        if (_scheme === MeasureScheme.usa_default) _scheme = MeasureScheme.default;

        return this.tabsAWS.find((t) => t.scheme === _scheme);
    };

    onSelectScheme(tab) {
        this.scheme = tab.scheme;
    }

    selectFahrenheit = () => {
        if (this.scheme === MeasureScheme.usa_default) return (this.scheme = MeasureScheme.default);

        if (this.scheme === MeasureScheme.default) return (this.scheme = MeasureScheme.usa_default);
    };

    // ---------------------------

    labelLanguage = TEXTS.CONFIG.language;
    inputTextLanguage = this.lang === 'ru' ? TEXTS.LOGIN_WINDOWS.ru : TEXTS.LOGIN_WINDOWS.en;
    LanguageItems: CheckboxItem[] = [
        {
            id: 0,
            label: TEXTS.LOGIN_WINDOWS.ru,
            selected: this.lang === 'ru',
            icon: 'ru',
        },
        {
            id: 1,
            label: TEXTS.LOGIN_WINDOWS.en,
            selected: this.lang === 'en',
            icon: 'en',
        },
    ];
    showDropdownLanguage = false;

    updateLanguage(listItems: CheckboxItem[]) {
        const _lang = listItems.find((item) => item.selected).label;
        this.inputTextLanguage = _lang;
        if (_lang === TEXTS.LOGIN_WINDOWS.ru) this.lang = 'ru';

        if (_lang === TEXTS.LOGIN_WINDOWS.en) this.lang = 'en';

        this.showDropdownLanguage = false;
    }

    toggleAutoTimezone() {
        this.isAutoTimeZone = !this.isAutoTimeZone;
        if (this.isAutoTimeZone) {
            this.setTimeZone(DEFAULT_TIMEZONE);
        } else {
            this.setTimeZone(this.selectedTimezone.id as string);
        }
    }

    selectTZ(item) {
        this.selectedTimezone = item;
        this.isShowDropdown = false;
        this.setTimeZone(item.id);
    }

    isChangeTimezone(): boolean {
        if (this.userTimezone === DEFAULT_TIMEZONE) {
            return this.selectedTimezone?.id === moment.tz.guess();
        } else {
            return this.selectedTimezone?.id === this.userTimezone;
        }
    }

    saveSystemSettings = async () => {
        if (currentSavedLang !== this.lang) {
            setSavedLang(this.lang);
        }
        const settings: UserSettings = {
            language: this.lang,
            measure_scheme: this.scheme === MeasureScheme.mpc ? MeasureScheme.default : this.scheme,
            use_pdk_shares: this.scheme === MeasureScheme.mpc,
            timezone: this.isAutoTimeZone ? DEFAULT_TIMEZONE : this.selectedTimezone.id.toString(),
        };
        this.store.dispatch(updateUserSettings({ payload: settings }));
    };

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    private setTimeZone(timezone: string): void {
        this.isAutoTimeZone = timezone === DEFAULT_TIMEZONE || timezone === null;
        if (this.isAutoTimeZone) {
            this.currentTimezone = moment.tz.guess();
        } else {
            this.currentTimezone = timezone;
        }
        this.tzList = getTZList();
        this.list = this.getList();
        this.selectedTimezone = this.list?.find((v) => v.selected);
        this.ref.markForCheck();
    }

    private getList() {
        if (!this.tzList) {
            return;
        }
        const result = [];
        this.tzList.forEach((item, index) => {
            result.push({
                id: item.name,
                label: this.formatLabel(item.name),
                selected: item.name === this.currentTimezone,
                value: item.name,
            });
        });

        return result;
    }

    private formatLabel(item: string) {
        // const name = item.replace('/', ' ');
        return `(GMT ${moment.tz(item).format('Z')}) ${item}`;
    }

    private correctScroll() {
        const currentEl = document.querySelector('.selected') as HTMLElement;
        const rect = this.timezoneList?.nativeElement.getBoundingClientRect();
        const currentRect = currentEl?.getBoundingClientRect();

        if (currentEl && rect && currentRect && currentRect.top >= rect.top + rect.height) {
            currentEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }
}
