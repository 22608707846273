import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { CalendarMonthsModule } from '@cityair/libs/common/calendar/calendar-months/calendar-months.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { CardModule } from '@cityair/components/card/card.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { SwitchButtonModule } from '@cityair/components/switch-button/switch-button.module';
import { DownloadBtnModule } from '@cityair/libs/shared/components/download-btn/download-btn.module';
import { AqiDetailsTooltipModule } from '@libs/shared-ui/components/aqi-details-tooltip/aqi-details-tooltip.module';
import { TimelinePanelModule } from '@libs/shared-ui/components/timeline-panel/timeline-panel.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { HeightsSelectorModule } from '@libs/shared-ui/components/heights-selector/heights-selector.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';
import { ImpactMainComponent } from '@cityair/modules/impact/components/impact-main.component/impact-main.component';
import { ControlPointsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/control-points.component';
import { ImpactRunsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-runs.component';
import { ImpactRunResultsComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-results/impact-run-results.component';
import { ImpactMapElementComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map-element/impact-map-element.component';
import { ImpactMapComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map.component';
import { ImpactRunTimePipe } from '@cityair/modules/impact/pipes/run-time.pipe';
import { FinishRunTimePipe } from '@cityair/modules/impact/pipes/finish-run-time.pipe';
import { ConfigRunCardComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/config-run-card/config-run-card.component';
import { DurationsRunPipe } from '@cityair/modules/impact/pipes/config-duration.pipe';
import { ConfigPeriodTimePipe } from '@cityair/modules/impact/pipes/config-period-time.pipe';
import { ImpactEditRunComponent } from '@cityair/modules/impact/components/impact-main.component/impact-runs/impact-edit-run/impact-edit-run.component';
import { ImpactRunResultComponent } from '@cityair/modules/impact/components/impact-main.component/impact-run-result/impact-run-result.component';
import { ImpactControlPointComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-points/impact-control-point/impact-control-point.component';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { ValueCircleModule } from '@libs/shared-ui/components/value-circle/value-circle.module';
import { ImpactCitiesComponent } from './components/impact-main.component/impact-cities/impact-cities.component';
import { ImpactSourcesComponent } from './components/impact-main.component/impact-sources/impact-sources.component';
import { WidgetComponent } from './components/impact-main.component/widget/widget.component';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { AnalyticModule } from '@libs/shared-ui/components/analytic/analytic.module';
import { CityDistrictChartModule } from './components/impact-main.component/city-district-chart/city-district-chart.module';
import { ImpactRunSelectorComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map-element/impact-run-selector/impact-run-selector.component';
import { ItemRadioModule } from '@libs/shared-ui/components/item-radio/item-radio.module';
import { ImpactSourceComponent } from './components/impact-main.component/impact-source/impact-source.component';
import { SourceTypeIconComponent } from './components/impact-main.component/source-type-icon/source-type-icon.component';
import { ImpactMapObjectSelectorComponent } from '@cityair/modules/impact/components/impact-map.component/impact-map-element/impact-map-object-selector/impact-map-object-selector.component';
import { SelectboxModule } from '@libs/shared-ui/components/selectbox/selectbox.module';
import { ImpactEventListComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-list.component';
import { DataForLocalityWidgetPipe } from '@cityair/modules/impact/pipes/data-locality-widget.pipe';
import { MaxValueEventPipe } from '@cityair/modules/impact/pipes/max-value-event.pipe';
import { ImpactEventDetailComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-detail/impact-event-detail.component';
import { ImpactEventCardComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-card/impact-event-card.component';
import { BaseChartDirective } from 'ng2-charts';
import { RepeatExceedEventPipe } from '@cityair/modules/impact/pipes/repeat-exceed-event.pipe';
import { DataSourceEventPipe } from '@cityair/modules/impact/pipes/data-source-event.pipe';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';
import { InfoCorrelationPopupModule } from '@libs/shared-ui/components/info-correlation-popup/info-correlation-popup.module';
import { CorrelationChartDataPipe } from '@cityair/modules/impact/pipes/correlation-chart-data.pipe';
import { AreaChartImpactComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-detail/area-chart/area-chart.component';
import { ChartPostCorrelationComponent } from '@cityair/modules/impact/components/impact-main.component/impact-event-list/impact-event-detail/chart-post-correlation/chart-post-correlation.component';
import { ChartDataPostPipe } from '@cityair/modules/impact/pipes/chart-data-post.pipe';
import { EventContributionPipe } from '@cityair/modules/impact/pipes/event-contribution.pipe';
import { EventContributionByTimePipe } from '@cityair/modules/impact/pipes/event-contibution-by-time.pipe';
import { PostIdsByEventsPipe } from '@cityair/modules/impact/pipes/post-ids-by-events.pipe';
import { OrderEventsPipe } from '@cityair/modules/impact/pipes/order-events.pipe';
import { ControlPointFormComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-points/control-point-form/control-point-form.component';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { StaticTagsModule } from '@libs/shared-ui/components/static-tags/static-tags.module';
import { SectionLineModule } from '@cityair/components/section-line/section-line.module';
import { BtnExtraModule } from '@libs/shared-ui/components/btn-extra/btn-extra.module';
import { BtnCancelModule } from '@libs/shared-ui/components/btn-cancel/btn-cancel.module';
import { BtnAcceptModule } from '@libs/shared-ui/components/btn-accept/btn-accept.module';
import { PopupAskModule } from '@cityair/components/popup-ask/popup-ask.module';
import { ImpactPointsComponent } from './components/impact-main.component/control-points/impact-points/impact-points.component';
import { ImpactRegionsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-regions/impact-regions.component';
import { ImpactRegionDetailComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-regions/impact-region-detail/impact-region-detail.component';
import { ImpactPostsComponent } from '@cityair/modules/impact/components/impact-main.component/control-points/impact-posts/impact-posts.component';
import { ImpactMmtItemComponent } from './components/impact-main.component/mmt-item/mmt-item.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        SharedComponentsModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        InputForCoordinateModule,
        CalendarMonthsModule,
        LoadersModule,
        CityscreenComponentsModule,
        OverlayModule,
        TimelinePanelModule,
        DownloadBtnModule,
        SwitchItemModule,
        AqiDetailsTooltipModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        HeightsSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        NonDataScreenModule,
        ValueCircleModule,
        SortListItemModule,
        AnalyticModule,
        CityDistrictChartModule,
        ItemRadioModule,
        SelectboxModule,
        BaseChartDirective,
        InfoIconModule,
        InfoCorrelationPopupModule,
        HeaderOfPanelModule,
        StaticTagsModule,
        SectionLineModule,
        BtnExtraModule,
        BtnCancelModule,
        BtnAcceptModule,
        PopupAskModule,
    ],
    exports: [ImpactMapComponent],
    declarations: [
        ImpactMainComponent,
        ControlPointsComponent,
        ImpactRunsComponent,
        ImpactRunResultsComponent,
        ImpactMapComponent,
        ImpactMapElementComponent,
        ImpactRunTimePipe,
        FinishRunTimePipe,
        ConfigRunCardComponent,
        DurationsRunPipe,
        ConfigPeriodTimePipe,
        ImpactEditRunComponent,
        ImpactRunResultComponent,
        ImpactControlPointComponent,
        ImpactCitiesComponent,
        ImpactSourcesComponent,
        WidgetComponent,
        ImpactSourceComponent,
        SourceTypeIconComponent,
        ImpactRunSelectorComponent,
        ImpactMapObjectSelectorComponent,
        ImpactEventListComponent,
        DataForLocalityWidgetPipe,
        MaxValueEventPipe,
        ImpactEventDetailComponent,
        ImpactEventCardComponent,
        RepeatExceedEventPipe,
        DataSourceEventPipe,
        CorrelationChartDataPipe,
        AreaChartImpactComponent,
        ChartPostCorrelationComponent,
        ChartDataPostPipe,
        EventContributionPipe,
        EventContributionByTimePipe,
        ImpactRegionsComponent,
        ImpactRegionDetailComponent,
        PostIdsByEventsPipe,
        OrderEventsPipe,
        ControlPointFormComponent,
        ImpactPointsComponent,
        ImpactPostsComponent,
        ImpactMmtItemComponent
    ],
})
export class ImpactModule {}
