import { ModulePageConfig } from '@cityair/namespace';

export const plumeModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableCompare: false,
    enableCalendar: false,
    enablePlumeButton: true,
    enableOldTimeline: false,
    enableAqi: false,
};
