import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { RadarChartComponent } from '@libs/shared-ui/components/charts/radar-chart/radar-chart.component';

@NgModule({
    imports: [CommonModule, BaseChartDirective],
    declarations: [RadarChartComponent],
    exports: [RadarChartComponent],
})
export class RadarChartModule {}
