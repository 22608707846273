<div class="chart-content">
    <canvas
        #chart
        baseChart
        [type]="chartType"
        [legend]="false"
        [datasets]="chartData"
        [options]="chartOptions"
    ></canvas>
</div>
