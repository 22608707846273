import { CompareActions } from '@cityair/namespace';
import { Locality, BasicDataResponse, Post } from '@libs/common/models/basicModels';

export function actionsBeforeCompare(
    stationToAdd: Post | Locality,
    comparedList: BasicDataResponse[],
    isLimit: boolean,
    isCompareMode: boolean
): CompareActions {
    const haveControlPoint = false;

    const stationIsInCompared = comparedList.findIndex(
        (s) => s.id === stationToAdd.id && s.obj === stationToAdd.obj
    );

    return {
        clearBefore: !isCompareMode,
        add: !isLimit && stationIsInCompared === -1,
        delete: stationIsInCompared >= 0,
    };
}
