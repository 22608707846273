import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsListComponent } from './tags-list.component';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';

@NgModule({
    imports: [CommonModule, CrossButtonModule],
    exports: [TagsListComponent],
    declarations: [TagsListComponent],
})
export class TagsListModule {}
