import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BtnExtraComponent } from './btn-extra.component';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';

@NgModule({
    imports: [CommonModule, LoadersModule],
    declarations: [BtnExtraComponent],
    exports: [BtnExtraComponent],
})
export class BtnExtraModule {}
