import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { getAssetPath } from '@cityair/config';

@Component({
    selector: 'cityair-settings-plug',
    templateUrl: './settings-plug.component.html',
    styleUrls: ['./settings-plug.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPlugComponent implements OnInit {
    @Input() title: string;
    @Input() image: string;

    ngOnInit() {}
    getAssetPath = getAssetPath;
}
