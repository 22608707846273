import { ModuleWithProviders, NgModule } from '@angular/core';
import { MapComponent } from '@libs/shared-ui/components/ol/map/map.component';
import { OverlayComponent } from '@libs/shared-ui/components/ol/overlay/overlay.component';
import { MAP_API_KEY, YANDEX_API_KEY } from '@libs/shared-ui/components/ol/map/map.service';
import { ImageComponent } from '@libs/shared-ui/components/ol/image/image.component';
import { LayerComponent } from '@libs/shared-ui/components/ol/layer/layer.component';
import { AttributionComponent } from '@libs/shared-ui/components/ol/attribution/attribution.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        MapComponent,
        OverlayComponent,
        ImageComponent,
        LayerComponent,
        AttributionComponent,
    ],
    imports: [CommonModule],
    exports: [MapComponent, OverlayComponent, ImageComponent, LayerComponent],
})
export class OlModule {
    static withConfig(config: { key: string; yaKey: string }): ModuleWithProviders<OlModule> {
        return {
            ngModule: OlModule,
            providers: [
                {
                    provide: MAP_API_KEY,
                    useValue: config.key,
                },
                {
                    provide: YANDEX_API_KEY,
                    useValue: config.yaKey,
                },
            ],
        };
    }
}
