import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { LittleComponentsModule } from '@libs/shared-ui/components/little-components/little-components.module';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { SharedComponentsModule } from '@cityair/libs/shared/components/shared-components.module';
import { PageTabsModule } from '@libs/shared-ui/components/page-tabs/page-tabs.module';
import { CardModule } from '@cityair/components/card/card.module';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';
import { InputModule } from '@libs/shared-ui/components/input/input.module';
import { InputForCoordinateModule } from '@cityair/components/input-for-coordinate/input-for-coordinate.module';
import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { ButtonAddModule } from '@libs/shared-ui/components/button-add/button-add.module';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { OverlayModule } from '@cityair/libs/shared/modules/overlay/overlay.module';
import { SwitchItemModule } from '@libs/shared-ui/components/switch-item/switch-item.module';
import { KebabMenuModule } from '@cityair/libs/shared/components/kebab-menu/kebab-menu.module';
import { ColorLegendModule } from '@cityair/components/color-legend/color-legend.module';
import { SwitchButtonModule } from '@cityair/components/switch-button/switch-button.module';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';
import { SortListItemModule } from '@cityair/libs/shared/components/sort-list-item/sort-list-item.module';
import { ButtonModule } from '@libs/shared-ui/components/button/button.module';
import { ButtonsSaveCancelModule } from '@libs/shared-ui/components/buttons-save-cancel/buttons-save-cancel.module';
import { MapControlModule } from '@libs/shared-ui/components/map-control/map-control.module';
import { MmtSelectorModule } from '@libs/shared-ui/components/mmt-selector/mmt-selector.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';
import { InputDropdownModule } from '@libs/shared-ui/components/input-dropdown/input-dropdown.module';
import { SelectboxRadioModule } from '@libs/shared-ui/components/selectbox-radio/selectbox-radio.module';
import { MapStyleSelectorComponent } from '@libs/shared-ui/components/map-style-selector/map-style-selector.component';
import { NonDataScreenModule } from '@cityair/components/non-data-screen/non-data-screen.module';
import { NetworkMapComponent } from '@cityair/modules/network/components/network-map/network-map.component';
import { NetworkMainComponent } from '@cityair/modules/network/components/network-main/network-main.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        SharedComponentsModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        LoadersModule,
        CityscreenComponentsModule,
        OverlayModule,
        SwitchItemModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        NonDataScreenModule,
        InfoIconModule,
        CrossButtonModule,
        SortListItemModule,
    ],
    exports: [NetworkMainComponent, NetworkMapComponent],
    declarations: [NetworkMainComponent, NetworkMapComponent],
})
export class NetworkModule {}
