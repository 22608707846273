import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';

import { USER_KEY } from '@libs/common/consts/user-key.const';
import { StorageService } from './storage.service';
import { HarvesterApiService } from '../harvester-api/harvester-api.service';
import { LoginErrorCode } from '../harvester-api/harvester.type';
import { ACCESS_TOKEN_KEY, UserItems, WindowGlobalVars } from '@cityair/namespace';

declare const window: WindowGlobalVars;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private user: UserItems = null;
    private accessToken: string;

    constructor(private storageService: StorageService, private harvesterApi: HarvesterApiService) {
        harvesterApi.error$.subscribe((error) => {
            if (error?.ErrorNumber === LoginErrorCode.TokenFailed) {
                this.removeAccessToken();
                this.removeUser();
            }
        });

        this.initFromStorage();
    }

    private initFromStorage() {
        const user = this.getUser();

        if (user) {
            this.setUser(user);
        }

        const token = this.getAccessToken();

        if (token) {
            this.setAccessToken(token);
        }
    }

    login(login: string, pwd: string) {
        return this.harvesterApi.login(login, pwd).pipe(
            filter((data) => !!data?.access),
            tap((data: { user: UserItems; access: string }) => {
                this.setAccessToken(data.access);
                this.setUser(data.user);
            })
        );
    }

    logOut = async (isCloseToken = true, isReload = true) => {
        this.removeUser();
        this.removeAccessToken();

        if (isCloseToken) await this.closeToken();

        if (isReload) location.reload();
    };

    isLoggedIn() {
        return !!(this.getUser() && this.getAccessToken());
    }

    getUser() {
        return this.user || (this.user = this.getSavedUser());
    }

    setUser(user: UserItems) {
        this.user = user;
        this.storageService.set(USER_KEY, JSON.stringify(user));

        // compatibility with legacy login page
        window.JS_CP_LOGIN = user.login;
    }

    removeUser() {
        this.user = null;
        this.storageService.remove(USER_KEY);
    }

    private getSavedUser() {
        try {
            return JSON.parse(this.storageService.get(USER_KEY));
        } catch (err) {
            // there is no saved user data
            return null;
        }
    }

    getAccessToken() {
        return this.accessToken || (this.accessToken = this.storageService.get(ACCESS_TOKEN_KEY));
    }

    private setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        this.storageService.set(ACCESS_TOKEN_KEY, accessToken);

        // compatibility with legacy login page
        window.JS_CP_TOKEN = accessToken;
    }

    removeAccessToken() {
        this.accessToken = '';
        this.storageService.remove(ACCESS_TOKEN_KEY);
    }

    closeToken() {
        return this.harvesterApi.closeToken(this.accessToken);
    }
}
