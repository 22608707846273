import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'ca-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.less'],
})
export class TextareaComponent implements AfterViewInit {
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() valueForm = '';
    @Input() textInfo = '';
    @Input() disabled = false;
    @Input() textError = '';
    @Input() heightValue: number;

    @ViewChild('textareaText') textareaText: ElementRef;

    ngAfterViewInit() {
        if (this.heightValue) {
            this.textareaText.nativeElement.style.height = this.heightValue + 'px';
        } else {
            const heightEl = this.textareaText.nativeElement.scrollHeight + 4;
            this.textareaText.nativeElement.style.height = heightEl + 'px';
        }
    }
}
