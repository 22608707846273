import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';

import { CardComponent } from '@cityair/components/card/components/card/card.component';
import { CardActionsModule } from '@cityair/components/card-actions/card-actions.module';

@NgModule({
    imports: [CommonModule, DirectivesModule, TooltipsModule, CardActionsModule],
    exports: [CardComponent],
    declarations: [CardComponent],
})
export class CardModule {}
