import { createElement } from '@libs/shared-ui/components/timeline-panel/chart-timeline/utils/tooltip-helper';
import { ANALYSIS_LINE_DASH_STYLES } from '@cityair/modules/analysis/constants';

export function createTooltipTrFirst(body, dashLine) {
    const td1 = createElement('td', 'charts_tooltip__td1');
    const span = createElement('span');
    const textCorrect = correctLabelTooltip(body[0]);

    span.innerHTML = textCorrect.cityName;
    td1.appendChild(span);

    const td2 = createElement('td', 'charts_tooltip__td2');
    // border dash
    const className = dashLine === ANALYSIS_LINE_DASH_STYLES[0] ? 'dash-line' : 'dash-line-border';
    const div = createElement('div', className);
    td2.appendChild(div);

    const tr = createElement('tr', 'charts_tooltip__tr');
    tr.appendChild(td1);
    tr.appendChild(td2);

    return tr;
}
export function correctLabelTooltip(text: string) {
    const values = text.split(':');
    const parser = values[0].split('$$');
    const label = parser[1] ?? parser[0];
    return {
        label: label,
        cityName: parser[0] ?? '',
        value: values[1] ?? '',
    };
}
