import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDropdownComponent } from './input-dropdown.component';
import { InputForDropdownModule } from '@libs/shared-ui/components/input-for-dropdown/input-for-dropdown.module';

@NgModule({
    exports: [InputDropdownComponent],
    declarations: [InputDropdownComponent],
    imports: [CommonModule, InputForDropdownModule],
})
export class InputDropdownModule {}
