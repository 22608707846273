import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';

import { LoadersModule } from '@libs/shared-ui/components/spinner/loaders.module';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { CrossButtonModule } from '@libs/shared-ui/components/cross-button/cross-button.module';

import { LoginPageComponent } from './components/login-page/login-page.component';
import { CityairLogoComponent } from './components/cityair-logo/cityair-logo.component';
import { CityairColoredLogoComponent } from './components/cityair-colored-logo/cityair-colored-logo.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LanguageItemComponent } from './components/language-item/language-item.component';
import { TriangleChevronComponent } from './components/triangle-chevron/triangle-chevron.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { ContactsInfoComponent } from './components/contacts-info/contacts-info.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { PasswordResetFormComponent } from './components/password-reset-form/password-reset-form.component';
import { ResetCodeFormComponent } from './components/reset-code-form/reset-code-form.component';
import { NewPasswordFormComponent } from './components/new-password-form/new-password-form.component';
import { EyeIconComponent } from './components/eye-icon/eye-icon.component';
import { LoginInfoPopupComponent } from './components/login-info-popup/login-info-popup.component';
import { PasswordUpdatedPopupComponent } from './components/password-updated-popup/password-updated-popup.component';
import { LinkExpiredPopupComponent } from './components/link-expired-popup/link-expired-popup.component';
import { DemoBadgeComponent } from './components/demo-badge/demo-badge.component';
import { LoginPaneComponent } from './components/login-pane/login-pane.component';
import { MnrecologyLoginPageComponent } from './components/mnrecology-login-page/mnrecology-login-page.component';
import { NornickelLoginPageComponent } from './components/nornickel-login-page/nornickel-login-page.component';
import { SakhalinLoginPageComponent } from './components/sakhalin-login-page/sakhalin-login-page.component';
import { Spb112LoginPageComponent } from './components/spb112-login-page/spb112-login-page.component';
import { LoginPageContainerComponent } from './components/login-page-container/login-page-container.component';
import { LanguageSelectorMobileComponent } from '@cityair/modules/login/components/language-selector-mobile/language-selector-m.component';
import { LanguageItemMobileComponent } from '@cityair/modules/login/components/language-item-mobile/language-item-m.component';
import { LoginPageMobileComponent } from '@cityair/modules/login/components/login-page-mobile/login-page-m.component';
import { MarketLinksComponent } from './components/market-links/market-links.component';
import { LoginFondComponent } from './components/login-fond/login-fond.component';
import { AirvoiceLoginPageComponent } from '@cityair/modules/login/components/airvoice-login-page/airvoice-login-page.component';
import { InputModule } from '@libs/shared-ui/components/input/input.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadersModule,
        DirectivesModule,
        CrossButtonModule,
        LoginRoutingModule,
        InputModule,
    ],
    exports: [
        LoginPageContainerComponent,
        LoginPageComponent,
        AirvoiceLoginPageComponent,
        LoginPageMobileComponent,
        MnrecologyLoginPageComponent,
        NornickelLoginPageComponent,
        SakhalinLoginPageComponent,
        Spb112LoginPageComponent,
        CityairLogoComponent,
        LanguageSelectorComponent,
        LanguageSelectorMobileComponent,
    ],
    declarations: [
        LoginPageComponent,
        CityairLogoComponent,
        LoginPageMobileComponent,
        CityairColoredLogoComponent,
        LanguageSelectorComponent,
        LanguageSelectorMobileComponent,
        LanguageItemComponent,
        LanguageItemMobileComponent,
        TriangleChevronComponent,
        LegalNoticeComponent,
        ContactsInfoComponent,
        LoginFormComponent,
        LoginButtonComponent,
        PasswordResetFormComponent,
        ResetCodeFormComponent,
        NewPasswordFormComponent,
        EyeIconComponent,
        LoginInfoPopupComponent,
        PasswordUpdatedPopupComponent,
        LinkExpiredPopupComponent,
        DemoBadgeComponent,
        LoginPaneComponent,
        LoginPageContainerComponent,
        MnrecologyLoginPageComponent,
        NornickelLoginPageComponent,
        SakhalinLoginPageComponent,
        Spb112LoginPageComponent,
        MarketLinksComponent,
        LoginFondComponent,
        AirvoiceLoginPageComponent,
    ],
})
export class LoginModule {}
