<div class="measure-wrapper">
    <div class="label">
        {{ labelText }}
    </div>
    <div class="list" [ngClass]="{ 'edit-mode': mode === 'edit' }" *ngIf="list?.length else noData">
        <div
            class="item"
            [ngClass]="{ active: isAll }"
            *ngIf="mode === 'edit' && multiSelect"
            (click)="selectAllValues()"
        >
            {{ allText }}
        </div>
        <div
            class="item"
            [ngClass]="{
                active: isSelected(value),
                disabled: list?.length === 1,
                'not-allow': notAvailable(value)
            }"
            *ngFor="let value of list"
            [innerHTML]="NAMES[value] ?? value"
            (click)="toggleValue(value)"
        ></div>
    </div>
    <ng-template #noData>
        <div class="no-data">
            {{noDataText}}
        </div>
    </ng-template>
</div>
