import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorLegendComponent } from './color-legend.component';
import { TooltipsModule } from '@libs/shared-ui/components/tooltips/tooltips.module';
import { InfoIconModule } from '@libs/shared-ui/components/icons/components/info-icon/info-icon.module';

@NgModule({
    exports: [ColorLegendComponent],
    declarations: [ColorLegendComponent],
    imports: [CommonModule, TooltipsModule, InfoIconModule],
})
export class ColorLegendModule {}
