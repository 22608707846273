import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import * as moment from 'moment-timezone';
import { EventDateRange } from '@libs/common/models/basicModels';

@Component({
    selector: 'cityair-date-range-selector',
    templateUrl: './date-range-selector.component.html',
    styleUrls: ['./date-range-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeSelectorComponent implements OnChanges {
    @Input() dateRange: EventDateRange;
    @Input() descriptionText = '';
    @Input() numberOfMonths = 8;
    @Input() displayMonth = 2;
    @Output() dateChange = new EventEmitter<EventDateRange>();
    showCalendar = false;
    timeBegin: number;
    timeEnd: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.dateRange?.currentValue) {
            this.timeBegin = moment(this.dateRange.start).valueOf();
            this.timeEnd = moment(this.dateRange.end).valueOf();
        }
    }

    public updateTime = (start, end) => {
        this.dateRange = {
            ...this.dateRange,
            start: moment(start).toISOString(),
            end: moment(end).toISOString(),
        };
        this.showCalendar = false;
        this.dateChange.emit(this.dateRange);
    };

    close = () => (this.showCalendar = false);
    show = () => (this.showCalendar = true);
}
