import { Component, OnDestroy } from '@angular/core';
import { getColorFromZone } from '@cityair/utils/utils';
import { RegionApi } from '@libs/common/models/basicModels';
import { TEXTS } from '@libs/common/texts/texts';
import { ColorZone } from '@libs/common/types/color-zone';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import {
    ImpactActions,
    selectRegions,
    selectActiveMmt,
    getStationImpactValue,
    getStationImpact,
    selectImpactSchemaZones,
    getControlPoint,
    getControlPointImpactValue,
    getRegionImpactValue,
    selectIsLoadingRegionsApi
} from '@cityair/modules/impact/store/impact.feature';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { Router } from '@angular/router';

@Component({
    selector: 'impact-regions',
    templateUrl: './impact-regions.component.html',
    styleUrls: ['./impact-regions.component.less'],
})
export class ImpactRegionsComponent implements OnDestroy {
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public textsScheme = TEXTS.MEASURES_SCHEME;
    public textsPlumes = TEXTS.PLUMES;
    public getStationImpact = getStationImpact;
    public getStationImpactValue = getStationImpactValue;
    public getControlPoint = getControlPoint;
    public getControlPointImpactValue = getControlPointImpactValue;
    public getRegionImpactValue = getRegionImpactValue;
    public selectIsLoadingRegionsApi = selectIsLoadingRegionsApi;
    selectRegions = selectRegions;
    selectMeasureScheme = selectMeasureScheme;

    getColorFromZone = getColorFromZone;
    public sortField: string = 'name';
    public sortDirection: number = -1;
    public selectedRegion: RegionApi = null;
    public showSelectedRegionPosts: boolean = false;

    public digitsAfterDot: number;
    public ngDestroyed$ = new Subject<void>();
    public currentMeasure: string;
    public zone: ColorZone;

    constructor(public store: Store, private router: Router) {
        store
            .select(selectImpactSchemaZones)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.digitsAfterDot = getDigitsAfterDot(data?.scheme, data?.mmt);
                this.zone = data?.zone;
            });
        store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
    }

    public setSortingCb(sortCb: string): void {
        if (this.sortField === sortCb) {
            this.sortDirection *= -1;
        } else {
            this.sortField = sortCb;
        }
    }

    onShowRegionPosts(region: RegionApi) {
        if (this.isRegionSelected(region)) {
            this.selectedRegion = null;
            this.showSelectedRegionPosts = false;
        } else {
            this.selectedRegion = region;
            this.showSelectedRegionPosts = true;
        }
    }

    isRegionSelected(region: RegionApi) {
        return region.id === this.selectedRegion?.id;
    }

    onOpenRegion(region: RegionApi) {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.regions}/${region.id}`,
        ]);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }
}
