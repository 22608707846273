import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QueryParams } from '@ngrx/data';
import { VangaAuthService } from '../../../core/services/vanga-auth/vanga-auth.service';
import { CITYAIR_API_BASIC_URL } from '@cityair/modules/plumes/constants';
import { isRU } from '@libs/common/texts/texts';
import { StationContributionResponse } from '@cityair/modules/plumes/services/station/models';

const PLUMES_API_URL = `${environment.vanga_api_url}/plumes/v1/runs`;
const CONTROL_POINTS_END_POINTS = 'control_points';
const API_URL = `${environment.api_url}${CITYAIR_API_BASIC_URL}/Post`;
@Injectable({
    providedIn: 'root',
})
export class ApiPlumes {
    private accessToken = '';
    private lang = isRU ? 'ru' : 'en';
    constructor(private http: HttpClient, private vangaAuthService: VangaAuthService) {}

    getStationData(data: { id: string; params: QueryParams }): Observable<any> {
        const token = this.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: data.params,
        };
        const url = API_URL + '/' + data.id + '/Timeline';
        return this.http.get(url, httpOptions);
    }

    getStationContributionData(data: {
        id: string;
        params: QueryParams;
    }): Observable<StationContributionResponse> {
        const token = this.vangaAuthService.getAccessToken();
        const httpOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': this.lang,
            },
            params: data.params,
        };
        const url = PLUMES_API_URL + '/' + data.id + '/timeline/';
        return this.http.get<StationContributionResponse>(url, httpOptions);
    }

    getControlPoints(data: { id: number; params: QueryParams }): Observable<any> {
        const httpOptions = this.getHttpOptions(data.params);
        const url = PLUMES_API_URL + '/' + data.id + '/' + CONTROL_POINTS_END_POINTS + '/';
        return this.http.get(url, httpOptions);
    }

    private getHttpOptions(params?: string | QueryParams | HttpParams): any {
        const token = this.vangaAuthService.getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            'Accept-Language': this.lang,
        };

        if (params) {
            return Object.assign({}, { headers }, { params });
        }

        return { headers };
    }

    private getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem('token'));
    }
}
