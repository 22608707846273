import { createAction, props } from '@ngrx/store';
import {
    IBasicResponse,
    AnalysisEvent,
    AnalysisEventStatus,
    CorrelationData,
    EventData,
    CorrelationPostData,
    AnalysisEventStatusByEnd,
    SortingData,
    WindDataPost,
} from '@libs/common/models/basicModels';
import { EventDateRange } from '@libs/common/models/basicModels';

export enum AnalysisEventsActions {
    initAnalysisMainEvent = '[AnalysisEvents] init Analysis main Event',
    initAnalysisEvent = '[AnalysisEvents] init Analysis Event',
    setDateRange = '[AnalysisEvents] set date range',
    isLoadingAnalysisEvent = '[AnalysisEvents] is Loading Analysis Event',
    setLoadingAnalysisTimeline = '[AnalysisEvents] set loading analysis timeline',
    setLoadingAnalysisCorrelation = '[AnalysisEvents] set loading analysis correlation',
    timelinePostsLoaded = '[AnalysisEvents] timelinePostLoaded',
    clearTimelineData = '[AnalysisEvents] clearTimelineData',
    setGroupsEvents = '[AnalysisEvents] set groups events',
    setCurrentAnalysisEvent = '[AnalysisEvents] set Current Analysis Event',
    setRegisterSearchText = '[AnalysisEvents] set register search text',
    setFilterStatus = '[AnalysisEvents] set filter status',
    setFilterMmt = '[AnalysisEvents] set filter mmt',
    setCorrelationDataEvents = '[AnalysisEvents] set correlation data',
    setErrorCorrelationDataEvents = '[AnalysisEvents] set error correlation data',
    setCurrentEventData = '[AnalysisEvents] set current event data',
    setCorrelationByEventData = '[AnalysisEvents] set correlation by event data',
    setSorting = '[AnalysisEvents] set sorting',
    clearFilter = '[AnalysisEvents] clear filter',
    clearGroupEvents = '[AnalysisEvents] clear Group Events',
    setWindData = '[AnalysisEvents] set wind data',
}
export const initAnalysisMainEvent = createAction(AnalysisEventsActions.initAnalysisMainEvent);
export const initAnalysisEvent = createAction(AnalysisEventsActions.initAnalysisEvent);
export const setSorting = createAction(
    AnalysisEventsActions.setSorting,
    props<{ payload: SortingData }>()
);
export const timelinePostsLoaded = createAction(
    AnalysisEventsActions.timelinePostsLoaded,
    props<{ payload: IBasicResponse }>()
);
export const clearTimelineData = createAction(AnalysisEventsActions.clearTimelineData);
export const isLoadingAnalysisEvent = createAction(
    AnalysisEventsActions.isLoadingAnalysisEvent,
    props<{ payload: boolean }>()
);
export const setLoadingAnalysisTimeline = createAction(
    AnalysisEventsActions.setLoadingAnalysisTimeline,
    props<{ payload: boolean }>()
);
export const setLoadingAnalysisCorrelation = createAction(
    AnalysisEventsActions.setLoadingAnalysisCorrelation,
    props<{ payload: boolean }>()
);
export const setCurrentAnalysisEvent = createAction(
    AnalysisEventsActions.setCurrentAnalysisEvent,
    props<{ eventId: string }>()
);

export const setGroupsEvents = createAction(
    AnalysisEventsActions.setGroupsEvents,
    props<{ payload: AnalysisEvent[]; isDemoMode: boolean }>()
);
export const clearGroupEvents = createAction(AnalysisEventsActions.clearGroupEvents);
export const setRegisterSearchText = createAction(
    AnalysisEventsActions.setRegisterSearchText,
    props<{ payload: string }>()
);
export const setFilterStatus = createAction(
    AnalysisEventsActions.setFilterStatus,
    props<{ payload: AnalysisEventStatus | AnalysisEventStatusByEnd }>()
);
export const setFilterMmt = createAction(
    AnalysisEventsActions.setFilterMmt,
    props<{ payload: string }>()
);
export const setCorrelationDataEvents = createAction(
    AnalysisEventsActions.setCorrelationDataEvents,
    props<{ payload: CorrelationData }>()
);
export const setErrorCorrelationDataEvents = createAction(
    AnalysisEventsActions.setErrorCorrelationDataEvents,
    props<{ payload: boolean }>()
);
export const setCurrentEventData = createAction(
    AnalysisEventsActions.setCurrentEventData,
    props<{ payload: EventData }>()
);
export const setCorrelationByEventData = createAction(
    AnalysisEventsActions.setCorrelationByEventData,
    props<{ payload: CorrelationPostData }>()
);
export const setDateRange = createAction(
    AnalysisEventsActions.setDateRange,
    props<{ payload: EventDateRange }>()
);
export const clearFilter = createAction(AnalysisEventsActions.clearFilter);
export const setWindData = createAction(
    AnalysisEventsActions.setWindData,
    props<{ payload: WindDataPost }>()
);
