import { Pipe, PipeTransform } from '@angular/core';
import { TimeSeriesData } from '@libs/common/models/basicModels';
import { AqiType } from '@libs/common/enums/aqi.type';

@Pipe({
    name: 'dataForLocalityWidget',
})
export class DataForLocalityWidgetPipe implements PipeTransform {
    transform(data: TimeSeriesData, mmt: string): number {
        if (mmt === AqiType.instant) {
            const len = data?.indexes?.[mmt].values.length;
            const value = data?.indexes?.[mmt]?.values[len - 1];
            return value ? Math.round(value) : null;
        } else {
            const len = data?.measurements?.[mmt].values.length;
            const value = data?.measurements?.[mmt]?.values[len - 1];
            return value ? Math.round(value) : null;
        }
    }
}
