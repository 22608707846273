import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { iif, of } from 'rxjs';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';
import {
    clearTimelineData,
    clearFilter,
    initAnalysisEvent,
    isLoadingAnalysisEvent,
    setCorrelationByEventData,
    setCorrelationDataEvents,
    setCurrentAnalysisEvent,
    setCurrentEventData,
    setErrorCorrelationDataEvents,
    setGroupsEvents,
    setLoadingAnalysisCorrelation,
    setLoadingAnalysisTimeline,
    timelinePostsLoaded,
    setRegisterSearchText,
    setFilterStatus,
    setFilterMmt,
    setSorting,
    setDateRange,
    clearGroupEvents,
    setWindData,
} from '@cityair/modules/analysis-events/store/actions';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
    selectCorrelationDataEvents,
    selectCurrentAnalysisEvent,
    selectDateRangeForList,
    selectIsClearFilterIsDisabled,
    selectIsDemoMode,
    selectParamsForCorrelationByEvent,
    selectParamsForEventAnalysis,
    selectParamsForEventList,
    selectParamsForEventListInit,
    selectParamsForWindQuery,
} from '@cityair/modules/analysis-events/store/selectors';
import { INIT_SORTING, REGISTER_EVENTS } from '@cityair/modules/analysis-events/constants';
import { selectExtConfig, selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { CorrelationData, CorrelationPostData, EventData } from '@libs/common/models/basicModels';
import { doNothing } from '@cityair/modules/core/store/actions';
import * as moment from 'moment-timezone';
@Injectable()
export class AnalysisEventsEffects {
    constructor(private actions$: Actions, private store: Store, private basicApi: BasicApi) {}

    setGroupAnalysisEventInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initAnalysisEvent),
            withLatestFrom(
                this.store.select(selectParamsForEventListInit),
                this.store.select(selectDateRangeForList)
            ),
            filter(([_, params, dateRange]) => params !== null && dateRange === null),
            switchMap(([action, params]) => [setDateRange({ payload: params })])
        )
    );

    setGroupAnalysisEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setDateRange),
            withLatestFrom(
                this.store.select(selectParamsForEventList),
                this.store.select(selectExtConfig)
            ),
            filter(([_, params]) => params !== null),
            tap(() => this.store.dispatch(clearGroupEvents())),
            switchMap(([action, params, extConfig]) =>
                iif(
                    () => !extConfig?.isDemoModeForEvents,
                    this.basicApi.getGroupEvents(params).pipe(
                        switchMap((response) => [
                            setGroupsEvents({ payload: response.data, isDemoMode: false }),
                        ]),
                        catchError((errorResponse: HttpErrorResponse) => {
                            if (errorResponse?.status === HttpStatusCode.Forbidden) {
                                location.reload();
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction);
                            }
                        })
                    ),
                    of(setGroupsEvents({ payload: REGISTER_EVENTS, isDemoMode: true }))
                )
            )
        )
    );

    getPostDataForAnalysis$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentAnalysisEvent),
            withLatestFrom(this.store.select(selectParamsForEventAnalysis)),
            filter(([action, data]) => data !== null),
            tap(() => this.store.dispatch(setLoadingAnalysisTimeline({ payload: true }))),
            tap(() => this.store.dispatch(clearTimelineData())),
            switchMap(([action, { groupId, params }]) =>
                this.basicApi.getDataTimeline(groupId, params).pipe(
                    switchMap((response) => [
                        timelinePostsLoaded({
                            payload: response,
                        }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, setLoadingAnalysisTimeline({ payload: false }));
                        }
                    })
                )
            )
        )
    );

    getWindDataByEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentAnalysisEvent),
            withLatestFrom(
                this.store.select(selectParamsForWindQuery),
                this.store.select(selectIsDemoMode)
            ),
            filter(([action, data, isDemo]) => data !== null && !isDemo),
            tap(() => this.store.dispatch(setWindData({ payload: null }))),
            switchMap(([action, params]) =>
                this.basicApi.getAnalysisWindData(params).pipe(
                    switchMap((response) => [
                        setWindData({
                            payload: response?.data,
                        }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => of(doNothing()))
                )
            )
        )
    );

    getCorrelationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentAnalysisEvent),
            withLatestFrom(
                this.store.select(selectCorrelationDataEvents),
                this.store.select(selectGroupId)
            ),
            filter(([_, data, groupId]) => data === null && !!groupId),
            switchMap(([action, data, groupId]) =>
                this.basicApi.getAnalysisData(groupId).pipe(
                    switchMap((response) => {
                        const data = response?.data as CorrelationData;
                        return [setCorrelationDataEvents({ payload: data })];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            if (errorResponse?.status === HttpStatusCode.NotFound) {
                                return of(setErrorCorrelationDataEvents({ payload: true }));
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction);
                            }
                        }
                    })
                )
            )
        )
    );

    getDataByEvent = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentAnalysisEvent),
            withLatestFrom(
                this.store.select(selectCurrentAnalysisEvent),
                this.store.select(selectGroupId)
            ),
            filter(([_, data, groupId]) => !!data && !!groupId),
            tap(() => this.store.dispatch(setCurrentEventData({ payload: null }))),
            tap(() => this.store.dispatch(isLoadingAnalysisEvent({ payload: true }))),
            switchMap(([action, data, groupId]) =>
                this.basicApi.getEventById(groupId, data.id).pipe(
                    switchMap((response) => {
                        const data = response?.data as EventData;
                        return [
                            setCurrentEventData({ payload: data }),
                            isLoadingAnalysisEvent({ payload: false }),
                        ];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            if (errorResponse?.status === HttpStatusCode.NotFound) {
                                return of(setErrorCorrelationDataEvents({ payload: true }));
                            } else {
                                const errorAction = getActionBasicError(errorResponse);
                                return of(errorAction);
                            }
                        }
                    })
                )
            )
        )
    );

    getCorrelationDataByEvent = createEffect(() =>
        this.actions$.pipe(
            ofType(setCurrentAnalysisEvent),
            withLatestFrom(
                this.store.select(selectCurrentAnalysisEvent),
                this.store.select(selectParamsForCorrelationByEvent)
            ),
            filter(([_, data, params]) => data !== null && params !== null),
            tap(() => this.store.dispatch(setCorrelationByEventData({ payload: null }))),
            tap(() => this.store.dispatch(setLoadingAnalysisCorrelation({ payload: true }))),
            switchMap(([action, data, params]) =>
                this.basicApi.getCorrelationDataByPost(data.events[0].post_id, params).pipe(
                    switchMap((response) => {
                        const data = response?.data as CorrelationPostData;
                        return [
                            setCorrelationByEventData({ payload: data }),
                            setLoadingAnalysisCorrelation({ payload: false }),
                        ];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        }
                    })
                )
            )
        )
    );

    clearFilter = createEffect(() =>
        this.actions$.pipe(
            ofType(clearFilter),
            withLatestFrom(this.store.select(selectIsClearFilterIsDisabled)),
            filter(([_, data]) => !data),
            switchMap(([action, data]) => {
                const actions = [
                    setRegisterSearchText({ payload: null }),
                    setFilterStatus({ payload: null }),
                    setFilterMmt({ payload: null }),
                    setSorting({ payload: INIT_SORTING }),
                    setDateRange({
                        payload: {
                            start: moment().startOf('day').subtract(1, 'months').toISOString(),
                            end: moment().endOf('day').toISOString(),
                        },
                    }),
                ];
                return actions;
            })
        )
    );
}
