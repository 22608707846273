import { createAction, props } from '@ngrx/store';
import { BasicUserTokens, RegionCoefsResponse } from '@libs/common/models/basicModels';

export enum SettingsActions {
    getUserPermission = '[SettingsActions] get user permission',
    setUserPermission = '[SettingsActions] set user permission',
    deleteUserToken = '[SettingsActions] delete user token',
    deleteAllActiveToken = '[SettingsActions] delete all user token',
    updateApiKey = '[SettingsActions] update api key',
    getUserTokenValue = '[SettingsActions] get user token value',
    setApiKeyToken = '[SettingsActions] set user token value',
    clearApiKeyToken = '[SettingsActions] clear user token value',
    updateRegionCoef = '[SettingsActions] update region coef',
    setRegionCoef = '[SettingsActions] set region coef',
    setNewCoef = '[SettingsActions] set new coef',
    setMapSettings = '[SettingsActions] set map settings',
    setStartModuleSettings = '[SettingsActions] set start module settings',
    isSavingMapSetting = '[SettingsActions] is saving map setting',
    updateGroupSettingsResponse = '[SettingsActions] update group settings response',
    errorSaveMapSettings = '[SettingsActions] error save map settings',
    isSavingStartModule = '[SettingsActions] is saving start module',
}

export const getUserPermission = createAction(SettingsActions.getUserPermission);
export const setUserPermission = createAction(
    SettingsActions.setUserPermission,
    props<{ payload: any }>()
);
export const deleteUserToken = createAction(
    SettingsActions.deleteUserToken,
    props<{ payload: BasicUserTokens }>()
);
export const deleteAllActiveToken = createAction(SettingsActions.deleteAllActiveToken);

export const updateApiKey = createAction(
    SettingsActions.updateApiKey,
    props<{ payload: BasicUserTokens }>()
);
export const getUserTokenValue = createAction(
    SettingsActions.getUserTokenValue,
    props<{ payload: BasicUserTokens }>()
);
export const setApiKeyToken = createAction(
    SettingsActions.setApiKeyToken,
    props<{ payload: string; id: number }>()
);
export const clearApiKeyToken = createAction(
    SettingsActions.clearApiKeyToken,
    props<{ payload: number }>()
);
export const setRegionCoef = createAction(
    SettingsActions.setRegionCoef,
    props<{ payload: RegionCoefsResponse }>()
);
export const updateRegionCoef = createAction(
    SettingsActions.updateRegionCoef,
    props<{ payload: number }>()
);
export const setNewCoef = createAction(SettingsActions.setNewCoef, props<{ payload: number }>());
export const setMapSettings = createAction(
    SettingsActions.setMapSettings,
    props<{ payload: any }>()
);
export const setStartModuleSettings = createAction(
    SettingsActions.setStartModuleSettings,
    props<{ payload: string }>()
);
export const isSavingMapSetting = createAction(
    SettingsActions.isSavingMapSetting,
    props<{ payload: boolean }>()
);
export const isSavingStartModule = createAction(
    SettingsActions.isSavingStartModule,
    props<{ payload: boolean }>()
);
export const updateGroupSettingsResponse = createAction(
    SettingsActions.updateGroupSettingsResponse,
    props<{ payload: any }>()
);
