import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    SimpleChanges,
} from '@angular/core';
import { PostsAndDevicesFacade } from '@cityair/modules/posts-and-devices/posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { NETWORK_PAGES } from '@cityair/modules/posts-and-devices/namespace';
import { ActionHistory } from '@cityair/modules/posts-and-devices/posts-and-devices.collection';
import { ANIMATION_MAX_HEIGHT } from '@cityair/libs/shared/utils/config';
import { Store } from '@ngrx/store';
import { selectMo } from '@cityair/modules/core/store/actions';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { Device, Post, DISPLAY_DEVICE_TYPE } from '@libs/common/models/basicModels';
import { selectDeviceByPostId } from '@cityair/modules/core/store/selectors';
import { selectUsersForPost } from '@cityair/modules/settings/store/users/selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deletePost } from '@cityair/modules/core/store/posts/posts.feature';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

@Component({
    selector: 'post-details',
    templateUrl: './post-details.component.html',
    styleUrls: ['./post-details.component.less'],
    animations: [ANIMATION_OPACITY, ANIMATION_MAX_HEIGHT],
})
export class PostDetailsComponent implements OnChanges, OnDestroy, OnInit {
    @Input() MO: Post;
    device: Device;
    DISPLAY_DEVICE_TYPE = DISPLAY_DEVICE_TYPE;
    isShowDeletePopup = false;
    isShowMenu = false;
    isRU = isRU;
    isShowActionHistory = false;
    menuPositionTop = 0;
    pages = NETWORK_PAGES;
    path = '';
    TEXTS = TEXTS;
    private popupOpenerElement: HTMLElement;
    nameTargetCard = '';
    idTargetCard = '';
    actionHistory: ActionHistory[] = null;
    wrapperGMT;
    isNoDataSources = true;
    isCheckTheresObserver = false;
    usersList: string[] = [];
    onDestroy$ = new Subject<void>();
    @ViewChild('cardMenu', { static: true }) cardMenu: TemplateRef<HTMLDivElement>;

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public store: Store<any>,
        private activatedRoute: ActivatedRoute,
        public popupProvider: OffPanelPopupService,
        private numberFormatPipe: ValueFormatNumberPipe
    ) {
        this.idTargetCard = this.activatedRoute.snapshot.params.id;
        this.path =
            this.pages.networks +
            '/' +
            this.activatedRoute.snapshot.url[0] +
            '/' +
            this.activatedRoute.snapshot.url[1] +
            '/';
    }

    ngOnInit() {
        const id = this.MO.id;
        this.store.dispatch(selectMo({ id }));
        this.store
            .select(selectDeviceByPostId(this.MO.id))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data) this.device = data;
                this.isNoDataSources = data ? false : true;
            });
        this.store
            .select(selectUsersForPost(id))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => (this.usersList = data));
    }

    ngOnChanges(changes: SimpleChanges) {
        this.wrapperGMT = TEXTS.GMT_VALUE.find(
            (gmt) => gmt[0] == this.MO.gmt_offset_seconds / 3600
        )?.[1];
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    clickMenu({ target, positionTop }) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.cardMenu, () => (this.isShowMenu = false));
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop;
        this.isShowMenu = true;
    }

    openPage(target: any, pageName: string) {
        if (target != null) {
            this.closePopup(target);
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        if (type === 'delete') {
            this.nameTargetCard = this.MO.name;
            this.isShowDeletePopup = true;
        }
    }

    deleteAccept() {
        this.store.dispatch(deletePost());
        this.isShowDeletePopup = false;
        this.postsAndDevicesFacade.openPage(
            this.pages.networks + '/' + this.activatedRoute.snapshot.url[0]
        );
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }

    getCoordinate() {
        const lat = this.numberFormatPipe.transform(this.MO.geometry.coordinates[1], 10);
        const lon = this.numberFormatPipe.transform(this.MO.geometry.coordinates[0], 10);
        return `${lat} ${lon}`;
    }
}
