import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    setActiveCorrelationPair,
    setCurrentTab,
    setSearchQueryLinks,
    setSortingLinks,
} from '@cityair/modules/analysis/store/actions';
import {
    selectIsLoadingData,
    selectCurrentMmt,
    selectErrorLoadCorrelationData,
    selectGeoParamsByActivePair,
    selectCorrelationAnalysisData,
    selectSortingDataLinks,
    selectSearchQueryLinks,
} from '@cityair/modules/analysis/store/selectors';
import { LANGUAGE, TEXTS } from '@libs/common/texts/texts';
import { CorrelationAnalysisData } from '@libs/common/models/basicModels';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { getCorrelationColor } from '@libs/common/consts/correlation-zone.const';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { getShiftMapByLine } from '@cityair/config';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchInputBasicComponent } from '@libs/shared-ui/components/search-input-basic/search-input-basic.component';

@Component({
    selector: 'cityair-correlation',
    templateUrl: './correlation.component.html',
    styleUrls: ['./correlation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrelationComponent implements OnDestroy {
    text = TEXTS.ANALYSIS.correlation;
    public textClear = TEXTS.LIST_OM.clearSearch;
    public textNames = TEXTS.NAMES;
    public textSearch = TEXTS.ANALYSIS.posts.searchPlaceholder;
    searchQuery: string;
    public sortDirection: number;
    public sortField: string;

    selectIsLoadingData = selectIsLoadingData;
    selectErrorLoadCorrelationData = selectErrorLoadCorrelationData;
    selectCurrentMmt = selectCurrentMmt;
    setActiveCorrelationPair = setActiveCorrelationPair;

    correlationData: CorrelationAnalysisData[] = null;
    public params: string;
    activePair: CorrelationAnalysisData;
    currentLang = LANGUAGE;
    public ngDestroyed$ = new Subject<void>();
    showInfoPopup = false;

    @ViewChild('contentWrapper') contentWrapper: ElementRef<HTMLElement>;
    @ViewChild('searchInput') searchInputComponent: SearchInputBasicComponent;

    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private mapActions: MapboxActions,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.params.subscribe((params) => {
            if (params?.pair) {
                this.params = params.pair;
                if (this.correlationData !== null) {
                    this.setActivePair(params.pair, this.correlationData);
                }
            }
            _changeDetectorRef.markForCheck();
        });
        this.store.dispatch(setCurrentTab({ payload: ANALYSIS_PAGES.links }));
        store
            .select(selectCorrelationAnalysisData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.correlationData = data;
                if (this.params && !this.activePair && this.correlationData !== null) {
                    this.setActivePair(this.params, this.correlationData);
                }
                _changeDetectorRef.markForCheck();
            });
        store
            .select(selectGeoParamsByActivePair)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null),
                distinctUntilChanged()
            )
            .subscribe((data) => {
                this.mapActions.centringOnLine(
                    Number(data.center[0]),
                    Number(data.center[1]),
                    false,
                    data.zoomLevel,
                    getShiftMapByLine()
                );
                setTimeout(() => this.correctScroll(), 100);
            });
        store
            .select(selectSortingDataLinks)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.sortDirection = data.dir;
                this.sortField = data.name;
            });
        store
            .select(selectSearchQueryLinks)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((query) => {
                this.searchQuery = query;
                if (query === null) {
                    this.searchInputComponent?.form.reset();
                }
            });
    }

    public getColor(value) {
        return getCorrelationColor(value);
    }

    public isActive(item) {
        if (this.activePair) {
            return (
                item.first_post === this.activePair.first_post &&
                item.second_post === this.activePair.second_post
            );
        }
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }

    public textChangeIn($event) {
        this.store.dispatch(setSearchQueryLinks({ payload: $event }));
    }

    public setSortingCb(sortCb: string): void {
        let sortDirection = this.sortDirection;
        let sortField = this.sortField;
        if (this.sortField === sortCb) {
            sortDirection *= -1;
        } else {
            sortField = sortCb;
        }
        this.store.dispatch(
            setSortingLinks({
                payload: {
                    name: sortField,
                    dir: sortDirection,
                },
            })
        );
    }

    showInfo() {
        this.showInfoPopup = !this.showInfoPopup;
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.activePair) {
            this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}`]);
            this.activePair = null;
            this.store.dispatch(setActiveCorrelationPair({ payload: null }));
        }
    }

    public selectPair(item) {
        this.router.navigate([
            `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}/${item.first_post}-${item.second_post}`,
        ]);
    }

    public closeChart(event) {
        event.stopPropagation();
        this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}`]);
        this.activePair = null;
        this.store.dispatch(setActiveCorrelationPair({ payload: null }));
    }

    private correctScroll() {
        const currentEl = document.querySelector('.active-pair') as HTMLElement;
        const rect = this.contentWrapper?.nativeElement.getBoundingClientRect();
        const currentRect = currentEl?.getBoundingClientRect();

        if (currentEl && rect && currentRect && currentRect.top >= rect.top + rect.height) {
            currentEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
    }

    private setActivePair(pair: string, data: CorrelationAnalysisData[]) {
        const pairArray = pair.split('-');
        const activePair = data.find(
            (v) => v.first_post === pairArray[0] && v.second_post === pairArray[1]
        );
        this.activePair = activePair;
        if (activePair) {
            this.store.dispatch(setActiveCorrelationPair({ payload: activePair }));
        }
    }
}
