import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TEXTS } from '@libs/common/texts/texts';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import { getDigitsAfterDot } from '@libs/common/helpers/get-digits-after-dot';
import { ANIMATION_OPACITY } from '@libs/common/consts/animation-opacity.const';
import {
    ImpactActions,
    selectActiveRun,
    selectActiveMmt,
    selectConfigImpactDic,
    selectControlPoints,
    selectIsLoadingControlPoints,
    getControlPointImpactValue,
    selectActivePin,
    selectImpactSchemaZones,
} from '@cityair/modules/impact/store/impact.feature';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { OffPanelPopupService } from '@cityair/modules/core/services/off-panel-popup.service';
import { ControlPointImpact } from '@cityair/modules/impact/service/api-model-impact';
import { takeUntil } from 'rxjs/operators';
import { ColorZone } from '@libs/common/types/color-zone';
import { getColorFromZone } from '@cityair/utils/utils';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';

@Component({
    selector: 'cs-impact-points',
    templateUrl: './impact-points.component.html',
    styleUrls: ['./impact-points.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class ImpactPointsComponent implements OnInit, OnDestroy {
    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;
    @ViewChild('popupDeleteOutlet', { static: true })
    popupDeleteOutlet: TemplateRef<HTMLDivElement>;
    selectMeasureScheme = selectMeasureScheme;
    selectActiveRun = selectActiveRun;
    selectActiveMmt = selectActiveMmt;
    selectConfigImpactDic = selectConfigImpactDic;
    selectIsLoadingControlPoints = selectIsLoadingControlPoints;
    selectControlPoints = selectControlPoints;
    getControlPointImpactValue = getControlPointImpactValue;

    textsPlumes = TEXTS.PLUMES;
    editStation = TEXTS.EDIT_STATION;
    textsScheme = TEXTS.MEASURES_SCHEME;
    public mmtNames = TEXTS.NAMES;
    errorListLoadText = TEXTS.FORECAST.errorListLoad;
    measureScheme = MeasureScheme;
    getDigitsAfterDot = getDigitsAfterDot;

    sortingControlPoint;
    sortingDirection = 1;
    public activeControlPoint: any;
    public loading;
    popupActive = false;
    popupPositionTop = 0;
    selectedPoint: ControlPointImpact = null;
    currentMeasure: string;
    popupOpenerElement: HTMLElement;
    isDeletePopupOpen: boolean;
    popupDeleteTexts = Object.assign({}, TEXTS.PLUMES.popupDelete);
    public ngDestroyed$ = new Subject<void>();
    activePost: string;
    zone: ColorZone;
    getColorFromZone = getColorFromZone;
    digitsAfterDot: number;

    constructor(
        readonly store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        readonly popupProvider: OffPanelPopupService,
        private router: Router
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.controlPoints }));
        store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
        store
            .select(selectActivePin)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.activeControlPoint = data ? data.toString() : data;
                _changeDetectorRef.markForCheck();
            });
        store
            .select(selectImpactSchemaZones)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.digitsAfterDot = getDigitsAfterDot(data?.scheme, data?.mmt);
                this.zone = data?.zone;
            });
    }

    ngOnInit() {
        this.sortingDirection = 1;
        this.sortingOfControlPoint(this.sortControlPointName);
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }

    sortControlPointName = (cp: ControlPointImpact) => cp.name;
    sortControlPointValue = (cp: ControlPointImpact) => {
        /* if (cp.timeline.hasOwnProperty(this.currentMeasure) && cp.timeline[this.currentMeasure]) {
            return cp.timeline[this.currentMeasure][this.timeIndex];
        } */

        return null;
    };

    sortingOfControlPoint = (sortItem) => {
        if (this.sortingControlPoint === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingControlPoint = sortItem;
        }
    };

    deleteControlPoint = (id: number) => {
        this.store.dispatch(ImpactActions.deleteControlPoint({ payload: id }));
    };

    editControlPoint = (id: number) => {
        this.popupProvider.setTemplate(null);
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}/${IMPACT_PAGES.edit}/${id}`,
        ]);
    };

    openControlPointChart(point: ControlPointImpact) {
        this.store.dispatch(ImpactActions.setActivePin({ payload: point.id.toString() }));
    }

    openActionsPopup($event, point: ControlPointImpact) {
        this.popupOpenerElement = $event.target;
        this.popupProvider.setTemplate(this.actionsOutlet);
        this.popupActive = true;
        this.selectedPoint = point;
        this.popupPositionTop = $event.positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedPoint = null;
        }
    }

    deleteControlPointAccept = () => {
        this.closeDeleteControlPointDialog();
        this.deleteControlPoint(this.selectedPoint.id);
    };

    closeDeleteControlPointDialog = () => {
        this.isDeletePopupOpen = false;
        this.popupProvider.setTemplate(null);
    };

    deleteControlPointDialog(e: Event, point: ControlPointImpact) {
        this.closeActionsPopup(e);
        const { body } = this.textsPlumes.popupDelete;
        this.popupDeleteTexts.body = body.replace('%s', point.name);
        this.isDeletePopupOpen = true;
        this.popupProvider.setTemplate(this.popupDeleteOutlet);
        this.selectedPoint = point;
    }

    onScroll(e: Event) {
        this.closeActionsPopup(e);
    }
}
