<div (clickOutside)="close()" class="input_date_range">
    <shared-ui-basic-date-range [dateRange]="dateRange" (click)="show()"></shared-ui-basic-date-range>
    <calendar-months
        [show]="showCalendar"
        [timeBegin]="timeBegin"
        [timeEnd]="timeEnd"
        [applyCb]="updateTime"
        [monthsVisible]="displayMonth"
        [columns]="1"
        [dynamicWidth]="true"
        [numberOfMonths]="numberOfMonths"
    ></calendar-months>
</div>
