import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartConfiguration, Tick } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { isRU, TEXTS } from '@libs/common/texts/texts';
import { ALERT_COLORS } from '@libs/common/consts/alert-colors';
import { AQI } from '@libs/common/consts/substance.consts';
import { BarChartData } from './namespace';
import { AqiType } from '@libs/common/enums/aqi.type';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';
import {
    AQI_IN_COLORS,
    CHART_BAR_NAME,
} from '@libs/shared-ui/components/analytic/analytic.component';
import * as moment from 'moment-timezone';

@Component({
    selector: 'cityscreen-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.less'],
})
export class BarChartComponent implements OnInit, OnChanges {
    @Input() isDemo: boolean;
    @Input() data: ChartDataset<'bar', number[]>[];
    @Input() chartName: string;
    @Input() tooltipDescription: string;
    @Input() measure?: string = TEXTS.NAMES[AQI];

    TEXTS = TEXTS;

    noData = TEXTS.CITY_CARD.noData;
    chartOptions: BarChartData = null;
    chartsNameLocale = [
        TEXTS.CITY_CARD.charts[0],
        TEXTS.CITY_CARD.charts[1],
        TEXTS.CITY_CARD.charts[2],
    ];
    public total = 1;

    constructor(private numberFormatPipe: ValueFormatNumberPipe) {}

    ngOnInit(): void {
        if (this.data && this.chartName) {
            this.chartOptions = this.getOptionsByName(this.data, this.chartName);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data.currentValue && this.chartName) {
            this.chartOptions = this.getOptionsByName(this.data, this.chartName);
        }
        if (changes?.isDemo?.currentValue) {
            this.measure = changes.isDemo.currentValue
                ? TEXTS.NAMES[AqiType.aqiIn]
                : TEXTS.NAMES[AQI];
        }
    }

    getOptionsByName(data: ChartDataset<'bar', number[]>[], chartName: string): BarChartData {
        switch (chartName) {
            case CHART_BAR_NAME.allHistory:
                this.total = this.getTotalValue(data);
                return {
                    title: this.chartsNameLocale[0],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(0, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        datasets: data.map((d) => {
                            const backgroundColor = this.isDemo
                                ? AQI_IN_COLORS
                                : ALERT_COLORS.slice(1);
                            return {
                                ...d,
                                backgroundColor,
                                hoverBackgroundColor: backgroundColor,
                                borderRadius: 4,
                                borderColor: 'transparent',
                            };
                        }),
                        labels: this.isDemo
                            ? ['50', '100', '200', '300', '400', '500']
                            : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                        options: this.getChartOptions(CHART_BAR_NAME.allHistory),
                        legend: false,
                        type: 'bar',
                        plugins: [ChartDataLabels],
                    },
                };
            case CHART_BAR_NAME.weekDay:
                return {
                    title: this.chartsNameLocale[2],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(2, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        datasets: data.map((d) => {
                            const backgroundColor = this.isDemo
                                ? this.getChartColorAQIIn(data)
                                : this.getChartColor(data);
                            return {
                                ...d,
                                backgroundColor,
                                hoverBackgroundColor: backgroundColor,
                                borderRadius: 4,
                                borderColor: 'transparent',
                            };
                        }),
                        labels: this.getWeekShort(),
                        options: this.getChartOptions(chartName),
                        type: 'bar',
                        plugins: [],
                    },
                };
            case CHART_BAR_NAME.dayHour:
                return {
                    title: this.chartsNameLocale[1],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(1, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        datasets: data.map((d) => {
                            const backgroundColor = this.isDemo
                                ? this.getChartColorAQIIn(data)
                                : this.getChartColor(data);
                            return {
                                ...d,
                                backgroundColor,
                                hoverBackgroundColor: backgroundColor,
                                borderRadius: 3,
                                borderColor: 'transparent',
                            };
                        }),
                        labels: Array(24)
                            .fill(0)
                            .map((_, i) => `${i}:00`.padStart(5, '0')),
                        options: this.getChartOptions(chartName),
                        type: 'bar',
                        plugins: [],
                    },
                };
        }
    }

    private getChartColor(data: ChartDataset<'bar', number[]>[]): string[] {
        return data?.[0].data.map((value) => {
            const index = value ? Math.round(value) : 0;
            return ALERT_COLORS[index];
        });
    }

    private getWeekShort() {
        const result: string[] = [];

        for (let i = 1; i <= 7; i++) {
            const value = moment.weekdaysMin(i);
            if (isRU) {
                result.push(value.charAt(0).toUpperCase() + value.slice(1));
            } else {
                result.push(value);
            }
        }

        return result;
    }

    private getChartColorAQIIn(data: ChartDataset<'bar', number[]>[]): string[] {
        return data?.[0].data.map((value) => {
            let index = 0;
            if (value < 50) {
                index = 0;
            } else {
                index = Math.ceil(value / 100);
            }
            return AQI_IN_COLORS[index];
        });
    }

    private getChartOptions(chartName: CHART_BAR_NAME): ChartConfiguration['options'] {
        const total = this.total;
        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    ticks: {
                        color: '#ADB2BC',
                        font: {
                            size: 14,
                        },
                        maxRotation: 0,
                        maxTicksLimit: chartName == CHART_BAR_NAME.dayHour ? 5 : 30,
                    },
                    border: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: '#27282C',
                        font: {
                            family: 'Inter',
                            size: 14,
                            weight: 'bold',
                        },
                        precision: 0,
                        labelOffset: 5,
                        padding: 2,
                        callback: (value: number, _: number, ticks: Tick[]) =>
                            value === ticks[ticks.length - 1].value ? '' : ` ${value} `,
                    },
                    border: {
                        display: false,
                        dash: [3],
                    },
                    grid: {
                        display: chartName != CHART_BAR_NAME.dayHour,
                        color: '#FFFFFF',
                        drawOnChartArea: true,
                        z: 2,
                    },
                    display: chartName != CHART_BAR_NAME.allHistory,
                },
            },
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: chartName != CHART_BAR_NAME.allHistory,
                    borderWidth: 1,
                    titleColor: '#000',
                    bodyColor: '#000',
                    displayColors: true,
                    borderColor: ALERT_COLORS[1],
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    xAlign: 'center',
                    yAlign: 'top',
                    callbacks: {
                        label: (tooltipItem) => {
                            const value = Number(tooltipItem.formattedValue);
                            if (isNaN(value)) {
                                return `${this.measure}: ${tooltipItem.formattedValue.slice(0, 4)}`;
                            } else {
                                const formatValue = this.numberFormatPipe.transform(value, 2);
                                return `${this.measure}: ${formatValue}`;
                            }
                        },
                    },
                },
                datalabels: {
                    formatter: (value) => `${((value * 100) / total).toFixed(0)}%`,
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: 'bold',
                    },
                    anchor: 'end',
                    align: 'end',
                    offset: 0,
                },
            },
        };
    }

    private getTotalValue(data): number {
        if (data && data[0] && data[0].data.length) {
            return data[0].data.reduce((partial_sum, a) => partial_sum + a, 0);
        } else {
            return 1;
        }
    }
}
