import {
    AQI,
    CO,
    CO2,
    H2S,
    HUM,
    NH3,
    NO2,
    NOISELEVEL,
    O3,
    PM10,
    PM25,
    PRES,
    SO2,
    SUM,
    TEMP,
    TSP,
    WDA,
    WVA,
    WVVA,
} from '@libs/common/consts/substance.consts';

export const DEFAULT_METEO_VALUES_COLOR = '#A2C617';

export const METEO_VALUES_COLORS = {
    [AQI]: '#888',
    [PM25]: '#A2C617',
    [PM10]: '#A2C617',
    [TEMP]: '#FAC549',
    [HUM]: '#8BC3F3',
    [PRES]: '#F14F48',
    [CO]: '#30BF3A',
    [CO2]: '#A2C617',
    [NO2]: '#FF8700',
    [O3]: '#D780FF',
    [NH3]: '#7CB5EC',
    [SO2]: '#30BFA0',
    [H2S]: '#FF18DA',
    [SUM]: '#9CA2B4',
    [TSP]: '#6230BF',
    [WVVA]: '#072CC4',
    [WVA]: '#849CFF',
    [WDA]: '#2DDFDF',
    [NOISELEVEL]: '#A2C617',
};

export const TIMELINE_HAS_DATA_TRACK_COLOR = '#DDDDDD';
export const TIMELINE_TRACK_QUALITY_DATA_COLOR = '#F492B5';
export const LINE_DASH_STYLES = [[], [1, 1], [8, 3], [8, 3, 2, 3, 2, 3], [8, 3, 2, 3]];

export const DAY_OR_HOUR_BOUND = 24 * 60; // minutes in day

export const STEP_DURATION = 400;

export const CITY_OBJ_TYPE = 'city';

export const COUNTRY_OBJ_TYPE = 'country';

export const TICK_COLORS = {
    major: '#e6e6e6',
    minor: '#f2f2f2',
};

export const TICK_LABEL_COLORS = {
    major: '#666666',
    minor: 'transparent',
};
