import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NetworkActions, selectDomain } from '@cityair/modules/network/store/network.feature';
import { DATA_MODELING } from '@cityair/modules/network/constants';
import { getGridLineByBbox } from '@cityair/modules/network/utils';
import { selectAllPosts } from '@cityair/modules/core/store/posts/posts.feature';
import { selectMeasureScheme, selectMeasuresZones } from '@cityair/modules/core/store/selectors';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { NetworkMapData } from '@cityair/modules/network/service/api-models';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { getActionBasicError } from '@cityair/modules/core/store/effects';
import { BasicApi } from '@cityair/modules/core/services/api/basic-api';

@Injectable()
export class NetworkEffects {
    constructor(private actions$: Actions, private store: Store, private basicApi: BasicApi) {}

    updateRunDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            switchMap(() => [NetworkActions.setIsAvailable({ payload: true })])
        )
    );

    getDataModeling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(
                this.store.select(selectDomain),
                this.store.select(selectAllPosts),
                this.store.select(selectMeasuresZones),
                this.store.select(selectMeasureScheme)
            ),
            tap(() => this.store.dispatch(NetworkActions.setLoading({ payload: true }))),
            switchMap(([action, domain, posts, zones]) => {
                const actions = [];
                const scheme = MeasureScheme.default;
                const result = getGridLineByBbox(domain, DATA_MODELING, posts, zones[scheme]);
                const dataMap = result?.dataMap as NetworkMapData;
                actions.push(NetworkActions.setData({ payload: result }));
                actions.push(NetworkActions.setLoading({ payload: false }));
                return actions;
            })
        )
    );

    loadRegionGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, selectGroupId]) => !!selectGroupId),
            switchMap(([_, groupId]) =>
                this.basicApi.getAllRegion(groupId).pipe(
                    switchMap((res: any) => [NetworkActions.setRegionsApi({ payload: res?.data })]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    getAnalysisData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([action, groupId]) => groupId !== null),
            switchMap(([action, groupId]) =>
                this.basicApi.getNetworkAnalysis(groupId).pipe(
                    switchMap((response) => [
                        NetworkActions.setAnalysisData({ payload: response?.data }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                )
            )
        )
    );
}
