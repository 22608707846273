import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
} from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'report-measure-select-list',
    templateUrl: './measure-select-list.component.html',
    styleUrls: ['./measure-select-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureSelectListComponent implements OnInit {
    @Input() required: boolean;
    @Input() list: string[];
    @Input() multiSelect = true;
    @Input() mode: 'edit' | 'display';
    @Input() selected: string[];
    @Input() available: string[];
    @Output() setSelected = new EventEmitter<string[]>();

    public labelText = TEXTS.REPORTS.keyParams.measure;
    public noDataText = TEXTS.REPORTS.reportDetails.noData;
    public allText = TEXTS.NOTIFICATIONS.all;
    public isAll = false;
    public NAMES = TEXTS.NAMES;
    constructor() {}

    ngOnInit(): void {
        this.isAll = this.selected?.length === this.list?.length && this.multiSelect;
        if (this.list?.length === 1 || !this.list) {
            this.mode = 'display';
            this.labelText = this.list?.length === 1 ? TEXTS.REPORTS.measure : TEXTS.REPORTS.keyParams.measure;
        }
    }

    public isSelected(value) {
        return this.selected?.includes(value);
    }

    public notAvailable(value) {
        if (!this.available || this.available?.length === 0) {
            return true;
        }
        return this.available.indexOf(value) >= 0 ? false : true;
    }
    public selectAllValues() {
        if (this.isAll) {
            return;
        }
        this.isAll = true;
        this.selected = [...this.list];
        this.setSelected.emit(this.selected);
    }

    public toggleValue(value) {
        if (this.mode === 'display' || this.notAvailable(value)) {
            return;
        }
        this.isAll = false;
        const isSelected = this.isSelected(value);
        if (this.multiSelect) {
            if (!isSelected) {
                this.selected.push(value);
            } else {
                this.selected = this.selected.filter((v) => v !== value);
            }
        } else {
            this.selected = [value];
        }

        this.setSelected.emit(this.selected);
    }
}
