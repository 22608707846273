import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsPage } from './components/analytics-page/analytics-page.component';
import { HeaderOfPanelModule } from '@libs/shared-ui/components/header-of-panel/header-of-panel.module';
import { AnalyticModule } from '@libs/shared-ui/components/analytic/analytic.module';

@NgModule({
    imports: [CommonModule, HeaderOfPanelModule, AnalyticModule],
    exports: [AnalyticsPage],
    declarations: [AnalyticsPage],
})
export class AnalyticsModule {}
