import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'shared-ui-info-correlation-popup',
    templateUrl: './info-correlation-popup.component.html',
    styleUrls: ['./info-correlation-popup.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCorrelationPopupComponent {
    @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
    public text = TEXTS.ANALYSIS.correlation;
    tooltipText = '';
    public close() {
        this.closePopup.emit(true);
    }
}
