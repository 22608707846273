import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartDataset, Tick } from 'chart.js';
import { COLORS, COLORS_HOVER } from '@cityair/modules/analysis-events/constants';
import { TEXTS } from '@libs/common/texts/texts';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

@Component({
    selector: 'cityair-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements OnChanges {
    @Input() mmt: string;
    @Input() data: ChartDataset<'bar', number[]>[];
    @Input() labelsData: string[];
    @Input() formatValue: string;
    @Input() activeIndex = null;

    constructor(private numberFormatPipe: ValueFormatNumberPipe) {}

    options: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        layout: {
            padding: {
                top: 20,
                right: 20,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#9B9B9B',
                    font: {
                        size: 13,
                    },
                    maxRotation: 0,
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: '#27282C',
                    font: {
                        family: 'Inter',
                        size: 13.2,
                    },
                    precision: 0,
                    labelOffset: 5,
                    padding: 2,
                    callback: (value: number, _: number, ticks: Tick[]) =>
                        ` ${value}${this.formatValue} `,
                },
                border: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: '#E3E4E8',
                    drawOnChartArea: true,
                },
                display: true,
            },
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                borderWidth: 1,
                titleColor: '#000',
                bodyColor: '#000',
                displayColors: true,
                borderColor: COLORS[1],
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                xAlign: 'center',
                yAlign: 'top',
                callbacks: {
                    label: (tooltipItem) => {
                        const value = Number(tooltipItem.formattedValue);
                        if (isNaN(value)) {
                            return `${
                                tooltipItem.dataset.label
                            }: ${tooltipItem.formattedValue.slice(0, 4)}${this.formatValue}`;
                        } else {
                            const formatValue = this.numberFormatPipe.transform(value, 2);
                            return `${tooltipItem.dataset.label}: ${formatValue}${this.formatValue}`;
                        }
                    },
                },
            },
            datalabels: {
                font: {
                    size: 12,
                    family: 'Inter',
                    weight: 'bold',
                },
                anchor: 'end',
                align: 'end',
                offset: 0,
            },
        },
    };
    type: ChartConfiguration['type'] = 'bar';
    plugins: ChartConfiguration['plugins'];
    labels: ChartConfiguration['data']['labels'] = [];
    datasets: ChartConfiguration['data']['datasets'] = [];
    noDataText = TEXTS.ANALYSIS_EVENTS.analysisEvent.barChartNoDataText;
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.data?.currentValue) {
            this.prepareData();
        }
        if (changes?.labelsData?.currentValue) {
            this.labels = this.labelsData;
        }
        if (changes?.activeIndex) {
            if (changes?.activeIndex?.currentValue !== undefined && this.data?.length) {
                this.prepareData();
            }
        }
    }

    private prepareData() {
        const datasets: ChartConfiguration['data']['datasets'] = [];
        this.data.forEach((d, i) => {
            datasets.push({
                ...d,
                backgroundColor:
                    this.activeIndex === null || this.activeIndex === i
                        ? COLORS[i]
                        : COLORS_HOVER[i],
                hoverBackgroundColor:
                    this.activeIndex === null || this.activeIndex === i
                        ? COLORS[i]
                        : COLORS_HOVER[i],
                borderRadius: 2,
                borderColor: 'transparent',
            });
        });
        this.datasets = datasets;
    }
}
