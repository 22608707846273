import { AdminDevice, MonitoringObject } from '@cityair/namespace';

export enum NETWORK_PAGES {
    networks = 'networks',
    postsList = 'posts',
    devicesList = 'devices',
    postDescription = 'post-details',
    postAndDevicesDescription = 'posts-and-devices-description',
    postEdit = 'edit',
    postCreate = 'create',
    postReport = 'report',
    postReportTza = 'report-tza',
    deviceDescription = 'device-details',
    details = 'details',
    deviceReport = 'report-primary-data',
}

export class PATH {
    name: string;
    path: string;
}

export class CreateOM extends MonitoringObject {
    locationId: number;

    constructor() {
        super();

        this.id = null;
        this.name = null;
        this.description = null;
        this.isOffline = null;
        this.gmtOffset = -new Date().getTimezoneOffset() / 60;
        this.tzOffset = null; /* в минкутах */
        this.geoLatitude = null;
        this.geoLongitude = null;
        this.pubName = null;
        this.lastPacketId = null;
        this.regionCoef = null;
        this.locationId = null;

        this.users = null;
        this.devicesId = null;
        this.devicesObj = null;
        this.devicesSerialNum = null;

        this.originChartData = null;
        this.measuresVal = null;
    }
}

export class CreateDevice extends AdminDevice {
    constructor() {
        super();

        this.id = null;
        this.serialNumber = '';
        this.model = null;
        this.sourceId = null;
        this.sourceName = null;
        this.name = null;
        this.startWork = null;
        this.serviceDate = null;
        this.intervalSec = null;
        // isNotSaveData: boolean;
        this.battery = null;
        this.v220 = null;
        this.offline = null;
        this.soft = null;
        this.hardware = null;
        this.linksToMo = null;
        this.namesMo = [];
        this.lastTime = null;
        this.geoLatitude = null;
        this.geoLongitude = null;
        this.childDevices = null;
    }
}
