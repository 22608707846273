import {
    AnalysisEvent,
    AnalysisEventStatus,
    AnalysisEventStatusByEnd,
    AnalysisEventType,
    BaseNotificationEvent,
    EventStatus,
    GroupEvent,
    IAnalysisEvent,
    Post,
} from '@libs/common/models/basicModels';
import * as moment from 'moment-timezone';
import {
    FORECAST_ADD_HOURS,
    POST_NUMBERS_FOR_GLOBAL_EVENTS,
    TEST_NOW,
} from '@cityair/modules/analysis-events/constants';
import { IntervalEnum } from '@cityair/namespace';
import { formatDuration } from '@libs/common/utils/date-formats';

export function getCorrelationsForPosts(posts: Post[], data): number[] {
    if (posts.length && data) {
        const result = [];
        const id = posts[0]?.id ?? null;
        if (id) {
            posts.forEach((post) => {
                if (post.id !== id) {
                    const value = data[post.id] ?? null;
                    result.push(value);
                }
            });
        }
        return result;
    }
    return [];
}
export function getEndTimeForAnalysisByEvent(event: BaseNotificationEvent): string {
    if (event.status === EventStatus.End) {
        return event.end_time;
    } else {
        const lastTime = event.update_time ?? event.begin_time;
        return moment(lastTime).add(FORECAST_ADD_HOURS, 'hours').toISOString();
    }
}
export const STATUS_ORDER = [
    AnalysisEventStatus.new,
    AnalysisEventStatus.wait,
    AnalysisEventStatus.accept,
    AnalysisEventStatus.cancel,
];
export const STATUS_ORDER_BY_END = [AnalysisEventStatusByEnd.new, AnalysisEventStatusByEnd.end];
export function getUniqueEventsByPost(events: GroupEvent[]) {
    const result = [];
    const uniqueIds = [];
    events?.forEach((event) => {
        if (uniqueIds.indexOf(event.post_id) === -1) {
            uniqueIds.push(event.post_id);
            result.push(event);
        }
    });
    return result;
}

export function prepareEventsAnalysis(
    { events, id, start, end, forecast_end, param, pdk_value, status, type, verify }: AnalysisEvent,
    isDemoMode
): IAnalysisEvent {
    const uniqueEvents = getUniqueEventsByPost(events);
    const currentStatus =
        status ?? (end === null ? AnalysisEventStatus.new : AnalysisEventStatus.accept);
    const endTime = end === null ? (isDemoMode ? TEST_NOW : moment().toISOString()) : end;
    const forecast_end_time =
        end === null && !isDemoMode
            ? forecast_end
                ? forecast_end
                : moment().add(FORECAST_ADD_HOURS, 'hours').toISOString()
            : id === '21'
            ? '2023-05-09T23:00:00Z'
            : null;
    const diffValue = moment(endTime).diff(start);
    const eventTypeByEnd =
        end === null ? AnalysisEventStatusByEnd.new : AnalysisEventStatusByEnd.end;
    const result: IAnalysisEvent = {
        events,
        id,
        start,
        end,
        pdk_value,
        statusDuration: end !== null ? EventStatus.End : EventStatus.Begin,
        type:
            type ?? uniqueEvents.length >= POST_NUMBERS_FOR_GLOBAL_EVENTS
                ? AnalysisEventType.global
                : AnalysisEventType.local,
        verify: verify ?? 85,
        duration: formatDuration(moment.duration(diffValue)),
        duration_ms: diffValue.valueOf(),
        start_ms: moment(start).valueOf(),
        end_ms: endTime
            ? moment(endTime).valueOf()
            : forecast_end === null
            ? 0
            : moment(forecast_end_time).valueOf(),
        forecast_end,
        forecast_end_time,
        param,
        status: currentStatus,
        status_rank: isDemoMode
            ? STATUS_ORDER.findIndex((v) => v === currentStatus)
            : STATUS_ORDER_BY_END.findIndex((v) => v === eventTypeByEnd),
        uniqueEvents,
        eventTypeByEnd,
    };

    return result;
}

export function getListMmt(list: IAnalysisEvent[]): string[] {
    const result = [];
    list?.forEach((item) => {
        if (result.indexOf(item.param) === -1) {
            result.push(item.param);
        }
    });
    return result;
}
