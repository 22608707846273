import { createAction, props } from '@ngrx/store';
import { Station } from '../services/station/models';
import { DateRange } from './reducers';
import { RunPlume, RunResponseById } from '../services/run/models';
import { ControlPoint, ControlPointsResponse } from '../services/control-point/models';
import { HttpErrorResponse } from '@angular/common/http';
import { Dictionary } from '@ngrx/entity';
import { Feature } from '@libs/shared-ui/components/timeline-panel/models/core';

export enum PlumesActions {
    toggleModule = '[Plumes] Toggle module',
    toggleLayerOnMap = '[Plumes] Toggle layer on map',
    setConfigForStationChart = '[Plumes] Set config for station chart',
    setPlumesMeasurement = '[Plumes] Set plumes measurement',
    onStationDataLoadFailed = '[Plumes] Station data load failed',
    setActiveStation = '[Plumes] Set active station',
    setActiveRun = '[Plumes] Set active run',
    setActivePlumesControlPoint = '[Plumes] set control point',
    changeCurrentMmt = '[Plumes] change current mmt',
    updateTimeIndex = '[Plumes] Update current timeseries position',
    updateDateRange = '[Plumes] Update current date range',
    updateDateRangeRun = '[Plumes] Update current date range run',
    datesUpdate = '[Plumes] Update timeseries dates',
    updateAllData = '[Plumes] Update all data',
    updatePlumesRuns = '[Plumes] Update plumes runs',
    authError = '[Plumes] Authentication error',
    setRunsLoadError = '[Plumes] Runs load error',
    disabledMapNavigation = '[Plumes] Disabled map navigation',
    updateEvaluationTime = '[Plumes] Update evaluation time',
    setRunLoading = '[Plumes] loading run',
    setControlPointData = '[Plumes] set control points data',
    setControlPointError = '[Plumes] set controlPoint error',
    setActiveRunDetail = '[Plumes] set active run detail',
    setRunConfigUpdateError = '[Plumes] set error update run config',
    setRunConfigByIdError = '[Plumes] error get run config by id',
    setEditControlPoint = '[Plumes] edit control point',
    setNewControlPoint = '[Plumes] set marker for new control point',
    setCoordinates = '[Plumes] set coordinates',
    updateControlPoint = '[Plumes] update control point',
    setChartLoading = '[Plumes] set chart data loading',
    setHeight = '[Plumes] set height',
    setChartData = '[Plumes] set chart data',
    setControlPointLoading = '[Plumes] set control points loading',
    toggleWindLayer = '[Plumes] toggle windLayer',
    isDisabledWindButton = '[Plumes] is disabled demo wind button',
    updateRunsByInterval = '[Plumes] update runs by interval',
    setExpandControlPoint = '[Plumes] set expand control point',
    setSourcesSnapShot = '[Plumes] set sources snapshot',
    toggleDisplayPostOnMap = '[Plumes] toggle display post on Map',
    setFilterForRuns = '[Plumes] set filter for runs',
    clickControlPointsOnMap = '[Plumes] click control points on map',
    setSourcesOrder = '[Plumes] set souces order',
    setActiveControlPointById = '[Plumes] set active control point by id',
    setActiveStationById = '[Plumes] set active station by id',
}

export const toggleModule = createAction(PlumesActions.toggleModule, props<{ payload: boolean }>());

export const toggleLayerOnMap = createAction(
    PlumesActions.toggleLayerOnMap,
    props<{ payload: boolean }>()
);

export const setPlumesMeasurement = createAction(
    PlumesActions.setPlumesMeasurement,
    props<{ payload: string }>()
);

export const onStationDataLoadFailed = createAction(
    PlumesActions.onStationDataLoadFailed,
    props<{ payload: any }>()
);

export const setActiveStation = createAction(
    PlumesActions.setActiveStation,
    props<{ payload: Station }>()
);
export const setActivePlumesControlPoint = createAction(
    PlumesActions.setActivePlumesControlPoint,
    props<{ payload: ControlPoint }>()
);
export const setActiveRunDetail = createAction(
    PlumesActions.setActiveRunDetail,
    props<{ payload: RunResponseById }>()
);
export const setActiveRun = createAction(
    PlumesActions.setActiveRun,
    props<{ payload: RunPlume }>()
);
export const setHeight = createAction(PlumesActions.setHeight, props<{ payload: number }>());

export const updateTimeIndex = createAction(
    PlumesActions.updateTimeIndex,
    props<{ payload: number }>()
);

export const updateDateRange = createAction(PlumesActions.updateDateRange, props<DateRange>());
export const updateDateRangeRun = createAction(
    PlumesActions.updateDateRangeRun,
    props<{ payload: DateRange }>()
);

export const datesUpdate = createAction(PlumesActions.datesUpdate, props<{ payload: string[] }>());

export const updateAllData = createAction(PlumesActions.updateAllData);

export const updatePlumesRuns = createAction(
    PlumesActions.updatePlumesRuns,
    props<{ payload: Record<string, string | string[]> }>()
);

export const authError = createAction(PlumesActions.authError, props<{ payload: boolean }>());

export const setRunsLoadError = createAction(
    PlumesActions.setRunsLoadError,
    props<{ payload: boolean }>()
);

export const disabledMapNavigation = createAction(
    PlumesActions.disabledMapNavigation,
    props<{ payload: boolean }>()
);
export const setRunLoading = createAction(
    PlumesActions.setRunLoading,
    props<{ payload: boolean }>()
);
export const setControlPointData = createAction(
    PlumesActions.setControlPointData,
    props<{ payload: ControlPointsResponse; controlPoints: Dictionary<ControlPoint> }>()
);
export const setControlPointError = createAction(
    PlumesActions.setControlPointError,
    props<{ payload: HttpErrorResponse }>()
);
export const setRunConfigUpdateError = createAction(
    PlumesActions.setRunConfigUpdateError,
    props<{ payload: HttpErrorResponse }>()
);
export const setRunConfigByIdError = createAction(
    PlumesActions.setRunConfigByIdError,
    props<{ payload: HttpErrorResponse }>()
);
export const setEditControlPoint = createAction(
    PlumesActions.setEditControlPoint,
    props<{ payload: ControlPoint }>()
);
export const setNewControlPoint = createAction(
    PlumesActions.setNewControlPoint,
    props<{ payload: ControlPoint }>()
);
export const setCoordinates = createAction(
    PlumesActions.setCoordinates,
    props<{ payload: { lat: number; lon: number } }>()
);
export const updateControlPoint = createAction(
    PlumesActions.updateControlPoint,
    props<{ payload: ControlPoint; field: string }>()
);
export const setChartLoading = createAction(
    PlumesActions.setChartLoading,
    props<{ payload: boolean }>()
);
export const changeCurrentMmt = createAction(
    PlumesActions.changeCurrentMmt,
    props<{ payload: string }>()
);
export const setChartData = createAction(
    PlumesActions.setChartData,
    props<{ payload: Feature[] }>()
);
export const setControlPointLoading = createAction(
    PlumesActions.setControlPointLoading,
    props<{ payload: boolean }>()
);
export const setConfigForStationChart = createAction(
    PlumesActions.setConfigForStationChart,
    props<{ payload: boolean }>()
);
export const toggleWindLayer = createAction(
    PlumesActions.toggleWindLayer,
    props<{ payload: boolean }>()
);
export const isDisabledWindButton = createAction(
    PlumesActions.isDisabledWindButton,
    props<{ payload: boolean }>()
);
export const updateRunsByInterval = createAction(PlumesActions.updateRunsByInterval);
export const setExpandControlPoint = createAction(
    PlumesActions.setExpandControlPoint,
    props<{ payload: string }>()
);
export const setSourcesSnapShot = createAction(
    PlumesActions.setSourcesSnapShot,
    props<{ payload: RunPlume[] }>()
);
export const toggleDisplayPostOnMap = createAction(
    PlumesActions.toggleDisplayPostOnMap,
    props<{ payload: boolean }>()
);
export const setFilterForRuns = createAction(
    PlumesActions.setFilterForRuns,
    props<{ payload: number }>()
);
export const clickControlPointsOnMap = createAction(
    PlumesActions.clickControlPointsOnMap,
    props<{ payload: ControlPoint }>()
);
export const setSourcesOrder = createAction(
    PlumesActions.setSourcesOrder,
    props<{ payload: number[] }>()
);
export const setActiveControlPointById = createAction(
    PlumesActions.setActiveControlPointById,
    props<{ payload: string }>()
);
export const setActiveStationById = createAction(
    PlumesActions.setActiveStationById,
    props<{ payload: string }>()
);
