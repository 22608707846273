<div class="keys-wrapper">
    <div class="row">
        <div class="button-wrapper">
            <button-add  [text]="textSettings.create" (action)="showPopup(null, 'create')"></button-add>
        </div>
        <div class="vert-line"></div>
        <div class="search-wrapper">
            <search-input-basic
                #searchInput
                [textPlaceholderInner]="textSettings.searchToken"
                [textInner]="searchQuery"
                [debounceTimeInner]="300"
                (textChangeInner)="textChangeIn($event)"
            ></search-input-basic>
        </div>
    </div>
    <div class="content" *ngIf="(store.select(selectUserApiKeys) |async) as apiKyes">
        <ng-container *ngIf="apiKyes | multipleSearchfilter : ['Title'] : searchQuery as result">
            <ng-container *ngIf="!result.length else listTemplate">
                <ng-container *ngIf="searchQuery; else noPosts">
                    <div class="no-data-wrapper">
                        <search-result-placeholder [clearText]="textClear" (clear)="searchQuery = ''"></search-result-placeholder>
                    </div>
                </ng-container>
                <ng-template #noPosts>
                    <div class="no-data-wrapper">
                        <non-data-screen
                            [text]="textSettings.noFoundToken"
                        ></non-data-screen>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #listTemplate>
                <div class="list scroll" #contentWrapper>
                    <div class="table-header">
                        <div class="name">
                            <shared-sort-list-item
                                (click)="setSortingCb('Title')"
                                [sortingDirection]="sortField === 'Title' ? sortDirection : 0"
                                [text]="textSettings.tokenTableHeader.name"
                            ></shared-sort-list-item>
                        </div>
                        <div class="key">
                            {{textSettings.tokenTableHeader.key}}
                        </div>
                        <div class="create-date">
                            <shared-sort-list-item
                                (click)="setSortingCb('CreateDate')"
                                [sortingDirection]="sortField === 'CreateDate' ? sortDirection : 0"
                                [text]="textSettings.tokenTableHeader.createDate"
                            ></shared-sort-list-item>
                        </div>
                        <div class="last-date">
                            <shared-sort-list-item
                                (click)="setSortingCb('LastDate')"
                                [sortingDirection]="sortField === 'LastDate' ? sortDirection : 0"
                                [text]="textSettings.tokenTableHeader.lastDate"
                            ></shared-sort-list-item>
                        </div>
                        <div class="count">
                            <shared-sort-list-item
                                (click)="setSortingCb('ExecCount')"
                                [sortingDirection]="sortField === 'ExecCount' ? sortDirection : 0"
                                [text]="textSettings.tokenTableHeader.count"
                            ></shared-sort-list-item>
                        </div>
                        <div class="actions"></div>
                    </div>
                    <div class="item-wrapper"
                         *ngFor="let token of result | orderBy : sortField : sortDirection"
                    >
                        <div class="name">
                            {{token.Title}}
                        </div>
                        <div class="key">
                            <div class="key-wrapper">
                                <div class="hidden-key ellipsis">{{showTokenValue[token.TokenId] || tokenPlaceholder}}</div>
                                <div  class="copy_token" (click)="copyToken(token)"
                                >
                                    {{textSettings.copy}}
                                </div>
                            </div>
                        </div>
                        <div class="create-date">{{token.CreateDate | shortDateFormat }}</div>
                        <div class="last-date">{{token.CreateDate | shortDateFormat }}</div>
                        <div class="count">{{token.ExecCount }}</div>
                        <div class="actions">
                            <kebab-menu (clickActions)="openActions($event, token)"></kebab-menu>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
<ng-template #tokenMenu>
    <card-actions-list
        *ngIf="isShowMenu"
        [position]="menuPositionTop"
        [positionLeft]="menuPositionLeft"
        (clickOutside)="closePopup($event)"
    >
        <card-actions-item
            data-cy="action-rename"
            [text]="textSettings.rename"
            (click)="showPopup($event, 'rename')"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
            data-cy="action-close"
            type="danger"
            [text]="textSettings.close"
            (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>

<ng-template #popupMenu>
    <stnd-ask-popup2
        *ngIf="popup"
        [texts]="{
            title:  popup === 'close' ? getDescription() :  configPopup[popup].title,
            accept: configPopup[popup].apply,
            body: ''
        }"
        [disableAccept]="(popup === 'create' || popup === 'rename') && keyControl.controls.name.invalid"
        (cancel)="popup = ''"
        (accept)="applyPopup()"
        [isDangerAccept]="popup === 'close'"
    >
        <div *ngIf="popup === 'create' || popup === 'rename'" style="margin-bottom: 70px;">
            <ca-input
                type="text"
                controlName="name"
                [form]="keyControl"
                [valueForm]="getValueForm('name')"
                [label]="textSettings.createKeyLabel"
                [textError]="getError('name')"
            ></ca-input>
        </div>
    </stnd-ask-popup2>
</ng-template>
