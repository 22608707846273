import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Group } from '@libs/common/types/group';

@Component({
    selector: 'shared-ui-group-change-selector',
    templateUrl: './group-change-selector.component.html',
    styleUrls: ['./group-change-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupChangeSelectorComponent implements OnChanges {
    @Input() groupList: any[];
    @Input() currentGroupId: string;
    @Input() withProfile: boolean;
    @Output()
    selectGroup = new EventEmitter<string>();
    @Output() selectModule = new EventEmitter<any>();
    public show = false;
    public group: Group;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentGroupId?.currentValue && this.groupList) {
            this.group = this.groupList.find((group) => group.id === this.currentGroupId);
        }
    }

    changeGroup(groupId: string) {
        this.selectGroup.emit(groupId);
    }
}
