<div class="impact-sources scroll">
    <div class="sources-table">
        <div class="sources-table__body">
            <div 
                *ngFor="let source of sources$ | async" 
                class="source-item"
                (click)="goToSource(source)"
            >
                <div class="source-item__content">
                    <div class="source-item__title">{{ source.name }}</div>
                    <div class="source-item__params">
                        <div class="source-item__param">
                            <div class="source-item__param-title">Интенсивность выброса</div>
                            <div class="source-item__param-value">{{ source.emissionIntensity }} г/с</div>
                        </div>
                        <div class="source-item__param">
                            <div class="source-item__param-title">Тип</div>
                            <div class="source-item__param-value">{{ source.sourceType }}</div>
                        </div>
                    </div>
                </div>
                <div 
                    class="source-item__image"
                    [attr.data-source-id]="source.id"
                ></div>
            </div>
        </div>
    </div>
</div>
