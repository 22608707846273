import {
    createFeature,
    createReducer,
    createSelector,
    createActionGroup,
    props,
    on,
    emptyProps,
    createFeatureSelector,
} from '@ngrx/store';
import type { BBox } from 'geojson';

import {
    DateRangeNetwork,
    NetworkData,
    NetworkDataModeling,
    NetworkInfoPinData,
    NetworkMapData,
    NetworkPostData,
    NetworkPriorityData,
} from '@cityair/modules/network/service/api-models';
import {
    INIT_NETWORK_DATE,
    DOMAIN_BBOX,
    DEFAULT_POST_COLOR,
} from '@cityair/modules/network/constants';
import { selectMeasuresZones } from '@cityair/modules/core/store/selectors';
import { CO, NO2, PM25 } from '@libs/common/consts/substance.consts';
import { ColorZone } from '@libs/common/types/color-zone';
import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { getColorFromZone } from '@cityair/utils/utils';
import { NetworkAnalysisData, RegionApi } from '@libs/common/models/basicModels';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

const NETWORK_FEATURE_KEY = 'network';

export const regionAdapter: EntityAdapter<RegionApi> = createEntityAdapter<RegionApi>();

export const NetworkActions = createActionGroup({
    source: 'Network',
    events: {
        'Init Module': emptyProps(),
        'Set Is Available': props<{ payload: boolean }>(),
        'Toggle Layer Map': props<{ payload: boolean }>(),
        'Set Loading': props<{ payload: boolean }>(),
        'Update Date Range': props<{ payload: DateRangeNetwork }>(),
        'Set Active Mmt': props<{ payload: string }>(),
        'Set Time Index': props<{ payload: number }>(),
        'Set Data Modeling': props<{ payload: NetworkDataModeling }>(),
        'Set Data': props<{ payload: NetworkData }>(),
        'Set Analysis Data': props<{ payload: NetworkAnalysisData }>(),
        'Set Regions Api': props<{ payload: RegionApi[] }>(),
    },
});
interface NetworkState {
    isActiveNetwork: boolean;
    domain: BBox;
    isLoading: boolean;
    showLayerOnMap: boolean;
    dateRange: DateRangeNetwork;
    networkTimeIndex: number;
    allMmts: string[];
    activeMmt: string;
    activePost: string;
    hoverPost: string;
    dataModeling: NetworkDataModeling;
    dataMap: NetworkMapData;
    priorityData: NetworkPriorityData;
    postData: NetworkPostData;
    infoPinData: NetworkInfoPinData;
    analysisData: NetworkAnalysisData;
    regionsApi: EntityState<RegionApi>;
}
const initialState: NetworkState = {
    isActiveNetwork: false,
    domain: DOMAIN_BBOX,
    isLoading: true,
    showLayerOnMap: true,
    dateRange: INIT_NETWORK_DATE,
    networkTimeIndex: 0,
    allMmts: [PM25, CO, NO2],
    activeMmt: PM25,
    activePost: null,
    hoverPost: null,
    dataModeling: null,
    dataMap: null,
    priorityData: null,
    postData: null,
    infoPinData: null,
    analysisData: null,
    regionsApi: regionAdapter.getInitialState(),
};

export const networkFeature = createFeature({
    name: NETWORK_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(NetworkActions.setIsAvailable, (state, { payload }) => ({
            ...state,
            isActiveNetwork: payload,
        })),
        on(NetworkActions.toggleLayerMap, (state, { payload }) => ({
            ...state,
            showLayerOnMap: payload === null ? !state.showLayerOnMap : payload,
        })),
        on(NetworkActions.setLoading, (state, { payload }) => ({
            ...state,
            isLoading: payload,
        })),
        on(NetworkActions.setActiveMmt, (state, { payload }) => ({
            ...state,
            activeMmt: payload,
        })),
        on(NetworkActions.setData, (state, { payload }) => ({
            ...state,
            dataMap: payload.dataMap,
            priorityData: payload.priorityData,
            postData: payload.postData,
            infoPinData: payload.infoData,
        })),
        on(NetworkActions.setAnalysisData, (state, { payload }) => ({
            ...state,
            analysisData: payload,
        })),
        on(NetworkActions.setRegionsApi, (state, { payload }) => {
            const regions = payload as RegionApi[];
            const regionsApi = regionAdapter.setMany(regions, state.regionsApi);
            return {
                ...state,
                regionsApi,
            };
        })
    ),
    extraSelectors: ({
        selectActiveMmt,
        selectIsActiveNetwork,
        selectShowLayerOnMap,
        selectActivePost,
        selectDataMap,
        selectPriorityData,
        selectPostData,
        selectInfoPinData,
    }) => ({
        selectNetworkSchemaZones: createSelector(
            selectActiveMmt,
            selectMeasuresZones,
            (currentMmt, zones) => {
                const scheme = MeasureScheme.default;
                if (zones && currentMmt && zones[scheme][currentMmt]) {
                    return {
                        scheme: scheme,
                        mmt: currentMmt,
                        zone: zones[scheme][currentMmt] as ColorZone,
                    };
                }
                return null;
            }
        ),
        selectIsShowNetworkGridLayer: createSelector(
            selectIsActiveNetwork,
            selectShowLayerOnMap,
            (isActive, isShow) => isActive && isShow
        ),
        selectPolygons: createSelector(
            selectActiveMmt,
            selectDataMap,
            (mmt, data) => data?.[mmt] ?? null
        ),
        selectPriorityDataByMmt: createSelector(
            selectActiveMmt,
            selectPriorityData,
            (mmt, data) => data?.[mmt] ?? null
        ),
        selectPostDataByMmt: createSelector(
            selectActiveMmt,
            selectPostData,
            (mmt, data) => data?.[mmt] ?? null
        ),
        selectInfoPins: createSelector(
            selectActiveMmt,
            selectInfoPinData,
            (mmt, data) => data?.[mmt] ?? null
        ),
    }),
});
const networkSelector = createFeatureSelector<NetworkState>(NETWORK_FEATURE_KEY);

export const selectRegions = createSelector(networkSelector, (state) =>
    regionAdapter.getSelectors().selectAll(state.regionsApi)
);
export const selectRegionsDic = createSelector(networkSelector, (state) =>
    regionAdapter.getSelectors().selectEntities(state.regionsApi)
);
export const selectRegionByPostId = (id: string) =>
    createSelector(selectRegions, (regions) => {
        let result = null;
        regions.forEach((region) => {
            if (region.post_ids.includes(id)) {
                result = region;
            }
        });
        return result;
    });
export const {
    selectDomain,
    selectIsActiveNetwork,
    selectPolygons,
    selectAllMmts,
    selectActiveMmt,
    selectNetworkSchemaZones,
    selectDateRange,
    selectShowLayerOnMap,
    selectIsShowNetworkGridLayer,
    selectActivePost,
    selectHoverPost,
    selectDataModeling,
    selectPostData,
    selectPriorityDataByMmt,
    selectPostDataByMmt,
    selectInfoPins,
    selectAnalysisData,
} = networkFeature;
export const selectColorByPostId = (id) =>
    createSelector(selectPostDataByMmt, selectNetworkSchemaZones, (data, zones) => {
        const currentPost = data?.find((v) => v.id === id);
        if (currentPost && zones) {
            return getColorFromZone(zones?.zone, currentPost.value);
        }
        return DEFAULT_POST_COLOR;
    });
