import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { TabModel } from '@libs/common/types/tab-model';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { TEXTS } from '@libs/common/texts/texts';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { initAnalysisModule, setPostDistance } from '@cityair/modules/analysis/store/actions';
import { getDistanceAllPosts } from '@cityair/modules/analysis/utils';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
    selectAllPostsAnalysis,
    selectCurrentTab,
    selectDataForRouting,
} from '@cityair/modules/analysis/store/selectors';
import { Subject } from 'rxjs';
import { CorrelationAnalysisData, CorrelationPeriod, Post } from '@libs/common/models/basicModels';
interface RoutingData {
    activePost: Post;
    activePair: CorrelationAnalysisData;
    mmt: string;
    period: CorrelationPeriod;
}
@Component({
    selector: 'cityair-analysis-main',
    templateUrl: './analysis-main.component.html',
    styleUrls: ['./analysis-main.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisMainComponent implements OnDestroy {
    textAnalysis = TEXTS.ANALYSIS;
    pages = ANALYSIS_PAGES;
    readonly mainComponentUrl = `/${ANALYSIS_PAGES.analysis}/`;
    public ngDestroyed$ = new Subject<void>();
    private routingData;
    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: ANALYSIS_PAGES.summary,
            title: this.textAnalysis.pagesName[ANALYSIS_PAGES.summary],
        },
        {
            isDisabled: false,
            type: 2,
            id: ANALYSIS_PAGES.posts,
            title: this.textAnalysis.pagesName[ANALYSIS_PAGES.posts],
        },
        {
            isDisabled: false,
            type: 3,
            id: ANALYSIS_PAGES.links,
            title: this.textAnalysis.pagesName[ANALYSIS_PAGES.links],
        },
    ];
    selectedTab: TabModel = this.tabs[0];
    constructor(private router: Router, private store: Store) {
        store
            .select(selectAllPostsAnalysis)
            .pipe(
                filter((v) => !!v.length),
                takeUntil(this.ngDestroyed$)
            )
            .subscribe((resp) => {
                this.store.dispatch(initAnalysisModule());
                const distance = getDistanceAllPosts(resp);
                this.store.dispatch(setPostDistance({ payload: distance }));
            });
        store
            .select(selectCurrentTab)
            .pipe(take(1))
            .subscribe((tab) => {
                if (this.router.url === `/${ANALYSIS_PAGES.analysis}`) {
                    if (!tab) {
                        this.openPage({ id: ANALYSIS_PAGES.summary });
                    } else {
                        this.openPage({ id: tab });
                    }
                }
            });
        store
            .select(selectDataForRouting)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.routingData = data;
            });
    }

    getActiveTab() {
        let tabId = this.router.url
            ? this.router.url.replace(this.mainComponentUrl, '')
            : this.selectedTab;
        const path = tabId.toString();
        if (path.indexOf('/') >= 0) {
            tabId = path.split('/')[0];
        }
        return this.tabs.find((item) => item.id === tabId);
    }

    openPage(event) {
        if (this.router.url.indexOf(event?.id) >= 0) {
            return;
        }
        if (event.id === ANALYSIS_PAGES.posts && this.routingData?.activePost) {
            this.router.navigate([
                `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}/${this.routingData.activePost}`,
            ]);
        } else if (event.id === ANALYSIS_PAGES.links && this.routingData?.activePair) {
            this.router.navigate([
                `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}/${this.routingData.activePair.first_post}-${this.routingData.activePair.second_post}`,
            ]);
        } else {
            this.router.navigate([this.mainComponentUrl + event.id]);
        }
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
    }
}
