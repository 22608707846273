<div class="selectbox-radio">
    <div class="selectbox-radio__list scroll" [formGroup]="form">
        <div class="selectbox-radio__list-item" *ngFor="let item of listItems">
            <label class="selectbox-radio__list-item__label">
                <div class="selectbox-radio__list-item__text">
                    <span
                        class="selectbox-radio__list-item__text__icon"
                        [ngStyle]="{
                            'background-image':
                                'url(' + getAsset('flags/' + item.icon + '.svg') + ')'
                        }"
                    ></span>
                    <div class="selectbox-radio__list-item__text-name">{{ item.label }}</div>
                </div>
                <input type="radio" formControlName="selectedItem" [value]="item.id" />
                <item-radio
                    class="selectbox-radio__list-item__checkmark"
                    [check]="item.selected"
                ></item-radio>
            </label>
        </div>
    </div>
</div>
