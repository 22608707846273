<div class="chart-content" (mouseleave)="hoverLineVisible = false">
    <canvas
        #chart
        baseChart
        [type]="chartType"
        [legend]="false"
        [datasets]="chartData"
        [options]="chartOptions"
        [plugins]="plugins"
    ></canvas>
    <div
        class="chart-hover-line"
        [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
        [style.left.px]="hoverLinePosition"
    ></div>
</div>
