import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarMenuComponent } from '@libs/shared-ui/components/sidebar-menu/sidebar-menu.component';
import { SidebarModule } from '@libs/shared-ui/components/sidebar/sidebar.module';
import { GroupChangeSelectorComponent } from '@libs/shared-ui/components/sidebar-menu/group-change-selector/group-change-selector.component';
import { GroupListItemComponent } from '@libs/shared-ui/components/sidebar-menu/group-change-selector/group-list-item/group-list-item.component';
import { DirectivesModule } from '@libs/shared-ui/components/directives/directives.module';
import { FeedbackModule } from '@libs/shared-ui/components/feedback/feedback.module';
import { UserProfileComponent } from '@libs/shared-ui/components/sidebar-menu/user-profile/user-profile.component';
import { PipesModule } from '@libs/shared-ui/components/pipes/pipes.module';

@NgModule({
    exports: [SidebarMenuComponent],
    declarations: [
        SidebarMenuComponent,
        GroupChangeSelectorComponent,
        GroupListItemComponent,
        UserProfileComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        SidebarModule,
        DirectivesModule,
        FeedbackModule,
        PipesModule,
    ],
})
export class SidebarMenuModule {}
