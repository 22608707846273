import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from '@cityair/modules/settings/store/settings/reducers';
import {
    selectCurrentRegionCoefs,
    selectExtConfig,
} from '@cityair/modules/core/store/group/group.feature';
import { selectMyRole } from '@cityair/modules/core/store/selectors';

const settingsSelector = createFeatureSelector<SettingsState>('settings');
export const selectActiveTokenId = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.activeTokenId
);
export const selectUserTokenHistory = createSelector(settingsSelector, (state: SettingsState) =>
    state?.userTokens?.filter((v) => !v.IsApiKey)
);
export const selectUserApiKeys = createSelector(settingsSelector, (state: SettingsState) =>
    state?.userTokens?.filter((v) => v.IsApiKey)
);
export const selectIsSavingMapSettings = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.isSavingMapSettings
);
export const selectIsSavingStartModule = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.isSavingStartModule
);
export const selectGroupResponseAfterUpdate = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.updateSettingsResponse
);

export const selectTotalActiveTokens = createSelector(
    selectUserTokenHistory,
    selectActiveTokenId,
    (tokens, active) => {
        if (tokens?.length && active) {
            return tokens.filter((v) => v.TokenId !== active && !v.IsApiKey)?.length;
        }
        return 0;
    }
);
export const selectAllTokenNotCurrentSession = createSelector(
    selectUserTokenHistory,
    selectActiveTokenId,
    (tokens, active) => {
        if (tokens?.length && active) {
            return tokens.filter((v) => v.TokenId !== active && !v.IsApiKey);
        }
        return null;
    }
);
export const selectApiKeyByToken = (id) =>
    createSelector(settingsSelector, (state: SettingsState) => state?.apiKeysToken[id] ?? null);
export const selectNewCoef = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.newCoefficient
);
export const selectCoefficient = createSelector(
    settingsSelector,
    (state: SettingsState) => state?.regionCoefficient
);
export const selectRegionCoefficient = createSelector(
    selectCurrentRegionCoefs,
    selectCoefficient,
    (groupCoefficient, updateCoefficient) => updateCoefficient ?? groupCoefficient
);
