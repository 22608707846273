import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'search-result-placeholder',
    template: ` <div class="search-result-placeholder">
        <div class="search-result-placeholder__img"></div>
        <div class="search-result-placeholder__text">
            {{ text }}
        </div>
        <div class="search-result-placeholder__btn" *ngIf="clearText" (click)="clear.emit()">
            {{ clearText }}
        </div>
    </div>`,
    styleUrls: ['./search-result-placeholder.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultPlaceholderComponent {
    @Input() text = TEXTS.POSTS_AND_DEVICES.unfortunatelyNothing;
    @Input() clearText?: string;
    @Output() clear = new EventEmitter();
}
