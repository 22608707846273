import { Component } from '@angular/core';

import { TEXTS } from '@libs/common/texts/texts';

@Component({
    selector: 'ca-contacts-info',
    templateUrl: 'contacts-info.component.html',
    styleUrls: ['contacts-info.component.less'],
})
export class ContactsInfoComponent {
    TEXTS = TEXTS;
}
