import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapControlComponent } from '@libs/shared-ui/components/map-control/map-control.component';

@NgModule({
    exports: [MapControlComponent],
    declarations: [MapControlComponent],
    imports: [CommonModule],
})
export class MapControlModule {}
