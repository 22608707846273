import { Component, OnDestroy } from '@angular/core';

import { TabModel } from '@libs/common/types/tab-model';
import { TEXTS } from '@libs/common/texts/texts';
import { selectGroupId } from '@cityair/modules/core/store/group/group.feature';
import { filter, take } from 'rxjs/operators';
import {
    changeCurrentPage,
    getNotificationsSettings,
} from '@cityair/modules/notifications/store/actions';
import { Store } from '@ngrx/store';
import { selectCurrentPage } from '@cityair/modules/notifications/store/selectors';
import { selectMyRole } from '@cityair/modules/core/store/selectors';
import { NotificationPages } from '@cityair/modules/notifications/store/reducers';
import { Subscription } from 'rxjs';
@Component({
    selector: 'notifications',
    templateUrl: 'notifications.component.html',
})
export class Notifications implements OnDestroy {
    pages = NotificationPages;
    private textNotify = TEXTS.NOTIFICATIONS;
    public textAdminPanel = TEXTS.ADMIN_PANEL;
    public currentPage: NotificationPages;
    selectMyRole = selectMyRole;

    pageTabs: TabModel[] = [
        {
            title: this.textNotify.eventList,
            type: NotificationPages.EVENT_FEED,
        },
        {
            title: this.textNotify.settingNotification,
            type: NotificationPages.SUBSCRIPTIONS,
        },
    ];

    selectedTab = this.pageTabs[0];
    subscriptions: Subscription[] = [];
    constructor(public store: Store) {
        this.store
            .select(selectGroupId)
            .pipe(
                filter((v) => !!v),
                take(1)
            )
            .subscribe((data) => {
                this.store.dispatch(getNotificationsSettings());
            });
        const currentPageSub = this.store.select(selectCurrentPage).subscribe((page) => {
            this.currentPage = page;
            this.selectedTab = this.pageTabs.find((t) => t.type === page);
        });
        this.subscriptions.push(currentPageSub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    showSelectedTab(tab: TabModel) {
        this.store.dispatch(changeCurrentPage({ payload: tab.type }));
    }
}
